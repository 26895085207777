import React, { Component } from 'react';
import FormValidator from '../../../../../validator/FormValidator';
import IdConstants from '../../../../../data/idConstants';
import PricingPlanPreview from '../PricingPlanPreview';
import {
  errorCodes,
  pricingPlanType,
  paymentOccurrenceTypes,
  defaultSlabDetails
} from '../../../../../data/appConstants';
import Loader from '../../../../Shared/Utils/Loader';
import PricingSlabsTable from '../PricingSlabsTable';
import getUserId from '../../../../../utils/get-user-id';
import './index.scss';
import {
  Dropdown,
  SideBar,
  StepIndicator,
  TextArea,
  TextInput,
  GroupingCard
} from '../../../../../msreact-kit/packages/html-components';
import { IS_MKOSINT_TENANT } from '../../../../../actions/configuration';
import { getPricingPlanTab, getPricingPlanType } from '../../../../pricing/utils';
import Images from '../../../../../assets/img';
import i18next from 'i18next';
import DynamicFieldConstants from '../../../../../shared/constants/dynamic-field-constants';
import { getRegionCode } from '../../../../../utils/get-region-details';
import Tooltip from '../../../../../components/Shared/Tooltip';
import _ from 'lodash';

const nodeRSA = require('node-rsa');

class PricingPlanDialog extends Component {
  constructor(props) {
    super(props);
    this.chargeableUnitTypesArray =
      this.getArrayFromPricingMaster('chargeable_unit');
    this.burstTimeUnitArray = this.getArrayFromPricingMaster('burst_time_unit');
    this.consumptionTypesArray = this.getArrayFromPricingMaster('consumption_type')
      ?.filter((item) => item.name !== IdConstants.pricing.notApplicable)
      ?.sort((item1, item2) => item1.displayOrder - item2.displayOrder);

    this.planBenefitsArray = [];
    const planBenefitObject = {
      attributeId: null,
      name: 'enterItemsIncludedInPlan',
      displayName: i18next.t('API_DETAIL.ENTER_ITEMS_INCLUDED_IN_THE_PLAN'),
      description: i18next.t('API_DETAIL.ADD_INFORMATION_LIKE_PRICE_USAGE'),
      xpath:
        '/pricingPlan/pricingPlan/basicPlanInformation/enterItemsIncludedInPlan',
      attributeType: DynamicFieldConstants.GROUP,
      displayOrder: 0,
      validation: {
        rule: {
          length: {
            min: 1,
            max: 10,
            errorCode: 'MS105',
            errorMessage: ''
          }
        }
      },
      attributes: [
        {
          attributeId: null,
          name: 'planDetailedItem',
          displayName: i18next.t('API_DETAIL.PLAN_DETAILED_ITEM'),
          description: '',
          xpath:
            '/pricingPlan/pricingPlan/basicPlanInformation/enterItemsIncludedInPlan/planDetailedItem',
          attributeType: DynamicFieldConstants.TEXT,
          placeHolder: i18next.t('API_DETAIL.ENTER_ITEM'),
          displayOrder: 10,
          validation: {
            rule: {
              required: {
                isRequired: true
              },
              length: {
                min: 0,
                max: 50
              }
            }
          }
        }
      ]
    };

    if (!this.props?.isEditPlan) this.planBenefitsArray?.push(planBenefitObject);
    else {
      this.props?.editPlanDetails?.planBenefits?.forEach((item) => {
        const planBenefitObjectCopy = _.cloneDeep(planBenefitObject);
        planBenefitObjectCopy.attributes[0].value = item.benefitDescription;
        planBenefitObjectCopy.name = planBenefitObjectCopy.name + item.planBenefitId;
        this.planBenefitsArray?.push(planBenefitObjectCopy);
      });
    }

    this.validator = new FormValidator([
      {
        field: 'planName',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLAN_NAME_IS_REQUIRED')
      },
      {
        field: 'description',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DESCRIPTION_IS_REQUIRED'
        )
      },
      {
        field: 'description',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLEASE_ENTER_VALID_CHARACTERS_IN_THE_DESCRIPTION_FIELD'
        ),
        args: ['PRICING_PLAN_NAME']
      },
      {
        field: 'planName',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLEASE_ENTER_VALID_CHARACTERS_IN_THE_NAME_FIELD'
        ),
        args: ['PRICING_PLAN_NAME']
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.BURST_CONTROL_LIMIT_IS_REQUIRED'
        )
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_INTEGER'
        ),
        args: ['NUMBER']
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_INTEGER'
        ),
        args: ['POSITIVENUMBER']
      }
    ]);

    let rateLimitTypes = this.props?.pricingPlanMasterData?.rate_limit;
    const currencyArray = IdConstants?.currencyOptions?.find(
      (item) => item.regionCode === getRegionCode()
    )?.currencies;
    const filteredCurrencies = this.props?.pricingPlanMasterData?.currency?.filter(
      (item) => currencyArray?.includes(item.name)
    );
    const currencyDropdownOptions = filteredCurrencies?.map(
      ({ id, name, displayName }) => ({
        label: displayName,
        id: id,
        name: name
      })
    );
    if (rateLimitTypes?.length) {
      rateLimitTypes = rateLimitTypes.filter(
        (consumptionType) =>
          consumptionType.name !== IdConstants?.pricing?.consumptionNoMeasurementType
      );
    }
    const planTab = _.cloneDeep(getPricingPlanTab());
    const initalPlanTab = planTab.map((plan) => {
      return {
        ...plan,
        displayName: i18next.t(plan.displayName)
      };
    });
    if (
      rateLimitTypes &&
      rateLimitTypes[0].name === IdConstants?.pricing?.bandWidthRateLimitType
    ) {
      rateLimitTypes = rateLimitTypes.reverse();
    }
    this.paymentOccurrenceCount = '-1';
    this.userDetails = {
      userId: getUserId(),
      typeOfUser: IdConstants.typeOfUsers.b2b,
      b2bIdentifier: this.props?.prodBasicInfo?.companyNo
    };
    this.setActivePlanType();
    this.state = {
      planBenefitsGroupData: _.cloneDeep(this.planBenefitsArray),
      currentPlan: {
        planId: '',
        planName: '',
        description: '',
        planBenefits: [],
        billingCycleType: '',
        price: '',
        currencyCode: '',
        consumption: '',
        rateLimitType: '',
        requestCount: '',
        chargeableUnit: '',
        quota: '',
        isSoftLimit: '',
        overUsagePrice: '',
        dataBandwidth: '',
        bandwidthType: '',
        burstControl: '',
        burstControlLimitCount: 1,
        burstTimeUnit: '',
        pricePerRequest: '',
        isPlanSubscribed: false,
        paymentPreference: 'true',
        slabLimitDetails: [],
        isSlabPricing: true,
        planDetails: {
          planType: this.activePlanName,
          paymentType: this.activePaymentType,
          paymentModePref: this.activePaymentPrefMode,
          occurrence: this.planOccurrence
        },
        userDetails: this.userDetails
      },
      active: 0,
      data: initalPlanTab,
      size: planTab.length,
      isDefaultPlan: false,
      saveButtonLabel: i18next.t('DYNAMIC_FORM.LABEL.ADD_PRICING_PLAN'),
      bytes: 'KB',
      validation: this.validator.validPlan(),
      isCustomPlan: false,
      isCustomPlanSaved: false,
      savedCustomPlan: {},
      rateLimitTypes: rateLimitTypes,
      consumptionTypes: '',
      currencyDropdownOptions: currencyDropdownOptions,
      billingCycles: [],
      pricingPlanTypeTabValue: _.cloneDeep(getPricingPlanType()),
      isPricingSlabsValid: true,
      priceSlabs: [],
      selectedChargeableUnit: 'PER_REQUEST_HIT'
    };
  }

  getArrayFromPricingMaster = (whichObject) => {
    return this.props?.pricingPlanMasterData[whichObject]?.map((item) => ({
      label: item?.displayName,
      id: item?.id,
      name: item?.name,
      displayName: item?.displayName
    }));
  };

  componentDidMount() {
    let plan = {};
    if (this.props.isEditPlan) {
      plan = this.props.editPlanDetails;
      this.setState({
        dialogHeading: i18next.t('DYNAMIC_FORM.PRICING_PLAN.EDIT_PRICING_PLAN'),
        saveButtonLabel: i18next.t('DYNAMIC_FORM.LABEL.SAVE')
      });
      this.setCurrentPlan(plan);
    } else {
      this.setCustomPlan();
      this.setState({
        dialogHeading: i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD_PRICING_PLAN'),
        saveButtonLabel: i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD_PRICING_PLAN')
      });
    }
  }

  setActivePlanType = () => {
    this.planTypes = this.props?.pricingPlanMasterData?.plan_type
      ?.filter((plan_type) => plan_type.name !== IdConstants.pricingPlanType.tryout)
      ?.sort((planType1, planType2) => planType1.name.localeCompare(planType2.name))
      ?.reverse();
    let breakCondition = false;
    this.showTieredInfo = false;
    this.showPayAsYouGoInfo = false;
    this.paymentOccurrenceType = '-1';
    if (this.props.isEditPlan) {
      let plan = this.props.editPlanDetails;
      this.activePlanName = plan.planDetails?.planType;
      this.activePaymentType = plan.planDetails?.paymentType;
      this.activePaymentPrefMode = plan.planDetails?.paymentModePref;
      this.paymentOccurrenceCount = plan.planDetails?.occurrence;
      paymentOccurrenceTypes.forEach((occurrenceType) => {
        occurrenceType.selected = false;
        if (occurrenceType.name === IdConstants.pricingPlanOccurrence.oneTime) {
          occurrenceType.selected = true;
        }
      });
      this.paymentOccurrenceType =
        this.paymentOccurrenceCount === '1'
          ? IdConstants.pricingPlanOccurrence.oneTime
          : IdConstants.pricingPlanOccurrence.recurring;
      this.planTypes?.map((types, typeIndex) => {
        if (
          types.name !== IdConstants.pricingPlanType.flatRate &&
          this.activePlanName === types.name
        ) {
          this.planActive = typeIndex;
          if (types.name === IdConstants.pricingPlanType.usageBased) {
            this.showTieredInfo = true;
          } else if (types.name === IdConstants.pricingPlanType.payAsYouGo) {
            this.showPayAsYouGoInfo = true;
          }
          if (types?.props && types?.props.length > 0) {
            types.props.map((planTypeProps, paymentTypeIndex) => {
              if (this.activePaymentType === planTypeProps.paymentType) {
                this.paymentTypeActive = paymentTypeIndex;
                if (
                  planTypeProps?.paymentModePref &&
                  planTypeProps?.paymentModePref.length > 0
                ) {
                  planTypeProps.paymentModePref.map(
                    (paymentModePref, paymentModeIndex) => {
                      if (
                        this.activePaymentPrefMode === paymentModePref?.paymentMode
                      ) {
                        this.paymentModeActive = paymentModeIndex;
                      }
                    }
                  );
                }
              }
            });
          }
        }
      });
    } else {
      this.planTypes?.map((types, typeIndex) => {
        if (types.name !== IdConstants.pricingPlanType.flatRate && !breakCondition) {
          this.activePlanName = types.name;
          this.planActive = typeIndex;
          breakCondition = true;
          if (types.name === IdConstants.pricingPlanType.usageBased) {
            this.showTieredInfo = true;
          } else if (types.name === IdConstants.pricingPlanType.payAsYouGo) {
            this.showPayAsYouGoInfo = true;
          }
          if (types?.props && types?.props.length > 0) {
            types.props.map((planTypeProps, paymentTypeIndex) => {
              if (paymentTypeIndex === 0) {
                this.paymentTypeActive = paymentTypeIndex;
                this.activePaymentType = planTypeProps.paymentType;
                if (
                  planTypeProps?.paymentModePref &&
                  planTypeProps?.paymentModePref.length > 0
                ) {
                  planTypeProps.paymentModePref.map(
                    (paymentModePref, paymentModeIndex) => {
                      if (paymentModeIndex === 0) {
                        this.paymentModeActive = paymentModeIndex;
                        this.activePaymentPrefMode = paymentModePref?.paymentMode;
                      }
                    }
                  );
                }
              }
            });
          }
        }
      });
    }
  };

  handleIndicatorClick = (activeItem) => {
    this.setState({
      active: activeItem,
      activeGrouping: activeItem
    });
  };

  savePlan = () => {
    const validation = this.validator.validate('all', '', this.state);
    this.setState({ validation });
    let plan = this.state.currentPlan;
    plan.planName = plan.planName.toUpperCase();
    plan.price = plan.price ? this.roundToDecimalValue(plan.price, 2) : '';
    plan.pricePerRequest = plan.pricePerRequest
      ? this.roundToDecimalValue(plan.pricePerRequest, 2)
      : '';
    plan.overUsagePrice = plan.overUsagePrice
      ? this.roundToDecimalValue(plan.overUsagePrice, 5)
      : '';
    plan.consumption = plan?.consumption?.name || plan?.consumption;
    plan.userDetails = {
      userId: getUserId(),
      typeOfUser: IdConstants.typeOfUsers.b2b,
      b2bIdentifier: this.props.prodBasicInfo?.companyNo
    };
    this.setState(
      {
        currentPlan: plan
      },
      () => {
        if (this.props.isEditPlan) {
          this.props.updatePlan(this.state);
        } else {
          this.props.createPlan(this.state);
        }
      }
    );
  };

  roundToDecimalValue(number, limit) {
    if (typeof number === 'string') {
      number = parseFloat(number);
    }
    return number.toFixed(limit);
  }

  getCurrentPlanPaymentPreference = (planType) => {
    switch (planType ?? this.state.pricingPlanTypeTabValue) {
      case IdConstants.freePlanBillingType.billingTypeCode:
      case IdConstants.freePolicyBillingType.billingTypeCode:
        return 'false';
      default:
        return 'true';
    }
  };

  getPaymentPreference = (plan) => {
    if (plan.paymentPreference) {
      let publicKey =
        '-----BEGIN PUBLIC KEY-----\n' +
        plan.paymentPreference.key +
        '\n' +
        '-----END PUBLIC KEY-----\n';
      let decryptionKey = new nodeRSA(publicKey);
      return decryptionKey.decryptPublic(plan.paymentPreference.isRequired, 'utf8');
    } else {
      return 'false';
    }
  };

  setCurrentPlan = (plan) => {
    let billingCycles = this.props?.pricingPlanMasterData?.billing_cycle;
    let billingCyclesState = [];
    if (plan) {
      billingCycles.forEach((cycle) => {
        if (
          plan.planId !== IdConstants.pricing.premiumPlanId &&
          !plan.unlimitedPlan &&
          cycle.name !== IdConstants.pricing.payPerCallBillingCycleType
        ) {
          billingCyclesState.push(cycle);
          plan.planBillingCycle.unlimitedPlan = false;
        } else if (
          (plan.planId === IdConstants.pricing.premiumPlanId ||
            plan.unlimitedPlan) &&
          cycle.name === IdConstants.pricing.payPerCallBillingCycleType
        ) {
          billingCyclesState.push(cycle);
          plan.planBillingCycle.billingCycleType =
            IdConstants.pricing.payPerCallBillingCycleType;
          plan.planBillingCycle.unlimitedPlan = true;
        }
      });
    }
    this.setState({
      planBenefitsGroupData: _.cloneDeep(this.planBenefitsArray),
      currentPlan: {
        planId: plan.planId,
        planName: plan.planName,
        description: plan.description,
        billingCycleType: plan.planBillingCycle.billingCycleType,
        price: plan.planCost,
        currencyCode: plan.currency.currencyCode,
        consumption: plan?.consumption
          ? this.consumptionTypesArray?.find(
              (item) => item.name === plan?.consumption
            )
          : this.consumptionTypesArray.find(
              (item) => item.name === IdConstants.pricing.definedByUsage
            ),
        rateLimitType: plan.rateLimit.rateLimitType.limitType,
        requestCount: plan.rateLimit.limitCount,
        chargeableUnit: plan?.rateLimit?.chargeableUnit?.chargeableUnitType
          ? {
              ...this.chargeableUnitTypesArray?.find(
                (item) =>
                  item.name === plan?.rateLimit?.chargeableUnit?.chargeableUnitType
              ),
              chargeableUnitType: plan?.rateLimit?.chargeableUnit?.chargeableUnitType
            }
          : {
              ...this.chargeableUnitTypesArray?.find(
                (item) => item.name === IdConstants.pricing.perReqHitSlabType
              ),
              chargeableUnitType: IdConstants.pricing.perReqHitSlabType
            },
        quota: plan.rateLimit.timeUnit.timeUnitCode,
        isSoftLimit: plan.softLimit,
        overUsagePrice: plan.softLimitData ? plan.softLimitData.additionalCost : 0,
        dataBandwidth: plan.rateLimit.limitCount,
        bandwidthType:
          plan.rateLimit.bandwidthUnitType !== null
            ? plan.rateLimit.bandwidthUnitType.bandWidthUnit
            : IdConstants.pricing.kiloByte,
        burstControl: plan?.burstControlLimit?.burstControlLimitId ? true : false,
        burstControlLimitCount: plan?.burstControlLimit?.burstControlLimitId
          ? plan.burstControlLimit.limitCount
          : 1,
        burstControlLimitId: plan?.burstControlLimit?.burstControlLimitId,
        burstTimeUnit: plan?.burstControlLimit?.burstControlLimitId
          ? this.burstTimeUnitArray?.find(
              (item) => item.name === plan?.burstControlLimit?.timeUnit?.timeUnitCode
            )
          : this.burstTimeUnitArray?.find(
              (item) => item.name === IdConstants.pricing.minuteBurstControl
            ),
        pricePerRequest: plan.softLimitData ? plan.softLimitData.additionalCost : 0,
        unlimitedPlan: plan.planBillingCycle.unlimitedPlan,
        isPlanSubscribed: plan.cardContent
          ? plan.cardContent.isPlanSubscribed
          : false,
        paymentPreference: this.getPaymentPreference(plan),
        slabLimitDetails: plan.rateLimit.slabLimitDetails,
        planBenefits: plan?.planBenefits,
        planDetails: {
          planType: this.activePlanName,
          paymentType: this.activePaymentType,
          paymentModePref: this.activePaymentPrefMode,
          occurrence: this.paymentOccurrenceCount
        }
      },
      isDefaultPlan: plan.defaultPlan,
      validation: this.validator.validPlan(),
      isCustomePlan: false,
      billingCycles: billingCyclesState,
      isCustomPlan: false,
      selectedChargeableUnit:
        plan?.rateLimit?.chargeableUnit?.chargeableUnitType ||
        IdConstants.pricing.perReqHitSlabType
    });
  };

  changeValueDropdown(event) {
    let name = event.name;
    let value = event.value.name;
    if (
      (name === 'dataBandwidth' ||
        name === 'requestCount' ||
        name === 'burstControlLimitCount') &&
      !/^\d*$/.test(value)
    ) {
      return;
    }
    if (name === 'isSoftLimit') {
      if (value === '1') {
        value = true;
      } else {
        value = false;
      }
    }
    if (name === 'bandwidthType') {
      let bytes = this.props?.pricingPlanMasterData?.bandwidth?.find(
        function (element) {
          return element.name === value;
        }
      );
      this.setState({
        bytes: bytes.name
      });
    }
    if (name === 'paymentOccurrence') {
      this.paymentOccurrenceCount =
        value === IdConstants.pricingPlanOccurrence.oneTime ? '1' : '-1';
    }
    this.setState(
      (prevState) => ({
        currentPlan: {
          ...prevState.currentPlan,
          [name]: value
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, 'currentPlan', this.state)
        });
      }
    );
  }

  changeValue(event) {
    let name = event.name;
    let value = event.value;
    if (
      (name === 'dataBandwidth' ||
        name === 'requestCount' ||
        name === 'burstControlLimitCount') &&
      !/^\d*$/.test(value)
    ) {
      return;
    }
    if (name === 'isSoftLimit') {
      if (value === '1') {
        value = true;
      } else {
        value = false;
      }
    }
    if (name === 'bandwidthType') {
      let bytes = this.props?.pricingPlanMasterData?.bandwidth?.find(
        function (element) {
          return element.name === value;
        }
      );
      this.setState({
        bytes: bytes.name
      });
    }
    this.setState(
      (prevState) => ({
        currentPlan: {
          ...prevState.currentPlan,
          [name]: value
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, 'currentPlan', this.state)
        });
      }
    );
    this.handleDisableNextBtn();
  }

  changeValueRequest(event) {
    let name = event.name;
    let value = '';
    if (name === 'burstTimeUnit') {
      value = event.value;
    } else {
      value = event.value.name;
    }
    if (
      (name === 'dataBandwidth' ||
        name === 'requestCount' ||
        name === 'burstControlLimitCount') &&
      !/^\d*$/.test(value)
    ) {
      return;
    }
    if (name === 'isSoftLimit') {
      if (value === '1') {
        value = true;
      } else {
        value = false;
      }
    }
    if (name === 'bandwidthType') {
      let bytes = this.props?.pricingPlanMasterData?.bandwidth?.find(
        function (element) {
          return element.name === value;
        }
      );
      this.setState({
        bytes: bytes.name
      });
    }
    this.setState(
      (prevState) => ({
        currentPlan: {
          ...prevState.currentPlan,
          [name]: value
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, 'currentPlan', this.state)
        });
      }
    );
  }

  handleCheckBoxChange = (event) => {
    this.setState({
      currentPlan: {
        ...this.state.currentPlan,
        burstControl: event.target.checked
      }
    });
  };

  setCustomPlan = () => {
    let billingCycleType = this.props?.pricingPlanMasterData?.billing_cycle?.find(
      function (element) {
        return element.name === IdConstants.pricing.monthBillingCycleType;
      }
    );
    let rupees = this.state.currencyDropdownOptions[0];
    let consumption = this.props?.pricingPlanMasterData?.consumption_type?.find(
      function (element) {
        return element.name === IdConstants.pricing.definedByUsage;
      }
    );
    let rateLimitType = this.props?.pricingPlanMasterData?.rate_limit?.find(
      function (element) {
        return element.name === IdConstants.pricing.reqCountLimitType;
      }
    );
    let quota = this.props?.pricingPlanMasterData?.rate_limit_time_unit?.find(
      function (element) {
        return element.name === IdConstants.pricing.monthTimeUnitCode;
      }
    );
    let bandwidthType = this.props?.pricingPlanMasterData?.bandwidth?.find(
      function (element) {
        return element.name === IdConstants.pricing.kiloByte;
      }
    );
    let burstTimeUnit = this.props?.pricingPlanMasterData?.burst_time_unit?.find(
      function (element) {
        return element.name === IdConstants.pricing.minuteBurstControl;
      }
    );
    const chargeableUnit = this.chargeableUnitTypesArray?.find(
      (item) => item.name === IdConstants.pricing.perReqHitSlabType
    );
    const customBillingCycles =
      this.props?.pricingPlanMasterData?.billing_cycle?.filter(
        (cycle) => cycle.name !== IdConstants.pricing.payPerCallBillingCycleType
      );
    this.setState({
      currentPlan: {
        planId: '',
        planName: '',
        description: '',
        billingCycleType: billingCycleType?.name,
        price: 0,
        currencyCode: rupees?.name,
        consumption: consumption?.name,
        rateLimitType: rateLimitType?.name,
        requestCount: '',
        chargeableUnit: {
          chargeableUnitType: chargeableUnit?.chargeableUnitType
            ? chargeableUnit?.chargeableUnitType
            : chargeableUnit?.name,
          ...chargeableUnit
        },
        quota: quota?.name,
        isSoftLimit: true,
        overUsagePrice: '',
        dataBandwidth: '',
        bandwidthType: bandwidthType?.name,
        burstControl: false,
        burstControlLimitCount: 1,
        burstTimeUnit: burstTimeUnit,
        pricePerRequest: 0,
        paymentPreference: this.getCurrentPlanPaymentPreference(),
        slabLimitDetails: [
          {
            ...defaultSlabDetails,
            disableUnlimited:
              this.activePlanName === IdConstants.pricingPlanType.payAsYouGo
                ? true
                : false
          }
        ],
        planBenefits: [],
        planDetails: {
          planType: this.activePlanName,
          paymentType: this.activePaymentType,
          paymentModePref: this.activePaymentPrefMode,
          occurrence: this.paymentOccurrenceCount
        },
        userDetails: this.userDetails
      },
      isDefaultPlan: false,
      validation: this.validator.validPlan(),
      isCustomPlan: true,
      billingCycles: customBillingCycles
    });
  };

  handlePricingPlanTypeTabValueChange = (event, newValue) => {
    const paymentPreference = this.getCurrentPlanPaymentPreference(newValue);
    this.setState((prevState) => ({
      pricingPlanTypeTabValue: newValue,
      currentPlan: {
        ...prevState.currentPlan,
        paymentPreference
      }
    }));
  };

  getChargeableUnit = () => {
    switch (this.state.currentPlan?.rateLimitType) {
      case IdConstants.pricing.reqCountLimitType:
        return this.props?.pricingPlanMasterData?.chargeable_unit?.map(
          ({ id, name, displayName }) => ({
            label: displayName,
            id: id,
            name: name
          })
        );
      case IdConstants.pricing.bandWidthRateLimitType:
        return this.props?.pricingPlanMasterData?.bandwidth?.map(
          ({ id, name, displayName }) => ({
            label: displayName,
            id: id,
            name: name
          })
        );
    }
  };

  setTableValidation = (isValid) => {
    this.setState({
      isPricingSlabsValid: isValid
    });
  };

  setPricingSlabs = (pricingSlabs) => {
    this.setState({
      priceSlabs: pricingSlabs
    });
    this.handleDisableNextBtn('', pricingSlabs);
  };

  handleChargableUnitChange = (event) => {
    if (event.name == 'chargeableUnit')
      this.setState({ selectedChargeableUnit: event?.value?.name });
    let value = event.value.name;
    let units = this.getChargeableUnit();
    let unit = {
      ...units.filter((chargeableUnit) => chargeableUnit.name === value)[0]
    };
    let plan = {};
    let slabLimitDetails = [
      {
        ...defaultSlabDetails,
        disableUnlimited:
          this.activePlanName === IdConstants.pricingPlanType.payAsYouGo
            ? true
            : false
      }
    ];
    if (this.props?.isEditPlan) {
      plan = this.props?.editPlanDetails;
    }
    if (
      plan &&
      !this.state.isCustomPlan &&
      plan.rateLimit?.chargeableUnit.chargeableUnitType === value
    ) {
      slabLimitDetails = plan.rateLimit.slabLimitDetails;
    }
    this.setState({
      currentPlan: {
        ...this.state.currentPlan,
        slabLimitDetails: slabLimitDetails,
        chargeableUnit: { ...unit, chargeableUnitType: unit.name }
      }
    });
  };

  prev = () => {
    let step = this.state.active;
    if (step > 0) {
      step = step - 1;
      this.setState({
        active: step
      });
    }
  };

  next = () => {
    if (this.state.active === this.state.size - 1) {
      this.savePlan();
    } else {
      let step = this.state.active;
      if (step < this.state.size) {
        step = step + 1;
        this.handleDisableNextBtn(step);
        this.setState({
          active: step
        });
      }
    }
  };

  isPlanBenefitAvailable() {
    for (const item of this.state.currentPlan?.planBenefits) {
      if (!item?.benefitDescription) {
        return false;
      }
    }
    return true;
  }

  handleDisableNextBtn = (step, value) => {
    const currentPlan = this.state.currentPlan;
    const pricingMaxValue = this.state.priceSlabs.every(
      (ratelimit) => ratelimit.maxValue?.length > 0
    );
    if (this.state.active === 0) {
      const dataLength =
        currentPlan?.planBenefits?.length > 0 && this.isPlanBenefitAvailable();
      if (
        currentPlan?.planName?.length > 0 &&
        currentPlan?.description?.length > 0 &&
        dataLength > 0
      ) {
        return false;
      }
    } else if (
      (step === 1 || this.state.active === 1) &&
      currentPlan?.billingCycleType &&
      currentPlan?.billingCycleType?.length > 0
    ) {
      return false;
    } else if (
      (step === 2 || this.state.active === 2) &&
      this.state.isPricingSlabsValid &&
      pricingMaxValue
    ) {
      return false;
    }
    return true;
  };

  handleGroupData = (groupDataCpy) => {
    this.setState({ planBenefitsGroupData: groupDataCpy });
    const planBenefitsCopy = [];
    groupDataCpy.map((item, index) => {
      planBenefitsCopy.push({
        benefitDescription: item.attributes[0].value,
        benefitOrder: index
      });
      return true;
    });

    this.setState({
      currentPlan: {
        ...this.state.currentPlan,
        planBenefits: planBenefitsCopy
      }
    });
  };

  handleDeleteGroup = (event, updatedData) => {
    this.handleGroupData(updatedData);
  };

  handleFieldChange = (event, fieldItem) => {
    const groupIndex = this.state?.planBenefitsGroupData?.findIndex(
      (item) => item.name === fieldItem.groupID
    );
    const groupDataCopy = [...this.state.planBenefitsGroupData];
    groupDataCopy[groupIndex].attributes[0].value = event?.value;
    groupDataCopy[groupIndex].attributes[0].error = event?.error;
    this.setState({
      planBenefitsGroupData: groupDataCopy
    });

    const planBenefitsCopy = groupDataCopy.map((item, index) => ({
      benefitDescription: item.attributes[0].value,
      benefitOrder: index
    }));

    this.setState({
      currentPlan: {
        ...this.state.currentPlan,
        planBenefits: planBenefitsCopy
      }
    });
  };

  handleNextBtnLabel = (state) => {
    if (state.active === state.size - 1) {
      if (this.props?.isEditPlan) {
        return i18next.t('DYNAMIC_FORM.PRICING_PLAN.UPDATE_PRICING_PLAN');
      }
      return i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD_PRICING_PLAN');
    }
    return i18next.t('DYNAMIC_FORM.LABEL.NEXT');
  };

  handleGetFields = (fieldItem) => {
    if (fieldItem.attributeType === DynamicFieldConstants.TEXT) {
      return (
        <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
          <TextInput
            handleChange={(event) => this.handleFieldChange(event, fieldItem)}
            {...fieldItem}
          />
        </div>
      );
    }
  };

  handlePlanTypeClick = (
    planIndex,
    planName,
    paymentTypeName,
    paymentPrefModeName
  ) => {
    this.planActive = planIndex;
    this.paymentTypeActive = 0;
    this.activePlanName = planName;
    let slabLimitDetails = this.state.currentPlan.slabLimitDetails;
    const disableUnlimitedNewVal =
      planName === IdConstants.pricingPlanType.payAsYouGo ? true : false;
    slabLimitDetails.forEach((row) => {
      row.disableUnlimited = disableUnlimitedNewVal;
    });

    this.setState({
      ...this.state,
      currentPlan: {
        ...this.state.currentPlan,
        slabLimitDetails: slabLimitDetails,
        planDetails: {
          ...this.state.currentPlan.planDetails,
          planType: this.activePlanName,
          paymentType: paymentTypeName,
          paymentModePref: paymentPrefModeName
        }
      }
    });
    if (planName === IdConstants.pricingPlanType.usageBased) {
      this.showTieredInfo = true;
      this.showPayAsYouGoInfo = false;
    } else if (planName === IdConstants.pricingPlanType.payAsYouGo) {
      this.showTieredInfo = false;
      this.showPayAsYouGoInfo = true;
    }
  };
  handlePaymentTypeClick = (
    planIndex,
    paymentTypeIndex,
    paymentTypeName,
    paymentPrefModeName
  ) => {
    this.planActive = planIndex;
    this.paymentTypeActive = paymentTypeIndex;

    this.setState({
      ...this.state,
      currentPlan: {
        ...this.state.currentPlan,
        planDetails: {
          ...this.state.currentPlan.planDetails,
          paymentType: paymentTypeName,
          paymentModePref: paymentPrefModeName
        }
      }
    });
  };
  handlePaymentModeClick = (
    planIndex,
    paymentTypeIndex,
    paymentModeIndex,
    paymentModePref
  ) => {
    this.planActive = planIndex;
    this.paymentTypeActive = paymentTypeIndex;
    this.paymentModeActive = paymentModeIndex;

    this.setState({
      ...this.state,
      currentPlan: {
        ...this.state.currentPlan,
        planDetails: {
          ...this.state.currentPlan.planDetails,
          paymentModePref: paymentModePref
        }
      }
    });
  };

  render() {
    let plan = this.state.currentPlan;
    let validator = this.state.isCustomPlan
      ? this.validator.validate('', '', this.state)
      : this.validator.validate('all', '', this.state);
    let validation = validator.prodInfo;
    let disableFields = plan.isPlanSubscribed && this.props.isEditPlan;
    let burstControlError = '';
    let showFreePlan = true;
    let showOneTimePaymentPlan = true;
    let showRecurringPaymentPlan = true;
    let showFreePolicyPlan = false;

    if (IS_MKOSINT_TENANT) {
      showFreePlan = false;
      showOneTimePaymentPlan = false;
      showRecurringPaymentPlan = false;
      showFreePolicyPlan = true;
    }

    return (
      <React.Fragment>
        <SideBar
          label={this.state.dialogHeading}
          handleClose={this.props.onClose}
          handleSideBar={this.props.onClose}
          handlePrevious={this.prev}
          handleNext={this.next}
          isShowPrevStepBtn={this.state.active == 0 ? false : true}
          nextStepDisabled={this.handleDisableNextBtn()}
          nextBtnLabel={this.handleNextBtnLabel(this.state)}
          customClass='side-bar__pricing__plan'
          childrenClass='side-bar__pricing__plan-children'
        >
          <>
            {this.props.loader && this.props.loader.loader && <Loader />}
            <div className='modal__pricing__plan'>
              <div className='modal__pricing__indicator'>
                {disableFields && (
                  <div>
                    <div className='indicator__description'>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN.API_HAS_BEEN_SUBSCRIBED_BY_THE_USER'
                      )}
                    </div>
                  </div>
                )}
              </div>
              {this.state.active == 0 && (
                <div className='pricing__plan__wrapper'>
                  <div className='wrapper__class'>
                    <div className='search'>
                      <StepIndicator
                        data={this.state.data}
                        customClass='pricing__plan__indicator'
                        size={'-small'}
                        active={this.state.active}
                        handleClick={this.handleIndicatorClick}
                      />
                    </div>
                    <div className='pricing-plan-form'>
                      <div className='w__50'>
                        <div className='w__50-planName'>
                          <TextInput
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.PLAN_NAME'
                            )}
                            validation={{
                              rule: {
                                required: {
                                  isRequired: true
                                },
                                length: {
                                  max: 20,
                                  errorCode: 'MS105'
                                }
                              }
                            }}
                            placeHolder={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.PLAN_PRICING_NAME'
                            )}
                            maxLength='20'
                            value={plan.planName.toUpperCase()}
                            name='planName'
                            handleChange={(event) => this.changeValue(event)}
                            disabled={this.state.isDefaultPlan || disableFields}
                            error={
                              this.props.validationError.error ===
                                errorCodes.uniquePlanNameError ||
                              this.props.validationError.error ===
                                errorCodes.defaultPlanNameError
                                ? this.props.validationError.message
                                : validation.currentPlan.planName.message
                            }
                          />
                        </div>
                        <div>
                          <TextArea
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.DESCRIBE_YOUR_PLAN'
                            )}
                            validation={{
                              rule: {
                                required: {
                                  isRequired: true
                                },
                                length: {
                                  max: 1000,
                                  errorCode: 'MS105'
                                }
                              }
                            }}
                            placeHolder={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.PLAN_DESCRIPTION_HERE'
                            )}
                            value={plan.description}
                            maxLength='1000'
                            name='description'
                            handleChange={(event) => this.changeValue(event)}
                            error={
                              validation.currentPlan.description.isInvalid
                                ? validation.currentPlan.description.message
                                : ''
                            }
                          />
                        </div>
                        {this.state?.planBenefitsGroupData?.length > 0 && (
                          <GroupingCard
                            group={this.state.planBenefitsGroupData}
                            handleGetFieldInput={(groupingCardData) =>
                              this.handleGetFields(groupingCardData)
                            }
                            handleGroupData={(groupData) => {
                              this.handleGroupData(groupData);
                            }}
                            handleDeleteGroup={(event, groupData) => {
                              this.handleDeleteGroup(event, groupData);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='w__50 marketplace_view'>
                    <div className='marketplace_view-preview-label'>
                      <h5>{i18next.t('SHARED.GENERIC_MARKETPLACE_PREVIEW')}</h5>
                    </div>
                    <div>
                      <PricingPlanPreview
                        plan={this.state.currentPlan}
                        priceSlabs={this.state.priceSlabs}
                      />
                    </div>
                    <div className='marketplace_view_p'>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN.THIS_IS_HOW_YOUR_PLAN_LOOKS_LIKE_TO_THE_END_USERS_IN_MARKETPLACE'
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.active == 1 && (
                <div className='pricing__plan__wrapper'>
                  <div className='wrapper__class'>
                    <div className='search'>
                      <StepIndicator
                        data={this.state.data}
                        size={'-small'}
                        customClass='pricing__plan__indicator'
                        active={this.state.active}
                        handleClick={this.handleIndicatorClick}
                      />
                    </div>
                    <div
                      value={this.state.pricingPlanTypeTabValue}
                      className='tab_panel pricing-plan-list'
                    >
                      <div
                        className='tab_panel_context'
                        onChange={(event, newValue) =>
                          this.handlePricingPlanTypeTabValueChange(event, newValue)
                        }
                      >
                        {this.planTypes?.map((planType, planIndex) => (
                          <div
                            label={planType?.name}
                            className={
                              planIndex === this.planActive
                                ? `context__tab-background-font-color context__box-style`
                                : `context__non-selected-tab-style context__box-style`
                            }
                            onClick={() =>
                              planType?.name !==
                                IdConstants.pricingPlanType.flatRate &&
                              planType?.name !== IdConstants.pricingPlanType.freePlan
                                ? this.handlePlanTypeClick(
                                    planIndex,
                                    planType?.name,
                                    planType?.props[0].paymentType,
                                    planType?.props[0].paymentModePref[0]
                                  )
                                : false
                            }
                            disabled={
                              planType?.name ===
                                IdConstants.pricingPlanType.flatRate ||
                              planType?.name === IdConstants.pricingPlanType.freePlan
                                ? true
                                : false
                            }
                            key={planType?.name}
                          >
                            {planType?.displayName}
                          </div>
                        ))}
                      </div>
                      <div className='tab_panel'>
                        {this.planTypes?.map(
                          (planType, planIndex) =>
                            planType?.props &&
                            planType?.props.length > 0 && (
                              <div
                                className={`${planType.value} tab_panel_context`}
                                style={{
                                  display:
                                    planIndex === this.planActive ? 'flex' : 'none'
                                }}
                              >
                                {planType?.props.map(
                                  (planTypeProps, paymentTypeIndex) =>
                                    planTypeProps.paymentType !==
                                      IdConstants.pricingPaymentType
                                        .notApplicable && (
                                      <div
                                        className={
                                          paymentTypeIndex ===
                                            this.paymentTypeActive &&
                                          planIndex === this.planActive
                                            ? `context__tab-background-font-color context__box-style`
                                            : `context__non-selected-tab-style context__box-style`
                                        }
                                        style={{ textTransform: 'capitalize' }}
                                        onClick={() =>
                                          this.handlePaymentTypeClick(
                                            planIndex,
                                            paymentTypeIndex,
                                            planTypeProps.paymentType,
                                            planTypeProps.paymentModePref[0]
                                              .paymentMode
                                          )
                                        }
                                      >
                                        {planTypeProps.paymentType}
                                        {planTypeProps.paymentModePref.length ===
                                          1 &&
                                          planTypeProps.paymentModePref[0]
                                            .paymentMode !==
                                            IdConstants.pricingPaymentType
                                              .notApplicable && (
                                            <span>
                                              {' (' +
                                                planTypeProps.paymentModePref[0]
                                                  .paymentMode +
                                                ')'}
                                            </span>
                                          )}
                                      </div>
                                    )
                                )}
                              </div>
                            )
                        )}
                      </div>
                      <div className='tab_panel'>
                        {this.planTypes?.map(
                          (planType, planIndex) =>
                            planType?.props &&
                            planType?.props.length > 0 && (
                              <div
                                className={`${planType.value} tab_panel_context`}
                                style={{
                                  display:
                                    planIndex === this.planActive ? 'flex' : 'none'
                                }}
                              >
                                {planType?.props.map(
                                  (planTypeProps, paymentTypeIndex) =>
                                    planTypeProps.paymentType !==
                                      IdConstants.pricingPaymentType.notApplicable &&
                                    planTypeProps.paymentModePref.length > 1 &&
                                    planTypeProps.paymentModePref.map(
                                      (paymentModePref, paymentModeIndex) => (
                                        <div
                                          key={paymentModePref.paymentMode}
                                          className={
                                            paymentTypeIndex ===
                                              this.paymentTypeActive &&
                                            planIndex === this.planActive &&
                                            paymentModeIndex ===
                                              this.paymentModeActive
                                              ? `context__tab-background-font-color context__box-style`
                                              : `context__non-selected-tab-style context__box-style`
                                          }
                                          style={{
                                            textTransform: 'capitalize',
                                            display:
                                              planIndex === this.planActive &&
                                              paymentTypeIndex ===
                                                this.paymentTypeActive
                                                ? 'flex'
                                                : 'none'
                                          }}
                                          onClick={() =>
                                            this.handlePaymentModeClick(
                                              planIndex,
                                              paymentTypeIndex,
                                              paymentModeIndex,
                                              paymentModePref.paymentMode
                                            )
                                          }
                                        >
                                          {paymentModePref.paymentMode}
                                        </div>
                                      )
                                    )
                                )}
                              </div>
                            )
                        )}
                      </div>
                      {showFreePlan && (
                        <div
                          className='pricing-plan-type-tab-panel'
                          value={pricingPlanType[0].value}
                        />
                      )}
                      {showOneTimePaymentPlan && (
                        <div
                          className='pricing-plan-type-tab-panel'
                          value={pricingPlanType[1].value}
                        />
                      )}
                      {showRecurringPaymentPlan && (
                        <div
                          className='pricing-plan-type-tab-panel tab_panel_body'
                          value={pricingPlanType[2].value}
                        >
                          <div className='panel__info-container'>
                            <div>
                              <img
                                src={Images.infoBgBlueIcon}
                                alt={i18next.t(
                                  'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                                )}
                                className='info-icon'
                              />
                            </div>
                            <div className='panel__payment-option-heading'>
                              {this.showTieredInfo &&
                                i18next.t(
                                  'DYNAMIC_FORM.PAYMENT_OPTIONS.USAGE_BASED'
                                )}
                              {this.showPayAsYouGoInfo &&
                                i18next.t(
                                  'DYNAMIC_FORM.PAYMENT_OPTIONS.PAY_AS_YOU_GO'
                                )}{' '}
                              -&nbsp;
                              <span className='panel__payment-option-heading-payment-option-description'>
                                {this.showTieredInfo &&
                                  i18next.t(
                                    'DYNAMIC_FORM.PRICING_PLAN.USAGE_IS_DIVIDED_INTO_TIERS'
                                  )}
                                {this.showPayAsYouGoInfo &&
                                  i18next.t(
                                    'DYNAMIC_FORM.PRICING_PLAN.CHARGES_BASED_ON_ACTUAL_USAGE'
                                  )}
                              </span>
                            </div>
                          </div>
                          <div
                            className='w__50-input-group-width'
                            style={{ marginTop: '25px' }}
                          >
                            <Dropdown
                              options={this.state.billingCycles?.map(
                                ({ id, displayName }) => ({
                                  label: displayName,
                                  id: id,
                                  name: displayName
                                })
                              )}
                              displayName={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.BILLING_CYCLE'
                              )}
                              name='billingCycleTye'
                              value={plan.billingCycleType}
                              handleChange={(event) =>
                                this.changeValueDropdown(event)
                              }
                            />
                            <div className='payment-option_description'>
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN.REFERS_TO_THE_FREQUENCY_OF_INVOICE_GENERATION_AND_PAYMENT'
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {showFreePolicyPlan && (
                        <div
                          className='pricing-plan-type-tab-panel tab_panel_body'
                          value={pricingPlanType[3].value}
                        >
                          <div className='panel__info-container'>
                            <div>
                              <img
                                src={Images.infoBgBlueIcon}
                                alt={i18next.t(
                                  'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                                )}
                                className='info-icon'
                              />
                            </div>
                            <div className='panel__payment-option-heading'>
                              {i18next.t('DYNAMIC_FORM.PAYMENT_OPTIONS.FREE_POLICY')}{' '}
                              -&nbsp;
                              <span className='panel__payment-option-heading-payment-option-description'>
                                {i18next.t(
                                  'DYNAMIC_FORM.PAYMENT_OPTIONS.FREE_POLICY_TEXT'
                                )}
                              </span>
                            </div>
                          </div>
                          <div className='input-currency-width'>
                            <div className='currency'>
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CURRENCY'
                              )}
                            </div>
                            <Dropdown
                              options={this.state.currencyDropdownOptions}
                              placeHolder={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CURRENCY_PLACEHOLDER'
                              )}
                              placeHolderDisabled={true}
                              displayName={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CURRENCY'
                              )}
                              name='currencyCode'
                              value={plan.currencyCode}
                              validation={{
                                rule: {
                                  required: {
                                    isRequired: true
                                  }
                                }
                              }}
                              handleChange={(event) =>
                                this.changeValueDropdown(event)
                              }
                            />
                            <div className='payment-option_description'>
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN.CURRENCY_TO_ACCEPT_PAYMENTS_FROM_BUYER'
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='w__50 marketplace_view'>
                    <div className='marketplace_view-preview-label'>
                      <h5>{i18next.t('SHARED.GENERIC_MARKETPLACE_PREVIEW')}</h5>
                    </div>
                    <div>
                      <PricingPlanPreview
                        plan={this.state.currentPlan}
                        priceSlabs={this.state.priceSlabs}
                      />
                    </div>
                    <div className='marketplace_view_p'>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN.THIS_IS_HOW_YOUR_PLAN_LOOKS_LIKE_TO_THE_END_USERS_IN_MARKETPLACE'
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.active == 2 && (
                <div className='pricing__plan__wrapper'>
                  <div className='wrapper__class'>
                    <div className='search'>
                      <StepIndicator
                        data={this.state.data}
                        size={'-small'}
                        customClass='pricing__plan__indicator'
                        active={this.state.active}
                        handleClick={this.handleIndicatorClick}
                      />
                    </div>
                    <div className='pricing__plan_indicator'>
                      <div className='sectionContainer'>
                        <div className='mb__15'>
                          <Dropdown
                            options={this.state.rateLimitTypes?.map(
                              ({ id, name, displayName }) => ({
                                label: displayName,
                                id: id,
                                name: name
                              })
                            )}
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CONSUMPTION_UNIT'
                            )}
                            name='rateLimitType'
                            value={plan.rateLimitType}
                            handleChange={(event) => this.changeValueDropdown(event)}
                          />
                          <div className='payment-option_description'>
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CONSUMPTION_UNIT_OF_THE_PRODUCT'
                            )}
                          </div>
                        </div>
                        <div className='mb__15'>
                          <Dropdown
                            options={this.props?.pricingPlanMasterData?.rate_limit_time_unit?.map(
                              ({ id, name, displayName }) => ({
                                label: displayName,
                                id: id,
                                name: name
                              })
                            )}
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA'
                            )}
                            name='quota'
                            value={plan.quota}
                            handleChange={(event) => this.changeValueDropdown(event)}
                          />
                          <div className='payment-option_description'>
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CONSUMPTION_QUOTA_CALCULATION_TIME_WINDOW'
                            )}
                          </div>
                        </div>
                        <div className='mb__15'>
                          <Dropdown
                            options={this.getChargeableUnit()}
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CHARGEABLE_UNIT'
                            )}
                            name='chargeableUnit'
                            value={plan?.chargeableUnit}
                            handleChange={(event) =>
                              this.handleChargableUnitChange(event)
                            }
                          />
                          <div className='payment-option_description'>
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.UNIT_OF_MEASURING_AND_METERING_THE_CONSUMPTION'
                            )}
                          </div>
                        </div>
                        <div className='mb__15'>
                          <Dropdown
                            options={this.consumptionTypesArray}
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CONSUMPTION_MODEL'
                            )}
                            name='consumption'
                            value={plan.consumption}
                            handleChange={(event) => this.changeValueDropdown(event)}
                          />
                          <div className='payment-option_description'>
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CONSUMPTION_MODEL_OF_THE_PRODUCT'
                            )}
                          </div>
                        </div>
                        <div className='mb__15'>
                          <Dropdown
                            options={this.state.currencyDropdownOptions}
                            displayName='Currency'
                            name='currencyCode'
                            value={plan.currencyCode}
                            handleChange={(event) => this.changeValueDropdown(event)}
                          />
                          <div className='payment-option_description'>
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.CURRENCY_TO_ACCEPT_PAYMENTS_FROM_BUYER'
                            )}
                          </div>
                        </div>
                        <div className='mb__15'>
                          <Dropdown
                            options={paymentOccurrenceTypes}
                            displayName={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.PAYMENT_OCCURENCE'
                            )}
                            name='paymentOccurrence'
                            value={paymentOccurrenceTypes.find(
                              (item) => item.name === this.paymentOccurrenceType
                            )}
                            handleChange={(event) => this.changeValueDropdown(event)}
                          />
                          <div className='payment-option_description'>
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.PAYMENT_OCCURRENCE_FOR_RECURRING_PAYMENT'
                            )}
                          </div>
                        </div>
                      </div>
                      <br />
                      <PricingSlabsTable
                        rateLimits={this.props?.pricingPlanMasterData?.rate_limit}
                        dataBandwidthChargableUnits={
                          this.props?.pricingPlanMasterData?.bandwidth
                        }
                        quotas={
                          this.props?.pricingPlanMasterData?.rate_limit_time_unit
                        }
                        currencies={this.props?.pricingPlanMasterData?.currency}
                        chargableUnitMasterData={
                          this.props?.pricingPlanMasterData?.chargeable_unit
                        }
                        currency={plan.currencyCode}
                        rateLimit={this.state.currentPlan.rateLimitType}
                        chargeableUnit={this.state.selectedChargeableUnit}
                        quota={this.state.currentPlan.quota}
                        slabLimitDetails={this.state.currentPlan.slabLimitDetails}
                        setTableValidation={this.setTableValidation}
                        setPricingSlabs={this.setPricingSlabs}
                      />
                      <hr />
                      <div className='heading-style -size-l highlight mb_1'>
                        {i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADVANCED_SETTINGS')}
                      </div>
                      <div className='pricing__plan_indicator-advanced-settings'>
                        <div className='advanced-settings'>
                          <div
                            className={`sectionContainer advanced-settings-details`}
                          >
                            <div className='advanced-settings-details-body'>
                              {this.props.prodBasicInfo?.productCategory
                                ?.categoryId === IdConstants.api.categoryId &&
                                this.state.pricingPlanTypeTabValue ===
                                  pricingPlanType[2].value &&
                                (this.state.currentPlan.rateLimitType ===
                                  IdConstants.pricing.reqCountLimitType ||
                                  this.state.currentPlan.rateLimitType ===
                                    IdConstants.pricing.bandWidthRateLimitType) && (
                                  <div className='advanced-settings-field'>
                                    <div className='advanced-settings-field-label'>
                                      <p className='highlight'>
                                        {i18next.t(
                                          'DYNAMIC_FORM.PRICING_PLAN.BURST_CONTROL'
                                        )}
                                      </p>
                                      <p>
                                        {i18next.t(
                                          'DYNAMIC_FORM.PRICING_PLAN.CONTROL_THE_INCOMING_SURGE_FROM_SINGLE_SOURCE'
                                        )}
                                      </p>
                                    </div>
                                    <div className='advanced-settings-field-switch'>
                                      <label className='toggle-switch'>
                                        <input
                                          type='checkbox'
                                          checked={plan.burstControl}
                                          onChange={(event) =>
                                            this.handleCheckBoxChange(event)
                                          }
                                          name='burstControl'
                                          disabled={disableFields}
                                        />
                                        <span className='switch' />
                                      </label>
                                    </div>
                                    <div className='advanced-settings-field-form'>
                                      <div className='advanced-settings-input-field'>
                                        <TextInput
                                          placeHolder={i18next.t(
                                            'DYNAMIC_FORM.PRICING_PLAN.ENTER_BURST_CONTROL_LIMIT'
                                          )}
                                          value={plan.burstControlLimitCount}
                                          name='burstControlLimitCount'
                                          maxLength='9'
                                          handleChange={(event) =>
                                            this.changeValue(event)
                                          }
                                          disabled={
                                            !plan.burstControl || disableFields
                                          }
                                          error={
                                            plan.burstControl &&
                                            validation.currentPlan
                                              .burstControlLimitCount.isInvalid
                                              ? validation.currentPlan
                                                  .burstControlLimitCount.message
                                              : burstControlError
                                          }
                                        />
                                      </div>

                                      <Tooltip
                                        title={plan?.burstTimeUnit?.displayName}
                                        placement='top'
                                      >
                                        <div className='advanced-settings-select-field'>
                                          <Dropdown
                                            displayName={i18next.t(
                                              'DYNAMIC_FORM.PRICING_PLAN.REQUEST'
                                            )}
                                            options={this.burstTimeUnitArray}
                                            disabled={
                                              !plan.burstControl || disableFields
                                            }
                                            name='burstTimeUnit'
                                            value={plan.burstTimeUnit}
                                            handleChange={(event) =>
                                              this.changeValueRequest(event)
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w__50 marketplace_view'>
                    <div className='marketplace_view-preview-label'>
                      <h5>{i18next.t('SHARED.GENERIC_MARKETPLACE_PREVIEW')}</h5>
                    </div>
                    <div>
                      <PricingPlanPreview
                        plan={this.state.currentPlan}
                        priceSlabs={this.state.priceSlabs}
                      />
                    </div>
                    <div className='marketplace_view_p'>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN.THIS_IS_HOW_YOUR_PLAN_LOOKS_LIKE_TO_THE_END_USERS_IN_MARKETPLACE'
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </SideBar>
      </React.Fragment>
    );
  }
}

export default PricingPlanDialog;
