import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import Button from '../../../Inputs/Button';
import variables from '../../../../../variables.module.scss';

const useStyles = {
  typoStyle: {
    float: 'right'
  },
  modalContentstyle: {
    fontFamily: variables.generalBoschFont
  },
  divStyle: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    fontFamily: variables.generalBoschFont,
    minWidth: '40rem'
  },
  headingStyling: {
    fontFamily: variables.generalBoschFont,
    fontSize: '1.6rem',
    fontWeight: '100'
  }
};

class SessionModal extends Component {
  constructor() {
    super();
    this.state = {
      open: true
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOk = () => {
    this.setState({ open: false });
    this.props.okOperation();
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          onClose={this.handleClose}
          disableBackdropClick={true}
        >
          <div className={classes.divStyle}>
            <Grid container>
              <Grid item xs={12}>
                <h2 id='simple-modal-title' className={classes.headingStyling}>
                  {this.props.heading}
                </h2>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='subtitle1'
                  className={classes.modalContentstyle}
                >
                  {this.props.modalContent}{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm>
                <Box mt={1} display='flex' justifyContent='flex-end'>
                  <Button variant='contained' label='OK' onClick={this.handleOk} />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(SessionModal);
