import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { PrimaryButton, SecondaryButton, TertiaryButton } from '../button';
import './index.scss';

const NavigationButtons = ({
  handlePrevious,
  nextStepDisabled,
  prevStepDisabled,
  handleNext,
  isShowPrevStepBtn,
  nextStepLabel,
  prevStepLabel,
  cancelStepLabel,
  isShowCancelBtn,
  handleClose,
  isAutosaved,
  isShowPreviewbtn,
  handlePreview,
  isPreviewDisabled,
  navigationMessage,
  enableSaveButton,
  saveDraft,
  isShowSaveBtn
}) => {
  return (
    <div className='navigation-buttons'>
      <div>
        {isShowCancelBtn && (
          <TertiaryButton label={cancelStepLabel} onClick={handleClose} />
        )}
      </div>
      {isAutosaved && (
        <div className='-size-m navigation-buttons__auto-save-text d-sm-none'>
          {navigationMessage}
        </div>
      )}

      <div className='navigation-buttons__right-wrapper'>
        {isShowPrevStepBtn && (
          <SecondaryButton
            label={prevStepLabel}
            onClick={handlePrevious}
            data-frok-action='back'
            disabled={prevStepDisabled}
          />
        )}
        {isShowPreviewbtn && (
          <PrimaryButton
            label={i18next.t('DYNAMIC_FORM.LABEL.PREVIEW')}
            onClick={handlePreview}
            data-frok-action='preview'
            disabled={isPreviewDisabled}
          />
        )}
        {nextStepLabel === 'Submit' && isShowSaveBtn && (
          <PrimaryButton
            label={i18next.t('DYNAMIC_FORM.LABEL.SAVE')}
            onClick={saveDraft}
            data-frok-action='next'
            disabled={enableSaveButton}
          />
        )}
        <PrimaryButton
          label={nextStepLabel}
          onClick={handleNext}
          data-frok-action='next'
          disabled={nextStepDisabled}
        />
      </div>
    </div>
  );
};

NavigationButtons.propTypes = {
  handlePrevious: PropTypes.func,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  nextStepDisabled: PropTypes.bool,
  prevStepDisabled: PropTypes.bool,
  isShowPrevStepBtn: PropTypes.bool,
  nextStepLabel: PropTypes.string,
  prevStepLabel: PropTypes.string,
  cancelStepLabel: PropTypes.string,
  isShowCancelBtn: PropTypes.bool,
  isAutosaved: PropTypes.bool,
  isShowPreviewbtn: PropTypes.bool,
  isPreviewDisabled: PropTypes.bool,
  isShowSaveBtn: PropTypes.bool
};

NavigationButtons.defaultProps = {
  nextStepDisabled: false,
  prevStepDisabled: false,
  isShowPrevStepBtn: true,
  nextStepLabel: 'Next Step',
  prevStepLabel: 'Previous Step',
  cancelStepLabel: 'Close',
  isShowCancelBtn: true,
  isAutosaved: false,
  isShowPreviewbtn: false,
  isPreviewDisabled: false,
  isShowSaveBtn: false
};

export default NavigationButtons;
