import React from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import stylesObject from './index.module.scss';

export const inlineStylesOptions = [
  {
    label: (
      <FormatBoldIcon className={stylesObject['rich-text-editor-style-icon']} />
    ),
    style: 'BOLD',
    itemType: 'inline',
    tooltipText: 'Bold'
  },
  {
    label: (
      <FormatItalicIcon className={stylesObject['rich-text-editor-style-icon']} />
    ),
    style: 'ITALIC',
    itemType: 'inline',
    tooltipText: 'Italic'
  },
  {
    label: (
      <FormatUnderlinedIcon
        className={stylesObject['rich-text-editor-style-icon']}
      />
    ),
    style: 'UNDERLINE',
    itemType: 'inline',
    tooltipText: 'Underline'
  },
  {
    label: (
      <FormatListBulletedIcon
        className={stylesObject['rich-text-editor-style-icon']}
      />
    ),
    style: 'unordered-list-item',
    itemType: 'block',
    tooltipText: 'Bullet list'
  },
  {
    label: (
      <FormatListNumberedIcon
        className={stylesObject['rich-text-editor-style-icon']}
      />
    ),
    style: 'ordered-list-item',
    itemType: 'block',
    tooltipText: 'Numbered list'
  }
];

export const headerStyleOptions = [
  {
    label: 'Paragraph',
    style: 'unstyled',
    value: 'Paragraph',
    itemType: 'block'
  },
  {
    label: (
      <h1
        className={`${stylesObject['header-style-menu']} ${stylesObject['header-one']}`}
      >
        Heading 1
      </h1>
    ),
    style: 'header-one',
    value: 'Heading 1',
    itemType: 'block'
  },
  {
    label: (
      <h2
        className={`${stylesObject['header-style-menu']} ${stylesObject['header-two']}`}
      >
        Heading 2
      </h2>
    ),
    style: 'header-two',
    value: 'Heading 2',
    itemType: 'block'
  },
  {
    label: (
      <h3
        className={`${stylesObject['header-style-menu']} ${stylesObject['header-three']}`}
      >
        Heading 3
      </h3>
    ),
    style: 'header-three',
    value: 'Heading 3',
    itemType: 'block'
  },
  {
    label: (
      <h4
        className={`${stylesObject['header-style-menu']} ${stylesObject['header-four']}`}
      >
        Heading 4
      </h4>
    ),
    style: 'header-four',
    value: 'Heading 4',
    itemType: 'block'
  },
  {
    label: (
      <h5
        className={`${stylesObject['header-style-menu']} ${stylesObject['header-five']}`}
      >
        Heading 5
      </h5>
    ),
    style: 'header-five',
    value: 'Heading 5',
    itemType: 'block'
  },
  {
    label: (
      <h6
        className={`${stylesObject['header-style-menu']} ${stylesObject['header-six']}`}
      >
        Heading 6
      </h6>
    ),
    style: 'header-six',
    value: 'Heading 6',
    itemType: 'block'
  }
];
