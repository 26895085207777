import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const theme = (theme) =>
  createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          background: '#EFEFF0 !important'
        },
        inputMarginDense: {
          background: '#EFEFF0',
          paddingTop: '14px',
          paddingBottom: '14px',
          paddingLeft: '13px',
          paddingRight: '13px'
        }
      }
    }
  });

function StyledTextField(props) {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        className={props.className}
        onBlur={props.onBlur}
        inputProps={props.inputProps}
        InputProps={props.InputProps}
        error={props.error}
        value={props.value}
        disabled={props.disabled}
        onInput={props.onInput}
        onChange={props.onChange}
        onPaste={props.onPaste}
        name={props.name}
        title={props.title}
        multiline={props.multiline}
        rows={props.rows}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        type={props.type}
        onKeyPress={props.onKeyPress}
        fullWidth={props.fullWidth}
        variant='filled'
        size='small'
      />
      <FormHelperText
        id='text-field-helper-text'
        style={{ marginTop: 'unset', color: 'red' }}
      >
        {props.helperText}
      </FormHelperText>
    </ThemeProvider>
  );
}

export default StyledTextField;
