import React, { Component } from 'react';
import Table from '../../../Shared/DataDisplay/Table';
import Modal from '../../../Shared/Utils/Modal/FormModal';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import Dialog from './endpointsDialog';
import Loader from '../../../Shared/Utils/Loader';
import SnackBar from '../../../Shared/Utils/SnackBar';
import '../add-api.scss';
import DialogTexts from '../../../../data/dialogText';
import ToastMessage from '../../../../data/toastMessages';
import IdConstants from '../../../../data/idConstants';
import {
  rowperpagemenu,
  endpointHeadings,
  apiMethods,
  groupHeading
} from '../../../../data/appConstants';
import { IS_MKOSINT_TENANT } from '../../../../actions/configuration';
import isEmpty from '../../../../validator/isEmpty';
import Dropdown from '../../../../components/Shared/Inputs/Dropdown';
import { formatEndpointDetails } from '../../../../shared/shared-function/formatEndpointDetails';
import './index.scss';
import {
  DialogBox,
  PrimaryButton,
  StepIndicator
} from '../../../../msreact-kit/packages/html-components';
import Images from '../../../../assets/img';
import i18next from 'i18next';

class Endpoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prodBasicInfo: '',
      prodDetailedInfo: {
        apiType: '',
        baseUrl: '',
        authorizationKey: '',
        authType: '',
        grantType: '',
        authTokenUrl: '',
        separator: '',
        scope: '',
        clientAuthentication: '',
        secretHeaderParamData: [],
        groupData: '',
        endpointsData: ''
      },
      saveAsDraft: true,
      openGroupDialog: false,
      openEndpointDialog: false,
      openConfirmModal: false,
      modalHeading: '',
      confirmModalText: '',
      deleteIndex: '',
      opensnackbar: true,
      modalForm: {
        category: 'group',
        name: '',
        description: '',
        index: '',
        isEdit: false,
        id: ''
      },
      endpointsModalForm: {
        category: 'endpoints',
        name: '',
        description: '',
        method: '',
        group: '',
        relativePath: '',
        index: '',
        isEdit: false,
        groupData: '',
        selectedGroup: '',
        id: '',
        chargeableStatusCode: ''
      },
      addSuccess: false,
      editSuccess: false,
      deleteSuccess: false,
      snackBarMsg: '',
      currentModal: '',
      errorText: '',
      error: false,
      type: '',
      openParamDialog: false,
      endPointRowIndex: '',
      paramCount: '',
      paramData: [],
      isEditParam: false,
      dataObject: '',
      setPage: '',
      page: 0,
      pageDataLength: 0,
      apiMethods: [],
      saveAsDraftSnackBar: false,
      endpointError: false,
      errMsg: '',
      isSwaggerSave: false,
      oldGroupData: [],
      oldEndpoints: [],
      downloadFileModalState: false,
      currentFormat: '',
      isEndpointLoaded: false,
      active: 0,
      size: 1,
      data: [
        {
          displayName: i18next.t('DYNAMIC_FORM.ADD_API.ENDPOINTS_GROUP'),
          activeItem: 0
        },
        {
          displayName: i18next.t('DYNAMIC_FORM.ADD_API.ENDPOINTS'),
          activeItem: 1
        }
      ],
      show: false,
      hide: true,
      tableData: []
    };
    this.changeOpen = this.changeOpen.bind(this);
    this.changeEndpointsOpen = this.changeEndpointsOpen.bind(this);
    this.changeOpenOpenParamDialog = this.changeOpenOpenParamDialog.bind(this);
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.changeEditValue = this.changeEditValue.bind(this);
    this.setParamData = this.setParamData.bind(this);
    this.changeState = this.changeState.bind(this);
    this.headerData = this.headerData.bind(this);
    this.queryData = this.queryData.bind(this);
    this.updateFormUrlEncoded = this.updateFormUrlEncoded.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.updateBody = this.updateBody.bind(this);
    this.updateRequestSample = this.updateRequestSample.bind(this);
    this.updateResponseSample = this.updateResponseSample.bind(this);
    this.paramCount = this.paramCount.bind(this);
    this.updateContentType = this.updateContentType.bind(this);
  }

  handleIndicatorClick = (activeItem) => {
    this.setState({
      active: activeItem,
      activeGrouping: activeItem
    });
  };

  modalHeading = 'Endpoint Group';
  modalEndpointsTitle = 'Endpoint';
  modalFields = ['Name', 'Description'];
  modelEndpointFields = [
    'Name',
    'Description',
    'Method',
    'Group',
    'RelativePath',
    'ChargeableStatusCode'
  ];

  prodDetailsParser = () => {
    if (
      (this.props.prodDetailedInfo.groupData === undefined ||
        this.props.prodDetailedInfo.groupData.length === 0) &&
      (this.props.prodDetailedInfo.endpointsData === undefined ||
        this.props.prodDetailedInfo.endpointsData.length === 0)
    ) {
      const details = formatEndpointDetails(
        this.props.location.state?.productDetails?.productDetailedInfo
      );
      if (!isEmpty(details.groupData) || !isEmpty(details.endpointsData))
        this.setState({ isSwaggerSave: true });
      return details;
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let prodBasicInfo = !isEmpty(this.props.prodBasicInfo)
      ? this.props.prodBasicInfo
      : {};
    let prodDetailedInfo = !isEmpty(this.props.prodDetailedInfo)
      ? this.props.prodDetailedInfo
      : {};
    let fileArray = this.props.files === undefined ? [] : this.props.files;
    if (
      this.props.location.state &&
      this.props.location.state.isSwagger &&
      this.props.swaggerProdDetailedInfo &&
      (this.props.prodDetailedInfo.groupData === undefined ||
        this.props.prodDetailedInfo.groupData.length === 0) &&
      (this.props.prodDetailedInfo.endpointsData === undefined ||
        this.props.prodDetailedInfo.endpointsData.length === 0)
    ) {
      prodDetailedInfo.groupData =
        this.props.swaggerProdDetailedInfo.groupData !== undefined
          ? this.props.swaggerProdDetailedInfo.groupData
          : [];
      prodDetailedInfo.endpointsData =
        this.props.swaggerProdDetailedInfo.endpointsData !== undefined
          ? this.props.swaggerProdDetailedInfo.endpointsData
          : [];
      this.setState({
        isSwaggerSave: true
      });
    }
    if (
      IS_MKOSINT_TENANT &&
      this.props.location.state?.productDetails?.productDetailedInfo
    ) {
      const formattedEndpointsDetails = this.prodDetailsParser(
        this.props.location.state?.productDetails?.productDetailedInfo
      );
      prodDetailedInfo.endpointsData =
        formattedEndpointsDetails?.endpointsData ?? prodDetailedInfo.endpointsData;
      prodDetailedInfo.groupData =
        formattedEndpointsDetails?.groupData ?? prodDetailedInfo.groupData;
    }
    if (prodDetailedInfo?.endpointsData !== undefined) {
      prodDetailedInfo.endpointsData.map((endpoint) => {
        let method = this.props.allMasterData?.apimethods?.find(function (element) {
          return element.id === endpoint.method;
        });
        if (method !== undefined) {
          endpoint.method = method.description;
        }
        return true;
      });
    }
    this.setState(
      {
        prodBasicInfo: prodBasicInfo,
        prodDetailedInfo: {
          apiType:
            prodDetailedInfo.apiType !== undefined ? prodDetailedInfo.apiType : '',
          baseUrl:
            prodDetailedInfo.baseUrl !== undefined ? prodDetailedInfo.baseUrl : '',
          authorizationKey:
            prodDetailedInfo.authorizationKey !== undefined
              ? prodDetailedInfo.authorizationKey
              : '',
          authType:
            prodDetailedInfo.authType !== undefined
              ? prodDetailedInfo.authType
              : IdConstants.noAuthTypeId,
          grantType:
            prodDetailedInfo.grantType !== undefined
              ? prodDetailedInfo.grantType
              : IdConstants.clientCredentialsId,
          authTokenUrl: !!prodDetailedInfo.authTokenUrl
            ? prodDetailedInfo.authTokenUrl
            : '',
          separator:
            prodDetailedInfo.separator !== undefined
              ? prodDetailedInfo.separator
              : '',
          scope: prodDetailedInfo.scope !== undefined ? prodDetailedInfo.scope : [],
          clientAuthentication: !!prodDetailedInfo.clientAuthentication
            ? prodDetailedInfo.clientAuthentication
            : 'Header',
          secretHeaderParamData:
            prodDetailedInfo.secretHeaderParamData !== undefined
              ? prodDetailedInfo.secretHeaderParamData
              : [],
          groupData:
            prodDetailedInfo.groupData !== undefined
              ? prodDetailedInfo.groupData
              : [],
          endpointsData:
            prodDetailedInfo.endpointsData !== undefined
              ? prodDetailedInfo.endpointsData
              : []
        },
        files: fileArray,
        saveAsDraft:
          prodBasicInfo && prodBasicInfo.status !== undefined
            ? prodBasicInfo.status.statusId === IdConstants.drafted.statusId
              ? true
              : false
            : true,
        apiMethods: this.props.allMasterData.apimethods,
        oldGroupData:
          prodDetailedInfo.groupData !== undefined ? prodDetailedInfo.groupData : [],
        oldEndpoints:
          prodDetailedInfo.endpointsData !== undefined
            ? prodDetailedInfo.endpointsData
            : []
      },
      function () {
        if (this.state.isSwaggerSave) {
          this.saveEndpointForSwagger();
        }
      }
    );
    this.props.handleButtonLabel();
    if (isEmpty(this.props.paramDataTypes)) {
      this.props.getParamDataTypes();
    }
    if (isEmpty(this.props.paramUrlOptions)) {
      this.props.getMappedParamUrlOptions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.prodEndErrorInfo !== null) {
      this.props.resetProdEndError();
      this.setState({
        errMsg: this.props.prodEndErrorInfo.message,
        endpointError: true
      });
    }
    if (
      !isEmpty(this.props.prodDetailedInfo) &&
      this.props.prodDetailedInfo !== prevProps.prodDetailedInfo
    ) {
      let prodBasicInfo = this.props.prodBasicInfo;
      let prodDetailedInfo = this.props.prodDetailedInfo;
      let fileArray = this.props.files === undefined ? [] : this.props.files;
      if (
        this.props.location.state &&
        this.props.location.state.isSwagger &&
        this.props.swaggerProdDetailedInfo &&
        (this.props.prodDetailedInfo.groupData === undefined ||
          this.props.prodDetailedInfo.groupData.length === 0) &&
        (this.props.prodDetailedInfo.endpointsData === undefined ||
          this.props.prodDetailedInfo.endpointsData.length === 0)
      ) {
        prodDetailedInfo.groupData =
          this.props.swaggerProdDetailedInfo.groupData !== undefined
            ? this.props.swaggerProdDetailedInfo.groupData
            : [];
        prodDetailedInfo.endpointsData =
          this.props.swaggerProdDetailedInfo.endpointsData !== undefined
            ? this.props.swaggerProdDetailedInfo.endpointsData
            : [];
        this.setState({
          isSwaggerSave: true
        });
      }
      if (
        IS_MKOSINT_TENANT &&
        this.props.location.state?.productDetails?.productDetailedInfo
      ) {
        const formattedEndpointsDetails = this.prodDetailsParser(
          this.props.location.state?.productDetails?.productDetailedInfo
        );
        prodDetailedInfo.endpointsData =
          formattedEndpointsDetails?.endpointsData ?? prodDetailedInfo.endpointsData;
        prodDetailedInfo.groupData =
          formattedEndpointsDetails?.groupData ?? prodDetailedInfo.groupData;
      }
      this.setState({
        prodBasicInfo: prodBasicInfo,
        prodDetailedInfo: {
          apiType:
            prodDetailedInfo.apiType !== undefined ? prodDetailedInfo.apiType : '',
          baseUrl:
            prodDetailedInfo.baseUrl !== undefined ? prodDetailedInfo.baseUrl : '',
          authorizationKey:
            prodDetailedInfo.authorizationKey !== undefined
              ? prodDetailedInfo.authorizationKey
              : '',
          authType:
            prodDetailedInfo.authType !== undefined
              ? prodDetailedInfo.authType
              : IdConstants.noAuthTypeId,
          grantType:
            prodDetailedInfo.grantType !== undefined
              ? prodDetailedInfo.grantType
              : IdConstants.clientCredentialsId,
          authTokenUrl: !!prodDetailedInfo.authTokenUrl
            ? prodDetailedInfo.authTokenUrl
            : '',
          separator:
            prodDetailedInfo.separator !== undefined
              ? prodDetailedInfo.separator
              : '',
          scope: prodDetailedInfo.scope !== undefined ? prodDetailedInfo.scope : [],
          clientAuthentication: !!prodDetailedInfo.clientAuthentication
            ? prodDetailedInfo.clientAuthentication
            : 'Header',
          secretHeaderParamData:
            prodDetailedInfo.secretHeaderParamData !== undefined
              ? prodDetailedInfo.secretHeaderParamData
              : [],
          groupData:
            prodDetailedInfo.groupData !== undefined
              ? prodDetailedInfo.groupData
              : [],
          endpointsData:
            prodDetailedInfo.endpointsData !== undefined
              ? prodDetailedInfo.endpointsData
              : []
        },
        files: fileArray,
        saveAsDraft:
          prodBasicInfo && prodBasicInfo.status !== undefined
            ? prodBasicInfo.status.statusId === IdConstants.drafted.statusId
              ? true
              : false
            : true,
        apiMethods: this.props.allMasterData.apimethods,
        oldGroupData:
          prodDetailedInfo.groupData !== undefined ? prodDetailedInfo.groupData : [],
        oldEndpoints:
          prodDetailedInfo.endpointsData !== undefined
            ? prodDetailedInfo.endpointsData
            : []
      });
      this.props.handleButtonLabel();
      if (isEmpty(this.props.paramDataTypes)) {
        this.props.getParamDataTypes();
      }
      if (isEmpty(this.props.paramUrlOptions)) {
        this.props.getMappedParamUrlOptions();
      }
    }
  }

  paramCount(length, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].paramCount = length;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  headerData(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].header = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  queryData(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].query = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  updateFormUrlEncoded(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].formUrlEncoded = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  updateFormData(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].formData = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  updateBody(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].body = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  updateRequestSample(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].requestSample = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  updateResponseSample(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].responseSample = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  updateContentType(data, index) {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    prodDetailedInfoData.endpointsData[index].contentType = data;
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
  }

  changeState() {
    this.setState({
      addSuccess: false,
      editSuccess: false,
      deleteSuccess: false,
      saveAsDraftSnackBar: false,
      endpointError: false
    });
  }

  changeOpen() {
    this.setState({ openGroupDialog: false });
  }

  changeEndpointsOpen() {
    this.setState({ openEndpointDialog: false });
  }

  changeEditValue() {
    this.setState({ isEditParam: false });
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      addSuccess: false,
      editSuccess: false,
      deleteSuccess: false,
      opensnackbar: false
    });
  };

  openModal = () => {
    this.setState({ openGroupDialog: true, currentModal: 'group' });
    this.setState({
      modalForm: {
        name: '',
        description: '',
        index: '',
        isEdit: false,
        category: 'group'
      }
    });
  };

  openEndPointsModal = () => {
    this.setState({ openEndpointDialog: true, currentModal: 'endpoint' });
    this.setState({
      endpointsModalForm: {
        name: '',
        group: '',
        method: '',
        description: '',
        paramaters: '',
        isEdit: false,
        category: 'endpoint',
        groupData: this.state.prodDetailedInfo.groupData,
        chargeableStatusCode: []
      }
    });
  };

  saveEndpointForSwagger = () => {
    this.props.saveGroupData(this.state).then(() => {
      if (!this.props.error) {
        this.props.saveEndpointData(this.state).then(() => {
          if (!this.props.error) {
            this.setState({
              saveAsDraftSnackBar: true,
              snackBarMsg: i18next.t(
                'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.GROUPS_AND_ENDPOINTS_DATA_SAVED_SUCCESSFULLY'
              ),
              isEndpointLoaded: true
            });
            this.props.updateInfo(this.props.info, 'isApiEdited', true);
          }
        });
      }
    });
  };

  handleEdit = (index, type, rowsperPage, page) => {
    if (type === 'group') {
      let dataIndex;
      if (page === 1) {
        dataIndex = index;
      } else {
        dataIndex = rowsperPage * (page - 1) + index;
      }
      this.setState({ openGroupDialog: true });
      let editData = this.state.prodDetailedInfo?.groupData?.filter(
        (row, j) => j === dataIndex
      );
      editData.map((event) =>
        this.setState({
          modalForm: {
            name: event.name,
            value: event.value,
            description: event.description,
            index: dataIndex,
            isEdit: true,
            type: event.type,
            category: 'group',
            id: event.id
          }
        })
      );
    }
    if (type === 'endpoint') {
      let dataIndex;
      if (page === 1) {
        dataIndex = index;
      } else {
        dataIndex = rowsperPage * (page - 1) + index;
      }
      this.setState({ openEndpointDialog: true });
      let editData = this.state.prodDetailedInfo?.endpointsData?.filter(
        (row, j) => j === dataIndex
      );
      let group = [];
      editData.map((event) => {
        if (!!event.group) {
          event.group.map((groupData) => {
            group.push({ id: groupData, name: groupData });
            return true;
          });
        }
        this.setState({
          endpointsModalForm: {
            name: event.name,
            description: event.description,
            method: event.method,
            index: dataIndex,
            isEdit: true,
            type: event.type,
            category: 'endpoint',
            relativePath: event.relativePath,
            groupData: this.state.prodDetailedInfo.groupData,
            selectedGroup: group,
            id: event.id,
            relativePathData: event.relativePathData,
            chargeableStatusCode: event.chargeableStatusCode
          }
        });
        return true;
      });
    }
  };

  openParamDialog = (index, paramData, rowsperPage, page) => {
    this.setState({ paramData: paramData, currentModal: 'param' });
    if (!!paramData) {
      this.setState({ isEditParam: true });
    }
    let dataIndex;
    if (page === 1) {
      dataIndex = index;
    } else {
      dataIndex = rowsperPage * (page - 1) + index;
    }
    this.setState({ endPointRowIndex: dataIndex });
    this.setState({ openParamDialog: true });
  };

  handleRemove = (dataObj, index, type, rowsperPage, page, pageData, setPage) => {
    let dataIndex;
    if (page === 1) {
      dataIndex = index;
    } else {
      dataIndex = rowsperPage * (page - 1) + index;
    }
    if (type === 'group') {
      let flag = false;
      this.state.prodDetailedInfo?.endpointsData?.map((endpointData) => {
        if (endpointData.group?.includes(dataObj.name)) {
          flag = true;
        }
        return true;
      });
      if (flag) {
        this.setState(
          {
            deleteIndex: dataIndex,
            type: type,
            dataObject: dataObj,
            pageDataLength: pageData.length,
            setPage: setPage,
            page: page
          },
          function () {
            this.setState({
              openConfirmModal: true,
              confirmModalText: DialogTexts.deleteAssociatedGroup.confirmModalText,
              modalHeading: DialogTexts.deleteAssociatedGroup.modalHeading
            });
          }
        );
      } else {
        this.setState(
          {
            deleteIndex: dataIndex,
            type: type,
            dataObject: dataObj,
            pageDataLength: pageData.length,
            setPage: setPage,
            page: page
          },
          function () {
            this.setState({
              openConfirmModal: true,
              confirmModalText: DialogTexts.deleteGroup.confirmModalText,
              modalHeading: DialogTexts.deleteGroup.modalHeading
            });
          }
        );
      }
    } else {
      this.setState(
        {
          deleteIndex: dataIndex,
          type: type,
          dataObject: dataObj,
          pageDataLength: pageData.length,
          setPage: setPage,
          page: page
        },
        function () {
          this.setState({
            openConfirmModal: true,
            confirmModalText: DialogTexts.deleteEndpoint.confirmModalText,
            modalHeading: DialogTexts.deleteEndpoint.modalHeading
          });
        }
      );
    }
  };

  handleOpenConfirmModal() {
    this.setState({ openConfirmModal: false });
  }

  changeOpenOpenParamDialog() {
    this.setState({ openParamDialog: false });
  }

  setParamData(data, index) {
    this.setState({ paramCount: data.length });
    this.setState({ paramData: data });
  }

  displaySnackBar = (data) => {
    if (data) {
      this.setState({
        editSuccess: true,
        deleteSuccess: false,
        addSuccess: false,
        snackBarMsg: ToastMessage.update.msg
      });
    } else {
      let snackBarMsg = ToastMessage.addEndpoint.msg;
      if (this.state.currentModal === 'group') {
        snackBarMsg = ToastMessage.addGroup.msg;
      } else if (this.state.currentModal === 'param') {
        snackBarMsg = ToastMessage.update.msg;
      }
      this.setState({
        addSuccess: true,
        deleteSuccess: false,
        editSuccess: false,
        snackBarMsg: snackBarMsg
      });
    }
  };

  okBtnAction = (type) => {
    if (
      this.state.confirmModalText === DialogTexts.deleteGroup.confirmModalText ||
      this.state.confirmModalText ===
        DialogTexts.deleteAssociatedGroup.confirmModalText ||
      DialogTexts.deleteEndpoint.confirmModalText
    ) {
      if (this.state.pageDataLength === 1 && this.state.page > 1) {
        this.state.setPage(this.state.page - 1);
      }
      if (type === 'group') {
        if (
          this.state.dataObject.id !== undefined &&
          this.state.dataObject.id !== null
        ) {
          this.props
            .deleteGroup(
              this.state.dataObject,
              this.props.prodBasicInfo.productId,
              this.props.prodDetailedInfo
            )
            .then(() => {
              if (!this.props.error && isEmpty(this.props.errorInfo)) {
                this.props.updateInfo(this.props.info, 'isApiEdited', true);
                this.setState({
                  prodDetailedInfo: {
                    ...this.state.prodDetailedInfo,
                    groupData: this.props.prodDetailedInfo.groupData,
                    endpointsData: this.props.prodDetailedInfo.endpointsData
                  }
                });
                this.props.updateProductStatus(
                  IdConstants.drafted.statusId,
                  this.props.prodBasicInfo.productId,
                  this.props
                );
                this.setState({
                  deleteSuccess: true,
                  addSuccess: false,
                  editSuccess: false,
                  snackBarMsg: ToastMessage.delete.msg
                });
              } else {
                if (!isEmpty(this.props.errorInfo)) {
                  this.setState(
                    {
                      endpointError: true,
                      errMsg: this.props.errorInfo.message
                    },
                    function () {
                      this.props.resetErrorInfo();
                    }
                  );
                }
              }
            });
        }
      } else if (type === 'endpoint') {
        if (
          this.state.dataObject.id !== undefined &&
          this.state.dataObject.id !== null
        ) {
          this.props
            .deleteEndpoint(
              this.state.dataObject,
              this.state.apiMethods,
              this.props.prodBasicInfo.productId,
              this.props.prodDetailedInfo,
              this.state.prodDetailedInfo.groupData
            )
            .then(() => {
              if (!this.props.error && isEmpty(this.props.errorInfo)) {
                this.setState({
                  prodDetailedInfo: {
                    ...this.state.prodDetailedInfo,
                    endpointsData: this.props.prodDetailedInfo.endpointsData
                  }
                });
                this.props.updateProductStatus(
                  IdConstants.drafted.statusId,
                  this.props.prodBasicInfo.productId,
                  this.props
                );
                this.setState({
                  deleteSuccess: true,
                  addSuccess: false,
                  editSuccess: false,
                  snackBarMsg: ToastMessage.delete.msg
                });
                this.props.updateInfo(this.props.info, 'isApiEdited', true);
              } else {
                if (!isEmpty(this.props.errorInfo)) {
                  this.setState(
                    {
                      endpointError: true,
                      errMsg: this.props.errorInfo.message
                    },
                    function () {
                      this.props.resetErrorInfo();
                    }
                  );
                }
              }
            });
        }
      }
      this.setState({ opensnackbar: true, openConfirmModal: false });
    }
  };

  populateTableData = (data) => {
    if (data[0].category === 'endpoint') {
      let method = this.props.allMasterData.apimethods.find(function (element) {
        return element.id === data[0].method;
      });
      if (data[0].isEdit) {
        let header = this.state.prodDetailedInfo.endpointsData[data[0].index].header;
        let query = this.state.prodDetailedInfo.endpointsData[data[0].index].query;
        let formData =
          this.state.prodDetailedInfo.endpointsData[data[0].index].formData;
        let formUrlEncoded =
          this.state.prodDetailedInfo.endpointsData[data[0].index].formUrlEncoded;
        let body = this.state.prodDetailedInfo.endpointsData[data[0].index].body;
        let paramCount =
          this.state.prodDetailedInfo.endpointsData[data[0].index].paramCount;
        let group = [];
        if (!!data[0].group) {
          data[0].group.map((groupData) => {
            group.push(groupData.name);
            return true;
          });
        }
        let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
        prodDetailedInfoData.endpointsData[data[0].index] = {
          name: data[0].name,
          description: data[0].description,
          method: method.name,
          group: group,
          relativePath: data[0].relativePath,
          id: data[0].id,
          header: header,
          query: query,
          formData: formData,
          formUrlEncoded: formUrlEncoded,
          body: body,
          paramCount: paramCount,
          chargeableStatusCode: data[0].chargeableStatusCode,
          relativePathData: data[0].relativePathData,
          requestSample:
            data[0].requestSample?.length > 0 ? data[0].requestSample : undefined,
          responseSample:
            data[0].responseSample?.length > 0 ? data[0].responseSample : undefined
        };
        this.setState({
          prodDetailedInfo: prodDetailedInfoData
        });
      } else {
        let group = [];
        if (!!data[0].group) {
          data[0].group.map((groupData) => {
            group.push(groupData.name);
            return true;
          });
        }
        this.state.prodDetailedInfo?.endpointsData?.push({
          name: data[0].name,
          description: data[0].description,
          method: method.name,
          group: group,
          relativePath: data[0].relativePath,
          parameters: 'Configure Parameter',
          relativePathData: data[0].relativePathData,
          chargeableStatusCode: data[0].chargeableStatusCode
        });
      }
    } else {
      if (data[0].isEdit) {
        let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
        prodDetailedInfoData.groupData[data[0].index] = {
          name: data[0].name,
          description: data[0].description,
          id: data[0].id
        };
        this.setState({
          prodDetailedInfo: prodDetailedInfoData
        });
        this.state.prodDetailedInfo?.endpointsData?.map((endpointData) => {
          if (endpointData?.group?.includes(data[0].oldName)) {
            endpointData?.group?.map((groupName, index) => {
              if (groupName === data[0].oldName) {
                endpointData.group[index] = data[0].name;
              }
              return true;
            });
          }
          return true;
        });
      } else {
        this.state.prodDetailedInfo?.groupData?.push({
          name: data[0].name,
          description: data[0].description
        });
      }
    }
  };

  saveEndpoint = (event) => {
    event.preventDefault();
    this.props.saveEndpointData(this.state).then(() => {
      if (!this.props.error && isEmpty(this.props.errorInfo)) {
        this.setState({
          saveAsDraftSnackBar: true
        });
        this.props.updateProductStatus(
          IdConstants.drafted.statusId,
          this.props.prodBasicInfo.productId,
          this.props
        );
        this.saveOldEndpointData(this.props.prodDetailedInfo.endpointsData);
        this.props.updateInfo(this.props.info, 'isApiEdited', true);
        this.setState({
          prodDetailedInfo: {
            ...this.props.prodDetailedInfo,
            endpointsData: this.props.prodDetailedInfo.endpointsData
          }
        });
      } else {
        if (!isEmpty(this.props.errorInfo)) {
          this.setState({
            errMsg: this.props.errorInfo.message,
            endpointError: true
          });
        }
        this.setState(
          {
            prodDetailedInfo: {
              ...this.state.prodDetailedInfo,
              endpointsData: this.state.oldEndpoints
            }
          },
          function () {
            this.updateReduxStorage();
            this.props.resetErrorInfo();
          }
        );
      }
    });
  };

  saveGroup = (event) => {
    event.preventDefault();
    this.props.saveGroupData(this.state).then(() => {
      if (!this.props.error && isEmpty(this.props.errorInfo)) {
        this.setState({
          saveAsDraftSnackBar: true
        });
        this.props.updateProductStatus(
          IdConstants.drafted.statusId,
          this.props.prodBasicInfo.productId,
          this.props
        );
        this.saveOldGroupData(this.props.prodDetailedInfo.groupData);
        this.props.updateInfo(this.props.info, 'isApiEdited', true);
      } else {
        if (!isEmpty(this.props.errorInfo)) {
          this.setState({
            errMsg: this.props.errorInfo.message,
            endpointError: true
          });
        }
        this.setState(
          {
            prodDetailedInfo: {
              ...this.state.prodDetailedInfo,
              groupData: this.state.oldGroupData
            }
          },
          function () {
            this.updateReduxStorage();
            this.props.resetErrorInfo();
          }
        );
      }
    });
  };

  updateReduxStorage = () => {
    this.props.reduxStorage(this.state, 'endpoint', 'prodDetailedInfo');
  };

  saveOldGroupData = (groups) => {
    let groupData = [];
    groups.map((group) => {
      if (group.hasOwnProperty('id')) {
        groupData.push(group);
      }
      return true;
    });
    this.setState({
      oldGroupData: groupData
    });
  };

  saveOldEndpointData = (endpoints) => {
    let endpointsData = [];
    endpoints.map((endpoint) => {
      if (endpoint.hasOwnProperty('id')) {
        endpointsData.push(endpoint);
      }
      return true;
    });
    this.setState({
      oldEndpoints: endpointsData
    });
  };

  updateEndpointsData = () => {
    this.setState({
      prodDetailedInfo: {
        ...this.state.prodDetailedInfo,
        endpointsData: this.props.prodDetailedInfo.endpointsData
      }
    });
  };

  updateFormat = (format) => {
    this.setState({ currentFormat: format });
  };

  closeDownloadFile = () => {
    this.setState({
      downloadFileModalState: false,
      currentFormat: ''
    });
  };

  openDownloadFile = () => {
    this.setState({
      downloadFileModalState: true,
      currentFormat: ''
    });
  };

  render() {
    if (this.props.activeGrouping === 1) {
      const disabled = !this.state.prodDetailedInfo.endpointsData.length;
      this.props.handleButtonState(disabled);
    } else {
      this.props.handleButtonState(false);
    }
    const editableAttributes = this.props.editableAttributes;
    let { downloadFileModalState } = this.state;
    let prodDetailedInfo = this.state.hasOwnProperty('prodDetailedInfo')
      ? this.state.prodDetailedInfo
      : {};
    let endpointsData = prodDetailedInfo.hasOwnProperty('endpointsData')
      ? prodDetailedInfo.endpointsData
      : [];
    let masterData = this.props.allMasterData?.apimethods
      ? this.props.allMasterData.apimethods
      : [];
    return (
      <div className='api_end'>
        {!this.props.loader.loader && this.state.saveAsDraftSnackBar && (
          <SnackBar
            severity='success'
            message={this.state.snackBarMsg}
            changeState={this.changeState}
          />
        )}
        {this.props.loader.loader && !this.state.isSwaggerSave && <Loader />}
        {this.state.isSwaggerSave && !this.state.isEndpointLoaded && <Loader />}
        <div className='api_endpoints'>
          <>
            <span
              className={
                endpointsData.length === 0
                  ? 'file_download_disabled'
                  : 'file_download'
              }
            >
              <span onClick={this.openDownloadFile} className='file_download-text'>
                {i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_SWAGGER_FILE')}{' '}
                <img
                  className='download-image-swagger'
                  src={Images.downloadIcon}
                  alt={i18next.t(
                    'DYNAMIC_FORM.ALTERNATE_TEXT.DOWNLOAD_IMAGE_SWAGGER'
                  )}
                />
              </span>
            </span>
            {endpointsData.length > 0 && downloadFileModalState && (
              <ConfirmModal
                heading={i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_THE_SWAGGER_FILE')}
                submitLabel={i18next.t('DYNAMIC_FORM.LABEL.DOWNLOAD')}
                okBtnAction={() => {
                  this.props.downloadFile(
                    this.state.currentFormat,
                    this.props.prodBasicInfo.productId
                  );
                }}
                dropdownComponent={<Dropdown updateFormat={this.updateFormat} />}
                callback={this.closeDownloadFile}
                closeModal='close_modal'
                modalHeaderStyles='modal_headerStyles'
                dialogPosition={'center'}
                currentFormat={this.state.currentFormat}
                dropdownWrapper='dropdown-wrapper'
              />
            )}
            <>
              <div className='search'>
                <StepIndicator
                  size='-small'
                  data={this.state.data}
                  active={this.props.activeGrouping}
                  customClass='api-step-indicator'
                  handleClick={this.handleIndicatorClick}
                />
              </div>
              {this.props.activeGrouping == 0 && (
                <div className='header__param__parent'>
                  <div className='heading-style -size-l highlight'>
                    {i18next.t('DYNAMIC_FORM.ADD_API.ENDPOINT_GROUP')}
                  </div>
                  <div className='subheading-style'>
                    {i18next.t(
                      'DYNAMIC_FORM.ADD_API.CREATE_GROUP_HERE_TO_ASSIGN_SIMILAR_ENDPOINTS'
                    )}
                  </div>
                  {!this?.props?.location?.state?.isView && (
                    <div className='mt__15'>
                      <PrimaryButton
                        label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')}
                        onClick={this.openModal}
                        disabled={
                          editableAttributes.productDetailedInfo_endpointGroups
                            .disabled
                        }
                      />
                    </div>
                  )}
                  {(() => {
                    if (this.state.openGroupDialog === true) {
                      return (
                        <Modal
                          callback={this.changeOpen}
                          width='8'
                          modalHeading={this.modalHeading}
                          modalFields={this.modalFields}
                          populateTableData={this.populateTableData}
                          modalForm={this.state.modalForm}
                          snackBar={this.displaySnackBar}
                          groupData={this.state.prodDetailedInfo.groupData}
                          apiMethod={masterData}
                          saveGroupData={this.saveGroup}
                          updateReduxStorage={this.updateReduxStorage}
                          saveOldGroupData={this.saveOldGroupData}
                          editableAttributes={editableAttributes}
                        />
                      );
                    }
                  })()}
                  {(() => {
                    if (!!this.state?.prodDetailedInfo?.groupData?.length) {
                      return (
                        <Table
                          type='group'
                          data={this.state.prodDetailedInfo.groupData.sort(
                            (firstItem, secondItem) =>
                              firstItem.index - secondItem.index
                          )}
                          rowperpagemenu={rowperpagemenu}
                          header={groupHeading}
                          handleRemove={this.handleRemove}
                          handleEdit={this.handleEdit}
                          {...this.props}
                          editableAttributes={editableAttributes}
                          customClass='api_endpoints_custom'
                        />
                      );
                    }
                  })()}
                </div>
              )}
              {this.props.activeGrouping == 1 && (
                <>
                  <div
                    style={{ marginTop: '1rem' }}
                    className='header__param__parent'
                  >
                    <div className='heading-style -size-l highlight'>
                      {i18next.t('DYNAMIC_FORM.ADD_API.ENDPOINTS')}{' '}
                      <span className='mandatory__symbol'>*</span>
                    </div>
                    <div className='subheading-style'>
                      {i18next.t('DYNAMIC_FORM.ADD_API.ADD_ENDPOINTS_TO_YOUR_API')}
                    </div>
                    <div className='note-style'>
                      {i18next.t(
                        'DYNAMIC_FORM.ADD_API.AT_LEAST_ONE_ENDPOINT_MUST_BE_ADDED'
                      )}
                    </div>
                  </div>
                  <>
                    {!this?.props?.location?.state?.isView && (
                      <div className='mt__15'>
                        <PrimaryButton
                          label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')}
                          onClick={this.openEndPointsModal}
                          disabled={
                            editableAttributes.productDetailedInfo_endPointDetails
                              .disabled
                          }
                        />
                      </div>
                    )}
                    {(() => {
                      if (this.state.openEndpointDialog === true) {
                        return (
                          <Modal
                            callback={this.changeEndpointsOpen}
                            width='8'
                            apiMethodData={masterData}
                            modalHeading={this.modalEndpointsTitle}
                            modalFields={this.modelEndpointFields}
                            populateTableData={this.populateTableData}
                            modalForm={this.state.endpointsModalForm}
                            snackBar={this.displaySnackBar}
                            endpointData={this.state.prodDetailedInfo.endpointsData}
                            apiMethod={masterData}
                            saveEndpointData={this.saveEndpoint}
                            updateReduxStorage={this.updateReduxStorage}
                            saveOldEndpointData={this.saveOldEndpointData}
                            editableAttributes={editableAttributes}
                          ></Modal>
                        );
                      }
                    })()}
                  </>
                  <div style={{ marginTop: '1rem' }}>
                    {(() => {
                      if (!!this.state.prodDetailedInfo.endpointsData.length) {
                        this.state.prodDetailedInfo.endpointsData.map((endpoint) => {
                          if (!apiMethods.includes(endpoint.method)) {
                            if (masterData.length > 0) {
                              endpoint.method = masterData.find(function (item) {
                                return item.id === endpoint.method;
                              }).name;
                            }
                          }
                          return true;
                        });
                        return (
                          <Table
                            type='endpoint'
                            data={this.state.prodDetailedInfo.endpointsData.sort(
                              (firstItem, secondItem) =>
                                firstItem.index - secondItem.index
                            )}
                            rowperpagemenu={rowperpagemenu}
                            header={endpointHeadings}
                            handleRemove={this.handleRemove}
                            handleEdit={this.handleEdit}
                            callback={this.openParamDialog}
                            {...this.props}
                            editableAttributes={editableAttributes}
                            customClass='api_endpoints_custom'
                          />
                        );
                      }
                    })()}
                  </div>
                </>
              )}
            </>
          </>
          <div />
          {(() => {
            if (this.state.openConfirmModal === true) {
              return (
                <DialogBox
                  title={this.state.modalHeading}
                  subTitle={this.state.confirmModalText}
                  isModal={true}
                  isSuccess={false}
                  handleConfirm={() => this.okBtnAction(this.state.type)}
                  handleCancel={this.handleOpenConfirmModal}
                />
              );
            }
            if (this.state.deleteSuccess) {
              return (
                <SnackBar
                  severity='success'
                  message={this.state.snackBarMsg}
                  changeState={this.changeState}
                />
              );
            }
          })()}
          {(() => {
            if (this.state.endpointError) {
              return (
                <SnackBar
                  severity='error'
                  message={this.state.errMsg}
                  changeState={this.changeState}
                />
              );
            }
            if (this.state.openParamDialog) {
              let endpoint =
                this.state.prodDetailedInfo.endpointsData[
                  this.state.endPointRowIndex
                ];
              let parameterOptions = [];
              let index = masterData.findIndex(
                (method) => method.name === endpoint.method
              );
              if (index !== -1) {
                let methodId = masterData[index].id;
                parameterOptions = this.props.paramUrlOptions[methodId];
              } else {
                parameterOptions = this.props.paramUrlOptions[endpoint.method];
              }
              return (
                <Dialog
                  callback={this.changeOpenOpenParamDialog}
                  paramData={this.setParamData}
                  index={this.state.endPointRowIndex}
                  isEdit={this.state.isEditParam}
                  paramDataValues={this.state.paramData}
                  changeEditValue={this.changeEditValue}
                  endpointData={this.state.prodDetailedInfo.endpointsData}
                  headerData={this.headerData}
                  queryData={this.queryData}
                  updateFormUrlEncoded={this.updateFormUrlEncoded}
                  updateFormData={this.updateFormData}
                  updateBody={this.updateBody}
                  updateRequestSample={this.updateRequestSample}
                  updateResponseSample={this.updateResponseSample}
                  updateContentType={this.updateContentType}
                  paramCount={this.paramCount}
                  deleteParam={this.props}
                  submitData={this.saveEndpoint}
                  snackBar={this.displaySnackBar}
                  contentTypeData={this.props.allMasterData.contentType}
                  paramDataTypes={this.props.paramDataTypes}
                  parameterOptions={parameterOptions}
                  updateEndpointsData={this.updateEndpointsData}
                  editableAttributes={editableAttributes}
                  disableFields={this?.props?.location?.state?.isView}
                />
              );
            }
          })()}
        </div>
      </div>
    );
  }
}
export default Endpoint;
