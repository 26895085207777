import React, { Component, createRef } from 'react';
import i18next from 'i18next';
import Loader from '../../components/Shared/Utils/Loader';
import Images from '../../assets/img';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTop from '../../components/Shared/scroll-to-top';
import IdConstants from '../../data/idConstants';
import NoResultDisplay from '../../components/Shared/no-result-display';
import DisplayOptions from '../../data/displayOptions';
import ProductListView from '../MyProductContainer/product-list-view';
import { toLowercaseReplaceSpace } from '../../shared/shared-function/helperFunctions';
import {
  PrimaryButton,
  Filter,
  Search
} from '../../msreact-kit/packages/html-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPricingPlanMasterData } from '../../actions/MasterDataAction';
import { IS_MKOSINT_TENANT } from '../../actions/configuration';
import SidebarContentWrapper from '../../components/BaseLayout/SidebarContainer';
import Footer from '../../components/BaseLayout/footer';
import AddApiDialog from '../../components/api/MyAPI/addApiDialog';
import CustomToolTip from '../../components/Shared/custom-tooltip';
import AutoMotiveCreateOptions from '../auto-spares-container/AutoMotiveCreateOptions';
import Snackbar from '../../components/Shared/Utils/SnackBar';
import { handleShowMessage } from '../../actions/api/pricing-plan-action';
import DialogTexts from '../../data/dialogText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { accessRoles } from '../../data/appConstants';
import './index.scss';

const AddFirstCategoryProduct = (props) => {
  return (
    <div className='first-hardware'>
      <div className='first-hardware_content'>
        <img
          src={Images.maleWorkingOnComputer}
          className={
            props.salesRepRole !== accessRoles.seller
              ? 'first-hardware_content_sales-rep-element'
              : 'first-hardware_content_element'
          }
          alt={`${i18next.t('DYNAMIC_FORM.UNIFIED_CATEGORY.ADD_YOUR_FIRST')} ${
            props.productCategoryName
          }`}
        />
        <div className='first-hardware_content_text-adjustment'>
          <p className='highlight'>
            {i18next.t('DYNAMIC_FORM.UNIFIED_CATEGORY.ADD_YOUR_FIRST')}{' '}
            {props.productCategoryName}
          </p>
          <p className='text'>
            {i18next.t('DYNAMIC_FORM.UNIFIED_CATEGORY.COLLECTIVE_LIST')}{' '}
            {props.productCategoryName}{' '}
            {i18next.t('DYNAMIC_FORM.UNIFIED_CATEGORY.HERE')}
          </p>
        </div>
        <div className='first-hardware_content_button'>
          <PrimaryButton
            label={`+ ${i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')} ${
              props.productCategoryName
            }`}
            onClick={() => props.handleButtonClick()}
          />
        </div>
      </div>
    </div>
  );
};

class UnifiedCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      successAlert: false,
      searchText: '',
      message: '',
      status: '',
      alert: false,
      currentPage: 1,
      dialogOpen: false,
      pageNumber: 0,
      productCount: 0,
      filterProducts: [],
      selectedOption: DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.dynamicProducts.title
      ).filters[0],
      openInfoModal: false,
      loaderState: true,
      activeCategory: null,
      isSearchBarActive: false,
      snackbar: {}
    };
    this.searchRef = createRef();
  }

  setProductCount = (response, id) => {
    let productCount = 0;
    response &&
      response.forEach((item) => {
        if (id === item.statusId) {
          productCount = item.productCount;
        } else if (id === 'All') {
          productCount += item.productCount;
        }
      });
    this.setState({ productCount: productCount });
  };

  getProduct = () => {
    const categoryName = this.props.match.params.name;
    if (this.props?.categoryList?.content) {
      const findCategory = this.props?.categoryList?.content.find(
        (category) =>
          toLowercaseReplaceSpace(category.productCategoryName) === categoryName
      );

      this.props.getProductsByStatus(
        this.state.selectedOption.id,
        '',
        0,
        15,
        [],
        findCategory.productCategoryId
      );
      this.props
        .getProductsCount(
          this.state.selectedOption.id,
          '',
          false,
          findCategory.productCategoryId
        )
        .then((response) => {
          this.setProductsCount('count', false);
          this.setProductsCount('searchCount', false);
          this.setProductCount(response, 'All');
        });

      const filterProducts = DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.dynamicProducts.title
      ).filters.map((option) => {
        let data = {
          label: option.title,
          id: option.id,
          isSelected: option.value
        };
        return data;
      });

      this.setState({
        filterProducts: filterProducts,
        activeCategory: findCategory
      });

      DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.dynamicProducts.title
      ).filters.forEach((option) => {
        if (this.state.filterProducts.id === option.id) {
          this.setState(
            {
              selectedIndex: option.index,
              selectedOption: DisplayOptions.find(
                (option) =>
                  option.productType ===
                  IdConstants.sidebarConstants.dynamicProducts.title
              ).filters[option.index]
            },
            () => {
              this.filterCardStatus(this.state.selectedOption);
            }
          );
        }
      });
    }
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      currentPage: 1
    });
  };

  setCurrentPage = (page) => {
    this.setState({
      currentPage: page
    });
    if (page !== 1) {
      this.setState({
        dialogOpen: true
      });
    }
  };

  handleAddButton = () => {
    this.setCurrentPage(2);
  };

  componentDidMount() {
    this.getProduct();
    const filterProducts = DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.dynamicProducts.title
    ).filters.map((option) => {
      let data = {
        label: option.title,
        id: option.id,
        isSelected: option.status === 'ALL'
      };
      return data;
    });

    this.setState({
      filterProducts: filterProducts
    });

    DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.dynamicProducts.title
    ).filters.forEach((option) => {
      if (this.state.filterProducts.id === option.id) {
        this.setState(
          {
            selectedIndex: option.index,
            selectedOption: DisplayOptions.find(
              (option) =>
                option.productType ===
                IdConstants.sidebarConstants.dynamicProducts.title
            ).filters[option.index]
          },
          () => {
            this.filterCardStatus(this.state.selectedOption);
          }
        );
      }
    });
    if (this?.props?.isShowSuccessMsg) {
      this.setState({
        snackbar: {
          isShow: true,
          snackBarType: 'success',
          snackBarMsg: i18next.t(
            'DYNAMIC_FORM.PRICING_PLAN.DETAILS_HAS_BEEN_UPDATED_SUCCESSFULLY'
          )
        }
      });
    }

    document.body.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      loaderState:
        props.allProductsCallLoade || props.allProductsCallLoade === undefined
          ? false
          : true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.allProductsCount !== prevProps.allProductsCount) {
      this.setProductsCount('count', false);
      this.setProductsCount('searchCount', false);
    }

    if (
      prevProps.match.params.name !== this.props.match.params.name ||
      prevProps?.categoryList !== this.props?.categoryList
    ) {
      this.props.resetProducts();
      this.getProduct();
    }

    if (
      prevState?.activeCategory?.productCategoryId !==
      this.state?.activeCategory?.productCategoryId
    ) {
      this.props.getPricingPlanMasterData(
        this.state?.activeCategory?.productCategoryId
      );
    }

    if (this?.props?.isShowSuccessMsg) {
      this.props.handleShowMessage(false);
    }
  }

  filterCardStatus = (option) => {
    const filtersUpdated = DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.dynamicProducts.title
    ).filters.map((item) => {
      let data = {
        label: item.title,
        id: item.id,
        isSelected: option.name === item.id
      };
      return data;
    });

    this.setState({
      selectedOption: { ...option, id: option.name },
      selectedIndex: option.index,
      searchText: '',
      filterProducts: filtersUpdated
    });

    this.props.getProductsByStatus(
      option.value ? option.name : 'All',
      '',
      0,
      15,
      [],
      this.state.activeCategory.productCategoryId
    );
    this.props
      .getProductsCount(
        option.value ? option.name : 'All',
        '',
        false,
        this.state.activeCategory.productCategoryId
      )
      .then((response) => {
        this.setProductsCount('count', false);
        this.setProductsCount('searchCount', false);
        this.setProductCount(response, option.value ? option.name : 'All');
      });
  };

  setSearchText = (text) => {
    this.setState({ searchText: text }, function () {
      this.props.getProductsByStatus(
        this.state.selectedOption.id,
        this.state.searchText,
        0,
        15,
        [],
        this.state.activeCategory.productCategoryId
      );
      this.props
        .getProductsCount(
          this.state.selectedOption.id,
          this.state.searchText,
          true,
          this.state.activeCategory.productCategoryId
        )
        .then((response) => {
          this.setProductsCount('searchCount', true);
          this.setProductCount(
            response,
            this.state.selectedOption.id ? this.state.selectedOption.id : 'All'
          );
        });
    });
  };

  setProductsCount = (key, isSearch) => {
    let totalProducts = 0;
    let countArray = this.props.allProductsCount;
    if (isSearch) {
      countArray = this.props.productsCount;
    }
    DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.dynamicProducts.title
    ).filters.forEach((option) => {
      let statusObj = countArray.find(function (status) {
        return status.statusId === option.id;
      });
      if (statusObj) {
        totalProducts = totalProducts + statusObj.productCount;
        option[key] = statusObj.productCount;
      } else {
        option[key] = 0;
      }
    });

    DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.dynamicProducts.title
    ).filters[0][key] = totalProducts;
  };

  goToCreatePage = () => {
    this.props.history.push({
      pathname: '/product/create',
      state: {
        productCategoryId: this.state.activeCategory.productCategoryId,
        redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
          this.state?.activeCategory?.productCategoryName
        )}`,
        productCategoryName: this.state?.activeCategory?.productCategoryName
      }
    });
  };

  handleUpdateProducts = () => {
    this.filterCardStatus(
      DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.dynamicProducts.title
      ).filters[0]
    );
  };

  handleSearchBarResponsive = () => {
    this.setState({
      isSearchBarActive: !this.state.isSearchBarActive
    });
  };

  handleClickOutside = (event) => {
    if (
      this.searchRef.current &&
      this.state.isSearchBarActive &&
      !this.searchRef.current.contains(event.target)
    ) {
      this.handleSearchBarResponsive();
    }
  };

  handleSnackbar = () => {
    this.setState({
      snackbar: {}
    });
  };

  handleArrowClick = () => {
    this.props.history.push('/dashboard');
  };

  handleDeleteProduct = (data) => {
    this.props
      .deleteProduct(data, this.state.selectedOption.id, this.state.searchText)
      .then((error) => {
        if (!error) {
          this.props
            .getProductsCount(
              this.state.selectedOption.id,
              '',
              false,
              this.state?.activeCategory?.productCategoryId
            )
            .then((response) => {
              this.setProductsCount('count', false);
              this.setProductsCount('searchCount', false);
              this.setProductCount(response, 'All');
            });
          if (
            this.props.deleteProdData &&
            !this.props.error &&
            !this.props.deleteProdConsumed
          ) {
            this.setState({
              snackbar: {
                isShow: true,
                snackBarType: 'success',
                snackBarMsg: i18next.t(
                  'DYNAMIC_FORM.MY_PRODUCTS.PRODUCT_HAS_BEEN_DELETED_SUCCESSFULLY'
                )
              }
            });
          }
        } else {
          this.setState({
            snackbar: {
              isShow: true,
              snackBarType: 'error',
              snackBarMsg:
                error.data.error === 'E000489'
                  ? DialogTexts.consumedApiInformation.confirmModalText
                  : error.data.message
            }
          });
        }
      });
  };

  getCurrentPageContent() {
    let allProducts = this.props.allProducts;
    const salesRepRole = localStorage.getItem(IdConstants.userLoginRole.role);
    switch (this.state.currentPage) {
      case 1:
        return (
          <SidebarContentWrapper>
            {this.state.snackbar.isShow && (
              <Snackbar
                severity={this.state.snackbar.snackBarType}
                message={this.state.snackbar.snackBarMsg}
                changeState={this.handleSnackbar}
              />
            )}
            <div className='hardware-field'>
              {salesRepRole !== accessRoles.seller &&
                this.props?.user?.roles?.includes(accessRoles.sales_rep) && (
                  <>
                    <div className='sales-rep-back-container'>
                      <div
                        className='sales-rep-back-nav-icon'
                        onClick={this.handleArrowClick}
                      >
                        <ArrowBackIcon />
                        <div className='sales-rep-back-nav-text'>
                          {localStorage.getItem(IdConstants.sellerName)}
                          {i18next.t('DYNAMIC_FORM.SHARED.SELLER_ACCOUNT')}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='-size-m highlight background-color-pending-status'>
                        <i className='a-icon boschicon-bosch-ic-alert-info warning-icon'></i>
                        {i18next.t('DYNAMIC_FORM.SHARED.YOU_ARE_MANAGING')}{' '}
                        {localStorage.getItem(IdConstants.sellerName)}
                        {i18next.t('DYNAMIC_FORM.SHARED.ACCOUNT')}
                      </div>
                    </div>
                  </>
                )}
              <div className='hardware-field_items'>
                <div className='hardware-field_items_text'>
                  {this.state?.activeCategory?.productCategoryName && (
                    <>
                      <CustomToolTip
                        id='category-name'
                        tooltipData={`${i18next.t(
                          'DYNAMIC_FORM.UNIFIED_CATEGORY.MY'
                        )} ${
                          this.state?.activeCategory?.productCategoryName
                        } (${this.state.productCount})`}
                        width='dynamic'
                        className='hardware-field__category-name-tooltip-wrapper'
                      >
                        <p
                          id='category-name'
                          className='highlight -size-l text-ellipsis hardware-field__category-name-tooltip'
                        >
                          {`${i18next.t('DYNAMIC_FORM.UNIFIED_CATEGORY.MY')} ${
                            this.state?.activeCategory?.productCategoryName
                          } (${this.state.productCount})`}
                        </p>
                      </CustomToolTip>
                    </>
                  )}
                </div>
                <div className='hardware-field_items_buttons'>
                  <div className='hardware-field_items_buttons_search'>
                    {(() => {
                      if (
                        DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.dynamicProducts.title
                        ).filters[0].count !== 0
                      ) {
                        return (
                          <div className='search-bar' ref={this.searchRef}>
                            <div
                              id='search-bar'
                              className={
                                this.state.isSearchBarActive
                                  ? 'searchbar-show'
                                  : 'searchbar-hide'
                              }
                            >
                              <Search
                                placeholder={`${i18next.t('DYNAMIC_FORM.SHARED.SEARCH_FOR')} ${this.state.activeCategory?.productCategoryName ?? ''}...`}
                                onSearch={this.setSearchText}
                                title={`Search ${this.state.activeCategory?.productCategoryName}`}
                              />
                            </div>
                            <div className='searchbar-icon'>
                              <div onClick={this.handleSearchBarResponsive}>
                                {!this.state.isSearchBarActive && (
                                  <i
                                    className='a-icon ui-ic-search'
                                    title='search'
                                  ></i>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>
                  <Filter
                    data={this.state.filterProducts}
                    handleFilter={this.filterCardStatus}
                  />
                  {this?.state?.activeCategory?.productCategoryId ===
                  IdConstants.automotiveSpares.categoryId ? (
                    <AutoMotiveCreateOptions />
                  ) : (
                    <>
                      <div className='hardware-field_items_buttons_product d-sm-none'>
                        <CustomToolTip
                          id='add-product'
                          tooltipData={`+ ${i18next.t(
                            'DYNAMIC_FORM.SHARED.BUTTON.ADD'
                          )} ${this.state.activeCategory?.productCategoryName}`}
                          width='dynamic'
                          className='hardware-field__add-product-tooltip-wrapper'
                        >
                          <PrimaryButton
                            label=''
                            onClick={() => {
                              IS_MKOSINT_TENANT
                                ? this.handleAddButton()
                                : this.goToCreatePage();
                            }}
                          >
                            <p
                              id='add-product'
                              className='text-ellipsis hardware-field__tooltip-add-product'
                            >
                              {`+ ${i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')} ${
                                this.state.activeCategory?.productCategoryName ?? ''
                              }`}
                            </p>
                          </PrimaryButton>
                        </CustomToolTip>
                      </div>
                      <div className='hardware-field_items_buttons_product d-lg-none hardware-field__add-btn'>
                        <PrimaryButton
                          label='+'
                          onClick={() => {
                            IS_MKOSINT_TENANT
                              ? this.handleAddButton()
                              : this.goToCreatePage();
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <hr className='hardware-field_line-border' />
              <div
                className={
                  this.state.isSearchBarActive
                    ? 'hardware-field_searchbar_open'
                    : 'hardware-field_cards'
                }
              >
                {DisplayOptions.find(
                  (option) =>
                    option.productType ===
                    IdConstants.sidebarConstants.dynamicProducts.title
                ).filters[0].count === 0 &&
                  !this.props.loader.loader && (
                    <>
                      <AddFirstCategoryProduct
                        handleButtonClick={() => {
                          IS_MKOSINT_TENANT
                            ? this.handleAddButton()
                            : this.goToCreatePage();
                        }}
                        productCategoryName={
                          this.state.activeCategory?.productCategoryName
                        }
                        salesRepRole={salesRepRole}
                      />
                      <Footer customClassName='d-lg-none' />
                    </>
                  )}
                {allProducts.length !== 0 ? (
                  <div
                    className={`hardware-field_cards_scroll ${salesRepRole === accessRoles.sales_rep ? 'hardware-field_cards_scroll_sales-rep-height' : 'hardware-field_cards_scroll_seller-height'}`}
                    id='infinite-scroll'
                  >
                    <InfiniteScroll
                      dataLength={this.props.allProducts.length}
                      next={this.fetchMoreData}
                      hasMore={
                        parseInt(this.props.responseHeaders['current-page-num']) !==
                        this.props.responseHeaders['total-page-num'] - 1
                      }
                      style={{ overflow: 'none' }}
                      scrollableTarget='infinite-scroll'
                    >
                      <ProductListView
                        data={this.props.allProducts}
                        path='hardware'
                        handlePublish={this.props.publishProduct}
                        location={this.props.location}
                        history={this.props.history}
                        getProductQuantity={this.props.getProductQuantity}
                        handleUpdateProducts={this.handleUpdateProducts}
                        rootProductCategoryId={
                          this.state.activeCategory?.rootProductCategoryId
                        }
                        productCategoryId={
                          this.state.activeCategory?.productCategoryId
                        }
                        handleDeleteProduct={this.handleDeleteProduct}
                        {...this.props}
                      />
                    </InfiniteScroll>
                    <ScrollToTop fromAPI={true} />
                    <Footer customClassName='d-lg-none' />
                  </div>
                ) : this.state.loaderState ? (
                  <Loader />
                ) : (
                  DisplayOptions.find(
                    (option) =>
                      option.productType ===
                      IdConstants.sidebarConstants.dynamicProducts.title
                  ).filters[0].count !== 0 &&
                  this.props.allProductsCallLoaded &&
                  !this.props.loader.loader &&
                  allProducts.length === 0 && (
                    <>
                      <NoResultDisplay />
                      <Footer customClassName='d-lg-none' />
                    </>
                  )
                )}
              </div>
            </div>
          </SidebarContentWrapper>
        );
      case 2:
        return (
          <AddApiDialog
            dialogOpen={this.state.dialogOpen}
            currentPage={this.state.currentPage}
            category={this.state.activeCategory}
            closeDialog={this.handleClose}
            {...this.props}
          />
        );
      default:
        return null;
    }
  }

  fetchMoreData = () => {
    let page = this.state.pageNumber + 1;
    this.props.getProductsByStatus(
      this.state.selectedOption.id,
      this.state.searchText,
      page,
      15,
      this.props.allProducts,
      this.state.activeCategory.productCategoryId
    );
    this.setState({
      pageNumber: page
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.loader.loader ? <Loader /> : null}
        {this.getCurrentPageContent()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  pricingPlanMasterData: redux_state.MasterDataReducer.pricingPlanMasterData,
  isShowSuccessMsg: redux_state.PricingPlanReducer.isShowSuccessMsg,
  user: redux_state.loginReducer.user
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getPricingPlanMasterData, handleShowMessage },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UnifiedCategory);
