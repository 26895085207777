import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const VerticleStepper = (props) => {
  const { stepperOption, activeCategory, setActiveCategory, title } = props;

  const handleActiveClass = (index) => {
    let clsName = '';
    if (activeCategory === index) {
      clsName = 'active';
    }
    if (activeCategory < index) {
      clsName = 'inprogress';
    }
    if (activeCategory > index) {
      clsName = 'completed';
    }
    return clsName;
  };

  const handleClickCategory = (index) => {
    setActiveCategory(index);
  };

  const handleCalcPercentageWidth = () => {
    const percetage = Number(activeCategory + 1) / Number(stepperOption.length);

    return `${percetage * 100}%`;
  };

  return (
    <div className='step-indicator'>
      <div className='step-indicator__details'>
        <h1 className='step-indicator__details__header'>{title}</h1>
        <div className='step-indicator__m-container'>
          <div className='step-indicator__m-content__wrapper'>
            <div className='step-indicator__m-content__wrapper__container'>
              <p className='step-indicator__m-content__form-title'>
                {stepperOption[activeCategory]?.displayName}
              </p>
              <p className='step-indicator__m-content__form-count'>
                {title && (
                  <>
                    {activeCategory + 1}/{stepperOption.length}
                  </>
                )}
              </p>
            </div>
            <p className='step-indicator__m-content__description'>
              {stepperOption[activeCategory]?.description}
            </p>
          </div>
          <div
            className='step-indicator__m-progress-bar a-progress-indicator -determinate'
            id='content-progress'
            role='progressbar'
            aria-label='progress bar determinate progress indicator'
            aria-valuemin='0'
            aria-valuemax='100'
            aria-valuenow='37'
            tabIndex='0'
          >
            <div
              className='a-progress-indicator__inner-bar'
              style={{ width: handleCalcPercentageWidth() }}
            ></div>
          </div>
        </div>
      </div>
      <div className='step-indicator__container'>
        {stepperOption &&
          stepperOption?.length > 0 &&
          stepperOption.map((element, index) => (
            <div
              key={element.displayName}
              className={`step-indicator__stepper ${handleActiveClass(index)}`}
              tabIndex='0'
              role='button'
              onClick={() => handleClickCategory(index)}
            >
              <div className='step-indicator__v-stepper'>
                <div className='step-indicator__count'>{index + 1}</div>
                <div className='step-indicator__devider'></div>
              </div>
              <div className='step-indicator__wrapper'>
                <div className='step-indicator__content'>
                  <div className='step-indicator__content__wrapper'>
                    <div className='step-indicator__content__title'>
                      {element.displayName}
                    </div>
                    <p className='step-indicator__content__description'>
                      {element.description}
                    </p>
                  </div>
                </div>
                {activeCategory === index && (
                  <div className='step-indicator__active-pointer' />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

VerticleStepper.propTypes = {
  stepperOption: PropTypes.array,
  activeCategory: PropTypes.number,
  setActiveCategory: PropTypes.func,
  title: PropTypes.string
};

VerticleStepper.defaultProps = {
  stepperOption: [],
  activeCategory: 1,
  title: ''
};

export default VerticleStepper;
