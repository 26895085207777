import React, { Component } from 'react';
import { Card, withStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Images from '../../../assets/img';
import IdConstants from '../../../data/idConstants';
import variables from '../../../variables.module.scss';

const styles = {
  media: {
    padding: '1rem',
    width: '140px'
  },

  card: {
    display: 'block',
    width: '100%',
    zIndex: 1,
    border: '1px solid #e6e6e6',
    position: 'relative',
    cursor: 'pointer'
  },

  productlogowrap: {
    position: 'absolute',
    top: '125px',
    left: '50%',
    transform: 'translateX(-44px)',
    borderRadius: '50%',
    width: '108px',
    height: '108px',
    zIndex: '1',
    boxShadow: '0px 3px 7px rgba(0,0,0,0.3)',
    background: '#fff'
  },

  productlogo: {
    display: 'block',
    borderRadius: '50%',
    width: '108px',
    height: '108px'
  },

  itemname: {
    fontSize: '1rem',
    fontFamily: variables.boldBoschFont,
    color: '#5a5a5a',
    marginTop: '55px',
    padding: '10px 15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  removeButton: {
    fontSize: '1rem',
    fontFamily: variables.generalBoschFont,
    color: 'red',
    marginTop: '10px',
    cursor: 'pointer'
  },

  cardContentStyling: {
    padding: '0px',
    wordBreak: 'break-all'
  },

  cardImgStyling: {
    height: '180px'
  }
};

class APIThrottlingCard extends Component {
  deleteApi = () => {
    this.props?.deleteApiFromThrottlePolicy(this.props?.data?.productId);
  };

  returnImageMap = (productImgMap) => {
    if (productImgMap) {
      return productImgMap[IdConstants.documentType.thumbnailImg]
        ? productImgMap[IdConstants.documentType.thumbnailImg][0].blobFileUrl
        : Images.api.thumbnail;
    } else {
      return Images.api.thumbnail;
    }
  };

  render() {
    const { classes } = this.props;
    let displayName = this.props?.data?.displayName;
    const productImgMap = this.props?.data?.docTypeToDocMetaInfoListMap;
    let thumbnailImg = this.returnImageMap(productImgMap);

    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardContent className={classes.cardContentStyling}>
            <Box>
              <Box>
                <CardMedia
                  className={classes.cardImgStyling}
                  image={Images.api.coverImg}
                  title={this.props?.cardTitle}
                  component='img'
                />
              </Box>
              <Box className={classes.productlogowrap}>
                <CardMedia
                  className={classes.productlogo}
                  image={thumbnailImg}
                  title={this.props?.data?.displayName}
                  component='img'
                />
              </Box>
            </Box>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.itemname}
                variant='h6'
                component='h6'
                align='center'
              >
                {displayName}
              </Typography>
              <Box align='center'>
                <Typography
                  gutterBottom
                  className={classes.removeButton}
                  variant='h6'
                  component='h6'
                  align='center'
                  onClick={() => this.deleteApi()}
                >
                  Remove from this policy
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(APIThrottlingCard);
