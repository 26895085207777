import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ParseDynamicOnboardingForm } from '../../shared/api-parser';
import ViewContainer from '../../containers/view-container';
import Loader from '../../components/Shared/Utils/Loader';
import { getApiPlans } from '../../actions/api/pricing-plan-action';
import BaseLayout from '../../msreact-kit/html-components/base-layout';
import {
  saveFormJsonData,
  getDynamicFormData,
  handleResetForm,
  getProductAllDocsList
} from '../../actions/api/hardwareAction';
import { getProductVisibilityData } from '../../actions/ProductDetailAction';
import { logout } from '../../actions/LoginAction';
import { getProductQuantity } from '../../actions/api/stockAvailAction';
import { getProductDetailedInfo } from '../../actions/api/SolutionAction';
import { cloneFunction } from '../../shared/api-parser/clone-parser';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import '../home/index.scss';

const ProductOnboarding = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeGrouping, setActiveGrouping] = useState(0);
  const [showSuccessBoxModal, setShowSuccessBoxModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const categoryId = location?.state?.productCategoryId;
  const productId = location?.state?.productId;

  const loader = useSelector((state) => state.LoaderReducer.loader);
  const onBoardingFormData = useSelector((state) => state.FormReducer.formJsonData);
  const isVisibleData = useSelector(
    (state) => state.ProductDetailReducer.isVisibleData
  );

  const [handleLoader] = useHandleLoader();

  useEffect(() => {
    handleLoader();
  }, []);

  const pricingPlans = useSelector(
    (state) => state.ProductPricingPlanReducer.pricingPlans
  );

  useEffect(() => {
    dispatch(handleResetForm());
    dispatch(getProductVisibilityData(productId));
    const formRes = dispatch(getDynamicFormData(categoryId));
    formRes.then((result) => {
      if (result) {
        let res = ParseDynamicOnboardingForm(result);
        dispatch(saveFormJsonData(res));
        const data = Promise.all([
          dispatch(getProductDetailedInfo(productId, categoryId)),
          dispatch(getProductAllDocsList(productId)),
          dispatch(getProductQuantity(productId)),
          dispatch(getApiPlans(productId))
        ]);
        data.then((resp) => {
          const [additionalInfo, documents, quantity] = resp;
          const cloneData = cloneFunction(res, additionalInfo, documents, quantity);
          dispatch(saveFormJsonData(cloneData));
        });
      } else {
        history.goBack();
      }
    });
  }, []);

  const signOut = () => {
    dispatch(logout());
  };

  const handleNextCategory = (fieldData, sectionLength, data) => {
    if (data.name === 'prodBasicInfo') {
      handleBasicInfo(sectionLength);
    } else {
      handleAdditionalInfo(fieldData, sectionLength, data);
    }
  };

  const handleNextStep = (sectionLength) => {
    if (sectionLength > 0 && sectionLength - 1 !== activeGrouping) {
      setActiveGrouping((prevState) => prevState + 1);
      return;
    }
    setActiveCategory((prevState) => prevState + 1);
    setActiveGrouping(0);
  };

  const handlePreviousCategory = (data, sectionLength) => {
    if (sectionLength > 0 && activeGrouping !== 0) {
      setActiveGrouping((prevState) => prevState - 1);
      return;
    }

    setActiveCategory((prevState) => prevState - 1);
  };

  const handleSuccessDialogBox = () => {
    setShowSuccessBoxModal((prev) => !prev);
  };

  const handleBasicInfo = (sectionLength) => {
    handleNextStep(sectionLength);
  };

  const handleCheckLastStep = (data) => {
    let isLastStep = false;
    if (
      (activeCategory === onBoardingFormData.steps.length - 1 &&
        data?.sections?.length === 0) ||
      (activeCategory === onBoardingFormData.steps.length - 1 &&
        data?.sections?.length > 0 &&
        activeGrouping === data.sections.length - 1)
    ) {
      isLastStep = true;
    }
    return isLastStep;
  };

  const handleAdditionalInfo = (fieldData, sectionLength, data) => {
    const isLastStep = handleCheckLastStep(data);
    if (isLastStep) {
      return history.goBack();
    }
    handleNextStep(sectionLength);
  };

  const handleStepCategory = (index) => {
    setActiveCategory(index);
    setActiveGrouping(0);
  };

  return (
    <BaseLayout logout={signOut}>
      <div className='home'>
        {loader && <Loader />}
        <ViewContainer
          activeCategory={activeCategory}
          activeGrouping={activeGrouping}
          handleStepCategory={handleStepCategory}
          handleNextCategory={handleNextCategory}
          setActiveGrouping={setActiveGrouping}
          handlePreviousCategory={handlePreviousCategory}
          onBoardingFormData={onBoardingFormData}
          showSuccessBoxModal={showSuccessBoxModal}
          handleSuccessDialogBox={handleSuccessDialogBox}
          apiPlans={pricingPlans}
          isView={location?.state?.isView}
          isVisibleData={isVisibleData}
        />
      </div>
    </BaseLayout>
  );
};

export default ProductOnboarding;
