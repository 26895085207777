import React, { useState } from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import styles from './cookie.module.scss';

function CookieModal(props) {
  const [checkboxState, updateCheckboxState] = useState(false);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  });
  const isPhone = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCheckboxChange = (event) => {
    updateCheckboxState(event.target.checked);
  };
  const privacySetting = (event) => {
    props.showPrivacyCookieModal();
  };

  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown
        open={props.open}
        PaperProps={{
          square: true,
          className: !isPhone && !isTablet ? styles['cookie-popup'] : ''
        }}
        sx={{
          backdropFilter: 'blur(2px)',
          zIndex: 10000
        }}
      >
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <Typography
                varaint='h6'
                className={`${styles['consent-heading']} ${styles['bosch-font-general-style']}`}
              >
                Privacy settings
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant='h6'
              className={`${styles['consent-content']} ${styles['bosch-font-general-style']}  ${styles['cookie-content-info']} `}
            >
              Cookies that are essential for technical reasons are set on this
              webpage so we can provide users with our offers. A cookie that stores
              your current privacy settings is also set (selection cookie).
            </Typography>
            <Typography
              variant='h6'
              className={`${styles['consent-cookie-content']} ${styles['bosch-font-general-style']}  ${styles['cookie-content-info']} `}
            >
              With the exception of the selection cookie, technically non-essential
              cookies and tracking mechanisms, that enable us to provide you with
              customized offers (marketing cookies and tracking mechanisms), are only
              used if you have given prior consent to such use&nbsp;
              <NavLink
                className={`${styles['cookie-policy-link']} ${styles['bosch-font-general-style']}`}
                to='/data-protection-policy'
              >
                Read more {'>'}
              </NavLink>
            </Typography>
          </DialogContentText>

          <div className={styles['checkbox-field-items']}>
            <label className={styles['custom-checkbox-label']}>
              <input
                type='checkbox'
                checked={checkboxState}
                onChange={(event) => {
                  handleCheckboxChange(event);
                }}
                className={styles['custom-checkbox-input']}
              />
              <span className={styles['custom-checkbox-checkmark']}></span>
            </label>

            <span className={styles['checkbox-text']}>
              Consent to marketing cookies
              <br />
              <NavLink
                className={`${styles['cookie-policy-link']} ${styles['checkbox-second-text']}`}
                to='/data-protection-policy'
              >
                Read more {'>'}
              </NavLink>
            </span>
          </div>
          <div className={styles['mini-text-div']}>
            When clicking “Save” only the cookies you selected will be used. Under
            your{' '}
            <Link
              className={` ${styles['sublinks']} ${styles['mini-text-div']} `}
              component={RouterLink}
              onClick={() => privacySetting()}
              underline='none'
            >
              privacy settings
            </Link>
            , you can withdraw at any time the consent that you granted here with
            immediate effect.
          </div>
          <div className={styles['cookie-btn-style']}>
            <Button
              className={`${styles['cookie-accept-button']}`}
              onClick={() => props.okOperation()}
            >
              Consent to marketing cookies
            </Button>
            <Button
              className={`${styles['save-button']}`}
              onClick={() =>
                checkboxState ? props.okOperation() : props.noCookiesSelection()
              }
            >
              Save
            </Button>
          </div>
          <div className={styles['footer-style-cookies']}>
            <NavLink
              className={`${styles['cookies-links-footer']} ${styles['bosch-font-general-style']}  ${styles['bottom-nav-links']} `}
              to='/data-protection-policy'
            >
              Data protection notice{' '}
            </NavLink>
            |
            <NavLink
              className={`${styles['cookies-links-footer']} ${styles['bosch-font-general-style']}  ${styles['bottom-nav-links']} `}
              to='/corporate-information'
            >
              {' '}
              Corporate information
            </NavLink>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default CookieModal;
