import reactGA from 'react-ga';
import * as Constants from '../actions/configuration';

export const initialization = () => {
  //Google Analytics Initialization
  reactGA.initialize(Constants.GOOGLE_ANALYTICS_TRACKING_ID, {
    gaOptions: {
      userId: localStorage.getItem('userId')
    }
  });
};

export const trackEvent = (
  eventCategory,
  eventAction,
  eventLabel = null,
  eventValue = null
) => {
  let analyticsPayload = {
    category: eventCategory,
    action: eventAction
  };
  if (eventLabel) {
    analyticsPayload = {
      ...analyticsPayload,
      label: eventLabel
    };
  }
  if (eventValue) {
    analyticsPayload = {
      ...analyticsPayload,
      value: eventValue
    };
  }
  //Google Analytics Event tracking
  reactGA.event(analyticsPayload);
};

export const trackPageView = (pageView) => {
  //Google Analytics Page view
  reactGA.pageview(pageView);
};
