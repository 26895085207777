const dialogText = {
  deletePlan: {
    confirmModalText: 'On deletion, this pricing plan will be removed',
    modalHeading: 'Do you really want to delete?'
  },
  planDeactivated: {
    confirmModalText:
      'Plan is deactivated now, hence buyer cannot see this plan. Only Active plans are visible to the buyer in Marketplace',
    modalHeading: 'Information'
  },
  planActivated: {
    confirmModalText:
      'Plan is Active now, hence it will be visible to the buyer in Marketplace',
    modalHeading: 'Information'
  },
  planDeactivationAborted: {
    confirmModalText:
      'Plan cannot be Deactivated. At least one Plan must be Active for an API.',
    modalHeading: 'Information'
  },
  cancelConfirmation: {
    confirmModalText:
      "Please make sure you save the changes before leaving the page. If you don't save, your data will be lost",
    modalHeading: 'Are you sure you want to exit?'
  },
  cancelConfirmationOnEdit: {
    confirmModalText:
      'API details has been changed. An approval is required from the Administrator. Only after the approval, API can be published and will be visible to the users.',
    modalHeading: 'Are you sure you want to exit?'
  },
  exitConfirmation: {
    confirmModalText: '',
    modalHeading: 'Are you sure you want to exit?'
  },
  submitConfirmation: {
    confirmModalText:
      'By clicking on Submit, API will be submitted for Review. Once Approved you will see your API in Approved state',
    modalHeading: 'Are you sure you want to Submit your API?'
  },
  documentDeleteConfirmation: {
    confirmModalText: 'On deletion, Document will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  videoDeleteConfirmation: {
    confirmModalText: 'On deletion, Video will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  thumbnailImgDeleteConfirmation: {
    confirmModalText: 'On deletion, Thumbnail image will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  coverImgDeleteConfirmation: {
    confirmModalText: 'On deletion, Cover image will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  deleteSecretHeader: {
    confirmModalText:
      'On deletion, secret header/parameter details will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  deleteGroup: {
    confirmModalText: 'On deletion, group will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  deleteAssociatedGroup: {
    confirmModalText:
      'Already endpoints are associated to this group. On deletion associated endpoints will be ungrouped',
    modalHeading: 'Do you really want to delete?'
  },
  deleteEndpoint: {
    confirmModalText: 'On deletion, endpoint will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  planDeletionAborted: {
    confirmModalText:
      'Deletion cannot be done. At least one plan must be present and Active for an API.',
    modalHeading: 'Information'
  },
  secretHeaderParamInfo: {
    confirmModalText:
      'Base URL and Authorization key is required to add the Secret Header or Parameter details',
    modalHeading: 'Information'
  },
  submitApi: {
    confirmModalText:
      'By clicking ok you are submitting your API for review. Once approved you will see your API in approved state.',
    modalHeading: 'Are you sure you want to submit your API?'
  },
  publishApi: {
    confirmModalText:
      'Are you sure you want to Publish? By clicking OK, your API will go live and accessible to everyone!',
    modalHeading: 'Confirmation'
  },
  editInfo: {
    confirmModalText:
      "Edit of any fields in API Description (except Business categories, Subcategories, Long Description), API Settings and API Endpoints stage results in status change. Hence you might need to submit API for the approval again. Any change in Business categories, Subcategories, Long Description field of API Description, API Documentation and API Pricing plan stage doesn't require an approval",
    modalHeading: 'Information'
  },
  editAfterConsumedInfo: {
    confirmModalText:
      'API has been subscribed by the users, hence only some fields are allowed to edit!',
    modalHeading: 'Information'
  },
  changeAuth: {
    confirmModalText: 'Are you sure you want to change the Authentication type?',
    modalHeading: 'Confirmation'
  },
  deleteConfirmation: {
    confirmModalText: 'On deletion API will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  submitDeleteConfirmation: {
    confirmModalText:
      "This API is already sent for Admin's approval. On deletion API will be removed permanently and will no longer be available for Admin",
    modalHeading: 'Do you really want to delete?'
  },
  consumedApiInformation: {
    confirmModalText: 'Unable to delete this product. Please contact administrator.',
    modalHeading: 'Information'
  },
  subscribedPlanDeactivation: {
    confirmModalText:
      'On Deactivating, plan will not be visible in marketplace for the new subscriptions but existing subscribers of this plan still be able to consume the API.',
    modalHeading: 'Information'
  },
  subscribedPlanDeletion: {
    confirmModalText:
      'API has already been consumed by the user with this plan, hence it cannot be deleted',
    modalHeading: 'Information'
  },
  productDeleteConfirmation: {
    confirmModalText:
      'On deletion, the selected product will be removed permanently',
    modalHeading: 'Do you really want to delete?'
  },
  data: {
    publishConfirmation: {
      confirmModalText:
        'By clicking OK, Data will go live and accessible in Marketplace!',
      modalHeading: 'Are you sure you want to Publish?'
    }
  },

  solutions: {
    publishConfirmation: {
      confirmModalText:
        'By clicking OK, Solutions will go live and accessible in Marketplace!',
      modalHeading: 'Are you sure you want to Publish?'
    }
  },

  hardware: {
    publishConfirmation: {
      confirmModalText:
        'By clicking OK, Hardware will go live and accessible in Marketplace!',
      modalHeading: 'Are you sure you want to Publish?'
    }
  },

  product: {
    publishConfirmation: {
      confirmModalText:
        'By clicking OK, Product will go live and accessible in Marketplace!',
      modalHeading: 'Are you sure you want to Publish?'
    }
  }
};

export default dialogText;
