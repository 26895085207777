import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import variables from '../../../../variables.module.scss';

const useStyles = makeStyles({
  filled: {
    background: variables.primaryColor,
    color: 'white',
    lineHeight: '28px',
    backgroundSize: '100% 200%',
    fontSize: '1em',
    cursor: 'pointer',
    fontWeight: '400',
    width: 'auto',
    fontFamily: variables.lightBoschFont,
    boxShadow: 'none',
    minWidth: '114px',
    padding: '7px 13px',
    border: '0px solid transparent',

    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#0a74bd'
    },
    '&:disabled': {
      color: 'white',
      opacity: '0.4'
    }
  },
  outlined: {
    border: '1px solid #007bc0',
    color: '#007bc0',
    lineHeight: '28px',
    background: '#FFF',
    backgroundSize: '100% 200%',
    fontSize: '1em',
    cursor: 'pointer',
    fontWeight: '400',
    width: 'auto',
    fontFamily: variables.lightBoschFont,
    boxShadow: 'none',
    minWidth: '114px',

    padding: '6px 13px',
    borderRadius: '0px',
    '&:hover': {
      color: '#0a74bd'
    },
    '&:disabled': {
      opacity: '0.4'
    }
  },
  label: {
    textTransform: 'capitalize'
  },
  disabledCapitalization: {
    textTransform: 'initial'
  }
});

export default function StylledButton(props) {
  const classes = useStyles();
  return (
    <div>
      {(() => {
        if (props.variant === 'outlined') {
          return (
            <Button
              style={props.style}
              className={props.className}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              classes={{
                root: classes.outlined,
                label:
                  props.toCapitalize === 'disable'
                    ? classes.disabledCapitalization
                    : classes.label
              }}
              disabled={props.disabled}
              onClick={props.onClick}
            >
              {props.label}
            </Button>
          );
        } else {
          return (
            <Button
              style={props.style}
              className={props.className}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              classes={{ root: classes.filled, label: classes.label }}
              disabled={props.disabled}
              onClick={props.onClick}
              id={props.id}
            >
              {props.label}
            </Button>
          );
        }
      })()}
    </div>
  );
}
