import * as ACTION_TYPES from '../../actions/ActionTypes';

const initialState = {
  orderStatus: [],
  orderDescription: {},
  statusChange: 0,
  comments: [],
  isLoading: false,
  billingAddress: {},
  shippingAddress: {},
  errorState: false,
  postComment: 0,
  rejectionReasons: [],
  isCancellable: '',
  pricingPolicies: []
};

const OrderSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.payload.data,
        isCancellable: action.payload.cancellable
      };
    case ACTION_TYPES.GET_ORDER_SUMMARY:
      return { ...state, orderDescription: action.payload };
    case ACTION_TYPES.CHANGE_ORDER_STATUS:
      return { ...state, statusChange: state.statusChange + 1 };
    case ACTION_TYPES.GET_COMMENTS:
      return { ...state, comments: action.payload };
    case ACTION_TYPES.POST_COMMENT:
      return { ...state, postComment: state.postComment + action.payload };
    case ACTION_TYPES.SET_LOADER:
      return { ...state, isLoading: action.payload };
    case ACTION_TYPES.SET_BILLING_ADDRESS:
      return { ...state, billingAddress: action.payload };
    case ACTION_TYPES.SET_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload };
    case ACTION_TYPES.CHANGE_ERROR_STATE:
      return { ...state, errorState: true };
    case ACTION_TYPES.GET_REJECTION_REASON:
      return { ...state, rejectionReasons: action.payload };
    case ACTION_TYPES.GET_PRICING_DETAILS:
      return { ...state, pricingPolicies: action.payload };
    case ACTION_TYPES.RESET_ORDER_STATE: {
      return {
        ...state,
        orderStatus: [],
        orderDescription: {},
        statusChange: 0,
        comments: [],
        isLoading: false,
        billingAddress: {},
        errorState: false,
        postComment: 0,
        rejectionReasons: [],
        pricingPolicies: []
      };
    }
    default:
      return state;
  }
};

export default OrderSummaryReducer;
