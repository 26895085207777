import React from 'react';
import ToggleSwitch from '../ToggleSwitch';
import './index.scss';
import i18next from 'i18next';

const VisibilitySettings = (props) => {
  return (
    <>
      <div>
        <div className='box-heading'>
          <div className='heading-style -size-l highlight'>
            {i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY_SETTINGS')}
          </div>
        </div>
        <div>
          <div className='sub-textstyle'>
            <p>{i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY_SETTINGS_SUBTEXT')}</p>
          </div>
        </div>
        <div className='visibility-button'>
          <div>
            <p>{i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY')}</p>
          </div>
          <div className='toggle-div'>
            <ToggleSwitch
              checked={props?.isVisibleData}
              onChange={() => props?.handleIsVisible()}
              name='active'
              disabled={props.disabled}
            />
          </div>
        </div>
        <div className='info-text'>
          <i className='a-icon boschicon-bosch-ic-alert-info info-style-icon'></i>
          <p>
            {props?.isVisibleData
              ? i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY_ENABLED_TEXT')
              : i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY_DISABLED_TEXT')}
          </p>
        </div>
      </div>
    </>
  );
};

export default VisibilitySettings;
