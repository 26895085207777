const initialState = {
  productDetail: [],
  productRating: [],
  postRatingRes: [],
  userRating: [],
  postReviewRes: [],
  postRatingRevRes: [],
  productDocDetail: [],
  updateRevRatRes: [],
  deleteRevRes: [],
  productVideos: [],
  productVideosLinks: [],
  productThumbnailImg: [],
  productCoverImg: [],
  isVisibleData: true
};

const ProductDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_BY_ID':
      return Object.assign({}, state, {
        productDetail: action.payload1,
        productRating: action.payload2,
        userRating: action.payload3
      });
    case 'POST_RATING':
      return Object.assign({}, state, { postRatingRes: action.payload });
    case 'POST_REVIEW_RATING':
      return Object.assign({}, state, { postRatingRevRes: action.payload });
    case 'POST_REVIEW':
      return Object.assign({}, state, { postReviewRes: action.payload });
    case 'GET_REVIEW_RATING':
      return Object.assign({}, state, {
        productRating: action.payload1,
        userRating: action.payload2
      });
    case 'GET_PRODUCT_DOCS_BY_ID':
      return Object.assign({}, state, { productDocDetail: action.payload });
    case 'GET_DOCUMENT_DATA':
      return Object.assign({}, state, { documentData: action.payload });
    case 'UPDATE_REVIEW_RATING':
      return Object.assign({}, state, {
        postRatingRevRes: action.payload1,
        productRating: action.payload2,
        userRating: action.payload3
      });
    case 'DELETE_REVIEW_RATING':
      return Object.assign({}, state, {
        deleteRevRes: action.payload1,
        productRating: action.payload2,
        userRating: action.payload3
      });
    case 'GET_PRODUCT_VIDEOS_BY_ID':
      return Object.assign({}, state, { productVideos: action.payload });
    case 'GET_PRODUCT_VIDEOS_LINK_BY_ID':
      return Object.assign({}, state, { productVideosLinks: action.payload });
    case 'GET_PRODUCT_THUMBNAIL_IMG':
      return Object.assign({}, state, { productThumbnailImg: action.payload });
    case 'GET_PRODUCT_COVER_IMG':
      return Object.assign({}, state, { productCoverImg: action.payload });
    case 'GET_IS_VISIBLE_DATA':
      return Object.assign({}, state, { isVisibleData: action.payload });
    default:
      return state;
  }
};

export default ProductDetailReducer;
