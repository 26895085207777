import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import {
  SecondaryButton,
  TextInput,
  SideBar
} from '../../msreact-kit/packages/html-components';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import { getAccessKey, resetAccessKey } from '../../actions/api/SettingsAction';
import images from '../../assets/img';
import Loader from '../../components/Shared/Utils/Loader';
import './index.scss';

const Settings = ({ callbackModal }) => {
  const dispatch = useDispatch();
  const [handleLoader] = useHandleLoader();
  const [copiedTimeoutId, setCopiedTimeoutId] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [copied, setCopied] = useState(false);
  const { accessKey, loader } = useSelector((state) => ({
    accessKey: state.SettingsReducer.accessKey,
    loader: state.LoaderReducer.loader
  }));

  useEffect(() => {
    return () => {
      dispatch(resetAccessKey());
      handleClearTimeout();
    };
  }, [dispatch]);

  const handleSideBar = () => {
    callbackModal();
  };

  const handleGenerateKey = () => {
    handleLoader();
    dispatch(getAccessKey());
  };

  const handleCopy = () => {
    copy(accessKey?.accessKey);
    setCopied(true);
    const timeoutId = setTimeout(() => {
      setCopied(false);
    }, 1000);
    setCopiedTimeoutId(timeoutId);
  };

  const handleClearTimeout = () => {
    if (copiedTimeoutId) {
      clearTimeout(copiedTimeoutId);
      setCopied(false);
    }
  };

  const handleIsVisible = () => setVisibility(!visibility);

  const inputType = visibility ? 'text' : 'password';

  return (
    <React.Fragment>
      {loader && <Loader />}
      <SideBar
        label={i18next.t('DYNAMIC_FORM.USER_ACCOUNT.SETTINGS')}
        handleSideBar={handleSideBar}
        isShowCancelBtn={false}
        nextStepLabel={i18next.t('DYNAMIC_FORM.LABEL.DONE')}
        handleNext={handleSideBar}
        customClass={'setting__container'}
      >
        <div className='setting__box-container'>
          <h5>{i18next.t('DYNAMIC_FORM.USER_ACCOUNT.ACCESS_KEY')}</h5>
          <div className='setting__box-container__box'>
            <TextInput
              cssName={'setting__box-container__box__access-key__input'}
              value={accessKey?.accessKey}
              type={inputType}
            />
            <span
              onClick={handleIsVisible}
              className='setting__box-container__box__access-key__icon'
            >
              {accessKey?.accessKey && (
                <img
                  src={visibility ? images.watchOn : images.watchOff}
                  className={'img-size'}
                  alt='visibility icon'
                />
              )}
            </span>
            {accessKey?.accessKey ? (
              <>
                <img
                  src={images.copy}
                  className={'setting__box-container__box__copy-img'}
                  onClick={handleCopy}
                  alt={i18next.t('DYNAMIC_FORM.USER_ACCOUNT.ALT_COPY')}
                />
                {copied && (
                  <p>{i18next.t('DYNAMIC_FORM.SHARED.COPIED_TO_CLIPBOARD')}</p>
                )}
              </>
            ) : (
              <div className='setting__box-container__box__generate'>
                <SecondaryButton
                  label={i18next.t('DYNAMIC_FORM.USER_ACCOUNT.GENERATE')}
                  onClick={handleGenerateKey}
                />
              </div>
            )}
          </div>
          <p className='setting__box-container__infocontent -size-s'>
            {i18next.t('DYNAMIC_FORM.USER_ACCOUNT.ACCESS_KEY_INFO_CONTENT')}
          </p>
        </div>
      </SideBar>
    </React.Fragment>
  );
};

export default Settings;
