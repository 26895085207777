import { DynamicFieldConstants } from '../constants/index';

export const parseDocValidation = (data) => {
  const parsedVal = {};
  data.forEach((element) => {
    if (element.propertyKey === 'com.bosch.mss.fileUpload.maxFileSizeInBytes') {
      parsedVal.maxSize = Number(element.configValue);
      parsedVal.documentTypeId = element.documentTypeId;
    } else if (element.propertyKey === 'com.bosch.mss.fileupload.maxFileCount') {
      parsedVal.maxFiles = Number(element.configValue);
    } else if (
      element.propertyKey === 'com.bosch.mss.fileUpload.supportedFileFormats'
    ) {
      const configVal = element.configValue;
      parsedVal.accept = configVal;
      const splitVal = configVal.split('/');
      if (splitVal.includes('image') || splitVal.includes('video')) {
        parsedVal.attributeType = DynamicFieldConstants.UPLOAD_IMAGE;
      }
    } else if (element.propertyKey === 'com.bosch.mss.fileUpload.multiple') {
      parsedVal.multiple = element.configValue;
    } else if (
      element.propertyKey === 'com.bosch.mss.fileUpload.supportedFileExtensions'
    ) {
      parsedVal.supportedFileExtensions = element?.configValue
        ?.split(',')
        .map((item) => {
          return `.${item}`;
        });
    }
  });
  return parsedVal;
};
