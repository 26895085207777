import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton } from '../../packages/html-components';
import Images from '../../../assets/img';
import './index.scss';

const ModalCard = ({
  title,
  subTitle,
  handleCancel,
  handleConfirm,
  isSuccess,
  secondaryLabel,
  primaryLabel,
  isSecondaryBtn,
  children
}) => {
  return (
    <Fragment>
      <div className={`dialog_modal__${isSuccess ? 'success' : 'error'}`}>
        <img
          className={`dialog_modal__${isSuccess ? 'success' : 'error'}__alert`}
          src={isSuccess ? Images.alertSuccess : Images.alertErrorBlack}
        />
        <img
          className={`dialog_modal__${isSuccess ? 'success' : 'error'}__image`}
          src={isSuccess ? Images.successIcon : Images.errorImage}
        />
      </div>
      <div className='dialog_alert__message'>
        <p className='dialog_alert__message__title'> {title} </p>
        <p className='dialog_alert__message__subtitle'>{subTitle}</p>
      </div>
      {children}
      <div className='dialog_modal__message'>
        <Fragment>
          {isSecondaryBtn && (
            <SecondaryButton onClick={handleCancel} label={secondaryLabel} />
          )}
          <PrimaryButton onClick={handleConfirm} label={primaryLabel} />
        </Fragment>
      </div>
    </Fragment>
  );
};

export default ModalCard;

ModalCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isSuccess: PropTypes.bool,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  isSecondaryBtn: PropTypes.bool
};

ModalCard.defaultProps = {
  title: 'Successfully Hardware Submitted',
  subTitle: 'Product details is successfully submitted for approval.',
  isSuccess: false,
  primaryLabel: 'Yes',
  secondaryLabel: 'No',
  isSecondaryBtn: true
};
