import React, { useState } from 'react';

const Tab = ({ handleChange, tabName }) => {
  const [activeIndex, setActiveIndex] = useState('NEW');
  const handleClick = (item) => {
    setActiveIndex(item.id);
    handleChange(item);
  };

  return (
    <div className='frontend-kit-example_tabbed-content'>
      <div className='a-tab-navigation__wrapper'>
        <div className='a-tab-navigation__gradients'></div>
        <ol className='a-tab-navigation' role='tablist'>
          {tabName.map((item) => (
            <li key={item.id} className='a-tab-navigation__item' role='none'>
              <button
                type='button'
                className={`a-tab-navigation__tab  ${
                  activeIndex === item.id ? '-selected' : ''
                }`}
                data-frok-tab-identifier='content-1'
                tabIndex='0'
                role='tab'
                aria-controls='tab-content-1'
                id='tab-content-1'
                onClick={() => handleClick(item)}
              >
                <div className='a-tab-navigation__tab-content'>
                  <div className='a-tab-navigation__label orders__container__tabCount'>
                    {item.label}{' '}
                    {item.labelCount >= 0 && (
                      <div className='orders__container__tabCount__statusCount'>
                        <div
                          className='a-badge -error'
                          tabIndex='0'
                          role='status'
                          aria-live='off'
                        >
                          {item.labelCount}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </button>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Tab;
