import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../dialog';
import {
  Dropdown,
  TextArea
} from '../../../../../msreact-kit/packages/html-components';
import { getRejectionReasons } from '../../../../../actions/api/OrderSummaryAction';
import PropTypes from 'prop-types';
import '../index.scss';

const RejectionRemarks = ({
  onClose,
  value,
  currentRejectReason,
  handleSelectedOption,
  rejectionRemarkLength,
  handleSubmitRejection,
  handleChange,
  setRejectedItemValue,
  setRejectionTextAreaValue
}) => {
  const rejectionData = {
    attributeId: null,
    name: 'rejectionRemark',
    displayName: i18next.t(
      'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.REJECTION_REMARK_POPUP.WRITE_REMARK'
    ),
    handleChange: handleChange,
    description: '',
    value: value,
    xpath: '',
    jsonType: null,
    attributeType: 'TEXT AREA',
    placeHolder: i18next.t(
      'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.REJECTION_REMARK_POPUP.REMARK_PLACEHOLDER'
    ),
    displayOrder: 4,
    isVisibleForBuyer: null,
    isElementRequired: true,
    stateChangeOnEdit: false,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        },
        length: {
          min: 1,
          max: 4000,
          errorCode: 'MS105',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: null,
    sections: []
  };

  const [rejectReasons, setRejectionReasons] = useState([]);

  const dispatch = useDispatch();

  const validation = {
    rule: {
      required: {
        isRequired: true,
        errorCode: 'MS102',
        errorMessage: ''
      }
    }
  };

  const closeDialogBox = () => {
    setRejectedItemValue({});
    setRejectionTextAreaValue('');
    onClose();
  };

  const { categoryId, rejectReason } = useSelector((state) => {
    return {
      categoryId:
        state?.OrderSummaryReducer?.orderDescription?.orderSubscriptionMap
          ?.productCategoryId,
      rejectReason: state?.OrderSummaryReducer.rejectionReasons
    };
  });
  useEffect(() => {
    if (!rejectReason.length) {
      dispatch(getRejectionReasons(categoryId));
    }
    let reasons = [];
    if (rejectReason.length) {
      rejectReason.forEach((item) => {
        reasons.push({
          id: item.rejectReasonId,
          label: item.reasonName
        });
      });
      setRejectionReasons(reasons);
    }
  }, [rejectReason]);

  if (rejectReason.length === 0) return null;

  return (
    <div className='add-comment'>
      <div className='add-comment__container'>
        <Dialog
          header={i18next.t(
            'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.REJECTION_REMARK_POPUP.WRITE_REMARK_TO_REJECT_THE_PRODUCT'
          )}
          isPrimary={false}
          secondLabel={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT')}
          isTertiary={false}
          isTitle={false}
          isDivider={false}
          onCloseDialog={closeDialogBox}
          handleSubmitClick={handleSubmitRejection}
          emptyCommentBox={rejectionRemarkLength}
          rejectReason={Object.keys(currentRejectReason)?.length}
        >
          <Dropdown
            options={rejectReasons}
            displayName={i18next.t(
              'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.REJECTION_REMARK_POPUP.REJECT_REASONS'
            )}
            placeHolder={i18next.t(
              'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.REJECTION_REMARK_POPUP.REJECT_REASONS_PLACEHOLDER'
            )}
            handleChange={(data) => handleSelectedOption(data)}
            validation={validation}
            name={rejectReason.reasonName}
          />
          <TextArea data-testId='rejection-text-area' {...rejectionData} />
        </Dialog>
      </div>
    </div>
  );
};

RejectionRemarks.propTypes = {
  onClose: PropTypes.func
};
export default RejectionRemarks;
