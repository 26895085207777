import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, children, ...props }) => {
  let cssName = 'a-button -without-icon';
  if (props.className) {
    cssName += ` ${props.className}`;
  } else {
    cssName += ` a-button--primary`;
  }

  return (
    <button {...props} type='button' className={cssName}>
      {label && <span className='a-button__label'>{label}</span>}
      {children}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

Button.defaultProps = {
  label: '',
  disabled: false,
  className: ''
};

export default Button;
