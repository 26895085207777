import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import Images from '../../assets/img';
import Footer from '../BaseLayout/footer';
import { Portal, Snackbar, Alert } from '@mui/material';
import idConstants from '../../data/idConstants';
import * as Constants from '../../actions/configuration';
import { RegisterCompany } from '@mobilitystore/user-onboarding-interface';
import { getRegionCode } from '../../utils/get-region-details';
import { getEnvironment } from '../../utils';
import AccessDenied from '../Shared/AccessDenied';
import { logout } from '../../actions/LoginAction';
import { accessRoles } from '../../data/appConstants';
import Loader from '../Shared/Utils/Loader';
import {
  saveUserDetails,
  submitTermsConsent,
  setUserDetails
} from '../../actions/UserRegisterAction';
import { getUserDetails } from '../../actions/api/ApiStatisticsAction';
import isEmpty from '../../validator/isEmpty';
import EmptyPage from '../../msreact-kit/html-components/empty-page';
import { internalSellerUserOnboardingForm } from '../../data/internalSellerUserOnboardingForm';
import './index.scss';
import i18next from 'i18next';
import SalesRepDashboard from './salesRepDashBoard';
import { getSellersList } from '../../actions/GetSellerListAction';

const Dashboard = (props) => {
  const dispatchRedux = useDispatch();
  const reduxStore = useSelector((state) => state);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [snackBarFlag, setSnackBarFlag] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [loader, setLoader] = useState(false);
  const [isSalesRepApproved, setIsSalesRepApproved] = useState(true);
  const [userLoginRole, setUserLoginRole] = useState('');
  const userCompanyStatus = reduxStore.CompanyDetailsReducer.userCompanyStatus;
  const userRegistrationSuccessMsg =
    reduxStore.ProfileReducer.userRegistrationSuccessMsg;
  const userRegistrationErrorMsg =
    reduxStore.ProfileReducer.userRegistrationErrorMsg;
  const isInternalSellerTenant =
    process.env.REACT_APP_SELLER_TENANT_ID === Constants.TENANT.MKOSINT;
  let registerHeading = i18next.t('HOME_PAGE.USER_STATUS.REGISTER_COMPANY');
  let registerDescription = i18next.t(
    'HOME_PAGE.USER_STATUS.PENDING_REG_DESCRIPTION'
  );
  let dynamicForm = '';
  const [sellerList, setSellerList] = useState([]);
  const role = localStorage.getItem(idConstants.userLoginRole.role);

  if (isInternalSellerTenant) {
    registerHeading = i18next.t('INTERNAL_SELLER.REGISTER_NOW');
    registerDescription = '';
    dynamicForm = internalSellerUserOnboardingForm(userCompanyStatus);
  }

  const openPopUp = () => {
    setOpenRegisterModal(true);
    if (window.location.pathname !== '/dashboard') {
      props.history.push('/dashboard');
    }
  };

  const accessDeniedDetails = {
    accessDeniedImage: Images.accessDenied,
    accessDeniedAlt: i18next.t('DYNAMIC_FORM.ACCESS_DENIED.ACCESS_DENIED_ALT'),
    accessDeniedCode: i18next.t('DYNAMIC_FORM.ACCESS_DENIED.ACCESS_DENIED_CODE'),
    header: i18next.t('DYNAMIC_FORM.ACCESS_DENIED.HEADER'),
    text: i18next.t('DYNAMIC_FORM.ACCESS_DENIED.TEXT'),
    signOut: i18next.t('DYNAMIC_FORM.USER_ACCOUNT.SIGN_OUT')
  };

  const RegisterComponent = localStorage.getItem(idConstants.tokens.aaaIdToken) &&
    (isInternalSellerTenant
      ? userCompanyStatus?.companyRegisteredStatus !==
        idConstants.approved.description.toLowerCase()
      : true) &&
    openRegisterModal && (
      <RegisterCompany
        language='en'
        clientToken={localStorage.getItem(idConstants.tokens.aaaIdToken)}
        environment={getEnvironment(process.env.REACT_APP_ENVIRONMENT)}
        successHandler={(response) => handleSuccessResponse(response)}
        cancelHandler={() => {
          setOpenRegisterModal(false);
        }}
        errorHandler={(error) => {
          if (error.message) {
            setSnackBarFlag(true);
            setSnackBarMsg(error.message);
            setSnackBarSeverity('error');
          }
        }}
        role={Constants.AAA_CONFIG.SELLER_ROLE}
        regionCode={getRegionCode()}
        fontName='boschsans'
        dynamicForm={dynamicForm}
      />
    );

  useEffect(() => {
    if (role === accessRoles.sales_rep) {
      const regionCode = getRegionCode();
      dispatchRedux(getSellersList(regionCode)).then((response) => {
        setSellerList(response?.users);
      });
    }
  }, []);

  useEffect(() => {
    if (isEmpty(userRegistrationSuccessMsg)) return;
    setSnackBarFlag(true);
    setSnackBarMsg(userRegistrationSuccessMsg);
    setSnackBarSeverity('success');
    setLoader(false);
    dispatchRedux({
      type: 'USER_REGISTRATION_SUCCESS_ALERT',
      payload: ''
    });
  }, [userRegistrationSuccessMsg]);

  useEffect(() => {
    if (isEmpty(userRegistrationErrorMsg)) return;
    setSnackBarFlag(true);
    setSnackBarMsg(userRegistrationErrorMsg);
    setSnackBarSeverity('error');
    setLoader(false);
    dispatchRedux({
      type: 'USER_REGISTRATION_ERROR_ALERT',
      error: ''
    });
  }, [userRegistrationErrorMsg]);

  const handleSuccessResponse = (response) => {
    if (isInternalSellerTenant) {
      const userDetails = {};
      userDetails.firstName = response.companyData.userData.firstName;
      userDetails.lastName = response.companyData.userData.lastName;
      userDetails.contactNo =
        response.companyData.userData.isdCode +
        ' ' +
        response.companyData.userData.phoneNo;
      userDetails.countryCode = 'IN';
      userDetails.roleName = Constants.AAA_CONFIG.SELLER_ROLE;
      setLoader(true);
      dispatchRedux(setUserDetails(userDetails));
      saveUserDetails(userDetails, dispatchRedux).then((res) => {
        if (res.data.userId) {
          dispatchRedux(getUserDetails(res.data.userId));
          dispatchRedux(submitTermsConsent());
        }
      });
    } else if (!isEmpty(response?.companyRegStatusDescription)) {
      dispatchRedux({
        type: 'USER_COMPANY_STATUS',
        payload: {
          ...userCompanyStatus,
          companyRegStatusDescription: response.companyRegStatusDescription,
          companyRegisteredStatus: response.status
        }
      });
    }
  };

  useEffect(() => {
    setLoader(true);
    if (Array.isArray(props?.user?.roles)) {
      setIsSalesRepApproved(props.user?.roles?.includes(accessRoles.sales_rep));
      setLoader(false);
    }
  }, [props?.user?.roles]);

  useEffect(() => {
    const storedUserLoginRole = localStorage.getItem(idConstants.userLoginRole.role);
    if (storedUserLoginRole) {
      setUserLoginRole(storedUserLoginRole);
    }
  }, []);

  const handleLogout = () => {
    dispatchRedux(logout());
  };

  const isCompanyApproved =
    userCompanyStatus?.companyRegisteredStatus === 'approved' &&
    userCompanyStatus?.areCompanyDetailsCaptured === true;

  return (
    <div>
      <div>
        {loader && <Loader />}
        {userLoginRole === accessRoles.sales_rep && !isSalesRepApproved && (
          <AccessDenied
            show={true}
            signOut={handleLogout}
            details={accessDeniedDetails}
          />
        )}
      </div>
      <div
        style={{
          backgroundImage:
            isCompanyApproved === true ? `url(${Images.backgroundDashboard})` : ''
        }}
        className={`${!isSalesRepApproved ? 'dashboard-container' : 'dashboard-height'} ${
          !isCompanyApproved && !isSalesRepApproved ? 'image-container' : ''
        } `}
      >
        {loader && <Loader />}
        {userCompanyStatus && (
          <div className={`${!isSalesRepApproved ? 'content-container' : ''}`}>
            {isCompanyApproved === false &&
            !isSalesRepApproved &&
            userLoginRole !== accessRoles.sales_rep ? (
              <EmptyPage
                label={registerHeading}
                disabled={false}
                showPrimaryButton={!userCompanyStatus?.companyRegisteredStatus}
                showNote={true}
                note={
                  userCompanyStatus?.companyRegisteredStatus
                    ? userCompanyStatus?.companyRegStatusDescription
                    : registerDescription
                }
                heading={
                  userCompanyStatus?.companyRegisteredStatus
                    ? userCompanyStatus?.companyRegisteredStatus.replace('_', ' ')
                    : i18next.t('HOME_PAGE.USER_STATUS.PENDING')
                }
                handleClick={() => openPopUp()}
                isHideEmpty={true}
              />
            ) : userLoginRole === accessRoles.sales_rep ? (
              <>
                <SalesRepDashboard {...props} sellerList={sellerList} />
              </>
            ) : (
              <>
                <img src={Images.monitor} className='image-dashboard' />
                <div className='dashboard-text'>
                  {i18next.t('DYNAMIC_FORM.DASHBOARD.TEXT_1')}
                  <br />
                  {i18next.t('DYNAMIC_FORM.DASHBOARD.TEXT_2')} !
                </div>
              </>
            )}
          </div>
        )}
        {RegisterComponent}
        <Portal>
          {snackBarFlag && snackBarMsg && (
            <Snackbar
              open={snackBarFlag}
              autoHideDuration={3000}
              onClose={() => {
                setSnackBarFlag(false);
                setSnackBarMsg('');
                setSnackBarSeverity('');
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert severity={snackBarSeverity}>{snackBarMsg}</Alert>
            </Snackbar>
          )}
        </Portal>
        <Footer customClassName='d-lg-none' />
      </div>
    </div>
  );
};

const mapStateToProps = (redux_state) => ({
  user: redux_state.loginReducer.user
});

export default connect(mapStateToProps)(Dashboard);
