import axios from 'axios';
import { GET_PRODUCT_QUANTITY } from '../../actions/configuration';
import { GAEventAction } from '../../data/ga-constants';
import { trackEvent } from '../../utils/analytics';

let cancelTokenSource = null;

const cancelPreviousRequest = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Previous request cancelled');
  }
  cancelTokenSource = axios.CancelToken.source();
};

export function stockAvailApi(body, isTriggerGAEvent) {
  return (dispatch) => {
    cancelPreviousRequest();
    axios
      .put(GET_PRODUCT_QUANTITY, body, {
        cancelToken: cancelTokenSource.token
      })
      .then((response) => {
        dispatch({
          type: 'STOCK_AVAIL_API',
          payload: response.data
        });

        //GA-314
        if (isTriggerGAEvent) {
          trackEvent(
            'create_product_update_stock',
            GAEventAction.productStockUpdated,
            body?.productName
          );
        }
      })
      .catch((error) => {});
  };
}

export function getProductQuantity(productId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_PRODUCT_QUANTITY + `/${productId}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_PRODUCT_QUANTITY_DETAILS',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {});
}
