const initialState = {
  retrievedApiSkuIds: []
};

const ApiSkuIdsReducer = (state = initialState, action) => {
  if (action.type === 'API_RETRIEVE_SKUIDS') {
    return Object.assign({}, state, { retrievedApiSkuIds: action.payload });
  }

  return state;
};

export default ApiSkuIdsReducer;
