import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Errors from '../errors';
import { handleMaxCountText } from '../../../shared/shared-function/helperFunctions';

import './index.scss';

const TextArea = ({
  description,
  displayName,
  className,
  isElementRequired,
  value,
  handleChange,
  name,
  error,
  validation,
  dataTestId,
  textAreaClassname,
  ...props
}) => {
  let val = {};
  let length = {};
  let regex = {};
  let required = {};
  if (validation) {
    val = validation;
    const { rule } = val;
    length = rule?.length;
    regex = rule?.regex;
    required = rule?.required;
  }

  const validateInputs = (values) => {
    const data = {
      value: values,
      error: '',
      name: name
    };
    const regexExp = new RegExp(regex?.pattern, 'gmu');

    switch (true) {
      case values.length === 0 && required?.isRequired:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
        data.isInValid = true;
        return data;
      case values.length > 0 &&
        regexExp &&
        regexExp.toString().length > 0 &&
        !regexExp?.test(values):
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_INVALID')}.`;
        data.isInValid = true;
        return data;
      case values.length > 0 && values.length < length?.min:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_TOO_SHORT')} (${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MIN_CHAR')} - ${length?.min}).`;
        data.isInValid = true;
        return data;
      case values.length > 0 && values.length > length?.max:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_TOO_LONG')} (${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - ${length?.max}).`;
        data.isInValid = true;
        return data;
      default:
        return data;
    }
  };

  const handleInputChange = (event) => {
    const data = {
      value: event.target.value,
      error: '',
      name: name
    };

    handleChange && handleChange(data);
  };

  const handlevalidation = (event) => {
    const data = validateInputs(event.target.value.trim());
    handleChange && handleChange(data);
  };

  let cName = 'a-text-area';
  if (props.disabled === true) {
    cName += ' a-text-area--disabled';
  }

  return (
    <React.Fragment>
      <div className={`${cName} ${className}`}>
        <label>
          {displayName}
          {required?.isRequired && <span className='input__required'>*</span>}
        </label>
        <textarea
          onChange={handleInputChange}
          value={value}
          data-testid={dataTestId}
          onBlur={handlevalidation}
          className={textAreaClassname}
          maxLength={length?.max}
          {...props}
        />
      </div>

      {error ? (
        <Errors errorMessaage={error} />
      ) : (
        <div className='input__details'>
          <p className='input__addinal-info -size-s'>{description}</p>
          {length?.max && (
            <p className='input__addinal-info input__max-char -size-s'>{`${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - ${handleMaxCountText(
              length?.max,
              value
            )}`}</p>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

TextArea.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  required: PropTypes.bool
};

TextArea.defaultProps = {
  type: '',
  className: '',
  label: '',
  multiple: false,
  min: 0,
  max: 1000,
  message: '',
  required: false
};

export default TextArea;
