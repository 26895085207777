import React from 'react';
import './index.scss';

const SidebarContentWrapper = ({ children, className }) => {
  let cssName = 'sidebar-content';
  if (className) {
    cssName += ' ' + className;
  }
  return <div className={cssName}>{children}</div>;
};

export default SidebarContentWrapper;
