const initialState = {
  uploadDocErr: null,
  chequeDocument: ''
};

const DocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPLOAD_CHEQUE_ERROR':
      return Object.assign({}, state, { uploadDocErr: action.payload });
    case 'CHEQUE_DOCUMENT':
      return Object.assign({}, state, { chequeDocument: action.payload });
    default:
      return state;
  }
};

export default DocumentReducer;
