import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Loader from '../../Shared/Utils/Loader';
import SubscribeAlertsDialog from './SubscribeAlertsDialog';
import MaterialTable from 'material-table';
import AlertIcon from '@material-ui/icons/NotificationsActiveOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircle';
import IdConstants from '../../../data/idConstants';
import Snackbar from '../../Shared/Utils/SnackBar';
import Images from '../../../assets/img';
import variables from '../../../variables.module.scss';
import { materialTableIcons } from '../../../utils/material-table-icons';

const useStylesAlign = makeStyles((theme) => ({
  root: {
    padding: '25px',
    align: 'center',
    position: 'absolute'
  },
  breadCrumbStyling: {
    margin: '0.5rem',
    fontFamily: variables.generalBoschFont,
    fontSize: '0.9rem',
    fontWeight: '400',
    textDecoration: 'none',
    color: '#005691'
  },
  headingTag: {
    fontFamily: variables.boldBoschFont,
    padding: '20px',
    fontStyle: 'bold',
    fontSize: '1.2rem'
  },
  breadcrumbGrid: {
    marginTop: '1rem'
  },
  tickColor: {
    color: 'green'
  },
  avatarSettings: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    float: 'left',
    marginRight: '15px'
  },
  apiName: {
    paddingTop: '10px'
  },
  checkBoxField: {
    float: 'left',
    marginRight: '15px'
  }
}));

export default function ApiAlerts(props) {
  const classesStyles = useStylesAlign();
  const [openDialogStatus, setopenDialogStatus] = useState(false);
  const [selectedProdId, setSelectedProdId] = useState('');

  const columns = [
    {
      title: 'API Name',
      field: 'productName',
      width: 200,
      cellStyle: {
        backgroundColor: '#EEE'
      },
      render: (rowData) => {
        return (
          <div>
            <div>
              <img
                src={
                  rowData.thumbnailUrl ? rowData.thumbnailUrl : Images.api.thumbnail
                }
                className={classesStyles.avatarSettings}
                alt=''
              />
            </div>
            <div className={classesStyles.apiName}>{rowData.productName}</div>
          </div>
        );
      }
    },
    {
      title: 'Alert Details',
      width: 550,
      render: (rowData) => {
        return (
          <div>
            <div className={classesStyles.checkBoxField}>
              {rowData.alertConfigs.map(
                (data) =>
                  data.alertTypeId === IdConstants.alertType.abnormalBackendTime &&
                  data.enabled && (
                    <div>
                      <CheckCircleOutlinedIcon className={classesStyles.tickColor} />{' '}
                      Abnormal Backend time
                    </div>
                  )
              )}
            </div>
            <div>
              {rowData.alertConfigs.map(
                (data) =>
                  data.alertTypeId === IdConstants.alertType.abnormalResponseTime &&
                  data.enabled && (
                    <div>
                      <CheckCircleOutlinedIcon className={classesStyles.tickColor} />{' '}
                      Abnormal Response time
                    </div>
                  )
              )}
            </div>
            <div>
              {rowData.alertConfigs.some((data) => data.enabled === true) ? (
                ''
              ) : (
                <div>No Alerts Subscribed</div>
              )}
            </div>
          </div>
        );
      }
    }
  ];

  const openAlertSubscriptionDialog = (prodId) => {
    props.getProdSpecificApiAlerts(prodId).then(() => {
      setopenDialogStatus(true);
      setSelectedProdId(prodId);
    });
  };

  const closeDialog = () => {
    setopenDialogStatus(false);
  };

  const changeSnackbarState = () => {
    props.resetSuccess();
  };

  return (
    <React.Fragment>
      <Grid item xs={10} className={classesStyles.breadcrumbGrid}>
        <Typography variant='subtitle1' className={classesStyles.headingTag}>
          Manage API Alerts
        </Typography>
      </Grid>
      {props.loader.loader && <Loader />}
      <MaterialTable
        icons={materialTableIcons()}
        columns={columns}
        data={props.allApiAlerts}
        options={{
          rowStyle: {
            fontFamily: variables.generalBoschFont,
            detailPanelType: 'single',
            tableLayout: 'fixed'
          },
          headerStyle: {
            fontFamily: variables.boldBoschFont,
            borderBottom: '4px solid #bfc0c2',
            fontWeight: 'normal',
            fontSize: '16px',
            padding: '15px 13px'
          },
          draggable: false,
          actionsColumnIndex: -1,
          search: true,
          showTitle: false,
          paging: props.allApiAlerts.length > 10,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30, 40, 50],
          actionsCellStyle: {
            backgroundColor: '#EEE',
            justifyContent: 'center',
            display: 'flex',
            width: 'auto',
            height: '71px'
          }
        }}
        actions={[
          {
            icon: AlertIcon,
            tooltip: 'Configure Alerts',
            onClick: (event, rowData) =>
              openAlertSubscriptionDialog(rowData.productId)
          }
        ]}
      />

      {openDialogStatus && (
        <SubscribeAlertsDialog
          prodId={selectedProdId}
          {...props}
          onClose={closeDialog}
        />
      )}

      {!props.loader.loader && props.success && (
        <Snackbar
          severity='success'
          message={props.success}
          changeState={changeSnackbarState}
        />
      )}
    </React.Fragment>
  );
}
