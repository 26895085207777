import Images from '../assets/img';

export const apiTypes = [
  {
    id: 'ed621a2f-1f6a-4469-b82b-c10500417be3',
    name: 'REST API',
    description: 'REST',
    img: Images.restApiImg,
    disabled: false
  },
  {
    id: '3f716c2a-7121-48c0-83b5-98f7275fd4fc',
    name: 'SOAP API',
    description: 'SOAP',
    img: Images.soapApiImg,
    disabled: true
  },
  {
    id: '477ae8ee-7f58-4801-84f5-48f9b45022c5',
    name: 'Web Services',
    description: 'Web Services',
    img: Images.webServicesImg,
    disabled: true
  },
  {
    id: '5d8382c4-b539-4682-963e-83933f39720c',
    name: 'JSON-RPC',
    description: 'JSON-RPC',
    img: Images.jsonRPCImg,
    disabled: true
  }
];

export const errorCodes = {
  saveProdInfoErrorCode: 'E00001',
  publishErrorCode: 'E000046',
  uniquePlanNameError: 'E000126',
  defaultPlanNameError: 'E000368',
  generateAuthKeyError: 'E000099',
  baseUrlError: 'E000162'
};

export const separatorData = [
  {
    id: '1',
    name: 'Comma',
    value: ',',
    label: 'Comma'
  },
  {
    id: '2',
    name: 'Space',
    value: ' ',
    label: 'Space'
  }
];

export const clientAuthData = [
  {
    id: 'Header',
    name: 'Send as Basic Auth Header',
    label: 'Send as Basic Auth Header'
  },
  {
    id: 'Body',
    name: 'Send in Body',
    label: 'Send in Body'
  }
];

export const parameterConstants = {
  headerConst: 'Header',
  queryConst: 'Query',
  formDataConst: 'Form-Data',
  formUrlEncodedConst: 'Form-URL-Encoded',
  rawBodyConst: 'Raw-Body'
};

export const groupHeading = [
  {
    name: 'Name',
    prop: 'name'
  },
  {
    name: 'Description',
    prop: 'description'
  }
];

export const responseCodeHeading = [
  {
    name: 'Class',
    prop: 'class'
  },
  {
    name: 'Response code',
    prop: 'response code'
  }
];

export const endpointHeadings = [
  {
    name: 'Name',
    prop: 'name'
  },
  {
    name: 'Group',
    prop: 'group'
  },
  {
    name: 'Method',
    prop: 'method'
  },
  {
    name: 'Description',
    prop: 'description'
  },
  {
    name: 'Relative Path',
    prop: 'relativePath'
  },
  {
    name: 'Parameters',
    prop: 'parameters'
  }
];

export const rowperpagemenu = [
  {
    name: '5',
    value: 5
  },
  {
    name: '10',
    value: 10
  },
  {
    name: '15',
    value: 15
  }
];

export const apiMethods = ['GET', 'PUT', 'POST', 'DELETE', 'PATCH'];
export const settingsTableHeading = [
  {
    name: 'Type',
    prop: 'type'
  },
  {
    name: 'Name',
    prop: 'name'
  },
  {
    name: 'Value',
    prop: 'value'
  },
  {
    name: 'Description',
    prop: 'description'
  }
];

export const ipConditionTypes = [
  { id: 'SPECIFIC_IP', name: 'Specific IP' },
  { id: 'IP_RANGE', name: 'IP Range' }
];

export const accessTypes = [
  { id: 'ALLOW_ACCESS', name: 'Allow Access' },
  { id: 'RESTRICT_ACCESS', name: 'Restrict Access' }
];

export const accessRoles = {
  buyer: 'BUYER',
  seller: 'SELLER',
  sales_rep: 'SALES_REP'
};

export const contentTypeConstants = {
  notApplicable: 'Not Applicable',
  urlEncoded: 'application/x-www-form-urlencoded',
  multipartData: 'multipart/form-data',
  applicationJson: 'application/json',
  applicationXml: 'application/xml'
};

export const samplePayloads = {
  json: '{"key1": "value","key2": "value"}',
  xml: '<?xml version="1.0" encoding="UTF-8"?><example><heading>XML example</heading><body>example!</body></example>'
};

export const contentTypeData = [
  {
    id: 'application/json',
    name: 'application/json'
  },
  {
    id: 'application/xml',
    name: 'application/xml'
  },
  {
    id: 'text/plain',
    name: 'text/plain'
  }
];

export const dataTypeConstants = [
  {
    id: 'Integer',
    name: 'Integer',
    isValid: true
  },
  {
    id: 'Array',
    name: 'Array',
    isValid: false
  },
  {
    id: 'Number',
    name: 'Number',
    isValid: true
  },
  {
    id: 'Object',
    name: 'Object',
    isValid: false
  },
  {
    id: 'String',
    name: 'String',
    isValid: true
  },
  {
    id: 'Boolean',
    name: 'Boolean',
    isValid: true
  },
  {
    id: 'File',
    name: 'File',
    isValid: false
  }
];

export const DATATYPE = {
  BOOLEAN: 'Boolean',
  STRING: 'String',
  INTEGER: 'Integer'
};

export const payloadConstants = {
  request: 'request',
  response: 'response'
};

export const masterDataConstants = {
  sellerContactNumber: 'seller_contact_number',
  sellerContactEmail: 'seller_contact_mail'
};

export const pricingPlanType = [
  {
    value: 'FREE_PLAN',
    name: 'Free plan',
    billingInfo: {
      billingTypeCode: 'FREE_PLAN'
    }
  },
  {
    value: 'ONE_TIME_PURCHASE',
    name: 'One Time Payment',
    billingInfo: {
      billingTypeCode: 'ONE_TIME_POST_PAYMENT'
    }
  },
  {
    value: 'SLAB_BASED_PRICING',
    name: 'Recurring payment',
    billingInfo: {
      billingTypeCode: 'RECURRING_PAYMENT'
    }
  },
  {
    value: 'FREE_POLICY',
    name: 'Free policy',
    billingInfo: {
      billingTypeCode: 'FREE_POLICY'
    }
  }
];

export const pricingSlabs = {
  maximumSlabs: 10,
  maximumToValue: 999999999
};

export const portalViewData = {
  MOBILE: 'mobile',
  TAB: 'tab',
  DESKTOP: 'desktop'
};

export const pricingPlanValidity = [
  {
    id: 1,
    name: 'Default - One Year'
  }
];

export const pricingTypes = [
  {
    id: 1,
    name: 'Flat Pricing',
    disabled: true,
    label: 'Flat pricing'
  },
  {
    id: 2,
    name: 'Slab based Pricing',
    disabled: false,
    label: 'Slab based pricing'
  }
];

export const tenantMapping = [
  {
    marketplaceName: 'M.OS',
    tenant: 'mos',
    region: 'IN'
  },
  {
    marketplaceName: 'L.OS',
    tenant: 'los',
    region: 'IN'
  },
  {
    marketplaceName: 'L.OS',
    tenant: 'loseu',
    region: 'EU'
  },
  {
    marketplaceName: 'L.OS',
    tenant: 'losna',
    region: 'NA'
  }
];

export const regionDetails = [
  {
    regionDisplayName: 'in',
    regionCode: 'APAC',
    legalEntityName: 'DYNAMIC_FORM.FOOTER.LEGAL_ENTITY_NAME_IN',
    name: 'apac',
    documents: {
      dataProtectionPolicy: '/v2/data-protection-policy.pdf',
      termsOfUse: '/v2/terms-of-use.pdf',
      corporateInformation: '/v2/corporate-information.pdf',
      legalNotice: '/v1/legal-notice.pdf'
    }
  },
  {
    regionDisplayName: 'eu',
    regionCode: 'EU',
    legalEntityName: 'DYNAMIC_FORM.FOOTER.LEGAL_ENTITY_NAME_EU',
    name: 'eu',
    documents: {
      dataProtectionPolicy: '/v3/data-protection-policy.pdf',
      termsOfUse: '/v3/terms-of-use.pdf',
      corporateInformation: '/v3/corporate-information.pdf',
      legalNotice: '/v2/legal-notice.pdf'
    }
  },
  {
    regionDisplayName: 'na',
    regionCode: 'NA',
    legalEntityName: 'DYNAMIC_FORM.FOOTER.LEGAL_ENTITY_NAME_NA',
    name: 'na',
    documents: {
      dataProtectionPolicy: '/v2/data-protection-policy.pdf',
      termsOfUse: '/v2/terms-of-use.pdf',
      corporateInformation: '/v2/corporate-information.pdf',
      legalNotice: '/v1/legal-notice.pdf'
    }
  }
];

export const alertSeverity = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const pageLimit = {
  INITIAL_PAGE_COUNT: 0,
  MAX_PAGE_COUNT: 10,
  INITIAL_PAGE: 0
};

export const quoteStatus = {
  requested: {
    description: 'REQUESTED'
  },
  submitted: {
    description: 'SUBMITTED'
  },
  inDraft: {
    description: 'DRAFT'
  },
  rejected: {
    description: 'REJECTED'
  },
  expired: {
    description: 'EXPIRED'
  },
  accepted: {
    description: 'ACCEPTED'
  },
  approved: {
    description: 'APPROVED'
  }
};

export const tabStatus = {
  new: {
    description: 'NEW'
  },
  completed: {
    description: 'COMPLETED'
  }
};

export const currencySymbols = {
  INR: '\u20B9 '
};

export const availabilityOptions = [
  {
    value: 'Standalone',
    label: 'Standalone'
  },
  {
    value: 'Workflow',
    label: 'Workflow'
  }
];

export const adapterAccessOptions = [
  {
    value: 'False'
  },
  {
    value: 'True'
  }
];

export const paymentOccurrenceTypes = [
  {
    id: 1,
    name: 'RECURRING',
    label: 'Recurring',
    selected: true
  }
];

export const defaultSlabDetails = {
  slabLevel: 0,
  minValue: '0',
  maxValue: '-1',
  cost: '0',
  disableUnlimited: false
};
