import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { accessRoles } from '../../data/appConstants';
import './index.scss';

export default function Popover({ isOpen, anchorEl, onClose, children }) {
  const userCompanyStatus = useSelector((state) => {
    return state?.CompanyDetailsReducer?.userCompanyStatus?.companyRegisteredStatus;
  });
  const user = useSelector((state) => {
    return state?.loginReducer?.user;
  });
  const popoverRef = useRef(null);

  const isSalesRep = user?.roles?.includes(accessRoles.sales_rep);
  let className = 'pending';
  if (!userCompanyStatus && !isSalesRep) {
    className += ' popover-styles';
  }

  const handleClick = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  if (!isOpen || !anchorEl) {
    return null;
  }

  return (
    <div className={className} ref={popoverRef}>
      {children}
    </div>
  );
}
