const initialState = {
  progress: false
};
const ProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_PROGRESS':
      return Object.assign({}, state, { progress: action.data });
    case 'HIDE_PROGRESS':
      return Object.assign({}, state, { progress: action.data });
    default:
      return state;
  }
};

export default ProgressReducer;
