import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Notification = ({
  cssName,
  content,
  icon,
  customImage,
  subContent,
  isNotification,
  children
}) => {
  return (
    <div
      className={`notification ${
        isNotification ? 'a-notification' : 'aggregate-container'
      } -neutral ${cssName}`}
      role='alert'
    >
      <div className='notification__wrapper'>
        {!customImage && icon && (
          <i className={`a-icon boschicon-bosch-ic-${icon}`}></i>
        )}
        {customImage || ''}
        {content && (
          <div
            id='notification-label-id-bar-with-icon-neutral'
            className='a-notification__content'
          >
            {content}
          </div>
        )}
      </div>
      {subContent && (
        <div className='notification__sub-content highlight'>{subContent}</div>
      )}
      {children}
    </div>
  );
};
export default Notification;

Notification.prototype = {
  isNotification: PropTypes.bool,
  icon: PropTypes.string
};

Notification.defaultProps = {
  isNotification: true,
  icon: ''
};
