import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import Popover from './popover';
import { Snackbar, Alert, Portal } from '@mui/material';
import {
  getDCSCompanyStatus,
  retrieveUserCompanyStatus
} from '../../actions/CompanyAction';
import getAllMasterData from '../../actions/MasterDataAction';
import idConstants from '../../data/idConstants';
import Profile from '../view-profile';
import { PrimaryButton } from '../../msreact-kit/packages/html-components';
import './index.scss';
import { RegisterCompany } from '@mobilitystore/user-onboarding-interface';
import { getEnvironment } from '../../utils';
import { getRegionCode } from '../../utils/get-region-details';
import Loader from '../Shared/Utils/Loader';
import * as Constants from '../../actions/configuration';
import { accessRoles } from '../../data/appConstants';
import isEmpty from '../../validator/isEmpty';
import {
  saveUserDetails,
  submitTermsConsent,
  setUserDetails
} from '../../actions/UserRegisterAction';
import { getUserDetails } from '../../actions/api/ApiStatisticsAction';
import i18next from 'i18next';
import { internalSellerUserOnboardingForm } from '../../data/internalSellerUserOnboardingForm';
import { GAEventAction } from '../../data/ga-constants';
import CustomToolTip from '../Shared/custom-tooltip';
import { trackEvent } from '../../utils/analytics';
import Settings from '../settings';

function UserAccount(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const reduxStore = useSelector((state) => state);
  const [snackBarFlag, setSnackBarFlag] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [userCompanyStatus, setUserCompanyStatus] = useState(
    reduxStore?.CompanyDetailsReducer?.userCompanyStatus
  );
  const companyDetailsError = reduxStore?.CompanyDetailsReducer?.companyDetailsError;
  const mismatchInDcsAndMsData =
    reduxStore?.CompanyDetailsReducer?.mismatchInDcsAndMsData;
  const dispatchRedux = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const isInternalSellerTenant =
    process.env.REACT_APP_SELLER_TENANT_ID === Constants.TENANT.MKOSINT;
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [dynamicForm, setDynamicForm] = useState('');
  const [loader, setLoader] = useState(false);
  const [isSalesRepApproved, setIsSalesRepApproved] = useState(true);
  const [userLoginRole, setUserLoginRole] = useState('');
  const userRegistrationSuccessMsg =
    reduxStore?.ProfileReducer?.userRegistrationSuccessMsg;
  const userRegistrationErrorMsg =
    reduxStore?.ProfileReducer?.userRegistrationErrorMsg;
  let pendingRegistration = i18next.t(
    'HOME_PAGE.USER_STATUS.PENDING_REG_DESCRIPTION'
  );
  let registerButtonText = i18next.t('HOME_PAGE.USER_STATUS.REGISTER_COMPANY');

  if (isInternalSellerTenant) {
    pendingRegistration = '';
    registerButtonText = i18next.t('INTERNAL_SELLER.REGISTER_NOW');
  }

  useEffect(() => {
    if (isEmpty(userRegistrationSuccessMsg)) return;
    setSnackBarFlag(true);
    setSnackBarMsg(userRegistrationSuccessMsg);
    setSnackBarSeverity('success');
    setLoader(false);
    dispatchRedux({
      type: 'USER_REGISTRATION_SUCCESS_ALERT',
      payload: ''
    });
  }, [userRegistrationSuccessMsg]);

  useEffect(() => {
    dispatchRedux(getAllMasterData(idConstants.api.categoryId));
  }, []);

  useEffect(() => {
    setUserCompanyStatus(reduxStore?.CompanyDetailsReducer?.userCompanyStatus);
  }, [reduxStore?.CompanyDetailsReducer?.userCompanyStatus]);

  useEffect(() => {
    if (isEmpty(userRegistrationErrorMsg)) return;
    setSnackBarFlag(true);
    setSnackBarMsg(userRegistrationErrorMsg);
    setSnackBarSeverity('error');
    setLoader(false);
    dispatchRedux({
      type: 'USER_REGISTRATION_ERROR_ALERT',
      error: ''
    });
  }, [userRegistrationErrorMsg]);

  useEffect(() => {
    if (userCompanyStatus && isInternalSellerTenant) {
      setDynamicForm(internalSellerUserOnboardingForm(userCompanyStatus));
    }
  }, [userCompanyStatus]);

  const handleLabelClick = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const signOut = () => {
    props.logout();

    //GA-101
    trackEvent('authentication', GAEventAction.signoutSuccess);
  };

  const handleClick = () => {
    setShowComponent(true);
  };

  const handleOnClick = () => {
    setShowSettings(true);
  };

  const callbackModal = () => {
    setShowComponent(false);
    handlePopOverClose();
  };

  const closeModal = () => {
    setShowSettings(false);
    handlePopOverClose();
  };

  React.useEffect(() => {
    if (mismatchInDcsAndMsData) {
      setSnackBarFlag(true);
      setSnackBarMsg(
        i18next.t('DYNAMIC_FORM.USER_ACCOUNT.MISMATCH_OCCURRED_IN_COMPANY_DETAILS')
      );
      setSnackBarSeverity('error');
    }
  }, [mismatchInDcsAndMsData]);

  React.useEffect(() => {
    dispatchRedux(getDCSCompanyStatus());
    dispatchRedux(retrieveUserCompanyStatus());
  }, []);

  React.useEffect(() => {
    let alertMsg = '';
    let snackAlertSeverityMsg = 'success';
    if (companyDetailsError && Object.keys(companyDetailsError).length > 0) {
      alertMsg = `${i18next.t('DYNAMIC_FORM.USER_ACCOUNT.ERROR')}: ${companyDetailsError.message}`;
      snackAlertSeverityMsg = 'error';
      setSnackBarFlag(true);
    }
    setSnackBarMsg(alertMsg);
    setSnackBarSeverity(snackAlertSeverityMsg);
  }, [companyDetailsError]);

  const openPopUp = () => {
    handlePopOverClose();
    setOpenRegisterModal(true);
    if (window.location.pathname !== '/dashboard') {
      props.history.push('/dashboard');
    }
  };

  const getIcon = (iconCss, menuClick) => {
    return (
      <i
        className={`a-icon ${iconCss} -size-l`}
        onClick={() => props.handleMenuClick(menuClick)}
      />
    );
  };

  useEffect(() => {
    if (Array.isArray(props.user?.roles)) {
      setIsSalesRepApproved(props.user?.roles?.includes(accessRoles.sales_rep));
    }
  }, [props.user?.roles]);

  useEffect(() => {
    const storedUserLoginRole = localStorage.getItem(idConstants.userLoginRole.role);
    if (storedUserLoginRole) {
      setUserLoginRole(storedUserLoginRole);
    }
  }, []);

  const profileStatus = () => {
    if (
      userCompanyStatus?.companyRegisteredStatus !== 'approved' &&
      !isSalesRepApproved &&
      userLoginRole !== accessRoles.sales_rep
    ) {
      if (
        props?.accessToken &&
        isEmpty(userCompanyStatus?.companyRegisteredStatus)
      ) {
        return (
          <React.Fragment>
            <div className='company-status-styling-module'>
              <div className='pending-field'>
                <p className='status-value highlight -size-m'>
                  {i18next.t('HOME_PAGE.USER_STATUS.PENDING')}
                </p>
                <p className='company-registration-status-box -size-m text'>
                  {pendingRegistration}
                </p>
                <PrimaryButton
                  className='register-button -size-m'
                  label={registerButtonText}
                  onClick={() => openPopUp()}
                />
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className='company-registration-status-box pending-approval-text'>
              <p className='status-value highlight'>
                {' '}
                {userCompanyStatus?.companyRegisteredStatus === 'denied' ? (
                  <i className='a-icon boschicon-bosch-ic-alert-error info-style'></i>
                ) : (
                  <i className='a-icon boschicon-bosch-ic-alert-info info-style'></i>
                )}
                {userCompanyStatus?.companyRegisteredStatus
                  ? userCompanyStatus.companyRegisteredStatus.replace('_', ' ')
                  : i18next.t('DYNAMIC_FORM.USER_ACCOUNT.PENDING_REGISTRATION')}
              </p>
              {userCompanyStatus?.companyRegStatusDescription}
            </div>
          </React.Fragment>
        );
      }
    }
  };

  const handleSuccessResponse = (response) => {
    if (
      process.env.REACT_APP_SELLER_TENANT_ID === Constants.TENANT.MKOSINT &&
      response
    ) {
      const userDetails = {};
      userDetails.firstName = response.companyData.userData.firstName;
      userDetails.lastName = response.companyData.userData.lastName;
      userDetails.contactNo =
        response.companyData.userData.isdCode +
        ' ' +
        response.companyData.userData.phoneNo;
      userDetails.countryCode = 'IN';
      userDetails.roleName = Constants.AAA_CONFIG.SELLER_ROLE;
      setLoader(true);
      dispatchRedux(setUserDetails(userDetails));
      saveUserDetails(userDetails, dispatchRedux).then((res) => {
        if (res.data.userId) {
          dispatchRedux(getUserDetails(res.data.userId));
          dispatchRedux(submitTermsConsent());
        }
      });
    } else if (!isEmpty(response?.companyRegStatusDescription)) {
      dispatchRedux({
        type: 'USER_COMPANY_STATUS',
        payload: {
          ...userCompanyStatus,
          companyRegStatusDescription: response.companyRegStatusDescription,
          companyRegisteredStatus: response.status
        }
      });
    }
  };

  const RegisterComponent = localStorage.getItem(idConstants.tokens.aaaIdToken) &&
    (isInternalSellerTenant
      ? userCompanyStatus?.companyRegisteredStatus !==
          idConstants.approved.description.toLowerCase() && dynamicForm
      : true) &&
    openRegisterModal && (
      <RegisterCompany
        language='en'
        clientToken={localStorage.getItem(idConstants.tokens.aaaIdToken)}
        environment={getEnvironment(process.env.REACT_APP_ENVIRONMENT)}
        successHandler={(response) => handleSuccessResponse(response)}
        cancelHandler={() => {
          setOpenRegisterModal(false);
        }}
        errorHandler={(error) => {
          if (error.message) {
            setSnackBarFlag(true);
            setSnackBarMsg(error.message);
            setSnackBarSeverity('error');
          }
        }}
        role={Constants.AAA_CONFIG.SELLER_ROLE}
        regionCode={getRegionCode()}
        fontName='boschsans'
        dynamicForm={dynamicForm}
      />
    );

  return (
    <React.Fragment>
      {RegisterComponent}
      {loader && <Loader />}
      <div className='username-block'>
        <i
          className='a-icon boschicon-bosch-ic-my-brand-frame -size-l'
          onClick={handleLabelClick}
        ></i>
        <div className='navigation-icon-wrapper'>
          {props.open
            ? getIcon('boschicon-bosch-ic-close', false)
            : getIcon('boschicon-bosch-ic-list-view-mobile', true)}
        </div>
      </div>
      <Popover isOpen={isOpen} anchorEl={anchorEl} onClose={handlePopOverClose}>
        <div className='user-account-details'>
          {userCompanyStatus?.user?.firstName &&
            (userCompanyStatus?.user?.firstName?.length > 30 ||
            userCompanyStatus?.user?.lastName?.length > 30 ? (
              <div title={userCompanyStatus?.user?.firstName}>
                <div className='user-account-usernames -size-m highlight'>
                  {userCompanyStatus?.user?.firstName}
                </div>
              </div>
            ) : (
              <div className='user-account-usernames -size-m highlight'>
                {userCompanyStatus?.user?.firstName +
                  ' ' +
                  userCompanyStatus?.user?.lastName}
              </div>
            ))}
          <CustomToolTip
            id={props.user?.emailId}
            tooltipData={props.user?.emailId}
            width='fixed'
          >
            <div className='user-account-email -size-m' id={props.user?.emailId}>
              {props.user?.emailId}
            </div>
          </CustomToolTip>
          <PrimaryButton
            label=''
            className='user-account-signout-button -size-m'
            onClick={signOut}
          >
            <span>{i18next.t('DYNAMIC_FORM.USER_ACCOUNT.SIGN_OUT')}</span>
            <i className='a-icon boschicon-bosch-ic-logout logout-icon -size-m' />
          </PrimaryButton>
        </div>
        {profileStatus()}
        {showComponent && <Profile callbackModal={callbackModal} />}
        <div className='user-account-dropdown-option -size-m' onClick={handleClick}>
          {i18next.t('DYNAMIC_FORM.USER_ACCOUNT.VIEW_PROFILE')}
        </div>
        {showSettings && <Settings callbackModal={closeModal} />}
        {props.user?.roles?.includes(accessRoles.sales_rep) &&
        userLoginRole === accessRoles.sales_rep ? (
          ''
        ) : (
          <div
            className='user-account-dropdown-option -size-m'
            onClick={handleOnClick}
          >
            {i18next.t('DYNAMIC_FORM.USER_ACCOUNT.SETTINGS')}
          </div>
        )}
      </Popover>
      <Portal>
        {snackBarFlag && snackBarMsg && (
          <Snackbar
            open={snackBarFlag}
            autoHideDuration={3000}
            onClose={() => {
              setSnackBarFlag(false);
              setSnackBarMsg('');
              setSnackBarSeverity('');
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBarSeverity}>{snackBarMsg}</Alert>
          </Snackbar>
        )}
      </Portal>
    </React.Fragment>
  );
}

const mapStateToProps = (redux_state) => ({
  accessToken: redux_state.loginReducer.accessToken,
  user: redux_state.loginReducer.user
});

export default connect(mapStateToProps)(UserAccount);
