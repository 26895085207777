import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Typography, Box, Grid, CardMedia } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Images from '../../../../assets/img';
import Breadcrumb from '../Breadcrumb';
import NavData from '../../../../data/navigationOptionsSeller';
import { withStyles } from '@material-ui/core/styles';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import { trackEvent } from '../../../../utils/analytics';
import UserAccount from '../../../UserAccount';
import { DashboardRoutes } from '../../../../routes/router';
import { clearLoginStorage } from '../../../../actions/LoginAction';
import { getCountryList } from '../../../../actions/CountryAction';
import { getProductCategories } from '../../../../actions/MasterDataAction';
import Footer from '../../../BaseLayout/footer';
import store from '../../../../store';
import DisplayOptions from '../../../../data/displayOptions';
import IdConstants from '../../../../data/idConstants';
import variables from '../../../../variables.module.scss';
import SideNavigation from '../../../../components/order-management/side-navigation';
import { portalViewData } from '../../../../../src/data/appConstants';
import { accessRoles } from '../../../../data/appConstants';

const drawerWidth = 320;
const useStyles = (theme) => ({
  root: {
    display: 'flex'
  },
  logo: {
    width: '9rem',
    paddingTop: '1px'
  },
  defaultColor: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  selected: {
    '&.Mui-selected:hover': {
      backgroundColor: '#005691',
      color: 'white'
    },
    '&.Mui-selected': {
      backgroundColor: '#005691',
      color: 'white'
    }
  },
  appBar: {
    background: 'white',
    boxShadow: 'none',
    marginTop: '6px',
    zIndex: '2',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: '20rem',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),

    '@media only screen and (max-width: 768px)': {
      marginLeft: 'unset'
    }
  },
  noNavSideBar: {
    width: 'unset',
    marginLeft: 'unset'
  },
  leftShift: {
    marginLeft: '73px'
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    marginTop: theme.spacing(1.8),
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    marginTop: theme.spacing(1.8),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'pre-wrap',
    padding: 0,
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
    position: 'absolute',
    bottom: '60px',
    top: 0,
    left: 0,
    right: 0
  },
  dashboard: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.09)',
    '@media only screen and (max-width: 768px)': {
      height: '100%'
    }
  },
  switchMarketLink: {
    fontFamily: variables.lightBoschFont,
    color: 'blue',
    cursor: 'pointer',
    marginRight: '10px'
  },
  switchlinkStyle: {
    marginLeft: '5px',
    cursor: 'pointer'
  },
  header: {
    textAlign: 'right',
    marginRight: '1rem',
    position: 'relative',

    '@media only screen and (max-width: 768px)': {
      display: 'none'
    }
  },
  noMainTypography: {
    display: 'none'
  },
  noContent: {
    display: 'none'
  }
});

class CollapsableSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      navOptions: NavData,
      modalContent: '',
      modalHeading: '',
      exitDialog: false,
      noRole: false,
      menu: false
    };
  }

  componentDidUpdate(prevProps) {
    const userLoginRole = localStorage.getItem(IdConstants.userLoginRole.role);

    if (this.props.productCategories !== prevProps.productCategories) {
      this.updateNavData();
    }

    // Redirect to '/dashboard' for unapproved users
    if (
      userLoginRole !== accessRoles.sales_rep &&
      this.props.userCompanyStatus?.companyRegisteredStatus !== 'approved' &&
      this.props.userCompanyStatus?.areCompanyDetailsCaptured === false &&
      window.location.pathname !== '/dashboard'
    ) {
      this.props.history.push('/dashboard');
    }
  }

  updateNavData = () => {
    const navData = this.state.navOptions;
    const myProductIndex = navData.findIndex(
      (option) => option.sidebarKey === IdConstants.sidebarConstants.myProducts.key
    );
    navData[myProductIndex]?.subMenus.forEach((option) => {
      option.disabled = this.props?.productCategories?.find(
        (list) => list.categoryId === option.categoryId
      )?.isActive
        ? false
        : true;
    });
    this.setState({
      navOptions: navData
    });
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true
    });
  };

  changeRoute = (option) => {
    this.setNavOptions(option.url);
    this.props.updateInfo(this.props.info, 'previousSession', 0);
    this.props.history.push({
      pathname: option.url,
      state: { ...this.props.location.state },
      key: option.key
    });
  };

  setNavOptions = (url) => {
    const navOptions = this.state.navOptions.map((element) => {
      if (element.url === url) {
        element.isActive = true;
        element.iconColor = 'white';
      } else {
        element.isActive = false;
        element.iconColor = 'gray';
      }
      return element;
    });
    this.setState({
      navOptions: navOptions
    });
  };

  componentDidMount() {
    store.subscribe(() => {
      if (store.getState().loginReducer.noRole === true) {
        this.setState({ noRole: store.getState().loginReducer.noRole });
      }
    });
    if (this.props.productCategories.length === 0) {
      this.props.getProductCategories();
    }
    this.updateNavData();
    this.props.getCountryList();
    this.setNavOptions(this.props.location.pathname);
    if (
      !localStorage.getItem(IdConstants.tokens.aaaIdToken) &&
      this.props.accessToken !== undefined
    ) {
      store.dispatch({
        type: 'ACCESS_TOKEN',
        payload: localStorage.getItem(IdConstants.tokens.aaaIdToken)
      });
      this.props.history.push('/login');
    }

    const apiStates = DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    ).filters;
    apiStates.forEach((state) => {
      if (
        this.props.location.pathname.substring(
          0,
          this.props.location.pathname.lastIndexOf('/addapi')
        ) === state.url
      ) {
        this.props.updateInfo(this.props.info, 'previousSession', state.index);
      }
    });
  }

  okBtnAction = () => {
    this.changeRoute(this.state.option);
  };

  handleOpenConfirmModal = () => {
    this.setState({
      exitDialog: false
    });
  };

  handleMenuClick = (data, trackEventData) => {
    this.setState({ menu: data });
    if (trackEventData) {
      trackEvent(
        trackEventData?.eventCategory,
        trackEventData?.eventAction,
        trackEventData?.eventLabel,
        trackEventData?.eventValue
      );
    }
  };

  render() {
    const { classes } = this.props;
    const urlLength = this.props.location.pathname.split('/');
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          color='inherit'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: urlLength.lastIndex < 6 && this.state.open,
            [classes.appBarShiftApi]: urlLength.lastIndex == 6 && this.state.open
          })}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={this.handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.open
              })}
            >
              <MenuIcon />
            </IconButton>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              sm
              style={{ width: '100%', gap: '1rem' }}
            >
              <Grid item xs={4}>
                <Box display='flex' justifyContent='flex-start'>
                  <CardMedia
                    image={Images.boschLogo}
                    title='Bosch logo'
                    component='img'
                    className={classes.logo}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Breadcrumb value={this.props.breadCrumbValue}></Breadcrumb>
              </Grid>
              <Grid item xs={4}>
                <h1 className={`${classes.header} -size-l`}>
                  {i18next.t('SHARED.UNIFIED_SELLER_PORTAL')}
                </h1>
              </Grid>
              <Grid item>
                {this.props.accessToken && (
                  <UserAccount
                    {...this.props}
                    handleMenuClick={this.handleMenuClick}
                    open={this.state.menu}
                  />
                )}
              </Grid>
            </Box>
          </Toolbar>
          <Divider />
        </AppBar>
        {
          <SideNavigation
            open={this.state.menu}
            handleMenuClick={this.handleMenuClick}
            customClassName={urlLength.lastIndex >= 6 && 'api_side_nav_onboarding'}
          />
        }
        <main
          className={
            this.state.menu
              ? classes.noContent
              : clsx(classes.content, {
                  [classes.appBarShift]: urlLength.lastIndex < 6 && this.state.open,
                  [classes.leftShift]: !this.state.open,
                  [classes.noNavSideBar]: urlLength.lastIndex == 6 && this.state.open
                })
          }
          id='main-content'
        >
          <div className={classes.toolbar} />
          {(() => {
            if (this.state.exitDialog === true) {
              return (
                <ConfirmModal
                  modalContent={this.state.modalContent}
                  heading={this.state.modalHeading}
                  callback={this.handleOpenConfirmModal}
                  okBtnAction={this.okBtnAction}
                ></ConfirmModal>
              );
            }
          })()}
          <Typography
            className={
              this.props.location.pathname === '/dashboard' && classes.dashboard
            }
          >
            <DashboardRoutes currentState={this.props.info.previousSession} />
          </Typography>
        </main>
        <Grid
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open
          })}
        >
          {this.props?.portalView !== portalViewData.MOBILE &&
            this.props?.portalView !== portalViewData.TAB && <Footer />}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  user: redux_state.loginReducer.user,
  breadCrumbValue: redux_state.SolutionReducer.breadCrumbValue,
  accessToken: redux_state.loginReducer.accessToken,
  productCategories: redux_state.MasterDataReducer.productCategories,
  portalView: redux_state.loginReducer.portalView,
  userCompanyStatus: redux_state.CompanyDetailsReducer.userCompanyStatus
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearLoginStorage,
      getCountryList,
      getProductCategories
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CollapsableSideBar));
