import axios from 'axios';
import * as Constants from './configuration';
import IdConstants from '../data/idConstants';

export function getProductDocsListById(productId, documentTypeId) {
  return (dispatch) =>
    axios
      .get(
        Constants.PRODUCT_DETAIL_ACTION.GET_PRODUCT_DOCS_LIST_BY_ID +
          productId +
          (documentTypeId ? '?documentTypeId=' + documentTypeId : ''),
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
          actionType: 'getDocuments'
        }
      )
      .then((response) => {
        if (documentTypeId === IdConstants.documentType.videoFile) {
          dispatch({
            type: 'GET_PRODUCT_VIDEOS_BY_ID',
            payload: response.data
          });
        } else if (documentTypeId === IdConstants.documentType.videoLinks) {
          dispatch({
            type: 'GET_PRODUCT_VIDEOS_LINK_BY_ID',
            payload: response.data
          });
        } else if (documentTypeId === IdConstants.documentType.thumbnailImg) {
          dispatch({
            type: 'GET_PRODUCT_THUMBNAIL_IMG',
            payload: response.data
          });
        } else if (documentTypeId === IdConstants.documentType.coverImg) {
          dispatch({
            type: 'GET_PRODUCT_COVER_IMG',
            payload: response.data
          });
        } else {
          dispatch({
            type: 'GET_PRODUCT_DOCS_BY_ID',
            payload: response.data
          });
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
export function getDocumentData(gridfsDocId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: Constants.PRODUCT_DETAIL_ACTION.GET_DOCUMENT_DATA + gridfsDocId,
      responseType: 'arraybuffer',
      data: {}
    })
      .then((response) => {
        dispatch({
          type: 'GET_DOCUMENT_DATA',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getProductVisibilityData(productId) {
  return (dispatch) =>
    axios({
      method: 'get',
      url: Constants.SOLUTION_ACTION.PRODUCT_VISIBILITY + '/' + productId,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    })
      .then((response) => {
        dispatch({
          type: 'GET_IS_VISIBLE_DATA',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function postProductVisibilityData(productId, visibility) {
  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        Constants.SOLUTION_ACTION.PRODUCT_VISIBILITY +
        '/' +
        productId +
        `?isVisible=` +
        visibility,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_IS_VISIBLE_DATA',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
