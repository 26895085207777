export const SAVE_FORM_JSON_DATA = 'SAVE_FORM_JSON_DATA';
export const SAVE_FORM_BASIC_INFO = 'SAVE_FORM_BASIC_INFO';
export const SAVE_FORM_ADDITIONAL_INFO = 'SAVE_FORM_ADDITIONAL_INFO';
export const SAVE_HARDWARE_PRODUCTS = 'SAVE_HARDWARE_PRODUCTS';
export const SAVE_ALL_HARDWARE_PRODUCTS = 'SAVE_ALL_HARDWARE_PRODUCTS';
export const GET_ORDER_SUMMARY = 'GET_ORDER_SUMMARY';
export const GET_ORDER_STATUS = 'GET_ORDER_STATUS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
export const POST_COMMENT = 'POST_COMMENT';
export const SET_LOADER = 'SET_LOADER';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const CHANGE_ERROR_STATE = 'CHANGE_ERROR_STATE';
export const GET_REJECTION_REASON = 'GET_REJECTION_REASON';
export const RESET_ORDER_STATE = 'RESET_ORDER_STATE';
export const SAVE_TERMS_AND_CONDITION_FILE = 'SAVE_TERMS_AND_CONDITION_FILE';
export const GET_TENANT_NAME = 'GET_TENANT_NAME';
export const GET_ACCESS_KEY = 'GET_ACCESS_KEY';
export const RESET_ACCESS_KEY = 'RESET_ACCESS_KEY';
export const UPDATE_PART_NUMBER = 'UPDATE_PART_NUMBER';
export const SAVE_DPN_DOCUMENT = 'SAVE_DPN_DOCUMENT';
export const SAVE_FORM_JSON_DATA_ONLY_VALUE = 'SAVE_FORM_JSON_DATA_ONLY_VALUE';
export const PART_NUMBER_DATA = 'PART_NUMBER_DATA';
export const RESET_PART_NUMBER_DATA = 'RESET_PART_NUMBER_DATA';
export const GET_PRICING_DETAILS = 'GET_PRICING_DETAILS';
