import { getNestedVal } from '../api-parser/clone-parser';
import { DynamicFieldConstants } from '../constants';
import { FieldConstants } from '../constants/field-constants';

export const handleParseXpath = (xpath, data) => {
  const result = xpath
    .split('/')
    .map((element) => element.trim())
    .filter((element) => element !== '');

  Array.isArray(data) && data.forEach((option) => delete option.label);

  let object = {},
    parsedData = object;
  for (let index = 0; index < result.length; index++) {
    if (index === result.length - 1) {
      parsedData = parsedData[result[index]] = data;
    } else {
      parsedData = parsedData[result[index]] = {};
    }
  }
  return object;
};

const handleCheckValue = (obj) => {
  for (const key in obj) {
    if (obj[key] && obj[key]?.length > 0) return true;
  }
  return false;
};

const findElement = (arr1, obj) => {
  if (arr1?.length > 0) {
    const commonObject = arr1?.find((item1) => item1?.value === obj?.value);
    return commonObject || null;
  }
};

const basicInfoParser = (data, categoryId, additionalInfo) => {
  const reqObject = {};
  const prodBasicInfo = {};
  const productCategory = {};
  const productDetailedInfo = {};
  productCategory.categoryId = categoryId;
  if (
    data.attributes &&
    data.attributes.length > 0 &&
    data.name === 'prodBasicInfo'
  ) {
    data.attributes.forEach((element) => {
      if (element.name === 'productBusinessCategoryMap') {
        prodBasicInfo.productBusinessCategoryMap = element.value.map((item) => ({
          businessCategory: {
            businessCategoryId: item.id.toString(),
            businessCategoryName: item.value
          }
        }));
        prodBasicInfo['businessCategory'] = element.value.map((item) => ({
          key: item.id.toString(),
          value: item.value,
          id: item.id.toString()
        }));
      } else {
        if (element.name === 'productName') {
          prodBasicInfo[element.name] = element.value.trim();
        } else if (element.attributeType !== 'HIDDEN') {
          prodBasicInfo[element.name] = element.value;
        }
      }
    });
  } else if (data.groups && data.groups.length > 0) {
    const groupPayload = [];
    data.groups.forEach((element, index) => {
      const payload = {};
      element.attributes.forEach((field) => {
        if (field.attributeType === DynamicFieldConstants.SINGLE_SELECT) {
          productDetailedInfo[field.name] = field.value
            ? {
                key: field.value.id.toString(),
                value: field.value.value,
                id: field.value.id.toString(),
                ...(element.name === FieldConstants.countryOfOrigin && {
                  label: element.options.find(
                    (option) => option.value === field.value.value
                  )?.label
                })
              }
            : undefined;
        } else {
          if (
            field.attributeType !== DynamicFieldConstants.FILE &&
            field.attributeType !== DynamicFieldConstants.UPLOAD_IMAGE &&
            field.attributeType !== 'HIDDEN'
          ) {
            payload[field.name] =
              field.value && field.value.length > 0 ? field.value : '';
            if (field?._id) {
              payload['_id'] = field?._id;
            }
          }

          if (handleCheckValue(payload)) {
            payload['displayOrder'] = index.toString();
          }
          if (
            field?.productDocMetaInfoId &&
            field?.productDocMetaInfoId?.length > 0
          ) {
            payload['attachmentKey'] = field?.productDocMetaInfoId;
          }
        }
      });

      const isValPresent = handleCheckValue(payload);
      if (payload && isValPresent) {
        groupPayload.push(payload);
      }
    });
    const getXpathData = handleParseXpath(data.groups[0].xpath, groupPayload);

    reqObject.productDetailedInfo = getXpathData.productDetailedInfo;
  } else if (data.attributes && data.attributes.length > 0) {
    data.attributes.forEach((element) => {
      if (element.attributeType === DynamicFieldConstants.SINGLE_SELECT) {
        productDetailedInfo[element.name] =
          element.value && element.value.id
            ? {
                key: element.value.id.toString(),
                value: element.value.value,
                id: element.value.id.toString()
              }
            : undefined;
      } else if (
        element.attributeType === DynamicFieldConstants.MULTI_SELECT &&
        element.name != 'productBusinessCategoryMap'
      ) {
        productDetailedInfo[element.name] = element?.value?.map((item) => {
          const result = element.xpath
            .split('/')
            .map((element) => element.trim().toString())
            .filter((element) => element !== '');
          const obj = {
            key: item.id,
            value: item.value,
            id: item.id,
            ...(element.name === FieldConstants.countryOfUsage && {
              label: element.options.find((option) => option.value === item.value)
                ?.label
            })
          };
          if (item?._id) {
            obj['_id'] = item._id;
          } else {
            if (result) {
              const assignField = getNestedVal(
                { productDetailedInfo: { ...additionalInfo } },
                result
              );
              const isValuePresent = findElement(assignField, obj);
              if (isValuePresent?._id) {
                obj['_id'] = isValuePresent._id;
              }
            }
          }
          return obj;
        });
      } else {
        if (
          element.attributeType !== DynamicFieldConstants.FILE &&
          element.attributeType !== DynamicFieldConstants.UPLOAD_IMAGE
        ) {
          productDetailedInfo[element.name] =
            element.value &&
            (element.value.length > 0 || element?.value?.toString().length)
              ? element.value
              : undefined;
        }
      }
    });
    const getXpathData = handleParseXpath(data.xpath, productDetailedInfo);
    reqObject.productDetailedInfo = getXpathData.productDetailedInfo;
  }
  prodBasicInfo.productCategory = { ...productCategory };
  reqObject.prodBasicInfo = { ...prodBasicInfo };

  return reqObject;
};

export default basicInfoParser;
