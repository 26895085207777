import React, { useState } from 'react';
import i18next from 'i18next';
import { TextInput } from '../../msreact-kit/packages/html-components';
import { getProductDetailsFromBoschSystem } from '../../actions/api/BoschSystemAction';
import CustomizedSnackbars from '../../components/Shared/Utils/SnackBar';
import './index.scss';
const BoschSystem = (props) => {
  const [productId, setProductId] = useState('');
  const [displaySnackBar, setDisplaySnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');

  const handleInputChange = (event) => {
    setProductId(event.target.value);
  };
  const handleSubmitClick = () => {
    const payload = {
      productId: productId
    };

    getProductDetailsFromBoschSystem(payload)
      .then((response) => {
        props.redirectToAddProduct(response);
      })
      .catch((error) => {
        setDisplaySnackBar(true);
        setSnackBarMsg(error.message);
        setSnackBarSeverity('error');
      });
  };
  const changeState = () => {
    setDisplaySnackBar(false);
  };

  return (
    <div className='swagger-container'>
      <div className='swagger-container-wrapper'>
        <div className='heading'>
          {i18next.t('DYNAMIC_FORM.SHARED.ADD_NEW_PRODUCT.BOSCH_SYSTEM.HEADING')}
        </div>
        <div className='sub-heading'>
          {i18next.t('DYNAMIC_FORM.SHARED.ADD_NEW_PRODUCT.BOSCH_SYSTEM.SUB_HEADING')}
        </div>
        <div className='product-id-heading'>
          {i18next.t(
            'DYNAMIC_FORM.SHARED.ADD_NEW_PRODUCT.BOSCH_SYSTEM.PRODUCT_ID_HEADING'
          )}
        </div>
        <TextInput
          displayName={i18next.t(
            'DYNAMIC_FORM.SHARED.ADD_NEW_PRODUCT.BOSCH_SYSTEM.PRODUCT_ID'
          )}
          validation={{
            rule: { required: { isRequired: true }, length: { max: 50 } }
          }}
          onChange={handleInputChange}
          value={productId}
        />
        <button
          className='submit_btn'
          type='submit'
          onClick={handleSubmitClick}
          disabled={!productId}
        >
          {i18next.t('DYNAMIC_FORM.SHARED.ADD_NEW_PRODUCT.BOSCH_SYSTEM.SUBMIT')}
        </button>
      </div>
      <div>
        {displaySnackBar && (
          <CustomizedSnackbars
            severity={snackBarSeverity}
            message={snackBarMsg}
            changeState={changeState}
          />
        )}
      </div>
    </div>
  );
};

export default BoschSystem;
