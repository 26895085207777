import React from 'react';
import PropTypes from 'prop-types';

import Button from './button';

const PrimaryButton = ({ className, ...props }) => {
  let cssName = 'a-button--primary';
  if (className) {
    cssName += ` ${className}`;
  }
  return <Button className={cssName} {...props} />;
};

PrimaryButton.propTypes = {
  className: PropTypes.string
};

PrimaryButton.defaultProps = {
  className: ''
};

export default PrimaryButton;
