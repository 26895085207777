const initialState = {
  countryList: [],
  regionSpecificCountryList: []
};

const CountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COUNTRY_LIST':
      return Object.assign({}, state, { countryList: action.payload });
    case 'REGION_SPECIFIC_COUNTRY_LIST':
      return Object.assign({}, state, { regionSpecificCountryList: action.payload });
    default:
      return state;
  }
};

export default CountryReducer;
