import axios from 'axios';
import * as Constants from '../configuration';
import ToastMessages from '../../data/toastMessages';

export function getAllApiAlerts() {
  return (dispatch) =>
    axios
      .get(Constants.API_ALERTS_ACTION.GET_API_ALERTS, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_API_ALERTS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getProdSpecificApiAlerts(productId) {
  return (dispatch) =>
    axios
      .get(Constants.API_ALERTS_ACTION.GET_API_ALERTS + '/' + productId, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'GET_PROD_SPECIFIC_API_ALERTS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function createProdSpecificApiAlerts(productId, alertData) {
  let jsonObject = JSON.stringify(alertData);
  return (dispatch) =>
    axios
      .post(
        Constants.API_ALERTS_ACTION.GET_API_ALERTS + '/' + productId,
        jsonObject,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: []
        }
      )
      .then((response) => {
        dispatch(getAllApiAlerts());
        dispatch({
          type: 'CREATE_PROD_SPECIFIC_API_ALERTS',
          payload: response.data
        });
        dispatch({
          type: 'SUCCESS',
          success: ToastMessages.apiAlertSuccess.msg
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function updateProdSpecificApiAlerts(productId, alertData) {
  let jsonObject = JSON.stringify(alertData);
  return (dispatch) =>
    axios
      .put(
        Constants.API_ALERTS_ACTION.GET_API_ALERTS + '/' + productId,
        jsonObject,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch(getAllApiAlerts());
        dispatch({
          type: 'UPDATE_PROD_SPECIFIC_API_ALERTS',
          payload: response.data
        });
        dispatch({
          type: 'SUCCESS',
          success: ToastMessages.apiAlertSuccess.msg
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
