import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogText from '../../../../../data/dialogText';
import Loader from '../../Loader';
import variables from '../../../../../variables.module.scss';
import {
  PrimaryButton,
  SecondaryButton,
  DialogBox
} from '../../../../../msreact-kit/packages/html-components';
import './index.scss';
const useStyles = {
  typoStyle: {
    float: 'right'
  },
  modalContentstyle: {
    fontFamily: variables.generalBoschFont,
    fontSize: '1.05rem'
  },
  divStyle: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    fontFamily: variables.generalBoschFont,
    minWidth: '40rem'
  },
  iconStyle: {
    color: '#fcaf17',
    marginRight: '1rem',
    fontSize: '2rem',
    marginBottom: '-4px'
  },
  okButtonStyle: {
    marginRight: '1rem'
  },
  textSize: {
    width: '100% !important',
    fontSize: '1.05rem',
    fontFamily: variables.lightBoschFont,
    whiteSpace: 'nowrap !important',
    border: '1px solid #007bc0'
  },
  deleteMSButtonStyle: {
    backgroundColor: '#007bc0 !important'
  },
  headingStyle: {
    fontFamily: variables.generalBoschFont,
    fontSize: '1.6rem',
    fontWeight: '100'
  },
  defaultModalHeader: {
    textAlign: 'left'
  },
  noAutoCapitalization: {
    '&.makeStyles-label-125': {
      textTransform: 'none !important'
    }
  }
};
class ConfirmModal extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
      buttonLabel: 'OK'
    };
  }

  componentDidMount() {
    if (this.props.heading === DialogText.cancelConfirmation.modalHeading) {
      this.setState({
        buttonLabel: 'Exit'
      });
    } else if (this.props.heading === DialogText.submitConfirmation.modalHeading) {
      this.setState({
        buttonLabel: 'Submit'
      });
    } else if (
      this.props.heading === DialogText.deleteSecretHeader.modalHeading ||
      this.props.heading === DialogText.deleteConfirmation.modalHeading ||
      this.props.heading === DialogText.submitDeleteConfirmation.modalHeading
    ) {
      this.setState({
        buttonLabel: 'Delete'
      });
    }
  }

  handleClose = (event) => {
    event.stopPropagation();
    this.setState({ open: false });
    this.props.callback();
  };

  handleSuccess = (event) => {
    event.stopPropagation();
    if (this.props.cancelBtn !== 'disable') {
      this.props.okBtnAction(
        this.props.index,
        this.props.type,
        this.props.allFiles,
        this.props.file
      );
    }
    this.setState({ open: false });
    this.props.callback();
  };
  render() {
    const { classes } = this.props;
    let dropdownWrapper, currentFormat, dropdownComponent;
    dropdownWrapper = this.props.hasOwnProperty('dropdownWrapper')
      ? this.props.dropdownWrapper
      : {};
    currentFormat = this.props.hasOwnProperty('currentFormat')
      ? this.props.currentFormat
      : '';
    dropdownComponent = this.props.hasOwnProperty('dropdownComponent')
      ? this.props.dropdownComponent
      : false;

    return (
      <React.Fragment>
        <DialogBox
          cssName='confirmation__modal__revamped'
          handleCancel={this.handleClose}
          title={this.props.heading}
          handleConfirm={this.handleSuccess}
          primaryLabel='Submit'
          isCloseIcon={true}
          subTitle={false}
        >
          <div className='confirm-dialog'>
            <div className={dropdownWrapper}>{this.props.dropdownComponent} </div>
            <div className='confirm-dialog__content'>
              <p className={classes.modalContentstyle}>{this.props.modalContent} </p>
            </div>
            <div className='confirm-dialog__content'>
              <p className={classes.modalContentstyle}>
                {this.props.modalContent2}{' '}
              </p>
            </div>
            <div>
              <p className={classes.modalContentstyle}>
                {this.props.modalContent3}{' '}
              </p>
            </div>
            <div>
              {this.props.deletionOfAccounts ? (
                <div>
                  <div className='confirm-dialog__content'>
                    <PrimaryButton
                      className={`${classes.okButtonStyle} ${
                        classes.textSize
                      } ${classes.deleteMSButtonStyle} ${
                        this.props.noAutoCapitalization &&
                        classes.noAutoCapitalization
                      }`}
                      label={this.props.deleteMSaccountButton}
                      onClick={this.props.handleDeleteMSaccount}
                    />
                  </div>
                  {/* <div className='confirm-dialog__content'>
                    <PrimaryButton
                      className={`${classes.textSize} ${
                        this.props.noAutoCapitalization &&
                        classes.noAutoCapitalization
                      }`}
                      onClick={this.props.handleDeleteCIAMaccount}
                      label={this.props.deleteCIAMaccount}
                    />
                  </div> */}
                </div>
              ) : (
                <div className='confirm__modal__button'>
                  <PrimaryButton
                    className={`${classes.okButtonStyle} ${
                      this.props.noAutoCapitalization && classes.noAutoCapitalization
                    }`}
                    label={
                      this.props.submitLabel
                        ? this.props.submitLabel
                        : this.state.buttonLabel
                    }
                    onClick={this.handleSuccess}
                    disabled={
                      dropdownComponent && currentFormat.length === 0 ? true : false
                    }
                  />
                  {this.props.cancelBtn !== 'disable' && (
                    <SecondaryButton
                      className={
                        this.props.noAutoCapitalization &&
                        classes.noAutoCapitalization
                      }
                      onClick={this.handleClose}
                      label={
                        this.props.cancelLabel ? this.props.cancelLabel : 'Cancel'
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </DialogBox>
        {this.props.confirmationModalLoader && <Loader />}
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(ConfirmModal);
