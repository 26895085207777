import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Images from '../../../../assets/img';
import './index.scss';

const ThumbnailCard = ({
  mediaIcon,
  file,
  uploadType,
  handleDelete,
  isView,
  handlePreviewElement
}) => {
  const hanldeCheckUploadType = (documentName) => {
    const docName = documentName;
    const extention = docName && docName?.split('.').pop();
    const videoExtention = [
      i18next.t('DYNAMIC_FORM.FORMATS.MP4'),
      i18next.t('DYNAMIC_FORM.FORMATS.AVI')
    ];
    if (extention && videoExtention.includes(extention.toUpperCase())) {
      return 'uploadVideo';
    }
  };

  const handleDeleteFile = (event) => {
    event.stopPropagation();
    handleDelete(file);
  };

  const imgAttachmentCss =
    uploadType === 'attachment' ? 'upload__attach_prev' : 'upload__list_prev';

  return (
    <div
      className={
        uploadType === 'attachment' ? 'upload__attach_view' : 'upload__img_listing'
      }
      onClick={() => handlePreviewElement(file)}
    >
      <div className='upload__list_img'>
        {hanldeCheckUploadType(file?.documentName) === 'uploadVideo' ? (
          <video controls='' className='upload__list_prev'>
            <source src={file?.blobFileUrl} type='video/mp4' />
          </video>
        ) : (
          <img
            src={file?.blobFileUrl}
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.PREVIEW')}
            className={imgAttachmentCss}
            data-testid='prev_image'
          />
        )}

        {!isView && (
          <div
            className='upload__list_del_cont'
            onClick={(event) => handleDeleteFile(event)}
          >
            <img
              src={Images.deleteIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE')}
              className='upload__list_del_icon'
            />
          </div>
        )}
      </div>
      {mediaIcon && (
        <div className='upload__list_prev_container'>
          <label className='upload__list_prev_content'>{file?.documentName}</label>
          <img
            src={mediaIcon}
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOCUMENT')}
            className='upload__list_prev_file_icon'
          />
        </div>
      )}
    </div>
  );
};

export default ThumbnailCard;

ThumbnailCard.propTypes = {
  isView: PropTypes.bool,
  handlePreviewElement: PropTypes.func
};

ThumbnailCard.defaultProps = {
  isView: false,
  handlePreviewElement: () => null
};
