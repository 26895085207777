import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import validation from '../validation';
import FileUpload from './file-upload';
import Images from '../../../assets/img';
import DocNotes from './doc-notes';

const MediaUpload = ({
  value,
  iconLabel,
  borderStyle,
  acceptsFormat,
  fileCount,
  fileSize,
  displayName,
  documentTypeId,
  thumbFile,
  handleChange,
  handleDeleteFile,
  ...props
}) => {
  return (
    <div>
      <h4 className='-size-l mb12'>{displayName}</h4>
      <FileUpload
        {...props}
        className={
          borderStyle === 'rectangle'
            ? 'upload__thumb upload__cover'
            : 'upload__thumb'
        }
        validations={validation.uploadImage}
        handleChange={handleChange}
        value={value}
      >
        {value && value?.length > 0 ? (
          <React.Fragment>
            <img
              className='upload__thumb_prev'
              src={value[0].blobFileUrl}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.THUMB')}
            />
            <img
              src={Images.deleteIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE')}
              onClick={() => handleDeleteFile(value[0])}
              className='upload__list_del_icon'
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <img
              className='upload__add_icon'
              src={Images.addIcon}
              alt={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')}
            />
            {iconLabel && <label className='upload__label'>{iconLabel}</label>}
          </React.Fragment>
        )}
      </FileUpload>
      <DocNotes {...props.validation} />
    </div>
  );
};

MediaUpload.propTypes = {
  displayName: PropTypes.string,
  iconLabel: PropTypes.string,
  src: PropTypes.array
};

MediaUpload.defaultProps = {
  displayName: '',
  iconLabel: '',
  src: []
};

export default MediaUpload;
