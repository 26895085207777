import React, { Component, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Dialog } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Images from '../../../assets/img';
import i18next from 'i18next';
import './my-api.scss';
import SnackBar from '../../Shared/Utils/SnackBar';
import Loader from '../../Shared/Utils/Loader';
import BoschSystem from '../../../shared/bosch-systems';
import { toLowercaseReplaceSpace } from '../../../shared/shared-function/helperFunctions';
import { apiTypes } from '../../../data/appConstants';
import idConstants from '../../../preview/data/idConstants';
import DisplayOptions from '../../../data/displayOptions';
import IdConstants from '../../../data/idConstants';
import { productDetailsParser } from '../../../data/boschSystemProductDetailsParser';
import { trackEvent } from '../../../utils/analytics';
import './index.scss';
import {
  Breadcrumb,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from '../../../msreact-kit/packages/html-components';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function DropZone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let allowedExtension = ['json', 'yaml'];
      let validFile = allowedExtension.includes(
        file.name.substring(file.name.lastIndexOf('.') + 1)
      );
      if (!validFile) {
        props.state('invalidFile');
      } else {
        props.uploadSwagger(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false
  });
  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <div>
        <label className='drop-zone-label'>
          Drag and drop the swagger JSON/YAML file
        </label>
        <br />
        <br />
        <SecondaryButton label='Select File' onClick={open} />
      </div>
      <input {...getInputProps()} multiple />
    </div>
  );
}
function bytesToSize(bytes) {
  const sizes = ['Byte', 'KB', 'MB'];
  for (let size of sizes) {
    if (bytes <= 1024) {
      return bytes + ' ' + size;
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return bytes + ' P';
}

class AddApiDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.currentPage,
      showDropZone: true,
      api: {
        manual: true,
        swagger: false,
        boschSystem: false
      },
      page: 3,
      openError: false,
      errMessage: '',
      fileName: '',
      fileSize: '',
      deleteFile: false,
      uploadSuccess: false,
      isRedirect: false,
      currentApiType: apiTypes[0],
      showing: false
    };
  }

  setPage(page) {
    this.setState({
      currentPage: page,
      showing: true
    });
  }

  handleClose = () => {
    this.props.closeDialog();
  };
  uploadSwaggerFile = (file) => {
    this.props.uploadSwaggerFile(file).then(() => {
      if (!this.props.error) {
        if (this.props.swaggerFilesErr === null) {
          this.setState({
            uploadSuccess: true,
            showDropZone: false,
            fileName: file.name,
            fileSize: bytesToSize(
              file.size === undefined ? file.documentSize : file.size
            )
          });
        } else {
          let msg =
            this.props.swaggerFilesErr.message !== undefined
              ? this.props.swaggerFilesErr.message
              : 'Unsupported Swagger file! Please attach a valid file';
          this.setState({
            errMessage: msg,
            openError: true
          });
        }
      }
    });
  };
  changeState(value) {
    if (value === 'invalidFile') {
      this.setState({
        errMessage: ' Unsupported file format! Please attach a valid file',
        openError: true
      });
    }
  }
  handleChangeState = () => {
    this.setState({
      deleteFile: false,
      uploadSuccess: false
    });
  };
  handleErrorState = () => {
    if (!this.state.showDropZone) {
      this.setState({
        errMessage: 'File Already Exists',
        openError: true
      });
    }
  };

  selectAPIType = (type) => {
    if (!type.disabled) {
      this.setState({
        currentApiType: type
      });
    }
  };

  goToCreatePage = (category) => {
    this.props.history.push({
      pathname: '/product/create',
      state: {
        productCategoryId: category.productCategoryId,
        redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
          category.productCategoryName
        )}`
      }
    });

    //GA-300
    trackEvent('create_product', 'clicked', category.productCategoryName);
  };

  goToCreatePageWithData = (productDetails, category) => {
    const parsedResponse = productDetailsParser(productDetails, category);
    this.props.history.push({
      pathname: '/product/create',
      key: 'source',
      state: {
        data: parsedResponse,
        isEdit: false,
        isView: false,
        publishData: null,
        submitted: false,
        isClone: false,
        isSource: true,
        productCategoryId: category.productCategoryId,
        redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
          category.productCategoryName
        )}`
      }
    });

    //GA-300
    trackEvent('create_product', 'clicked', category.productCategoryName);
  };

  redirectToAddProduct = (productDetails) => {
    if (this.props.category.productCategoryId === IdConstants.api.categoryId)
      this.redirectToAddAPI(productDetails);
    else if (this.state.page === 5)
      this.goToCreatePageWithData(productDetails, this.props.category);
    else this.goToCreatePage(this.props.category);
  };

  redirectToAddAPI = (productDetails) => {
    const parsedResponse = this.state.api.boschSystem
      ? productDetailsParser(productDetails, idConstants.api)
      : {};
    this.props.history.push({
      pathname: `${
        DisplayOptions.find(
          (option) =>
            option.productType === IdConstants.sidebarConstants.apiProduct.title
        ).filters[this.props.info.previousSession].url
      }/addapi/step1`,
      state: { apiType: this.state.currentApiType, productDetails: parsedResponse }
    });
    this.props.updateInfo(this.props.info, 'showPopup', true);
  };

  getStartedDescription = () => {
    if (this.props.category.productCategoryId === IdConstants.api.categoryId) {
      return i18next.t(
        'DYNAMIC_FORM.SHARED.GET_STARTED.API.GET_STARTED_DESCRIPTION'
      );
    }
    return i18next.t(
      'DYNAMIC_FORM.SHARED.GET_STARTED.DYNAMIC_FORM.GET_STARTED_DESCRIPTION'
    );
  };
  getManualOnboardingHeading = () => {
    if (this.props.category.productCategoryId === IdConstants.api.categoryId) {
      return i18next.t('DYNAMIC_FORM.SHARED.GET_STARTED.API.HEADING');
    }
    return i18next.t('DYNAMIC_FORM.SHARED.GET_STARTED.DYNAMIC_FORM.HEADING');
  };

  getManualOnboardingDescription = () => {
    if (this.props.category.productCategoryId === IdConstants.api.categoryId) {
      return i18next.t('DYNAMIC_FORM.SHARED.GET_STARTED.API.DESCRIPTION');
    }
    return i18next.t('DYNAMIC_FORM.SHARED.GET_STARTED.DYNAMIC_FORM.DESCRIPTION');
  };

  getManualOnboardingProperties = () => {
    if (this.state.api.manual === true) {
      if (
        IS_MKOSINT_TENANT &&
        this.props.category.productCategoryId === idConstants.api.categoryId
      ) {
        return 'item___background-smoke-active_mkosint';
      }
      return 'item___background-smoke-active';
    } else {
      if (
        IS_MKOSINT_TENANT &&
        this.props.category.productCategoryId === idConstants.api.categoryId
      ) {
        return 'item__background-smoke_mkosint';
      }
      return 'item__background-smoke';
    }
  };
  getPageContent(page) {
    switch (page) {
      case 2:
        return (
          <div>
            <div className='outer-box'>
              <div className='item__heading'>
                {i18next.t('DYNAMIC_FORM.SHARED.GET_STARTED.HEADING')}
              </div>
              <div className='item__sub-heading'>{this.getStartedDescription()}</div>
              <div
                className={this.getManualOnboardingProperties()}
                onClick={() =>
                  this.setState({
                    api: {
                      manual: true,
                      swagger: false,
                      boschSystem: false
                    },
                    page: 3
                  })
                }
              >
                <div className='api'>
                  <div className='api__products'>
                    <div className='api__img'>
                      <div
                        className={
                          IS_MKOSINT_TENANT &&
                          this.props.category.productCategoryId ===
                            idConstants.api.categoryId
                            ? 'api__text api__add_icon_mkosint'
                            : 'api__text api__add_icon'
                        }
                      >
                        <img
                          src={Images.contractDocumentIcon}
                          className='item__img-icon'
                          alt='Manual'
                        />
                      </div>
                    </div>
                    <div className='api__space-around'>
                      <div className='box-heading-manual add__api__subtitle'>
                        {this.getManualOnboardingHeading()}
                      </div>
                      <div className='add__api__description'>
                        {this.getManualOnboardingDescription()}
                      </div>
                    </div>
                  </div>
                  {this.state.api.manual === true ? (
                    <div className='api__arrow-icon'>
                      <img src={Images.checkIcon} className='api__icon'></img>
                    </div>
                  ) : (
                    <div className='api__arrow-icon-diabled' />
                  )}
                </div>
              </div>
              {this.props.category.productCategoryId ===
                IdConstants.api.categoryId && (
                <div
                  className={
                    this.state.api.swagger === true
                      ? 'item___background-smoke-active'
                      : 'item__background-smoke'
                  }
                  onClick={() =>
                    this.setState({
                      api: {
                        manual: false,
                        swagger: true,
                        boschSystem: false
                      },
                      page: 4
                    })
                  }
                >
                  <div className='api'>
                    <div className='api__products'>
                      <div className='api__img'>
                        <div
                          className={
                            IS_MKOSINT_TENANT &&
                            this.props.category.productCategoryId ===
                              idConstants.api.categoryId
                              ? 'api__text api__add__swagger__icon_mkosint'
                              : 'api__text api__add__swagger__icon '
                          }
                        >
                          <img
                            src={Images.documentSwaggerIcon}
                            className='item__img-icon'
                            alt='Swagger'
                          />
                        </div>
                      </div>
                      <div className='api__space-around'>
                        <div className='box-heading-swagger add__api__subtitle'>
                          Import using Swagger
                        </div>
                        <div className='add__api__description'>
                          <div>List an API using a Swagger file.</div>
                        </div>
                      </div>
                    </div>
                    <div className='api__swagger__icon'>
                      <div className='api__swagger'>
                        {this.state.api.swagger === true ? (
                          <div className='api__arrow-icon'>
                            <img src={Images.checkIcon} className='api__icon'></img>
                          </div>
                        ) : (
                          <div className='api__arrow-icon-diabled' />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {IS_MKOSINT_TENANT && (
                <div
                  className={
                    this.state.api.boschSystem === true
                      ? 'item___background-smoke-active'
                      : 'item__background-smoke'
                  }
                  onClick={() =>
                    this.setState({
                      api: {
                        manual: false,
                        swagger: false,
                        boschSystem: true
                      },
                      page: 5
                    })
                  }
                >
                  <div className='api'>
                    <div className='api__products'>
                      <div className='api__img'>
                        <div
                          className={
                            IS_MKOSINT_TENANT &&
                            this.props.category.productCategoryId ===
                              idConstants.api.categoryId
                              ? 'api__text api__bosch_system_icon_mkosint'
                              : 'api__text api__bosch_system_icon'
                          }
                        >
                          <img
                            src={Images.boschSystemIcon}
                            className='item__img-icon'
                            alt='Manual'
                          />
                        </div>
                      </div>
                      <div className='api__space-around'>
                        <div className='box-heading-manual add__api__subtitle'>
                          Fetch from Bosch Systems
                        </div>
                        <div className='add__api__description'>
                          Fetch your product details from Bosch.
                        </div>
                      </div>
                    </div>
                    {this.state.api.boschSystem === true ? (
                      <div className='api__arrow-icon'>
                        <img src={Images.checkIcon} className='api__icon'></img>
                      </div>
                    ) : (
                      <div className='api__arrow-icon-diabled' />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='item__vertical'>
              <div className='item__close_button'>
                <div>
                  <TertiaryButton label='Close' onClick={() => this.handleClose()} />
                </div>
                <div className='item__secondary_main'>
                  <div className='item__secondary_sub_main'>
                    <SecondaryButton
                      label='Back'
                      onClick={() => this.handleClose()}
                    />
                  </div>
                  <div>
                    <PrimaryButton
                      label='Next'
                      onClick={() => {
                        this.setPage(this.state.page);
                        this.setState({
                          showDropZone: true,
                          showing: true
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return IS_MKOSINT_TENANT &&
          this.props.category.productCategoryId !== idConstants.api.categoryId ? (
          this.goToCreatePage(this.props.category)
        ) : (
          <div className='item__api__manually'>
            <div className='item__heading api_manually_heading'>
              <img
                src={Images.backIcon}
                fontSize='large'
                className='item__arrow-back'
                onClick={() => {
                  this.setPage(2);
                  this.setState({ showDropZone: true, showing: false });
                }}
              />
              List API Manually
            </div>
            <div className='item__sub-heading api_manually_heading'>
              <p>Please choose your API Type</p>
            </div>
            <div className='item__api_selection'>
              {apiTypes.map((type) => (
                <div className='item__list-item' key={type.id}>
                  <div
                    className={
                      this.state.currentApiType.id === type.id
                        ? 'item__api-selected '
                        : 'item__api-disabled'
                    }
                    onClick={() => this.selectAPIType(type)}
                  >
                    <div>
                      <div className='item__list-content'>
                        {this.state.currentApiType.id === type.id ? (
                          <div className='item__api_list'>
                            <img src={Images.checkIcon} className='item__checked' />
                          </div>
                        ) : (
                          <div className='item__api_list' />
                        )}
                      </div>
                    </div>
                    <div className='item__api-content'>
                      <img src={type.img} className='item__api-icon' alt='Manual' />
                    </div>
                    <div
                      className={
                        this.state.currentApiType.id === type.id
                          ? 'item__api-title-selected'
                          : 'item__api-title1'
                      }
                    >
                      {type.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case 4:
        return (
          <div className='swagger-container'>
            <div className='swagger-container-wrapper'>
              <div className='heading'>Import Swagger API</div>
              <div className='sub-heading'>List an API using a Swagger file.</div>
              <div className='sub-heading'>
                Open API Specification (Swagger Specification) is an API description
                format for REST APIs. Swagger file allows you to describe your entire
                API
              </div>
              <div>
                <React.Fragment>
                  <div
                    className={
                      this.state.showDropZone
                        ? 'drop-zone-block'
                        : 'drop-zone-display'
                    }
                  >
                    <div className='drop-zone-box'>
                      <DropZone
                        state={(arr) => this.changeState(arr)}
                        uploadSwagger={this.uploadSwaggerFile}
                        {...this.props}
                      />
                    </div>
                    <div className='drop-zone-msg'>
                      Only .json/.yaml file formats are allowed
                    </div>
                  </div>
                </React.Fragment>
                {!this.state.showDropZone && (
                  <React.Fragment>
                    <div className='dropzone-wrapper'>
                      <div className='sub-heading'>
                        <img
                          className='sub-heading-description-icon'
                          src={Images.descriptionIcon}
                          alt='Description Icon'
                        />
                      </div>
                      <div>
                        <div className='sub-heading'>
                          <div className='sub-heading-file-name'>
                            {this.state.fileName}
                          </div>
                          <div>{this.state.fileSize}</div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className='sub-heading'>
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                this.setState({
                                  showDropZone: true,
                                  deleteFile: true
                                });
                                this.props.resetProduct();
                              }}
                            >
                              <img
                                src={Images.deleteIcon}
                                alt='Delete icon'
                                className='swagger-delete-icon'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div
              className={this.state.openError === true ? 'show-modal' : 'hide-modal'}
            >
              <div className='modal-div'>
                <img
                  src={Images.deleteIcon}
                  alt='Delete Icon'
                  className='error-symbol'
                />
                <div className='error-label'>Error</div>
                {i18next.t('API_ONBOARDING.INVALID_SWAGGER')}
                <br />
                <br />
                <PrimaryButton
                  label='Got It!'
                  onClick={() => this.setState({ openError: false })}
                />
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <BoschSystem redirectToAddProduct={this.redirectToAddProduct} />
          </div>
        );
      default:
        return <div></div>;
    }
  }

  render() {
    const { showing } = this.state;
    if (this.state.isRedirect) {
      this.props.updateInfo(this.props.info, 'showPopup', true);
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[this.props.info.previousSession].url
        }/addapi/step1`,
        state: {
          ...this.props.location.state,
          productId: null,
          isSwagger: true,
          apiType: this.state.currentApiType
        }
      });
    }
    return (
      <div>
        <div onDrop={this.handleErrorState}>
          {(() => {
            if (this.state.deleteFile) {
              return (
                <SnackBar
                  severity='success'
                  changeState={() => this.handleChangeState()}
                  message='Deleted successfully'
                />
              );
            }
            if (this.state.uploadSuccess) {
              return (
                <SnackBar
                  severity='success'
                  changeState={() => this.handleChangeState()}
                  message='Imported successfully'
                />
              );
            }
          })()}
          <div>
            <Dialog
              fullScreen
              open={this.props.dialogOpen}
              onClose={() => this.handleClose()}
              TransitionComponent={Transition}
            >
              {(() => {
                if (this.props.loader?.loader) {
                  return <Loader />;
                }
              })()}
              <div></div>
              <div>
                <div className='api__section'>
                  <div className='api__content'></div>
                  <div className='api__add__manually'>
                    <div className='breadcrumb__getstarted'>
                      <Breadcrumb
                        items={[
                          {
                            title: IS_MKOSINT_TENANT
                              ? 'Add New Product'
                              : 'Add New API',
                            to: '/dashboard/myproducts/'
                          }
                        ]}
                        className='bread-crumb__container__arrow bread-crumb__container__api'
                      />
                    </div>
                    <div
                      className={
                        IS_MKOSINT_TENANT &&
                        this.props.category.productCategoryId ===
                          idConstants.api.categoryId
                          ? 'apiflow_getstart_page_mkosint'
                          : 'apiflow_getstart_page'
                      }
                    >
                      <div className='apiflow_getstart_box'>
                        {this.getPageContent(this.state.currentPage)}
                      </div>
                      <div className='getstart__image'>
                        <img
                          src={Images.groupAddImage}
                          className='item__add-api-img-style'
                          alt='List API'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showing ? (
                  <div className='item__vertical'>
                    <div className='item__close_button'>
                      <div>
                        <TertiaryButton
                          label='Close'
                          onClick={() => this.handleClose()}
                        />
                      </div>
                      <div className='item__secondary_main'>
                        <div className='item__secondary_sub_main'>
                          <SecondaryButton
                            label='Back'
                            onClick={() => {
                              this.setPage(2);
                              this.setState({
                                showDropZone: true,
                                showing: false
                              });
                            }}
                          />
                        </div>
                        <div>
                          {this.state.page !== 5 && (
                            <PrimaryButton
                              disabled={
                                this.state.page === 4 && this.state.showDropZone
                              }
                              label='Next'
                              onClick={() => {
                                if (this.state.page === 4) {
                                  this.setState({ isRedirect: true });
                                  return;
                                }
                                this.redirectToAddProduct();
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default AddApiDialog;
