import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Errors from '../errors';
import { handleMaxCountText } from '../../../shared/shared-function/helperFunctions';

import './index.scss';
import CustomToolTip from '../../../components/Shared/custom-tooltip';

const Input = ({
  displayName,
  className,
  isElementRequired,
  value,
  handleChange,
  name,
  error,
  description,
  dataTestId,
  validation,
  handleBlur,
  cssName,
  id,
  ...props
}) => {
  let val = {};
  let length = {};
  let regex = {};
  let required = {};
  if (validation) {
    val = validation;
    const { rule } = val;
    length = rule?.length;
    regex = rule?.regex ? rule?.regex : {};
    if (rule?.url?.isUrl) {
      regex['pattern'] = rule?.url?.pattern;
    }
    required = rule?.required;
  }

  const validateInputs = (values) => {
    const data = {
      value: values,
      error: '',
      name: name
    };

    const regexExp = new RegExp(regex?.pattern, 'gmu');
    switch (true) {
      case values.length === 0 && required?.isRequired:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
        data.isInValid = true;
        return data;
      case values.length > 0 &&
        regexExp &&
        regexExp.toString().length > 0 &&
        !regexExp.test(values):
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_INVALID')}.`;
        data.isInValid = true;
        return data;
      case values.length > 0 && values.length < length?.min:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_TOO_SHORT')} (${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MIN_CHAR')} - ${length?.min}).`;
        data.isInValid = true;
        return data;
      case values.length > 0 && values.length > length?.max:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_TOO_LONG')} (${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - ${length?.max}).`;
        data.isInValid = true;
        return data;
      default:
        return data;
    }
  };

  const blockInvalidChar = (event) => {
    const key = event.charCode || event.keyCode;
    if (props.type === 'number') {
      if (['e', 'E', '+', '-'].includes(event.key)) {
        event.preventDefault();
      }
      if (key == 38 || key == 40) {
        event.preventDefault();
      }
    }
  };

  const handleInputChange = (event) => {
    const { value: inputValue, type } = event.target;
    if (type === 'number') {
      if (inputValue.length > length?.max) {
        return;
      }
    }

    const data = {
      value: type === 'number' && inputValue ? Number(inputValue) : inputValue,
      error: '',
      name: name,
      event: event
    };
    handleChange && handleChange(data);
  };

  const handlevalidation = (event) => {
    const { value: inputValue, type } = event.target;
    const data = validateInputs(inputValue.trim());
    if (type === 'number' && inputValue) {
      data.value = Number(inputValue);
    }
    data.eventType = event.type;
    handleChange && handleChange(data);
    handleBlur && handleBlur(data);
  };

  return (
    <React.Fragment>
      <CustomToolTip
        id={id}
        tooltipData={displayName}
        width='dynamic'
        className={props?.class}
      >
        <div className={cssName ? className + ' ' + cssName : className}>
          <label id={id}>
            {displayName}
            {required?.isRequired && <span className='input__required'>*</span>}
          </label>
          <input
            onChange={handleInputChange}
            onKeyDown={(event) => blockInvalidChar(event)}
            onWheel={(event) => event.target.blur()}
            value={value}
            data-testid={name}
            onBlur={handlevalidation}
            maxLength={length?.max}
            {...props}
          />
        </div>
      </CustomToolTip>

      {error ? (
        <Errors
          errorMessaage={error}
          cssName='error-notification-wrapper'
          id='notification-label-id-text-error'
          {...props}
        />
      ) : (
        <div className='input__details'>
          <p className='input__addinal-info -size-s'>{description}</p>
          {length?.max && (
            <p className='input__addinal-info input__max-char -size-s'>{`${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - ${handleMaxCountText(
              length?.max,
              value
            )}`}</p>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool
};

Input.defaultProps = {
  type: '',
  placeHolder: '',
  className: '',
  label: '',
  multiple: false,
  message: '',
  required: false,
  value: ''
};

export default Input;
