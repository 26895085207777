import isEmpty from './isEmpty';
export default function isDataType(str, dataType, countryCode) {
  switch (dataType.toUpperCase()) {
    case 'ENUM':
      return !isEnum(str);
    case 'STRING':
      return !isString(str);
    case 'NUMBER_WITH_SPACES_BRACKETS_HYPHEN':
      return !isNumberWithSpacesBracketsHyphen(str);
    case 'TELEPHONE_REGEX':
      return !isTelephoneRegex(str);
    case 'EMAIL_ID':
      return !isValidEmailId(str);
    case 'BOOLEAN':
      return !isBoolean(str);
    case 'FLOAT':
      return !isFloat(str);
    case 'ALPHANUMERIC':
      return !isalphanumeric(str);
    case 'NUMBER_WITH_HYPHEN':
      return !isNumberWithHyphen(str);
    case 'POSITIVENUMBER':
      return !isPositiveNumber(str);
    case 'IFSC':
      return !isValidIfscCode(str);
    case 'ALPHABETS_SPACES':
      return !isAlphabetsWithSpaces(str);
    case 'ALPHABETS':
      return !isAlphabets(str);
    case 'NON_ALPHANUMERIC_WITH_SPACES':
      return isNotAlphanumericWithSpaces(str);
    case 'PRICING_PLAN_NAME':
      return !isvaildPricingPlanName(str);
    case 'ADDRESS_FIELD_ALLOWED_CHARACTERS':
      return isAllowedAddressField(str);
    case 'DISTRICT_ALLOWED_CHARACTERS':
      return !isDistrictAllowedCharacters(str);
    case 'MSME':
      return !isMSMEAllowedCharaters(str);
    case 'VAT_ID':
      return isValidVatId(str);
    case 'ZIP_CODE':
      return isValidZIPCode(str, countryCode);
    case 'TIN':
      return isValidTin(str);
    default:
      return false;
  }
}

function isEnum(str) {
  const expression = /^.*$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

export function isNumberWithSpacesBracketsHyphen(str) {
  const expression = /^([0-9-()\s]*)$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isTelephoneRegex(str) {
  const expression = /^(?![\s-])[0-9\s-]{5,15}(?<![\s-])$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isMSMEAllowedCharaters(str) {
  const expression = /^[a-zA-Z\d\s-]+$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isValidVatId(str) {
  const expression = /^(DE)?\d{9}$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isValidZIPCode(str, countryCode) {
  const expression = /^\d{5}-\d{4}$/;
  if (countryCode === 'DE') return str.length === 5;
  else if (countryCode === 'US') return expression.test(str);
}

function isValidEmailId(str) {
  const expression = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9]+(\.[a-zA-Z]{2,30}){1,5})$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isString(str) {
  const expression = /^([A-Za-z0-9]*)$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isBoolean(str) {
  if (!isEmpty(str)) {
    return (
      str === '0' ||
      str === '1' ||
      str.toLowerCase() === 'true' ||
      str.toLowerCase() === 'false'
    );
  } else {
    return false;
  }
}
export function isFloat(str) {
  const expression = /^\d+(\.\d{1,20})?$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}
export function isalphanumeric(str) {
  const expression = /^[A-Za-z\d\s]+$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

export function isNumberWithHyphen(str) {
  const expression = /^([0-9-]*)$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}
export function isPositiveNumber(str) {
  const expression = /^(\d*)$/;
  if (!isEmpty(str)) {
    return expression.test(str) && str !== '0';
  } else {
    return false;
  }
}

export function isValidIfscCode(str) {
  const expression = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  if (!isEmpty(str)) {
    return expression.test(str) && str !== '0';
  } else {
    return false;
  }
}

function isAlphabetsWithSpaces(str) {
  const expression = /^[a-zA-Z ]*$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isAlphabets(str) {
  const expression = /^[A-Za-z]+$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isDistrictAllowedCharacters(str) {
  const expression = /^[a-zA-Z\d\s\(\).-]*$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isNotAlphanumericWithSpaces(str) {
  const expression = /[^A-Za-z0-9 .-]/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isvaildPricingPlanName(str) {
  const expression = /^[-_,@#&*\(\)\[\]\{\}A-Za-z0-9.\s]*$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isAllowedAddressField(str) {
  const expression = /[{}[\]]/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isValidTin(str) {
  const expression = /^\d{2}-\d{7}$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}
