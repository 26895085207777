import React from 'react';
import {
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import variables from '../../../variables.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '400px',
    fontFamily: variables.generalBoschFont
  }
}));

const RadioButton = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row className={classes.root}>
          <Grid container>
            {props.data.map((element) => {
              return (
                <Grid key={element.id} item sm>
                  <FormControlLabel
                    checked={props.checkedItem === element.id}
                    value={element.id}
                    control={<Radio color='primary' />}
                    label={element.name}
                    name={props.name}
                    onChange={props.onChange}
                    disabled={props.disabled}
                  />
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default RadioButton;
