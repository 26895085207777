import axios from 'axios';
import { SELLER_TENANT_PIN_ACTION } from '../../actions/configuration';

export function getPinDetails() {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: SELLER_TENANT_PIN_ACTION.PIN_URL,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_PIN_DETAILS',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function createPin(tenantId) {
  return (dispatch) =>
    axios({
      method: 'POST',
      url: SELLER_TENANT_PIN_ACTION.PIN_URL,
      data: tenantId,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'CREATE_PIN_DETAILS',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function updatePin(tenantId) {
  return (dispatch) =>
    axios({
      method: 'PUT',
      url: SELLER_TENANT_PIN_ACTION.PIN_URL,
      data: tenantId,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'UPDATE_PIN_DETAILS',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
