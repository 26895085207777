import React, { Component } from 'react';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import store from '../../../store';
import Images from '../../../assets/img';
import DialogTexts from '../../../data/dialogText';
import IdConstants from '../../../data/idConstants';
import { GAEventAction } from '../../../data/ga-constants';
import StockAvail from '../../../msreact-kit/html-components/stock-avail';
import PartUpdate from '../../../msreact-kit/html-components/part-update';
import {
  getEditableDetails,
  getProductDetailedInfo
} from '../../../actions/api/SolutionAction';
import { getApiPlans } from '../../../actions/api/pricing-plan-action';
import {
  getSkuIdDetails,
  getTenantProductStatus
} from '../../../actions/MyProductsAction';
import {
  getProductVisibilityData,
  postProductVisibilityData
} from '../../../actions/ProductDetailAction';
import { DialogBox } from '../../../msreact-kit/packages/html-components';
import { bindActionCreators } from 'redux';
import SkuDialogBox from '../../../msreact-kit/html-components/sku-dialog';
import SkuIdDetails from '../../../msreact-kit/html-components/sku-id-details';
import SimpleDropdown from '../../../msreact-kit/html-components/simple-dropdown/SimpleDropDown';
import { toLowercaseReplaceSpace } from '../../../shared/shared-function/helperFunctions';
import '../../../components/Shared/Utils/card/index.scss';
import capitalizeText from '../../../utils/capitalize-text';
import { trackEvent } from '../../../utils/analytics';
import CustomToolTip from '../../../components/Shared/custom-tooltip';
import Tooltip from '../../../components/Shared/Tooltip';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';
import './index.scss';

class StylledCard extends Component {
  constructor(props) {
    super(props);
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
  }
  state = {
    raised: false,
    opendil: false,
    openConfirmModal: false,
    modalHeading: '',
    confirmModalText: '',
    openDeleteModal: false,
    openStockModal: false,
    openPartUpdateModal: false,
    openSharesModal: false,
    modalData: {},
    createdDate: null,
    openVisibilityModal: false,
    isVisible: true
  };

  handleCloseModal = () => {
    const popoverContentContainer = document.getElementById('popover-content');
    if (!popoverContentContainer) return;
    popoverContentContainer.style.display = 'none';
    while (popoverContentContainer.hasChildNodes()) {
      popoverContentContainer.removeChild(popoverContentContainer.firstChild);
    }
  };

  handleClick = (event) => {
    event.stopPropagation();

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth - 100;
    const screenH = window.innerHeight - 300;
    const menuData = this.getDropdownData();
    const rootW = 150;
    const rootH = menuData.length > 0 ? 39 * menuData.length : 90;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;
    let style = {};

    if (right) {
      style.left = `${clickX + 5}px`;
    }

    if (left) {
      style.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      style.top = `${clickY - rootH - 5}px`;
    }
    let popoverContentContainer = document.getElementById('popover-content');
    popoverContentContainer.onclick = () => {
      this.handleCloseModal();
    };
    if (popoverContentContainer) {
      popoverContentContainer.innerHTML = '';
      popoverContentContainer.style.display = 'block';
      const contextMenuElement = document.createElement('context-menu');
      contextMenuElement.style.left = style.left;
      contextMenuElement.style.top = style.top;
      contextMenuElement.style.width = rootW + 'px';
      contextMenuElement.style.height = rootH + 'px';
      contextMenuElement.style.position = 'absolute';

      ReactDOM.render(
        <SimpleDropdown
          dropDownData={menuData}
          id={this.props.id}
          activeId={this.props.activeId}
        />,
        contextMenuElement
      );
      popoverContentContainer.append(contextMenuElement);
    }
  };

  openShareModal = (description, event) => {
    const data = Promise.all([
      store.dispatch(getSkuIdDetails(event.productId)),
      store.dispatch(getTenantProductStatus(event.productId)),
      store.dispatch(getEditableDetails(event.productId)),
      store.dispatch(
        getProductDetailedInfo(event.productId, event.productCategoryId)
      ),
      store.dispatch(getApiPlans(event.productId))
    ]);
    data.then(() => {
      if (description === 'APPROVED') {
        this.setState((prev) => {
          return {
            ...prev,
            openSharesModal: true,
            modalData: event
          };
        });
      }
      if (
        description === 'PUBLISHED' ||
        description === IdConstants.tenantPublish.description
      ) {
        this.setState((prev) => {
          return {
            ...prev,
            openPublishedModal: true,
            modalData: event
          };
        });
      }
    });
  };

  closePublishedModal = () => {
    this.setState((prev) => {
      return {
        ...prev,
        openPublishedModal: false
      };
    });
  };

  isPhysicalQuantity = () => {
    return this?.props?.categoryList?.content.some(
      (item) =>
        item?.productCategoryId === this?.props?.data?.productCategoryId &&
        item?.isQuantity === true
    );
  };

  getDropdownData = () => {
    let dropDownData = [];
    if (
      this?.props?.data?.productCategoryId ===
      IdConstants.automotiveSpares.categoryId
    ) {
      dropDownData = [
        {
          id: 2,
          label: i18next.t('DYNAMIC_FORM.LABEL.VIEW'),
          handler: () => {
            this.handleCloseModal();
            this.props.history.push({
              pathname: `/automotive-spares/individual-form`,
              key: 'view',
              state: {
                ...this.props.data,
                isEdit: false,
                isView: true,
                publishData: null,
                submitted: false
              }
            });
          }
        },
        {
          id: 3,
          label: i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE'),
          handler: () => {
            this.handleCloseModal();
            this.openDeleteModal();
          }
        }
      ];

      if (this.isPhysicalQuantity()) {
        dropDownData = [
          ...dropDownData,
          {
            id: 5,
            label: i18next.t('DYNAMIC_FORM.LABEL.STOCK_UPDATE'),
            handler: () => {
              this.handleCloseModal();
              this.openStockModal();
            }
          }
        ];
      }

      if (
        [
          IdConstants.published.description,
          IdConstants.tenantPublish.description
        ].includes(this.props.data.status.description)
      ) {
        dropDownData.unshift({
          id: 7,
          label: i18next.t('DYNAMIC_FORM.LABEL.MORE_DETAILS'),
          handler: () => {
            this.handleCloseModal();
            this.openShareModal(this.props.data.status.description, this.props.data);
          }
        });
      }
    } else {
      dropDownData = [
        {
          id: 1,
          label: i18next.t('DYNAMIC_FORM.LABEL.EDIT'),
          handler: () => {
            this.handleCloseModal();
            this.props.history.push({
              pathname: `/product/edit`,
              key: 'edit',
              state: {
                ...this.props.data,
                isEdit: true,
                isView: false,
                publishData: null,
                submitted: false,
                redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
                  this.props?.data?.productCategoryName
                )}`,
                productCategoryName: this.props?.data?.productCategoryName
              }
            });
            //GA-400
            trackEvent(
              'edit_product',
              GAEventAction.clicked,
              this.props?.data?.productCategoryName
            );
          }
        },
        {
          id: 2,
          label: i18next.t('DYNAMIC_FORM.LABEL.VIEW'),
          handler: () => {
            this.handleCloseModal();
            this.props.history.push({
              pathname: `/product/view`,
              key: 'view',
              state: {
                ...this.props.data,
                isEdit: false,
                isView: true,
                publishData: null,
                submitted: false
              }
            });
          }
        },
        {
          id: 3,
          label: i18next.t('DYNAMIC_FORM.LABEL.CLONE'),
          handler: () => {
            this.handleCloseModal();
            this.props.history.push({
              pathname: `/product/create`,
              key: 'clone',
              state: {
                ...this.props.data,
                isEdit: false,
                isView: false,
                publishData: null,
                submitted: false,
                isClone: true,
                redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
                  this.props?.data?.productCategoryName
                )}`
              }
            });
          }
        },
        {
          id: 4,
          label: i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE'),
          handler: () => {
            this.handleCloseModal();
            this.openDeleteModal();
          }
        }
      ];

      if (!IS_MKOSINT_TENANT) {
        dropDownData.push({
          id: 5,
          label: i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY_SETTINGS'),
          handler: () => {
            this.handleCloseModal();
            this.openVisibilityModal();
          }
        });
      }

      if (this.isPhysicalQuantity()) {
        dropDownData = [
          ...dropDownData,
          {
            id: 6,
            label: i18next.t('DYNAMIC_FORM.LABEL.STOCK_UPDATE'),
            handler: () => {
              this.handleCloseModal();
              this.openStockModal();
            }
          },
          {
            id: 7,
            label: i18next.t('DYNAMIC_FORM.LABEL.UPDATE_PART_NUMBER'),
            handler: () => {
              this.handleCloseModal();
              this.openPartUpdateModal();
            }
          }
        ];
      }
      if (
        (this.props.data.status.description == IdConstants.approved.description ||
          this.props.data.status.description ==
            IdConstants.tenantPublish.description ||
          this.props.data.status.description == IdConstants.published.description) &&
        !IS_MKOSINT_TENANT
      ) {
        dropDownData.push({
          id: 8,
          label: i18next.t('DYNAMIC_FORM.LABEL.PUBLISH'),
          handler: () => {
            this.handleCloseModal();
            this.marketPlaceSelection();
          }
        });
      }
      if (
        [
          IdConstants.published.description,
          IdConstants.approved.description,
          IdConstants.tenantPublish.description
        ].includes(this.props.data.status.description)
      ) {
        dropDownData.unshift({
          id: 8,
          label: i18next.t('DYNAMIC_FORM.LABEL.MORE_DETAILS'),
          handler: () => {
            this.handleCloseModal();
            this.openShareModal(this.props.data.status.description, this.props.data);
            //GA-315
            trackEvent(
              'create_product_more_details',
              GAEventAction.clicked,
              this.props?.data?.productName
            );
          }
        });
      }
      if (this.props.data.status.description === 'REJECTED') {
        dropDownData.unshift({
          id: 9,
          label: i18next.t('DYNAMIC_FORM.LABEL.MORE_DETAILS'),
          handler: () => {
            this.handleCloseModal();
            this.props.openRejectModal(
              this.props.data.status.description,
              this.props.data
            );
            //GA-315
            trackEvent(
              'create_product_more_details',
              GAEventAction.clicked,
              this.props?.data?.productName
            );
          }
        });
      }
    }
    return dropDownData;
  };

  marketPlaceSelection = () => {
    this.props.history.push({
      pathname: `/add-product`,
      state: {
        basicInfo: this?.props?.data
      }
    });
  };

  getStatus = () => {
    let status;
    switch (this.props.data?.status?.description) {
      case IdConstants.tenantPublish.description:
        status = i18next.t('DYNAMIC_FORM.TENANT_STATUS.PUBLISH_PENDING');
        break;
      case IdConstants.publishInProgress.description:
      case IdConstants.readyForPublish.description:
        status = i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.IN_PROGRESS');
        break;
      default:
        status = this.props.data?.status?.description ?? '';
    }

    if (status) return capitalizeText(status);
    else return i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.NO_DESCRIPTION_AVAILABLE');
  };

  handleOpenConfirmModal() {
    this.setState({ openConfirmModal: false });
  }

  openDeleteModal = (event) => {
    event?.stopPropagation();
    this.setState({
      openDeleteModal: true,
      modalHeading: DialogTexts.productDeleteConfirmation.modalHeading,
      confirmModalText: DialogTexts.productDeleteConfirmation.confirmModalText
    });
  };

  handleOpenDeleteModal() {
    this.setState({ openDeleteModal: false });
  }

  okBtnAction = () => {
    this.props.handlePublish(this.props.data);
  };

  handleDeleteProduct = () => {
    if (this.props.data.productCategoryId) {
      this.props.handleDeleteProduct(this.props.data);
      this.setState({ openDeleteModal: false });
    }
  };

  openVisibilityModal = (event) => {
    event?.stopPropagation();
    store.dispatch(getProductVisibilityData(this.props?.data?.productId)).then(() =>
      this.setState({
        isVisible: this.props?.isVisibleData,
        openVisibilityModal: true
      })
    );
  };

  handleVisibilityAPI = () => {
    if (this.state.isVisible !== this.props?.isVisibleData) {
      store
        .dispatch(
          postProductVisibilityData(
            this.props?.data?.productId,
            this.state.isVisible
          )
        )
        .then(() =>
          this.setState({
            isVisible: this.props?.isVisibleData,
            openVisibilityModal: false
          })
        );
    } else if (this.state.isVisible === this.props?.isVisibleData) {
      this.handleOpenVisibilityModal();
    }
  };

  handleIsVisible = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleOpenVisibilityModal = () => {
    this.setState({ openVisibilityModal: false });
  };

  onMouseOver = () => this.setState({ raised: true });
  onMouseOut = () => this.setState({ raised: false });

  openStockModal = () => {
    this.setState({
      openStockModal: true
    });
    this.props.getProductQuantity(this.props.data.productId);
  };

  closeStockModal = () => {
    this.setState({
      openStockModal: false
    });
  };

  openPartUpdateModal = () => {
    this.setState({
      openPartUpdateModal: true
    });
    this.props.getPartNumber && this.props.getPartNumber(this.props.data.productId);
  };

  closePartUpdateModel = () => {
    this.setState({
      openPartUpdateModal: false
    });
  };

  closeShareModal = () => {
    this.setState((prev) => {
      return {
        ...prev,
        openSharesModal: false
      };
    });
  };

  componentDidMount() {
    const { createdDate } = this.props.data;
    const dateObj = new Date(createdDate);
    const formattedDate = dateObj.toLocaleString();
    this.setState({ createdDate: formattedDate });
  }

  getCardClassName(description) {
    switch (description) {
      case 'DRAFTED':
        return 'drafted-card';
      case 'REJECTED':
        return 'rejected-card';
      case 'PUBLISHED':
        return 'published-card';
      case 'APPROVED':
        return 'approved-card';
      case 'SUBMITTED':
        return 'submited-card';
      default:
        return 'styled-icon';
    }
  }

  render() {
    let displayName = this.props.data.displayName;
    let productShortDesc = this.props.data.productShortDesc;
    const productImgMap = this.props.data.docTypeToDocMetaInfoListMap;
    let thumbnailImg = '';

    if (productImgMap) {
      const thumbnailImgArray = productImgMap[IdConstants.documentType.thumbnailImg];
      if (thumbnailImgArray && thumbnailImgArray.length > 0) {
        thumbnailImg = thumbnailImgArray[0].blobFileUrl;
      } else if (this.props.cardThumbnail) {
        thumbnailImg = this.props.cardThumbnail;
      } else {
        thumbnailImg =
          Images.thumbnailImg[this.props.data.productCategoryId] ||
          Images.defaultThumbnail;
      }
    }

    const isCardNonEditable = false;

    return (
      <React.Fragment>
        <div
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          className={`card ${this.state.raised ? 'raised' : ''}`}
        >
          {this.props?.data?.sysUserCreatedBy && (
            <Tooltip
              title={i18next.t('DYNAMIC_FORM.SHARED.PRODUCT_ONBOARDED_BY_SALES_REP')}
            >
              <img
                src={Images.salesRep}
                className='sales-rep-icon'
                alt={i18next.t('LANDING_PAGE.SALES_REP')}
              />
            </Tooltip>
          )}
          <hr className='card-block-wrap' />
          <div className='product-logo-wrapper'>
            <img
              className='product-logo-poster'
              src={thumbnailImg || this.props.cardThumbnail}
              alt={this.props.data.displayName}
            />
          </div>
          <div className='card-content'>
            <CustomToolTip
              id={displayName}
              tooltipData={displayName}
              width='dynamic'
              className='item-name-wrap'
            >
              <p className='item-name highlight -size-l' id={displayName}>
                {displayName}
              </p>
            </CustomToolTip>
            <div className='item-description-wrap'>
              <p className='item-description -size-m'>{productShortDesc}</p>
            </div>
            <div
              className={`arrow-icon-published ${
                isCardNonEditable ? 'disable-product-card' : ''
              }`}
            >
              <div
                className={`${this.getCardClassName(
                  this.props.data.status.description
                )} list-item-card `}
              >
                <p className='-size-m'>{this.getStatus()}</p>
              </div>
              <div className='more-options-wrap'>
                <i
                  className='a-icon boschicon-bosch-ic-options options'
                  title='More Options'
                  onClick={this.handleClick}
                />
              </div>
              <div
                className='arrow-icon-placement'
                onClick={() => this.props.clickedHandle(this.props.data.productId)}
              >
                <i
                  className='a-icon boschicon-bosch-ic-arrow-right-frame right-arrow'
                  title='Go to product view'
                />
              </div>
            </div>
          </div>
        </div>

        {(() => {
          if (this.state.openConfirmModal === true) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={true}
                handleConfirm={() => {
                  this.handleOpenConfirmModal();
                  this.okBtnAction();
                }}
                handleCancel={this.handleOpenConfirmModal}
              />
            );
          }
        })()}
        {(() => {
          if (this.state.openDeleteModal) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={false}
                handleConfirm={this.handleDeleteProduct}
                handleCancel={this.handleOpenDeleteModal}
              />
            );
          }
        })()}
        {(() => {
          if (this.state.openVisibilityModal) {
            return (
              <DialogBox
                isModal
                isCloseIcon
                handleConfirm={this.handleVisibilityAPI}
                handleCancel={this.handleOpenVisibilityModal}
                primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.SAVE')}
                secondaryLabel={i18next.t('DYNAMIC_FORM.LABEL.CANCEL')}
                image={Images.sellerSwitch}
                isVisibilityModal={true}
                isVisible={this.state.isVisible}
                handleIsVisible={this.handleIsVisible}
              />
            );
          }
        })()}
        {(() => {
          if (this.state.openStockModal === true) {
            return (
              <StockAvail
                openStockModal={this.openStockModal}
                closeModal={this.closeStockModal}
              />
            );
          }
          if (this.state.openPartUpdateModal === true) {
            return (
              <PartUpdate
                productDetails={this.props.data}
                openPartUpdateModal={this.openPartUpdateModal}
                closeModal={this.closePartUpdateModel}
              />
            );
          }
        })()}

        <div>
          {(() => {
            if (
              this.props.openRejectedModal === true &&
              this.props?.hardwareProductDetail?.prodBasicInfo?.productId ===
                this.props.data?.productId
            ) {
              const comments =
                this.props?.hardwareProductDetail?.prodBasicInfo?.comments;
              return (
                <DialogBox
                  isModal={true}
                  isSuccess={false}
                  isCloseIcon={true}
                  handleCancel={() => this.props.closeRejectModal()}
                  handleConfirm={() =>
                    this.props.closeRejectModal(
                      this.props.data.productId,
                      this.props.data.productCategoryId
                    )
                  }
                  title={i18next.t(
                    'DYNAMIC_FORM.PRODUCT_CONTAINER.KINDLY_CORRECT_THE_BELOW_MENTIONED_ERRORS_AND_RESUBMIT'
                  )}
                  subTitle={''}
                  isSecondaryBtn={false}
                  primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.VIEW')}
                  cssName={'modal'}
                >
                  <div className='reject-modal__content'>
                    <p className='highlight'>
                      {i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.REJECTED_AREAS')}
                    </p>
                    {this.props?.rejectReasons?.length > 0 &&
                      this.props.rejectReasons.map((event) => {
                        return (
                          <p key={event?.rejectReason?.reasonName}>
                            {event?.rejectReason?.reasonName}
                          </p>
                        );
                      })}
                    <p className='highlight'>
                      {i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.COMMENTS')}
                    </p>
                    <p className='comment'>{comments}</p>
                  </div>
                </DialogBox>
              );
            }
          })()}
        </div>
        {(() => {
          if (this.state.openSharesModal === true) {
            const comments =
              this.props?.hardwareProductDetail?.prodBasicInfo?.comments;
            const planDetails = this.props?.pricingPlans;
            return (
              <SkuDialogBox
                handleCancel={this.closeShareModal}
                handleConfirm={() => {
                  this.marketPlaceSelection();
                  this.closeShareModal();
                }}
                isModal={false}
                title={this.state.modalData.displayName}
                isDivider={false}
                primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.PUBLISH')}
                cssName={'custom-modal'}
              >
                <SkuIdDetails
                  cardData={this.props.productSharesData}
                  modalData={this.state.modalData}
                  comments={comments}
                  planDetails={planDetails}
                  tenantProductStatus={
                    this.props.tenantProductStatus?.tenantProductStatus
                  }
                />
              </SkuDialogBox>
            );
          }
        })()}

        {(() => {
          if (this.state.openPublishedModal === true) {
            const comments =
              this?.props?.data?.productCategoryId ===
              IdConstants.automotiveSpares.categoryId
                ? i18next.t('AUTOMOTIVE.AUTO_APPROVED')
                : this.props?.hardwareProductDetail?.prodBasicInfo?.comments;
            const planDetails = this.props?.pricingPlans;
            return (
              <SkuDialogBox
                handleCancel={this.closePublishedModal}
                handleConfirm={() => {
                  this.okBtnAction();
                  this.closePublishedModal();
                }}
                isModal={false}
                title={this.state.modalData.displayName}
                isDivider={false}
                isPrimaryBtn={false}
                cssName={'custom__modal'}
              >
                <SkuIdDetails
                  cardData={this.props.productSharesData}
                  modalData={this.state.modalData}
                  comments={comments}
                  planDetails={planDetails}
                  tenantProductStatus={
                    this.props.tenantProductStatus?.tenantProductStatus
                  }
                />
              </SkuDialogBox>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  productSharesData: redux_state.SolutionReducer.productSharesData,
  rejectReasons: redux_state.rejectReasonReducer.rejectReasons,
  pricingPlans: redux_state.ProductPricingPlanReducer.pricingPlans,
  hardwareProductDetail: redux_state.HardwareProductReducer.hardwareProductDetail,
  categoryList: redux_state.CategoryManagementReducer.categoryList,
  isVisibleData: redux_state.ProductDetailReducer.isVisibleData,
  tenantProductStatus: redux_state.SolutionReducer.tenantProductStatus
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSkuIdDetails,
      getTenantProductStatus,
      getProductDetailedInfo,
      getEditableDetails,
      getApiPlans
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StylledCard);
