import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApiAlerts from '../../components/api/APIAlerts';
import loading from '../../actions/LoaderAction';
import { resetSuccess } from '../../actions/SuccessAction';
import {
  getAllApiAlerts,
  getProdSpecificApiAlerts,
  createProdSpecificApiAlerts,
  updateProdSpecificApiAlerts
} from '../../actions/api/ApiAlertsAction';
import axios from 'axios';

class ApiAlertsContainer extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        self.props.loading(true);
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.props.loading(false);
        return response;
      },
      function (error) {
        self.props.loading(false);
        return Promise.reject(error);
      }
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getAllApiAlerts();
  }

  render() {
    return <ApiAlerts {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSuccess,
      loading,
      getAllApiAlerts,
      getProdSpecificApiAlerts,
      createProdSpecificApiAlerts,
      updateProdSpecificApiAlerts
    },
    dispatch
  );
}

const mapStateToProps = (redux_state) => ({
  success: redux_state.SuccessReducer.success,
  loader: redux_state.LoaderReducer,
  allApiAlerts: redux_state.ApiAlertsReducer.allApiAlerts,
  prodSpecificApiAlerts: redux_state.ApiAlertsReducer.prodSpecificApiAlerts,
  createSpecificApiAlerts: redux_state.ApiAlertsReducer.createSpecificApiAlerts,
  updateSpecificApiAlerts: redux_state.ApiAlertsReducer.updateSpecificApiAlerts
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiAlertsContainer);
