import { portalViewData } from '../data/appConstants.js';

const initialState = {
  validToken: false,
  user: {},
  errorLogin: null,
  userDetails: {},
  eulaAcceptance: true,
  acceptEulaResult: {},
  acceptEulaErr: null,
  forgotPassword: false,
  checkUserAgreement: [],
  checkUserAgreementVersion2: '',
  showTermsConditions: false,
  showPrivacyPolicies: false,
  tokenInvalidated: false,
  refreshToken: '',
  accessToken: '',
  noRole: false,
  portalView: portalViewData.DESKTOP,
  countryCodeRegion: '',
  countryCodeFlagSet: false
};

const booleanActionPayload = (payload) => {
  return payload ? true : false;
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        validToken: booleanActionPayload(action.payload),
        user: action.payload
      };
    case 'ERROR_LOGIN':
      return Object.assign({}, state, { errorLogin: action.error });
    case 'SET_USER_DETAILS':
      return {
        ...state,
        userDetails: action.payload
      };
    case 'EULA_ACCEPTANCE':
      return Object.assign({}, state, { eulaAcceptance: action.payload });
    case 'ACCEPT_EULA_RESULT':
      return Object.assign({}, state, {
        acceptEulaResult: action.payload,
        acceptEulaErr: null
      });
    case 'ACCEPT_EULA_ERR':
      return Object.assign({}, state, {
        acceptEulaResult: {},
        acceptEulaErr: action.error
      });
    case 'FORGOT_PASSWORD':
      return Object.assign({}, state, { forgotPassword: action.payload });
    case 'CHECK_USER_AGREEMENT':
      return { ...state, checkUserAgreement: action.payload };
    case 'CHECK_RESELLER':
      return { ...state, isReseller: action.payload };
    case 'SHOW_TERMS_CONDITIONS':
      return { ...state, showTermsConditions: action.payload };
    case 'SHOW_PRIVACY_POLICIES':
      return { ...state, showPrivacyPolicies: action.payload };
    case 'TOKEN_INVALIDATED':
      return { ...state, tokenInvalidated: action.payload };
    case 'REFRESH_TOKEN':
      return { ...state, refreshToken: action.payload };
    case 'ACCESS_TOKEN':
      return { ...state, accessToken: action.payload };
    case 'NO_ROLE':
      return { ...state, noRole: action.payload };
    case 'SET_PORTAL_VIEW':
      return Object.assign({}, state, { portalView: action.payload });
    case 'COUNTRY_CODE_REGION':
      return Object.assign({}, state, {
        countryCodeRegion: action.payload,
        countryCodeFlagSet: action.flag
      });
    default:
      return state;
  }
};

export default loginReducer;
