import Axios from 'axios';
import * as Constants from './configuration';

export const retrieveUserCompanyStatus = (dcsCompanyData) => async (dispatch) => {
  await Axios.get(Constants.COMPANY.COMPANY_STATUS, {
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(async (response) => {
      await dispatch({
        type: 'USER_COMPANY_STATUS',
        payload: response?.data
      });
      await dispatch({
        type: 'IS_REG_CHECK_DONE',
        payload: true
      });
      if (
        response.data &&
        dcsCompanyData &&
        Object.keys(dcsCompanyData).length > 0 &&
        dcsCompanyData.dcsCompanyId !== ''
      ) {
        if (!response.data.companyId) {
          dispatch({
            type: 'MISMATCH_IN_DCS_MS_COMPANY_DATA',
            payload: true
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_USER_COMPANY_STATUS',
        payload: error
      });
      dispatch({
        type: 'IS_REG_CHECK_DONE',
        payload: true
      });
    });
};

export const retrieveUserCompanyStatusForViewProfile = () => async (dispatch) => {
  await Axios.get(Constants.COMPANY.COMPANY_STATUS, {
    headers: {
      'content-type': 'application/json'
    }
  })
    .then((response) => {
      dispatch({
        type: 'USER_VIEW_COMPANY_STATUS',
        payload: response?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_USER_VIEW_COMPANY_STATUS',
        payload: error
      });
    });
};

export const getDCSCompanyStatus = () => async (dispatch) => {
  await Axios.get(Constants.COMPANY.GET_MYCOMPANY_STATUS, {
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(async (response) => {
      if (
        response.data.role.some((item) => item.name === '') ||
        response.data.role.length === 0
      ) {
        await dispatch({
          type: 'IS_REG_CHECK_DONE',
          payload: true
        });
      } else if (
        response.data.role.some(
          (item) => item.name === Constants.AAA_CONFIG.SELLER_ROLE
        ) ||
        response.data.role.find(
          (item) => item.name === Constants.AAA_CONFIG.BUYER_ROLE
        )
      ) {
        await dispatch(retrieveUserCompanyStatus(response.data));
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_USER_COMPANY_STATUS',
        payload: error
      });
      dispatch({
        type: 'IS_REG_CHECK_DONE',
        payload: true
      });
    });
};
