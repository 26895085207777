import axios from 'axios';
import {
  PRODUCT_BASIC_INFO,
  PRODUCT_BASIC_INFO_CABPABILITIES,
  PRODUCT_ADDITIONAL_INFO_CABPABILITIES,
  PRODUCT_ADDITIONAL_INFO,
  PRODUCT_STATECHANGE_CAPABILITIES,
  PRODUCT_ONBOARDING_FORM,
  FILE_UPLOAD,
  GET_PRODUCTS,
  PRODUCT_DETAIL_ACTION,
  REMOVE_GROUP_CONTENT,
  SOLUTION_ACTION,
  PRODUCT_SERVICE_URL
} from '../../actions/configuration';
import IdConstants from '../../data/idConstants';
import { GAEventAction } from '../../data/ga-constants';
import {
  SAVE_FORM_JSON_DATA,
  SAVE_FORM_BASIC_INFO,
  SAVE_FORM_ADDITIONAL_INFO,
  SAVE_HARDWARE_PRODUCTS,
  SAVE_ALL_HARDWARE_PRODUCTS,
  SAVE_TERMS_AND_CONDITION_FILE,
  SAVE_DPN_DOCUMENT,
  SAVE_FORM_JSON_DATA_ONLY_VALUE
} from '../ActionTypes';
import { utf8Encode } from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { FieldConstants } from '../../shared/constants/field-constants';
import { getUserDetailsData } from '../../shared/shared-function/services';
import { getRegionCode } from '../../utils/get-region-details';

let cancelTokenSource = null;

const cancelPreviousRequest = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Previous request cancelled');
  }
  cancelTokenSource = axios.CancelToken.source();
};

export function getDynamicFormData(categoryId) {
  const regionCode = getRegionCode();
  return (dispatch) =>
    axios({
      method: 'GET',
      url: PRODUCT_ONBOARDING_FORM + categoryId + `?regionCode=${regionCode}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        let filteredArray = [];
        if (categoryId === IdConstants.solutions.categoryId) {
          filteredArray = response.data.steps.map((item) => {
            if (item.name === FieldConstants.prodBasicInfo && item.attributes) {
              item.attributes = item.attributes.filter(
                (attribute) =>
                  attribute.name !== FieldConstants.productBusinessCategoryMap &&
                  attribute.name !== FieldConstants.subcategory
              );
            }
            return item;
          });
        }
        const updatedObject = { ...response.data, steps: filteredArray };
        return categoryId === IdConstants.solutions.categoryId
          ? updatedObject
          : response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function getHardwareProducts(categoryId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_PRODUCTS + `page=0&size=15&prodCategoryId=${categoryId}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: SAVE_HARDWARE_PRODUCTS,
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function getAllHardwareProducts(
  categoryId = 'fbd9542b-a534-47bc-b2c9-7b9e3fef7921'
) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_PRODUCTS + `categoryId=${categoryId}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: SAVE_ALL_HARDWARE_PRODUCTS,
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function saveFormBasicInfo(reqObj, companyId) {
  const basicInfoPayload = {
    data: reqObj,
    ...getUserDetailsData(
      companyId,
      null,
      reqObj.prodBasicInfo.productCategory.categoryId
    )
  };

  return (dispatch) =>
    axios({
      method: 'POST',
      url: PRODUCT_BASIC_INFO_CABPABILITIES,
      data: basicInfoPayload,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: SAVE_FORM_BASIC_INFO,
          payload: response?.data?.data
        });
        return response?.data?.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function updateFormBasicInfo(reqObj, productId) {
  return (dispatch) => {
    cancelPreviousRequest();
    return axios({
      method: 'PATCH',
      url: PRODUCT_BASIC_INFO + `/${productId}?`,
      data: reqObj,
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: cancelTokenSource.token
    })
      .then((response) => {
        dispatch({
          type: SAVE_FORM_BASIC_INFO,
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
  };
}

export function saveFormAdditionalInfo(params, featureId, companyId) {
  const additionalInfoPayload = {
    data: params,
    ...getUserDetailsData(
      companyId,
      featureId,
      params.prodBasicInfo.productCategory.categoryId
    )
  };

  return (dispatch) =>
    axios
      .post(
        PRODUCT_ADDITIONAL_INFO_CABPABILITIES + featureId,
        additionalInfoPayload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch({
          type: SAVE_FORM_ADDITIONAL_INFO,
          payload: response?.data?.data?.productDetailedInfo
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function updateFormAdditionalInfo(params, featureId) {
  return (dispatch) =>
    axios
      .patch(PRODUCT_ADDITIONAL_INFO + featureId, params, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch({
          type: SAVE_FORM_ADDITIONAL_INFO,
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function saveFormJsonData(data) {
  return {
    type: SAVE_FORM_JSON_DATA,
    payload: data
  };
}

export function saveFormJsonDataOnlyValue(data) {
  return {
    type: SAVE_FORM_JSON_DATA_ONLY_VALUE,
    payload: data
  };
}

export function saveAdditionalInfo(data) {
  return {
    type: SAVE_FORM_ADDITIONAL_INFO,
    payload: data
  };
}
export function saveBasicInfo(data) {
  return {
    type: SAVE_FORM_BASIC_INFO,
    payload: data
  };
}

export function handleResetForm() {
  return (dispatch) => {
    dispatch({
      type: SAVE_FORM_JSON_DATA,
      payload: []
    });
    dispatch({
      type: SAVE_FORM_JSON_DATA_ONLY_VALUE,
      payload: null
    });
    dispatch({
      type: SAVE_FORM_BASIC_INFO,
      payload: {}
    });
    dispatch({
      type: SAVE_FORM_ADDITIONAL_INFO,
      payload: {}
    });
    dispatch({
      type: 'GET_PRICING_PLANS',
      payload: []
    });
  };
}

export function updateProductStatus(statusId, productId, categoryId, companyId) {
  const reqJson = {
    data: {
      prodBasicInfo: {
        productCategory: {
          categoryId: categoryId
        },
        status: {
          statusId: statusId
        },
        comments: ''
      }
    },
    ...getUserDetailsData(companyId, productId, categoryId)
  };
  return (dispatch) =>
    axios
      .patch(PRODUCT_STATECHANGE_CAPABILITIES + productId + `?`, reqJson, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch({
          type: SAVE_FORM_BASIC_INFO,
          payload: response.data?.data
        });
        return response?.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function uploadFile(imageFile, productId, docTypeId, categoryId) {
  let actionType;
  let bodyFormData = new FormData();
  bodyFormData.append('data', imageFile);
  bodyFormData.append('categoryId', categoryId);
  bodyFormData.append('documentTypeId', docTypeId);
  if (docTypeId === IdConstants.documentType.videoFile) {
    actionType = 'videoFiles';
  } else {
    actionType = 'uploadFiles';
  }
  return (dispatch) =>
    axios({
      method: 'POST',
      url: FILE_UPLOAD.UPLOADFILE + productId + '/files',
      data: bodyFormData,
      actionType: actionType,
      docTypeId: docTypeId
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'UPLOAD_FILES_ERR',
          payload: error
        });
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
        dispatch({
          type: 'UPLOAD_FILES_ERR_STATUS',
          payload: error?.response?.data
        });
      });
}

export function deleteFile(file, categoryId) {
  return (dispatch) =>
    axios
      .delete(
        FILE_UPLOAD.UPLOADFILE +
          file.productId +
          '/files?data=' +
          encodeURIComponent(file.documentName) +
          '&categoryId=' +
          categoryId +
          '&documentTypeId=' +
          file.docTypeId,
        {
          headers: {
            'Content-Type': 'application/json',
            Validate: 'false'
          },
          data: {},
          actionType: 'uploadFiles',
          docTypeId: file.docTypeId
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error
        });
      });
}

export function getProductDocsListById(productId, documentTypeId) {
  return (dispatch) =>
    axios
      .get(
        PRODUCT_DETAIL_ACTION.GET_PRODUCT_DOCS_LIST_BY_ID +
          productId +
          (documentTypeId ? '?documentTypeId=' + documentTypeId : ''),
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
          actionType: 'getDocuments'
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getProductAllDocsList(productId) {
  return (dispatch) =>
    axios
      .get(
        PRODUCT_DETAIL_ACTION.GET_PRODUCT_DOCS_LIST_BY_ID +
          productId +
          '?documentTypeId=all&isDpnEnabled=true',
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
          actionType: 'getDocuments'
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function validateProductUniqueness(name, categoryId) {
  name = btoa(utf8Encode(name));
  return (dispatch) =>
    axios
      .get(
        SOLUTION_ACTION.PRODUCT_BASIC_INFO +
          `?name=${name}&categoryId=` +
          categoryId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'VALIDATE_PRODUCT_UNIQUENESS',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_NAME_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
// The publishToTenant function is specifically designed for the Best Marketplace to publish tos tenant.
export const publishToTenant = (payload) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${PRODUCT_SERVICE_URL}/tenant/publish`,
      data: payload,
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        return error;
      });
  };
};

export function publishProduct(data, selectedIndex, tenantIds, companyId) {
  let reqJson = {
    data: {
      prodBasicInfo: {
        productCategory: {
          categoryId: data.productCategoryId
        },
        status: {
          statusId: IdConstants.published.statusId
        }
      }
    },
    ...getUserDetailsData(companyId, data.productId, data.productCategoryId)
  };
  if (tenantIds?.length > 0) {
    reqJson.data.tenantIds = tenantIds;
    reqJson.data.prodBasicInfo.status = {
      statusId: IdConstants.tenantPublish.statusId
    };
  }

  return (dispatch) =>
    axios
      .patch(PRODUCT_STATECHANGE_CAPABILITIES + data.productId, reqJson, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        return axios
          .get(GET_PRODUCTS, {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {}
          })
          .then((response2) => {
            dispatch({
              type: SAVE_ALL_HARDWARE_PRODUCTS,
              payload: response2.data
            });
            dispatch({
              type: SAVE_HARDWARE_PRODUCTS,
              payload: response2.data
            });
            //GA-313
            trackEvent(
              'create_product_published',
              GAEventAction.productPublished,
              data?.productName
            );
            return response2;
          })
          .catch((error) => {
            dispatch({
              type: 'PUBLISH_ERROR',
              error: error,
              selectedIndex: selectedIndex,
              errorMsg:
                'Product has been Published, Please refresh the page to reflect the changes'
            });
          });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PUBLISH_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function deleteProductGroup(payload, productId) {
  return (dispatch) =>
    axios
      .delete(`${REMOVE_GROUP_CONTENT}/${productId}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: payload
      })
      .then((response) => {
        dispatch({
          type: SAVE_FORM_ADDITIONAL_INFO,
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function getProducts(categoryId, searchText, page = 0, size = 4) {
  const searchParam = searchText ? `&txtInProdName=${searchText}` : '';
  const prodCategoryId = categoryId ? `&prodCategoryId=${categoryId}` : '';

  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_PRODUCTS + `page=${page}&size=${size}${searchParam}${prodCategoryId}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function saveMinimumOrderQuantity(reqObj) {
  return (dispatch) =>
    axios({
      method: 'PUT',
      url: PRODUCT_DETAIL_ACTION.MINIMUM_ORDER_QUANTITY,
      data: reqObj,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function handleSaveTermsAndCondition(payload) {
  return (dispatch) => {
    dispatch({
      type: SAVE_TERMS_AND_CONDITION_FILE,
      payload: payload
    });
  };
}

export function handleSaveDPNDocument(payload) {
  return (dispatch) => {
    dispatch({
      type: SAVE_DPN_DOCUMENT,
      payload: payload
    });
  };
}
