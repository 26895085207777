const initialState = {
  productMetaData: {}
};

const ManageProductsReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_META_DATA_PREVIEW':
      return Object.assign({}, state, { productMetaData: action.payload });
    default:
      return state;
  }
};

export default ManageProductsReducer_Preview;
