import axios from 'axios';
import * as Constants from './configuration';

export const getRejectReasonForProduct = (productId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Constants.REJECT_REASON_ACTION.REJECT_REASON + `${productId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      );
      const rejectReasons = response.data;
      dispatch({
        type: 'SET_REJECT_REASONS',
        payload: rejectReasons
      });
      return rejectReasons;
    } catch (err) {
      dispatch({
        type: 'ERROR',
        error: err
      });
    }
  };
};
