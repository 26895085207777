import React from 'react';
import PropTypes from 'prop-types';
import Images from '../../../../assets/img';
import i18next from 'i18next';
import fileNameWithEllipsis from '../../../../utils/file-name-with-ellipsis';

const AttachmentCard = ({ file, handleDelete, isView, handlePreviewElement }) => {
  if (!file) return null;

  return (
    <div
      className='upload__list upload__list_spec'
      onClick={() => handlePreviewElement(file)}
    >
      <div key={file?.documentName} className='upload__list_file'>
        <img
          src={Images.documentDoc}
          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOCUMENT')}
          className='upload__list_doc'
        />
        <label className='-size-m'>{fileNameWithEllipsis(file?.documentName)}</label>
      </div>
      {!isView && (
        <img
          src={Images.deleteIcon}
          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE')}
          onClick={(event) => handleDelete(event, file)}
          className='upload__list_del'
          data-testid='upload__list_del'
        />
      )}
    </div>
  );
};

AttachmentCard.propTypes = {
  isView: PropTypes.bool,
  handlePreviewElement: PropTypes.func
};

AttachmentCard.defaultProps = {
  isView: false,
  handlePreviewElement: () => null
};

export default AttachmentCard;
