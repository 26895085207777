const initialState = {
  tenantList: []
};

const PublishRequestReducer = (state = initialState, action) => {
  if (['GET_TENANT_NAME', 'CLEAR_TENANT_LIST'].includes(action.type)) {
    return Object.assign({}, state, { tenantList: action.payload });
  }

  return state;
};

export default PublishRequestReducer;
