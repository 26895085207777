import React from 'react';
import { PrimaryButton } from '../../../msreact-kit/html-components/button';
import styles from './access-denied.module.scss';

const AccessDenied = ({ show, signOut, details }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles['popup-overlay']}>
      <div className={styles['popup-content']}>
        <img
          src={details.accessDeniedImage}
          alt={details.accessDeniedAlt}
          className={styles['popup-image']}
        />
        <h6 className={styles['popup-header']}>{details.accessDeniedCode}</h6>
        <h6 className={styles['popup-text-header']}>{details.header} </h6>
        <p className={styles['popup-text']}>{details.text}</p>
        <PrimaryButton
          className={styles['access-account-signout-button']}
          onClick={signOut}
        >
          <span>{details.signOut}</span>
          <i className='a-icon boschicon-bosch-ic-logout logout-icon -size-m' />
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AccessDenied;
