const initialState = {
  loader: false
};
const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_LOADER':
      return Object.assign({}, state, { loader: action.data });
    case 'HIDE_LOADER':
      return Object.assign({}, state, { loader: action.data });
    default:
      return state;
  }
};

export default LoaderReducer;
