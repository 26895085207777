import React from 'react';
import PropTypes from 'prop-types';

import FileUpload from './file-upload';
import validation from '../validation';
import ThumbnailCard from './thumbnail-card';
import Images from '../../../assets/img';
import DocNotes from './doc-notes';

import './index.scss';
import i18next from 'i18next';

const VideoUpload = ({
  displayName,
  placeHolder,
  value,
  handleChange,
  handleDeleteFile,
  ...props
}) => {
  const handleDelete = (file) => {
    handleDeleteFile(file);
    const convertArray = [...new Set(value.flat())];
    const updatedList = [...value];
    updatedList.splice(convertArray.indexOf(file), 1);

    const data = {
      value: updatedList,
      error: '',
      fieldId: ''
    };
    if (updatedList.length === 0) {
      data.error = `${i18next.t('DYNAMIC_FORM.VIDEO_UPLOAD.IMAGE')} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
      data.isInValid = true;
    }
    handleChange(data);
    handleDeleteFile(file);
  };

  const files =
    value &&
    value?.length > 0 &&
    value
      .flat()
      .map((file) => (
        <ThumbnailCard
          key={file}
          file={file}
          mediaIcon={Images.startPlay}
          handleDelete={handleDelete}
          uploadType='uploadVideo'
        />
      ));

  return (
    <React.Fragment>
      <h4 className='-size-l mb12'>{displayName}</h4>
      <div className='horizontal__container'>
        <FileUpload
          className='upload__image'
          validations={validation.uploadVideo}
          mediaIcon={Images.imagery}
          handleChange={handleChange}
          value={value}
          {...props}
        >
          <img
            className='upload__add_icon'
            src={Images.addIcon}
            alt={i18next.t('DYNAMIC_FORM.VIDEO_UPLOAD.ADD_FRAME')}
          />
          <label className='upload__label'>{placeHolder}</label>
        </FileUpload>
        {files && files.length > 0 && (
          <div className='upload__media_listing' data-testid='handler'>
            {files}
          </div>
        )}

        <DocNotes {...props.validation} />
      </div>
    </React.Fragment>
  );
};

VideoUpload.propTypes = {
  displayName: PropTypes.string,
  placeHolder: PropTypes.string
};

VideoUpload.defaultProps = {
  displayName: '',
  placeHolder: ''
};

export default VideoUpload;
