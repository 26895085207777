import i18next from 'i18next';
import moment from 'moment';

export const convertUTCToLocalDateFormat = (date, format) => {
  if (!date) return null;

  return moment.utc(date).local().format(format);
};

export const convertUTCToLocalDateTime = (date) => {
  return convertUTCToLocalDateFormat(date, 'DD/MM/YYYY h:mm A');
};

export const convertUTCToLocalDate = (date) => {
  return convertUTCToLocalDateFormat(date, 'DD/MM/YYYY');
};

export const getAutoSaveInfo = (inMilliseconds) => {
  let durationObj;
  const seconds = inMilliseconds / 1000;
  let autoSaveTextKey =
    'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.AUTOSAVED_AFTER_INACTIVITY_MINUTES';
  if (seconds <= 60) {
    autoSaveTextKey = 'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.AUTOSAVED_AFTER_INACTIVITY';
    durationObj = {
      durationType: 'seconds',
      duration: seconds
    };
  }

  const minutes = seconds / 60;
  if (minutes <= 60) {
    durationObj = {
      durationType: 'minutes',
      duration: minutes
    };
  }

  durationObj.autoSaveText = i18next.t(autoSaveTextKey, {
    autoSaveIn: durationObj.duration
  });
  return durationObj;
};
