const initialState = {
  usageTracking: [],
  usageMetrics: [],
  responseHeaders: ''
};

const ApiStatisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USAGE_TRACKING':
      return Object.assign({}, state, {
        usageTracking: action.payload,
        responseHeaders: action.headers,
        errorMsg: action.errorMsg
      });
    case 'GET_USAGE_METRICS':
      return Object.assign({}, state, { usageMetrics: action.payload });
    default:
      return state;
  }
};

export default ApiStatisticsReducer;
