import React from 'react';
import i18next from 'i18next';
import ProductListView from './product-list-view';
import { PrimaryButton } from '../../msreact-kit/packages/html-components';
import NoResultDisplay from '../../components/Shared/no-result-display';
import { toLowercaseReplaceSpace } from '../../shared/shared-function/helperFunctions';
import DeviceBreakPoints from '../../preview/deviceBreakPoints';
import './index.scss';

const ListProducts = (props) => {
  const { isSmallDevice, isTab } = DeviceBreakPoints();
  const rowsToDisplay = isSmallDevice ? 1 : isTab ? 3 : 4;

  const handleProduct = (category) => {
    props.history.push({
      pathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
        category.productCategoryName
      )}`,
      state: { productCategoryId: category.productCategoryId }
    });
  };

  const getProductCount = (response) => {
    let productCount = 0;
    response &&
      response.forEach((item) => {
        productCount += item.productCount;
      });
    return productCount;
  };

  return (
    <>
      <div className='my-products'>
        <div className='my-products_item'>
          <p className='-size-l text my-products_item_text'>
            {props.category.productCategoryName} (
            {getProductCount(props?.category?.count)})
          </p>
          <div className='my-products_item_view-button'>
            {getProductCount(props?.category?.count) > rowsToDisplay && (
              <PrimaryButton
                label={`${i18next.t('DYNAMIC_FORM.LABEL.VIEW_ALL')} >>`}
                onClick={() => handleProduct(props.category)}
              />
            )}
          </div>
        </div>
        {/* Change the props of APIListView for hardware  */}
        <div className='my-products_cards'>
          {(props?.category?.data?.length === 0 || !props?.category?.data) &&
          !props.loader.loader ? (
            <NoResultDisplay />
          ) : (
            <ProductListView
              data={props?.category?.data}
              handlePublish={props.publishProduct}
              info={props.info}
              path='hardware'
              updateInfo={props.updateInfo}
              updatePrevSession={props.updatePrevSession}
              resetEditableDetails={props.resetEditableDetails}
              location={props.location}
              history={props.history}
              getProductQuantity={props.getProductQuantity}
              rootProductCategoryId={props?.category?.rootProductCategoryId}
              getPartNumber={props.getPartNumber}
              handleDeleteProduct={props.handleDeleteProduct}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListProducts;
