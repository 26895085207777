import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from '../../packages/html-components';
import ModalCard from './modal-card';

import './index.scss';

const SkuDialogBox = ({
  title,
  subTitle,
  primaryLabel,
  secondaryLabel,
  handleCancel,
  handleConfirm,
  isModal,
  isSuccess,
  isSecondaryBtn,
  children,
  isDivider,
  isPrimaryBtn,
  cssName
}) => {
  return (
    <div className='sku-dialog_modal'>
      <div className={`sku-dialog_modal__content ${cssName}`}>
        {isModal ? (
          <ModalCard
            title={title}
            subTitle={subTitle}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            isModal={isModal}
            isSuccess={isSuccess}
            isSecondaryBtn={isSecondaryBtn}
          >
            {children}
          </ModalCard>
        ) : (
          <Fragment>
            <div className='dialog_remark__message'>
              <h4 className='dialog_remark__message__title -size-l mb12 text-ellipsis'>
                {title}
              </h4>
              <button
                type='button'
                className='a-button a-button--integrated -without-label dialog_remark__close'
                data-frok-action='close'
                aria-label='close dialog'
                onClick={handleCancel}
              >
                <i
                  className='a-icon a-button__icon ui-ic-close'
                  title='Close'
                  width={24}
                  height={24}
                ></i>
              </button>
            </div>
            {isDivider && <hr className='a-divider' />}
            {children}
            {isPrimaryBtn && (
              <div
                className={`sku-dialog_modal__message ${
                  cssName && cssName + '_msg'
                }`}
              >
                <PrimaryButton onClick={handleConfirm} label={primaryLabel} />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SkuDialogBox;

SkuDialogBox.propTypes = {
  isModal: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isDivider: PropTypes.bool,
  isPrimaryBtn: PropTypes.bool
};

SkuDialogBox.defaultProps = {
  isModal: false,
  isSuccess: false,
  isDivider: false,
  isPrimaryBtn: true
};
