import React from 'react';
import PropTypes from 'prop-types';

import FileUpload from '../file-upload';
import { SecondaryButton } from '../../button';
import AttachmentCard from '../attachment-card';
import DocNotes from '../doc-notes';
import Errors from '../../errors';
import './index.scss';
import i18next from 'i18next';

const UserManual = ({
  displayName,
  placeHolder,
  value,
  handleChange,
  handleDeleteFile,
  customClass,
  handlePreviewElement,
  error,
  disabled,
  isRequired,
  ...props
}) => {
  const handleDelete = (event, file) => {
    event.stopPropagation();
    handleDeleteFile(file);
  };

  const files =
    value &&
    value?.length > 0 &&
    value.flat().map((file) => (
      <div className='pdu__document-wrapper' key={file}>
        <AttachmentCard
          key={file}
          file={file}
          handleDelete={handleDelete}
          handlePreviewElement={handlePreviewElement}
          isView={props.isView}
        />
      </div>
    ));
  return (
    <React.Fragment>
      <h4 className='pdu__displayName -size-l mb12'>{displayName}</h4>
      <div className={`pdu__container ${customClass}`}>
        <div>
          <FileUpload
            className={`upload__user ${disabled && 'ms-disabled'}`}
            handleChange={handleChange}
            value={value}
            disabled={disabled}
            {...props}
          >
            <label className='-size-m'>
              {placeHolder?.length > 0
                ? placeHolder
                : i18next.t('DYNAMIC_FORM.LABEL.DROP_FILES_HERE_TO_IMPORT_THEM')}
              {isRequired && <span className='input__required'>*</span>}
            </label>
            <div className='pdu__divider'>
              <div className='a-divider pdu__width' />
              <label className='pdu__label_space'>
                {i18next.t('DYNAMIC_FORM.LABEL.OR')}
              </label>
              <div className='a-divider pdu__width' />
            </div>
            <SecondaryButton
              label={
                props?.validation?.maxFiles > 1
                  ? i18next.t('DYNAMIC_FORM.LABEL.SELECT_FILES')
                  : i18next.t('DYNAMIC_FORM.LABEL.SELECT_FILE')
              }
              disabled={disabled}
            />
          </FileUpload>
          <DocNotes {...props.validation} />
        </div>
        {error && <Errors errorMessaage={error} />}
        {files && files.length > 0 && (
          <div className='upload__doc_listing' data-testid='handler'>
            {files}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

UserManual.propTypes = {
  displayName: PropTypes.string,
  placeHolder: PropTypes.string,
  isRequired: PropTypes.bool
};

UserManual.defaultProps = {
  displayName: '',
  placeHolder: 'Drop files here to import them',
  isRequired: false
};

export default UserManual;
