const initialState = {
  adminPricingPlans: [],
  apiPlans: [],
  validationError: {},
  userSubscriptions: [],
  subscribePlan: null,
  subscribeError: null,
  unSubscribedSuccess: {},
  unSubscribeError: null,
  userApplications: [],
  wso2AccessToken: {},
  productSubscriptions: [],
  enableTryOutError: null,
  isSubscribedProduct: []
};

const PricingPlanReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_API_PLANS_PREVIEW':
      return Object.assign({}, state, { apiPlans: action.payload });
    case 'CLEAR_API_PLANS_PREVIEW':
      return Object.assign({}, state, { apiPlans: action.payload });
    case 'SUBSCRIBE_PLAN_PREVIEW':
      return Object.assign({}, state, {
        subscribePlan: action.payload,
        subscribeError: action.error
      });
    case 'GET_USER_SUBSCRIPTIONS_PREVIEW':
      return Object.assign({}, state, { userSubscriptions: action.payload });
    case 'GET_USER_APPLICATIONS_PREVIEW':
      return Object.assign({}, state, { userApplications: action.payload });
    case 'UNSUBSCRIBE_PLAN_PREVIEW':
      return Object.assign({}, state, {
        unSubscribedSuccess: action.payload,
        unSubscribeError: action.unSubscribeError
      });
    case 'WSO2_ACCESS_TOKEN_PREVIEW':
      return Object.assign({}, state, {
        wso2AccessToken: action.payload,
        enableTryOutError: null
      });
    case 'GET_SUBSCRIPTION_FOR_PRODUCT_PREVIEW':
      return Object.assign({}, state, {
        productSubscriptions: action.payload,
        isSubscribedProduct: action.payload
      });
    case 'TRYOUT_ENABLE_ERROR_PREVIEW':
      return Object.assign({}, state, { enableTryOutError: action.error });
    case 'CLEAR_SUBSCRIBE_ERRORS_PREVIEW':
      return Object.assign({}, state, { subscribeError: action.payload });
    case 'CLEAR_SUBSCRIBE_PLAN_PREVIEW':
      return Object.assign({}, state, { subscribePlan: action.payload });
    default:
      return state;
  }
};

export default PricingPlanReducer_Preview;
