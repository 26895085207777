import React from 'react';
import images from '../../../assets/img';
import './index.scss';

const NoResultDisplay = () => {
  return (
    <div className='display-results'>
      <h5 className='highlight -size-l'>Sorry! No results found!</h5>
      <p className='text -size-m center-align'>
        Have you checked the spelling? Try a different search term.
      </p>
      <img
        className='display-results_icon'
        src={images.noResultIcon}
        alt='No Result'
      />
    </div>
  );
};

export default NoResultDisplay;
