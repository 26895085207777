const initialState = {
  success: null
};

const SuccessReducer = (state = initialState, action) => {
  if (action.type === 'SUCCESS') {
    return Object.assign({}, state, { success: action.success });
  }
  return state;
};

export default SuccessReducer;
