const initialState = {
  stockApi: [],
  productQuantityDetails: {}
};

const StockAvailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STOCK_AVAIL_API':
      return Object.assign({}, state, { stockApi: action.payload });
    case 'GET_PRODUCT_QUANTITY_DETAILS':
      return Object.assign({}, state, {
        productQuantityDetails: action.payload
      });
    default:
      return state;
  }
};

export default StockAvailReducer;
