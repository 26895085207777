const initialState = {
  usageTracking: [],
  userApplications: [],
  apiDataMsg: {},
  usageMetrics: [],
  allSubscriptions: []
};

const ApiStatisticsReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USAGE_TRACKING_PREVIEW':
      return Object.assign({}, state, {
        usageTracking: action.payload,
        apiDataMsg: {}
      });
    case 'GET_USER_APPLICATIONS_PREVIEW':
      return Object.assign({}, state, { userApplications: action.payload });
    case 'API_DATA_PREVIEW':
      return Object.assign({}, state, {
        apiDataMsg: action.errorMsg,
        usageTracking: {}
      });
    case 'GET_USAGE_METRICS_PREVIEW':
      return Object.assign({}, state, { usageMetrics: action.payload });
    case 'ALL_SUBSCRIPTIONS_PREVIEW':
      return Object.assign({}, state, { allSubscriptions: action.payload });
    default:
      return state;
  }
};

export default ApiStatisticsReducer_Preview;
