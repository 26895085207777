import React from 'react';
import i18next from 'i18next';

import PricingNotification from '../pricing-notification';
import idConstants from '../../../data/idConstants';
import CustomToolTip from '../../Shared/custom-tooltip';
import images from '../../../assets/img';

import './index.scss';

const PricingStatusNotification = ({ plans, isShowSubmitNotification }) => {
  const getCommaSeparatedPlanNames = (statusId) => {
    const filteredPlans = plans?.filter(
      (plan) => plan.planName && plan?.status?.statusId === statusId
    );

    if (filteredPlans.length === 1) {
      return {
        planNames: filteredPlans[0].planName,
        filteredPlans: filteredPlans
      };
    } else {
      const filteredName = filteredPlans.map((plan) => plan.planName);
      return {
        planNames: filteredName.join(', '),
        filteredPlans: filteredPlans
      };
    }
  };

  const { planNames, filteredPlans } = getCommaSeparatedPlanNames(
    isShowSubmitNotification
      ? idConstants.submitted.statusId
      : idConstants.drafted.statusId
  );
  const notificationHeader = isShowSubmitNotification
    ? i18next.t('DYNAMIC_FORM.PRICING_PLAN.REVIEW_PENDING_FOR_NEW_PRICING_PLAN')
    : i18next.t(
        'DYNAMIC_FORM.PRICING_PLAN.ADMIN_APPROVAL_REQUIRED_FOR_NEW_PRICING_PLAN'
      );

  const notificationDescription = isShowSubmitNotification
    ? i18next.t(
        filteredPlans.length === 1
          ? 'DYNAMIC_FORM.PRICING_PLAN.RECENT_CHANGES_IS_WAITING_FOR_APPROVAL'
          : 'DYNAMIC_FORM.PRICING_PLAN.RECENT_CHANGES_ARE_WAITING_FOR_APPROVAL',
        { planNames: planNames }
      )
    : i18next.t(
        'DYNAMIC_FORM.PRICING_PLAN.RECENT_CHANGES_PRICING_WAITING_FOR_SUBMIT',
        { planNames: planNames }
      );
  const status = isShowSubmitNotification
    ? i18next.t('SHARED.AWAITING_FOR_APPROVAL')
    : i18next.t('DYNAMIC_FORM.SHARED.DRAFT');

  const clsName = isShowSubmitNotification
    ? 'home__container__status'
    : 'home__container__status-title';

  return (
    <div className='pricing-status home__container__pricing-notification'>
      <PricingNotification image={images.pricingPendingApproval}>
        <div className='pricing-status__container'>
          <p className='highlight home-wrapper__form__header'>
            {notificationHeader}
          </p>
          <CustomToolTip
            id='description'
            tooltipData={notificationDescription}
            width='dynamic'
            className='pricing-status__custom-tooltip'
          >
            <p
              className='home-wrapper__form__notification-info text-ellipsis'
              id='description'
            >
              {notificationDescription}
            </p>
          </CustomToolTip>
        </div>
        <div className='home__container__waiting-approval'>
          <p className='home__container__status highlight'>
            {i18next.t('DYNAMIC_FORM.SHARED.STATUS')}{' '}
          </p>
          {'-'}
          <p className={clsName}>{status}</p>
        </div>
      </PricingNotification>
    </div>
  );
};

export default PricingStatusNotification;
