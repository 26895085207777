import React from 'react';
import Images from '../../assets/img';
import LoginCiam from './Login/loginCiam';
import styles from './login-provider.module.scss';
import i18next from 'i18next';

const LoginProvider = (props) => {
  return (
    <div>
      <div className={styles['welcome-banner-strap']} />
      <div className={styles['welcome-banner']}>
        <div className={styles['welcome-message']}>
          <LoginCiam {...props} />
        </div>
        <div className={styles['welcome-image-grid']}>
          <div className={styles['image-container']}>
            <div>
              <img
                className={styles['welcome-image-background']}
                src={Images.welcomeImage}
                alt={i18next.t('LANDING_PAGE.WELCOME')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginProvider;
