import React from 'react';

import Input from './input';

const TextInput = ({ type, ...props }) => {
  let cName = 'a-text-field';
  if (props?.disabled === true) {
    cName += ' a-text-field--disabled';
  }
  return <Input type={type || 'text'} className={cName} {...props} />;
};

export default TextInput;
