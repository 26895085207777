import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      color: '#3f51b5'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  linearProgressStyle: {
    height: '0.5rem'
  }
}));

export default function StylledProgress() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? prevProgress : prevProgress + 10
      );
    }, 400);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={true} invisible={true}></Backdrop>
      <div className={classes.root}>
        <LinearProgress
          className={classes.linearProgressStyle}
          variant='buffer'
          value={progress}
        />
      </div>
    </React.Fragment>
  );
}
