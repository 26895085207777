import isEmpty from './isEmpty';

export function isLocalhostIP(str) {
  const localhostURL = '127.0.0.1';
  return str.includes(localhostURL) ? true : false;
}

export default function isURL(str) {
  const expression =
    /^(https?:\/\/)[a-zA-Z0-9+&@#/%?=~_\-|!:,.;]*[a-zA-Z0-9+&@#/%=~_\-|]\.[a-zA-Z0-9+&@#/%?=~_\-|!:,.;]+(\/[a-zA-Z0-9#]+\/?)*$(?:\/\S*)?$/; //(?:\/\S*)?$/ is used to check for any space
  // \.[A-Za-z]+(\/[a-zA-Z0-9#]+\/?)*$ is used to check if the url has ended with . followed by any alphabet
  const regex = new RegExp(expression);
  if (!isEmpty(str)) {
    return !str.match(regex);
  } else {
    return false;
  }
}
