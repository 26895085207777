import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Button from '../../../Shared/Inputs/Button';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextField from '../../../Shared/Inputs/TextField';
import IdConstants from '../../../../data/idConstants';
import FormHelperText from '@material-ui/core/FormHelperText';
import LightTooltip from '../../../Shared/Tooltip';
import variables from '../../../../variables.module.scss';

const useStyles = {
  root: {
    padding: '25px',
    align: 'center',
    position: 'absolute'
  },
  breadCrumbStyling: {
    margin: '0.5rem',
    fontFamily: variables.generalBoschFont,
    fontSize: '0.9rem',
    fontWeight: '400',
    textDecoration: 'none',
    color: '#005691'
  },
  headingTag: {
    fontFamily: variables.boldBoschFont,
    padding: '20px',
    fontStyle: 'bold',
    fontSize: '1.2rem'
  },
  breadcrumbGrid: {
    marginTop: '1rem'
  },
  divStyle: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    fontFamily: variables.generalBoschFont,
    minWidth: '55rem',
    maxHeight: '41rem',
    overflowY: 'auto'
  },
  inputgroupWidth: {
    width: '66%',
    float: 'left',
    marginRight: '10px'
  },
  clearFloat: {
    clear: 'both'
  },
  alignMiliSeconds: {
    marginTop: '30px'
  },
  checkBoxlabel: {
    marginTop: '30px'
  },
  userConsentlabel: {
    marginTop: '50px'
  },
  infoIconStyle: {
    top: '5px',
    position: 'relative',
    cursor: 'pointer'
  },
  helperText: {
    color: 'red',
    marginTop: 'unset'
  },
  buttonStyle: {
    cursor: 'pointer'
  },
  labelAsterisk: {
    color: 'red'
  }
};

const openDialog = true;

class SubscribeAlertsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abnormalBackendTime: false,
      abnormalBackendTimeVal: '',
      abnormalResponseTime: false,
      abnormalResponseTimeVal: '',
      userConsent: false,
      disableConfirm: true,
      disableBackendTimeTxtBox: true,
      disableResponseTimeTxtBox: true,
      abnormalBackendTimeErrTxt: false,
      abnormalResponseTimeErrTxt: false,
      apiAlertConfigIdBackendTime: '',
      apiAlertConfigIdResponseTime: '',
      editAlert: false
    };
  }

  componentDidMount() {
    if (this.props?.prodSpecificApiAlerts?.length >= 1) {
      this.setState({
        disableConfirm: false,
        userConsent: true,
        editAlert: true
      });
      this.props?.prodSpecificApiAlerts?.forEach((alert) => {
        if (alert.alertTypeId === IdConstants.alertType.abnormalResponseTime) {
          this.setState({
            abnormalResponseTime: alert.enabled,
            disableResponseTimeTxtBox: !alert.enabled,
            abnormalResponseTimeVal: alert.thresholdValue,
            apiAlertConfigIdResponseTime: alert.apiSellerAlertConfigId
          });
        }
        if (alert.alertTypeId === IdConstants.alertType.abnormalBackendTime) {
          this.setState({
            abnormalBackendTime: alert.enabled,
            disableBackendTimeTxtBox: !alert.enabled,
            abnormalBackendTimeVal: alert.thresholdValue,
            apiAlertConfigIdBackendTime: alert.apiSellerAlertConfigId
          });
        }
      });
    } else {
      this.setState({
        disableConfirm: true,
        userConsent: false,
        editAlert: false
      });
    }
  }

  handleCheckBoxChange = (event) => {
    if (event.target.name === 'abnormalBackendTime') {
      this.setState({
        abnormalBackendTime: event.target.checked
      });
      if (event.target.checked) {
        this.setState({
          disableBackendTimeTxtBox: false
        });
        if (this.state.userConsent) {
          this.setState({
            disableConfirm: false
          });
        }
      } else {
        this.setState({
          disableBackendTimeTxtBox: true
        });
        if (
          this.state.userConsent &&
          !this.state.abnormalResponseTime &&
          !this.state.editAlert
        ) {
          this.setState({
            disableConfirm: true
          });
        }
      }
    }

    if (event.target.name === 'abnormalResponseTime') {
      this.setState({
        abnormalResponseTime: event.target.checked
      });
      if (event.target.checked) {
        this.setState({
          disableResponseTimeTxtBox: false
        });
        if (this.state.userConsent) {
          this.setState({
            disableConfirm: false
          });
        }
      } else {
        this.setState({
          disableResponseTimeTxtBox: true
        });
        if (
          this.state.userConsent &&
          !this.state.abnormalBackendTime &&
          !this.state.editAlert
        ) {
          this.setState({
            disableConfirm: true
          });
        }
      }
    }

    if (event.target.name === 'userConsent') {
      this.setState({
        userConsent: event.target.checked
      });
      if (
        (event.target.checked && this.state.abnormalBackendTime) ||
        (event.target.checked && this.state.abnormalResponseTime)
      ) {
        this.setState({
          disableConfirm: false
        });
      } else {
        this.setState({
          disableConfirm: true
        });
      }
    }
  };

  saveAlertSubscriptions = () => {
    if (
      this.state.abnormalBackendTime &&
      (this.state.abnormalBackendTimeVal === '' ||
        this.state.abnormalBackendTimeVal == 0 ||
        isNaN(this.state.abnormalBackendTimeVal))
    ) {
      this.setState({
        abnormalBackendTimeErrTxt: true
      });
      return false;
    } else {
      this.setState({
        abnormalBackendTimeErrTxt: false
      });
    }

    if (
      (this.state.abnormalResponseTime &&
        this.state.abnormalResponseTimeVal === '') ||
      (this.state.abnormalResponseTime && this.state.abnormalResponseTimeVal == 0) ||
      (this.state.abnormalResponseTime && isNaN(this.state.abnormalResponseTimeVal))
    ) {
      this.setState({
        abnormalResponseTimeErrTxt: true
      });
      return false;
    } else {
      this.setState({
        abnormalResponseTimeErrTxt: false
      });
    }

    let alertObj = [
      {
        alertTypeId: IdConstants.alertType.abnormalBackendTime,
        enabled: this.state.abnormalBackendTime,
        thresholdValue: this.state.abnormalBackendTimeVal
          ? this.state.abnormalBackendTimeVal
          : 0,
        apiSellerAlertConfigId: this.state.apiAlertConfigIdBackendTime
      },
      {
        alertTypeId: IdConstants.alertType.abnormalResponseTime,
        enabled: this.state.abnormalResponseTime,
        thresholdValue: this.state.abnormalResponseTimeVal
          ? this.state.abnormalResponseTimeVal
          : 0,
        apiSellerAlertConfigId: this.state.apiAlertConfigIdResponseTime
      }
    ];

    if (this.props?.prodSpecificApiAlerts?.length >= 1) {
      this.props.updateProdSpecificApiAlerts(this.props.prodId, alertObj);
    } else {
      this.props.createProdSpecificApiAlerts(this.props.prodId, alertObj);
    }
    this.props.onClose();
  };

  changeValue = (event) => {
    event.target.value = Math.max(0, parseInt(event.target.value))
      .toString()
      .slice(0, 10);
    if (event.target.name === 'backendTimeVal') {
      this.setState({
        abnormalBackendTimeVal: parseInt(event.target.value)
      });
    }
    if (event.target.name === 'responseTimeVal') {
      this.setState({
        abnormalResponseTimeVal: parseInt(event.target.value)
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={openDialog}
          onClose={this.props.onClose}
          disableBackdropClick={true}
        >
          <div className={classes.divStyle}>
            <Grid container>
              <Grid item xs={11}>
                <h2 id='simple-modal-title' className={classes.dialogHeadingStyle}>
                  Subscribe to Alert Notifications
                </h2>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={this.props.onClose}>
                  <CloseIcon size='small' />
                </IconButton>
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={this.state.abnormalBackendTime}
                  onChange={(event) => this.handleCheckBoxChange(event)}
                  name='abnormalBackendTime'
                  color='primary'
                />
              }
              label='Abnormal Backend Time'
            />
            <LightTooltip
              title='Triggers an email if the API backend time is higher than the specified threshold value. This alert could be an indication of slow backend'
              aria-label='view'
              className={classes.infoIconStyle}
            >
              <InfoOutlinedIcon fontSize='small' color='primary' />
            </LightTooltip>

            <div className={classes.clearFloat}></div>
            <div className={classes.inputgroupWidth}>
              <InputLabel
                htmlFor='component-simple'
                required
                classes={{ asterisk: classes.labelAsterisk }}
              >
                Enter Threshold value for API Backend Time
              </InputLabel>
              <TextField
                placeholder='Enter Value'
                name='backendTimeVal'
                type='number'
                inputProps={{ maxLength: 10 }}
                value={this.state.abnormalBackendTimeVal}
                onChange={(event) => this.changeValue(event)}
                disabled={this.state.disableBackendTimeTxtBox}
                fullWidth
              />
              {this.state.abnormalBackendTimeErrTxt && (
                <FormHelperText className={classes.helperText}>
                  Threshold value cannot be empty or zero
                </FormHelperText>
              )}
            </div>
            <div className={classes.alignMiliSeconds}>milliseconds</div>
            <div className={classes.clearFloat}></div>
            <div className={classes.checkBoxlabel}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={this.state.abnormalResponseTime}
                    onChange={(event) => this.handleCheckBoxChange(event)}
                    name='abnormalResponseTime'
                    color='primary'
                  />
                }
                label='Abnormal Response Time'
              />
              <LightTooltip
                title='Triggers an email if the API response time is higher than the specified threshold value. This alert could be an indication of slow Gateway or slow backend'
                aria-label='view'
                className={classes.infoIconStyle}
              >
                <InfoOutlinedIcon fontSize='small' color='primary' />
              </LightTooltip>
            </div>

            <div className={classes.clearFloat}></div>
            <div className={classes.inputgroupWidth}>
              <InputLabel
                htmlFor='component-simple'
                required
                classes={{ asterisk: classes.labelAsterisk }}
              >
                Enter Threshold value for API Response Time
              </InputLabel>
              <TextField
                placeholder='Enter Value'
                type='number'
                name='responseTimeVal'
                inputProps={{ maxLength: 10 }}
                value={this.state.abnormalResponseTimeVal}
                onChange={(event) => this.changeValue(event)}
                disabled={this.state.disableResponseTimeTxtBox}
                fullWidth
              />
              {this.state.abnormalResponseTimeErrTxt && (
                <FormHelperText className={classes.helperText}>
                  Threshold value cannot be empty or zero
                </FormHelperText>
              )}
            </div>
            <div className={classes.alignMiliSeconds}>milliseconds</div>
            <div className={classes.userConsentlabel}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={this.state.userConsent}
                    onChange={(event) => this.handleCheckBoxChange(event)}
                    name='userConsent'
                    color='primary'
                    disabled={this.state.editAlert}
                  />
                }
                label='I agree that on confirmation, standard email charges will be applied for the alert notifications'
              />
            </div>
            <Grid container>
              <Grid item sm>
                <Box mt={1} display='flex' justifyContent='flex-end'>
                  <Box mr={2}>
                    <Button
                      variant='contained'
                      className={classes.buttonStyle}
                      label='Save'
                      onClick={this.saveAlertSubscriptions}
                      disabled={this.state.disableConfirm}
                    />
                  </Box>
                  <Button
                    variant='outlined'
                    className={classes.buttonStyle}
                    onClick={this.props.onClose}
                    label='Cancel'
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(SubscribeAlertsDialog);
