const footerTabs = [
  {
    name: 'Corporate information',
    index: 0,
    key: 'corporate-information'
  },
  {
    name: 'Legal notice',
    index: 1,
    key: 'legal-notice'
  },
  {
    name: 'Data protection policy',
    index: 2,
    key: 'data-protection-policy'
  },
  {
    name: 'Terms of use',
    index: 3,
    key: 'terms-of-use'
  }
];

export default footerTabs;
