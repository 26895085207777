const initialState = {
  files: [],
  filesUploadErr: null,
  videoLinkSuccess: '',
  videoLinkFailure: null,
  filesUploadErrStatus: null
};

const FileUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPLOAD_TECH_FILES':
      return Object.assign({}, state, {
        files: action.payload,
        filesUploadErr: null
      });
    case 'UPLOAD_THUMNAIL_FILES':
      return Object.assign({}, state, {
        thumbnailFiles: action.payload,
        filesUploadErr: null
      });
    case 'DELETE_TECH_FILES':
      return Object.assign({}, state, { files: action.payload });
    case 'DELETE_THUMBNAIL_FILES':
      return Object.assign({}, state, { thumbnailFiles: action.payload });
    case 'UPLOAD_FILES_ERR':
      return Object.assign({}, state, { filesUploadErr: action.payload });
    case 'UPLOAD_FILES_ERR_STATUS':
      return Object.assign({}, state, { filesUploadErrStatus: action.payload });
    case 'UPLOAD_VIDEO_LINKS':
      return Object.assign({}, state, {
        videoLinkSuccess: action.payload,
        videoLinkFailure: null
      });
    case 'UPLOAD_VIDEO_LINKS_ERR':
      return Object.assign({}, state, {
        videoLinkSuccess: '',
        videoLinkFailure: action.payload
      });
    case 'RESET_FILES':
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.files = [];
      return stateCopy;
    default:
      return state;
  }
};

export default FileUploadReducer;
