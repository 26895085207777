const capitalizeText = (name) => {
  const arr = name.split(' ');
  for (let index = 0; index < arr.length; index++) {
    arr[index] =
      arr[index].charAt(0).toUpperCase() + arr[index].slice(1).toLowerCase();
  }
  return arr.join(' ');
};

export default capitalizeText;
