const initialState = {
  pricingPlans: [],
  pricingPlan: {},
  updatePricingPlanError: '',
  deletePricingPlanError: ''
};

const ProductPricingPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRICING_PLANS':
      return Object.assign({}, state, { pricingPlans: action.payload });
    case 'CREATE_PRICING_PLAN':
      return Object.assign({}, state, { pricingPlan: action.payload });
    case 'DELETE_PRICING_PLAN':
      return Object.assign({}, state, { pricingPlan: action.payload });
    case 'DELETE_PRICING_PLAN_ERROR':
      return Object.assign({}, state, { deletePricingPlanError: action.error });
    case 'RESET_PRICING_PLAN':
      return Object.assign({}, state, { pricingPlans: [] });
    default:
      return state;
  }
};

export default ProductPricingPlanReducer;
