import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown,
  TextInput,
  MultiSelect,
  TextArea,
  CheckBox,
  Attachment,
  GroupingCard,
  NumberInput
} from '../../../../msreact-kit/packages/html-components';
import { DynamicFieldConstants } from '../../../../shared/constants';
import './index.scss';
import { sortByDisplayOrder } from '../../../../utils';

const BasicPlan = ({
  item,
  handleFieldChange,
  handleGroupData,
  handleDeleteGroup,
  children
}) => {
  const data = { ...item };
  data.attributes = sortByDisplayOrder(data?.attributes);

  const handleGetFields = (fieldItem, callback) => {
    const updatedPlanName = { ...fieldItem };
    if (fieldItem?.name === 'planName')
      updatedPlanName.value = fieldItem?.value?.toUpperCase();

    switch (fieldItem.attributeType) {
      case 'TEXT':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <TextInput
              handleChange={(event) => callback(event, fieldItem)}
              {...updatedPlanName}
            />
          </div>
        );

      case DynamicFieldConstants.NUMBER:
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <NumberInput
              handleChange={(event) => callback(event, fieldItem)}
              {...updatedPlanName}
            />
          </div>
        );

      case 'TEXTAREA':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <TextArea
              handleChange={(event) => callback(event, fieldItem)}
              {...fieldItem}
            />
          </div>
        );

      case 'SINGLESELECT':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <Dropdown
              {...fieldItem}
              handleChange={(event) => callback(event, fieldItem)}
            />
          </div>
        );
      case 'MULTISELECT':
        return (
          <div className='home-wrapper__form__input' key={fieldItem.fieldId}>
            <MultiSelect
              {...fieldItem}
              values={fieldItem.value}
              handleChange={(event) => callback(event, fieldItem)}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div className='home-wrapper__form__document' key={fieldItem.fieldId}>
            <CheckBox label={fieldItem.name} {...fieldItem} />
          </div>
        );
      case 'FILE':
        return (
          <div className='home-wrapper__form__document' key={fieldItem.fieldId}>
            <Attachment
              inputLabel={fieldItem.displayName}
              {...fieldItem}
              handleChange={(event) => callback(event, fieldItem)}
            />
          </div>
        );

      default:
        return;
    }
  };

  return (
    <Fragment>
      <div className='basic-plan'>
        <div className='new-pricing-plan'>
          <div className='new-pricing-plan__list'>
            {data?.attributes?.map((itemField, k) =>
              handleGetFields(
                {
                  ...itemField,
                  isGrouping: true
                },
                handleFieldChange
              )
            )}
            {data?.groups && data?.groups?.length > 0 && (
              <GroupingCard
                group={data.groups}
                handleGetFieldInput={(groupingCardData) =>
                  handleGetFields(groupingCardData, handleFieldChange)
                }
                handleGroupData={handleGroupData}
                handleDeleteGroup={handleDeleteGroup}
              />
            )}
          </div>
          {children}
        </div>
      </div>
    </Fragment>
  );
};
BasicPlan.propTypes = {
  planNameDetails: PropTypes.object,
  textAreaData: PropTypes.object,
  middleTextForPlan: PropTypes.object,
  bottomInputFields: PropTypes.array
};
BasicPlan.defaultProps = {
  planNameDetails: {},
  textAreaData: {},
  middleTextForPlan: {},
  bottomInputFields: []
};
export default BasicPlan;
