import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = ({
  label,
  handleChange,
  handleToggle,
  disabled,
  isRight,
  id,
  name,
  checked
}) => {
  const handleCheckChange = (event) => {
    handleToggle && handleToggle(event.target.checked);

    const data = {
      value: event.target.checked,
      error: '',
      name: name
    };

    handleChange && handleChange(data);
  };

  return (
    <div className='a-toggle'>
      {!isRight && (
        <label className='a-toggle__label -left' htmlFor={id}>
          {label}
        </label>
      )}
      <input
        type='checkbox'
        id={id}
        name={id}
        aria-describedby={id}
        data-testid='toggle-left-label'
        onChange={handleCheckChange}
        disabled={disabled}
        checked={checked}
      />
      <label className='a-toggle__box' htmlFor={id}></label>
      {isRight && (
        <label className='a-toggle__label -right' htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

ToggleButton.defaultProps = {
  label: 'Toggle Button',
  type: '',
  id: '',
  name: '',
  disabled: false
};
