import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Images from '../../../assets/img';

import './index.scss';

const CategoryCard = ({
  changeHandler,
  handleChange,
  value,
  id,
  name,
  displayName,
  checkValue,
  description,
  radioCheck,
  cssName
}) => {
  useEffect(() => {
    if (radioCheck) {
      const data = {
        value: radioCheck.value,
        error: '',
        name: name,
        check: radioCheck.id === id ? true : false
      };
      handleChange && handleChange(data);
    }
  }, [radioCheck]);

  const handleRadioChange = (event) => {
    changeHandler && changeHandler(event.target);
  };

  const checkBoxStyle = {
    'input[type="radio"]:checked ~ .custom-radio_button__label:before': {
      content: `url(${Images.checkMarkWhite})`
    }
  };

  return (
    <div className='custom-radio_button'>
      <input
        type='radio'
        name={description}
        className={`custom-radio_button_input ${checkBoxStyle}`}
        id={id}
        value={value}
        checked={checkValue}
        onChange={(event) => handleRadioChange(event)}
      />

      <label className={`custom-radio_button__label ${cssName}`} htmlFor={id}>
        <div className={`custom-radio_button__text ${cssName + '_text'}`}>
          {displayName}
        </div>
      </label>
    </div>
  );
};

CategoryCard.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  displayName: PropTypes.string,
  cssName: PropTypes.string
};

CategoryCard.defaultProps = {
  value: '',
  displayName: '',
  id: '',
  name: '',
  cssName: ''
};

export default CategoryCard;
