import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';
import idConstants from '../../../preview/data/idConstants';

import {
  getOrdersListing,
  getOrderHistory
} from '../../../actions/api/OrdersAction';
import { orderStatus as orderStatusUtils } from '../../../shared/shared-function/helperFunctions';
import {
  ITEM_LENGTH_ORDER_HISTORY,
  ROW_DATA,
  INTERNAL_SELLER_ORDER_ROW_DATA
} from '../../../shared/constants/field-constants';
import {
  Search,
  Table,
  Tabs,
  EmptyPage,
  Filter
} from '../../../msreact-kit/packages/html-components';
import SidebarContentWrapper from '../../BaseLayout/SidebarContainer';
import Loader from '../../../components/Shared/Utils/Loader';
import Footer from '../../BaseLayout/footer';
import { pageLimit } from '../../../data/appConstants';
import useHandleLoader from '../../../msreact-kit/hooks/useHandleLoader';
import CustomToolTip from '../../Shared/custom-tooltip';

const OrderCard = ({ isHistory = false, isListing = false }) => {
  const history = useHistory();
  const [status, setStatus] = useState('NEW');
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState([]);
  const [filterId, setFilterId] = useState([]);
  const [categories, setCategories] = useState();
  const [currentPage, setCurrentPage] = useState(pageLimit.INITIAL_PAGE_COUNT);
  const [pageSize, setPageSize] = useState(ITEM_LENGTH_ORDER_HISTORY);
  const [paginationData, setPaginationData] = useState(0);
  const orderRowData = IS_MKOSINT_TENANT ? INTERNAL_SELLER_ORDER_ROW_DATA : ROW_DATA;
  const orderStatus = IS_MKOSINT_TENANT
    ? idConstants.order.statusId.subscribed.name
    : isHistory
      ? orderStatusUtils.ORDER_HISTORY
      : '';

  const dispatch = useDispatch();
  const [handleLoader] = useHandleLoader();

  const { loader, orderList } = useSelector((state) => ({
    loader: state.LoaderReducer.loader,
    orderList: isListing
      ? state.OrdersReducer.orderListing
      : state.OrdersReducer.orderHistory
  }));

  const onSearch = (value) => {
    setSearch(value);
    setCurrentPage(pageLimit.INITIAL_PAGE_COUNT);
  };

  const handleChange = (item) => {
    setStatus(item.id);
    setCurrentPage(pageLimit.INITIAL_PAGE_COUNT);
  };

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    const paginationValues = {
      totalPages: orderList?.filter?.totalPages
    };
    setPaginationData(paginationValues);
    const filterData = orderList?.filter?.categories?.map((item) => {
      return {
        label: item.displayName,
        id: item.categoryId,
        isSelected: item.selected,
        name: item.categoryId
      };
    });
    setCategories(filterData);
  }, [orderList]);

  const handleViewMore = (productOrderDetailId, dcsOrderId) => {
    history.push({
      pathname: `/dashboard/order-management/order-details/${productOrderDetailId}`,
      state: {
        isHistory,
        dcsOrderId: dcsOrderId
      }
    });
  };

  useEffect(() => {
    const formatedTableData = [];
    orderList?.content?.length > 0 &&
      orderList.content.forEach((element) => {
        const formatedData = {
          orderId: element?.dcsOrderId,
          productOrderDetailId: element?.productOrderDetailId,
          productName: element?.productDetailsDto?.displayName,
          productCategory: (
            <CustomToolTip
              id={element?.productDetailsDto?.category?.displayName}
              tooltipData={element?.productDetailsDto?.category?.displayName}
              width='dynamic'
              className='asset-type__content'
            >
              <p
                className='content_style text-ellipsis'
                id={element?.productDetailsDto?.category?.displayName}
              >
                {element?.productDetailsDto?.category?.displayName}
              </p>
            </CustomToolTip>
          ),
          buyerName: `${element?.orderCreatedBy?.userFirstName} ${element?.orderCreatedBy?.userLastName}`,
          orderedDate: moment(element?.orderDate).format('DD/MM/YYYY, hh:mm A'),
          quantity: element?.orderedQuantity,
          availableStocks: element?.productDetailsDto?.quantity?.quantity ?? 'NA',
          viewMore: (
            <a
              className='ms-pointer'
              onClick={() =>
                handleViewMore(element?.productOrderDetailId, element?.dcsOrderId)
              }
            >
              {i18next.t('ORDER_MANAGEMENT.ORDER_CARD.VIEW_DETAILS.VIEW_MORE')}
            </a>
          )
        };
        formatedTableData.push(formatedData);
      });
    setTableData(formatedTableData);
  }, [orderList]);

  useEffect(() => {
    if (isListing) {
      dispatch(getOrdersListing(search, pageSize, status, currentPage, filterId));
    } else if (isHistory) {
      dispatch(
        getOrderHistory(search, pageSize, orderStatus, currentPage, filterId)
      );
    }
  }, [status, search, pageSize, currentPage, filterId]);

  const handlePerPage = (event) => {
    setPageSize(event.value.value);
    setCurrentPage(pageLimit.INITIAL_PAGE_COUNT);
  };

  const handlePagination = (number) => {
    setCurrentPage(number);
  };

  const handleFilter = (event) => {
    if (event.value) {
      setFilterId((prev) => [...prev, event.name]);
    } else {
      filterId.splice(filterId.indexOf(event.name), 1);
      setFilterId([...filterId]);
    }
  };

  const tabName = [
    {
      label: i18next.t('ORDER_MANAGEMENT.ORDER_CARD.TAB_NAME.NEW_REQUEST'),
      labelCount: orderList?.filter?.statusCount
        ? orderList.filter.statusCount[orderStatusUtils.NEW]
        : 0,
      id: orderStatusUtils.NEW
    },
    {
      label: i18next.t('ORDER_MANAGEMENT.ORDER_CARD.TAB_NAME.ONGOING_ORDERS'),
      labelCount: orderList?.filter?.statusCount
        ? orderList.filter.statusCount[orderStatusUtils.ONGOING_ORDER]
        : 0,
      id: orderStatusUtils.ONGOING_ORDER
    }
  ];

  return (
    <SidebarContentWrapper>
      <div className='orders'>
        {loader && <Loader />}
        <div className='orders__container'>
          <div className='orders__container__item'>
            <div className='orders__container__item__titleBlock'>
              <h1 className='orders__container__item__heading'>
                {isListing
                  ? i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.MY_ORDERS')
                  : i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.ORDER_HISTORY')}
              </h1>
            </div>
            <div className='orders__container__item__search'>
              <Search placeholder='Search' onSearch={onSearch} />
              <Filter
                handleFilter={handleFilter}
                data={categories}
                cssName='scroll'
              />
            </div>
          </div>
          {isListing && (
            <div className='orders__container__tabs'>
              <Tabs handleChange={handleChange} tabName={tabName} />
            </div>
          )}
          {orderList?.content?.length > 0 && (
            <div className='orders__container__table'>
              <Table
                rowData={orderRowData}
                data={tableData}
                handlePerPage={handlePerPage}
                handlePagination={handlePagination}
                paginationData={paginationData}
                currentPage={currentPage}
              />
            </div>
          )}
          {!loader && orderList?.content?.length === 0 && (
            <div className='orders__container__empty'>
              <EmptyPage
                label=''
                disabled={false}
                showPrimaryButton={false}
                showNote={false}
                heading={i18next.t(
                  'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.NO_DATA_FOUND'
                )}
              />
            </div>
          )}
        </div>
        <Footer customClassName='d-lg-none' />
      </div>
    </SidebarContentWrapper>
  );
};

export default OrderCard;
