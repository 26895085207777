import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartNumber } from '../../../actions/api/SettingsAction';
import Images from '../../../assets/img';
import Icons from '../../html-components/stock-avail/icons/Group.svg';
import PrimaryButton from '../button/primary-button';
import TextInput from '../input/text-input';
import * as ACTION_TYPES from '../../../actions/ActionTypes';

// This component uses the style of stock availability component
const PartUpdate = ({ productDetails, openPartUpdateModal, closeModal }) => {
  const [isError, setIsError] = useState('');
  const [partId, setPartId] = useState('');
  const [isValueEdited, setIsValueEdited] = useState(true);
  const dispatch = useDispatch();
  const { sellerPartId } = useSelector((state) => ({
    sellerPartId: state.SettingsReducer.sellerPartId
  }));

  useEffect(() => {
    // Reset the data while unmounting
    return () => {
      setPartId('');
      dispatch({
        type: ACTION_TYPES.RESET_PART_NUMBER_DATA,
        payload: ''
      });
    };
  }, []);

  useEffect(() => {
    if (sellerPartId) {
      setPartId(sellerPartId);
    }
  }, [sellerPartId]);

  const handleUpdate = () => {
    const payload = {
      productId: productDetails?.productId,
      sellerPartId: partId
    };
    dispatch(updatePartNumber(payload));
    closeModal();
  };

  const handleValidation = (event) => {
    setPartId(event.value);
    setIsError(event.error);
    setIsValueEdited(false);
  };

  const isDisableBtn = () => isValueEdited || (isError && isError.length > 0);

  return (
    <div>
      {openPartUpdateModal && (
        <div className='modal' onClick={(event) => event.stopPropagation()}>
          <div onClick={() => closeModal()} className='modal__overlay' />
          <div className='modal__content'>
            <img
              className='modal__success'
              src={Icons}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ARROW')}
            />
            <img
              className='modal__cancelIcon'
              onClick={() => closeModal()}
              src={Images.closeIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.CLOSE')}
            />
            <div className='alert__message'>
              <h1 className='alert__message__title -size-l'>
                {' '}
                {i18next.t(
                  'DYNAMIC_FORM.PART_UPDATE.PLEASE_PROVIDE_PART_NUMBER'
                )}{' '}
              </h1>
              <div className='a-text-field modal__input'>
                <TextInput
                  name='part'
                  value={partId}
                  handleChange={handleValidation}
                  displayName={i18next.t('DYNAMIC_FORM.PART_UPDATE.PART_NUMBER')}
                  validation={{
                    rule: {
                      required: {
                        isRequired: true
                      },
                      length: {
                        min: 1,
                        max: 36
                      }
                    }
                  }}
                  error={isError}
                />
              </div>
              <div className='modal__message'>
                <PrimaryButton
                  className='modal__close'
                  onClick={handleUpdate}
                  label={i18next.t('DYNAMIC_FORM.LABEL.UPDATE_CHANGES')}
                  disabled={isDisableBtn()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartUpdate;
