import isEmpty from '../validator/isEmpty';
import { isValidIfscCode } from '../validator/isDataType';
import i18next from 'i18next';

export const initialBankDetailsState = {
  accountHolder: '',
  accountNumber: '',
  bankName: '',
  ifscCode: '',
  bankAddress: ''
};

export const initialBankDetailsErrorState = {
  accountHolder: '',
  accountNumber: '',
  bankName: '',
  ifscCode: '',
  bankAddress: ''
};

export const validateBankDetails = (event, bankDetailsError) => {
  let { name, value } = event.target;
  let errorDetails = { ...bankDetailsError };

  const handleIfscValidation = (value) => {
    if (!isValidIfscCode(value)) {
      return i18next.t('HOME_PAGE.BANK_DETAILS.INVALID_IFSC');
    } else return '';
  };

  switch (name) {
    case 'accountHolder':
      errorDetails[name] = isEmpty(value)
        ? i18next.t('HOME_PAGE.BANK_DETAILS.ACCOUNT_NAME_MANDATORY')
        : '';
      break;
    case 'accountNumber':
      errorDetails[name] = isEmpty(value)
        ? i18next.t('HOME_PAGE.BANK_DETAILS.ACCOUNT_NO_MANDATORY')
        : '';
      break;
    case 'bankName':
      errorDetails[name] = isEmpty(value)
        ? i18next.t('HOME_PAGE.BANK_DETAILS.BANK_NAME_MANDATORY')
        : '';
      break;
    case 'ifscCode':
      errorDetails[name] = isEmpty(value)
        ? i18next.t('HOME_PAGE.BANK_DETAILS.IFSC_CODE_MANDATORY')
        : handleIfscValidation(value);
      break;
    case 'bankAddress':
      errorDetails[name] = isEmpty(value)
        ? i18next.t('HOME_PAGE.BANK_DETAILS.BANK_ADDRESS_MANDATORY')
        : '';
      break;
  }
  return { ...errorDetails };
};
