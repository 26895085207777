import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import idConstants from '../preview/data/idConstants';
import { isValidJSONString } from '../shared/shared-function/services';

const getJsonObjectFromText = (text) => {
  if (text) {
    return isValidJSONString(text)
      ? JSON.stringify(
          convertToRaw(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(text))
            ).getCurrentContent()
          )
        )
      : JSON.stringify(convertToRaw(ContentState.createFromText(text ?? '')));
  } else {
    return '';
  }
};

const formatAPIProductDetailsResponse = (data) => {
  return {
    ...data,
    productDetailedInfo: {
      ...data.productDetailedInfo,
      lISupport: data.productDetailedInfo.l1Support,
      lIEscalation: data.productDetailedInfo.l1Escalation,
      lIIEscalation: data.productDetailedInfo.l2Escalation,
      productLongDesc: getJsonObjectFromText(
        data.productDetailedInfo.productLongDesc
      ),
      termsAndConditions: getJsonObjectFromText(
        data.productDetailedInfo.termsAndConditions
      ),
      configurations: getJsonObjectFromText(data.productDetailedInfo.configurations),
      rules: getJsonObjectFromText(data.productDetailedInfo.rules),
      prerequisites: getJsonObjectFromText(data.productDetailedInfo.prerequisites),
      history: getJsonObjectFromText(data.productDetailedInfo.history),
      processAndMethods: getJsonObjectFromText(
        data.productDetailedInfo.processAndMethods
      ),
      dataOwnerShip: getJsonObjectFromText(data.productDetailedInfo.dataOwnerShip),
      userManual: getJsonObjectFromText(data.productDetailedInfo.userManual),
      brochure: getJsonObjectFromText(data.productDetailedInfo.brochure),
      productLifecycle: getJsonObjectFromText(
        data.productDetailedInfo.productLifecycle
      ),
      contract: getJsonObjectFromText(data.productDetailedInfo.contract),
      legal: getJsonObjectFromText(data.productDetailedInfo.legal),
      sla: getJsonObjectFromText(data.productDetailedInfo.sla),
      compliances: getJsonObjectFromText(data.productDetailedInfo.compliances),
      tags: data?.productDetailedInfo?.productTags
    }
  };
};

const formatBestProductDetailsResponse = (data) => {
  return {
    prodBasicInfo: {
      productId: data?.prodBasicInfo?.productId,
      productName: data?.prodBasicInfo?.productName,
      productShortDesc: data?.prodBasicInfo?.productShortDesc,
      displayName: data?.prodBasicInfo?.displayName,
      productCategory: {
        categoryId: data?.prodBasicInfo?.productCategory?.categoryId,
        parentProductCategoryId:
          data?.prodBasicInfo?.productCategory?.parentProductCategoryId,
        productCategoryName:
          data?.prodBasicInfo?.productCategory?.productCategoryName,
        displayName: data?.prodBasicInfo?.productCategory?.displayName,
        description: data?.prodBasicInfo?.productCategory?.description
      },
      productBusinessCategoryMap: []
    },
    productDetailedInfo: {
      productId: data?.productDetailedInfo?.productId,
      docs: {
        brochure: getJsonObjectFromText(data?.productDetailedInfo?.brochure),
        contract: getJsonObjectFromText(data?.productDetailedInfo?.contract),
        legal: getJsonObjectFromText(data?.productDetailedInfo?.legal),
        productLifeCycle: getJsonObjectFromText(
          data?.productDetailedInfo?.productLifeCycle
        ),
        userManual: getJsonObjectFromText(data?.productDetailedInfo?.userManual),
        sla: getJsonObjectFromText(data?.productDetailedInfo?.sla),
        compliances: getJsonObjectFromText(data?.productDetailedInfo?.compliances)
      },
      productDetail: {
        prerequisites: getJsonObjectFromText(
          data?.productDetailedInfo?.prerequisites
        ),
        processAndMethods: getJsonObjectFromText(
          data?.productDetailedInfo?.processAndMethods
        ),
        dataOwnership: getJsonObjectFromText(
          data?.productDetailedInfo?.dataOwnerShip
        ),
        availability: {
          value: data?.productDetailedInfo?.usageAvailability
        },
        adapterLocation: data?.productDetailedInfo?.adapterLocation,
        adapterAccess: data?.productDetailedInfo?.adapterAccess,
        history: getJsonObjectFromText(data?.productDetailedInfo?.history),
        tags: data?.productDetailedInfo?.productTags
      },
      support: {
        l1Escalation: {
          name: data?.productDetailedInfo?.l1Escalation?.name,
          contactNumber: data?.productDetailedInfo?.l1Escalation?.contactNumber,
          contactUrl: data?.productDetailedInfo?.l1Escalation?.contactUrl,
          email: data?.productDetailedInfo?.l1Escalation?.email
        },
        l2Escalation: {
          name: data.productDetailedInfo?.l2Escalation?.name,
          contactNumber: data.productDetailedInfo?.l2Escalation?.contactNumber,
          contactUrl: data.productDetailedInfo?.l2Escalation?.contactUrl,
          email: data.productDetailedInfo?.l2Escalation?.email
        },
        l1Support: {
          name: data.productDetailedInfo?.l1Support?.name,
          contactNumber: data.productDetailedInfo?.l1Support?.contactNumber,
          contactUrl: data.productDetailedInfo?.l1Support?.contactUrl,
          email: data.productDetailedInfo?.l1Support?.email
        }
      },
      productLongDesc: getJsonObjectFromText(
        data?.productDetailedInfo?.productLongDesc
      ),
      termsAndConditions: getJsonObjectFromText(
        data?.productDetailedInfo?.termsAndConditions
      )
    }
  };
};

export const productDetailsParser = (data, category) => {
  if (category.productCategoryName === idConstants.api.productCategoryName)
    return formatAPIProductDetailsResponse(data);
  else return formatBestProductDetailsResponse(data);
};
