import React from 'react';
import i18next from 'i18next';
import './index.scss';

const FormEditWrapper = ({ keyVal, isFormEdit, children }) => {
  if (!isFormEdit) {
    return children;
  }

  return (
    <div className='form-editor' key={keyVal}>
      <div className='form-editor__children'>{children}</div>
      <div className='form-editor__edit-btn'>
        {i18next.t('DYNAMIC_FORM.FORM_EDIT_WRAPPER.EDIT')}
      </div>
    </div>
  );
};

export default FormEditWrapper;
