import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MyAPI from '../../components/api/my-api';
import loading from '../../actions/LoaderAction';
import {
  updateInfo,
  publishApi,
  resetPublishError,
  uploadSwaggerFile,
  resetProduct,
  getEditableAttributes,
  getEditableDetails,
  resetEditableDetails,
  deleteProduct,
  resetPublishInfo
} from '../../actions/api/SolutionAction';
import {
  getProductsByStatus,
  getProductsCount,
  resetProducts,
  resetProductsLoadedFlag
} from '../../actions/MyProductsAction';
import { getThrottlingPolicies } from '../../actions/api/ApiThrottlingPolicyAction';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import { handleShowMessage } from '../../actions/api/pricing-plan-action';

const MySolutionContainer = (props) => {
  const [handleLoader] = useHandleLoader();

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <MyAPI {...props} />;
};

const mapStateToProps = (redux_state) => ({
  allProducts: redux_state.SolutionReducer.allProducts,
  allProductsCallLoaded: redux_state.SolutionReducer.allProductsCallLoaded,
  filteredProduct: redux_state.SolutionReducer.filteredProduct,
  loader: redux_state.LoaderReducer,
  publishData: redux_state.SolutionReducer.publishData,
  publishErrorInfo: redux_state.SolutionReducer.publishErrorInfo,
  selectedIndex: redux_state.SolutionReducer.selectedIndex,
  errorMsg: redux_state.SolutionReducer.errorMsg,
  error: redux_state.ErrorReducer.error,
  swaggerFilesErr: redux_state.SolutionReducer.swaggerFilesErr,
  info: redux_state.SolutionReducer.info,
  editableAttributes: redux_state.SolutionReducer.editableAttributes,
  editableDetails: redux_state.SolutionReducer.editableDetails,
  productsCount: redux_state.SolutionReducer.productsCount,
  responseHeaders: redux_state.SolutionReducer.responseHeaders,
  allProductsCount: redux_state.SolutionReducer.allProductsCount,
  deleteProdData: redux_state.SolutionReducer.deleteProdData,
  deleteProdError: redux_state.SolutionReducer.deleteProdError,
  deleteProdConsumed: redux_state.SolutionReducer.deleteProdConsumed,
  noRole: redux_state.loginReducer.noRole,
  isShowSuccessMsg: redux_state.PricingPlanReducer.isShowSuccessMsg
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loading,
      publishApi,
      resetPublishError,
      uploadSwaggerFile,
      updateInfo,
      resetProduct,
      getEditableAttributes,
      getEditableDetails,
      resetEditableDetails,
      getProductsByStatus,
      getProductsCount,
      resetProducts,
      deleteProduct,
      getThrottlingPolicies,
      resetPublishInfo,
      resetProductsLoadedFlag,
      handleShowMessage
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MySolutionContainer);
