import {
  SAVE_FORM_BASIC_INFO,
  SAVE_FORM_JSON_DATA,
  SAVE_FORM_ADDITIONAL_INFO,
  SAVE_HARDWARE_PRODUCTS,
  SAVE_ALL_HARDWARE_PRODUCTS,
  SAVE_TERMS_AND_CONDITION_FILE,
  SAVE_DPN_DOCUMENT,
  SAVE_FORM_JSON_DATA_ONLY_VALUE
} from '../../actions/ActionTypes';

const initialState = {
  formJsonData: [],
  prodBasicInfo: {},
  prodNameError: {},
  prodDetailedInfo: {},
  hardwareProducts: [],
  allHardwareProducts: [],
  termsAndConditionFile: null,
  dpnDocument: null,
  formJsonDataOnlyValue: null
};

const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FORM_JSON_DATA:
      return Object.assign({}, state, { formJsonData: action.payload });
    case SAVE_FORM_JSON_DATA_ONLY_VALUE:
      return Object.assign({}, state, { formJsonDataOnlyValue: action.payload });
    case SAVE_FORM_BASIC_INFO:
      return Object.assign({}, state, { prodBasicInfo: action.payload });
    case SAVE_FORM_ADDITIONAL_INFO:
      return Object.assign({}, state, { prodDetailedInfo: action.payload });
    case SAVE_HARDWARE_PRODUCTS:
      return Object.assign({}, state, { hardwareProducts: action.payload });
    case SAVE_ALL_HARDWARE_PRODUCTS:
      return Object.assign({}, state, { allHardwareProducts: action.payload });
    case SAVE_TERMS_AND_CONDITION_FILE:
      return { ...state, termsAndConditionFile: action.payload };
    case SAVE_DPN_DOCUMENT:
      return { ...state, dpnDocument: action.payload };
    default:
      return state;
  }
};

export default FormReducer;
