import React, { useState } from 'react';
import './index.scss';

const ScrollToTop = (props) => {
  if (props.fromAPI) {
    setTimeout(() => {
      if (document.getElementById('infinite-scroll')) {
        document.getElementById('infinite-scroll').onscroll = function () {
          if (document.getElementById('infinite-scroll')?.scrollTop > 400) {
            setShowScroll(true);
          } else {
            setShowScroll(false);
          }
        };
      }
    }, 0);
  } else {
    if (document.getElementById('main-content')) {
      document.getElementById('main-content').onscroll = function () {
        if (document.getElementById('main-content').scrollTop > 400) {
          setShowScroll(true);
        } else {
          setShowScroll(false);
        }
      };
    }
  }

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
    if (props.fromAPI === true) {
      document.getElementById('infinite-scroll').scrollTop = 0;
    } else {
      document.getElementById('main-content').scrollTop = 0;
    }
  };

  window.addEventListener('scroll', checkScrollTop);

  return (
    <React.Fragment>
      {showScroll && (
        <div title='Go to Top' className='scroll-alignment'>
          <i
            className='boschicon-bosch-ic-arrow-up scroll-to-top -size-2xl'
            onClick={scrollTop}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ScrollToTop;
