const CDN_LINK = `${process.env.REACT_APP_BUYER_CDN_LINK}/assets/img`;

const images = {
  filterIcon: `${CDN_LINK}/filter-icon.svg`,
  checkboxChecked: `${CDN_LINK}/cb-checked.svg`,
  checkboxUnchecked: `${CDN_LINK}/cb-unchecked.svg`,
  listingPageBanner: `${CDN_LINK}/listing-page-banner-animation.svg`,
  boschLogo: `${CDN_LINK}/bosch-logo-only.svg`,
  mcpContactUs: `${CDN_LINK}/getintouch.png`,
  startPlayFrame: `${CDN_LINK}/start-play-frame.svg`,
  closeIconNav: `${CDN_LINK}/close.svg`,
  routeMaticThumbNail: `${CDN_LINK}/routematic-thumbnail.png`,
  successStoryCardImage: `${CDN_LINK}/success-story-card-image.jpg`,
  mcpLogoNavIcon: `${CDN_LINK}/mcp-nav-icon.png`,
  mcpGridViewMobicon: `${CDN_LINK}/grid-view.svg`,
  boschStrip: `${CDN_LINK}/topbar.png`,
  boschStripSmall: `${CDN_LINK}/supergraphic-topbar.jpg`,
  boschIcons: {
    peopleIcon: `${CDN_LINK}/people.svg`,
    appIcon: `${CDN_LINK}/directory-cloud.svg`
  },
  companyLogos: {
    awsLogo: `${CDN_LINK}/ourEcoSystemImages/aws.png`,
    azureLogo: `${CDN_LINK}/ourEcoSystemImages/azure.png`,
    chargeZoneLogo: `${CDN_LINK}/ourEcoSystemImages/chargezone.svg`,
    kazamLogo: `${CDN_LINK}/ourEcoSystemImages/kazam.jpg`,
    magentaLogo: `${CDN_LINK}/ourEcoSystemImages/magenta.svg`,
    mongoDbLogo: `${CDN_LINK}/ourEcoSystemImages/mongodb.png`,
    routematicLogo: `${CDN_LINK}/ourEcoSystemImages/routematic.png`,
    zeliotLogo: `${CDN_LINK}/ourEcoSystemImages/zeliot.png`,
    ampereLogo: `${CDN_LINK}/ourEcoSystemImages/ampere.png`,
    carxLogo: `${CDN_LINK}/ourEcoSystemImages/carx.png`,
    expedentLogo: `${CDN_LINK}/ourEcoSystemImages/expedent.png`,
    setuLogo: `${CDN_LINK}/ourEcoSystemImages/setu.png`
  },
  sortByIcon: `${CDN_LINK}/down.svg`,
  recentlyAddedIcon: `${CDN_LINK}/recently-added.svg`,
  ascIcon: `${CDN_LINK}/ascending.svg`,
  descIcon: `${CDN_LINK}/decending.svg`,
  generalFaq: `${CDN_LINK}/general-faq.svg`,
  resetIcon: `${CDN_LINK}/reset-icon.svg`,
  sortIcon: `${CDN_LINK}/sort-icon.svg`,
  checkIcon: `${CDN_LINK}/check.svg`,
  faqBanner: `${CDN_LINK}/faq-banner.png`,
  sellerIcon: `${CDN_LINK}/seller-faq.svg`,
  buyerIcon: `${CDN_LINK}/buyer-faq.svg`,
  thumbnail: `${CDN_LINK}/thumbnail.svg`,
  backgroundimg: `${CDN_LINK}/business.jpg`,
  whiteCashFrameRupee: `${CDN_LINK}/mcp/white-cash-frame-rupee.svg`,
  whiteLockOpen: `${CDN_LINK}/mcp/white-lock-open.svg`,
  whiteCubes: `${CDN_LINK}/mcp/white-cubes.svg`,
  whiteShareFrame: `${CDN_LINK}/mcp/white-share-frame.svg`,
  whitePlugPlay: `${CDN_LINK}/mcp/white-plug-play.svg`,
  whiteCoins: `${CDN_LINK}/mcp/white-coins.svg`,
  whiteComponents: `${CDN_LINK}/mcp/white-components-available.svg`,
  whiteEfficiency: `${CDN_LINK}/mcp/white-efficiency.svg`,
  whiteClock: `${CDN_LINK}/mcp/white-clock-24-7.svg`,
  whiteCallTeam: `${CDN_LINK}/mcp/white-call-team.svg`,
  whiteDocument: `${CDN_LINK}/mcp/white-document-edit.svg`,
  blueAzure: `${CDN_LINK}/mcp/blue-infrastructure-services-azure.svg`,
  blueDevice: `${CDN_LINK}/mcp/blue-device-management.svg`,
  blueCTDManagement: `${CDN_LINK}/mcp/blue-ctd-management.svg`,
  mcpStoreSectionLg: `${CDN_LINK}/mcp-store.png`,
  mcpStoreSectionSm: `${CDN_LINK}/mcp-store-sm.png`,
  mcpBackgroundOverview: `${CDN_LINK}/mcp/mcp-overview-bg.jpg`,
  mcpFrontBannerImg: `${CDN_LINK}/mcp/mcp-banner.png`,
  chargeZoneLogo: `${CDN_LINK}/mcp/charge-zone-logo.svg`,
  trackzeusLogo: `${CDN_LINK}/mcp/trakzeus-logo.svg`,
  carezeusLogo: `${CDN_LINK}/mcp/carezeus-logo.svg`,
  carIconImg: `${CDN_LINK}/car-connected.png`,
  forwardIcon: `${CDN_LINK}/mcp/forward-right.svg`,
  backIcon: `${CDN_LINK}/mcp/back-left.svg`,
  cloudIconImg: `${CDN_LINK}/cloud-share.png`,
  azureInfraServices: `${CDN_LINK}/mcp/azure-infra-services.png`,
  awsInfraServices: `${CDN_LINK}/mcp/aws-infra-services.png`,
  mongoSharedService: `${CDN_LINK}/mcp/mongo-shared-service.png`,
  cloudManagementBasic: `${CDN_LINK}/mcp/cloud-management-basic.png`,
  extendedWorkbench: `${CDN_LINK}/mcp/extended-workbench.png`,
  deviceManagement: `${CDN_LINK}/mcp/device-management.png`,
  certificateManagement: `${CDN_LINK}/mcp/certificate-management.png`,
  fotaManagement: `${CDN_LINK}/mcp/fota-management.png`,
  userManagement: `${CDN_LINK}/mcp/user-management.png`,
  fewClicksAwayGif: `${CDN_LINK}/few-clicks-away.gif`,
  notificationManagementProductDropdown: `${CDN_LINK}/mcp/notification-management-product-dropdown.png`,
  mapManagementProductDropdown: `${CDN_LINK}/mcp/map-management-product-dropdown.png`,
  cloudToDeviceManagementProductDropdown: `${CDN_LINK}/mcp/cloud-to-device-management-product-dropdown.png`,
  ecosystemGenericDeviceConnectedImg: `${CDN_LINK}/mcp/ecosystem-generic-device-connected.svg`,
  ecosystemBinaryImg: `${CDN_LINK}/mcp/ecosystem-binary.svg`,
  playButton: `${CDN_LINK}/playbackimg.png`,
  videoPlayIcon: `${CDN_LINK}/video-play-icon.svg`,
  certificate1: `${CDN_LINK}/mcp/certificate-management-1.png`,
  certificate2: `${CDN_LINK}/mcp/certificate-management-2.png`,
  certificate3: `${CDN_LINK}/mcp/certificate-management-3.png`,
  certificate4: `${CDN_LINK}/mcp/certificate-management-4.png`,
  cloudtoDeviceManagement: `${CDN_LINK}/mcp/cloud-to-device-management.png`,
  deviceManagement1: `${CDN_LINK}/mcp/device-management-1.png`,
  deviceManagement2: `${CDN_LINK}/mcp/device-management-2.png`,
  deviceManagement3: `${CDN_LINK}/mcp/device-management-3.png`,
  deviceManagement4: `${CDN_LINK}/mcp/device-management-4.png`,
  fotaManagement1: `${CDN_LINK}/mcp/fota-management-1.png`,
  fotaManagement2: `${CDN_LINK}/mcp/fota-management-2.png`,
  fotaManagement3: `${CDN_LINK}/mcp/fota-management-3.png`,
  fotaManagement4: `${CDN_LINK}/mcp/fota-management-4.png`,
  fotaManagement5: `${CDN_LINK}/mcp/fota-management-5.png`,
  mapManagement: `${CDN_LINK}/mcp/map-management.png`,
  notificationManagement: `${CDN_LINK}/mcp/notification-management.png`,
  userManagement1: `${CDN_LINK}/mcp/user-management-1.png`,
  userManagement2: `${CDN_LINK}/mcp/user-management-2.png`,
  userManagement3: `${CDN_LINK}/mcp/user-management-3.png`,
  blueCallTeam: `${CDN_LINK}/mcp/blue-call-team.svg`,
  blueCashFrameRupee: `${CDN_LINK}/mcp/blue-cash-frame-rupee.svg`,
  blueClock: `${CDN_LINK}/mcp/blue-clock-24-7.svg`,
  blueCloudDevice: `${CDN_LINK}/mcp/blue-cloud-device-service.svg`,
  blueCoins: `${CDN_LINK}/mcp/blue-coins.svg`,
  blueComponentAvailable: `${CDN_LINK}/mcp/blue-components-available.svg`,
  blueCubes: `${CDN_LINK}/mcp/blue-cubes.svg`,
  blueDocumentEdit: `${CDN_LINK}/mcp/blue-document-edit.svg`,
  blueEfficiency: `${CDN_LINK}/mcp/blue-efficiency.svg`,
  blueLockOpen: `${CDN_LINK}/mcp/blue-lock-open.svg`,
  blueLogo: `${CDN_LINK}/mcp/blue-logo.svg`,
  blueMap: `${CDN_LINK}/mcp/blue-map.svg`,
  bluePlugPlay: `${CDN_LINK}/mcp/blue-plug-play.svg`,
  blueShareFrame: `${CDN_LINK}/mcp/blue-share-frame.svg`,
  blueStack: `${CDN_LINK}/mcp/blue-technical-consulting-tech-stack.svg`,
  whiteCertificate: `${CDN_LINK}/mcp/white-certificate-management.svg`,
  whiteCloud: `${CDN_LINK}/mcp/white-cloud-device-service.svg`,
  whiteDevice: `${CDN_LINK}/mcp/white-device-management.svg`,
  whiteFOTA: `${CDN_LINK}/mcp/white-fota-management.svg`,
  whiteAWS: `${CDN_LINK}/mcp/white-infrastructure-services-aws.svg`,
  whiteAzure: `${CDN_LINK}/mcp/white-infrastructure-services-azure.svg`,
  whiteLogo: `${CDN_LINK}/mcp/white-logo.svg`,
  whiteSupport: `${CDN_LINK}/mcp/white-maintenance-support.svg`,
  whiteMap: `${CDN_LINK}/mcp/white-map.svg`,
  whiteMongo: `${CDN_LINK}/mcp/white-mongo.svg`,
  whiteNotification: `${CDN_LINK}/mcp/white-notification.svg`,
  whiteTechStack: `${CDN_LINK}/mcp/white-technical-consulting-tech-stack.svg`,
  whiteUser: `${CDN_LINK}/mcp/white-user-management.svg`,
  blueStar: `${CDN_LINK}/mcp/blue-full-star-wishlist.svg`,
  blueStarEmpty: `${CDN_LINK}/mcp/blue-star-wishlist.svg`,
  blueAWS: `${CDN_LINK}/mcp/blue-infrastructure-services-aws.svg`,
  blueFOTA: `${CDN_LINK}/mcp/blue-foto-management.svg`,
  blueUser: `${CDN_LINK}/mcp/blue-user.svg`,
  blueSupport: `${CDN_LINK}/mcp/blue-maintenance-support.svg`,
  mcpRequestInfobg: `${CDN_LINK}/mcp/request-info-bg.jpg`,
  mcpRequestInfoLady: `${CDN_LINK}/mcp/mcp-mascot-lady.svg`,
  mcpBackgroundImg: `${CDN_LINK}/mcp/mcp-background.png`,
  mcpVideoBannerImg: `${CDN_LINK}/mcp/mcp-video-img.png`,
  productDocumentCSVBlue: `${CDN_LINK}/product-document-csv-blue.png`,
  routematicLogo: `${CDN_LINK}/mcp/routematic-logo.svg`,
  zeliotLogo: `${CDN_LINK}/mcp/zeliot-logo.svg`,
  parkzeusLogo: `${CDN_LINK}/mcp/parkzeus-logo.svg`,
  carxLogo: `${CDN_LINK}/mcp/carx.svg`,
  parksmartLogo: `${CDN_LINK}/mcp/parksmart-logo.svg`,
  faqFloatingButton: `${CDN_LINK}/mcp/faq-button.png`,
  ecosystemWelcomeImg: `${CDN_LINK}/mcp/ecosystem-welcome.svg`,
  blueCertificateManagement: `${CDN_LINK}/mcp/blue-certificate-management.svg`,
  blueMongo: `${CDN_LINK}/mcp/blue-mongo.svg`,
  blueNotification: `${CDN_LINK}/mcp/blue-notification.svg`,
  categories: {
    software: `${CDN_LINK}/mobility-solutions.jpg`,
    hardware: `${CDN_LINK}/industry-and-trade.jpg`,
    parentCategory: `${CDN_LINK}/icon-bg-home.png`,
    api: `${CDN_LINK}/nav-api.svg`,
    sdk: `${CDN_LINK}/nav-sdk.svg`,
    filter: `${CDN_LINK}/filter.svg`,
    childCategory: `${CDN_LINK}/nav-api.svg`,
    library: `${CDN_LINK}/nav-library.svg`,
    mobileApp: `${CDN_LINK}/nav-mobileapp.svg`,
    ddl: `${CDN_LINK}/nav-ddl.svg`,
    framework: `${CDN_LINK}/nav-framework.svg`,
    sensors: `${CDN_LINK}/nav-sensors.svg`,
    gateway: `${CDN_LINK}/nav-gateway.svg`,
    devices: `${CDN_LINK}/nav-devices.svg`
  },
  api: {
    logo: `${CDN_LINK}/api-logo.jpg`,
    apibackgroundimg: `${CDN_LINK}/coverimage.jpg`,
    backgroundimg: `${CDN_LINK}/business.jpg`,
    thumbnail: `${CDN_LINK}/api-thumbnail.png`
  },
  cloudCircleIcon: `${CDN_LINK}/mcp-cloud-circle.svg`,
  rupeeIcon: `${CDN_LINK}/rupee-icon.svg`,
  genericPricingIcon: `${CDN_LINK}/generic-pricing.svg`,
  data: {
    backgroundImage: `${CDN_LINK}/data-background-image.jpg`,
    thumbnail: `${CDN_LINK}/data-thumbnail.png`
  },
  analyticsTrackingImg: `${CDN_LINK}/analytics-tracking.png`,
  applicationsImg: `${CDN_LINK}/applications.png`,
  myAccountImg: `${CDN_LINK}/my-account.png`,
  purchasedProductsImg: `${CDN_LINK}/purchased-products.png`,
  sellerAccountImg: `${CDN_LINK}/seller-account.png`,
  loginBackground: `${CDN_LINK}/login-background.jpg`,
  bgGreyImg: `${CDN_LINK}/bg-grey.svg`,
  analyticsImg: `${CDN_LINK}/analytics.png`,
  applicationsBgImg: `${CDN_LINK}/applications-bg.png`,
  sellerImg: `${CDN_LINK}/seller.png`,
  purchasedProductsBgImg: `${CDN_LINK}/products.png`,
  securityImage: `${CDN_LINK}/security-img.png`,
  genericAppImg: `${CDN_LINK}/generic-app-thumbnail.png`,
  applicationBackgroungImg: `${CDN_LINK}/bg-app-img.jpg`,
  desktop: `${CDN_LINK}/desktop.png`,
  sellerStepper: `${CDN_LINK}/seller-stepper.png`,
  buyerStepper: `${CDN_LINK}/buyer-stepper.png`,
  contactSeller: `${CDN_LINK}/contact-seller.jpg`,
  customPlanImg: `${CDN_LINK}/custom-plan.png`,
  mobilityTitleLogo: `${CDN_LINK}/mobility-title-logo.jpg`,
  registerBackgroung: `${CDN_LINK}/register-background.jpg`,
  contactSellerModalLogo: `${CDN_LINK}/contact-seller-modal.jpg`,
  pdfBoschLogo: `${CDN_LINK}/emailTemplate/logo.png`,
  rocketImage: `${CDN_LINK}/rocket-img.png`,
  deleteUser: `${CDN_LINK}/request-for-deletion.svg`,
  contactUs: `${CDN_LINK}/contactus-mail.png`,
  userManual: `${CDN_LINK}/user-manual.svg`,
  video: `${CDN_LINK}/video-record.png`,
  tryOutBgImg: `${CDN_LINK}/revamped-tryout-bg.svg`,
  orderConfirmed: `${CDN_LINK}/greentick.png`,
  orderPending: `${CDN_LINK}/pending-yellow.png`,
  orderError: `${CDN_LINK}/redcross.png`,
  orderErrorIllustration: `${CDN_LINK}/failed.png`,
  orderConfirmedIllustration: `${CDN_LINK}/success.png`,
  viewProfile: `${CDN_LINK}/viewprofile.png`,
  browseIcon: `${CDN_LINK}/browse-icon.png`,
  dcsApiError: `${CDN_LINK}/dcs-error.png`,
  deletedApiError: `${CDN_LINK}/deleted-api-error-img.svg`,
  noRecordsFound: `${CDN_LINK}/no-records-found.png`,
  feature: {
    thumbnail: `${CDN_LINK}/feature-logo.jpg`,
    backgroundImage: `${CDN_LINK}/business.jpg`
  },
  services: {
    enterpriseLicensing: {
      thumbnail: `${CDN_LINK}/saas-logo.png`,
      backgroundImage: `${CDN_LINK}/saas-background-image.jpg`
    },
    managedServices: {
      thumbnail: `${CDN_LINK}/peripherals-logo.png`,
      backgroundImage: `${CDN_LINK}/peripherals-background-image.jpg`
    },
    infrastructure: {
      thumbnail: `${CDN_LINK}/iaas-logo.png`,
      backgroundImage: `${CDN_LINK}/iaas-background-image.jpg`
    },
    softwareServices: {
      thumbnail: `${CDN_LINK}/software-services-default.svg`
    },
    professionalServices: {
      thumbnail: `${CDN_LINK}/professional-services-default.svg`
    },
    communicationServices: {
      thumbnail: `${CDN_LINK}/communication-services-default.svg`
    }
  },
  solutions: {
    thumbnail: `${CDN_LINK}/solutions-logo.png`,
    backgroundImage: `${CDN_LINK}/solutions-background-image.jpg`,
    defaultHighlightImage: `${CDN_LINK}/default-highlight-image.svg`
  },
  pending: `${CDN_LINK}/pending.png`,
  rejected: `${CDN_LINK}/rejected.png`,
  consumers: `${CDN_LINK}/consumer.svg`,
  enterprises: `${CDN_LINK}/enterprises.svg`,
  providers: `${CDN_LINK}/providers.svg`,
  productCategory: {
    ddl: `${CDN_LINK}/ddl.svg`,
    devices: `${CDN_LINK}/devices.svg`,
    framework: `${CDN_LINK}/framework.svg`,
    gateway: `${CDN_LINK}/gateway.svg`,
    library: `${CDN_LINK}/library.svg`,
    mobileapp: `${CDN_LINK}/mobileapp.svg`,
    productCategoryApi: `${CDN_LINK}/product-category-api.svg`,
    productCategorySdk: `${CDN_LINK}/product-category-sdk.svg`,
    sensors: `${CDN_LINK}/sensors.svg`,
    apiCategory: `${CDN_LINK}/api-thumbnail.png`,
    featureCategory: `${CDN_LINK}/feature-icon.svg`,
    hardwareCategory: `${CDN_LINK}/hardware.svg`,
    softwareCategory: `${CDN_LINK}/saas.svg`,
    cloudInfraCategory: `${CDN_LINK}/cloud-infra.svg`,
    dataLakeCategory: `${CDN_LINK}/data-lake.svg`,
    dataCategory: `${CDN_LINK}/data-thumbnail.png`,
    solutionCategory: `${CDN_LINK}/solutions-logo.png`,
    mobilitySoftwareCategory: `${CDN_LINK}/saas.png`,
    peripheralsCategory: `${CDN_LINK}/peripherals.png`,
    cloudInfrastructureCategory: `${CDN_LINK}/cloud.png`
  },
  onestop: `${CDN_LINK}/onestop.svg`,
  participate: `${CDN_LINK}/participate.svg`,
  boschVerified: `${CDN_LINK}/bosch-verified.svg`,
  infoIcon: `${CDN_LINK}/info.svg`,
  sellersectionHomepage: `${CDN_LINK}/sellers-section-homepage.png`,
  sellersectionHomepageMobile: `${CDN_LINK}/sellers-section-homepage-mobile.png`,
  easyonboardingIcon: `${CDN_LINK}/easy-onboarding.svg`,
  rupeecircleIcon: `${CDN_LINK}/rupee-symbol-circle.svg`,
  graphGrowingIcon: `${CDN_LINK}/graph.svg`,
  closeIcon: `${CDN_LINK}/close-small.svg`,
  supportBannerImage: `${CDN_LINK}/support-banner-image.svg`,
  getInTouchBannerImage: `${CDN_LINK}/contactus.svg`,
  apiCard: `${CDN_LINK}/api-card.jpg`,
  dataCard: `${CDN_LINK}/data-card.jpg`,
  hardwareCard: `${CDN_LINK}/hardware-card.jpg`,
  mcpCard: `${CDN_LINK}/mcp-card.png`,
  mcpLogoUpdated: `${CDN_LINK}/mcp-logo-updated.png`,
  solutionsCard: `${CDN_LINK}/solutions-card.jpg`,
  servicesCard: `${CDN_LINK}/services-card.jpg`,
  supportsectionHomepage: `${CDN_LINK}/support-section-homepage.svg`,
  supportsectionRevampHomepage: `${CDN_LINK}/support-image.svg`,
  mailSupportIcon: `${CDN_LINK}/mail-support.svg`,
  userOnboardingManual: `${CDN_LINK}/user-onboarding-manual.svg`,
  userManualIcon: `${CDN_LINK}/document.svg`,
  faq: `${CDN_LINK}/faq-support.svg`,
  documentSupportIcon: `${CDN_LINK}/document-support.svg`,
  videoSupportIcon: `${CDN_LINK}/video-support.svg`,
  secureIcon: `${CDN_LINK}/secure-icon.svg`,
  curatedIcon: `${CDN_LINK}/curated-icon.svg`,
  keypadIcon: `${CDN_LINK}/keypad-icon.svg`,
  reducedIcon: `${CDN_LINK}/reduced-icon.svg`,
  intentsMobiThumbnail: `${CDN_LINK}/intents-mobi.png`,
  zeliotThumbnail: `${CDN_LINK}/zeliot.png`,
  decentroThubmail: `${CDN_LINK}/decentro.png`,
  guyWithIpad: `${CDN_LINK}/guy-with-ipad.svg`,
  guyWithIpadMobileView: `${CDN_LINK}/guy-with-ipad-mobile-view.svg`,
  girlWithIpad: `${CDN_LINK}/girl-with-ipad.svg`,
  searchBanner: `${CDN_LINK}/search-banner.svg`,
  aboutUsImage: `${CDN_LINK}/aboutus-header.jpg`,
  aboutUsColImageStory: `${CDN_LINK}/aboutus-col-img-story.png`,
  aboutUsColImageMission: `${CDN_LINK}/aboutus-col-img-mission.png`,
  desktopIconImg: `${CDN_LINK}/desktop-connectivity.png`,
  unsubscribeModalImg: `${CDN_LINK}/unsubscribe-modal.svg`,
  campaignThankyouImg: `${CDN_LINK}/thank-you-handshake.gif`,
  contactSalesCardImg: `${CDN_LINK}/male-working-on-computer.svg`,
  loaderImage: `${CDN_LINK}/loader.gif`,
  checkmarkImg: `${CDN_LINK}/checkmark.svg`,
  useCasesImage1: `${CDN_LINK}/use-cases-image-1.svg`,
  useCasesImage2: `${CDN_LINK}/use-cases-image-2.svg`,
  useCasesImage3: `${CDN_LINK}/use-cases-image-3.svg`,
  useCasesImage4: `${CDN_LINK}/use-cases-image-4.svg`,
  useCasesImage5: `${CDN_LINK}/use-cases-image-5.svg`,
  magnifyBrand: `${CDN_LINK}/magnify-your-brand.gif`,
  unlockBusiness: `${CDN_LINK}/unlock-business.gif`,
  buildToScale: `${CDN_LINK}/build-to-scale.gif`,
  storeImage: `${CDN_LINK}/store-use-cases.gif`,
  userThumbsUp: `${CDN_LINK}/user-thumb-up.gif`,
  windowSearch: `${CDN_LINK}/window-search.gif`,
  whatWeOffer: {
    sdkIcon: `${CDN_LINK}/sdk-icon.svg`,
    hardwareIcon: `${CDN_LINK}/hardware-icon.svg`,
    cloudIcon: `${CDN_LINK}/cloud-icon.svg`,
    dataIcon: `${CDN_LINK}/data-icon.svg`,
    solutionsIcon: `${CDN_LINK}/solutions-icon.svg`,
    apiIcon: `${CDN_LINK}/api-icon.svg`,
    servicesIcon: `${CDN_LINK}/services-icon.svg`,
    cloudInfra: `${CDN_LINK}/cloud-services-infrastructure.svg`,
    enterpriseLicensing: `${CDN_LINK}/cloud-services-enterprise-licensing.svg`,
    cloudManagedServices: `${CDN_LINK}/cloud-services-managed-services.svg`,
    softwareServices: `${CDN_LINK}/software.svg`,
    professionalServices: `${CDN_LINK}/professional.svg`,
    communicationServices: `${CDN_LINK}/communication.svg`
  },
  youMayAlsoLike: {
    apiIcon: `${CDN_LINK}/api-circle-icon.svg`,
    dataIcon: `${CDN_LINK}/data-circle-icon.svg`,
    solutionsIcon: `${CDN_LINK}/solutions-circle-icon.svg`
  },
  caseStudyForeGroundImg1: `${CDN_LINK}/case-study-1.png`,
  caseStudyForeGroundImg2: `${CDN_LINK}/case-study-2.png`,
  caseStudyForeGroundImg3: `${CDN_LINK}/case-study-3.png`,
  caseStudyLogoImg1: `${CDN_LINK}/logo-1.jpg`,
  caseStudyLogoImg2: `${CDN_LINK}/logo-2.png`,
  caseStudyLogoImg3: `${CDN_LINK}/logo-3.png`,
  computer: `${CDN_LINK}/computer.svg`,
  animatedComputer: `${CDN_LINK}/animated-computer.svg`,
  blogs: `${CDN_LINK}/blog.svg`,
  vidoesResources: `${CDN_LINK}/vidoes-resources.svg`,
  productGuide: `${CDN_LINK}/product-guide.svg`,
  productVideo: `${CDN_LINK}/product-video.svg`,
  noResultsFound: `${CDN_LINK}/no-results-found.svg`,
  categoryAssets: {
    api: `${CDN_LINK}/apibanner.svg`,
    solutions: `${CDN_LINK}/solutions-banner.svg`,
    data: `${CDN_LINK}/data-banner.svg`,
    hardware: `${CDN_LINK}/hardware-banner.svg`,
    sdk: `${CDN_LINK}/sdk-banner.svg`,
    fintechLogo: `${CDN_LINK}/fintech.svg`,
    locationAndMappingLogo: `${CDN_LINK}/location-and-mapping.svg`,
    logisticsAndFleetLogo: `${CDN_LINK}/logisticsandfleet.svg`,
    digitalIndiaLogo: `${CDN_LINK}/govt-apis-digital-india.svg`,
    journeyRouteLogo: `${CDN_LINK}/journey-travel-route-planning.svg`,
    verificationLogo: `${CDN_LINK}/verification-and-validation.svg`,
    fuelChargingLogo: `${CDN_LINK}/fuel-and-charging-infra.svg`,
    iotLogo: `${CDN_LINK}/iot-ai-ml.svg`,
    parkingLogo: `${CDN_LINK}/parking.svg`,
    communicationDevicesLogo: `${CDN_LINK}/communication-devices.svg`,
    hardwareSensorsLogo: `${CDN_LINK}/hardware-sensors.svg`,
    fleetManagementLogo: `${CDN_LINK}/fleet-management.svg`,
    specialityHardwareLogo: `${CDN_LINK}/speciality-hardware.svg`,
    telematicsLogo: `${CDN_LINK}/telematics.svg`,
    vehicleAnalyticsLogo: `${CDN_LINK}/vehicle-analytics.svg`,
    communicationServicesLogo: `${CDN_LINK}/communication-services-logo.svg`,
    professionalServicesLogo: `${CDN_LINK}/professional-managed-services.svg`,
    softwareServicesLogo: `${CDN_LINK}/software-service.svg`
  },
  caseStudyPage: {
    caseStudyPageBannerImg: `${CDN_LINK}/casestudy-banner-img.svg`,
    arrowImg: `${CDN_LINK}/case-study-arrow-img.svg`,
    websiteLinkImg: `${CDN_LINK}/website-link-img.svg`,
    segmentImg: `${CDN_LINK}/segment-img.svg`,
    countryImg: `${CDN_LINK}/country-flag-img.svg`,
    industryImg: `${CDN_LINK}/industry-img.svg`,
    customerIcon: `${CDN_LINK}/user-icon-img.svg`,
    userAdvanceImg: `${CDN_LINK}/user-advanced-img.svg`,
    planChartImg: `${CDN_LINK}/plan-chart-img.svg`,
    targetDartImg: `${CDN_LINK}/target-dart-img.svg`,
    signzyImg: `${CDN_LINK}/signzy-img.svg`
  },
  hardware: {
    thumbnail: `${CDN_LINK}/hardware-thumbnail.svg`
  }
};

export default images;
