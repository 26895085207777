const initialState = {
  sellerCompanyId: ''
};

const SalesRepCompanyIdReducer = (state = initialState, action) => {
  if (action.type === 'SET_SALES_REP_COMPANY_ID') {
    return { ...state, sellerCompanyId: action.payload };
  }

  return state;
};

export default SalesRepCompanyIdReducer;
