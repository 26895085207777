const initialState = {
  paramDataTypes: {},
  paramUrlOptions: {}
};

const EndpointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PARAM_DATA_TYPES':
      return Object.assign({}, state, { paramDataTypes: action.payload });
    case 'GET_PARAM_URL_OPTIONS':
      return Object.assign({}, state, { paramUrlOptions: action.payload });
    default:
      return state;
  }
};

export default EndpointsReducer;
