import React from 'react';
import './style.css';

const selectText = 'Choose the file format';
const dropdownOptions = ['JSON', 'YAML'];

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      haveText: ''
    };
  }

  render() {
    const { isOpen, haveText } = this.state;
    let dropdownText = '';
    if (isOpen) {
      dropdownText = selectText;
    } else {
      dropdownText = haveText;
    }
    if (!haveText) {
      dropdownText = selectText;
    }
    return (
      <div
        className={isOpen ? 'dropdown active' : 'dropdown'}
        onClick={this.handleClick}
      >
        <div className='dropdown-text'>{dropdownText}</div>
        {this.itemList(dropdownOptions)}
      </div>
    );
  }

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleText = (event) => {
    this.setState(
      {
        haveText: event.currentTarget.textContent
      },
      () => this.props.updateFormat(this.state.haveText)
    );
  };

  itemList = (props) => {
    const list = props.map((item) => (
      <div onClick={this.handleText} className='dropdown-item' key={item.toString()}>
        {item}
      </div>
    ));
    return <div className='dropdown-items'> {list} </div>;
  };
}

export default Dropdown;
