import axios from 'axios';
import { GET_ORDERS } from '../../actions/configuration';

export function getOrdersListing(search, pageSize, status, currentPage, categoryId) {
  const orderStatus = status ? `orderStatus=${status}&` : '';
  const searchTerm = search ? `search=${search}&` : '';
  const pageNumber = currentPage ? `pageNumber=${currentPage}&` : '';
  const pageLimit = pageSize ? `pageSize=${pageSize}&` : '';
  const catId = categoryId?.length > 0 ? `categoryId=${categoryId}` : '';

  let url =
    GET_ORDERS + `?` + orderStatus + searchTerm + pageNumber + pageLimit + catId;

  return (dispatch) =>
    axios({
      method: 'GET',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_ORDERS_LISTING',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getOrderHistory(search, pageSize, status, currentPage, categoryId) {
  const searchTerm = search ? `search=${search}&` : '';
  const pageNumber = currentPage ? `pageNumber=${currentPage}&` : '';
  const orderStatus = status ? `orderStatus=${status}&` : '';
  const pageLimit = pageSize ? `pageSize=${pageSize}&` : '';
  const catId = categoryId?.length > 0 ? `categoryId=${categoryId}` : '';

  let url =
    GET_ORDERS + `?` + searchTerm + pageNumber + orderStatus + pageLimit + catId;

  return (dispatch) =>
    axios({
      method: 'GET',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_ORDERS_HISTORY',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
