import React from 'react';
import MaterialTable from 'material-table';
import Button from '../../../Shared/Inputs/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import {
  Alert,
  TextField,
  Box,
  Collapse,
  IconButton,
  NativeSelect
} from '@mui/material';
import {
  parameterConstants,
  contentTypeConstants,
  dataTypeConstants,
  payloadConstants
} from '../../../../data/appConstants';
import '../add-api.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from '../../../Shared/Tooltip';
import variables from '../../../../variables.module.scss';
import EndpointBodyPayload from './EndpointBodyPayload';
import { materialTableIcons } from '../../../../utils/material-table-icons';
import i18next from 'i18next';

function InlineEditTable(props) {
  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important'
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&$selected': {},
            '&$selected:hover': {
              backgroundColor: '#005691',
              color: 'white'
            },
            '&:before': {
              width: 'unset'
            }
          },
          button: {
            '&:hover': {
              backgroundColor: '#005691',
              color: 'white'
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#d9ebf7'
            },
            '&:before': {
              width: 'unset'
            }
          }
        }
      }
    }
  });
  const [alert, setAlert] = React.useState(false);
  const [alertText, setAlertText] = React.useState('');
  let params = [];
  props.parameterOptions.map((option) => {
    if (option.reqParamType !== 'Path') {
      params.push({
        id: option.description,
        name: option.description,
        isActive: true
      });
    }
    return true;
  });
  const [allDataTypes, setAllDataTypes] = React.useState(dataTypeConstants);
  const [selectedRequestContentType, setSelectedRequestContentType] = React.useState(
    props.endpointData[props.index]?.contentType &&
      (props.endpointData[props.index].contentType[0] ===
        contentTypeConstants.applicationJson ||
        props.endpointData[props.index]?.contentType[0] ===
          contentTypeConstants.applicationXml)
      ? props.endpointData[props.index].contentType[0]
      : contentTypeConstants.notApplicable
  );
  const [selectedEndpointName, setSelectedEndpointName] = React.useState('');
  const [selectedResponseContentType, setSelectedResponseContentType] =
    React.useState(
      props.endpointData[props.index]?.responseSample?.length > 0
        ? props.endpointData[props.index].responseSample[
            props.endpointData[props.index].responseSample.length - 1
          ].contentType
        : contentTypeConstants.notApplicable
    );
  const [selectedResponseCode, setSelectedResponseCode] = React.useState(
    props.endpointData[props.index]?.responseSample?.length > 0
      ? props.endpointData[props.index].responseSample[
          props.endpointData[props.index].responseSample.length - 1
        ].responseStatusCode
      : ''
  );
  const [requestBody, setRequestBody] = React.useState(
    props.endpointData[props.index]?.requestSample?.length > 0
      ? props.endpointData[props.index].requestSample[
          props.endpointData[props.index].requestSample.length - 1
        ]['request']
      : ''
  );
  const [responseBody, setResponseBody] = React.useState(
    props.endpointData[props.index]?.responseSample?.length > 0
      ? props.endpointData[props.index].responseSample[
          props.endpointData[props.index].responseSample.length - 1
        ].response
      : ''
  );
  const [paramLookup] = React.useState(params);
  const [errorFlag, setErrorFlag] = React.useState(false);
  const [requestPayloadError, setRequestPayloadError] = React.useState(false);
  const [responsePayloadError, setResponsePayloadError] = React.useState(false);
  const [isEditAction, setEditAction] = React.useState(false);
  const regexQuery = /^[a-zA-Z0-9\[_\]\\-]*$/; // to validate name when parameter is Query
  const regex = /^[A-Za-z0-9-_\[\]\(\)]+$/; //to validate name which is alphanumeric and accepts underscore and hyphen
  //Disabling the fields based on parameters added

  const updateParamLookup = (data) => {
    let existingParamType = '';
    data.map((filteredData) => {
      if (
        filteredData.parameter === parameterConstants.formDataConst ||
        filteredData.parameter === parameterConstants.formUrlEncodedConst ||
        filteredData.parameter === parameterConstants.rawBodyConst
      ) {
        existingParamType = filteredData.parameter;
      }
      return true;
    });
    paramLookup.map((param) => {
      if (
        param.id !== existingParamType &&
        param.id !== parameterConstants.headerConst &&
        param.id !== parameterConstants.queryConst &&
        existingParamType !== ''
      ) {
        param.isActive = false;
      } else {
        param.isActive = true;
      }
      if (
        existingParamType === parameterConstants.rawBodyConst &&
        param.id === existingParamType
      ) {
        param.isActive = false;
      }
      return true;
    });
  };

  React.useEffect(() => {
    setSelectedEndpointName(props.endpointData[props.index]?.name);
  }, []);

  React.useEffect(() => {
    if (requestPayloadError || responsePayloadError) {
      setEditAction(false);
    }
  }, [requestPayloadError, responsePayloadError]);

  const handleShowToolTip = (type) => {
    setState((prevState) => {
      const findValIndex = prevState.columns.findIndex(
        (item) => item.field === 'required'
      );
      prevState.columns[findValIndex].title = (
        <Box display='inline-block'>
          {i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.REQUIRED')}
          <LightTooltip
            onOpen={() => handleShowToolTip(true)}
            onClose={() => handleShowToolTip(false)}
            open={type}
            title={i18next.t('DYNAMIC_FORM.ADD_API.MARKED_AS_YES')}
          >
            <span
              onClick={() => {
                handleShowToolTip(true);
              }}
            >
              <InfoOutlinedIcon className='info-icon-style' color='primary' />
            </span>
          </LightTooltip>
        </Box>
      );
      return prevState;
    });
  };

  const [state, setState] = React.useState({
    columns: [
      {
        title: 'Parameter',
        field: 'parameter',
        width: 100,
        editComponent: (props) => {
          return (
            <NativeSelect
              variant='standard'
              value={!!props.value ? props.value : parameterConstants.headerConst}
              onChange={(event) => {
                props.onChange(event.target.value);
                handleChange(event);
              }}
            >
              {paramLookup.map((option) => (
                <option
                  key={option.name}
                  value={option.id}
                  disabled={!option.isActive}
                >
                  {option.name}
                </option>
              ))}
            </NativeSelect>
          );
        },
        editable: props.editableAttributes.productDetailedInfo_endPointDetails
          .disabled
          ? 'never'
          : 'onAdd'
      },
      {
        title: (
          <>
            Name<span className='input__required'>*</span>
          </>
        ),
        field: 'name',
        width: 100,
        filterPlaceholder: 'Enter name',
        cellStyle: {
          textOverflow: 'ellipsis',
          maxWidth: '9rem',
          overflow: 'hidden'
        },
        editable: props.editableAttributes.productDetailedInfo_endPointDetails
          .disabled
          ? 'never'
          : 'always',
        validate: (rowData) =>
          rowData.name === undefined ||
          rowData.name === '' ||
          (rowData.parameter === 'Query'
            ? !regexQuery.test(rowData.name)
            : !regex.test(rowData.name))
            ? { isValid: false, helperText: 'Please enter valid Name' }
            : { isValid: true, helperText: '' },
        editComponent: (props) => {
          return (
            <TextField
              onChange={(e) => props.onChange(e.target.value)}
              variant='standard'
              value={props.value}
              inputProps={{ maxLength: 50 }}
              helperText={props.helperText}
              error={props.helperText !== '' && props.value !== undefined}
            />
          );
        }
      },
      {
        title: 'Data Type',
        field: 'dataType',
        width: 100,
        editComponent: (props) => {
          return (
            <NativeSelect
              variant='standard'
              value={props.value ? props.value : dataTypeConstants[0].id}
              onChange={(event) => props.onChange(event.target.value)}
            >
              {allDataTypes.map((option) => (
                <option
                  key={option.name}
                  value={option.id}
                  disabled={!option.isValid}
                >
                  {option.name}
                </option>
              ))}
            </NativeSelect>
          );
        },
        editable: props.editableAttributes.productDetailedInfo_endPointDetails
          .disabled
          ? 'never'
          : 'onAdd'
      },
      {
        title: (
          <Box display='inline-block'>
            {i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.REQUIRED')}
            <LightTooltip
              onOpen={() => handleShowToolTip(true)}
              onClose={() => handleShowToolTip(false)}
              open={false}
              title={i18next.t('DYNAMIC_FORM.ADD_API.MARKED_AS_YES')}
            >
              <span
                onClick={() => {
                  handleShowToolTip(true);
                }}
              >
                <InfoOutlinedIcon className='info-icon-style' color='primary' />
              </span>
            </LightTooltip>
          </Box>
        ),
        field: 'required',
        width: 100,
        initialEditValue: 'Yes',
        editComponent: (props) => {
          return (
            <NativeSelect
              variant='standard'
              value={props.value ? props.value : dataTypeConstants[0].id}
              onChange={(event) => props.onChange(event.target.value)}
            >
              {['Yes', 'No'].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </NativeSelect>
          );
        },
        editable: props.editableAttributes.productDetailedInfo_endPointDetails
          .disabled
          ? 'never'
          : 'always'
      },
      {
        title: 'Description',
        field: 'description',
        width: 100,
        cellStyle: {
          textOverflow: 'ellipsis',
          maxWidth: '9rem',
          overflow: 'hidden'
        },
        editComponent: (props) => {
          return (
            <TextField
              variant='standard'
              onChange={(e) => props.onChange(e.target.value)}
              value={props.value}
              inputProps={{ maxLength: 500 }}
            />
          );
        },
        editable: props.editableAttributes.productDetailedInfo_endPointDetails
          .disabled
          ? 'never'
          : 'always'
      }
    ],
    data: []
  });

  const handleChange = (event) => {
    let selectedParamType = event.target.value;
    let paramObj = props?.parameterOptions?.find(function (item) {
      return item.description === selectedParamType;
    });
    if (!!paramObj) {
      selectedParamType = paramObj.reqParamType;
    }
    let dataTypes = props.paramDataTypes[selectedParamType];
    let paramDataTypes = allDataTypes;
    paramDataTypes.forEach((type) => {
      if (dataTypes.includes(type.id)) {
        type.isValid = true;
      } else {
        type.isValid = false;
      }
    });
    setAllDataTypes(paramDataTypes);
  };

  if (props.isEdit) {
    let paramDataValues = [];
    props.paramDataValues.map((data) => {
      let required = '';
      if (data.required === '1') {
        required = 'Yes';
      } else required = 'No';
      paramDataValues.push({
        dataType: data.dataType,
        description: data.description,
        name: data.name,
        required: required,
        parameter: data.parameter,
        id: data.id
      });
      return true;
    });
    state.data = paramDataValues;
    updateParamLookup(state.data);
    props.changeEditValue();
  }

  const setRequestContentType = (data, oldData) => {
    let parameterTypes = data.map((type) => type.parameter);
    if (props?.endpointData[props.index]?.method === 'GET') {
      setSelectedRequestContentType(contentTypeConstants.notApplicable);
    } else {
      switch (oldData.parameter) {
        case parameterConstants.rawBodyConst:
          if (oldData.dataType !== 'Object' && oldData.dataType !== 'Array') {
            setSelectedRequestContentType(contentTypeConstants.notApplicable);
            setRequestBody('');
          }
          break;
        case parameterConstants.formDataConst:
          if (parameterTypes.indexOf(parameterConstants.formDataConst) === -1) {
            setRequestBody('');
            setSelectedRequestContentType(contentTypeConstants.notApplicable);
          }
          break;
        case parameterConstants.formUrlEncodedConst:
          if (
            parameterTypes.indexOf(parameterConstants.formUrlEncodedConst) === -1
          ) {
            setRequestBody('');
            setSelectedRequestContentType(contentTypeConstants.notApplicable);
          }
          break;
      }
    }
  };

  const getRequestContentType = () => {
    let parameterTypes = state.data.map((type) => type.parameter);
    let rawBodyParameter = null;
    if (props.endpointData[props.index].method === 'GET') {
      return contentTypeConstants.notApplicable;
    } else {
      if (parameterTypes.length > 0) {
        if (parameterTypes.indexOf(parameterConstants.formDataConst) !== -1) {
          return contentTypeConstants.multipartData;
        }

        if (parameterTypes.indexOf(parameterConstants.formUrlEncodedConst) !== -1) {
          return contentTypeConstants.urlEncoded;
        }

        if (
          (parameterTypes.indexOf(parameterConstants.headerConst) !== -1 ||
            parameterTypes.indexOf(parameterConstants.queryConst) !== -1) &&
          parameterTypes.indexOf(parameterConstants.rawBodyConst) === -1
        ) {
          return selectedRequestContentType;
        }

        if (parameterTypes.indexOf(parameterConstants.rawBodyConst) !== -1) {
          rawBodyParameter = state.data.filter(
            (type) => type.parameter === parameterConstants.rawBodyConst
          );
          if (
            rawBodyParameter[0].dataType !== 'Object' &&
            rawBodyParameter[0].dataType !== 'Array'
          ) {
            return contentTypeConstants.applicationJson;
          } else {
            return selectedRequestContentType;
          }
        }
      } else {
        return selectedRequestContentType;
      }
    }
  };

  const saveData = (event) => {
    let headerNames = [];
    let queryNames = [];
    let formDataNames = [];
    let formUrlEncodedNames = [];
    let formBodyNames = [];
    let paramDataTypes = state.data.map((item) => item.dataType);
    let responseSample = [
      {
        responseStatusCode: selectedResponseCode,
        contentType: selectedResponseContentType,
        response: responseBody,
        _id:
          props?.endpointData[props.index]?.responseSample?.length > 0
            ? props.endpointData[props.index].responseSample[
                props.endpointData[props.index].responseSample.length - 1
              ]._id
            : undefined
      }
    ];
    let requestSample = [
      {
        request: requestBody,
        _id:
          props?.endpointData[props.index]?.requestSample?.length > 0
            ? props.endpointData[props.index].requestSample[
                props.endpointData[props.index].requestSample.length - 1
              ]._id
            : undefined
      }
    ];
    if (
      (state.data.some(
        (item) => item.parameter === parameterConstants.rawBodyConst
      ) &&
        !paramDataTypes.includes('Object') &&
        !paramDataTypes.includes('Array')) ||
      state.data.some(
        (item) => item.parameter === parameterConstants.formDataConst
      ) ||
      state.data.some(
        (item) => item.parameter === parameterConstants.formUrlEncodedConst
      )
    ) {
      requestSample[0]['request'] = '';
    }
    state.data.map((data) => {
      switch (data.parameter) {
        case parameterConstants.headerConst:
          headerNames.push(data.name.trim());
          break;
        case parameterConstants.queryConst:
          queryNames.push(data.name.trim());
          break;
        case parameterConstants.formDataConst:
          formDataNames.push(data.name.trim());
          break;
        case parameterConstants.formUrlEncodedConst:
          formUrlEncodedNames.push(data.name.trim());
          break;
        case parameterConstants.rawBodyConst:
          formBodyNames.push(data.name.trim());
          break;
        default:
          break;
      }
      return true;
    });
    const headerSet = new Set(headerNames);
    const querySet = new Set(queryNames);
    const formDataSet = new Set(formDataNames);
    const formUrlEncodedSet = new Set(formUrlEncodedNames);
    const formBodySet = new Set(formBodyNames);
    let error = false;
    if (
      headerNames.length !== headerSet.size ||
      queryNames.length !== querySet.size ||
      formDataNames.length !== formDataSet.size ||
      formUrlEncodedNames.length !== formUrlEncodedSet.size ||
      formBodyNames.length !== formBodySet.size
    ) {
      error = true;
      setErrorFlag(true);
      setAlertText(i18next.t('DYNAMIC_FORM.ADD_API.DUPLICATE_NAME'));
      setAlert(true);
    }
    if (!error) {
      let header = [];
      let query = [];
      let formurlEncoded = [];
      let formData = [];
      let body = [];
      let paramCount = 0;
      state.data.map((data) => {
        const required = data.required === 'Yes' ? '1' : '0';
        let dataObj = {
          name: data.name,
          required: required,
          dataType: data.dataType,
          description: data.description,
          parameter: data.parameter,
          id: data.id
        };
        switch (data.parameter) {
          case parameterConstants.headerConst:
            header.push(dataObj);
            break;
          case parameterConstants.queryConst:
            query.push(dataObj);
            break;
          case parameterConstants.formDataConst:
            formData.push(dataObj);
            break;
          case parameterConstants.formUrlEncodedConst:
            formurlEncoded.push(dataObj);
            break;
          case parameterConstants.rawBodyConst:
            body.push(dataObj);
            break;
          default:
            break;
        }
        return true;
      });

      props.endpointData['requestSample'] = requestSample;
      props.endpointData['responseSample'] = responseSample;
      props.endpointData['header'] = header;
      props.endpointData['query'] = query;
      props.endpointData['formData'] = formData;
      props.endpointData['formUrlEncoded'] = formurlEncoded;
      props.endpointData['body'] = body;
      props.endpointData['contentType'] = getRequestContentType();
      paramCount =
        header.length +
        query.length +
        formData.length +
        formurlEncoded.length +
        body.length;
      props.headerData(header, props.index);
      props.queryData(query, props.index);
      props.updateFormUrlEncoded(formurlEncoded, props.index);
      props.updateFormData(formData, props.index);
      props.updateBody(body, props.index);
      props.updateRequestSample(requestSample, props.index);
      props.updateResponseSample(responseSample, props.index);
      props.paramCount(paramCount, props.index);
      props.updateContentType([getRequestContentType()], props.index);
      props.paramData(state.data);
      props.callback();
      props.snackBar(false);
      props.submitData(event);
    }
  };

  const changeRequestContentType = (event) => {
    let selected = event.target.value;
    setSelectedRequestContentType(selected);
  };

  const changeResponseContentType = (event) => {
    let selected = event.target.value;
    setSelectedResponseContentType(selected);
  };

  const changeResponseCodeValue = (value) => {
    setSelectedResponseCode(value);
  };

  const setRequestBodySampleValue = (value) => {
    if (value !== 'Please enter sample request body...') {
      setRequestBody(value);
    }
  };

  const setResponseBodySampleValue = (value) => {
    if (value !== 'Please enter sample response body...') {
      setResponseBody(value);
    }
  };

  const setRequestSaveButtonState = (value) => {
    setEditAction(true);
    setRequestPayloadError(value);
  };

  const setResponseSaveButtonState = (value) => {
    setEditAction(true);
    setResponsePayloadError(value);
  };

  const isRequestBodyPayloadValid = () => {
    if (
      props?.endpointData[props.index]?.method === 'PUT' ||
      props?.endpointData[props.index]?.method === 'POST' ||
      props?.endpointData[props.index]?.method === 'PATCH' ||
      props?.endpointData[props.index]?.method === 'DELETE'
    ) {
      if (state.data.length === 0) {
        return true;
      }
      if (
        !state.data.some(
          (item) => item.parameter === parameterConstants.formDataConst
        ) &&
        !state.data.some(
          (item) => item.parameter === parameterConstants.formUrlEncodedConst
        ) &&
        (state.data.some(
          (item) => item.parameter === parameterConstants.headerConst
        ) ||
          state.data.some(
            (item) => item.parameter === parameterConstants.queryConst
          )) &&
        !state.data.some(
          (item) => item.parameter === parameterConstants.rawBodyConst
        )
      ) {
        return true;
      }
      if (
        state.data.some(
          (item) => item.parameter === parameterConstants.rawBodyConst
        ) &&
        state.data.some(
          (item) => item.dataType === 'Object' || item.dataType === 'Array'
        )
      ) {
        return true;
      }
      return false;
    }
  };

  const getRequestResponseEditableFlag = () => {
    if (props?.deleteParam?.disableFields) {
      return true;
    } else {
      return props?.editableAttributes?.productDetailedInfo_endPointDetails?.disabled
        ? true
        : false;
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MaterialTable
          icons={materialTableIcons()}
          title={''}
          columns={state.columns}
          data={state.data}
          options={{
            rowStyle: {
              backgroundColor: '#e0e2e5',
              fontFamily: variables.generalBoschFont,
              detailPanelType: 'single',
              tableLayout: 'fixed'
            },
            headerStyle: {
              fontFamily: variables.boldBoschFont,
              borderBottom: '4px solid #bfc0c2',
              fontWeight: 'normal',
              fontSize: '14px',
              padding: '15px 0px'
            },
            draggable: false,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false
          }}
          localization={{
            body: {
              addTooltip: 'Add Parameter',
              editRow: {
                deleteText: 'Are you sure you want to permanently delete this row?',
                saveTooltip: 'Add'
              }
            }
          }}
          editable={
            !props?.deleteParam?.disableFields &&
            !props?.editableAttributes?.productDetailedInfo_endPointDetails?.disabled
              ? {
                  isDeletable: (rowData) =>
                    props.editableAttributes.productDetailedInfo_endPointDetails
                      .disabled === false ||
                    props.editableAttributes.productDetailedInfo_endPointDetails
                      .disabled === undefined,
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        setState((prevState) => {
                          let dataTypes = props.paramDataTypes['Header'];
                          let paramDataTypes = allDataTypes;
                          paramDataTypes.forEach((type) => {
                            type.isValid = dataTypes.includes(type.id);
                          });
                          setAllDataTypes(paramDataTypes);
                          const data = [...prevState.data];
                          const columns = [...prevState.columns];
                          if (newData.parameter === undefined) {
                            newData.parameter = parameterConstants.headerConst;
                          }
                          if (!newData.dataType) {
                            newData.dataType = dataTypeConstants[0].id;
                          }
                          data.push(newData);
                          updateParamLookup(data);
                          setEditAction(true);
                          return { ...prevState, data, columns };
                        });
                      }, 600);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          setState((prevState) => {
                            const data = [...prevState.data];
                            if (newData.parameter === undefined) {
                              newData.parameter = parameterConstants.headerConst;
                            }
                            data[data.indexOf(oldData)] = newData;
                            setErrorFlag(false);
                            setAlert(false);
                            setEditAction(true);
                            return { ...prevState, data };
                          });
                        }
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        setState((prevState) => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          setRequestContentType(data, oldData);
                          if (!!oldData.id) {
                            let required = '0';
                            if (oldData.required === 'Yes') {
                              required = '1';
                            }
                            oldData.required = required;
                            props.deleteParam
                              .deleteEndpointParameter(
                                oldData,
                                props.index,
                                props.deleteParam.prodBasicInfo.productId,
                                props.deleteParam.prodDetailedInfo
                              )
                              .then(() => {
                                props.updateEndpointsData();
                              });
                          }
                          updateParamLookup(data);
                          setErrorFlag(false);
                          setAlert(false);
                          setEditAction(true);
                          return { ...prevState, data };
                        });
                      }, 600);
                    })
                }
              : props?.deleteParam?.disableFields
                ? {
                    isDeletable: (rowData) =>
                      props?.editableAttributes?.productDetailedInfo_endPointDetails
                        ?.disabled === false ||
                      props?.editableAttributes?.productDetailedInfo_endPointDetails
                        ?.disabled === undefined
                  }
                : {
                    isDeletable: (rowData) =>
                      props?.editableAttributes?.productDetailedInfo_endPointDetails
                        ?.disabled === false ||
                      props?.editableAttributes?.productDetailedInfo_endPointDetails
                        ?.disabled === undefined,
                    isEditable: (rowData) =>
                      props?.editableAttributes?.productDetailedInfo_endPointDetails
                        ?.disabled === false ||
                      props?.editableAttributes?.productDetailedInfo_endPointDetails
                        ?.disabled === undefined,
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          if (oldData) {
                            setState((prevState) => {
                              const data = [...prevState.data];
                              if (newData.parameter === undefined) {
                                newData.parameter = parameterConstants.headerConst;
                              }
                              data[data.indexOf(oldData)] = newData;
                              setErrorFlag(false);
                              setAlert(false);
                              setEditAction(true);
                              return { ...prevState, data };
                            });
                          }
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          setState((prevState) => {
                            const data = [...prevState.data];
                            data.splice(data.indexOf(oldData), 1);
                            setRequestContentType(data, oldData);
                            if (!!oldData.id) {
                              let required = '0';
                              if (oldData.required === 'Yes') {
                                required = '1';
                              }
                              oldData.required = required;
                              props.deleteParam
                                .deleteEndpointParameter(
                                  oldData,
                                  props.index,
                                  props.deleteParam.prodBasicInfo.productId,
                                  props.deleteParam.prodDetailedInfo
                                )
                                .then(() => {
                                  props.updateEndpointsData();
                                });
                            }
                            updateParamLookup(data);
                            setErrorFlag(false);
                            setAlert(false);
                            setEditAction(true);
                            return { ...prevState, data };
                          });
                        }, 600);
                      })
                  }
          }
        />
        <Collapse in={alert}>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlert(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {' '}
            {alertText}
          </Alert>
        </Collapse>
        {isRequestBodyPayloadValid() && (
          <EndpointBodyPayload
            endPointName={selectedEndpointName}
            title={i18next.t('DYNAMIC_FORM.ADD_API.REQUEST_EXAMPLE')}
            selectedContentType={selectedRequestContentType}
            contentTypes={props.contentTypeData}
            bodyPlaceholderText={i18next.t(
              'DYNAMIC_FORM.ADD_API.PLEASE_ENTER_SAMPLE_REQUEST_BODY'
            )}
            schemaPlaceholderText={i18next.t(
              'DYNAMIC_FORM.ADD_API.PLEASE_ENTER_SAMPLE_REQUEST_SCHEMA'
            )}
            type={payloadConstants.request}
            isSchemaDisabled={true}
            isBuyerView={false}
            isEditable={!getRequestResponseEditableFlag()}
            changeContentType={changeRequestContentType}
            setSaveButtonState={setRequestSaveButtonState}
            bodyValue={requestBody}
            setBodySampleValue={setRequestBodySampleValue}
            schemaValue={''}
          />
        )}
        {
          <Box mt={5}>
            <EndpointBodyPayload
              endPointName={selectedEndpointName}
              title={i18next.t('API_DETAIL.ENDPOINTS_TAB.RESPONSE_EXAMPLE')}
              selectedContentType={selectedResponseContentType}
              selectedResponseCode={selectedResponseCode}
              contentTypes={props.contentTypeData}
              bodyPlaceholderText={i18next.t(
                'DYNAMIC_FORM.ADD_API.PLEASE_ENTER_SAMPLE_RESPONSE_BODY'
              )}
              schemaPlaceholderText={i18next.t(
                'DYNAMIC_FORM.ADD_API.PLEASE_ENTER_SAMPLE_REQUEST_SCHEMA'
              )}
              type={payloadConstants.response}
              isSchemaDisabled={true}
              isBuyerView={false}
              isEditable={!getRequestResponseEditableFlag()}
              changeContentType={changeResponseContentType}
              changeResponseCodeValue={changeResponseCodeValue}
              setSaveButtonState={setResponseSaveButtonState}
              bodyValue={responseBody}
              setBodySampleValue={setResponseBodySampleValue}
              schemaValue={''}
            />
          </Box>
        }
        <div className='inline-edit-save-button-style'>
          <div className='margin-right-spacing'>
            <Button
              variant='contained'
              label={i18next.t('DYNAMIC_FORM.LABEL.SAVE')}
              disabled={
                !isEditAction ||
                requestPayloadError ||
                responsePayloadError ||
                errorFlag ||
                props.editableAttributes.productDetailedInfo_endPointDetails
                  .disabled ||
                props.disableFields
              }
              onClick={(event) => saveData(event)}
            />
          </div>
          <Button
            variant='outlined'
            label={i18next.t('DYNAMIC_FORM.LABEL.CANCEL')}
            onClick={props.callback}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default InlineEditTable;
