import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRegionSpecificCountryList } from '../../actions/CountryAction';
import { retrieveMSCompanyDetailsForViewProfile } from '../../actions/ProfileAction';
import { documentDownload } from '../../actions/DocumentAction';
import { retrieveUserCompanyStatusForViewProfile } from '../../actions/CompanyAction';
import {
  regenerateAAATokens,
  logout,
  deregisterUser
} from '../../actions/LoginAction';
import idConstants from '../../data/idConstants';
import { GAEventAction } from '../../data/ga-constants';
import * as Constants from '../../actions/configuration';
import Loader from '../Shared/Utils/Loader';
import { accessRoles } from '../../data/appConstants';
import {
  SecondaryButton,
  SideBar
} from '../../msreact-kit/packages/html-components';
import ConfirmModal from '../Shared/Utils/Modal/ConfirmationModal';
import InfoModal from '../Shared/Utils/Modal/InformationModal';
import SnackBar from '../Shared/Utils/SnackBar';
import { trackEvent } from '../../utils/analytics';
import {
  checkForAccountDeregistration,
  deleteSellerAccountFromMS
} from '../../actions/DeleteUserAction';
import './index.scss';
import images from '../../assets/img';
import _ from 'lodash';

function bytesToSize(bytes) {
  const sizes = [
    i18next.t('DYNAMIC_FORM.FORMATS.BYTE'),
    i18next.t('DYNAMIC_FORM.FORMATS.KB'),
    i18next.t('DYNAMIC_FORM.FORMATS.MB')
  ];
  for (let size of sizes) {
    if (bytes <= 1024) {
      return bytes + ' ' + size;
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return bytes + 'P';
}

const Profile = (props) => {
  const [userData, setUserData] = useState([]);
  const [userEmailId, setUserEmailId] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  // Required for furture use as document section is commented for other regions
  // const [vatFiles, setVatFiles] = useState([]);
  // const [otherFiles, setOtherFiles] = useState([]);
  const [w9Files, setW9Files] = useState([]);
  const [panFiles, setPanFiles] = useState([]);
  const [gstFiles, setGstFiles] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const reduxStore = useSelector((state) => state);
  const errorDeleteAccount = reduxStore.DeleteAccountReducer.errorDeleteAccount;
  const [successSellerAccountDeletionAlert, setSuccessSellerAccountDeletionAlert] =
    useState({ flag: false, message: '', serverity: '' });
  const [chooseDeletionModal, setChooseDeletionModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [openInfoModal, setOpenInfoModal] = React.useState(false);
  const [viewUserCompanyStatus, setViewUserCompanyStatus] = React.useState({});
  const dispatchRedux = useDispatch();
  const isInternalSellerTenant =
    process.env.REACT_APP_SELLER_TENANT_ID === Constants.TENANT.MKOSINT;
  const pendingRegistrationText = isInternalSellerTenant
    ? i18next.t('INTERNAL_SELLER.PENDING_REGISTRATION')
    : i18next.t('DYNAMIC_FORM.VIEW_PROFILE.PENDING_FOR_COMPANY_REGISTRATION');
  const charsInALine =
    idConstants.companyName.charslength / idConstants.companyName.sections + 1;
  const userLoginRole = localStorage.getItem(idConstants.userLoginRole.role);
  const isSalesRep =
    props.user?.roles?.includes(accessRoles.sales_rep) &&
    userLoginRole === accessRoles.sales_rep;
  const { firstName = '', lastName = '' } = viewUserCompanyStatus?.user || {};
  useEffect(() => {
    props.getRegionSpecificCountryList();
  }, []);

  useEffect(() => {
    if (props?.user) {
      const decoded_jwtToken = props?.user;
      setUserEmailId(decoded_jwtToken?.emailId);
    }
    props.retrieveUserCompanyStatusForViewProfile().then(() => {
      if (
        props.viewUserCompanyStatus?.companyId === undefined ||
        props.viewUserCompanyStatus?.companyId === null ||
        isInternalSellerTenant
      ) {
        setUserData('');
      } else {
        setLoader(true);
        props
          .retrieveMSCompanyDetailsForViewProfile(
            props.viewUserCompanyStatus.companyId
          )
          .then((res) => {
            if (res) {
              let panFilesFromRes = [];
              let gstFilesFromRes = [];
              let vatFilesFromRes = [];
              let otherFilesFromRes = [];
              let w9FilesFromRes = [];
              setUserData(res);
              props?.regionSpecificCountryList?.forEach((country) => {
                if (res.billingAddress && country?.countryCode === res.countryCode) {
                  setBillingCountry(country.countryName);
                  setCountryCode(country?.countryCode);
                }
                if (res.documents && res.documents != []) {
                  res.documents.forEach((item) => {
                    if (country?.countryCode === 'IN') {
                      if (item.docTypeId === idConstants.docId.pan) {
                        panFilesFromRes.push(item);
                      } else if (item.docTypeId === idConstants.docId.gst) {
                        gstFilesFromRes.push(item);
                      }
                    }
                    if (idConstants.euCountryCodes.includes(country?.countryCode)) {
                      if (item.docTypeId === idConstants.docId.vatId) {
                        vatFilesFromRes.push(item);
                      } else if (item.docTypeId === idConstants.docId.otherDoc) {
                        otherFilesFromRes.push(item);
                      }
                    }
                    if (country?.countryCode === 'US') {
                      if (item.docTypeId === idConstants.docId.w9Id) {
                        w9FilesFromRes.push(item);
                      }
                    }
                  });
                }
              });
              setPanFiles(panFilesFromRes);
              setGstFiles(gstFilesFromRes);
              setW9Files(w9FilesFromRes);
              setLoader(false);
              // Required for furture use as document section is commented for other regions
              // setVatFiles(vatFilesFromRes);
              // setOtherFiles(otherFilesFromRes);
            }
          });
      }
    });
  }, [props?.viewUserCompanyStatus?.companyId, props?.regionSpecificCountryList]);

  React.useEffect(() => {
    setViewUserCompanyStatus(props?.viewUserCompanyStatus);
  }, [props?.viewUserCompanyStatus]);

  React.useEffect(() => {
    if (errorDeleteAccount) {
      setSuccessSellerAccountDeletionAlert((prevState) => ({
        ...prevState,
        flag: true,
        message: errorDeleteAccount,
        serverity: 'error'
      }));
    }
  }, [errorDeleteAccount]);

  const handleSideBar = () => {
    props.callbackModal();
  };

  const handleDeleteAccount = () => {
    props.regenerateAAATokens()?.then(() => {
      dispatchRedux(logout());
    });
    window.open(process.env.REACT_APP_SKID_DELETE, '_blank');
  };

  const handleDeleteClose = () => {
    props.regenerateAAATokens()?.then(() => {
      dispatchRedux(logout());
    });
  };

  const viewDocument = (fileId, docId, name) => {
    //code to view and download the documents
    setLoader(true);
    let params = {
      fileId: fileId,
      docId: docId
    };
    props
      .documentDownload(params)
      .then((response) => {
        setLoader(false);
        let apiResponse = response.hasOwnProperty('request')
          ? response.request.response
          : {};
        let fileName = name;
        let blob = new Blob([apiResponse], { type: 'octet/stream' });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        document.body.appendChild(link);
        link.style = 'display: none';
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  /**
   * @tutorial SELLER_DEREGISTRATION Deleting the User details from MS Seller Account i.e., user seller role, applications
   * @yields checks for eligibility of user deletion, if eligible will proceed to delete seller account from MS system and role from keycloak, then regenerates AAA token to update role deletion
   */
  const handleDeleteMSaccount = () => {
    setLoader(true);
    setChooseDeletionModal(false);
    checkForAccountDeregistration()
      .then((response) => {
        dispatchRedux({
          type: 'ACCOUNT_DELETION_CHECK',
          payload: response.data
        });
        if (response.data?.error) {
          setLoader(false);
          setOpenInfoModal(true);
        } else if (response.data === true) {
          setLoader(true);
          deleteSellerAccountFromMS()
            .then((response) => {
              setLoader(false);
              setShowDeletePopup(true);
            })
            .catch((error) => {
              setLoader(false);
              dispatchRedux({
                type: 'ERROR_DELETE_ACCOUNT',
                payload: error?.message
              });
            });
        }
      })
      .catch((error) => {
        setLoader(false);
        dispatchRedux({
          type: 'ERROR',
          error: error
        });
      });
  };

  /**
   * @tutorial CIAM_DEREGISTRATION
   * @yields checks for eligibility of user deletion, if eligible will proceed for logout and then shows CIAM deregistration page
   */
  const handleDeleteCIAMaccount = () => {
    setLoader(true);
    setChooseDeletionModal(false);
    checkForAccountDeregistration()
      .then((response) => {
        dispatchRedux({
          type: 'ACCOUNT_DELETION_CHECK',
          payload: response.data
        });
        setLoader(false);
        if (response.data.error) {
          setOpenInfoModal(true);
        } else if (response.data === true) {
          setLoader(true);
          dispatchRedux(deregisterUser());
        }
      })
      .catch((error) => {
        setLoader(false);
        dispatchRedux({
          type: 'ERROR',
          error: error
        });
      });
  };

  const okBtnAction = () => {
    setOpenInfoModal(false);
  };
  const closeInfoPopup = () => {
    setOpenInfoModal(false);
  };

  const documentSection = (files) => {
    return files.map((item) => {
      return (
        <div className='expansion-display-type' key={item.docTypeId}>
          <div className='document-section'>
            <i className={getDocumentImage(item.documentName)}></i>
            <div
              className='document-details'
              onClick={() =>
                viewDocument(item.docLink, item.docTypeId, item.documentName)
              }
            >
              <p>{item.documentName}</p>
              <p>
                {bytesToSize(
                  item.size === undefined ? item.documentSize : item.size
                )}
              </p>
            </div>
          </div>
        </div>
      );
    });
  };

  const getDocumentImage = (fileName) => {
    const fileExtension = fileName.split('.').pop();
    switch (fileExtension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'a-icon boschicon-bosch-ic-image-gallery icon-size';
      case 'mp3':
        return 'a-icon boschicon-bosch-ic-document-audio icon-size';
      case 'mp4':
        return 'a-icon boschicon-bosch-ic-video icon-size';
      case 'pdf':
        return 'a-icon boschicon-bosch-ic-document-pdf icon-size';
      default:
        return 'a-icon boschicon-bosch-ic-document-doc icon-size';
    }
  };

  const getStatusContent = () => {
    if (
      viewUserCompanyStatus.companyRegisteredStatus === null ||
      viewUserCompanyStatus.companyRegisteredStatus === 'draft'
    ) {
      return (
        <div className='-size-m highlight background-color-pending-status'>
          <i className='a-icon boschicon-bosch-ic-alert-info warning-icon'></i>
          {pendingRegistrationText}
        </div>
      );
    } else if (
      viewUserCompanyStatus.companyRegisteredStatus === 'pending_approval'
    ) {
      return (
        <div className='-size-m highlight background-color-pending-status'>
          <i className='a-icon boschicon-bosch-ic-alert-info warning-icon'></i>
          {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.PENDING_FOR_ADMIN_APPROVAL')}
        </div>
      );
    } else if (viewUserCompanyStatus.companyRegisteredStatus === 'denied') {
      return (
        <div className='-size-m highlight background-color-rejected-status'>
          {i18next.t('DYNAMIC_FORM.SHARED.REJECTED')}
        </div>
      );
    }
  };
  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(process.env.REACT_APP_SKID_URL, '_blank');
    //GA-801
    trackEvent(
      'profile',
      GAEventAction.clicked,
      'Manage Single KeyID from profile page'
    );
  };
  return (
    <React.Fragment>
      {loader && <Loader />}
      <SideBar
        customClass='highlight profile-heading-size'
        label={i18next.t('DYNAMIC_FORM.LABEL.MY_PROFILE_DETAILS')}
        handleSideBar={handleSideBar}
        isShowCancelBtn={false}
        nextStepLabel={i18next.t('DYNAMIC_FORM.LABEL.DONE')}
        handleNext={handleSideBar}
      >
        {!isSalesRep
          ? viewUserCompanyStatus?.companyRegisteredStatus !== 'approved' &&
            getStatusContent()
          : null}
        {showDeletePopup && (
          <>
            <div className={`dialog ${showDeletePopup ? 'popupOpen' : ''}`}>
              <div className='dialog-content'>
                <div className='content-wrapper'>
                  <div className='left-part'>
                    <div className='image-container'>
                      <img src={images.userRemoveWhite} />
                    </div>
                    <p>
                      {i18next.t(
                        'DYNAMIC_FORM.VIEW_PROFILE.DELETED_MOBILITY_SUCCESSFULLY'
                      )}
                    </p>
                  </div>
                  <div className='right-part'>
                    <div className='close-button-wrapper'>
                      <button
                        className='popup-close-button'
                        onClick={handleDeleteClose}
                      >
                        X
                      </button>
                    </div>
                    <p>
                      {i18next.t(
                        'DYNAMIC_FORM.VIEW_PROFILE.DELETE_SINGLEKEYID_INFO'
                      )}
                    </p>
                    <img src={images.singleKeyIDImage} />
                    <br />
                    <button
                      className='delete-singlekeyid-button'
                      onClick={handleDeleteAccount}
                    >
                      {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.DELETE_SINGLEKEYID')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!isSalesRep ? (
          <>
            <div className='bg-grey-profile'>
              <div className='profile-styling'>
                <div className='user-heading -size-xl text'>
                  {`${firstName} ${lastName}`}
                  {viewUserCompanyStatus.companyRegisteredStatus === 'approved' && (
                    <div className='approved-status-container'>
                      <i className='a-icon boschicon-bosch-ic-alert-success-filled status-checked'></i>
                    </div>
                  )}
                </div>
                <div className='user-details'>
                  <div className='mail-text item'>
                    <h5>{i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MAIL_ID')}</h5>
                    <p className='label-text -size-l'>{userEmailId}</p>
                  </div>
                  <div className='number-text item'>
                    <h5>{i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MOBILE_NO')}</h5>
                    <p className='label-text -size-l'>
                      {viewUserCompanyStatus?.user?.contactNo ?? ''}
                    </p>
                  </div>
                </div>
              </div>
              <h3 className='profile-heading highlight -size-l'>
                {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.COMPANY_DETAILS')}
              </h3>
              {!isInternalSellerTenant && (
                <div className='profile-styling'>
                  <div className='company-details'>
                    <div className='item'>
                      <div className='expansion-display-type'>
                        <div className='-size-m'>
                          {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.ORGANIZATION_NAME')}
                        </div>
                        {_.times(idConstants?.companyName?.sections, (index) => {
                          const substring = userData?.companyName?.substring(
                            index * charsInALine,
                            (index + 1) * charsInALine
                          );

                          return (
                            <div className='text -size-l' key={index}>
                              {substring}
                            </div>
                          );
                        })}
                      </div>
                      {countryCode === 'IN' && (
                        <div className='expansion-display-type'>
                          <div className='-size-m'>
                            {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.BUSINESS_TYPE')}
                          </div>
                          <div className='text -size-l'>
                            {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.businessType}{' '}
                          </div>
                        </div>
                      )}
                      {countryCode === 'IN' && (
                        <div className='expansion-display-type'>
                          <div className='-size-m'>
                            {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.GSTIN')}
                          </div>
                          <div className='text -size-l'>
                            {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'GST'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'GST'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'GST'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'GST'
                              ).value}{' '}
                          </div>
                        </div>
                      )}
                      {countryCode === 'IN' && (
                        <div className='expansion-display-type'>
                          <div className='-size-m'>
                            {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.PAN_NO')}
                          </div>
                          <div className='text -size-l'>
                            {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'PAN'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'PAN'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'PAN'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'PAN'
                              ).value}{' '}
                          </div>
                        </div>
                      )}
                      {idConstants.euCountryCodes.includes(countryCode) && (
                        <div className='expansion-display-type'>
                          <div className='-size-m'>
                            {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.VAT_ID')}
                          </div>
                          <div className='text -size-l'>
                            {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'VAT'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'VAT'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'VAT'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'VAT'
                              ).value}{' '}
                          </div>
                        </div>
                      )}
                      {countryCode === 'US' && (
                        <div className='expansion-display-type'>
                          <div className='-size-m'>
                            {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.TIN')}
                          </div>
                          <div className='text -size-l'>
                            {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'TIN'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'TIN'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'TIN'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) => item.attributeName === 'TIN'
                              ).value}{' '}
                          </div>
                        </div>
                      )}
                      {countryCode === 'IN' && (
                        <>
                          <div className='expansion-display-type'>
                            <div className='-size-m'>
                              {i18next.t(
                                'DYNAMIC_FORM.VIEW_PROFILE.COVERED_UNDER_MSMED_ACT'
                              )}
                            </div>
                            <div className='text -size-l'>
                              {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'Covered Under MSMED Act'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'Covered Under MSMED Act'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'Covered Under MSMED Act'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'Covered Under MSMED Act'
                              ).value === 'yes'
                                ? i18next.t('DYNAMIC_FORM.SHARED.BUTTON.YES')
                                : i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NO')}{' '}
                            </div>
                          </div>
                          {userData !== undefined &&
                            userData !== null &&
                            userData != '' &&
                            userData.compAttributes !== undefined &&
                            userData.compAttributes !== null &&
                            userData.compAttributes !== '' &&
                            userData.compAttributes.find(
                              (item) =>
                                item.attributeName === 'Covered Under MSMED Act'
                            ) !== undefined &&
                            userData.compAttributes.find(
                              (item) =>
                                item.attributeName === 'Covered Under MSMED Act'
                            ) !== null &&
                            userData.compAttributes.find(
                              (item) =>
                                item.attributeName === 'Covered Under MSMED Act'
                            ) !== '' &&
                            userData.compAttributes.find(
                              (item) =>
                                item.attributeName === 'Covered Under MSMED Act'
                            ).value === 'yes' && (
                              <div className='expansion-display-type'>
                                <div className='-size-m'>
                                  {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MSME_NO')}
                                </div>
                                <div className='text -size-l'>
                                  {userData !== undefined &&
                                    userData !== null &&
                                    userData != '' &&
                                    userData.compAttributes !== undefined &&
                                    userData.compAttributes !== null &&
                                    userData.compAttributes !== '' &&
                                    userData.compAttributes.find(
                                      (item) =>
                                        item.attributeName ===
                                        'If Yes, Please Enter MSME No'
                                    ) !== undefined &&
                                    userData.compAttributes.find(
                                      (item) =>
                                        item.attributeName ===
                                        'If Yes, Please Enter MSME No'
                                    ) !== null &&
                                    userData.compAttributes.find(
                                      (item) =>
                                        item.attributeName ===
                                        'If Yes, Please Enter MSME No'
                                    ) !== '' &&
                                    userData.compAttributes.find(
                                      (item) =>
                                        item.attributeName ===
                                        'If Yes, Please Enter MSME No'
                                    ).value}{' '}
                                </div>
                              </div>
                            )}
                          <div className='expansion-display-type'>
                            <div className='-size-m'>
                              {i18next.t(
                                'DYNAMIC_FORM.VIEW_PROFILE.PSU_SEZ_UNDER_COMPOUNDING_SCHEME'
                              )}
                            </div>
                            <div className='text -size-l'>
                              {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName ===
                                  'PSU/SEZ/Under Compounding Scheme'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName ===
                                  'PSU/SEZ/Under Compounding Scheme'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName ===
                                  'PSU/SEZ/Under Compounding Scheme'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName ===
                                  'PSU/SEZ/Under Compounding Scheme'
                              ).value === 'yes'
                                ? i18next.t('DYNAMIC_FORM.SHARED.BUTTON.YES')
                                : i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NO')}{' '}
                            </div>
                          </div>
                          <div className='expansion-display-type'>
                            <div className='-size-m'>
                              {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.TAX_EXEMPT')}
                            </div>
                            <div className='text -size-l'>
                              {userData !== undefined &&
                              userData !== null &&
                              userData != '' &&
                              userData.compAttributes !== undefined &&
                              userData.compAttributes !== null &&
                              userData.compAttributes !== '' &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'TDS Exempted u/s 197'
                              ) !== undefined &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'TDS Exempted u/s 197'
                              ) !== null &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'TDS Exempted u/s 197'
                              ) !== '' &&
                              userData.compAttributes.find(
                                (item) =>
                                  item.attributeName === 'TDS Exempted u/s 197'
                              ).value === 'yes'
                                ? i18next.t('DYNAMIC_FORM.SHARED.BUTTON.YES')
                                : i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NO')}{' '}
                            </div>
                          </div>
                        </>
                      )}
                      <div className='expansion-display-type address-display'>
                        <div className='-size-m'>
                          {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.BILLING_ADDRESS')}
                        </div>
                        <div>
                          {userData !== undefined &&
                            userData !== null &&
                            userData != '' &&
                            userData.billingAddress !== undefined &&
                            userData.billingAddress !== null &&
                            userData.billingAddress !== '' && (
                              <div>
                                <div className='text -size-l'>
                                  {countryCode === 'IN'
                                    ? userData.billingAddress.addressLine1
                                    : userData.billingAddress.addressLine2}
                                  {userData.billingAddress.addressLine1 !== '' &&
                                    userData.billingAddress.addressLine2 !== '' &&
                                    ', '}
                                  {countryCode === 'IN'
                                    ? userData.billingAddress.addressLine2
                                    : userData.billingAddress.addressLine1}{' '}
                                </div>
                                <div className='text -size-l bottom-space'>
                                  {userData?.billingAddress?.cityName}
                                  {userData?.billingAddress?.cityName && ', '}
                                  {userData.billingAddress.districtName}
                                  {userData.billingAddress.districtName && ', '}
                                  {userData.billingAddress.stateCode}
                                  {userData.billingAddress.stateCode && ', '}
                                  {userData?.billingAddress?.stateName
                                    ?.split('(')[0]
                                    ?.trim()}
                                  {userData?.billingAddress?.stateName && ', '}
                                  {billingCountry}
                                  {billingCountry && ', '}
                                  {userData.billingAddress.postalCode}{' '}
                                </div>
                                <div className='text -size-l bottom-space'>
                                  Ph {userData.billingAddress.contactNumber}
                                  {userData.billingAddress.contactNumber !== '' &&
                                    userData.billingAddress.alternateNo !== '' &&
                                    ','}
                                  {userData.billingAddress.alternateNo}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='expansion-display-type'>
                        <div className='-size-m'>
                          {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.CONTACT_NO')}
                        </div>
                        <div className='text -size-l'>
                          {userData !== undefined &&
                            userData !== null &&
                            userData != '' &&
                            userData.contactDetails !== undefined &&
                            userData.contactDetails !== null &&
                            userData.contactDetails !== '' &&
                            userData.contactDetails.contactNo}{' '}
                        </div>
                      </div>
                      <div className='expansion-display-type'>
                        <div className='-size-m'>
                          {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.COMPANY_EMAIL_ID')}
                        </div>
                        <div className='text -size-l'>
                          {userData !== undefined &&
                            userData !== null &&
                            userData != '' &&
                            userData.contactDetails !== undefined &&
                            userData.contactDetails !== null &&
                            userData.contactDetails !== '' &&
                            userData.contactDetails.emailId}{' '}
                        </div>
                      </div>
                      <div className='expansion-display-type'>
                        <div className='-size-m'>
                          {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.COUNTRY')}
                        </div>
                        <div className='text -size-l'> {billingCountry} </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {countryCode !== 'DE' &&
                countryCode !== 'DK' &&
                countryCode !== 'NL' &&
                countryCode !== 'SE' &&
                countryCode !== 'LU' && (
                  <>
                    <h3 className='profile-heading highlight -size-l'>
                      {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.DOCUMENTS')}
                    </h3>
                    <div className='profile-styling'>
                      <div className='item-3'>
                        {countryCode === 'IN' ? (
                          <>
                            {documentSection(panFiles)}
                            {documentSection(gstFiles)}
                          </>
                        ) : (
                          // <>
                          //   {documentSection(vatFiles)}
                          //   {documentSection(otherFiles)}
                          // </>
                          <>{documentSection(w9Files)}</>
                        )}
                      </div>
                    </div>
                  </>
                )}
            </div>
            {!isInternalSellerTenant && (
              <>
                <div className='profile-account-deletion'>
                  <SecondaryButton
                    label=''
                    className='profile-account-request'
                    onClick={() => {
                      setChooseDeletionModal(true);
                      //GA-800
                      trackEvent(
                        'profile',
                        GAEventAction.clicked,
                        'Request for Account Deletion'
                      );
                    }}
                  >
                    <i className='a-icon boschicon-bosch-ic-user-remove'></i>
                    <span>
                      {i18next.t(
                        'DYNAMIC_FORM.VIEW_PROFILE.REQUEST_FOR_ACCOUNT_DELETION'
                      )}
                    </span>
                  </SecondaryButton>
                </div>
                <div className='profile-account-deletion'>
                  <SecondaryButton
                    label=''
                    className='profile-account-request'
                    onClick={handleLinkClick}
                  >
                    <i className='a-icon boschicon-bosch-ic-my-brand-frame -size-l'></i>
                    <span>Manage SingleKeyID</span>
                  </SecondaryButton>
                </div>
              </>
            )}
            {chooseDeletionModal && (
              <ConfirmModal
                className=''
                deletionOfAccounts
                deleteMSaccountButton={i18next.t(
                  'DYNAMIC_FORM.VIEW_PROFILE.DELETE_ACCOUNTS_FROM_MARKETPLACE'
                )}
                // deleteCIAMaccount={i18next.t('DYNAMIC_FORM.VIEW_PROFILE.DELETE_ACCOUNT_BOSCH')}
                heading={i18next.t('DYNAMIC_FORM.VIEW_PROFILE.CHOOSE_DELETE_OPTION')}
                modalContent={[
                  <React.Fragment key='delete-account-title'>
                    <div className='highlight'>
                      {i18next.t(
                        'DYNAMIC_FORM.VIEW_PROFILE.DELETE_ACCOUNTS_FROM_MARKETPLACE'
                      )}
                    </div>
                  </React.Fragment>,
                  <React.Fragment key='delete-account-description'>
                    {i18next.t(
                      'DYNAMIC_FORM.VIEW_PROFILE.DELETE_MARKETPLACE_ACCOUNT'
                    )}
                  </React.Fragment>
                ]}
                // modalContent2={[
                //   <React.Fragment key='delete-accounts-title'>
                //     <div className='highlight'>
                //       {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.DELETE_ACCOUNTS_FROM_BOSCH_PLATFORM')}
                //     </div>
                //   </React.Fragment>,
                //   <React.Fragment key='delete-accounts-description'>
                //     {i18next.t('DYNAMIC_FORM.VIEW_PROFILE.DELETE_MARKETPLACE_ALL_ACCOUNT')}
                //   </React.Fragment>
                // ]}
                handleDeleteCIAMaccount={handleDeleteCIAMaccount}
                handleDeleteMSaccount={handleDeleteMSaccount}
                callback={() => setChooseDeletionModal(false)}
                confirmationModalLoader={loader}
                disableEscapeKeyDown
                noAutoCapitalization
              />
            )}
            {openInfoModal && (
              <InfoModal
                heading={i18next.t('DYNAMIC_FORM.VIEW_PROFILE.CANNOT_DELETE')}
                modalContent={i18next.t(
                  'DYNAMIC_FORM.VIEW_PROFILE.ACCOUNT_CANNOT_DELETE'
                )}
                okBtnAction={okBtnAction}
                onClose={closeInfoPopup}
                category='warning'
              ></InfoModal>
            )}
            {successSellerAccountDeletionAlert.flag && (
              <SnackBar
                severity={successSellerAccountDeletionAlert.serverity}
                changeState={() =>
                  setSuccessSellerAccountDeletionAlert((prevState) => ({
                    ...prevState,
                    flag: false,
                    message: '',
                    serverity: ''
                  }))
                }
                message={successSellerAccountDeletionAlert.message}
              />
            )}
          </>
        ) : (
          <>
            <div className='bg-grey-profile'>
              <div className='profile-styling'>
                <div className='user-heading -size-xl text'>
                  {`${firstName} ${lastName}`}
                  {isSalesRep && (
                    <div className='approved-status-container'>
                      <i className='a-icon boschicon-bosch-ic-alert-success-filled status-checked'></i>
                    </div>
                  )}
                </div>
                <div className='mail-text item sales-rep-margin'>
                  <h5>{i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MAIL_ID')} :</h5>
                  <p className='label-text -size-l'>{userEmailId}</p>
                </div>
                <div className='number-text item sales-rep-margin'>
                  <h5>{i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MOBILE_NO')} :</h5>
                  <p className='label-text -size-l'>
                    {viewUserCompanyStatus?.user?.contactNo ?? '-'}
                  </p>
                </div>
                <div className='number-text item sales-rep-margin'>
                  <h5>{i18next.t('DYNAMIC_FORM.SHARED.ROLE')} :</h5>
                  <p className='label-text -size-l'>
                    {i18next.t('LANDING_PAGE.SALES_REPRESENTATIVE')}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </SideBar>
    </React.Fragment>
  );
};

const mapStateToProps = (redux_state) => ({
  viewUserCompanyStatus: redux_state.CompanyDetailsReducer.viewUserCompanyStatus,
  regionSpecificCountryList: redux_state.CountryReducer.regionSpecificCountryList,
  viewMSCompanyDetails: redux_state.CompanyDetailsReducer.viewMSCompanyDetails,
  accessToken: redux_state.loginReducer.accessToken,
  user: redux_state.loginReducer.user
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      retrieveMSCompanyDetailsForViewProfile,
      retrieveUserCompanyStatusForViewProfile,
      getRegionSpecificCountryList,
      documentDownload,
      regenerateAAATokens
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
