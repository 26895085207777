import axios from 'axios';
import { USER_DETAILS_ACTION } from '../configuration';

export function getStateList() {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: USER_DETAILS_ACTION.STATE_LIST + `?countryCode=IN`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}
