import React from 'react';
import LoginProvider from '../components/LoginProvider';
import HeaderLogin from '../components/BaseLayout/HeaderLogin';
import Footer from '../components/BaseLayout/footer';
import { logout } from '../actions/LoginAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import loading from '../actions/LoaderAction';
import axios from 'axios';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        self.props.loading(true);
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.props.loading(false);
        return response;
      },
      function (error) {
        self.props.loading(false);
        return Promise.reject(error);
      }
    );
  }

  signOut = () => {
    this.props.logout();
  };

  render() {
    return (
      <React.Fragment>
        {(() => {
          if (this.props.location.pathname === '/logout') {
            this.signOut();
          }
        })()}
        <HeaderLogin />
        <LoginProvider {...this.props} />
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  loader: redux_state.LoaderReducer
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      loading
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
