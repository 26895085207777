import { IS_MKOSINT_TENANT } from '../actions/configuration';
import validator from '../validator';

class FormValidator {
  constructor(validations) {
    // validations is an array of validation rules specific to a form
    this.validations = validations;
  }

  getMkosintFieldStateValue = (state, rule) => {
    if (rule.field.includes('-')) {
      const fieldSplit = rule.field.split('-');
      return state[rule.obj][fieldSplit[0]][fieldSplit[1]];
    } else {
      return state[rule.obj][rule.field];
    }
  };

  validate(formField, obj, state) {
    // start out assuming valid
    let validation = state.validation;
    if (formField !== '' && formField !== 'all') {
      validation.prodInfo[obj][formField] = { isInvalid: false, message: '' };
    }
    validation.isValid = true;
    // for each validation rule
    this.validations.forEach((rule) => {
      let stateVal = '';
      stateVal = IS_MKOSINT_TENANT
        ? this.getMkosintFieldStateValue(state, rule)
        : state[rule.obj][rule.field];
      if (stateVal === undefined) {
        return;
      }
      const validation_method =
        typeof rule.method === 'string' ? validator[rule.method] : rule.method;
      const field_value = stateVal?.toString();
      const args = rule.args || [];

      if (validation_method(field_value, ...args)) {
        validation.isValid = false;
      }
      // if the field hasn't already been marked invalid by an earlier rule
      if (
        !validation.prodInfo[rule.obj][rule.field]?.isInvalid &&
        (formField === rule.field || formField === 'all') &&
        validation_method(field_value, ...args, state)
      ) {
        // determine the field value, the method to invoke and optional args from
        // the rule definition
        // call the validation_method with the current field value as the first
        // argument, any additional arguments, and the whole state as a final
        // argument.  If the result doesn't match the rule.validWhen property,
        // then modify the validation object for the field and set the isValid
        // field to false
        validation.prodInfo[rule.obj][rule.field] = {
          isInvalid: true,
          message: rule.message
        };
      }
    });
    return validation;
  }

  valid() {
    const prodInfo = {
      prodBasicInfo: {},
      prodDetailedInfo: {}
    };
    this.validations.map(
      (rule) => (prodInfo[rule.obj][rule.field] = { isInvalid: false, message: '' })
    );
    return { isValid: true, prodInfo };
  }

  validPlan() {
    const prodInfo = {
      currentPlan: {}
    };
    this.validations.map(
      (rule) => (prodInfo[rule.obj][rule.field] = { isInvalid: false, message: '' })
    );
    return { isValid: true, prodInfo };
  }

  validForm() {
    const prodInfo = {
      registrationForm: {}
    };
    this.validations.map((rule) => {
      prodInfo[rule.obj][rule.field] = { isInvalid: false, message: '' };
      return true;
    });
    return { isValid: true, prodInfo };
  }
}

export default FormValidator;
