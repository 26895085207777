const initialState = {
  skuIdApi: [],
  generateSkuApi: [],
  skuAdminDetail: [],
  productApproval: [],
  productView: {}
};

const SkuiIdGenerateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SKU_ID':
      return Object.assign({}, state, { skuIdApi: action.payload });
    case 'POST_SKU_API':
      return Object.assign({}, state, { generateSkuApi: action.payload });
    case 'POST_SKU_ADMIN_DETAILS':
      return Object.assign({}, state, { skuAdminDetail: action.payload });
    case 'PRODUCT_APPROVAL':
      return Object.assign({}, state, { productApproval: action.payload });
    case 'PRODUCT_VIEW':
      return Object.assign({}, state, { productView: action.payload });
    default:
      return state;
  }
};

export default SkuiIdGenerateReducer;
