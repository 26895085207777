import React from 'react';

import MediaUpload from './media-upload';
import Validation from '../validation';
import i18next from 'i18next';

const CoverUpload = ({ placeHolder, ...props }) => {
  const acceptsFormat = `${i18next.t('DYNAMIC_FORM.FORMATS.JPG')}, ${i18next.t('DYNAMIC_FORM.FORMATS.JPEG')} and  ${i18next.t('DYNAMIC_FORM.FORMATS.PNG')}`;
  const fileCount = 1;
  const fileSize = `5${i18next.t('DYNAMIC_FORM.FORMATS.MB')}`;
  return (
    <MediaUpload
      {...props}
      iconLabel={placeHolder}
      borderStyle='rectangle'
      acceptsFormat={acceptsFormat}
      fileCount={fileCount}
      fileSize={fileSize}
      validations={Validation.coverImage}
    />
  );
};

export default CoverUpload;
