import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const CategoryHighlighter = ({ category, categoryColor }) => {
  if (!category) return null;

  return (
    <span
      className='category-tag'
      style={
        categoryColor && {
          background: `${categoryColor} 0% 0% no-repeat padding-box`
        }
      }
    >
      {category}
    </span>
  );
};

CategoryHighlighter.propTypes = {
  category: PropTypes.string,
  categoryColor: PropTypes.string
};

CategoryHighlighter.defaultProptypes = {
  category: '',
  categoryColor: 'transparent'
};

export default CategoryHighlighter;
