import axios from 'axios';
import { GET_ACCESS_KEY, UPDATE_PART_NUMBER } from '../../actions/configuration';
import * as ACTION_TYPES from '../ActionTypes';

export function getAccessKey() {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    return axios
      .get(GET_ACCESS_KEY, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.GET_ACCESS_KEY,
          payload: response.data
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      });
  };
}

export const resetAccessKey = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.RESET_ACCESS_KEY,
      payload: ''
    });
  };
};

export const updatePartNumber = (payload) => {
  return (dispatch) =>
    axios
      .post(UPDATE_PART_NUMBER, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.UPDATE_PART_NUMBER,
          payload: {
            ...response.data,
            status: response.status
          }
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      });
};

export function getPartNumber(productId) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    axios({
      method: 'GET',
      url: UPDATE_PART_NUMBER + `/${productId}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.PART_NUMBER_DATA,
          payload: response.data
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
        return response.data;
      })
      .catch(() => {
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      });
  };
}
