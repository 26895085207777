import React, { Component } from 'react';
import { Divider, Typography, Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../Shared/Inputs/Button';
import '../api-throttling.scss';
import Images from '../../../../assets/img';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import Snackbar from '../../../Shared/Utils/SnackBar';
import isEmpty from '../../../../validator/isEmpty';
import ToolTip from '../../../Shared/Tooltip';

const useStyles = (theme) => ({
  buttonAligning: {
    float: 'right'
  },
  cardHeading: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

class APIThrottlingPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPolicies: true,
      policyId: '',
      openConfirmModal: false,
      deleteIndex: 0
    };
  }

  setShowPolicies = (value, policyId) => {
    this.setState({
      policyId: policyId,
      showPolicies: value
    });
    if (value === true) {
      this.props.getThrottlingPolicies();
    }
    this.props.history.push({
      pathname: '/dashboard/myproducts/myapi/apithrottling/addthrottlingpolicy',
      state: { ...this.props.location.state, policyId: policyId }
    });
  };

  editPolicy = (policyId) => {
    this.setState({
      policyId: policyId,
      showPolicies: false
    });
    this.props.getThrottlePolicyDetails(policyId);
    this.props.history.push({
      pathname: '/dashboard/myproducts/myapi/apithrottling/addthrottlingpolicy',
      state: { ...this.props.location.state, policyId: policyId }
    });
  };

  deletePolicy = (policyId) => {
    this.setState({
      policyId: policyId,
      openConfirmModal: true,
      confirmModalText:
        'On removing, all the throttling conditions will be removed from the API.',
      modalHeading: 'Do you really want to remove API from this throttling policy?'
    });
  };

  okBtnAction = () => {
    this.props.deleteThrottlePolicy(this.state.policyId).then(() => {
      if (isEmpty(this.props.deletePolicyError)) {
        this.setState({
          snackBarType: 'success',
          snackBarMsg: 'Deleted Successfully',
          displaySnackBar: true
        });
      } else {
        this.setState({
          snackBarType: 'error',
          snackBarMsg: this.props.deletePolicyError.message,
          displaySnackBar: true
        });
      }
    });
  };

  changeState = () => {
    this.setState({
      displaySnackBar: false
    });
  };

  handleOpenConfirmModal = () => {
    this.setState({ openConfirmModal: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.displaySnackBar && (
          <Snackbar
            severity={this.state.snackBarType}
            message={this.state.snackBarMsg}
            changeState={this.changeState}
          />
        )}
        <React.Fragment>
          <Grid container sm={12}>
            <Grid item sm={10}>
              <Box m={2}>
                <Typography component='h5' variant='h5'>
                  <b>Advanced API Throttling Policies</b>
                </Typography>
              </Box>
            </Grid>

            <Grid item sm={2}>
              <Box className={classes.buttonAligning} m={1}>
                <Button
                  variant='contained'
                  label='+ Add Policy'
                  onClick={() => this.setShowPolicies(false, '')}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider variant='middle' />
          <Box m={2}>
            <Grid container spacing={3}>
              {this.props.throttlingPolicies.map((card) => {
                return (
                  <Grid item sm={3} key={card.throttlePolicyName}>
                    <Card className='card-style'>
                      <CardMedia
                        className='card-image'
                        image={Images.apiThrottling.throttlingCardBg}
                        title={card.throttlePolicyName}
                        alt={card.throttlePolicyName}
                        component='img'
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant='h6'
                          component='h6'
                          className={classes.cardHeading}
                        >
                          <b>{card.throttlePolicyName}</b>
                        </Typography>
                        <Box className='card-text'>
                          <Typography
                            variant='body2'
                            color='textSecondary'
                            component='p'
                          >
                            {card.description}
                          </Typography>
                        </Box>
                      </CardContent>
                      <Box align='center'>
                        <ToolTip title='Edit Policy'>
                          <EditOutlinedIcon
                            size='small'
                            className='icon-spacing cursor-pointer'
                            onClick={() => this.editPolicy(card.throttlePolicyId)}
                          />
                        </ToolTip>
                        <ToolTip title='Delete Policy'>
                          <DeleteOutlinedIcon
                            size='small'
                            className='cursor-pointer'
                            onClick={() => this.deletePolicy(card.throttlePolicyId)}
                          />
                        </ToolTip>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {this.state.openConfirmModal && (
            <ConfirmModal
              modalContent={this.state.confirmModalText}
              heading={this.state.modalHeading}
              callback={this.handleOpenConfirmModal}
              okBtnAction={this.okBtnAction}
              index={this.state.deleteIndex}
            ></ConfirmModal>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(APIThrottlingPolicies);
