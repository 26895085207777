import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
// Hiding Manage alerts for BTE event
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import StatisticsIcon from '@material-ui/icons/Equalizer';
import CloudIcon from '@material-ui/icons/Cloud';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import IdConstants from './idConstants';
import Images from '../assets/img';
import Icon from '@material-ui/core/Icon';
import HelpIcon from '@material-ui/icons/Help';

const navigationOptions = [
  /*
        sidebarKey: add unique key, so that to highlight in left navigation it will be selected properly
        hasSubMenus: boolean - If any subMenus are there then add this flag
        subMenus: array - array of submenu items
        subItemlevel2: array - level2 submenu items
        possibleUrls: include common pathname in all possible routes from selected sidebar selection
        possible to move the menuitems position anywhere in it's current tree level, Not depending on index's

        currently side navigation supports only level2 submenu's, If we want to expand then add same logic like subItemlevel2
    */
  {
    sidebarKey: IdConstants.sidebarConstants.dashboard.key,
    title: IdConstants.sidebarConstants.dashboard.title,
    url: '/dashboard',
    icon: <HomeIcon />,
    hasSubMenus: false,
    disabled: false
  },
  {
    sidebarKey: IdConstants.sidebarConstants.myProducts.key,
    title: IdConstants.sidebarConstants.myProducts.title,
    icon: <AppsIcon />,
    url: '/dashboard/myproducts',
    hasSubMenus: true,
    disabled: false,
    subMenus: [
      {
        sidebarKey: IdConstants.sidebarConstants.apiProduct.key,
        title: IdConstants.sidebarConstants.apiProduct.title,
        categoryId: IdConstants.api.categoryId,
        icon: <AccountTreeIcon />,
        url: '/dashboard/myproducts/myapi/all',
        possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        disabled: false,
        visibility: true,
        subItemlevel2: [
          {
            sidebarKey: IdConstants.sidebarConstants.apiStatistics.key,
            title: IdConstants.sidebarConstants.apiStatistics.title,
            url: '/dashboard/myproducts/myapi/apistatistics',
            icon: <StatisticsIcon />,
            hasSubMenus: false,
            disabled: false,
            visibility: true
          }
          // {
          //     sidebarKey: IdConstants.sidebarConstants.throttlePolicy.key,
          //     title: IdConstants.sidebarConstants.throttlePolicy.title,
          //     url: '/dashboard/myproducts/myapi/apithrottling',
          //     possibleUrls: ['/dashboard/myproducts/myapi/apithrottling/addthrottlingpolicy'],
          //     icon: <AssignmentOutlinedIcon />,
          //     key: 'apithrottling',
          //     hasSubMenus: false,
          //     disabled: false,
          //     visibility:false
          // },
          // Hiding Manage alerts for BTE event
          // {
          //     sidebarKey: IdConstants.sidebarConstants.apiAlerts.key,
          //     title: IdConstants.sidebarConstants.apiAlerts.title,
          //     url: '/dashboard/myproducts/myapi/apialerts',
          //     icon: <SettingsIcon />,
          //     hasSubMenus: false,
          //     disabled: false,
          //     visibility:true
          // }
        ]
      },
      {
        sidebarKey: IdConstants.sidebarConstants.hardwareProduct.key,
        title: IdConstants.sidebarConstants.hardwareProduct.title,
        categoryId: IdConstants.api.categoryId,
        icon: <AccountTreeIcon />,
        url: '/dashboard/hardware',
        possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        disabled: false,
        visibility: true
      },
      {
        sidebarKey: IdConstants.sidebarConstants.cloudInfra.key,
        title: IdConstants.sidebarConstants.cloudInfra.title,
        // url: '/dashboard/myproducts/myCloudInfra/all',
        // possibleUrls: ['/dashboard/myproducts/myCloudInfra'],
        // key: 'myCloudInfra-all',
        icon: (
          <Icon>
            <img src={Images.cloudInfra} />
          </Icon>
        ),
        hasSubMenus: false,
        disabled: true,
        visibility: false
      },
      {
        title: IdConstants.sidebarConstants.dataProduct.title,
        sidebarKey: IdConstants.sidebarConstants.dataProduct.key,
        icon: <CloudIcon />,
        hasSubMenus: false,
        disabled: true,
        visibility: false
      }
    ]
  },
  {
    sidebarKey: IdConstants.sidebarConstants.apiAlerts,
    // title: IdConstants.sidebarConstants.myProducts.title,
    title: 'Order Management',
    icon: (
      <i className='a-icon boschicon-bosch-ic-delivery-checkmark' title='cart' />
    ),
    url: '/dashboard/order-management',
    hasSubMenus: true,
    disabled: false,
    displayOptions: false,
    subMenus: [
      {
        sidebarKey: IdConstants.sidebarConstants.myOrders.key,
        title: IdConstants.sidebarConstants.myOrders.title,
        categoryId: IdConstants.api.categoryId,
        icon: (
          <i className='a-icon boschicon-bosch-ic-box-checkmark' title='My Orders' />
        ),
        url: '/dashboard/order-management',
        possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: true
      },
      {
        sidebarKey: IdConstants.sidebarConstants.orderHistory.key,
        title: IdConstants.sidebarConstants.orderHistory.title,
        categoryId: IdConstants.api.categoryId,
        icon: (
          <i
            className='a-icon boschicon-bosch-ic-consumer-goods'
            title='Order History'
          />
        ),
        url: '/dashboard/order-history',
        possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: true
      }
    ]
  },
  {
    sidebarKey: IdConstants.sidebarConstants.faq.key,
    title: IdConstants.sidebarConstants.faq.title,
    url: '/dashboard/faq',
    icon: <HelpIcon />,
    hasSubMenus: false,
    disabled: false
  },
  {
    sidebarKey: IdConstants.sidebarConstants.apiAlerts,
    // title: IdConstants.sidebarConstants.myProducts.title,
    title: 'Order Management',
    icon: (
      <i className='a-icon boschicon-bosch-ic-delivery-checkmark' title='cart' />
    ),
    //url: '/dashboard/order-management',
    hasSubMenus: true,
    disabled: false,
    displayOptions: false,
    subMenus: [
      {
        sidebarKey: 'MyOrdersKey#12$34 ',
        title: 'My Orders',
        // sidebarKey: IdConstants.sidebarConstants.apiProduct.key,
        // title: IdConstants.sidebarConstants.apiProduct.title,
        categoryId: IdConstants.api.categoryId,
        icon: (
          <i className='a-icon boschicon-bosch-ic-box-checkmark' title='My Orders' />
        ),
        url: '/dashboard/order-management',
        // possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: true
      },
      {
        sidebarKey: IdConstants.sidebarConstants.hardwareProduct.key,
        title: 'Order History',
        // title: IdConstants.sidebarConstants.hardwareProduct.title,
        categoryId: IdConstants.api.categoryId,
        icon: (
          <i
            className='a-icon boschicon-bosch-ic-consumer-goods'
            title='Order History'
          />
        ),
        url: '/dashboard/order-history',
        possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: true
      }
    ]
  },
  {
    sidebarKey: IdConstants.sidebarConstants.hardwareProduct.key,
    title: 'Order History',
    // title: IdConstants.sidebarConstants.hardwareProduct.title,
    categoryId: IdConstants.api.categoryId,
    icon: (
      <i
        className='a-icon boschicon-bosch-ic-consumer-goods'
        title='Order History'
      />
    ),
    url: '/dashboard/order-history',
    possibleUrls: ['/dashboard/myproducts/myapi'],
    key: 'myapi-all',
    hasSubMenus: true,
    displayOptions: false,
    disabled: false,
    visibility: true
  }
];

export default navigationOptions;
