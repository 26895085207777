import FormValidator from '../validator/FormValidator';
import i18next from 'i18next';
import '../i18n';

export const l1SupportValidator = new FormValidator([
  {
    field: 'lISupport-name',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.EMPTY')
  },
  {
    field: 'lISupport-name',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.INVALID'),
    args: ['ALPHABETS_SPACES']
  },
  {
    field: 'lISupport-email',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.EMPTY')
  },
  {
    field: 'lISupport-email',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.INVALID'),
    args: ['EMAIL_ID']
  },
  {
    field: 'lISupport-contactUrl',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.EMPTY')
  },
  {
    field: 'lISupport-contactUrl',
    obj: 'prodDetailedInfo',
    method: 'isURL',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.INVALID')
  },
  {
    field: 'lISupport-contactNumber',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.EMPTY')
  },
  {
    field: 'lISupport-contactNumber',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.INVALID'),
    args: ['TELEPHONE_REGEX']
  }
]);

export const l1EscalationValidator = new FormValidator([
  {
    field: 'lIEscalation-name',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.EMPTY')
  },
  {
    field: 'lIEscalation-name',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.INVALID'),
    args: ['ALPHABETS_SPACES']
  },
  {
    field: 'lIEscalation-email',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.EMPTY')
  },
  {
    field: 'lIEscalation-email',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.INVALID'),
    args: ['EMAIL_ID']
  },
  {
    field: 'lIEscalation-contactUrl',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.EMPTY')
  },
  {
    field: 'lIEscalation-contactUrl',
    obj: 'prodDetailedInfo',
    method: 'isURL',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.INVALID')
  },
  {
    field: 'lIEscalation-contactNumber',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.EMPTY')
  },
  {
    field: 'lIEscalation-contactNumber',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.INVALID'),
    args: ['TELEPHONE_REGEX']
  }
]);

export const l2EscalationValidator = new FormValidator([
  {
    field: 'lIIEscalation-name',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.EMPTY')
  },
  {
    field: 'lIIEscalation-name',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.INVALID'),
    args: ['ALPHABETS_SPACES']
  },
  {
    field: 'lIIEscalation-email',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.EMPTY')
  },
  {
    field: 'lIIEscalation-email',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.INVALID'),
    args: ['EMAIL_ID']
  },
  {
    field: 'lIIEscalation-contactUrl',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.EMPTY')
  },
  {
    field: 'lIIEscalation-contactUrl',
    obj: 'prodDetailedInfo',
    method: 'isURL',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.INVALID')
  },
  {
    field: 'lIIEscalation-contactNumber',
    obj: 'prodDetailedInfo',
    method: 'isEmpty',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.EMPTY')
  },
  {
    field: 'lIIEscalation-contactNumber',
    obj: 'prodDetailedInfo',
    method: 'isDataType',
    validWhen: false,
    message: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.INVALID'),
    args: ['TELEPHONE_REGEX']
  }
]);
