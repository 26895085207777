export const GAEventAction = {
  clicked: 'clicked',
  loaded: 'loaded',
  uploaded: 'uploaded',
  added: 'added',
  productSubmitted: 'product_submitted',
  productPublished: 'product_published',
  productStockUpdated: 'product_stock_updated',
  quotationSubmitted: 'quotation_submitted',
  signinSuccess: 'signin_success',
  signoutSuccess: 'signout_success'
};

export const GAConstants = {
  isTriggerGAEvent: true
};
