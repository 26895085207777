import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  PrimaryButton,
  SecondaryButton,
  IconButton
} from '../../packages/html-components';

import Images from '../../../assets/img';

import i18next from 'i18next';

import './index.scss';
import VisibilitySettings from '../../../components/Shared/VisibilitySetting';

const dialogImage = (image, isSuccess) => {
  if (image) return image;
  else {
    return isSuccess ? Images.successIcon : Images.errorImage;
  }
};

const dialogAlertImage = (image, isSuccess) => {
  if (image) return image;
  else {
    return isSuccess ? Images.alertSuccess : Images.alertError;
  }
};

const ModalCard = ({
  title,
  subTitle,
  handleCancel,
  handleConfirm,
  isSuccess,
  isCloseIcon,
  secondaryLabel,
  primaryLabel,
  isSecondaryBtn,
  children,
  cssName,
  image,
  isVisibilityModal,
  isVisible,
  handleIsVisible
}) => {
  return (
    <Fragment>
      <div
        className={`dialog_modal__${isVisibilityModal ? 'visibility_modal' : isSuccess ? 'success' : 'error'}`}
      >
        <img
          className={`dialog_modal__${isSuccess ? 'success' : 'error'}__alert`}
          src={dialogAlertImage(image, isSuccess)}
        />
        <img
          className={`dialog_modal__${isSuccess ? 'success' : 'error'}__image`}
          src={dialogImage(image, isSuccess)}
        />
        {isCloseIcon && (
          <IconButton
            label=''
            className={`dialog_modal__close_icon ${isVisibilityModal ? 'dialog_modal__close__icon_visibility_modal' : 'dialog_modal__close_icon_default'}`}
            iconCss='ui-ic-close'
            iconTitle={i18next.t('DYNAMIC_FORM.ICON_TITLE.CLOSE')}
            onClick={handleCancel}
            isIcon={true}
          />
        )}
      </div>
      {!isVisibilityModal && (
        <div className='dialog_alert__message'>
          {title && <p className='dialog_alert__message__title'> {title} </p>}
          {subTitle && <p className='dialog_alert__message__subtitle'>{subTitle}</p>}
        </div>
      )}
      {isVisibilityModal && (
        <div className='dialog_alert__message dialog_alert__message__visibility_settings'>
          <VisibilitySettings
            isVisibleData={isVisible}
            handleIsVisible={handleIsVisible}
            disabled={false}
          />
        </div>
      )}
      {children}
      <div className={`dialog_modal__message ${cssName}`}>
        <Fragment>
          {isSecondaryBtn && (
            <SecondaryButton onClick={handleCancel} label={secondaryLabel} />
          )}
          <PrimaryButton onClick={handleConfirm} label={primaryLabel} />
        </Fragment>
      </div>
    </Fragment>
  );
};

export default ModalCard;

ModalCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isSuccess: PropTypes.bool,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  isSecondaryBtn: PropTypes.bool,
  isCloseIcon: PropTypes.bool
};

ModalCard.defaultProps = {
  title: 'Successfully Hardware Submitted',
  subTitle: 'Product details is successfully submitted for approval.',
  isSuccess: false,
  primaryLabel: 'Yes',
  secondaryLabel: 'No',
  isSecondaryBtn: true,
  isCloseIcon: false
};
