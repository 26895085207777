import React from 'react';
import CollapsableSideBar from '../Shared/Navigation/CollapsableSideBar';
import Images from '../../../src/assets/img';
import './index.scss';

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className='bosch_strip'>
        <img src={Images.boschStrip} alt='bosch strip' />
      </div>
      <CollapsableSideBar {...props} />
    </React.Fragment>
  );
};

export default Sidebar;
