import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

import { getQuotationListing } from '../../../actions/QuotationAction';
import {
  Table,
  Tabs,
  Filter,
  EmptyPage
} from '../../../msreact-kit/packages/html-components';
import useHandleLoader from '../../../msreact-kit/hooks/useHandleLoader';
import { pageLimit, quoteStatus, tabStatus } from '../../../data/appConstants';
import Loader from '../../Shared/Utils/Loader';
import { convertUTCToLocalDateTime } from '../../../utils/date-time-utils';
import CategoryHighlighter from '../../order-management/category-highlight';
import variables from '../../../variables.module.scss';

import './index.scss';

const QuotationListing = () => {
  const [selectedTab, setSelectedTab] = useState(tabStatus.new.description);
  const [tableData, setTableData] = useState([]);
  const [filterId, setFilterId] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageLimit.INITIAL_PAGE);
  const [paginationData, setPaginationData] = useState(pageLimit.INITIAL_PAGE_COUNT);
  const [pageSize, setPageSize] = useState(pageLimit.MAX_PAGE_COUNT);
  const [subCategoryId, setSubCategoryId] = useState([]);

  const dispatch = useDispatch();
  const [handleLoader] = useHandleLoader();
  const history = useHistory();

  const { quotationList, loader } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader,
      quotationList: state.QuotationReducer.quotationList
    };
  });

  const handleChange = (item) => {
    setSelectedTab(item.id);
    setCurrentPage(pageLimit.INITIAL_PAGE_COUNT);
    setPaginationData({});
  };

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    // Default Status for NEW
    let quotationStatus = [
      quoteStatus.requested.description,
      quoteStatus.inDraft.description,
      quoteStatus.submitted.description
    ];
    if (selectedTab === tabStatus.completed.description) {
      quotationStatus = [
        quoteStatus.rejected.description,
        quoteStatus.expired.description,
        quoteStatus.approved.description
      ];
    }
    dispatch(getQuotationListing(pageSize, quotationStatus, currentPage, filterId));
  }, [pageSize, selectedTab, currentPage, filterId]);

  useEffect(() => {
    const formattedTableData = [];
    quotationList?.content?.forEach((element) => {
      const formattedData = {
        serviceName: element?.productSubCategory?.productSubCategoryName,
        buyerName: element?.buyerName,
        productName: element?.productName,
        submittedDate: convertUTCToLocalDateTime(element?.submittedDate),
        expiring: element?.expireIn,
        status: getStatusText(element?.status?.quotationStatusDescription),
        action: (
          <div className='quotation-listing__view-more'>
            <p
              className='quotation-listing__view-more__title'
              onClick={() => handleRedirect(element)}
            >
              {i18next.t('SHARED.VIEW_MORE')}
            </p>
          </div>
        )
      };
      formattedTableData.push(formattedData);
    });

    const listOfCategories = quotationList?.filter?.map((item) => {
      return {
        label: item?.displayName,
        id: item?.subCategoryId,
        isSelected: item?.selected,
        name: item?.displayName
      };
    });

    setTableData(formattedTableData);
    setPaginationData({
      totalPages: quotationList?.totalPage
    });
    setSubCategoryId(listOfCategories);
  }, [quotationList]);

  const getStatusText = (status) => {
    let color;
    let quotationStatus = '';

    if (i18next.exists(`DYNAMIC_FORM.SHARED.${status}`)) {
      quotationStatus = i18next.t(`DYNAMIC_FORM.SHARED.${status}`);
    }

    switch (status) {
      case quoteStatus.submitted.description:
      case quoteStatus.accepted.description:
        color = variables.statusSubmitted;
        break;
      case quoteStatus.expired.description:
        color = variables.statusInDraft;
        break;
      case quoteStatus.approved.description:
        quotationStatus = i18next.t('DYNAMIC_FORM.SHARED.ACCEPTED');
        color = variables.statusSubmitted;
        break;
      case quoteStatus.inDraft.description:
        quotationStatus = i18next.t('DYNAMIC_FORM.SHARED.IN_DRAFT');
        color = variables.statusInDraft;
        break;
      case quoteStatus.rejected.description:
        color = variables.statusRejected;
        break;
      case quoteStatus.requested.description:
        quotationStatus = i18next.t('DYNAMIC_FORM.SHARED.NEW');
        color = variables.statusRequested;
        break;
      default:
        color = variables.statusRequested;
        break;
    }

    return <CategoryHighlighter category={quotationStatus} categoryColor={color} />;
  };

  const handlePerPage = (event) => {
    setPageSize(event.value.value);
    setCurrentPage(pageLimit.INITIAL_PAGE_COUNT);
  };

  const handlePagination = (number) => {
    setCurrentPage(number);
  };

  const handleRedirect = (data) => {
    history.push({
      pathname: 'proposal-request/quotation-summary',
      state: {
        ...data
      }
    });
  };

  const handleFilter = (event) => {
    if (event.value) {
      setFilterId((prev) => [...prev, event.name]);
    } else {
      filterId.splice(filterId.indexOf(event.name), 1);
      setFilterId([...filterId]);
    }
  };

  const tabName = [
    {
      label: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEW'),
      id: tabStatus.new.description
    },
    {
      label: i18next.t('DYNAMIC_FORM.SHARED.COMPLETED'),
      id: tabStatus.completed.description
    }
  ];

  const tableHeaders = [
    {
      value: 'DYNAMIC_FORM.SHARED.SERVICE_NAME',
      key: 'serviceName',
      style: 'service-name__content'
    },
    {
      value: 'DYNAMIC_FORM.SHARED.BUYER_NAME',
      key: 'buyerName',
      style: 'buyer-name__content'
    },
    {
      value: 'DYNAMIC_FORM.SHARED.PRODUCT_NAME',
      key: 'productName',
      style: 'productid__content'
    },
    {
      value: 'DYNAMIC_FORM.SHARED.SUBMITTED_DATE',
      key: 'submittedDate',
      style: 'submitted-date__content'
    },
    {
      value: 'DYNAMIC_FORM.SHARED.EXPIRING_IN_DAYS',
      key: 'expiring',
      style: 'expiredby__content'
    },
    {
      value: 'DYNAMIC_FORM.SHARED.STATUS',
      key: 'status',
      style: 'status__content'
    },
    {
      value: 'DYNAMIC_FORM.SHARED.ACTIONS',
      key: 'action',
      style: 'view__content'
    }
  ];

  const renderQuotationTable = () => {
    if (tableData?.length > 0) {
      return (
        <div className='quotation-listing__table'>
          <Table
            rowData={tableHeaders}
            data={tableData}
            handlePerPage={handlePerPage}
            handlePagination={handlePagination}
            paginationData={paginationData}
            currentPage={currentPage}
          />
        </div>
      );
    } else if (!loader && tableData?.length === 0) {
      return (
        <div className='quotation-listing__empty'>
          <EmptyPage
            label=''
            disabled={false}
            showPrimaryButton={false}
            showNote={false}
            heading={i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.NO_DATA_FOUND')}
          />
        </div>
      );
    }
  };

  return (
    <div className='quotation-listing'>
      {loader && <Loader />}
      <div className='quotation-listing__container'>
        <p className='quotation-listing__container__title'>
          {i18next.t('QUOTATIONS.REQUEST_FOR_QUOTATIONS')}
        </p>
        <div className='quotation-listing__container__block'>
          <Filter
            handleFilter={handleFilter}
            data={subCategoryId}
            cssName='scroll'
          />
        </div>
      </div>
      <div className='quotation-listing__tabs'>
        <Tabs handleChange={handleChange} tabName={tabName} />
      </div>
      {renderQuotationTable()}
    </div>
  );
};

export default QuotationListing;
