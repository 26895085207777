import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

const CheckBox = ({
  displayName,
  className,
  description,
  handleChange,
  id,
  name,
  handleDisableBtn,
  checked,
  disabled
}) => {
  const handleCheckChange = (event) => {
    handleDisableBtn && handleDisableBtn(event.target.checked);

    const data = {
      value: event.target.checked,
      error: '',
      name: name
    };

    handleChange && handleChange(data);
  };

  return (
    <div className='a-checkbox'>
      <input
        type='checkbox'
        id={id}
        data-testid='checkbox-1'
        onChange={handleCheckChange}
        checked={checked}
        disabled={disabled}
      />
      <label className={className} htmlFor={id}>
        {displayName}
      </label>
      {description && (
        <p className='add-pricing-card__description'>
          {i18next.t('DYNAMIC_FORM.PRICING_PLAN.NOTE')} : {description}
        </p>
      )}
    </div>
  );
};

CheckBox.propTypes = {
  displayName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

CheckBox.defaultProps = {
  displayName: 'Checkbox',
  disabled: false,
  required: false
};

export default CheckBox;
