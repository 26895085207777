const idConstants = {
  tokens: {
    aaaJwtToken: 'aaaJwtToken',
    aaaRefreshToken: 'aaaRefreshToken',
    aaaIdToken: 'aaaIdToken',
    dcsJwtToken: 'dcsJwtToken',
    dcsIdToken: 'dcsIdToken',
    dcsRefreshToken: 'dcsRefreshToken',
    aaaVerifier: 'aaaVerifier',
    dcsVerifier: 'dcsVerifier',
    dcsCode: 'dcsCode',
    aaaCode: 'aaaCode'
  },
  countryCode: {
    countryId: 'f497f48f-0355-4bf6-9554-692b57613f9c',
    countryCode: 'IN'
  },
  metaData: {
    metaTag: 'meta',
    headTag: 'head',
    title: 'title',
    description: 'description',
    content: 'content'
  },
  pricing: {
    payPerCallBillingCycleType: 'PAY_PER_CALL',
    yearBillingCycleType: 'YEARLY',
    bandWidthRateLimitType: 'DATA_BANDWIDTH',
    smsRateLimitType: 'SMS',
    userRateLimitType: 'USER',
    deviceRateLimitType: 'DEVICE',
    msgRateLimitType: 'MESSAGE',
    monthTimeUnitCode: 'MONTHLY',
    hourBurstControl: 'REQUEST_PER_HOUR',
    minuteBurstControl: 'REQUEST_PER_MIN',
    secondBurstControl: 'REQUEST_PER_SEC',
    perReqHitSlabType: 'PER_REQUEST_HIT',
    oneTimePaymentSlabType: 'NOT_APPLICABLE',
    fullSlabType: 'FULL_SLAB',
    oneTimePurchase: 'ONE_TIME_PURCHASE'
  },
  oneTimeBillingType: {
    billingTypeCode: 'ONE_TIME_PURCHASE'
  },
  recurringBillingType: {
    billingTypeCode: 'RECURRING_PAYMENT'
  },
  freePlanBillingType: {
    billingTypeCode: 'FREE_PLAN'
  },
  fixedBillingType: {
    billingTypeCode: 'FIXED_PLAN'
  },
  recurringInvoiceBillingType: {
    billingTypeCode: 'RECURRING_INVOICE'
  },
  contactSalesBillingType: {
    billingTypeCode: 'CONTACT_SALES'
  },
  oneTimePostBillingType: {
    billingTypeCode: 'ONE_TIME_POST_PAYMENT'
  },
  grantTypes: [
    {
      id: '0ed6b316-f76f-4445-aa98-621abe868b5c',
      name: 'Client Credentials',
      grantTypeName: 'client_credentials'
    },
    {
      id: '98db6ce6-6aa2-404c-b255-c9f83b95d56d',
      name: 'Password Credentials',
      grantTypeName: 'password'
    },
    {
      id: '9ed0e066-9f3c-4873-abdb-ebb93bcc6d09',
      name: 'Refresh Token',
      grantTypeName: 'refresh_token'
    },
    {
      id: 'a2b967db-7b15-4979-a629-614597908645',
      name: 'Authorization Code',
      grantTypeName: 'authorization_code'
    },
    {
      id: 'a9073e7b-0a47-45cc-9965-bc4187761cb7',
      name: 'Device Code',
      grantTypeName: 'device_code'
    }
  ],

  authType: {
    oAuth2: '0a32c1c8-5da0-43fd-b7bb-bc3b6e5ddf0c',
    noAuth: '150dbafa-ae48-48c6-a97a-ae675635cdd0'
  },

  scopeId: 'a03a62d1-4128-4d04-9e16-8e466b201d87',
  method: {
    get: '57ac73ba-77e0-4a33-a929-3d65ab8f2fe7',
    post: 'a0e18101-8606-4fe1-bd0e-77bee764a76b',
    put: '616bb2f1-5dda-4dd5-bea9-6a2ee7e0bbe7',
    patch: '18f71415-2689-4c74-b984-f4a9792bf94b',
    delete: '3f0e9156-7e65-40b6-b33b-5d6585df1c82'
  },

  billingCycle: {
    payPerCall: {
      type: 'PAY_PER_CALL',
      name: 'Pay Per Call Plan'
    },
    yearly: {
      type: 'YEARLY',
      name: 'Yearly Plan'
    },
    monthly: {
      type: 'MONTHLY',
      name: 'Monthly'
    }
  },

  rateLimitTimeUnit: {
    daily: {
      type: 'DAILY',
      name: 'daily'
    },
    monthly: {
      type: 'MONTHLY',
      name: 'monthly'
    }
  },

  rateLimit: {
    dataBandwidth: {
      type: 'DATA_BANDWIDTH',
      name: 'Data Bandwidth'
    },
    requestCount: {
      type: 'REQUEST_COUNT',
      name: 'Request Count'
    },
    noMeasurement: {
      type: 'NO_MEASUREMENT',
      name: 'No Measurement'
    },
    sms: {
      type: 'SMS',
      name: 'SMS'
    },
    device: {
      type: 'DEVICE',
      name: 'Device'
    },
    user: {
      type: 'USER',
      name: 'user'
    },
    message: {
      type: 'MESSAGE',
      name: 'Message'
    }
  },
  bandwidth: {
    gb: {
      name: 'GB'
    },
    kb: {
      name: 'KB'
    },
    mb: {
      name: 'MB'
    }
  },
  login: {
    clientSecret: 'aeaa43b3-fc30-42c0-b3a1-92cdd0811d6d'
  },
  register: {
    inProcessStatus: 'fb2ca59a-2926-46be-b800-eb707d8cd5a8'
  },
  documentType: {
    techDocs: '6e952be4-12b0-4753-a0da-7591c618f51c',
    coverImg: '1e726a62-7df2-40b8-b85f-b27b700a0b0a',
    thumbnailImg: '87389ac9-3c7d-4b99-965b-aa2a224bea7f',
    imageFile: '738a0b13-2efd-428a-a3a7-58fbfff1f4c2',
    videoFile: 'd5e6c74b-8246-4cbb-9401-aeae8c98760b',
    videoLinks: 'd0152830-dfda-4110-a9fc-264e94b54b20',
    dataSampleFileDownload: '97527849-37ba-4154-a49e-7e112a16d657',
    productDocuments: 'eaaf9296-ea4d-4000-a97e-37b6c42bd8f0',
    productDocumentsCsv: '2e02a415-37f5-427c-9c96-64eec133a0d7',
    refundPolicyDocument: '1139adc0-7670-4e4b-89e5-3c170c9fb8cc',
    returnAndReplacementPolicyDocument: '21ce40d2-c11e-4561-af70-f7f03d9f6901',
    userManualDocument: '614ff7e1-f0ca-4a28-af13-9622410067f4',
    highlightsDocument: 'ef69bdde-b513-4831-a74c-686a1c0ef605',
    termsConditionsDocument: 'tcbb8b72-0fd8-4c26-bd8d-10f4755b0179',
    caseStudyDocument: '9412b952-0bed-41ed-9fc1-df19b7c674cc'
  },
  planType: {
    payPerCallId: '938081ef75e4de0a0175f398792f0065'
  },
  ratingFactorId: {
    factorId: 'd45f6ecc-240e-4bba-854f-b533db8ac669'
  },
  api: {
    categoryId: 'fd9f9032-3ece-4d33-a5df-3ecc24370ff8', // category id for api
    productCategoryName: 'API'
  },
  data: {
    categoryId: 'a667657b-a184-4363-af1a-9d415e419f44', // category id for data product
    productCategoryName: 'Data'
  },
  infrastructure: {
    categoryId: '06033e78-6863-49fa-be59-0908b79b8fdb', //category for service product
    productCategoryName: 'Infrastructure'
  },
  managedServices: {
    categoryId: '8abe03a8-eac5-4cd8-a10d-c5971b93c082',
    productCategoryName: 'Managed Services'
  },
  enterpriseLicensing: {
    categoryId: '89b0fd2b-7a34-4d1a-920a-94bf327411f0',
    productCategoryName: 'Enterprise Licensing'
  },
  softwareServices: {
    categoryId: '30f2e2a0-c7d8-4f46-b287-274d28cc6c41',
    productCategoryName: 'Software Services'
  },
  professionalServices: {
    categoryId: '4f43cd54-b8b7-412b-a69c-10c8e8c2c6f7',
    productCategoryName: 'Professional Services'
  },
  communicationServices: {
    categoryId: 'aa88d3ad-5b8e-4729-8420-df7f399672ca',
    productCategoryName: 'Communication Services'
  },
  solutions: {
    categoryId: '95499757-98b8-4f9a-bfa7-a5b8af72b8f3',
    productCategoryName: 'Solutions'
  },
  cloudServices: {
    categoryId: 'b2622d6b-0241-46df-a1d5-6e3a58740da7',
    productCategoryName: 'Cloud Services'
  },
  software: {
    categoryId: '457e3f4b-3076-4578-9c97-521ac69ec9e8',
    productCategoryName: 'Software'
  },
  services: {
    categoryId: '4ab7164a-b4b1-4c31-849d-8eb0aadebdd0',
    productCategoryName: 'Services'
  },
  sdkServices: {
    categoryId: '2f9f15dd-9ceb-4db4-b5bc-83842e222f49',
    productCategoryName: 'sdk-services'
  },
  applicationBreadCrumb: {
    name: 'Application',
    path: '/myaccount/myapplications/application/subscribed-apis'
  },
  registrationStatus: [
    {
      registrationStatusId: '9fc87dc9-f486-4b3a-80b2-dd6be6fab2fe',
      companyStatus: 'Awaiting Admin Approval'
    }
  ],
  addressType: [
    {
      addressTypeId: '38367a60-ace2-4180-98dc-e86e4b907a63',
      addressType: 'BILLING'
    },
    {
      addressTypeId: '346d573a-7e2b-4928-b265-f8d160740d46',
      addressType: 'SHIPPING'
    }
  ],
  docId: {
    pan: '961603dd-1f1e-4461-9f8e-7cf73ca4dd6a',
    gst: '62486d06-6c75-424b-85e0-0bd6ab58b56c'
  },
  drafted: {
    statusId: '742cffe3-4754-4479-9369-f72368674bb9'
  },
  submitted: {
    statusId: '6d78da39-181d-4ead-b5ff-b11559cd171a'
  },
  published: {
    statusId: 'ff841ba0-2141-4c57-b26a-d5af00e03782',
    name: 'PUBLISHED'
  },
  feature: {
    categoryId: 'dc2044cc-1dcf-4771-a922-320af18e273a',
    filter: {
      vehicleType: '0ecdadbc-11f0-4e0e-a741-551457295a82',
      country: '21019628-5363-4431-ab2d-d70aa339b9e1',
      brand: '340c9628-5363-4431-ab2d-d70aa339b9e1',
      model: '741f201d-24f6-4e6b-ab21-a469ef733ce7'
    },
    pricing: {
      yearBillingCycleType: 'YEARLY_SUBSCRIPTION',
      monthBillingCycleType: 'MONTHLY_SUBSCRIPTION',
      oneTimePurchaseBillingCycleType: 'ONE_TIME_PURCHASE'
    }
  },
  agreementTypes: {
    termsConditions: '36a1b008-6b44-4905-9370-007939a96292',
    privacyPolicies: 'f439e945-c36e-4a91-979d-c2f26ba39f17',
    eula: '11f3a013-9ca2-4020-a45d-40c619bbe49e'
  },
  auditLogsModuleIds: {
    razorpayModuleId: 'd213c00e-c773-4e0e-b562-1ca599e897e0'
  },
  errorCodes: {
    gst: 'E000064',
    gstAddress: 'E000065',
    pan: 'E000066',
    panFullName: 'E000068',
    panDoc: 'E000069',
    deregError: 'E000070',
    networkError: 'Network Error',
    duplicateGST: 'E000082'
  },
  order: {
    statusId: {
      suspended: {
        id: '2cc16cbf-9d1e-4de5-828a-825d5bd98712',
        name: 'SUSPENDED'
      },
      subscribed: {
        id: '62b5ac5e-8c76-4bf3-996c-05b79c66534b',
        name: 'SUBSCRIBED'
      },
      cancellationInProgress: {
        id: 'ba71f4c8-ecc0-42a5-935f-c6d68c0e450a',
        name: 'CANCELLATIONINPROGRESS'
      },
      blocked: {
        id: 'e84f47ea-d3ae-40e0-be90-2942f2969268',
        name: 'BLOCKED'
      },
      unSubscribed: {
        id: 'f649c471-c936-4c02-8364-f76a84ded0f6',
        name: 'UNSUBSCRIBED'
      }
    }
  },
  hardware: {
    categoryId: 'e298c066-96db-477a-883e-4d42f15d91f1', // category id for hardware product
    productCategoryName: 'Hardware'
  },
  otherSubCategory: {
    apiItem: '0c8aba21-1c1e-4e93-9824-af6ab3f9cc3d'
  }
};

export default idConstants;
