import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../button/primary-button';
import Icons from './icons/Group.svg';
import Images from '../../../assets/img';
import { stockAvailApi } from '../../../actions/api/stockAvailAction';
import TextInput from '../input/text-input';
import { regexValues } from '../../../shared/constants/field-constants';
import './index.scss';
import { GAConstants } from '../../../data/ga-constants';

const StockAvail = ({ title, subTitle, openStockModal, closeModal }) => {
  const [isError, setIsError] = useState('');
  const [quantity, setQuantity] = useState('0');
  const [isValueEdited, setIsValueEdited] = useState(true);

  const dispatch = useDispatch();

  const toggleModal = () => {
    closeModal();
  };

  const productQuantityDetails = useSelector(
    (state) => state.StockAvailReducer.productQuantityDetails
  );

  useEffect(() => {
    if (
      productQuantityDetails?.quantity !== null &&
      productQuantityDetails?.quantity >= 0
    ) {
      setQuantity(productQuantityDetails?.quantity?.toString());
    }
  }, [productQuantityDetails]);

  const handleUpdate = () => {
    const body = {
      productName: productQuantityDetails?.product?.productName,
      productId: productQuantityDetails?.product?.productId,
      quantity: +quantity,
      type: 'REFURBISH',
      orderId: null
    };
    dispatch(stockAvailApi(body, GAConstants.isTriggerGAEvent));
    toggleModal();
  };

  const handlevalidation = (event) => {
    setQuantity(event.value);
    setIsError(event.error);
    setIsValueEdited(false);
  };

  const isDisableBtn = () => {
    return isValueEdited || isError?.length > 0 ? true : false;
  };

  return (
    <div>
      {openStockModal && (
        <div className='modal' onClick={(event) => event.stopPropagation()}>
          <div onClick={toggleModal} className='modal__overlay'></div>
          <div className='modal__content'>
            <img
              className='modal__success'
              src={Icons}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ARROW')}
            />
            <img
              className='modal__cancelIcon'
              onClick={toggleModal}
              src={Images.closeIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.CLOSE')}
            />
            <div className='alert__message'>
              <h1 className='alert__message__title -size-l'> {title} </h1>
              <p className='alert__message__subtitle'>{subTitle}</p>
              <div className='a-text-field modal__input'>
                <TextInput
                  name='quantity'
                  value={quantity}
                  handleChange={handlevalidation}
                  displayName={i18next.t('DYNAMIC_FORM.STOCK_AVAIL.QUANTITY')}
                  validation={{
                    rule: {
                      required: {
                        isRequired: true
                      },
                      regex: {
                        pattern: regexValues.numberRegex
                      },
                      length: {
                        min: 1,
                        max: 5
                      }
                    }
                  }}
                  error={isError}
                />
              </div>
              <div className='modal__message'>
                <PrimaryButton
                  className='modal__close'
                  onClick={handleUpdate}
                  label={i18next.t('DYNAMIC_FORM.LABEL.UPDATE_CHANGES')}
                  disabled={isDisableBtn()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StockAvail.defaultProps = {
  title: 'Stock Level Availability',
  subTitle: 'Enter the available stock in the given box.',
  disabled: true
};

export default StockAvail;
