import React, { useRef, useEffect } from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../msreact-kit/packages/html-components';
import CustomToolTip from '../../components/Shared/custom-tooltip';

import './index.scss';

const AutoMotiveCreateOptions = () => {
  const history = useHistory();

  const handleClickOutside = () => {
    const element = document.getElementById('dropdown-menu');
    element.classList.remove('-visible');
  };

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

    return ref;
  };

  const handleGrouping = () => {
    const element = document.getElementById('dropdown-menu');
    element.classList.toggle('-visible');
  };

  const handleCreateForm = () => {
    history.push({
      pathname: '/automotive-spares/individual-form'
    });
  };

  const dropDownData = [
    {
      id: 1,
      label: i18next.t('AUTOMOTIVE.INDIVIDUAL'),
      handler: () => {
        handleCreateForm();
      }
    },
    {
      id: 2,
      label: i18next.t('AUTOMOTIVE.BULK'),
      handler: () => {}
    }
  ];

  const wrapperRef = useOutsideClick(handleClickOutside);

  return (
    <React.Fragment>
      <div className='automotive-field'>
        <div className='automotive-field_items'>
          <div className='automotive-field_items_buttons'>
            <div className='automotive-field_items_buttons_product d-sm-none'>
              <div className='context-menu' ref={wrapperRef}>
                <div className='box'>
                  <div className='contex-menu__box -size-m'>
                    <CustomToolTip
                      id='add-product'
                      tooltipData={`+ ${i18next.t(
                        'DYNAMIC_FORM.SHARED.BUTTON.ADD'
                      )} ${i18next.t('AUTOMOTIVE.AUTOMOTIVE_SPARES')}`}
                      width='dynamic'
                    >
                      <PrimaryButton label='' onClick={() => handleGrouping()}>
                        <p
                          id='add-product'
                          className='text-ellipsis automotive-field__tooltip-add-product'
                        >
                          {`+ ${i18next.t(
                            'DYNAMIC_FORM.SHARED.BUTTON.ADD'
                          )} ${i18next.t('AUTOMOTIVE.AUTOMOTIVE_SPARES')}`}
                        </p>
                      </PrimaryButton>
                    </CustomToolTip>
                  </div>
                  <nav
                    id='dropdown-menu'
                    className='o-context-menu'
                    aria-label='Context Menu Navigation'
                    aria-hidden='false'
                  >
                    <div className='m-popover  m-popover__left automotive-field_automative_option -without-arrow'>
                      <div className='a-box -floating'>
                        <div className='m-popover__content'>
                          <ul className={'o-context-menu__menuItems'} role='menubar'>
                            {dropDownData?.length > 0 &&
                              dropDownData?.map((item) => (
                                <li
                                  key={item.id}
                                  className='a-menu-item'
                                  role='none'
                                >
                                  <a
                                    role='menuitem'
                                    className='a-menu-item__link'
                                    aria-disabled='false'
                                    aria-controls='group-id'
                                    onClick={item.handler}
                                  >
                                    <p>{item.label}</p>
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AutoMotiveCreateOptions;
