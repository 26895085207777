import { useState, useEffect } from 'react';

const useDebounce = (value, timeOut) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const timeOutValue = () => {
    if (timeOut) {
      return timeOut;
    } else {
      return value && value.length > 3 ? 150 : 50;
    }
  };

  useEffect(() => {
    let d = timeOutValue();
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, d);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebounce;
