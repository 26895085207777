import axios from 'axios';
import {
  GET_TENANT_CATEGORY,
  GET_TENANT_CATEGORY_NAME,
  SELLER_TENANT_REQUEST
} from '../configuration';

export function getTenantCategory() {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_TENANT_CATEGORY,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_TENANT_CATEGORY',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export const getProductCategories = () => {
  return (dispatch) =>
    axios
      .get(GET_TENANT_CATEGORY_NAME, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'GET_CATEGORIES',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};

export const sellerTenantRequest = (tenant, categoryId) => {
  return (dispatch) =>
    axios
      .put(SELLER_TENANT_REQUEST + `?tenant=${tenant}&categoryId=${categoryId}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};
