import React from 'react';
import PropTypes from 'prop-types';

import FileUpload from './file-upload';
import ThumbnailCard from './thumbnail-card';
import validation from '../validation';
import Images from '../../../assets/img';
import i18next from 'i18next';

const Attachment = ({
  heading,
  displayName,
  value,
  placeHolder,
  handleChange,
  ...props
}) => {
  const handleDelete = (file) => {
    handleDeleteFile(file);
  };

  const files =
    value &&
    value?.length > 0 &&
    value.map((file) => (
      <ThumbnailCard
        key={file}
        file={file}
        handleDelete={handleDelete}
        uploadType='attachment'
      />
    ));

  return (
    <React.Fragment>
      <div>
        {heading && <h4>{heading}</h4>}
        <FileUpload
          className='upload__attachment'
          validations={validation.attachment}
          isList={true}
          handleChange={handleChange}
          value={value}
          {...props}
        >
          <img
            className='upload__paper_clip'
            src={Images.paperClip}
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ATTACHMENT')}
          />
          <label className='upload__label'>{displayName}</label>
        </FileUpload>
      </div>
      {files && files.length > 0 && (
        <div className='upload__media_listing' data-testid='handler'>
          {files}
        </div>
      )}
    </React.Fragment>
  );
};

Attachment.propTypes = {
  heading: PropTypes.string,
  placeHolder: PropTypes.string
};

Attachment.defaultProps = {
  heading: '',
  placeHolder: ''
};

export default Attachment;
