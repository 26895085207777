const toastMessage = {
  saveStep: {
    msg: 'Details saved successfully!'
  },
  saveSecretHeader: {
    msg: 'Secret header/parameter added successfully!'
  },
  update: {
    msg: 'Updated successfully'
  },
  delete: {
    msg: 'Deleted successfully'
  },
  addGroup: {
    msg: 'Group name added successfully!'
  },
  addEndpoint: {
    msg: 'Endpoint added successfully!'
  },
  documentUpload: {
    msg: 'Document uploaded successfully!'
  },
  videoUpload: {
    msg: 'Video uploaded successfully!'
  },
  thumbnailUpload: {
    msg: 'Thumbnail Image uploaded successfully!'
  },
  coverImageUpload: {
    msg: 'Cover Image uploaded successfully!'
  },
  multimediaImageUpload: {
    msg: 'Product Image uploaded successfully!'
  },
  highlightImageUpload: {
    msg: 'Highlight Image uploaded successfully!'
  },
  caseStudyImageUpload: {
    msg: 'Case Study Image uploaded successfully!'
  },
  addPlan: {
    msg: 'Plan added successfully!'
  },
  updatePlan: {
    msg: 'Plan has been updated successfully!'
  },
  saveDraft: {
    msg: "API has been added and status set to 'In Draft' "
  },
  changedToDraft: {
    msg: "API has been updated and status changed to 'In Draft'"
  },
  error: {
    msg: 'Error: An error has been occurred. Please try again later.'
  },
  submitError: {
    msg: 'Error: Some fields are not entered or not valid! Please enter all the required fields with valid data'
  },
  updateApi: {
    msg: 'API details has been updated successfully!'
  },
  invalidRequestContentType: {
    msg: 'Please select the relevant Content type for request'
  },
  invalidResponseContentType: {
    msg: 'Please select the relevant Content type for response'
  },
  invalidContentTypeAndBody: {
    msg: 'Body and content type are not matching.'
  },
  invalidSchemaForJson: {
    msg: 'Invalid Schema'
  },
  inValidFormData: {
    msg: 'Endpoint cannot have Form-data, Form-urlencoded and Raw-body together. Only one of these parameter is allowed'
  },
  apiAlertSuccess: {
    msg: 'Alert settings saved'
  },
  saveBankDetails: {
    msg: 'Bank Details saved successfully!'
  },
  saveUserDetailsErr: {
    msg: 'Unable to save User Details'
  },
  saveCompanyDetailsErr: {
    msg: 'Unable to save Company Details'
  },
  docUpload: {
    msg: 'Document uploaded successfully'
  },
  docUploadErr: {
    msg: 'Unable to upload document'
  },
  invalidReqResSwaggerUpload: {
    msg: 'Invalid request and response examples!'
  },
  invalidReqSwaggerUpload: {
    msg: 'Invalid request examples!'
  },
  invalidResSwaggerUpload: {
    msg: 'Invalid response examples!'
  },
  successTextBuyer: {
    msg: 'Valid Request body'
  },
  errorTextBuyer: {
    msg: 'Request body is not valid'
  },
  validExample: {
    msg: 'Valid example'
  },
  invalidExample: {
    msg: 'Example is not valid'
  },
  invalidResponseCode: {
    msg: 'Invalid Response code'
  },
  incorrectDetailsErr: {
    msg: 'Error Message : The details that you have entered are incorrect'
  }
};

export default toastMessage;
