import React, { Fragment } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  IconButton,
  SecondaryButton
} from '../../packages/html-components';
import ModalCard from './modal-card';
import './index.scss';

const DialogBox = ({
  title,
  subTitle,
  primaryLabel,
  secondaryLabel,
  handleCancel,
  handleConfirm,
  isModal,
  isSuccess,
  isSecondaryBtn,
  isCloseIcon,
  children,
  isDivider,
  isPrimaryBtn,
  cssName,
  chargeableCode,
  image,
  isVisibilityModal,
  isVisible,
  handleIsVisible,
  isTitleIconRequired,
  handleSecondaryBtnClick,
  isCloseInfoIcon
}) => {
  return (
    <div className='dialog_modal' style={{ position: chargeableCode && 'absolute' }}>
      <div
        className={`dialog_modal__content ${isVisibilityModal && 'dialog_modal__content__visibility_modal'} ${cssName && cssName + '__height'}`}
      >
        {isModal ? (
          <ModalCard
            title={title}
            subTitle={subTitle}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            isSuccess={isSuccess}
            isSecondaryBtn={isSecondaryBtn}
            isCloseIcon={isCloseIcon}
            image={image}
            isVisibilityModal={isVisibilityModal}
            isVisible={isVisible}
            handleIsVisible={handleIsVisible}
          >
            {children}
          </ModalCard>
        ) : (
          <Fragment>
            <div
              className={`dialog_remark__message ${
                cssName && cssName + '__header'
              } ${chargeableCode && 'dialog_remark__chargeable'}`}
            >
              {title && (
                <h4 className='dialog_remark__message__title -size-l mb12'>
                  {title}
                </h4>
              )}
              {isCloseInfoIcon && (
                <IconButton
                  label=''
                  className={`dialog_remark_general__close ${
                    cssName && cssName + '__close'
                  } ${chargeableCode && 'dialog_remark_general__chargeable_close'}`}
                  iconCss='ui-ic-close'
                  iconTitle={i18next.t('DYNAMIC_FORM.ICON_TITLE.CLOSE')}
                  onClick={handleCancel}
                  isIcon={true}
                />
              )}
            </div>
            {isDivider && <hr className='a-divider' />}
            <div className={`${cssName && cssName + '__body'}`}>{children}</div>
            {isPrimaryBtn && (
              <div
                className={`dialog_modal__message ${cssName && cssName + '__msg'}`}
              >
                {secondaryLabel && (
                  <SecondaryButton
                    onClick={handleSecondaryBtnClick}
                    label={secondaryLabel}
                  />
                )}
                <PrimaryButton
                  onClick={(event) => {
                    handleConfirm(event);
                  }}
                  label={primaryLabel}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DialogBox;

DialogBox.propTypes = {
  isModal: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isDivider: PropTypes.bool,
  isPrimaryBtn: PropTypes.bool,
  isCloseInfoIcon: PropTypes.bool
};

DialogBox.defaultProps = {
  isModal: false,
  isSuccess: false,
  isDivider: false,
  isPrimaryBtn: true,
  chargeableCode: false,
  isCloseInfoIcon: true
};
