import React from 'react';
import { connect } from 'react-redux';
import { updateInfo } from '../actions/api/SolutionAction';
import { logout } from '../actions/LoginAction';
import { bindActionCreators } from 'redux';
import Sidebar from '../components/Sidebar';
import {
  checkForAccountDeletion,
  requestForAccountDeletion,
  resetAccountDeletion
} from '../actions/DeleteUserAction';

class SidebarContainer extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <Sidebar {...this.props} isShowSideBar={true} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateInfo,
      logout,
      checkForAccountDeletion,
      requestForAccountDeletion,
      resetAccountDeletion
    },
    dispatch
  );
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  info: state.SolutionReducer.info,
  isAccountDeletable: state.DeleteAccountReducer.isAccountDeletable,
  requestAccDeletionResp: state.DeleteAccountReducer.requestAccDeletionResp,
  accountDeletionError: state.DeleteAccountReducer.accountDeletionError
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
