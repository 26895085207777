import axios from 'axios';
import * as Constants from './configuration';

export default function getAllMasterData(categoryId) {
  return (dispatch) =>
    axios
      .get(
        Constants.MASTER_DATA_ACTION.GET_ALL_MASTERDATA +
          `?categoryId=` +
          categoryId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_ALL_MASTER_DATA',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
export function getPricingPlanMasterData(categoryId) {
  return (dispatch) =>
    axios
      .get(
        Constants.MASTER_DATA_ACTION.GET_PRICING_PLAN_MASTER_DATA +
          `?categoryId=` +
          categoryId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PRICING_PLAN_MASTER_DATA',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
export const getProductCategories = () => {
  return (dispatch) =>
    axios
      .get(Constants.MASTER_DATA_ACTION.GET_PRODUCT_CATEGORIES, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'GET_PRODUCT_CATEGORIES',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};
