import React, { useState, useEffect } from 'react';
import Images from '../../../../../../src/assets/img';
import styles from './pricing-preview.module.scss';
import {
  PrimaryButton,
  SecondaryButton
} from '../../../../../msreact-kit/packages/html-components';
import idConstants from '../../../../../data/idConstants';
import i18next from 'i18next';
import { minPlanBenefitsToShow } from '../../../../../shared/constants/field-constants';
import CustomToolTip from '../../../../Shared/custom-tooltip';

function PricingPlanPreview(props) {
  const [planBenefits, setPlanBenefits] = useState([]);

  useEffect(() => {
    let planBenefitsArray = props?.plan?.planBenefits?.slice(
      0,
      minPlanBenefitsToShow
    );
    planBenefitsArray = planBenefitsArray.filter(
      (benefit) => benefit?.benefitDescription
    );
    setPlanBenefits(planBenefitsArray);
  }, [props.plan]);

  const getPriceRange = (priceSlabs, currency) => {
    let priceValues = [];
    let minPrice = '';
    let maxPrice = '';
    priceSlabs.forEach((tier) => {
      priceValues.push(tier.cost);
    });
    if (priceValues.every((item) => item === priceValues[0])) {
      minPrice = priceValues.length > 0 ? priceValues[0] : '';
    } else {
      priceValues.sort(function (firstElement, secondElement) {
        return firstElement - secondElement;
      });
      minPrice = priceValues.length > 0 ? priceValues[0] : '';
      if (priceValues.length > 1) {
        maxPrice = priceValues[priceValues.length - 1];
      }
    }
    return (
      <>
        {minPrice && minPrice > 0 && (
          <span className={styles['bold-style']}>
            {parseFloat(minPrice).toFixed(2)} {props.plan.currencyCode}
          </span>
        )}
        {maxPrice !== '' && (
          <span className={styles['bold-style']}>
            {' '}
            - {parseFloat(maxPrice).toFixed(2)} {props.plan.currencyCode}
          </span>
        )}
      </>
    );
  };

  const getPricingType = () => {
    if (
      props?.plan?.chargeableUnit?.chargeableUnitType ===
      idConstants.pricing.fullSlabType
    ) {
      return i18next.t('DYNAMIC_FORM.PRICING_CARD.FULL_SLAB');
    }

    if (props?.plan?.rateLimitType === idConstants.pricing.reqCountLimitType) {
      return i18next.t('DYNAMIC_FORM.PRICING_CARD.PER_REQUEST_HIT');
    }

    return '';
  };

  const getPrimaryButton = () => {
    if (
      props.pricingPlanPayload === idConstants.oneTimePostBillingType.billingTypeCode
    ) {
      return i18next.t('DYNAMIC_FORM.SHARED.BUTTON.BUY_NOW');
    } else if (
      props.pricingPlanPayload === idConstants.oneTimeBillingType.billingTypeCode
    ) {
      return i18next.t('DYNAMIC_FORM.SHARED.BUTTON.PURCHASE');
    }

    return i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBSCRIBE');
  };

  return (
    <div>
      <div className={styles['plan-card']}>
        <div
          className={`${styles['plan-card-header']} ${styles['background-custom']}`}
        >
          <CustomToolTip
            className={styles['plan-card-header-customtooltip']}
            id={props?.plan?.planName}
            tooltipData={props?.plan?.planName}
            width='dynamic'
          >
            <div
              className={`${styles['plan-card-name']}`}
              id={props?.plan?.planName}
            >
              {props?.plan?.planName?.toUpperCase()}
            </div>
          </CustomToolTip>
          <div>
            {props?.plan?.price > 0
              ? `${props.plan.price} ${props.plan.currencyCode}`
              : getPriceRange(props?.priceSlabs, props?.plan?.currency)}
          </div>
          <div>{getPricingType()}</div>
        </div>
        <div className={styles['list-content']}>
          <div className={styles['checklist__item']}>
            {planBenefits.map((benefit) => (
              <div className={styles['list-style']} key={benefit.id}>
                <div className={styles['list-item-icon-style']}>
                  <img
                    src={Images.checkMark}
                    alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.CHECKMARK_ICON')}
                    className={styles['list-item-icon']}
                  />
                </div>
                <CustomToolTip
                  id={benefit?.benefitDescription}
                  tooltipData={benefit.benefitDescription}
                  width='dynamic'
                >
                  <div
                    className={styles['list-item-text']}
                    id={benefit?.benefitDescription}
                  >
                    {benefit.benefitDescription}
                  </div>
                </CustomToolTip>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['pricing__view__button']}>
          <div className={styles['align__center__button']}>
            <img
              className='info-icon-cursor-styling'
              src={Images.infoBgBlueIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.INFO')}
            />
            <p className={styles['view_details']}>
              {i18next.t('DYNAMIC_FORM.LABEL.VIEW_DETAILS')}
            </p>
          </div>
          {/* Add to cart card only for hardware based on billingTypeCode */}
          {props.pricingPlanPayload ===
            idConstants.oneTimePostBillingType.billingTypeCode && (
            <div className='align__center__button mt__1'>
              <SecondaryButton
                label={i18next.t('DYNAMIC_FORM.LABEL.ADD_TO_CART')}
                className={styles['marketplace-button']}
              />
            </div>
          )}
          <div className='align__center__button mt__1'>
            <PrimaryButton
              className={`${styles['background-custom']} ${styles['marketplace-button']}`}
              label={getPrimaryButton()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PricingPlanPreview;
