import React, { useState } from 'react';
import { Multiselect } from '../MultiSelect/multiselect.component';
import Images from '../../../../assets/img';
import './index.scss';

export default function MultipleSelect(props) {
  const [mutliSelectRef, setMutliSelectRef] = useState(null);

  const handleclick = () => {
    mutliSelectRef?.current?.click();
  };

  const refHandler = (childRef) => {
    setMutliSelectRef(childRef);
  };

  const getPlaceholder = (data, placeholder) => {
    return data?.length > 0 ? '' : placeholder;
  };

  return (
    <React.Fragment>
      <div
        className='multiple__div'
        style={{ minWidth: '-webkit-fill-available', width: 'inherit' }}
      >
        <Multiselect
          refHandler={refHandler}
          fullwidth={props.fullWidth}
          id='multiselect'
          options={props.data}
          onSelect={props.onSelect}
          onRemove={props.onRemove}
          selectedValues={props.selected}
          displayValue='name'
          showCheckbox={true}
          placeholder={getPlaceholder(props.selected, props.placeholder)}
          avoidHighlightFirstOption='true'
          disabled={props.disabled}
          selectionLimit={props.selectionLimit}
          maxCount={props.maxCount}
        >
          {props?.children}
        </Multiselect>
        <div className='dropdown__arrow arrow-right' onClick={handleclick}>
          <img src={Images.arrowDown} />
        </div>
      </div>
      {props.helperText && props.helperText.length >= 1 && (
        <>
          <div>
            <img src={Images.alertErrorBlack} />
          </div>
          {props.helperText}
        </>
      )}
    </React.Fragment>
  );
}
