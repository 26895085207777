const initialState = {
  allApiAlerts: [],
  prodSpecificApiAlerts: [],
  createProdSpecificApiAlerts: [],
  updateProdSpecificApiAlerts: []
};

const ApiAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_API_ALERTS':
      return Object.assign({}, state, { allApiAlerts: action.payload });
    case 'GET_PROD_SPECIFIC_API_ALERTS':
      return Object.assign({}, state, { prodSpecificApiAlerts: action.payload });
    case 'CREATE_PROD_SPECIFIC_API_ALERTS':
      return Object.assign({}, state, { createSpecificApiAlerts: action.payload });
    case 'UPDATE_PROD_SPECIFIC_API_ALERTS':
      return Object.assign({}, state, { updateSpecificApiAlerts: action.payload });
    default:
      return state;
  }
};

export default ApiAlertsReducer;
