//lib
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { InputAdornment } from '@mui/material';
import dayjs from 'dayjs';

//css
import './index.scss';

//components
import {
  NumberInput,
  TextArea,
  PrimaryButton,
  SecondaryButton,
  Notification
} from '../../../../msreact-kit/packages/html-components';
import Loader from '../../../Shared/Utils/Loader';

//assets
import images from '../../../../assets/img';

//actions
import { postConsumption } from '../../../../actions/api/ConsumptionPostingAction';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//constants
import idConstants from '../../../../data/idConstants';

const ConsumptionPosting = ({ ...props }) => {
  const [contractInfo, setContractInfo] = useState([]);
  const [consumptionInfo, setConsumptionInfo] = useState({
    quantity: '0',
    amount: '0',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    remarks: ''
  });
  const [fromDateError, setFromDateError] = useState('');
  const [toDateError, setToDateError] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [apiErrorMsg, setErrorMsg] = useState({});
  const [loader, setLoader] = useState(false);
  const [openFromDate, setOpenFromDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);

  useEffect(() => {
    setContractInfo(props.contractData);
  }, [props.contractData]);

  const handleInputChange = (event) => {
    setConsumptionInfo({
      ...consumptionInfo,
      [event.name]: event.value
    });
  };

  const displayErrorMessage = (error) => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        return `${i18next.t('CONSUMPTION.MIN_MAX_DATE_ERROR', { error })}`;
      }

      case 'invalidDate': {
        return i18next.t('CONSUMPTION.INVALID_DATE');
      }

      default: {
        return '';
      }
    }
  };

  const fromDateErrorMessage = React.useMemo(() => {
    return displayErrorMessage(fromDateError);
  }, [fromDateError]);

  const toDateErrorMessage = React.useMemo(() => {
    return displayErrorMessage(toDateError);
  }, [toDateError]);

  const postConsumptionApi = (data) => {
    const payload = {
      measurements: [
        {
          contractId: data.contractId,
          measureUnit: props.pricingPolicies?.rateLimit?.rateLimitType?.limitType,
          quantity:
            props.pricingPolicies?.consumption ===
              idConstants.pricing.definedBySeller && !consumptionInfo.quantity
              ? 1
              : Number(consumptionInfo.quantity),
          amount: Number(consumptionInfo.amount),
          remarks: consumptionInfo.remarks
        }
      ],
      msOrderId: props.orderDescription.productOrderDto.productOrderId,
      startDate: Date.parse(consumptionInfo.startDate),
      endDate: Date.parse(consumptionInfo.endDate),
      currency: props.pricingPolicies?.currency?.currencyCode,
      tenantId: data.tenantId
    };
    setLoader(true);
    props.postConsumption(payload).then((response) => {
      setLoader(false);
      if (response?.status !== 200 && response?.data?.message) {
        setApiResponse(i18next.t('SHARED.ERROR'));
        setErrorMsg(response?.data);
      } else if (response === 'SUCCESS') setApiResponse(i18next.t('SHARED.SUCCESS'));
    });
  };

  const dateComponent = (type) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={
            type === 'startDate' ? i18next.t('SHARED.FROM') : i18next.t('SHARED.TO')
          }
          open={type === 'startDate' ? openFromDate : openToDate}
          onOpen={() =>
            type === 'startDate' ? setOpenFromDate(true) : setOpenToDate(true)
          }
          onClose={() =>
            type === 'startDate' ? setOpenFromDate(false) : setOpenToDate(false)
          }
          slotProps={{
            textField: {
              variant: 'filled',
              focused: true,
              required: true,
              helperText:
                type === 'startDate' ? fromDateErrorMessage : toDateErrorMessage,
              InputProps: {
                endAdornment: (
                  <InputAdornment position='end'>
                    <img
                      onClick={() =>
                        type === 'startDate'
                          ? setOpenFromDate(!openFromDate)
                          : setOpenToDate(!openToDate)
                      }
                      src={images.calenderIcon}
                      className='calendar-icon'
                      alt={i18next.t('CALENDAR_ICON')}
                    />
                  </InputAdornment>
                )
              }
            }
          }}
          className='date-time-block'
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock
          }}
          name={type === 'startDate' ? 'startDate' : 'endDate'}
          value={
            type === 'startDate'
              ? consumptionInfo.startDate
              : consumptionInfo.endDate
          }
          onChange={(newValue) =>
            type === 'startDate'
              ? setConsumptionInfo({
                  ...consumptionInfo,
                  startDate: dayjs(new Date(newValue))
                })
              : setConsumptionInfo({
                  ...consumptionInfo,
                  endDate: dayjs(new Date(newValue))
                })
          }
          onError={(error) => {
            type === 'startDate' ? setFromDateError(error) : setToDateError(error);
          }}
        />
      </LocalizationProvider>
    );
  };

  const mandatoryQuantityFlag = () => {
    if (props.pricingPolicies?.consumption === idConstants.pricing.definedByUsage)
      return true;
    else if (
      props.pricingPolicies?.consumption === idConstants.pricing.definedBySeller
    )
      return false;
    else return true;
  };

  const mandatoryAmountFlag = () => {
    if (props.pricingPolicies?.consumption === idConstants.pricing.definedByUsage)
      return false;
    else if (
      props.pricingPolicies?.consumption === idConstants.pricing.definedBySeller
    )
      return true;
    else return true;
  };

  const getDisabledFlag = () => {
    if (props.pricingPolicies?.consumption === idConstants.pricing.definedByUsage)
      return (
        consumptionInfo.quantity === '' ||
        !consumptionInfo.startDate ||
        !consumptionInfo.endDate ||
        fromDateError ||
        toDateError
      );
    else if (
      props.pricingPolicies?.consumption === idConstants.pricing.definedBySeller
    )
      return (
        consumptionInfo.amount === '' ||
        !consumptionInfo.startDate ||
        !consumptionInfo.endDate ||
        fromDateError ||
        toDateError
      );
    else
      return (
        consumptionInfo.amount === '' ||
        consumptionInfo.quantity === '' ||
        !consumptionInfo.startDate ||
        !consumptionInfo.endDate ||
        fromDateError ||
        toDateError
      );
  };

  const resetData = () => {
    setConsumptionInfo({
      ...consumptionInfo,
      quantity: '0',
      amount: '0',
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()),
      remarks: ''
    });
    setApiResponse('');
  };

  return (
    <div>
      {loader && <Loader />}
      <div className='consumption-status'>
        <h5>{i18next.t('CONSUMPTION.CONSUMPTION_METERING_INFO')}: </h5>
        {contractInfo?.map((data) => {
          return (
            <React.Fragment key={data.contractId}>
              <p className='contract-info'>
                {i18next.t('CONSUMPTION.CONTRACT_ID')}: {data.contractId}
              </p>
              <div className='consumption-details-container'>
                <div className='section-block usage-block'>
                  <div className='img-block'>
                    <img className='img-size' src={images.usageIcon} />
                    <span className='heading-section'>
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.USAGE')}
                    </span>
                  </div>
                  <div className='usage-div-block'>
                    <span className='usage-block-1'>
                      {i18next.t('CONSUMPTION.UNIT_OF_MEASUREMENT')}:{' '}
                    </span>
                    <span className='unit-currency-block'>
                      {props.pricingPolicies?.rateLimit?.rateLimitType?.limitType}
                    </span>
                  </div>
                  <div className='usage-div-block'>
                    <span className='usage-block-1'>
                      {i18next.t('PRODUCT_DETAIL.QUANTITY')}:{' '}
                    </span>
                    <div className='text-input'>
                      <NumberInput
                        id='input-block'
                        displayName={i18next.t('PRODUCT_DETAIL.QUANTITY')}
                        validation={{
                          rule: {
                            required: {
                              isRequired: mandatoryQuantityFlag()
                            }
                          }
                        }}
                        value={consumptionInfo.quantity}
                        name='quantity'
                        handleChange={(event) => handleInputChange(event)}
                        handleBlur={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>
                </div>
                <hr className='a-divider a-divider--vertical' />
                <div className='section-block usage-block'>
                  <div className='img-block'>
                    <img className='img-size' src={images.priceIcon} />
                    <span className='heading-section'>
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICE')}
                    </span>
                  </div>
                  <div className='usage-div-block'>
                    <span className='usage-block-1'>
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CURRENCY')}:{' '}
                    </span>
                    <span className='unit-currency-block'>
                      {props.pricingPolicies?.currency?.currencyCode}
                    </span>
                  </div>
                  <div className='usage-div-block'>
                    <span className='usage-block-1'>
                      {i18next.t('CONSUMPTION.AMOUNT')}:{' '}
                    </span>
                    <NumberInput
                      id='input-block'
                      class='text-input'
                      displayName={i18next.t('CONSUMPTION.AMOUNT')}
                      validation={{
                        rule: {
                          required: {
                            isRequired: mandatoryAmountFlag()
                          }
                        }
                      }}
                      disabled={!mandatoryAmountFlag()}
                      value={consumptionInfo.amount}
                      name='amount'
                      handleChange={(event) => handleInputChange(event)}
                      handleBlur={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <hr className='a-divider a-divider--vertical' />
                <div className='section-block usage-block calendar-section'>
                  <div className='img-block'>
                    <img className='img-size' src={images.clockIcon} />
                    <span className='heading-section'>
                      {i18next.t('CONSUMPTION.CONSUMPTION_TIMESTAMP')}
                    </span>
                  </div>
                  <div className='usage-div-block'>
                    <span className='usage-block-1'>
                      {i18next.t('CONSUMPTION.START_DATE')}:{' '}
                    </span>
                    <div className='date-section'>{dateComponent('startDate')}</div>
                  </div>
                  <div className='usage-div-block'>
                    <span className='usage-block-1'>
                      {i18next.t('CONSUMPTION.END_DATE')}:{' '}
                    </span>
                    <div className='date-section'>{dateComponent('endDate')}</div>
                  </div>
                </div>
                <hr className='a-divider a-divider--vertical' />
                <div className='section-block usage-block remarks-section'>
                  <div>
                    <span className='heading-section'>
                      {i18next.t('SHARED.REMARKS')}
                    </span>
                  </div>
                  <div className='remarks-block'>
                    <TextArea
                      displayName={i18next.t('SHARED.REMARKS')}
                      validation={{
                        rule: {
                          length: {
                            max: 500
                          }
                        }
                      }}
                      value={consumptionInfo.remarks}
                      maxLength='500'
                      name='remarks'
                      handleChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
              <div className='button-summary__footer'>
                <div className='button-summary__navigation-buttons'>
                  <div className='button-summary__navigation-buttons__right-wrapper'>
                    <SecondaryButton
                      label={i18next.t('CONSUMPTION.CLEAR_DATA')}
                      onClick={() => resetData()}
                    />
                    <PrimaryButton
                      onClick={() => postConsumptionApi(data)}
                      disabled={getDisabledFlag()}
                      label={i18next.t('CONSUMPTION.POST_CONSUMPTION')}
                    />
                  </div>
                </div>
              </div>
              {apiResponse && (
                <div className='notification-card'>
                  <Notification
                    cssName={
                      apiResponse === i18next.t('SHARED.SUCCESS')
                        ? 'success-card'
                        : 'error-card'
                    }
                    icon=''
                    content={
                      <React.Fragment>
                        <div className='highlight'>
                          {apiResponse === i18next.t('SHARED.SUCCESS')
                            ? i18next.t('SHARED.SUCCESS')
                            : i18next.t('SHARED.ERROR')}
                        </div>
                        <div>
                          {apiResponse === i18next.t('SHARED.SUCCESS')
                            ? `${i18next.t('CONSUMPTION.SUCCESS_MESSAGE', { contractId: data.contractId })}`
                            : apiErrorMsg.message}
                        </div>
                      </React.Fragment>
                    }
                    customImage={
                      <img
                        className='alert-icon'
                        src={
                          apiResponse === i18next.t('SHARED.SUCCESS')
                            ? images.alertSuccess
                            : images.alertError
                        }
                      />
                    }
                    isNotification={true}
                  />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postConsumption
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ConsumptionPosting);
