import axios from 'axios';
import * as Constants from '../configuration';
import { getProductsByStatus } from '../MyProductsAction';
import IdConstants from '../../data/idConstants';
import errorConstants from '../../data/errorConstants';
import {
  errorCodes,
  separatorData,
  parameterConstants
} from '../../data/appConstants';
import DisplayOptions from '../../data/displayOptions';
import { GAEventAction } from '../../data/ga-constants';
import { utf8Encode } from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { getRegionCode } from '../../utils/get-region-details';
import { getUserDetailsData } from '../../shared/shared-function/services';

const getProdDetailedInfoResponse = (productDetails, productSubCategory) => {
  const productDetailedInfo = {
    subcategory: productSubCategory,
    productLongDesc: productDetails.productLongDesc,
    termsAndConditions: productDetails.termsAndConditions
  };
  if (Constants.SELLER_TENANT_ID === Constants.TENANT.MKOSINT) {
    return {
      ...productDetailedInfo,
      tags: productDetails.tags,
      usageAvailability: productDetails.usageAvailability,
      adapterAccess: productDetails.adapterAccess,
      adapterLocation: productDetails.adapterLocation
    };
  } else {
    return productDetailedInfo;
  }
};

export default function saveProductInfo(data, setActiveStep, type, props) {
  const regionCode = getRegionCode().toLowerCase();
  const jsonObject = {};
  jsonObject.prodBasicInfo = data.prodBasicInfo;
  const productCategory = {};
  productCategory.categoryId = IdConstants.api.categoryId;
  jsonObject.prodBasicInfo.productCategory = productCategory;
  jsonObject.prodBasicInfo.productBusinessCategoryMap = [];
  const productBusinessCategoryMap = [];
  data.prodBasicInfo.businessCategories.map((value) => {
    productBusinessCategoryMap.push({
      businessCategory: {
        businessCategoryId: value.id,
        businessCategoryName: value.name
      }
    });
    return true;
  });
  const productSubCategory = [];
  data.prodDetailedInfo.subcategory.map((value) => {
    productSubCategory.push({
      id: value.id,
      name: value.name
    });
    return true;
  });
  jsonObject.prodBasicInfo.productBusinessCategoryMap = productBusinessCategoryMap;
  if (
    Constants.IS_MKOSINT_TENANT &&
    data.prodDetailedInfo.usageAvailability ===
      IdConstants.usageAvailability.standalone
  ) {
    data.prodDetailedInfo.adapterAccess = '';
    data.prodDetailedInfo.adapterLocation = '';
  }

  const basicInfoPayload = {
    data: jsonObject,
    ...getUserDetailsData(
      props?.userCompanyStatus?.companyId,
      null,
      jsonObject.prodBasicInfo.productCategory.categoryId
    )
  };

  return async (dispatch) => {
    try {
      const prodBasicInfoResponse = await axios({
        method: 'POST',
        url: `${Constants.PRODUCT_BASIC_INFO_CABPABILITIES}`,
        data: basicInfoPayload,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const prodBasicInfo = prodBasicInfoResponse?.data?.data;
      prodBasicInfo.businessCategories = jsonObject.prodBasicInfo.businessCategories;
      let productDetailedInfo = getProdDetailedInfoResponse(
        data.prodDetailedInfo,
        productSubCategory
      );
      productDetailedInfo = {
        ...productDetailedInfo,
        apiType: data.prodDetailedInfo.apiType,
        productRegion: regionCode,
        isReseller: data.prodDetailedInfo.isReseller
      };

      const additionalInfoPayload = {
        data: {
          prodBasicInfo: {
            productCategory: {
              categoryId: IdConstants.api.categoryId
            }
          },
          productDetailedInfo: productDetailedInfo
        },
        ...getUserDetailsData(
          props?.userCompanyStatus?.companyId,
          prodBasicInfoResponse.data?.data?.productId,
          IdConstants.api.categoryId
        )
      };

      const prodAdditionalInfoResponse = await axios.post(
        `${Constants.PRODUCT_ADDITIONAL_INFO_CABPABILITIES}${prodBasicInfoResponse.data?.data?.productId}`,
        additionalInfoPayload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      dispatch({
        type: 'SAVE_PRODUCT_INFO',
        payload1: prodBasicInfo,
        payload2: prodAdditionalInfoResponse.data?.data
      });
    } catch (error) {
      const response = error.response;
      if (response !== undefined) {
        dispatch({
          type: 'PRODUCT_INFO_ERROR',
          error: response.data
        });
      } else {
        dispatch({
          type: 'ERROR',
          error: error
        });
      }
    }
  };
}

const sendAdditionalDetails = (apiResponse, prodBasicInfo, prodDetailedInfo) => {
  const prodBasic = apiResponse.data;
  prodBasic['businessCategories'] = prodBasicInfo.businessCategories;
  if (prodDetailedInfo) {
    const productSubCategory = [];
    prodDetailedInfo?.subcategory?.map((value) => {
      productSubCategory.push({
        id: value.id,
        name: value.name
      });
      return true;
    });
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          apiResponse.data.productId,
        {
          prodBasicInfo: {
            productCategory: {
              categoryId: IdConstants.api.categoryId
            }
          },
          productDetailedInfo: getProdDetailedInfoResponse(
            prodDetailedInfo,
            productSubCategory
          )
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch({
          type: 'UPDATE_PRODUCT_INFO',
          payload1: prodBasic,
          payload2: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
  }
};

export function updateProductInfo(
  prodBasicInfo,
  prodDetailedInfo,
  productId,
  props,
  updateProdInfo
) {
  const jsonObject = {};
  jsonObject.prodBasicInfo = {};
  jsonObject.prodBasicInfo.productShortDesc = prodBasicInfo.productShortDesc;
  const productCategory = {};
  productCategory.categoryId = IdConstants.api.categoryId;
  jsonObject.prodBasicInfo.productCategory = productCategory;
  jsonObject.prodBasicInfo.productBusinessCategoryMap = [];
  const productBusinessCategoryMap = [];
  prodBasicInfo.businessCategories.map((value) => {
    productBusinessCategoryMap.push({
      businessCategory: {
        businessCategoryId: value.id
      }
    });
    return true;
  });
  jsonObject.prodBasicInfo.productId = productId;
  jsonObject.prodBasicInfo.productBusinessCategoryMap = productBusinessCategoryMap;
  if (
    props?.editableDetails?.stateCondition !== 'REJECTED' &&
    props.isProdNameEditable
  ) {
    jsonObject.prodBasicInfo.productName = prodBasicInfo.productName;
  }
  if (updateProdInfo) {
    jsonObject.prodBasicInfo.saccode = updateProdInfo.productSACCode;
  }
  return (dispatch) =>
    axios({
      method: 'PATCH',
      url: Constants.PRODUCT_BASIC_INFO + `/${productId}?`,
      data: jsonObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response1) => {
        sendAdditionalDetails(response1, prodBasicInfo, prodDetailedInfo);
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
//api to get the unique authorization key
export function generateSecretKey(prodBasicInfo) {
  return (dispatch) =>
    axios
      .get(
        Constants.SOLUTION_ACTION.UPDATE_PRODUCT_DETAIL_INFO +
          `?uid=${prodBasicInfo.productId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GENERATE_SECRET_KEY',
          payload: response.data
        });
        return response.data;
      })

      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

const restructureProductData = (response, productData, dispatch) => {
  if (response.data?.accessControls?.authDetails !== undefined) {
    const authDetails = response.data.accessControls.authDetails;
    const separator = !!authDetails.separator
      ? authDetails.separator
      : separatorData[1].value;
    productData.authType = authDetails.authType ? authDetails.authType : '';
    productData.authTokenUrl = !!authDetails.authTokenUrl
      ? authDetails.authTokenUrl
      : '';
    productData.clientAuthentication = !!authDetails.clientAuthentication
      ? authDetails.clientAuthentication
      : '';
    productData.grantType = authDetails.grantType ? authDetails.grantType : '';
    productData.separator = !!authDetails.separator
      ? separatorData.find(function (item) {
          return item.value === authDetails.separator;
        }).id
      : '2';
    productData.scope = !!authDetails.scope ? authDetails.scope.join(separator) : '';
  }
  productData.authorizationKey = response.data.authorizationKey;
  productData.baseUrl = response.data.baseUrl;
  dispatch({
    type: 'SAVE_PRODUCT_DETAILED_INFO',
    payload: productData
  });
};

export function saveProductDetailedInfo(productData, setActiveStep) {
  const grantType =
    productData.prodDetailedInfo.grantType === ''
      ? IdConstants.clientCredentialsId
      : productData.prodDetailedInfo.grantType;
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    },
    productDetailedInfo: {
      baseUrl: productData.prodDetailedInfo.baseUrl,
      authorizationKey: productData.prodDetailedInfo.authorizationKey
    }
  };

  reqJson.productDetailedInfo.accessControls = {};
  if (productData.prodDetailedInfo.authType === IdConstants.oAuth2Authentication) {
    const separator = !!productData.prodDetailedInfo.separator
      ? separatorData.find(function (item) {
          return item.id === productData.prodDetailedInfo.separator;
        }).value
      : separatorData[1].value;
    reqJson.productDetailedInfo.accessControls.authDetails = {
      authType: productData.prodDetailedInfo.authType,
      grantType: grantType,
      authTokenUrl: productData.prodDetailedInfo.authTokenUrl,
      clientAuthentication: productData.prodDetailedInfo.clientAuthentication,
      separator: separator
    };

    if (grantType === IdConstants.grantType.deviceCode) {
      reqJson.productDetailedInfo.accessControls.authDetails.verificationURL =
        productData.prodDetailedInfo.verificationURL;
    }

    if (grantType === IdConstants.grantType.authorizationCode) {
      reqJson.productDetailedInfo.accessControls.authDetails.authorizationURL =
        productData.prodDetailedInfo.authorizationURL;
    }

    if (!!productData.prodDetailedInfo.scope) {
      const scopeData = productData.prodDetailedInfo.scope;
      reqJson.productDetailedInfo.accessControls.authDetails.scope =
        scopeData.split(separator);
    }
  } else {
    reqJson.productDetailedInfo.accessControls.authDetails = {
      authType: productData.prodDetailedInfo.authType
    };
  }
  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          productData.prodBasicInfo.productId +
          '?',
        reqJson
      )
      .then((response) => {
        restructureProductData(response, productData, dispatch);
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function saveAdditionalDetailedInfo(productData) {
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    },
    productDetailedInfo: productData.prodDetailedInfo
  };
  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          productData.prodBasicInfo.productId +
          '?',
        reqJson
      )
      .then((response) => {
        productData = response;
        dispatch({
          type: 'SAVE_PRODUCT_DETAILED_INFO',
          payload: productData
        });
      })
      .catch((error) => {
        const errorResponse = error.response;
        if (errorResponse !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: errorResponse.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export const saveProductSupportInfo = (productData) => {
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    },
    productDetailedInfo: {
      lISupport: productData.prodDetailedInfo?.lISupport,
      lIEscalation: productData.prodDetailedInfo?.lIEscalation,
      lIIEscalation: productData.prodDetailedInfo?.lIIEscalation
    }
  };
  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          productData.prodBasicInfo.productId +
          '?',
        reqJson
      )
      .then((response) => {
        productData.prodDetailedInfo.lISupport = response.data.lISupport
          ? response.data.lISupport
          : {};
        productData.prodDetailedInfo.lIEscalation = response.data.lIEscalation
          ? response.data.lIEscalation
          : {};
        productData.prodDetailedInfo.lIIEscalation = response.data.lIIEscalation
          ? response.data.lIIEscalation
          : {};
        dispatch({
          type: 'SAVE_PRODUCT_DETAILED_INFO',
          payload: productData
        });
      })
      .catch((error) => {
        const response = {};
        if (error.response) {
          response.error = error.response.data;
        } else {
          response.error = error;
        }
        return response;
      });
};

export function updateProductDetailedInfo(productData) {
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    }
  };
  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          productData.prodBasicInfo.productId +
          '?',
        reqJson
      )
      .then((response) => {
        productData.prodDetailedInfo.productSacCode = response.data.productSACCode;
        dispatch({
          type: 'UPDATE_PRODUCT_DETAILED_INFO',
          payload: productData
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function saveSecretHeaderData(productData, setActiveStep) {
  const secretHeader = [];
  const secretParam = [];
  productData.prodDetailedInfo.secretHeaderParamData.map((data) => {
    if (data.type === 'header') {
      secretHeader.push({
        name: data.name,
        value: data.value,
        description: data.description,
        _id: data.id
      });
    } else {
      secretParam.push({
        name: data.name,
        value: data.value,
        description: data.description,
        _id: data.id
      });
    }
    return true;
  });
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    },
    productDetailedInfo: {}
  };

  reqJson.productDetailedInfo.accessControls = {};
  reqJson.productDetailedInfo.accessControls.secretHeaders = secretHeader;
  reqJson.productDetailedInfo.accessControls.secretParameter = secretParam;
  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          productData.prodBasicInfo.productId +
          '?',
        reqJson
      )
      .then((response) => {
        const newSecretHeader = [];
        const newSecretParam = [];
        let secretHeaderParamData = [];
        const headerIds = [];
        const paramIds = [];
        response.data.accessControls.secretHeaders.map((headerData) =>
          headerIds.push(headerData._id)
        );

        response.data.accessControls.secretParameter.map((paramData) =>
          paramIds.push(paramData._id)
        );

        response.data.accessControls.secretHeaders.map((header) => {
          newSecretHeader.push({
            name: header.name,
            value: header.value,
            description: header.description,
            id: header._id,
            type: 'header'
          });
          return true;
        });

        response.data.accessControls.secretParameter.map((parameter) => {
          newSecretParam.push({
            name: parameter.name,
            value: parameter.value,
            description: parameter.description,
            id: parameter._id,
            type: 'parameter'
          });
          return true;
        });
        secretHeaderParamData = newSecretHeader.concat(newSecretParam);
        productData.prodDetailedInfo.secretHeaderParamData = secretHeaderParamData;
        dispatch({
          type: 'SAVE_SECRET_HEADER_PARAM',
          payload: productData
        });
      })

      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function resetProduct() {
  return {
    type: 'RESET_PRODUCT'
  };
}

export function reduxStorage(data, type, obj) {
  if (type === 'businessCategories') {
    data[obj].productBusinessCategoryMap = [];
    const productBusinessCategoryMap = [];
    data.prodBasicInfo.businessCategories.map((value) => {
      productBusinessCategoryMap.push({
        businessCategory: {
          businessCategoryId: value.id
        }
      });
      return true;
    });

    data[obj].productBusinessCategoryMap = productBusinessCategoryMap;
  }
  return {
    type: 'REDUX_STORAGE',
    payload: data
  };
}

export function updateInfo(data, key, condition) {
  if (key === 'reset') {
    data['isEdit'] = false;
    data['isApiEdited'] = false;
    data['isBasicInfoEdited'] = false;
    data['isPlanEdited'] = false;
    data['descriptionChanged'] = false;
    data['nonApprovalFieldChanged'] = false;
    data['settingsChanged'] = false;
    data['apiAdded'] = false;
    data['visited'] = false;
    data['isDraft'] = true;
    data['showPopup'] = false;
    data['isView'] = false;
    data['isViewEdit'] = false;
    data['resetValidation'] = true;
    data['showDashBoard'] = false;
    data['isConsumer'] = false;
    data['throttlingChanged'] = false;
    data['isThrottlingEdited'] = false;
    data['activeStep'] = 1;
    data['isDocumentationEdited'] = false;
    data['throttlePolicyId'] = '';
    data['isTryOutPlanEdited'] = false;
  } else {
    data[key] = condition;
  }
  return {
    type: 'UPDATE_INFO',
    payload: data
  };
}

const getEndpointsData = (response, endPointsData) => {
  response.data.endPointDetails.map((data) => {
    const header = [];
    const parameter = [];
    const formData = [];
    const formUrlEncoded = [];
    const body = [];
    let count = '';
    const relativePathData = [];
    let mappedGroup = [];
    data.headers.map((headerData) => {
      header.push({
        id: headerData._id,
        name: headerData.name,
        dataType: headerData.datatype,
        description: headerData.description,
        required: headerData.isRequired,
        parameter: parameterConstants.headerConst
      });
      return true;
    });

    data.parameters.map((paramData) => {
      parameter.push({
        id: paramData._id,
        name: paramData.name,
        dataType: paramData.datatype,
        description: paramData.description,
        required: paramData.isRequired,
        parameter: parameterConstants.queryConst
      });
      return true;
    });

    data.formdata?.map((form) => {
      formData.push({
        id: form._id,
        name: form.name,
        dataType: form.datatype,
        description: form.description,
        required: form.isRequired,
        parameter: parameterConstants.formDataConst
      });
      return true;
    });

    data.formurlEncoded?.map((form) => {
      formUrlEncoded.push({
        id: form._id,
        name: form.name,
        dataType: form.datatype,
        description: form.description,
        required: form.isRequired,
        parameter: parameterConstants.formUrlEncodedConst
      });
      return true;
    });

    data.body?.map((bodyData) => {
      body.push({
        id: bodyData._id,
        name: bodyData.name,
        dataType: bodyData.datatype,
        description: bodyData.description,
        required: bodyData.isRequired,
        parameter: parameterConstants.rawBodyConst
      });
      return true;
    });

    data.pathParameter?.map((pathData) => {
      relativePathData.push({
        Name: pathData.name,
        DataType: pathData.datatype,
        Required: pathData.isRequired,
        Description: pathData.description,
        id: pathData._id
      });
      return true;
    });

    mappedGroup = !!data.group ? data.group : mappedGroup;
    count =
      header.length +
      parameter.length +
      formData.length +
      formUrlEncoded.length +
      body.length;
    if (!!data.contentType) {
      endPointsData.push({
        name: data.name,
        description: data.description,
        method: data.method,
        relativePath: data.path,
        id: data._id,
        group: mappedGroup,
        header: header,
        query: parameter,
        formData: formData,
        formUrlEncoded: formUrlEncoded,
        body: body,
        contentType: data.contentType,
        paramCount: count,
        relativePathData: relativePathData,
        requestSample:
          data.requestSample?.length > 0 ? data.requestSample : undefined,
        responseSample:
          data.responseSample?.length > 0 ? data.responseSample : undefined
      });
    } else {
      endPointsData.push({
        name: data.name,
        description: data.description,
        method: data.method,
        relativePath: data.path,
        id: data._id,
        group: mappedGroup,
        header: header,
        query: parameter,
        formData: formData,
        formUrlEncoded: formUrlEncoded,
        chargeableStatusCode: data.chargeableStatusCode,
        body: body,
        paramCount: count,
        relativePathData: relativePathData,
        requestSample:
          data.requestSample?.length > 0 ? data.requestSample : undefined,
        responseSample:
          data.responseSample?.length > 0 ? data.responseSample : undefined
      });
    }
    return true;
  });
  return endPointsData;
};

export function deleteGroup(dataObject, productId, prodDetailedInfo) {
  if (dataObject.hasOwnProperty('id')) {
    const reqJson = {
      prodBasicInfo: {
        productCategory: {
          categoryId: IdConstants.api.categoryId
        }
      }
    };
    const groupGroups = [];
    groupGroups.push({
      groupName: dataObject.name,
      groupDesc: dataObject.description,
      index: dataObject.index,
      _id: dataObject.id
    });

    reqJson.productDetailedInfo = {};
    reqJson.productDetailedInfo.endpointGroups = groupGroups;
    return (dispatch) =>
      axios({
        method: 'DELETE',
        url: Constants.SOLUTION_ACTION.DELETE_PRODUCT + productId + '?',
        data: reqJson,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          const groupData = [];
          let endPointsData = [];
          response.data?.endpointGroups?.map((data) => {
            groupData.push({
              name: data.groupName,
              description: data.groupDesc,
              id: data._id,
              index: data.index
            });
            return true;
          });
          if (!!response.data.endPointDetails) {
            endPointsData = getEndpointsData(response, endPointsData);
          }

          prodDetailedInfo.groupData = groupData;
          prodDetailedInfo.endpointsData = endPointsData;
          dispatch({
            type: 'DELETE_GROUP',
            payload: prodDetailedInfo
          });
        })
        .catch((error) => {
          const response = error.response;
          if (response !== undefined) {
            dispatch({
              type: 'PRODUCT_INFO_ERROR',
              error: response.data
            });
          } else {
            dispatch({
              type: 'ERROR',
              error: error
            });
          }
        });
  }
}

const deleteSpecificEndpoint = (response, prodDetailedInfo, dispatch) => {
  const groupDataFromRes = [];
  const endPointsData = [];
  let mappedGroup = [];
  response.data.endpointGroups?.map((data) => {
    groupDataFromRes.push({
      name: data.groupName,
      description: data.desc,
      id: data._id
    });
    return true;
  });

  response.data.endPointDetails.map((data) => {
    const headerFromRes = [];
    const parameterFromRes = [];
    const formDataFromRes = [];
    const formUrlEncodedFromRes = [];
    const bodyFromRes = [];
    let count = '';
    const relativePathData = [];
    const chargeableStatusCodeData = [];
    data.headers?.map((headerData) => {
      headerFromRes.push({
        id: headerData._id,
        name: headerData.name,
        dataType: headerData.datatype,
        description: headerData.description,
        required: headerData.isRequired,
        parameter: parameterConstants.headerConst
      });
      return true;
    });

    data.parameters?.map((paramData) => {
      parameterFromRes.push({
        id: paramData._id,
        name: paramData.name,
        dataType: paramData.datatype,
        description: paramData.description,
        required: paramData.isRequired,
        parameter: parameterConstants.queryConst
      });
      return true;
    });

    data.formdata?.map((form) => {
      formDataFromRes.push({
        id: form._id,
        name: form.name,
        dataType: form.datatype,
        description: form.description,
        required: form.isRequired,
        parameter: parameterConstants.formDataConst
      });
      return true;
    });

    data.formurlEncoded?.map((form) => {
      formUrlEncodedFromRes.push({
        id: form._id,
        name: form.name,
        dataType: form.datatype,
        description: form.description,
        required: form.isRequired,
        parameter: parameterConstants.formUrlEncodedConst
      });
      return true;
    });

    data.body?.map((bodyData) => {
      bodyFromRes.push({
        id: bodyData._id,
        name: bodyData.name,
        dataType: bodyData.datatype,
        description: bodyData.description,
        required: bodyData.isRequired,
        parameter: parameterConstants.rawBodyConst
      });
      return true;
    });

    data.pathParameter?.map((pathData) => {
      relativePathData.push({
        Name: pathData.name,
        DataType: pathData.datatype,
        Required: pathData.isRequired,
        Description: pathData.description,
        id: pathData._id
      });
      return true;
    });

    data.chargeableStatusCode?.map((chargeableData) => {
      chargeableStatusCodeData.push(chargeableData);
      return true;
    });

    mappedGroup = data.group;
    count =
      headerFromRes.length +
      parameterFromRes.length +
      formDataFromRes.length +
      formUrlEncodedFromRes.length +
      bodyFromRes.length;
    const chargeableStatusCodeDataValue =
      chargeableStatusCodeData.length > 0 ? chargeableStatusCodeData : [];
    const requestSampleValue =
      data.requestSample?.length > 0 ? data.requestSample : undefined;
    const responseSampleValue =
      data.responseSample?.length > 0 ? data.responseSample : undefined;
    if (!!data.contentType) {
      endPointsData.push({
        name: data.name,
        description: data.description,
        method: data.method,
        relativePath: data.path,
        id: data._id,
        group: mappedGroup,
        header: headerFromRes,
        query: parameterFromRes,
        formData: formDataFromRes,
        chargeableStatusCodeData: chargeableStatusCodeDataValue,
        formUrlEncoded: formUrlEncodedFromRes,
        body: bodyFromRes,
        contentType: data.contentType,
        paramCount: count,
        relativePathData: relativePathData,
        requestSample: requestSampleValue,
        responseSample: responseSampleValue,
        index: data.index
      });
    } else {
      endPointsData.push({
        name: data.name,
        description: data.description,
        method: data.method,
        relativePath: data.path,
        id: data._id,
        group: mappedGroup,
        header: headerFromRes,
        query: parameterFromRes,
        formData: formDataFromRes,
        chargeableStatusCode: chargeableStatusCodeDataValue,
        formUrlEncoded: formUrlEncodedFromRes,
        body: bodyFromRes,
        paramCount: count,
        relativePathData: relativePathData,
        requestSample: requestSampleValue,
        responseSample: responseSampleValue,
        index: data.index
      });
    }
    return true;
  });

  prodDetailedInfo.groupData = groupDataFromRes;
  prodDetailedInfo.endpointsData = endPointsData;

  dispatch({
    type: 'DELETE_ENDPOINT',
    payload: prodDetailedInfo
  });
};

export function deleteEndpoint(
  dataObject,
  apiMethods,
  productId,
  prodDetailedInfo,
  groupData
) {
  if (!dataObject.hasOwnProperty('id')) return;
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    }
  };
  const endpointData = [];
  const mappedgroup = [];
  const header = [];
  const parameter = [];
  const formData = [];
  const formUrlEncoded = [];
  const body = [];
  const pathParameter = [];
  const chargeableStatusCodeData = [];

  dataObject.relativePathData?.map((path) => {
    pathParameter.push({
      isRequired: path.Required,
      datatype: path.DataType,
      name: path.Name,
      description: path.Description,
      _id: path.id
    });
    return true;
  });

  const methodObj = apiMethods.find(
    (element) => element.description === dataObject.method
  );
  if (methodObj) {
    dataObject.method = methodObj.id;
  }
  dataObject.group?.map((group) => {
    if (groupData.some((obj) => obj.name === group)) {
      mappedgroup.push(group);
    }
    return true;
  });

  dataObject.header?.map((headerData) => {
    header.push({
      name: headerData.name,
      datatype: headerData.dataType,
      description: headerData.description,
      isRequired: headerData.required,
      _id: headerData.id
    });
    return true;
  });

  dataObject.query?.map((queryData) => {
    parameter.push({
      name: queryData.name,
      datatype: queryData.dataType,
      description: queryData.description,
      isRequired: queryData.required,
      _id: queryData.id
    });
    return true;
  });

  dataObject.formData?.map((form) => {
    formData.push({
      name: form.name,
      datatype: form.dataType,
      description: form.description,
      isRequired: form.required,
      _id: form.id
    });
    return true;
  });

  dataObject.formUrlEncoded?.map((encoded) => {
    formUrlEncoded.push({
      name: encoded.name,
      datatype: encoded.dataType,
      description: encoded.description,
      isRequired: encoded.required,
      _id: encoded.id
    });
    return true;
  });

  dataObject.chargeableStatusCode?.map((chargeableData) => {
    chargeableStatusCodeData.push(chargeableData);
    return true;
  });

  dataObject.body?.map((bodyData) => {
    body.push({
      name: bodyData.name,
      datatype: bodyData.dataType,
      description: bodyData.description,
      isRequired: bodyData.required,
      _id: bodyData.id
    });
    return true;
  });

  endpointData.push({
    name: dataObject.name,
    method: dataObject.method,
    path: dataObject.relativePath,
    group: mappedgroup,
    description: dataObject.description,
    _id: dataObject.id,
    headers: header,
    parameters: parameter,
    chargeableStatusCode: chargeableStatusCodeData,
    formdata: formData.length > 0 ? formData : undefined,
    formurlEncoded: formUrlEncoded.length > 0 ? formUrlEncoded : undefined,
    body: body.length > 0 ? body : undefined,
    pathParameter: pathParameter,
    requestSample:
      dataObject.requestSample?.length > 0 ? dataObject.requestSample : undefined,
    responseSample:
      dataObject.responseSample?.length > 0 ? dataObject.responseSample : undefined,
    index: dataObject.index
  });
  reqJson.productDetailedInfo = {};
  reqJson.productDetailedInfo.endPointDetails = endpointData;
  return (dispatch) =>
    axios({
      method: 'DELETE',
      url: Constants.SOLUTION_ACTION.DELETE_PRODUCT + productId + '?',
      data: reqJson,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        deleteSpecificEndpoint(response, prodDetailedInfo, dispatch);
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function deleteEndpointParameter(
  dataObject,
  index,
  productId,
  prodDetailedInfo
) {
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    }
  };
  let endpointDeleteName = '';
  const headerData = [];
  const paramData = [];
  const formData = [];
  const formUrlEncoded = [];
  const body = [];

  const paramObj = {
    isRequired: dataObject.required,
    datatype: dataObject.dataType,
    name: dataObject.name,
    description: dataObject.description,
    _id: dataObject.id
  };
  reqJson.productDetailedInfo = {};
  switch (dataObject.parameter) {
    case parameterConstants.headerConst:
      endpointDeleteName = 'endPointDetails.' + index + '.headers';
      headerData.push(paramObj);
      reqJson.productDetailedInfo[endpointDeleteName] = headerData;
      break;
    case parameterConstants.queryConst:
      endpointDeleteName = 'endPointDetails.' + index + '.parameters';
      paramData.push(paramObj);
      reqJson.productDetailedInfo[endpointDeleteName] = paramData;
      break;
    case parameterConstants.formDataConst:
      endpointDeleteName = 'endPointDetails.' + index + '.formdata';
      formData.push(paramObj);
      reqJson.productDetailedInfo[endpointDeleteName] = formData;
      break;
    case parameterConstants.formUrlEncodedConst:
      endpointDeleteName = 'endPointDetails.' + index + '.formurlEncoded';
      formUrlEncoded.push(paramObj);
      reqJson.productDetailedInfo[endpointDeleteName] = formUrlEncoded;
      break;
    case parameterConstants.rawBodyConst:
      endpointDeleteName = 'endPointDetails.' + index + '.body';
      body.push(paramObj);
      reqJson.productDetailedInfo[endpointDeleteName] = body;
      break;
    default:
      break;
  }

  return (dispatch) =>
    axios({
      method: 'DELETE',
      url: Constants.SOLUTION_ACTION.DELETE_PRODUCT + productId,
      data: reqJson,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        const groupData = [];
        const endPointsData = [];
        let mappedGroup = [];
        response.data.endpointGroups?.map((data) => {
          groupData.push({
            name: data.groupName,
            description: data.desc,
            id: data._id
          });
          return true;
        });

        response.data.endPointDetails.map((data) => {
          const relativePathData = [];
          if (data.pathParameter) {
            data.pathParameter.map((pathData) => {
              relativePathData.push({
                Name: pathData.name,
                DataType: pathData.datatype,
                Required: pathData.isRequired,
                Description: pathData.description,
                id: pathData._id
              });
              return true;
            });
          }

          const headerFromDeleteRes = [];
          const parameterFromDeleteRes = [];
          const formDataFromDeleteRes = [];
          const formUrlEncodedFromDeleteRes = [];
          const bodyFromDeleteRes = [];
          let count = '';

          data.headers?.map((headerData) => {
            headerFromDeleteRes.push({
              id: headerData._id,
              name: headerData.name,
              dataType: headerData.datatype,
              description: headerData.description,
              required: headerData.isRequired,
              parameter: parameterConstants.headerConst
            });
            return true;
          });

          data.parameters?.map((paramData) => {
            parameterFromDeleteRes.push({
              id: paramData._id,
              name: paramData.name,
              dataType: paramData.datatype,
              description: paramData.description,
              required: paramData.isRequired,
              parameter: parameterConstants.queryConst
            });
            return true;
          });

          data.formdata?.map((paramData) => {
            formDataFromDeleteRes.push({
              id: paramData._id,
              name: paramData.name,
              dataType: paramData.datatype,
              description: paramData.description,
              required: paramData.isRequired,
              parameter: parameterConstants.formDataConst
            });
            return true;
          });

          data.formurlEncoded?.map((paramData) => {
            formUrlEncodedFromDeleteRes.push({
              id: paramData._id,
              name: paramData.name,
              dataType: paramData.datatype,
              description: paramData.description,
              required: paramData.isRequired,
              parameter: parameterConstants.formUrlEncodedConst
            });
            return true;
          });

          data.body?.map((paramData) => {
            bodyFromDeleteRes.push({
              id: paramData._id,
              name: paramData.name,
              dataType: paramData.datatype,
              description: paramData.description,
              required: paramData.isRequired,
              parameter: parameterConstants.rawBodyConst
            });
            return true;
          });

          mappedGroup = data.group ?? mappedGroup;
          count =
            headerFromDeleteRes.length +
            parameterFromDeleteRes.length +
            formDataFromDeleteRes.length +
            formUrlEncodedFromDeleteRes.length +
            bodyFromDeleteRes.length;
          if (!!data.contentType) {
            endPointsData.push({
              name: data.name,
              description: data.description,
              method: data.method,
              relativePath: data.path,
              id: data._id,
              group: mappedGroup,
              header: headerFromDeleteRes,
              query: parameterFromDeleteRes,
              formData: formDataFromDeleteRes,
              formUrlEncoded: formUrlEncodedFromDeleteRes,
              body: bodyFromDeleteRes,
              contentType: data.contentType,
              paramCount: count,
              relativePathData: relativePathData,
              requestSample:
                data.requestSample?.length > 0 ? data.requestSample : undefined,
              responseSample:
                data.responseSample?.length > 0 ? data.responseSample : undefined
            });
          } else {
            endPointsData.push({
              name: data.name,
              description: data.description,
              method: data.method,
              relativePath: data.path,
              id: data._id,
              group: mappedGroup,
              header: headerFromDeleteRes,
              query: parameterFromDeleteRes,
              formData: formDataFromDeleteRes,
              formUrlEncoded: formUrlEncodedFromDeleteRes,
              body: bodyFromDeleteRes,
              paramCount: count,
              relativePathData: relativePathData,
              requestSample:
                data.requestSample?.length > 0 ? data.requestSample : undefined,
              responseSample:
                data.responseSample?.length > 0 ? data.responseSample : undefined
            });
          }
          return true;
        });

        prodDetailedInfo.endpointGroups = groupData;
        prodDetailedInfo.endpointsData = endPointsData;

        dispatch({
          type: 'DELETE_ENDPOINT_PARAMETER',
          payload: prodDetailedInfo
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
export function deleteSecretParam(dataObject, productId, prodDetailedinfo) {
  if (dataObject.hasOwnProperty('id')) {
    const reqJson = {
      prodBasicInfo: {
        productCategory: {
          categoryId: IdConstants.api.categoryId
        }
      }
    };
    const secretParameter = [];
    const secretHeader = [];
    let accessControlName = '';
    if (dataObject.type === 'header') {
      accessControlName = 'secretHeaders';
      secretHeader.push({
        name: dataObject.name,
        description: dataObject.description,
        _id: dataObject.id,
        value: dataObject.value
      });
    } else {
      accessControlName = 'secretParameter';
      secretParameter.push({
        name: dataObject.name,
        description: dataObject.description,
        _id: dataObject.id,
        value: dataObject.value
      });
    }
    reqJson.productDetailedInfo = {};
    reqJson.productDetailedInfo.accessControls = {};
    if (accessControlName === 'secretParameter')
      reqJson.productDetailedInfo.accessControls[accessControlName] =
        secretParameter;
    else
      reqJson.productDetailedInfo.accessControls[accessControlName] = secretHeader;
    return (dispatch) =>
      axios({
        method: 'DELETE',
        url: Constants.SOLUTION_ACTION.DELETE_PRODUCT + productId + '?',
        data: reqJson,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          let secretHeaderParamData = [];
          const secretHeaders = [];
          const secretParam = [];
          response.data.accessControls.secretHeaders.map((data) => {
            secretHeaders.push({
              name: data.name,
              value: data.value,
              description: data.description,
              id: data._id,
              type: 'header'
            });
            return true;
          });
          response.data.accessControls.secretParameter.map((data) => {
            secretParam.push({
              name: data.name,
              value: data.value,
              description: data.description,
              id: data._id,
              type: 'parameter'
            });
            return true;
          });

          secretHeaderParamData = secretHeaders.concat(secretParam);
          prodDetailedinfo.secretHeaderParamData = secretHeaderParamData;
          dispatch({
            type: 'DELETE_SECRETPARAM',
            payload: prodDetailedinfo
          });
        })
        .catch((error) => {
          const response = error.response;
          if (response !== undefined) {
            dispatch({
              type: 'PRODUCT_INFO_ERROR',
              error: response.data
            });
          } else {
            dispatch({
              type: 'ERROR',
              error: error
            });
          }
        });
  }
}

export function validateProductUniqueness(name) {
  name = btoa(utf8Encode(name));
  return (dispatch) =>
    axios
      .get(
        Constants.SOLUTION_ACTION.PRODUCT_BASIC_INFO +
          `?name=${name}&categoryId=` +
          IdConstants.api.categoryId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'VALIDATE_PRODUCT_UNIQUENESS',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_NAME_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function resetProdNameError() {
  return {
    type: 'PRODUCT_NAME_ERROR',
    error: {}
  };
}

export function getProductDetailedInfo(productId, categoryId) {
  return (dispatch) =>
    axios
      .get(
        Constants.PRODUCT_DETAIL_ACTION.GET_PRODUCT_BY_ID +
          `${productId}?categoryId=` +
          categoryId +
          '&unifiedStructure=true&termsConditions=rtf&longDescription=rtf',
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: 'GET_PRODUCT_DETAILED_INFO',
            payload: response?.data?.data
          });
          dispatch({
            type: 'GET_HARDWARE_PRODUCT_DETAILED_INFO',
            payload: response?.data?.data
          });
          return response?.data?.data;
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'HARDWARE_PRODUCT_INFO_ERROR',
            error: response.data
          });
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function updateProductStatus(statusId, productId, props) {
  if (props.info.isConsumer) {
    return {
      type: 'CONSUMER',
      payload: {}
    };
  }

  const productName = props.prodBasicInfo.displayName;
  const reqJson = {
    data: {
      prodBasicInfo: {
        productCategory: {
          categoryId: IdConstants.api.categoryId
        },
        status: {
          statusId: statusId
        },
        comments: ''
      }
    },
    ...getUserDetailsData(
      props?.userCompanyStatus?.companyId,
      productId,
      IdConstants.api.categoryId
    )
  };
  return (dispatch) =>
    axios
      .patch(Constants.PRODUCT_STATECHANGE_CAPABILITIES + productId + `?`, reqJson, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        const gaCategory =
          props?.location?.state?.isEdit === true ? 'edit' : 'create';
        if (
          response.data?.data?.status.description ===
          IdConstants.submitted.description
        ) {
          //GA-312-API
          trackEvent(
            `${gaCategory}_product_submitted`,
            GAEventAction.productSubmitted,
            response?.data?.data.productName
          );
        }

        if (statusId === IdConstants.submitted.statusId) {
          const path = DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[0].url;
          props.history.push({
            pathname: path,
            state: {
              ...props.location.state,
              submitted: true,
              productName: productName
            }
          });
        }
        dispatch({
          type: 'SAVE_PRODUCT_STATUS_INFO',
          payload: response?.data?.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function publishApi(data, selectedIndex, categoryId) {
  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      },
      status: {
        statusId: 'ff841ba0-2141-4c57-b26a-d5af00e03782'
      }
    }
  };
  return (dispatch) =>
    axios
      .patch(Constants.SOLUTION_ACTION.PUBLISH_API + data.productId, reqJson, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response1) => {
        if (categoryId === IdConstants.api.categoryId) {
          //GA-313-API
          trackEvent(
            'create_product_published',
            GAEventAction.productPublished,
            response1.data?.productName
          );
        }
        const publishResponse = response1;
        axios
          .get(Constants.SOLUTION_ACTION.GET_ALL_PRODUCTS, {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {}
          })
          .then((response2) => {
            dispatch({
              type: 'PUBLISH_API',
              payload1: response2.data,
              payload2: publishResponse
            });
          })
          .catch((error) => {
            dispatch({
              type: 'PUBLISH_ERROR',
              error: error,
              selectedIndex: selectedIndex,
              errorMsg:
                'API has been Published, Please refresh the page to reflect the changes'
            });
          });
      })
      .catch((error) => {
        const response = error.response;
        if (
          response !== undefined &&
          response.data.error === errorCodes.publishErrorCode
        ) {
          dispatch({
            type: 'PUBLISH_ERROR',
            error: error,
            selectedIndex: selectedIndex,
            errorMsg:
              'API is already Published, Please refresh the page to reflect the changes'
          });
        } else {
          dispatch({
            type: 'PUBLISH_ERROR',
            error: error,
            selectedIndex: selectedIndex,
            errorMsg:
              'API is not Published due to some error. Please try again later.'
          });
        }
      });
}

export function resetPublishError() {
  return {
    type: 'PUBLISH_ERROR',
    error: null
  };
}
export function resetPublishInfo() {
  return {
    type: 'RESET_PUBLISH_API',
    payload: {}
  };
}

export function uploadSwaggerFile(swaggerFile) {
  const bodyFormData = new FormData();
  const fileExtension = swaggerFile.name
    .slice(swaggerFile.name.lastIndexOf('.') + 1)
    .toLowerCase();
  let blobType = null;
  switch (fileExtension) {
    case 'json':
      blobType = { type: 'application/json' };
      break;

    case 'yaml':
      blobType = { type: 'text/yaml' };
      break;

    default:
      blobType = { type: 'application/octet-stream' };
  }
  bodyFormData.append('swaggerFile', new Blob([swaggerFile], blobType));
  bodyFormData.append('categoryId', IdConstants.api.categoryId);
  return (dispatch) =>
    axios({
      method: 'POST',
      url: Constants.SOLUTION_ACTION.SWAGGER_UPLOAD,
      data: bodyFormData,
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
      .then((response) => {
        resetProduct();
        dispatch({
          type: 'UPLOAD_SWAGGER_FILE',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'UPLOAD_SWAGGER_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
export function resetProdEndError() {
  return {
    type: 'PRODUCT_ENDPOINT_ERROR',
    error: null
  };
}

export function resetErrorInfo() {
  return {
    type: 'PRODUCT_INFO_ERROR',
    error: {}
  };
}

export function getEditableAttributes(productId) {
  return (dispatch) =>
    axios
      .get(
        Constants.SOLUTION_ACTION.GET_EDITABLE_ATTRIBUTES +
          `${productId}?categoryId=` +
          IdConstants.api.categoryId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        if (response.data)
          dispatch({
            type: 'EDITABLE_ATTRIBUTES_INFO',
            payload: response.data
          });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function getEditableDetails(productId) {
  return (dispatch) =>
    axios
      .get(Constants.SOLUTION_ACTION.GET_EDITABLE_DETAILS + `${productId}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        if (response.data)
          dispatch({
            type: 'EDITABLE_DETAILS_INFO',
            payload: response.data
          });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function resetEditableDetails() {
  return {
    type: 'EDITABLE_DETAILS_INFO',
    payload: {}
  };
}

export function deleteProduct(data, id, searchText) {
  return (dispatch) =>
    axios
      .delete(Constants.PRODUCT_SERVICE_URL + '/' + data.productId, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch(
          getProductsByStatus(id, searchText, 0, 15, [], data.productCategoryId)
        );
        dispatch({
          type: 'DELETE_PROD',
          payload: response
        });
      })
      .catch((error) => {
        const response = error.response;
        dispatch({
          type: 'DELETE_PROD_ERROR',
          error: response.data
        });
        if (
          response.data.error ===
          errorConstants.deleteProductError.pricingServicePublished
        ) {
          dispatch({
            type: 'DELETE_PROD_CONSUMED',
            errorMsg:
              'Deletion cannot be done as there are subscribers associated to this API'
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: response
          });
        }
        return response;
      });
}

export function saveBreadCrumbValue(value) {
  return {
    type: 'SET_BREADCRUMB_VALUE',
    payload: value
  };
}

export function validateProductNameEdit(productId) {
  return (dispatch) =>
    axios
      .get(
        Constants.SOLUTION_ACTION.VALIDATE_PRODUCT_NAME_EDIT +
          `?productId=${productId}&categoryId=${IdConstants.api.categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'VALIDATE_PROD_NAME_EDIT',
          payload: !response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_EDIT_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
