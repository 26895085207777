import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import {
  getFileUploadConfig,
  getQuotationDocuments,
  postQuotationDocuments,
  deleteQuotationDocuments,
  postQuotation,
  getQuotationFormValues,
  getQuotationPrice
} from '../../../../actions/QuotationAction';
import idConstants from '../../../../data/idConstants';
import { GAEventAction } from '../../../../data/ga-constants';
import { currencySymbols, quoteStatus } from '../../../../data/appConstants';
import {
  NumberInput,
  ImageUpload,
  UserManual,
  Notification,
  NavigationButtons,
  DialogBox,
  AttachmentCard,
  ThumbnailCard,
  PreviewModal
} from '../../../../msreact-kit/packages/html-components';
import {
  downloadFile,
  handleCheckUploadType
} from '../../../../shared/shared-function/services';
import {
  TimeoutConstant,
  regexValues
} from '../../../../shared/constants/field-constants';
import { DynamicFieldConstants } from '../../../../shared/constants';
import Images from '../../../../assets/img';
import SnackBar from '../../../Shared/Utils/SnackBar';
import { trackEvent } from '../../../../utils/analytics';
import './index.scss';

const OuotationSummaryCard = () => {
  const [formValues, setFormValues] = useState({});
  const [bulkTotalAmount, setBulkTotalAmount] = useState(0);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [showSuccessBoxModal, setShowSuccessBoxModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackBarObject, setSnackBarObject] = useState({
    snackbar: false,
    message: '',
    severity: ''
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const idleTimer = createRef();

  const locationState = history.location.state;
  const { quotationId, productMapId, status } = locationState;
  const autoSaveIn = TimeoutConstant.quotationFormAutoSave / 1000;

  const {
    quotationForm,
    sellerQuotationDocuments,
    buyerQuotationDocuments,
    buyerQuotationImages,
    sellerQuotationImages,
    sellerDocumentConfig,
    sellerImageConfig,
    userCompanyStatus,
    quotationFormValues
  } = useSelector((state) => {
    return {
      quotationForm: state.QuotationReducer.quotationForm,
      buyerQuotationDocuments: state.QuotationReducer.buyerQuotationDocuments,
      sellerQuotationDocuments: state.QuotationReducer.sellerQuotationDocuments,
      buyerQuotationImages: state.QuotationReducer.buyerQuotationImages,
      sellerQuotationImages: state.QuotationReducer.sellerQuotationImages,
      sellerDocumentConfig: state.QuotationReducer.sellerDocumentConfig,
      sellerImageConfig: state.QuotationReducer.sellerImageConfig,
      quotationFormValues: state.QuotationReducer.quotationFormValues,
      userCompanyStatus: state.CompanyDetailsReducer.userCompanyStatus
    };
  });

  useEffect(() => {
    dispatch(
      getQuotationDocuments(quotationId, idConstants.quotationDocumentType.buyerDoc)
    );
    dispatch(
      getQuotationDocuments(quotationId, idConstants.quotationDocumentType.buyerImg)
    );

    getSellerDocuments(idConstants.quotationDocumentType.sellerDoc);
    getSellerDocuments(idConstants.quotationDocumentType.sellerImg);

    dispatch(getFileUploadConfig(idConstants.quotationDocumentType.sellerImg));
    dispatch(getFileUploadConfig(idConstants.quotationDocumentType.sellerDoc));

    dispatch(getQuotationFormValues(quotationId));

    if (
      isSubmitted() ||
      status.quotationStatusDescription === quoteStatus.inDraft.description
    ) {
      handleQuotationPrice();
    }
  }, []);

  const getSellerDocuments = (documentId) => {
    dispatch(getQuotationDocuments(quotationId, documentId, productMapId));
  };

  const handleChange = (event) => {
    setBulkTotalAmount('');
    setFormValues((prevState) => ({
      ...prevState,
      [event.name]: event.value
    }));
    setErrors((prevState) => ({
      ...prevState,
      [event.name]: event.error
    }));
  };

  const handleTotalAmt = (event) => {
    setFormValues({});
    setBulkTotalAmount(event.value);
    setErrors({
      [event.name]: event.error
    });
  };

  const handleGetTotalAmt = () => {
    if (bulkTotalAmount) return bulkTotalAmount;

    const values = Object.values(formValues);
    let netTotalAmount = 0;

    values.forEach((value) => {
      const num = parseFloat(value);
      if (!isNaN(num)) {
        netTotalAmount += num;
      }
    });

    return netTotalAmount;
  };

  const handleUploadFile = async (imageFile, documentId) => {
    for (const image of imageFile.value) {
      await dispatch(
        postQuotationDocuments(image, quotationId, documentId, productMapId)
      );
    }
    getSellerDocuments(documentId);
  };

  const handleImageUpload = (imageFile) => {
    handleUploadFile(imageFile, idConstants.quotationDocumentType.sellerImg);
  };

  const handleFileUpload = (imageFile) => {
    handleUploadFile(imageFile, idConstants.quotationDocumentType.sellerDoc);
  };

  const handlePreviewModal = (file = null) => {
    setShowPreviewModal((prevState) => !prevState);
    setPreviewData(file);
  };

  const handleViewDocument = (data) => {
    if (data.blobFileUrl) {
      downloadFile(data.blobFileUrl, data.documentName).then(() => {
        dispatch(loading(false));
      });
    }
  };

  const handleDeleteFile = (file, documentId) => {
    dispatch(deleteQuotationDocuments(quotationId, file.uid)).then((response) => {
      if (response) {
        getSellerDocuments(documentId);
      }
    });
  };

  const handlePrevious = () => history.goBack();

  const getPayload = () => {
    const payload = {
      categoryId: quotationForm[0].productCategoryId,
      quotationId: quotationId,
      companyId: userCompanyStatus.companyId,
      quotationProductMapId: productMapId,
      subCategoryId: locationState.productSubCategory.productSubCategoryId,
      bulkTotalAmount: bulkTotalAmount ? bulkTotalAmount : 0,
      netTotalAmount: handleGetTotalAmt(),
      attributes: {}
    };

    if (Object.keys(payload).length > 0) {
      payload.attributes = formValues;
    }
    return payload;
  };

  const handleSubmit = () => {
    const payload = getPayload();
    payload.state = quoteStatus.submitted.description;
    dispatch(postQuotation(payload)).then((res) => {
      if (res) {
        handleSuccessDialogBox();
        //GA-601
        trackEvent(
          'request_for_quotation',
          GAEventAction.quotationSubmitted,
          'Request for Quotation',
          handleGetTotalAmt()
        );
      }
    });
  };

  const handleSuccessDialogBox = () => {
    setShowSuccessBoxModal((prevState) => !prevState);
  };

  const handleQuotationPrice = () => {
    dispatch(getQuotationPrice(quotationId, productMapId)).then((res) => {
      if (res) {
        if (Object.keys(res.attributes).length > 0) {
          setFormValues(res.attributes);
        } else {
          setBulkTotalAmount(res.bulkTotalAmount);
        }
      }
    });
  };

  const isSubmitted = () =>
    !(
      status.quotationStatusDescription === quoteStatus.inDraft.description ||
      status.quotationStatusDescription === quoteStatus.requested.description
    );

  const isError = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleDisableSubmitBtn = () => {
    return isSubmitted() || isError(errors) || handleGetTotalAmt() <= 0;
  };

  const handleGetValue = (type, value) => {
    switch (type) {
      case DynamicFieldConstants.TEXT:
      case DynamicFieldConstants.NUMBER:
      case DynamicFieldConstants.TEXT_AREA:
        return value;
      case DynamicFieldConstants.SINGLE_SELECT:
        return value?.value;
      default:
        return;
    }
  };

  const handleIdle = () => {
    if (!handleDisableSubmitBtn()) {
      const payload = getPayload();
      payload.state = quoteStatus.inDraft.description;
      dispatch(postQuotation(payload)).then((res) => {
        if (res) {
          setSnackBarObject({
            snackbar: true,
            message: i18next.t('DYNAMIC_FORM.SNACKBAR.YOUR_CHANGES_ARE_AUTOSAVE'),
            severity: 'success'
          });
        }
      });
    }
  };

  const handleSnackBar = () => {
    setSnackBarObject((prevState) => {
      return {
        ...prevState,
        snackbar: false
      };
    });
  };

  return (
    <div className='quotation-card'>
      <p className='quotation-card__title highlight -size-xl'>
        {i18next.t('QUOTATIONS.QUOTATION_SUMMARY')}
      </p>
      <div className='quotation-card__overview'>
        <div className='quotation-card__overview__container'>
          <div className='quotation-card__title-wrapper'>
            <p className='highlight -size-l'>
              {locationState?.productSubCategory?.productSubCategoryName}
            </p>
            <div className='quotation-card__category-wrapper'>
              <p className='text'>{i18next.t('QUOTATIONS.PROFESSIONAL_SERVICE')}</p>
            </div>
          </div>
        </div>
        <div className='quotation-card__form'>
          {quotationForm?.length > 0 &&
            quotationForm[0].attributes.map((item) => (
              <div key={item.name} className='quotation-card__form__container'>
                <div>
                  <p className='-size-s quotation-card__form__data'>
                    {item.displayName}
                  </p>
                  <p className='-size-m quotation-card__form__data'>
                    {quotationFormValues?.attributes &&
                    Object.keys(quotationFormValues?.attributes).length > 0
                      ? handleGetValue(
                          item.attributeType,
                          quotationFormValues?.attributes[item.name]
                        )
                      : '-'}
                  </p>
                </div>
                <div>
                  <NumberInput
                    displayName={i18next.t('DYNAMIC_FORM.SHARED.ENTER_PRICE')}
                    description={i18next.t(
                      'QUOTATIONS.CURRENCY_TO_ACCEPT_FROM_BUYER'
                    )}
                    name={item.name}
                    handleChange={handleChange}
                    value={formValues[item.name]}
                    placeHolder={`${currencySymbols.INR} 0.00`}
                    validation={{
                      rule: {
                        required: {
                          isRequired: false
                        },
                        regex: {
                          pattern: regexValues.priceRegex
                        }
                      }
                    }}
                    disabled={isSubmitted()}
                    error={errors[item.name]}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className='quotation-card__dash'>
          <div className='quotation-card__dash__line' />
          <p className='quotation-card__dash__or'>
            {i18next.t('DYNAMIC_FORM.LABEL.OR')}
          </p>
          <div className='quotation-card__dash__line' />
        </div>
        <div className='quotation-card__total-cost'>
          <NumberInput
            displayName={i18next.t('QUOTATIONS.TOTAL_PRICE_QUOTATION_IN_DOCUMENT')}
            name='totalPrice'
            placeHolder={'0.00'}
            handleChange={handleTotalAmt}
            value={bulkTotalAmount}
            validation={{
              rule: {
                required: {
                  isRequired: false
                },
                regex: {
                  pattern: regexValues.priceRegex
                }
              }
            }}
            disabled={isSubmitted()}
            error={errors['totalPrice']}
          />
        </div>

        <div className='quotation-card__document-wrapper'>
          <p className='highlight -size-l quotation-card__document-title'>
            {i18next.t('QUOTATIONS.BUYER_UPLOADED_IMAGES')}
          </p>
          <div className='quotation-card__documents'>
            {buyerQuotationImages?.map((file, i) => (
              <div onClick={() => handlePreviewModal(file)} key={file?.id}>
                <ThumbnailCard
                  file={file}
                  mediaIcon={Images.imagery}
                  uploadType='uploadImage'
                  isView={true}
                />
              </div>
            ))}
            {buyerQuotationImages?.length === 0 && (
              <p>{i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.NO_DATA_FOUND')}</p>
            )}
          </div>

          <p className='highlight -size-l quotation-card__document-title'>
            {i18next.t('QUOTATIONS.BUYER_UPLOADED_DOCUMENTS')}
          </p>
          <div className='quotation-card__documents'>
            {buyerQuotationDocuments?.map((file, i) => (
              <div onClick={() => handleViewDocument(file)} key={file?.id}>
                <AttachmentCard file={file} isView={true} />
              </div>
            ))}
            {buyerQuotationImages?.length === 0 && (
              <p>{i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.NO_DATA_FOUND')}</p>
            )}
          </div>

          <p className='highlight -size-l quotation-card__document-title'>
            {i18next.t('QUOTATIONS.ADD_IMAGES_OF_YOUR_SERVICE')}
          </p>
          <ImageUpload
            handleChange={handleImageUpload}
            value={sellerQuotationImages}
            validation={{
              maxFiles: sellerImageConfig.maxFileCount,
              maxSize: sellerImageConfig.maxFileSize,
              supportedFileExtensions: sellerImageConfig?.allowedFileExtensions
                ?.split(',')
                .map((item) => {
                  return `.${item}`;
                })
            }}
            handleDeleteFile={(file) =>
              handleDeleteFile(file, idConstants.quotationDocumentType.sellerImg)
            }
            handlePreviewElement={handlePreviewModal}
            isView={isSubmitted()}
            disabled={isSubmitted()}
            isHideIcon={true}
          />

          <p className='highlight -size-l quotation-card__document-title'>
            {i18next.t('QUOTATIONS.ADD_DOCUMENTS')}
          </p>
          <UserManual
            handleChange={handleFileUpload}
            validation={{
              maxFiles: sellerDocumentConfig.maxFileCount,
              maxSize: sellerDocumentConfig.maxFileSize,
              supportedFileExtensions: sellerDocumentConfig?.allowedFileExtensions
                ?.split(',')
                .map((item) => {
                  return `.${item}`;
                })
            }}
            value={sellerQuotationDocuments}
            handlePreviewElement={handleViewDocument}
            handleDeleteFile={(file) =>
              handleDeleteFile(file, idConstants.quotationDocumentType.sellerDoc)
            }
            isView={isSubmitted()}
            disabled={isSubmitted()}
          />
        </div>
        <Notification
          cssName='quotation-card__disclaimer'
          content='Prices include taxes and other charges'
          icon='alert-info'
          isNotification={false}
          subContent={`${i18next.t('DYNAMIC_FORM.SHARED.GRAND_TOTAL')}*:  ${
            currencySymbols.INR
          }${handleGetTotalAmt()}`}
        />
        <p className='-size-m d-lg-none quotation-card__navigation-text'>
          {`${i18next.t(
            'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.AUTOSAVED_AFTER_INACTIVITY',
            {
              autoSaveIn
            }
          )}`}
        </p>
        <div className='quotation-card__navigation-buttons'>
          <NavigationButtons
            isShowCancelBtn={false}
            isAutosaved={!isSubmitted()}
            nextStepLabel={i18next.t('QUOTATIONS.SEND_QUOTE')}
            handleNext={handleSubmit}
            prevStepLabel={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.BACK')}
            handlePrevious={handlePrevious}
            nextStepDisabled={handleDisableSubmitBtn()}
            navigationMessage={`${i18next.t(
              'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.AUTOSAVED_AFTER_INACTIVITY',
              {
                autoSaveIn
              }
            )}`}
          />
        </div>

        {showPreviewModal && previewData && (
          <PreviewModal
            mediaUrl={previewData.blobFileUrl}
            isVideo={handleCheckUploadType(previewData.documentName)}
            handlePreviewModal={handlePreviewModal}
          />
        )}

        {showSuccessBoxModal && (
          <DialogBox
            primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
            title={`${i18next.t('QUOTATIONS.SUCCESSFULLY_QUOTE_SENT')}!`}
            subTitle={i18next.t('QUOTATIONS.QUOTATION_SENT_REQUESTED_BUYER')}
            isModal={true}
            isSuccess={true}
            isSecondaryBtn={false}
            handleConfirm={() => {
              handleSuccessDialogBox();
              history.goBack();
            }}
            handleCancel={() => {
              handleSuccessDialogBox();
              history.goBack();
            }}
          />
        )}
        <IdleTimer
          ref={idleTimer}
          timeout={TimeoutConstant.quotationFormAutoSave}
          onIdle={handleIdle}
        />
        {snackBarObject.snackbar && (
          <SnackBar
            severity={snackBarObject.severity}
            message={snackBarObject.message}
            changeState={handleSnackBar}
          />
        )}
      </div>
    </div>
  );
};

export default OuotationSummaryCard;
