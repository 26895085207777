import React from 'react';
import i18next from 'i18next';
import Images from '../../../assets/img';
import { TextInput } from '../../packages/html-components';
import { convertUTCToLocalDate } from '../../../utils/date-time-utils';
import CustomToolTip from '../../../components/Shared/custom-tooltip';
import idConstants from '../../../data/idConstants';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';
import './index.scss';

const SkuIdDetails = ({
  cardData,
  modalData,
  comments,
  planDetails,
  tenantProductStatus
}) => {
  const headerData = [
    { value: i18next.t('DYNAMIC_FORM.SKU_ID.PLAN'), cssName: 'plan-name' },
    { value: i18next.t('DYNAMIC_FORM.SKU_ID.SKU_ID'), cssName: 'sku-id' },
    {
      value: i18next.t('DYNAMIC_FORM.SKU_ID.BOSCH_SHARE'),
      cssName: 'bosch-share'
    },
    {
      value: i18next.t('DYNAMIC_FORM.SKU_ID.SELLER_SHARE'),
      cssName: 'seller-share'
    }
  ];

  const marketplaceHeader = [
    {
      value: i18next.t('DYNAMIC_FORM.TENANT_STATUS.MARKET_PLACE')
    },
    {
      value: i18next.t('DYNAMIC_FORM.SKU_ID.STATUS')
    }
  ];

  const cells = Array(4).fill('_');

  const renderTenantStatus = (status) => {
    switch (status) {
      case idConstants.published.description:
        return i18next.t('DYNAMIC_FORM.TENANT_STATUS.PUBLISHED');
      case idConstants.tenantRejected.description:
        return i18next.t('DYNAMIC_FORM.SHARED.REJECTED');
      case idConstants.tenantPublish.description:
      default:
        return i18next.t('DYNAMIC_FORM.TENANT_STATUS.AWAITING_APPROVAL');
    }
  };

  const mapStatusIcons = (status) => {
    switch (status) {
      case idConstants.published.description:
        return Images.alertSuccessGreen;
      case idConstants.tenantRejected.description:
        return Images.abortFrame;
      case idConstants.tenantPublish.description:
      default:
        return Images.alertSuccessOrange;
    }
  };

  const getStatus = (status) => {
    if (status === idConstants.tenantPublish.description) {
      return i18next.t('DYNAMIC_FORM.TENANT_STATUS.PUBLISH_PENDING');
    }
    return status;
  };

  const renderTenantMarketplaceUI = () => {
    if (tenantProductStatus?.length > 0) {
      return (
        <div className='tenant-marketplace'>
          <p className='-size-l highlight'>
            {i18next.t('DYNAMIC_FORM.TENANT_STATUS.PRODUCT_PUBLICH_MARKETPLACE')}
          </p>
          <br />
          <div className='marketplace-table-wrapper-header'>
            {marketplaceHeader?.length > 0 &&
              marketplaceHeader.map((data) => (
                <p key={data.value} className='marketplace-table-header size-m'>
                  {data.value}
                </p>
              ))}
          </div>
          {tenantProductStatus?.map((tenantItem, index) => (
            <div key={tenantItem?.tenant} className='marketplace-table-wrapper-col'>
              <div className='marketplace-table-wapper__flex'>
                <p>{tenantItem?.tenant}</p>
                <div className='tenant-marketplace__reason-wrapper'>
                  <p className='tenant-status-view'>
                    <img
                      className={`tenant-status-image ${
                        tenantItem?.statusDescription ===
                          idConstants.tenantRejected.description &&
                        'product_reject-status'
                      }`}
                      src={mapStatusIcons(tenantItem?.statusDescription)}
                    />
                    {renderTenantStatus(tenantItem?.statusDescription)}
                  </p>
                  {tenantItem?.rejectReasonDTO?.comments && (
                    <CustomToolTip
                      id={index}
                      tooltipData={tenantItem?.rejectReasonDTO?.comments}
                      isIcon={true}
                      width='dynamic'
                      className='tenant-marketplace__tooltip'
                    >
                      <div className='tooltip-endpoint text-ellipsis' id={index}>
                        <img
                          className='tenant-marketplace__info-icon'
                          src={Images.infoIcon}
                          alt={tenantItem?.rejectReasonDTO?.comments}
                        />
                      </div>
                    </CustomToolTip>
                  )}
                </div>
              </div>
              {tenantItem?.rejectReasonDTO?.description && (
                <div className='marketplace-table-wapper__reason'>
                  {i18next.t('SHARED.REASON')}:{' '}
                  {tenantItem?.rejectReasonDTO?.description}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className='generatesku-id__container'>
      <div className='generatesku-id__text-info-container'>
        <p className='-size-m'>
          {i18next.t('DYNAMIC_FORM.SKU_ID.STATUS')}:{' '}
          <span className='size-m highlight'>
            {getStatus(modalData?.status?.description)}
          </span>
        </p>
        <div className='generatesku-id__title-wrapper'>
          {`${IS_MKOSINT_TENANT ? i18next.t('DYNAMIC_FORM.SKU_ID.PUBLISHED_DATE') : i18next.t('DYNAMIC_FORM.SKU_ID.APPROVED_DATE')}: ${convertUTCToLocalDate(modalData?.createdDate)}`}
        </div>
        {comments && (
          <div className='generatesku-id__title-wrapper'>
            {`${i18next.t('DYNAMIC_FORM.SKU_ID.APPROVAL_COMMENTS')}: ${comments}`}
          </div>
        )}
      </div>
      <div className='sku-table-container'>
        <table>
          <thead>
            <tr>
              {headerData?.length > 0 &&
                headerData.map((data) => (
                  <th key={data.value} className={`${data.cssName} size-m`}>
                    {data.value}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {cardData?.length > 0 ? (
              cardData.map((item) => (
                <tr key={item.planId}>
                  <td className='center-align  highlight'>
                    {planDetails?.length > 0 &&
                      planDetails.map(
                        (data) =>
                          data?.planId === item?.planId && (
                            <CustomToolTip
                              className='sku-table-container-customtooltip'
                              id={data?.planName}
                              tooltipData={data?.planName}
                              width='dynamic'
                            >
                              <p
                                key={data?.planId}
                                className='-size-m text-ellipsis'
                                id={data?.planName}
                              >
                                {data?.planName}
                              </p>
                            </CustomToolTip>
                          )
                      )}
                  </td>
                  <td>
                    <TextInput
                      value={item?.skuVariantId}
                      disabled={true}
                      cssName='skuid'
                    />
                  </td>
                  <td className='center-align -size-m highlight'>
                    {item?.platformShare + '%'}
                  </td>
                  <td className='center-align -size-m highlight'>
                    {item?.sellerShare + '%'}
                  </td>
                </tr>
              ))
            ) : (
              <tr className='center-align highlight'>
                {cells.map((cell) => (
                  <td key={cell}>{cell}</td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {renderTenantMarketplaceUI()}
    </div>
  );
};

export default SkuIdDetails;
