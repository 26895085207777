const initialState = {
  error: null
};

const ErrorReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'ERROR_PREVIEW':
      return Object.assign({}, state, { error: action.error });
    default:
      return state;
  }
};

export default ErrorReducer_Preview;
