import React, { useRef, useEffect, useState } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Images from '../../../assets/img';
import './index.scss';

const DatePicker = ({ name, value, changeHandler, validation }) => {
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        onClickOutside();
      }
    };

    const onClickOutside = () => {
      setIsOpen(false);
    };

    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event);
    });
    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event);
      });
    };
  }, []);

  let val = {};
  let required = {};
  if (validation) {
    val = validation;
    const { rule } = val;
    required = rule?.required;
  }

  const handleChange = (event) => {
    setIsOpen(!isOpen);
    setStartDate(event);
    const data = {
      value: moment(event).format('DD/MM/YYYY'),
      error: '',
      name: name
    };
    if (required?.isRequired && event.length === 0) {
      data.error = `${name} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
      data.isInValid = true;
    }

    changeHandler?.(data);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const getDate = () => {
    if (startDate) return moment(startDate).format('DD/MM/YYYY');

    return value ? value : 'DD/MM/YYYY';
  };

  return (
    <div className='date-picker' ref={datePickerRef}>
      <label>
        {name}
        {required?.isRequired && (
          <span className='dropdown_single__required'>*</span>
        )}
      </label>
      <button className='date-custom-input' onClick={handleClick}>
        {getDate()}
      </button>
      {isOpen && (
        <div className='date-picker__element'>
          <ReactDatePicker selected={startDate} onChange={handleChange} inline />
        </div>
      )}
      <img src={Images.calenderIcon} alt='calendar-icon' className='calendar-icon' />
    </div>
  );
};

export default DatePicker;
