import React from 'react';
import Images from '../../../assets/img';

import './index.scss';

const AddPricingPlanCard = ({ displayName, onClickCard }) => {
  return (
    <div className='add-pricing-card'>
      <div className='add-pricing-card__plan-button' onClick={onClickCard}>
        <img src={Images.addIcon} className='add-pricing-card__image-icon' alt='' />
        <p className='add-pricing-card__title'>{displayName}</p>
      </div>
    </div>
  );
};

export default AddPricingPlanCard;
