import React from 'react';

import OrderCard from '../order-card';

import './index.scss';

const OrderHistory = () => {
  return <OrderCard isHistory={true} />;
};

export default OrderHistory;
