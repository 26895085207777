import React from 'react';
import Select from '@material-ui/core/Select';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const theme = (theme) =>
  createTheme({
    overrides: {
      MuiSelect: {
        root: {
          background: '#EFEFF0',

          paddingTop: '14px',
          paddingBottom: '14px',
          paddingLeft: '13px',
          paddingRight: '13px'
        }
      }
    }
  });

export default function StylledSelect(props) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Select
          native
          className={props.className}
          value={props.value}
          fullWidth
          error={props.error}
          style={props.style}
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.onChange}
          disabled={props.disabled}
        >
          {props.data === undefined ? (
            <option></option>
          ) : (
            <React.Fragment>
              {props.dataval === 'brand' && (
                <option value=''>Select your brand</option>
              )}
              {(!props.value || props.dataval === 'company_registration') &&
                props.dataval !== 'brand' &&
                props.dataval !== 'select_form_parameter' && (
                  <option value=''>Please Select</option>
                )}
              {props.data.map((optionsValue) => (
                <option key={optionsValue.id} value={optionsValue.id}>
                  {!props.displayNameAttribute
                    ? optionsValue.name
                    : optionsValue[props.displayNameAttribute]}
                </option>
              ))}
            </React.Fragment>
          )}
        </Select>
        <FormHelperText style={{ color: 'red' }}>{props.helperText}</FormHelperText>
      </ThemeProvider>
    </div>
  );
}
