import React from 'react';
import IdConstants from '../../../data/idConstants';
import { GAEventAction } from '../../../data/ga-constants';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';

const orderManagementUrl = IS_MKOSINT_TENANT
  ? '/dashboard/order-history'
  : '/dashboard/order-management';

export const navigationOptions = [
  /*
        sidebarKey: add unique key, so that to highlight in left navigation it will be selected properly
        hasSubMenus: boolean - If any subMenus are there then add this flag
        subMenus: array - array of submenu items
        subItemlevel2: array - level2 submenu items
        possibleUrls: include common pathname in all possible routes from selected sidebar selection
        possible to move the menuitems position anywhere in it's current tree level, Not depending on index's

        currently side navigation supports only level2 submenu's, If we want to expand then add same logic like subItemlevel2
    */
  {
    sidebarKey: IdConstants.sidebarConstants.dashboard.key,
    title: 'DYNAMIC_FORM.NAVIGATION_OPTIONS.DASHBOARD',
    url: '/dashboard',
    icon: (
      <i
        className='a-icon boschicon-bosch-ic-home'
        title='DYNAMIC_FORM.NAVIGATION_OPTIONS.DASHBOARD'
      />
    ),
    hasSubMenus: false,
    disabled: false,
    displayOptions: false,
    //GA-200
    trackEventData: {
      eventCategory: 'side_bar',
      eventAction: GAEventAction.clicked,
      eventLabel: 'Dashboard'
    }
  },
  {
    sidebarKey: IdConstants.sidebarConstants.myProducts.key,
    title: 'DYNAMIC_FORM.NAVIGATION_OPTIONS.MY_PRODUCTS',
    icon: (
      <i
        className='a-icon boschicon-bosch-ic-grid-view'
        title='DYNAMIC_FORM.NAVIGATION_OPTIONS.MY_PRODUCTS'
      />
    ),
    url: '/dashboard/myproducts',
    hasSubMenus: true,
    disabled: false,
    displayOptions: false,
    //GA-201
    trackEventData: {
      eventCategory: 'side_bar',
      eventAction: GAEventAction.clicked,
      eventLabel: 'My Products'
    },
    subMenus: [
      {
        sidebarKey: IdConstants.sidebarConstants.apiProduct.key,
        title: 'DYNAMIC_FORM.NAVIGATION_OPTIONS.API',
        categoryId: IdConstants.api.categoryId,
        icon: (
          <svg
            className='MuiSvgIcon-root a-icon'
            focusable='false'
            viewBox='0 0 24 24'
            aria-hidden='true'
          >
            <path d='M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z'></path>
          </svg>
        ),
        url: '/dashboard/myproducts/myapi/all',
        possibleUrls: ['/dashboard/myproducts/myapi'],
        key: 'myapi-all',
        hasSubMenus: true,
        disabled: false,
        visibility: true,
        displayOptions: false,
        //GA-202
        trackEventData: {
          eventCategory: 'side_bar',
          eventAction: GAEventAction.clicked,
          eventLabel: 'API'
        },
        subItemlevel2: [
          {
            sidebarKey: IdConstants.sidebarConstants.apiStatistics.key,
            title: IdConstants.sidebarConstants.apiStatistics.title,
            url: '/dashboard/myproducts/myapi/apistatistics',
            icon: <i />,
            hasSubMenus: false,
            disabled: false,
            visibility: !IS_MKOSINT_TENANT,
            displayOptions: false,
            //GA-701
            trackEventData: {
              eventCategory: 'side_bar',
              eventAction: GAEventAction.clicked,
              eventLabel: 'API Statistics'
            }
          }
          // {
          //   sidebarKey: IdConstants.sidebarConstants.throttlePolicy.key,
          //   title: IdConstants.sidebarConstants.throttlePolicy.title,
          //   url: '/dashboard/myproducts/myapi/apithrottling',
          //   possibleUrls: [
          //     '/dashboard/myproducts/myapi/apithrottling/addthrottlingpolicy',
          //   ],
          //   icon: <i />,
          //   key: 'apithrottling',
          //   hasSubMenus: false,
          //   disabled: false,
          //   visibility: false,
          //   displayOptions: false,
          // },
          // Hiding Manage alerts for BTE event
          // {
          //     sidebarKey: IdConstants.sidebarConstants.apiAlerts.key,
          //     title: IdConstants.sidebarConstants.apiAlerts.title,
          //     url: '/dashboard/myproducts/myapi/apialerts',
          //     icon: <SettingsIcon />,
          //     hasSubMenus: false,
          //     disabled: false,
          //     visibility:true
          // }
        ]
      },
      {
        sidebarKey: IdConstants.sidebarConstants.cloudInfra.key,
        title: IdConstants.sidebarConstants.cloudInfra.title,
        // url: '/dashboard/myproducts/myCloudInfra/all',
        // possibleUrls: ['/dashboard/myproducts/myCloudInfra']
        // key: 'myCloudInfra-all',
        icon: <i />,
        hasSubMenus: false,
        disabled: true,
        visibility: false,
        displayOptions: false
      },
      {
        title: IdConstants.sidebarConstants.dataProduct.title,
        sidebarKey: IdConstants.sidebarConstants.dataProduct.key,
        icon: <i />,
        hasSubMenus: false,
        disabled: true,
        visibility: false,
        displayOptions: false
      }
    ]
  },
  // Order management and Order history menu should be commented while pushing to QA server.
  {
    sidebarKey: IdConstants.sidebarConstants.orderMangement.key,
    title: 'DYNAMIC_FORM.NAVIGATION_OPTIONS.ORDER_MANAGEMENT',
    icon: (
      <i
        className='a-icon boschicon-bosch-ic-delivery-checkmark no-rotate'
        title='DYNAMIC_FORM.NAVIGATION_OPTIONS.CART'
      />
    ),
    url: orderManagementUrl,
    hasSubMenus: true,
    disabled: false,
    displayOptions: false,
    //GA-500
    trackEventData: {
      eventCategory: 'side_bar',
      eventAction: GAEventAction.clicked,
      eventLabel: 'My Orders'
    },
    subMenus: [
      {
        sidebarKey: IdConstants.sidebarConstants.myOrders.key,
        title: 'DYNAMIC_FORM.NAVIGATION_OPTIONS.MY_ORDERS',
        icon: (
          <i
            className='a-icon boschicon-bosch-ic-box-checkmark'
            title='DYNAMIC_FORM.NAVIGATION_OPTIONS.MY_ORDERS'
          />
        ),
        url: '/dashboard/order-management',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: !IS_MKOSINT_TENANT,
        //GA-500
        trackEventData: {
          eventCategory: 'side_bar',
          eventAction: GAEventAction.clicked,
          eventLabel: 'My Orders'
        }
      },
      {
        sidebarKey: IdConstants.sidebarConstants.orderHistory.key,
        title: 'DYNAMIC_FORM.NAVIGATION_OPTIONS.ORDER_HISTORY',
        icon: (
          <i
            className='a-icon boschicon-bosch-ic-consumer-goods'
            title='DYNAMIC_FORM.NAVIGATION_OPTIONS.ORDER_HISTORY'
          />
        ),
        url: '/dashboard/order-history',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: true,
        //GA-501
        trackEventData: {
          eventCategory: 'side_bar',
          eventAction: GAEventAction.clicked,
          eventLabel: 'Order History'
        }
      }
    ]
  },
  {
    sidebarKey: IdConstants.sidebarConstants.proposalRequest.key,
    title: 'QUOTATIONS.PROPOSAL_REQUEST',
    icon: (
      <i
        className='a-icon boschicon-bosch-ic-box-questionmark'
        title='QUOTATIONS.PROPOSAL_REQUEST'
      />
    ),
    url: '/dashboard/proposal-request',
    hasSubMenus: true,
    disabled: false,
    displayOptions: false,
    //GA-600
    trackEventData: {
      eventCategory: 'side_bar',
      eventAction: GAEventAction.clicked,
      eventLabel: 'Quotation Requests'
    },
    visibility: !IS_MKOSINT_TENANT,
    subMenus: [
      {
        sidebarKey: IdConstants.sidebarConstants.quotationsRequest.key,
        title: 'QUOTATIONS.REQUEST_FOR_QUOTATIONS',
        icon: (
          <i
            className='a-icon boschicon-bosch-ic-cubes'
            title='QUOTATIONS.REQUEST_FOR_QUOTATIONS'
          />
        ),
        url: '/dashboard/proposal-request',
        hasSubMenus: true,
        displayOptions: false,
        disabled: false,
        visibility: true,
        //GA-600
        trackEventData: {
          eventCategory: 'side_bar',
          eventAction: GAEventAction.clicked,
          eventLabel: 'Quotation Requests'
        }
      }
    ]
  }
  // {
  //   sidebarKey: 'LogoutKey',
  //   title: 'Logout',
  //   url: '/dashboard/logout',
  //   icon: <i className='a-icon boschicon-bosch-ic-login' title='logout' />,
  //   hasSubMenus: false,
  //   disabled: false,
  // },
  // Commenting as per MS-16579
  // {
  //   sidebarKey: IdConstants.sidebarConstants.faq.key,
  //   title: IdConstants.sidebarConstants.faq.title,
  //   url: '/dashboard/faq',
  //   icon: (
  //     <i className='a-icon boschicon-bosch-ic-question-frame' title='FAQ' />
  //   ),
  //   hasSubMenus: false,
  //   disabled: false,
  // },
];

export default navigationOptions;
