const initialState = {
  isRegistrationCheckDone: false,
  companyDetailsError: '',
  userCompanyStatus: '',
  errorUserCompanyStatus: '',
  viewMSCompanyDetails: '',
  viewUserCompanyStatus: '',
  mismatchInDcsAndMsData: false
};

const CompanyDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_COMPANY_ERROR':
      return { ...state, companyDetailsError: action.payload };
    case 'IS_REG_CHECK_DONE':
      return { ...state, isRegistrationCheckDone: action.payload };
    case 'USER_COMPANY_STATUS':
      return { ...state, userCompanyStatus: action.payload };
    case 'ERROR_USER_COMPANY_STATUS':
      return { ...state, errorUserCompanyStatus: action.payload };
    case 'ERROR_MS_COMPANY_DETAILS':
      return { ...state, errorMsCompanyDetails: action.payload };
    case 'MS_VIEW_COMPANY_DETAILS':
      return { ...state, viewMSCompanyDetails: action.payload };
    case 'USER_VIEW_COMPANY_STATUS':
      return { ...state, viewUserCompanyStatus: action.payload };
    case 'MISMATCH_IN_DCS_MS_COMPANY_DATA':
      return { ...state, mismatchInDcsAndMsData: action.payload };
    default:
      return state;
  }
};
export default CompanyDetailsReducer;
