import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { vertical, horizontal } = { vertical: 'top', horizontal: 'center' };

  const handleClose = () => {
    setOpen(false);
    props.changeState();
  };

  return (
    <div className={classes.root}>
      {(() => {
        if (props.from !== undefined && props.from === 'codesnippet') {
          return (
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={() => handleClose()}
              anchorOrigin={props.anchorOrigin}
            >
              <Alert severity={props.severity}>{props.message}</Alert>
            </Snackbar>
          );
        } else {
          return (
            <Snackbar
              open={open}
              autoHideDuration={4000}
              onClose={() => handleClose()}
              anchorOrigin={{ vertical, horizontal }}
            >
              <Alert severity={props.severity}>{props.message}</Alert>
            </Snackbar>
          );
        }
      })()}
    </div>
  );
}
