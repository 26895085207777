export const onboardingFormForMkosInt = [
  {
    attributeId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
    name: 'configurations',
    displayName: 'Add configuration details for the product',
    description: '',
    xpath: '/productDetailedInfo/docs/configurations',
    jsonType: null,
    attributeType: 'FILE',
    placeHolder: null,
    displayOrder: 6,
    isVisibleForBuyer: false,
    isElementRequired: null,
    stateChangeOnEdit: null,
    isEncryptable: null,
    validation: null,
    nonEditableAttributeValidation: null,
    optionType: null,
    options: [
      {
        propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
        documentTypeId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentTypeName: 'Configurations',
        configValue: 'application/pdf,application/doc,application/docx'
      },
      {
        propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
        documentTypeId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentTypeName: 'Configurations',
        configValue: '2097152'
      },
      {
        propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
        documentTypeId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentTypeName: 'Configurations',
        configValue: 'json'
      },
      {
        propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
        documentTypeId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentId: 'ff432103-f21e-4dfd-9c2c-5880595297c5',
        documentTypeName: 'Configurations',
        configValue: '1'
      }
    ],
    attributes: null,
    sections: []
  },
  {
    attributeId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
    name: 'rules',
    displayName: 'Rules',
    description: '',
    xpath: '/productDetailedInfo/docs/rules',
    jsonType: null,
    attributeType: 'FILE',
    placeHolder: null,
    displayOrder: 6,
    isVisibleForBuyer: false,
    isElementRequired: null,
    stateChangeOnEdit: null,
    isEncryptable: null,
    validation: null,
    nonEditableAttributeValidation: null,
    optionType: null,
    options: [
      {
        propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
        documentTypeId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentTypeName: 'Rules',
        configValue: 'application/pdf,application/doc,application/docx'
      },
      {
        propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
        documentTypeId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentTypeName: 'Rules',
        configValue: '2097152'
      },
      {
        propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
        documentTypeId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentTypeName: 'Rules',
        configValue: 'pdf,doc,docx'
      },
      {
        propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
        documentTypeId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentId: '42f98a8a-9fcc-4904-bc9a-9e0cef92861f',
        documentTypeName: 'Rules',
        configValue: '1'
      }
    ],
    attributes: null,
    sections: []
  },
  {
    attributeId: '',
    name: 'prerequisites',
    displayName: 'Prerequisites',
    description: '',
    xpath: '/productDetailedInfo/prerequisites',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 2,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
        name: 'prerequisitesFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/prerequisites/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentTypeName: 'Prerequisites',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentTypeName: 'Prerequisites',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentTypeName: 'Prerequisites',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentId: '64e2d566-c8da-4a03-b98c-af1c60f01d29',
            documentTypeName: 'Prerequisites',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'history',
    displayName: 'History',
    description: '',
    xpath: '/productDetailedInfo/history',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 2,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
        name: 'historyFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/history/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentTypeName: 'History',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentTypeName: 'History',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentTypeName: 'History',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentId: 'ebd36307-f218-4b03-aeec-65a4bd00421a',
            documentTypeName: 'History',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'processAndMethods',
    displayName: 'Process and Methods',
    description: '',
    xpath: '/productDetailedInfo/processAndMethods',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 2,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
        name: 'processMethodsFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/processAndMethods/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentTypeName: 'Process and Methods',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentTypeName: 'Process and Methods',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentTypeName: 'Process and Methods',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentId: '1e922887-fe30-4cfd-b823-fe9c3a562bc8',
            documentTypeName: 'Process and Methods',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'dataOwnerShip',
    displayName: 'Data Ownership',
    description: '',
    xpath: '/productDetailedInfo/dataOwnerShip',
    jsonType: 'STRING',
    attributeType: 'RICHTEXTEDITOR',
    placeHolder: 'Add the Privacy or Data Ownership details here',
    displayOrder: 13,
    isVisibleForBuyer: true,
    isElementRequired: true,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: 'Field is mandatory'
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: null,
    sections: []
  },
  {
    attributeId: '',
    name: 'userManual',
    displayName: 'User Manual',
    description: '',
    xpath: '/productDetailedInfo/userManual',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 2,
    isVisibleForBuyer: true,
    isElementRequired: true,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: 'Field is mandatory'
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
        name: 'userManualFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/userManual/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentTypeName: 'User/Operator Manual',
            configValue: '2097152'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentTypeName: 'User/Operator Manual',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentTypeName: 'User/Operator Manual',
            configValue: '2'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentId: '614ff7e1-f0ca-4a28-af13-9622410067f4',
            documentTypeName: 'User/Operator Manual',
            configValue: 'application/pdf,application/doc,application/docx'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'brochure',
    displayName: 'Brochure',
    description: '',
    xpath: '/productDetailedInfo/brochure',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 2,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
        name: 'brochureFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/brochure/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentTypeName: 'Brochure',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentTypeName: 'Brochure',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentTypeName: 'Brochure',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentId: '7213a9a7-f7f2-486b-a59f-176afb8d504e',
            documentTypeName: 'Brochure',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'productLifecycle',
    displayName: 'Product Life Cycle',
    description: '',
    xpath: '/productDetailedInfo/productLifecycle',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 3,
    isVisibleForBuyer: true,
    isElementRequired: true,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: 'Field is mandatory'
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '7e729882-19db-4279-8531-7fec60658fdb',
        name: 'productLifeCycleFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/productLifecycle/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 3,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentTypeName: 'Product Life Cycle',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentTypeName: 'Product Life Cycle',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentTypeName: 'Product Life Cycle',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentId: '7e729882-19db-4279-8531-7fec60658fdb',
            documentTypeName: 'Product Life Cycle',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'contract',
    displayName: 'Contract',
    description: '',
    xpath: '/productDetailedInfo/contract',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 4,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
        name: 'contractFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/contract/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 4,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentTypeName: 'Contract',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentTypeName: 'Contract',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentTypeName: 'Contract',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentId: '1b787ab5-ccdc-4cf2-93c6-081e70e99488',
            documentTypeName: 'Contract',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'legal',
    displayName: 'Legal',
    description: '',
    xpath: '/productDetailedInfo/legal',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 5,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: [
      {
        attributeId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
        name: 'legalFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/legal/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 5,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentTypeName: 'Legal',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentTypeName: 'Legal',
            configValue: '2097152'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentTypeName: 'Legal',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentId: 'acacd435-ed5e-4c20-9a59-0e8a3a8d9f3d',
            documentTypeName: 'Legal',
            configValue: '1'
          }
        ],
        attributes: null,
        sections: []
      }
    ],
    sections: []
  },
  {
    attributeId: '',
    name: 'sla',
    displayName: 'SLA',
    description: '',
    xpath: '/productDetailedInfo/sla',
    jsonType: 'STRING',
    attributeType: 'TEXTANDFILE',
    placeHolder: '',
    displayOrder: 2,
    isVisibleForBuyer: true,
    isElementRequired: false,
    stateChangeOnEdit: true,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: 'Field is mandatory'
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
        name: 'prerequisitesFile',
        displayName: '',
        description: null,
        xpath: '/productDetailedInfo/docs/prerequisites/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentTypeName: 'SLA',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentTypeName: 'SLA',
            configValue: '1'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentTypeName: 'SLA',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentId: 'ca08d44b-56c3-4338-821f-21692c2a00f9',
            documentTypeName: 'SLA',
            configValue: '2097152'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  },
  {
    attributeId: '',
    name: 'compliances',
    displayName: 'Compliances',
    description: '',
    xpath: '/productDetailedInfo/docs/compliances',
    jsonType: null,
    attributeType: 'TEXTANDFILE',
    placeHolder: null,
    displayOrder: 6,
    isVisibleForBuyer: false,
    isElementRequired: true,
    stateChangeOnEdit: true,
    isEncryptable: true,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: 'Field is mandatory'
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: Object.assign([
      {
        attributeId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
        name: 'compliancesFile',
        displayName: '',
        descritpion: null,
        xpath: '/productDetailedInfo/docs/compliances/file',
        jsonType: null,
        attributeType: 'FILE',
        placeHolder: null,
        displayOrder: 2,
        isVisibleForBuyer: false,
        isElementRequired: null,
        stateChangeOnEdit: null,
        isEncryptable: null,
        validation: null,
        nonEditableAttributeValidation: null,
        optionType: null,
        options: [
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileFormats',
            documentTypeId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentTypeName: 'Compliances',
            configValue: 'application/pdf,application/doc,application/docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.maxFileSizeInBytes',
            documentTypeId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentTypeName: 'Compliances',
            configValue: '2097152'
          },
          {
            propertyKey: 'com.bosch.mss.fileUpload.supportedFileExtensions',
            documentTypeId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentTypeName: 'Compliances',
            configValue: 'pdf,doc,docx'
          },
          {
            propertyKey: 'com.bosch.mss.fileupload.maxFileCount',
            documentTypeId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentId: '9bd797f8-af07-4519-9c57-86bcf989e29e',
            documentTypeName: 'Compliances',
            configValue: '5'
          }
        ],
        attributes: null,
        sections: []
      }
    ]),
    sections: []
  }
];
