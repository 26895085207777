import axios from 'axios';
import * as Constants from '../configuration';
import IdConstants from '../../data/idConstants';
import { parameterConstants } from '../../data/appConstants';

export function getParamDataTypes() {
  return (dispatch) =>
    axios
      .get(
        Constants.ENDPOINTS_ACTION.GET_PARAMETER_DATATYPES +
          `?categoryId=${IdConstants.api.categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PARAM_DATA_TYPES',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getMappedParamUrlOptions() {
  return (dispatch) =>
    axios
      .get(
        Constants.ENDPOINTS_ACTION.GET_PARAM_URL_OPTIONS +
          `?categoryId=${IdConstants.api.categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_PARAM_URL_OPTIONS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function saveGroupData(product) {
  const groupGroups = [];
  const groupResponse = [];
  product.prodDetailedInfo.groupData.map((data, index) => {
    groupGroups.push({
      groupName: data.name ? data.name.trim() : '',
      groupDesc: data.description ? data.description.trim() : '',
      index: index,
      _id: data.id
    });
    return true;
  });

  const reqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    }
  };
  reqJson.productDetailedInfo = {};
  reqJson.productDetailedInfo.endpointGroups = groupGroups;
  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          product.prodBasicInfo.productId +
          '?',
        reqJson,

        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        response.data.endpointGroups.map((data, index) => {
          groupResponse.push({
            name: data.groupName ? data.groupName.trim() : '',
            description: data.groupDesc ? data.groupDesc.trim() : '',
            index: index,
            id: data._id
          });
          return true;
        });
        product.prodDetailedInfo.groupData = groupResponse;
        dispatch({
          type: 'SAVE_ENDPOINT_INFO',
          payload: product.prodDetailedInfo
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR', //error for group
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

const populateEndpointsDetails = (data, endpointIds) => {
  const headerIds = [];
  const paramIds = [];
  const pathIds = [];
  const formDataIds = [];
  const formUrlEncodedIds = [];
  const bodyIds = [];
  let associatedGroup = [];
  const chargeableStatusCodeData = [];
  if (!!data.group) {
    associatedGroup = data.group;
  }
  data.headers?.map((headerData) => {
    headerIds.push({
      id: headerData._id,
      name: headerData.name,
      dataType: headerData.datatype,
      description: headerData.description,
      required: headerData.isRequired,
      parameter: parameterConstants.headerConst
    });
    return true;
  });
  data.parameters?.map((paramData) => {
    paramIds.push({
      id: paramData._id,
      name: paramData.name,
      dataType: paramData.datatype,
      description: paramData.description,
      required: paramData.isRequired,
      parameter: parameterConstants.queryConst
    });
    return true;
  });
  data.formdata?.map((form) => {
    formDataIds.push({
      id: form._id,
      name: form.name,
      dataType: form.datatype,
      description: form.description,
      required: form.isRequired,
      parameter: parameterConstants.formDataConst
    });
    return true;
  });
  data.formurlEncoded?.map((formEncodedData) => {
    formUrlEncodedIds.push({
      id: formEncodedData._id,
      name: formEncodedData.name,
      dataType: formEncodedData.datatype,
      description: formEncodedData.description,
      required: formEncodedData.isRequired,
      parameter: parameterConstants.formUrlEncodedConst
    });
    return true;
  });
  data.body?.map((bodyData) => {
    bodyIds.push({
      id: bodyData._id,
      name: bodyData.name,
      dataType: bodyData.datatype,
      description: bodyData.description,
      required: bodyData.isRequired,
      parameter: parameterConstants.rawBodyConst
    });
    return true;
  });
  data.pathParameter?.map((pathData) => {
    pathIds.push({
      id: pathData._id,
      Name: pathData.name,
      DataType: pathData.datatype,
      Description: pathData.description,
      Required: pathData.isRequired
    });
    return true;
  });
  data.chargeableStatusCode?.map((chargeableData) => {
    chargeableStatusCodeData.push(chargeableData);
    return true;
  });
  endpointIds.push({
    id: data._id,
    headers: headerIds,
    parameters: paramIds,
    formData: formDataIds,
    formUrlEncoded: formUrlEncodedIds,
    body: bodyIds,
    pathParams: pathIds,
    chargeableStatusCodeData:
      chargeableStatusCodeData.length > 0 ? chargeableStatusCodeData : [],
    associatedGroup: associatedGroup,
    contentType: data.contentType ? data.contentType : undefined,
    count:
      headerIds.length +
      paramIds.length +
      formDataIds.length +
      formUrlEncodedIds.length +
      bodyIds.length,
    requestSample: data.requestSample?.length > 0 ? data.requestSample : undefined,
    responseSample: data.responseSample?.length > 0 ? data.responseSample : undefined
  });
  return endpointIds;
};

export function saveEndpointData(product) {
  const endpointData = [];
  product.prodDetailedInfo.endpointsData.map((data, index) => {
    const headers = [];
    const parameters = [];
    const relativePathData = [];
    const formUrlEncoded = [];
    const formData = [];
    const body = [];
    let mappedgroup = [];
    const chargeableStatusCodeData = [];
    const methodObj = product?.apiMethods?.find(
      (element) => element.description === data.method
    );
    if (methodObj) {
      data.method = methodObj.id;
    }

    data.group?.map((group, groupIndex) => {
      const index = product?.prodDetailedInfo?.groupData.findIndex(
        (element) => element.name === group
      ); //groupGroups
      index === -1 && data.group.splice(groupIndex, 1);
      return true;
    });

    mappedgroup = data.group ?? mappedgroup;

    data.relativePathData?.map((pathData) => {
      relativePathData.push({
        name: pathData.Name,
        datatype: pathData.DataType,
        isRequired: pathData.Required,
        description: pathData.Description
      });
      return true;
    });

    data.header?.map((headerData) => {
      headers.push({
        name: headerData.name,
        datatype: headerData.dataType,
        description: headerData.description,
        isRequired: headerData.required,
        _id: headerData.id
      });
      return true;
    });

    data.query?.map((queryData) => {
      parameters.push({
        name: queryData.name,
        datatype: queryData.dataType,
        description: queryData.description,
        isRequired: queryData.required,
        _id: queryData.id
      });
      return true;
    });

    data.formData?.map((form) => {
      formData.push({
        name: form.name,
        datatype: form.dataType,
        description: form.description,
        isRequired: form.required,
        _id: form.id
      });
      return true;
    });

    data.formUrlEncoded?.map((formUrlEncodedData) => {
      formUrlEncoded.push({
        name: formUrlEncodedData.name,
        datatype: formUrlEncodedData.dataType,
        description: formUrlEncodedData.description,
        isRequired: formUrlEncodedData.required,
        _id: formUrlEncodedData.id
      });
      return true;
    });

    data.chargeableStatusCode?.map((chargeableData) => {
      chargeableStatusCodeData.push(chargeableData);
      return true;
    });

    data.body?.map((bodyData) => {
      body.push({
        name: bodyData.name,
        datatype: bodyData.dataType,
        description: bodyData.description,
        isRequired: bodyData.required,
        _id: bodyData.id
      });
      return true;
    });

    endpointData.push({
      name: data.name ? data.name.trim() : '',
      method: data.method,
      path: data.relativePath,
      group: mappedgroup,
      _id: data.id,
      headers: headers,
      parameters: parameters,
      formdata: formData.length > 0 ? formData : undefined,
      formurlEncoded: formUrlEncoded.length > 0 ? formUrlEncoded : undefined,
      body: body.length > 0 ? body : undefined,
      description: data.description ? data.description.trim() : '',
      pathParameter: relativePathData,
      chargeableStatusCode: chargeableStatusCodeData,
      contentType: data.contentType ? data.contentType : undefined,
      requestSample: data.requestSample?.length > 0 ? data.requestSample : undefined,
      responseSample:
        data.responseSample?.length > 0 ? data.responseSample : undefined,
      index: index
    });
    return true;
  });
  const endpointReqJson = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.api.categoryId
      }
    }
  };
  endpointReqJson.productDetailedInfo = {};
  endpointReqJson.productDetailedInfo.endPointDetails = endpointData;

  return (dispatch) =>
    axios
      .patch(
        Constants.SOLUTION_ACTION.PRODUCT_ADDITIONAL_INFO +
          product.prodBasicInfo.productId +
          '?',
        endpointReqJson,

        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const groupIds = [];
        let endpointIds = [];
        const endpointDataFromRes = [];
        const groupGroups = [];
        if (response.data.endpointGroups !== undefined) {
          response.data.endpointGroups.map((data) => groupIds.push(data._id));
        }
        if (response.data.endPointDetails !== undefined) {
          response.data.endPointDetails.map((data) => {
            endpointIds = populateEndpointsDetails(data, endpointIds);
          });
        }

        product.prodDetailedInfo.groupData.map((data) => {
          groupGroups.push({
            name: data.name,
            description: data.description,
            id: groupIds[0]
          });
          groupIds.shift();
          return true;
        });
        product.prodDetailedInfo.endpointsData.map((data) => {
          const methodObj = product.apiMethods.find(
            (element) => element.id === data.method
          );
          if (methodObj) {
            data.method = methodObj.description;
          }
          endpointDataFromRes.push({
            name: data.name,
            method: data.method,
            relativePath: data.relativePath,
            group: endpointIds[0].associatedGroup,
            description: data.description,
            chargeableStatusCode: endpointIds[0].chargeableStatusCodeData,
            id: endpointIds[0].id,
            header: endpointIds[0].headers,
            query: endpointIds[0].parameters,
            formData: endpointIds[0].formData,
            formUrlEncoded: endpointIds[0].formUrlEncoded,
            body: endpointIds[0].body,
            contentType: endpointIds[0].contentType,
            paramCount: endpointIds[0].count,
            relativePathData: endpointIds[0].pathParams,
            requestSample: endpointIds[0].requestSample,
            responseSample: endpointIds[0].responseSample
          });
          endpointIds.shift();
          return true;
        });

        response.data.endPointDetails.forEach((data) => {
          endpointDataFromRes.forEach((item) => {
            if (item.id === data._id) {
              item.name = data.name;
              item.description = data.description;
            }
          });
        });

        product.prodDetailedInfo.groupData = groupGroups;
        product.prodDetailedInfo.endpointsData = endpointDataFromRes;
        dispatch({
          type: 'SAVE_ENDPOINT_INFO',
          payload: product.prodDetailedInfo
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR', //error for endpooint
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
