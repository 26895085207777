const errorConstants = {
  ThrottlingPolicyError: {
    duplicatePolicyNameError: 'E000248',
    mapToSubscribedApiError: 'E000268'
  },
  deleteProductError: {
    pricingServicePublished: 'E000489'
  },
  consentAlreadyAvailable: 'E000714'
};

export default errorConstants;
