const initialState = {
  allMasterData: {},
  pricingMasterData: {},
  pricingPlanMasterData: {},
  productCategories: []
};

const MasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_MASTER_DATA':
      return Object.assign({}, state, { allMasterData: action.payload });
    case 'GET_PRICING_PLAN_MASTER_DATA':
      return Object.assign({}, state, {
        pricingPlanMasterData: action.payload,
        pricingMasterData: action.payload
      });
    case 'GET_PRODUCT_CATEGORIES':
      return Object.assign({}, state, { productCategories: action.payload });
    default:
      return state;
  }
};

export default MasterDataReducer;
