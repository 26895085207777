import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@mui/lab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function ActionAlert(props) {
  const classes = useStyles();
  const [open] = React.useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onCloseAlert();
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          severity={props.severity}
          className={classes.fontStyle}
          action={
            <IconButton
              aria-label='close'
              color='error'
              size='small'
              onClick={() => {
                props.onCloseAlert();
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          <Grid>{props.message}</Grid>
          <Grid>{props.message1}</Grid>
          <Grid>{props.message2}</Grid>
        </Alert>
      </Collapse>
    </div>
  );
}
