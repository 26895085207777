import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Images from '../../../assets/img';
import { Link } from '@material-ui/core';
import './styles.scss';

const HeaderLogin = (props) => {
  return (
    <React.Fragment>
      <div className='header-login'>
        <div
          style={{ backgroundImage: `url(${Images.boschStripSmall})` }}
          className='bosch-strip header-login__strip-small'
        />
        <div className='header-login__container'>
          <Link component={RouterLink} to='/login' underline='none'>
            <img src={Images.boschLogo} title='Bosch logo' className='bosch-logo' />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderLogin;
