import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  Box,
  InputLabel,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Button from '../../../../Shared/Inputs/Button';
import TextField from '../../../../Shared/Inputs/TextField';
import FormValidator from '../../../../../validator/FormValidator';
import Select from '../../../../Shared/Inputs/Select';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IdConstants from '../../../../../data/idConstants';
import { errorCodes } from '../../../../../data/appConstants';
import TextFieldMat from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LightTooltip from '../../../../Shared/Tooltip';
import isEmpty from '../../../../../validator/isEmpty';
import Loader from '../../../../Shared/Utils/Loader';
import variables from '../../../../../variables.module.scss';
import i18next from 'i18next';

const nodeRSA = require('node-rsa');

const useStyles = {
  typoStyle: {
    float: 'right'
  },
  modalContentstyle: {
    fontFamily: variables.generalBoschFont
  },
  divStyle: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    fontFamily: variables.generalBoschFont,
    minWidth: '55rem',
    maxHeight: '41rem',
    overflowY: 'auto'
  },
  inputgroup: {
    marginTop: '20px',
    '& > *': {
      margin: '5px 0px',
      fontSize: '0.8rem',
      fontFamily: variables.mediumBoschFont,
      color: '#333'
    }
  },
  dataBandwidthOverPriceStyle: {
    margin: '0px'
  },
  inputgroupWidth: {
    marginTop: '20px',
    width: '66%',
    '& > *': {
      margin: '5px 0px',
      fontSize: '0.8rem',
      fontFamily: variables.mediumBoschFont,
      color: '#333'
    }
  },
  selectLabelStyle: {
    marginBottom: '10px'
  },
  boxStyle: {
    border: '1px solid lightgray',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '2px'
  },
  labelStyle: {
    fontSize: '0.8rem',
    fontFamily: variables.mediumBoschFont,
    color: '#333',
    width: 'auto',
    whiteSpace: 'nowrap'
  },
  gridStyle: {
    marginTop: '20px'
  },
  gridStylePricePerRequest: {
    marginTop: '12px'
  },
  dialogHeadingStyle: {
    fontFamily: variables.generalBoschFont,
    fontSize: '1.6rem',
    fontWeight: '100'
  },
  currencyStyle: {
    marginTop: '0.3rem'
  },
  priceStyle: {
    marginTop: '2rem'
  },
  currencyWidth: {
    width: '160%'
  },
  radioGroupStyle: {
    width: '400px',
    fontFamily: variables.generalBoschFont
  },
  formLabelStyle: {
    fontFamily: variables.generalBoschFont
  },
  centerAlignStyle: {
    textAlign: 'center'
  },
  dataBandwidthStyle: {
    marginRight: '0.5rem'
  },
  labelAsterisk: {
    color: 'red'
  },
  creatingFor: {
    marginTop: '20px',
    marginBottom: '-10px'
  },
  maxCharTxt: {
    fontSize: '0.7rem',
    color: '#8c8c8c'
  },
  autoCompleteStyle: {
    marginTop: '-20px'
  },
  fieldStyle: {
    fontSize: '0.8rem',
    fontFamily: variables.mediumBoschFont,
    color: '#333',
    marginTop: '1rem'
  },
  infoIconStyle: {
    marginTop: '0.8rem',
    marginLeft: '0.5rem',
    fontSize: '17px',
    cursor: 'pointer'
  },
  infoIcon: {
    fontSize: '17px',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  errorTxt: {
    color: 'red',
    fontSize: '0.9em',
    fontFamily: variables.mediumBoschFont
  },
  infoLimitStyle: {
    marginTop: '0.8rem',
    fontSize: '17px',
    marginLeft: '-10px',
    cursor: 'pointer'
  },
  marginStyle: {
    marginTop: '15px'
  },
  marginTopDivStyle: {
    marginBottom: '25px',
    marginTop: '0px !important'
  },
  reqCountStyles: {
    marginLeft: '100px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  formMarginStyle: {
    marginTop: '0px'
  },
  quotaLimitDivStyle: {
    marginBottom: '25px',
    marginTop: '0px'
  },
  infoStyles: {
    marginLeft: '40px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  limitInfoStyles: {
    marginLeft: '75px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  usageCountStyles: {
    marginLeft: '140px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  quotaDivStyle: {
    marginTop: '20px'
  },
  marginBottomDivStyle: {
    marginBottom: '0px'
  },
  labelStyles: {
    marginLeft: '20px'
  },
  userNameStyle: {
    marginLeft: '75px',
    marginTop: '-25px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  groupNameStyle: {
    marginLeft: '85px',
    marginTop: '-25px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  reqInfoStyles: {
    marginLeft: '110px',
    marginTop: '-25px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  dataLimitInfoStyles: {
    marginLeft: '80px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  quotaLimitInfoStyles: {
    marginLeft: '40px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  usageInfoStyles: {
    marginLeft: '140px',
    marginTop: '-25px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  quotaInfoStyles: {
    marginLeft: '90px',
    marginTop: '-30px',
    cursor: 'pointer',
    fontSize: '17px'
  },
  reqDivStyle: {
    marginTop: '10px'
  },
  selectDivStyle: {
    marginTop: '20px'
  },
  marginTopStyle: {
    marginTop: '10px'
  },
  paymentLabel: {
    fontWeight: 'bold',
    marginTop: '25px'
  },
  flexStyle: {
    display: 'flex'
  },
  infoBox: {
    cursor: 'pointer'
  },
  bottomMargin: {
    marginBottom: '15px'
  }
};

const quotaLimitData = [
  {
    id: '1',
    name: i18next.t('DYNAMIC_FORM.PRICING_PLAN.SOFT_LIMIT')
  },
  {
    id: '2',
    name: i18next.t('DYNAMIC_FORM.PRICING_PLAN.HARD_LIMIT')
  }
];

class PrivatePlanDialog extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: 'planName',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLAN_NAME_IS_REQUIRED')
      },
      {
        field: 'description',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DESCRIPTION_IS_REQUIRED'
        )
      },
      {
        field: 'planName',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLEASE_ENTER_ALPHANUMERIC_CHARACTER'
        ),
        args: ['ALPHANUMERIC']
      },
      {
        field: 'price',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICE_IS_REQUIRED')
      },
      {
        field: 'requestCount',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.PRICING_PLAN.REQUEST_COUNT_IS_REQUIRED')
      },
      {
        field: 'overUsagePrice',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.PRICING_PLAN.OVER_USAGE_PRICE_IS_REQUIRED')
      },
      {
        field: 'dataBandwidth',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DATA_BANDWIDTH_IS_REQUIRED'
        )
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.BURST_CONTROL_LIMIT_IS_REQUIRED'
        )
      },
      {
        field: 'pricePerRequest',
        obj: 'currentPlan',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PRICE_PER_REQUEST_IS_REQUIRED'
        )
      },
      {
        field: 'price',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['FLOAT']
      },
      {
        field: 'pricePerRequest',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['FLOAT']
      },
      {
        field: 'overUsagePrice',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['FLOAT']
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['NUMBER']
      },
      {
        field: 'requestCount',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['NUMBER']
      },
      {
        field: 'dataBandwidth',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['NUMBER']
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ENTER_A_POSITIVE_NUMBER'
        ),
        args: ['POSITIVENUMBER']
      },
      {
        field: 'price',
        obj: 'currentPlan',
        method: 'customValidator',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAXIMUM_LENGTH_EXCEEDED'
        ),
        args: ['PRICE']
      },
      {
        field: 'requestCount',
        obj: 'currentPlan',
        method: 'customValidator',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAXIMUM_LENGTH_EXCEEDED'
        ),
        args: ['REQUEST_COUNT']
      },
      {
        field: 'burstControlLimitCount',
        obj: 'currentPlan',
        method: 'customValidator',
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAXIMUM_LENGTH_EXCEEDED'
        ),
        validWhen: false,
        args: ['BURST_CONTROL']
      },
      {
        field: 'pricePerRequest',
        obj: 'currentPlan',
        method: 'customValidator',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAXIMUM_LENGTH_EXCEEDED'
        ),
        args: ['PRICE_PER_REQUEST']
      },
      {
        field: 'overUsagePrice',
        obj: 'currentPlan',
        method: 'customValidator',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAXIMUM_LENGTH_EXCEEDED'
        ),
        args: ['OVER_USAGE_PRICE']
      },
      {
        field: 'dataBandwidth',
        obj: 'currentPlan',
        method: 'customValidator',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAXIMUM_INTEGER_LENGTH_EXCEEDED'
        ),
        args: ['LIMIT_COUNT']
      }
    ]);
    let rateLimitTypes = this.props?.pricingMasterData?.rate_limit;
    if (rateLimitTypes?.length) {
      rateLimitTypes = rateLimitTypes.filter(
        (consumptionType) =>
          consumptionType.name !== IdConstants.pricing.consumptionNoMeasurementType
      );
    }
    if (rateLimitTypes[0].name === IdConstants.pricing.bandWidthRateLimitType) {
      rateLimitTypes = rateLimitTypes.reverse();
    }
    let billingCycleType = this.props?.pricingMasterData?.billing_cycle?.find(
      function (element) {
        return element.name === IdConstants.pricing.monthBillingCycleType;
      }
    );
    let rupees = this.props?.pricingMasterData?.currency?.find(function (element) {
      return element.name === IdConstants.pricing.rupeesCurrencyCode;
    });
    let rateLimitType = this.props?.pricingMasterData?.rate_limit?.find(
      function (element) {
        return element.name === IdConstants.pricing.reqCountLimitType;
      }
    );
    let quota = this.props?.pricingMasterData?.rate_limit_time_unit?.find(
      function (element) {
        return element.name === IdConstants.pricing.dailyRateTimeLimit;
      }
    );
    let bandwidthType = this.props?.pricingMasterData?.bandwidth?.find(
      function (element) {
        return element.name === IdConstants.pricing.kiloByte;
      }
    );
    let burstTimeUnit = this.props?.pricingMasterData?.burst_time_unit?.find(
      function (element) {
        return element.name === IdConstants.pricing.secondBurstControl;
      }
    );

    this.state = {
      currentPlan: {
        planName: '',
        description: '',
        billingCycleType: billingCycleType.name,
        price: 0,
        currencyCode: rupees.name,
        rateLimitType: rateLimitType.name,
        requestCount: '',
        quota: quota.name,
        isSoftLimit: true,
        overUsagePrice: '',
        dataBandwidth: '',
        bandwidthType: bandwidthType.name,
        burstControl: false,
        burstControlLimitCount: 1,
        burstTimeUnit: burstTimeUnit.name,
        pricePerRequest: 0,
        unlimitedPlan: false,
        isPlanSubscribed: false,
        paymentPreference: 'false'
      },
      saveButtonLabel: i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD'),
      bytes: 'KB',
      validation: this.validator.validPlan(),
      rateLimitTypes: rateLimitTypes,
      creatingForRadioValue: 'individual',
      privatePlan: true,
      user: '',
      userName: '',
      defaultUserName: null,
      businessGroup: '',
      businessGroupName: '',
      defaultGrpName: null,
      isValidGrp: false,
      isValidUser: false,
      isActive: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let plan = {};
    if (this.props.isEditPlan) {
      plan = this.props.editPlanDetails;
      this.setState({
        dialogHeading: i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.EDIT_PRIVATE_PRICING_PLAN'
        ),
        saveButtonLabel: i18next.t('DYNAMIC_FORM.LABEL.SAVE')
      });
      this.setCurrentPlan(plan);
    } else {
      this.setState({
        dialogHeading: i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.ADD_PRIVATE_PRICING_PLAN'
        ),
        saveButtonLabel: i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD')
      });
    }
  }

  approvedUsersFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>
      option.userFirstName + ' ' + option.userLastName + ' : ' + option.userEmailId1,
    ignoreCase: true
  });

  groupFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>
      option.organization.orgName + ' : ' + option.businessGroup,
    ignoreCase: true
  });

  handleChange(event) {
    this.setState({ creatingForRadioValue: event.target.value });
  }

  savePlan = () => {
    const validation = this.validator.validate('all', '', this.state);
    this.setState({ validation });
    let plan = { ...this.state.currentPlan };
    plan.planName = plan.planName.toUpperCase();
    plan.price =
      plan.price !== undefined && !isEmpty(plan.price)
        ? this.roundToDecimalValue(plan.price, 2)
        : '';
    plan.pricePerRequest =
      plan.pricePerRequest !== undefined && !isEmpty(plan.pricePerRequest)
        ? this.roundToDecimalValue(plan.pricePerRequest, 2)
        : '';
    plan.overUsagePrice =
      plan.overUsagePrice !== undefined && plan.overUsagePrice !== ''
        ? this.roundToDecimalValue(plan.overUsagePrice, 5)
        : '';
    if (plan.billingCycleType === IdConstants.pricing.payPerCallBillingCycleType) {
      plan.unlimitedPlan = true;
    } else {
      plan.unlimitedPlan = false;
    }
    this.setState(
      {
        currentPlan: plan
      },
      () => {
        if (this.props.isEditPlan) {
          this.props.updatePlan(this.state);
        } else {
          this.props.createPlan(this.state);
        }
      }
    );
  };

  roundToDecimalValue(number, limit) {
    if (typeof number === 'string') {
      number = parseFloat(number);
    }
    return number.toFixed(limit);
  }

  getPaymentPreference = (plan) => {
    if (plan.paymentPreference) {
      let public_key =
        '-----BEGIN PUBLIC KEY-----\n' +
        plan.paymentPreference.key +
        '\n' +
        '-----END PUBLIC KEY-----\n';
      let decryptionKey = new nodeRSA(public_key);
      return decryptionKey.decryptPublic(plan.paymentPreference.isRequired, 'utf8');
    } else {
      return 'false';
    }
  };

  setCurrentPlan = (plan) => {
    let selectedUserObj = this.props.approvedUsers.find(function (element) {
      return element.userEmailId1 === plan.user;
    });

    let selectedGrpObj = this.props.orgGrps.find(function (element) {
      return element.organizationBusinessGroupId === plan.businessGroup;
    });

    if (selectedUserObj) {
      this.setState({
        creatingForRadioValue: 'individual',
        defaultUserName: selectedUserObj,
        defaultGrpName: null
      });
    } else {
      this.setState({
        creatingForRadioValue: 'group',
        defaultUserName: null,
        defaultGrpName: selectedGrpObj
      });
    }
    this.setState({
      currentPlan: {
        planId: plan.planId,
        planName: plan.planName,
        description: plan.description,
        billingCycleType: plan.planBillingCycle.billingCycleType,
        price: plan.planCost,
        currencyCode: plan.currency.currencyCode,
        limitType: plan.rateLimit.rateLimitType.limitType,
        requestCount: plan.rateLimit.limitCount,
        quota: plan.rateLimit.timeUnit.timeUnitCode,
        isSoftLimit: plan.isSoftLimit,
        overUsagePrice: plan.softLimitData ? plan.softLimitData.additionalCost : 0,
        dataBandwidth: plan.rateLimit.limitCount,
        bandwidthType:
          plan.rateLimit.bandwidthUnitType !== null
            ? plan.rateLimit.bandwidthUnitType.bandWidthUnit
            : IdConstants.pricing.kiloByte,
        burstControl: plan.burstControlLimit !== null ? true : false,
        burstControlLimitCount:
          plan.burstControlLimit !== null ? plan.burstControlLimit.limitCount : 1,
        burstTimeUnit:
          plan.burstControlLimit !== null
            ? plan.burstControlLimit.timeUnit.timeUnitCode
            : IdConstants.pricing.secondBurstControl,
        pricePerRequest: plan.softLimitData ? plan.softLimitData.additionalCost : 0,
        unlimitedPlan: plan.planBillingCycle.unlimitedPlan,
        isPlanSubscribed: plan.cardContent
          ? plan.cardContent.isPlanSubscribed
          : false,
        paymentPreference: this.getPaymentPreference(plan)
      },
      validation: this.validator.validPlan(),
      privatePlan: plan.privatePlan,
      user: plan.user,
      userName: plan.userName,
      businessGroup: plan.businessGroup,
      businessGroupName: plan.businessGroupName,
      isValidGrp: true,
      isValidUser: true
    });
  };

  changeValue(event) {
    let name = event.target.name;
    let value = event.target.value;

    if (name === 'planName') {
      value = value.toUpperCase();
    }
    if (name === 'paymentPreferenceOption') {
      this.setState((prevState) => ({
        currentPlan: {
          ...prevState.currentPlan,
          paymentPreference: value
        }
      }));
    }
    // commenting this as this is leading to one security hotspot which can be reviewed later when the private plan dialog
    // actual requirement comes in for implementation as this is currently not in scope
    // if (
    //   (name === 'price' || name === 'overUsagePrice') &&
    //   !/^-?\d*\.?\d*$/.test(event.target.value)
    // ) {
    //   return;
    // }
    if (
      (name === 'dataBandwidth' ||
        name === 'requestCount' ||
        name === 'burstControlLimitCount') &&
      !/^\d*$/.test(event.target.value)
    ) {
      return;
    }
    if (name === 'isSoftLimit') {
      if (value === '1') {
        value = true;
      } else {
        value = false;
      }
    }
    if (name === 'bandwidthType') {
      let bytes = this.props.pricingMasterData.bandwidth.find(function (element) {
        return element.name === value;
      });
      this.setState({
        bytes: bytes.name
      });
    }
    this.setState(
      (prevState) => ({
        currentPlan: {
          ...prevState.currentPlan,
          [name]: value
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, 'currentPlan', this.state)
        });
      }
    );
  }

  handleCheckBoxChange = (event) => {
    this.setState({
      currentPlan: {
        ...this.state.currentPlan,
        burstControl: event.target.checked
      }
    });
  };

  validateForm = (validation) => {
    let currentPlan = this.state.currentPlan;
    let status = validation.currentPlan;
    if (!this.state.isValidUser) {
      return false;
    }
    if (!this.state.isValidGrp) {
      return false;
    }
    if (!isEmpty(this.props.validationError)) {
      return false;
    }
    if (
      currentPlan.planName === '' ||
      currentPlan.description === '' ||
      status.planName.isInvalid ||
      status.description.isInvalid
    ) {
      return false;
    }
    if (
      currentPlan.billingCycleType === IdConstants.pricing.payPerCallBillingCycleType
    ) {
      if (status.pricePerRequest.isInvalid || currentPlan.pricePerRequest == '') {
        return false;
      }
    } else {
      if (status.price.isInvalid) {
        return false;
      }
      if (currentPlan.limitType === IdConstants.pricing.bandWidthRateLimitType) {
        if (status.dataBandwidth.isInvalid || currentPlan.dataBandwidth === '') {
          return false;
        }
      }
      if (currentPlan.limitType === IdConstants.pricing.reqCountLimitType) {
        if (status.requestCount.isInvalid || currentPlan.requestCount === '') {
          return false;
        }
      }
      if (currentPlan.isSoftLimit === true) {
        if (status.overUsagePrice.isInvalid || currentPlan.overUsagePrice === '') {
          return false;
        }
      }
    }
    if (currentPlan.burstControl) {
      if (
        status.burstControlLimitCount.isInvalid ||
        currentPlan.burstControlLimitCount == ''
      ) {
        return false;
      }
    }
    return true;
  };

  selectedOrgGrp(val) {
    this.setState({
      isActive: true
    });
    if (val) {
      this.setState({
        businessGroup: val.organizationBusinessGroupId,
        businessGroupName: val.businessGroup,
        user: '',
        userName: '',
        isValidGrp: true,
        isValidUser: true
      });
    } else {
      this.setState({
        isValidGrp: false
      });
    }
  }

  selectedUser(val) {
    this.setState({
      isActive: true
    });
    if (val) {
      this.setState({
        user: val.userEmailId1,
        userName: val.userFirstName + ' ' + val.userLastName,
        businessGroup: '',
        businessGroupName: '',
        isValidUser: true,
        isValidGrp: true
      });
    } else {
      this.setState({
        isValidUser: false
      });
    }
  }

  render() {
    const { classes } = this.props;
    let plan = this.state.currentPlan;
    let validator = this.validator.validate('', '', this.state);
    let validation = validator.prodInfo;
    let disableSave = true;
    let burstControlError = '';
    let disableFields = plan.isPlanSubscribed && this.props.isEditPlan;
    if (!validator.isValid) {
      disableSave = !this.validateForm(validation);
      if (plan.burstControl) {
        let burstControl = parseInt(plan.burstControlLimitCount, 10);
        let reqCount = parseInt(plan.requestCount, 10);
        if (
          plan.billingCycleType !== IdConstants.pricing.payPerCallBillingCycleType &&
          plan.limitType === IdConstants.pricing.reqCountLimitType &&
          burstControl >= reqCount
        ) {
          burstControlError = i18next.t(
            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.BURST_CONTROL_LIMIT_MUST_BE_LESSER_THAN_REQUEST_COUNT'
          );
          disableSave = true;
        }
      }
    } else if (!isEmpty(this.props.validationError)) {
      disableSave = true;
    } else if (plan.burstControl) {
      let burstControl = parseInt(plan.burstControlLimitCount, 10);
      let reqCount = parseInt(plan.requestCount, 10);
      if (
        plan.billingCycleType !== IdConstants.pricing.payPerCallBillingCycleType &&
        plan.limitType === IdConstants.pricing.reqCountLimitType &&
        burstControl >= reqCount
      ) {
        burstControlError = i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN_DIALOG.BURST_CONTROL_LIMIT_MUST_BE_LESSER_THAN_REQUEST_COUNT'
        );
        disableSave = true;
      } else {
        disableSave = false;
      }
    } else {
      disableSave = false;
    }

    return (
      <React.Fragment>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.props.open}
          onClose={this.props.onClose}
          disableBackdropClick={true}
        >
          <>
            {this.props.loader && this.props.loader.loader && <Loader />}
            <div className={classes.divStyle}>
              <Grid container>
                <Grid item xs={11}>
                  <h2 id='simple-modal-title' className={classes.dialogHeadingStyle}>
                    {this.state.dialogHeading}
                  </h2>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={this.props.onClose}>
                    <CloseIcon size='small' />
                  </IconButton>
                </Grid>
                {disableFields && (
                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle1'
                      className={classes.modalContentstyle}
                    >
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.API_HAS_BEEN_SUBSCRIBED_BY_THE_USER'
                      )}
                    </Typography>
                  </Grid>
                )}
                {!this.props.isEditPlan && (
                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle1'
                      className={classes.modalContentstyle}
                    >
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CHOOSE_THE_USER_OR_BUSSINESS_GROUP_AND_DEFINE_THE_PRICING_PLAN'
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <div className={classes.creatingFor}>
                <Typography
                  variant='subtitle1'
                  className={classes.modalContentstyle}
                >
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CREATING_FOR')}
                </Typography>
              </div>
              <FormControl component='fieldset'>
                <RadioGroup
                  aria-label='Creating For'
                  name='creatingfor'
                  onChange={this.handleChange}
                  row
                  className={classes.radioGroupStyle}
                >
                  <FormControlLabel
                    className={classes.formLabelStyle}
                    checked={this.state.creatingForRadioValue === 'individual'}
                    value='individual'
                    control={<Radio color='primary' />}
                    label='Individual User'
                    labelPlacement='end'
                    disabled={disableFields}
                  />
                  <FormControlLabel
                    className={classes.formLabelStyle}
                    checked={this.state.creatingForRadioValue === 'group'}
                    value='group'
                    control={<Radio color='primary' />}
                    label='Business Group'
                    labelPlacement='end'
                    disabled={disableFields}
                  />
                </RadioGroup>
              </FormControl>

              <div
                className={
                  this.state.creatingForRadioValue === 'individual'
                    ? ''
                    : 'hide-element'
                }
              >
                <div className={classes.inputgroupWidth}>
                  <Box display='inline-block' className={classes.inputgroup}>
                    <InputLabel
                      htmlFor='component-simple'
                      required
                      classes={{ asterisk: classes.labelAsterisk }}
                    >
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.USER_NAME')}
                    </InputLabel>
                  </Box>
                  <Box className={classes.userNameStyle}>
                    <LightTooltip
                      title={i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_AN_USER_FOR_WHOM_THE_PRIVATE_PLAN_WOULD_BE_ASSIGNED'
                      )}
                    >
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        color='primary'
                      />
                    </LightTooltip>
                  </Box>
                  <Autocomplete
                    id='individual'
                    name='individual'
                    disabled={disableFields}
                    className={classes.autoCompleteStyle}
                    options={this.props.approvedUsers}
                    filterOptions={this.approvedUsersFilterOptions}
                    getOptionLabel={(option) =>
                      option.userFirstName +
                      ' ' +
                      option.userLastName +
                      ' : ' +
                      option.userEmailId1
                    }
                    defaultValue={this.state.defaultUserName}
                    onChange={(event, value) => this.selectedUser(value)}
                    renderInput={(params) => (
                      <TextFieldMat
                        {...params}
                        label={i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_A_USER'
                        )}
                        margin='normal'
                      />
                    )}
                  />
                  {this.state.isActive && (
                    <InputLabel className={classes.errorTxt}>
                      {!this.state.isValidUser
                        ? i18next.t(
                            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.USER_NAME_IS_REQUIRED'
                          )
                        : ''}
                    </InputLabel>
                  )}
                </div>
              </div>
              <div
                className={
                  this.state.creatingForRadioValue === 'group' ? '' : 'hide-element'
                }
              >
                <div className={classes.inputgroupWidth}>
                  <Box display='inline-block' className={classes.inputgroup}>
                    <InputLabel
                      htmlFor='component-simple'
                      required
                      classes={{ asterisk: classes.labelAsterisk }}
                    >
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.GROUP_NAME')}
                    </InputLabel>
                  </Box>
                  <Box className={classes.groupNameStyle}>
                    <LightTooltip
                      title={i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_GROUP_FOR_WHICH_THE_PRIVATE_PLAN_WOULD_BE_ASSIGNED'
                      )}
                    >
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        color='primary'
                      />
                    </LightTooltip>
                  </Box>
                  <Autocomplete
                    id='group'
                    name='group'
                    disabled={disableFields}
                    options={this.props.orgGrps}
                    filterOptions={this.groupFilterOptions}
                    getOptionLabel={(option) =>
                      option.organization.orgName + ' : ' + option.businessGroup
                    }
                    defaultValue={this.state.defaultGrpName}
                    className={classes.autoCompleteStyle}
                    onChange={(event, value) => this.selectedOrgGrp(value)}
                    renderInput={(params) => (
                      <TextFieldMat
                        {...params}
                        label={i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_A_GROUP'
                        )}
                        margin='normal'
                      />
                    )}
                  />
                  {this.state.isActive && (
                    <InputLabel className={classes.errorTxt}>
                      {!this.state.isValidGrp
                        ? i18next.t(
                            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.GROUP_NAME_IS_REQUIRED'
                          )
                        : ''}
                    </InputLabel>
                  )}
                </div>
              </div>

              <div className={classes.inputgroupWidth}>
                <InputLabel
                  htmlFor='component-simple'
                  required
                  classes={{ asterisk: classes.labelAsterisk }}
                >
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.NAME')}
                </InputLabel>
                <TextField
                  id='filled-basic'
                  className={classes.planName}
                  placeholder={i18next.t(
                    'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICING_PLAN_NAME'
                  )}
                  name='planName'
                  onChange={(event) => this.changeValue(event)}
                  inputProps={{ maxLength: 15 }}
                  value={plan.planName}
                  error={
                    validation.currentPlan.planName.isInvalid ||
                    this.props.validationError.error ===
                      errorCodes.uniquePlanNameError
                      ? true
                      : false
                  }
                  disabled={disableFields}
                  helperText={
                    this.props.validationError.error ===
                    errorCodes.uniquePlanNameError
                      ? i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_NAME_IS_ALREADY_PRESENT'
                        )
                      : validation.currentPlan.planName.message
                  }
                  fullWidth
                />
                <Typography className={classes.maxCharTxt} align='right'>
                  {i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - 15
                </Typography>
              </div>
              <div className={classes.inputgroupWidth}>
                <InputLabel
                  htmlFor='component-simple'
                  required
                  classes={{ asterisk: classes.labelAsterisk }}
                >
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN.DESCRIPTION')}
                </InputLabel>
                <TextField
                  placeholder={i18next.t(
                    'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_DESCRIPTION_HERE'
                  )}
                  name='description'
                  inputProps={{ maxLength: 100 }}
                  value={plan.description}
                  onChange={(event) => this.changeValue(event)}
                  error={validation.currentPlan.description.isInvalid ? true : false}
                  helperText={
                    validation.currentPlan.description.isInvalid
                      ? validation.currentPlan.description.message
                      : ''
                  }
                  fullWidth
                />
                <Typography className={classes.maxCharTxt} align='right'>
                  {i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - 100
                </Typography>
              </div>
              <div className={classes.inputgroupWidth}>
                <InputLabel htmlFor='component-simple'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.BILLING')}
                </InputLabel>
                <Select
                  data={this.props.pricingMasterData.billing_cycle}
                  name='billingCycleType'
                  onChange={(event) => this.changeValue(event)}
                  value={plan.billingCycleType}
                  disabled={disableFields}
                />
              </div>

              {plan.billingCycleType ===
              IdConstants.pricing.payPerCallBillingCycleType ? (
                <React.Fragment>
                  <Typography variant='p'>
                    {i18next.t(
                      'DYNAMIC_FORM.PRICING_PLAN_DIALOG.NOTE_INVOICES_WILL_BE_GENERATED_AND_SENT_TO_BUYER_ON_A_MONTHLY_BASIS'
                    )}
                  </Typography>
                  <section className={classes.fieldStyle}>
                    <InputLabel htmlFor='component-simple'>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.APPLICATION_USAGE'
                      )}
                    </InputLabel>
                    <Grid container>
                      <Grid item>
                        <InputLabel
                          htmlFor='component-simple'
                          className={classes.fieldStyle}
                        >
                          {i18next.t(
                            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.UNLIMITED_APPLICATION'
                          )}
                        </InputLabel>
                      </Grid>
                      <Grid item>
                        <LightTooltip
                          title={i18next.t(
                            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_CAN_BE_SUBSCRIBED_FOR_MULTIPLE_APPLICATION_AT_A_TIME'
                          )}
                          aria-label='view'
                          className={classes.infoIconStyle}
                        >
                          <InfoOutlinedIcon fontSize='small' color='primary' />
                        </LightTooltip>
                      </Grid>
                    </Grid>
                  </section>
                  <Grid container sm spacing={3}>
                    <Grid item>
                      <div>
                        <Grid
                          container
                          sm
                          className={classes.gridStylePricePerRequest}
                        >
                          <Grid item sm={8}>
                            <InputLabel
                              htmlFor='component-simple'
                              required
                              className={classes.labelStyle}
                              classes={{ asterisk: classes.labelAsterisk }}
                            >
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICE_PER_REQUEST'
                              )}
                            </InputLabel>
                          </Grid>
                          <Grid item sm={4}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CONSUMER_WILL_BE_CHARGED_WITH_THE_UNIT_SPECIFIED_FOR_EACH_CALL_MADE_TO_YOUR_API'
                              )}
                              aria-label='view'
                            >
                              <InfoOutlinedIcon fontSize='small' color='primary' />
                            </LightTooltip>
                          </Grid>
                        </Grid>

                        <TextField
                          placeholder={i18next.t('DYNAMIC_FORM.SHARED.ENTER_PRICE')}
                          name='pricePerRequest'
                          value={plan.pricePerRequest}
                          onChange={(event) => this.changeValue(event)}
                          error={
                            validation.currentPlan.pricePerRequest.isInvalid
                              ? true
                              : false
                          }
                          helperText={
                            validation.currentPlan.pricePerRequest.isInvalid
                              ? validation.currentPlan.pricePerRequest.message
                              : ''
                          }
                          inputProps={{ maxLength: 16 }}
                          disabled={disableFields}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.inputgroup}>
                        <InputLabel htmlFor='component-simple'>
                          {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CURRENCY')}
                        </InputLabel>
                        <Select
                          data={this.props.pricingMasterData.currency}
                          name='currencyCode'
                          onChange={(event) => this.changeValue(event)}
                          value={plan.currencyCode}
                          disabled={true}
                          className={classes.currencyStyle}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography variant='p'></Typography>
                  <section className={classes.fieldStyle}>
                    <InputLabel htmlFor='component-simple'>
                      {i18next.t(
                        'DYNAMIC_FORM.PRICING_PLAN_DIALOG.APPLICATION_USAGE'
                      )}
                    </InputLabel>
                    <Grid container>
                      <Grid item>
                        <InputLabel
                          htmlFor='component-simple'
                          className={classes.fieldStyle}
                        >
                          {i18next.t(
                            'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SINGLE_APPLICATION'
                          )}
                        </InputLabel>
                      </Grid>
                      <LightTooltip
                        title={i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_CAN_BE_SUBSCRIBED_FOR_ONLY_ONE_APPLICATION_AT_A_TIME'
                        )}
                        aria-label='view'
                        className={classes.infoIconStyle}
                      >
                        <InfoOutlinedIcon fontSize='small' color='primary' />
                      </LightTooltip>
                    </Grid>
                  </section>
                  <Grid container sm spacing={2}>
                    <Grid item>
                      <div className={classes.inputgroup}>
                        <InputLabel
                          htmlFor='component-simple'
                          required
                          classes={{ asterisk: classes.labelAsterisk }}
                        >
                          {i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICE')}
                        </InputLabel>
                        <TextField
                          placeholder={i18next.t('DYNAMIC_FORM.SHARED.ENTER_PRICE')}
                          name='price'
                          value={plan.price}
                          onChange={(event) => this.changeValue(event)}
                          error={
                            validation.currentPlan.price.isInvalid ? true : false
                          }
                          helperText={
                            validation.currentPlan.price.isInvalid
                              ? validation.currentPlan.price.message
                              : ''
                          }
                          inputProps={{ maxLength: 13 }}
                          disabled={disableFields}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.inputgroup}>
                        <InputLabel
                          htmlFor='component-simple'
                          className={classes.selectLabelStyle}
                        >
                          {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CURRENCY')}
                        </InputLabel>
                        <Select
                          data={this.props.pricingMasterData.currency}
                          name='currencyCode'
                          onChange={(event) => this.changeValue(event)}
                          value={plan.currencyCode}
                          disabled={true}
                          className={classes.currencyWidth}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      aria-label='position'
                      name='limitType'
                      row
                      className={classes.radioGroupStyle}
                    >
                      <Grid container>
                        {this.state.rateLimitTypes.map((limit) => (
                          <Grid item sm={6} key={limit.name}>
                            <FormControlLabel
                              className={classes.formLabelStyle}
                              checked={plan.limitType === limit.name}
                              value={limit.name}
                              control={<Radio color='primary' />}
                              label={limit.name}
                              onChange={(event) => this.changeValue(event)}
                              disabled={disableFields}
                            />
                            {(() => {
                              if (limit.name !== 'Data Bandwidth') {
                                return (
                                  <LightTooltip
                                    title={i18next.t(
                                      'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_THE_OPTION_TO_THROTTLE_REQUEST'
                                    )}
                                    aria-label='view'
                                    className={classes.infoLimitStyle}
                                  >
                                    <InfoOutlinedIcon
                                      fontSize='small'
                                      color='primary'
                                    />
                                  </LightTooltip>
                                );
                              } else {
                                return (
                                  <LightTooltip
                                    title={i18next.t(
                                      'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_THE_OPTION_TO_THROTTLE_BASED_ON_THE_AMOUNT_OF_DATA_TRANSFERRED_PER_UNIT_TIME'
                                    )}
                                    aria-label='view'
                                    className={classes.infoLimitStyle}
                                  >
                                    <InfoOutlinedIcon
                                      fontSize='small'
                                      color='primary'
                                    />
                                  </LightTooltip>
                                );
                              }
                            })()}
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                  {plan.limitType === IdConstants.pricing.reqCountLimitType ? (
                    <Grid container sm spacing={1}>
                      <Grid item sm={3}>
                        <div
                          className={`${classes.inputgroup} ${classes.marginStyle} ${classes.marginTopDivStyle}`}
                        >
                          <Box display='inline-block' className={classes.inputgroup}>
                            <InputLabel
                              htmlFor='component-simple'
                              required
                              classes={{ asterisk: classes.labelAsterisk }}
                              className={classes.selectLabelStyle}
                            >
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.REQUEST_COUNT'
                              )}
                            </InputLabel>{' '}
                          </Box>
                          <Box className={classes.reqCountStyles}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SPECIFY_THE_NUMBER_OF_REQUESTS_WHICH_WOULD_BE_ALLOWED_FOR_PARTICULAR_PERIOD'
                              )}
                            >
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                color='primary'
                              />
                            </LightTooltip>
                          </Box>
                          <FormControl className={classes.formMarginStyle}>
                            <TextField
                              placeholder={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.ENTER_REQUEST_COUNT'
                              )}
                              name='requestCount'
                              value={plan.requestCount}
                              onChange={(event) => this.changeValue(event)}
                              error={
                                validation.currentPlan.requestCount.isInvalid
                                  ? true
                                  : false
                              }
                              helperText={
                                validation.currentPlan.requestCount.isInvalid
                                  ? validation.currentPlan.requestCount.message
                                  : ''
                              }
                              inputProps={{ maxLength: 10 }}
                              disabled={disableFields}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item sm={2}>
                        <div
                          className={`${classes.inputgroup} ${classes.marginStyle} ${classes.quotaLimitDivStyle}`}
                        >
                          <Box display='inline-block' className={classes.inputgroup}>
                            <InputLabel
                              htmlFor='component-simple'
                              className={classes.selectLabelStyle}
                            >
                              {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA')}
                            </InputLabel>
                          </Box>
                          <Box className={classes.infoStyles}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_THE_TIME_PERIOD_TO_ALLOW_THE_MENTIONED_REQUEST_COUNT'
                              )}
                            >
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                color='primary'
                              />
                            </LightTooltip>
                          </Box>
                          <Select
                            data={this.props.pricingMasterData.rate_limit_time_unit}
                            name='quota'
                            onChange={(event) => this.changeValue(event)}
                            value={plan.quota}
                            disabled={disableFields}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={3}>
                        <div
                          className={`${classes.inputgroup} ${classes.marginStyle} ${classes.quotaLimitDivStyle}`}
                        >
                          <Box display='inline-block' className={classes.inputgroup}>
                            <InputLabel
                              htmlFor='component-simple'
                              className={classes.selectLabelStyle}
                            >
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTQ_LIMIT'
                              )}{' '}
                            </InputLabel>
                          </Box>
                          <Box className={classes.limitInfoStyles}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SOFT_LIMIT_ALLOWS_TO_GO_OVER_SPECIFIED_QUOTA_LIMIT'
                              )}
                            >
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                color='primary'
                              />
                            </LightTooltip>
                          </Box>
                          <Select
                            data={quotaLimitData}
                            name='isSoftLimit'
                            onChange={(event) => this.changeValue(event)}
                            value={
                              plan.isSoftLimit
                                ? quotaLimitData[0].id
                                : quotaLimitData[1].id
                            }
                            disabled={disableFields}
                          />
                        </div>
                      </Grid>
                      {plan.isSoftLimit ? (
                        <React.Fragment>
                          <Grid item sm={3}>
                            <div
                              className={`${classes.inputgroup} ${classes.marginStyle} ${classes.marginTopDivStyle} ${classes.marginBottomDivStyle}`}
                            >
                              <Box
                                display='inline-block'
                                className={classes.inputgroup}
                              >
                                <InputLabel
                                  htmlFor='component-simple'
                                  required
                                  className={`${classes.selectLabelStyle} ${classes.centerAlignStyle} ${classes.labelStyles}`}
                                  classes={{ asterisk: classes.labelAsterisk }}
                                >
                                  {i18next.t(
                                    'DYNAMIC_FORM.PRICING_PLAN_DIALOG.OVER_USAGE_PRICE'
                                  )}{' '}
                                </InputLabel>
                              </Box>
                              <Box className={classes.usageCountStyles}>
                                <LightTooltip
                                  title={i18next.t(
                                    'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SPECIFY_THE_PRICE_THAT_WOULD_BE_CHARGED_TO_THE_USER_ONCE_IT_CROSESS_PRESCRIBED_QUOTA'
                                  )}
                                >
                                  <InfoOutlinedIcon
                                    className={classes.infoIcon}
                                    color='primary'
                                  />
                                </LightTooltip>
                              </Box>
                              <Grid container sm className={classes.inputgroup}>
                                <Grid item sm={1}>
                                  <h2>&#x20b9;</h2>
                                </Grid>
                                <Grid
                                  item
                                  sm={11}
                                  className={classes.formMarginStyle}
                                >
                                  <TextField
                                    placeholder={i18next.t(
                                      'DYNAMIC_FORM.SHARED.ENTER_PRICE'
                                    )}
                                    name='overUsagePrice'
                                    value={plan.overUsagePrice}
                                    onChange={(event) => this.changeValue(event)}
                                    error={
                                      validation.currentPlan.overUsagePrice.isInvalid
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      validation.currentPlan.overUsagePrice.isInvalid
                                        ? validation.currentPlan.overUsagePrice
                                            .message
                                        : ''
                                    }
                                    inputProps={{ maxLength: 16 }}
                                    disabled={disableFields}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item sm={1} className={classes.centerAlignStyle}>
                            <Box mt={4}>
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CHARGEABLE_PER_REQUEST'
                              )}
                            </Box>
                          </Grid>
                        </React.Fragment>
                      ) : null}
                    </Grid>
                  ) : (
                    <Grid container sm spacing={1}>
                      <Grid
                        item
                        sm={4}
                        className={`${classes.inputgroup} ${classes.marginTopDivStyle}`}
                      >
                        <Box display='inline-block' className={classes.inputgroup}>
                          <InputLabel
                            className={classes.inputgroup}
                            htmlFor='component-simple'
                            required
                            classes={{ asterisk: classes.labelAsterisk }}
                          >
                            {i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN_DIALOG.DATA_BANDWIDTH'
                            )}
                          </InputLabel>{' '}
                        </Box>
                        <Box className={classes.reqInfoStyles}>
                          <LightTooltip
                            title={i18next.t(
                              'DYNAMIC_FORM.PRICING_PLAN.SPECIFY_THE_DATA_SIZE'
                            )}
                          >
                            <InfoOutlinedIcon
                              className={classes.infoIcon}
                              color='primary'
                            />
                          </LightTooltip>
                        </Box>
                        <Grid container>
                          <Grid item sm={9}>
                            <div className={classes.dataBandwidthStyle}>
                              <TextField
                                placeholder={i18next.t(
                                  'DYNAMIC_FORM.PRICING_PLAN_DIALOG.ENTER_DATA_BANWIDTH'
                                )}
                                name='dataBandwidth'
                                value={plan.dataBandwidth}
                                onChange={(event) => this.changeValue(event)}
                                error={
                                  validation.currentPlan.dataBandwidth.isInvalid
                                    ? true
                                    : false
                                }
                                helperText={
                                  validation.currentPlan.dataBandwidth.isInvalid
                                    ? validation.currentPlan.dataBandwidth.message
                                    : ''
                                }
                                inputProps={{ maxLength: 10 }}
                                disabled={disableFields}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={3}>
                            <div>
                              <Select
                                data={this.props.pricingMasterData.bandwidth}
                                name='bandwidthType'
                                onChange={(event) => this.changeValue(event)}
                                value={plan.bandwidthType}
                                disabled={disableFields}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={2}>
                        <div
                          className={`${classes.inputgroup} ${classes.marginStyle} ${classes.quotaDivStyle} ${classes.marginTopDivStyle}`}
                        >
                          <Box display='inline-block' className={classes.inputgroup}>
                            <InputLabel
                              htmlFor='component-simple'
                              className={classes.selectLabelStyle}
                            >
                              {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA')}
                            </InputLabel>
                          </Box>
                          <Box className={classes.infoStyles}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SELECT_THE_TIME_PERIOD_TO_ALLOW_THE_MENTIONED_REQUEST_COUNT'
                              )}
                            >
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                color='primary'
                              />
                            </LightTooltip>
                          </Box>
                          <Select
                            data={this.props.pricingMasterData.rate_limit_time_unit}
                            name='quota'
                            onChange={(event) => this.changeValue(event)}
                            value={plan.quota}
                            disabled={disableFields}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={2}>
                        <div
                          className={`${classes.inputgroup} ${classes.marginStyle} ${classes.quotaDivStyle} ${classes.marginTopDivStyle}`}
                        >
                          <Box display='inline-block' className={classes.inputgroup}>
                            <InputLabel
                              htmlFor='component-simple'
                              required
                              className={classes.selectLabelStyle}
                              classes={{ asterisk: classes.labelAsterisk }}
                            >
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTQ_LIMIT'
                              )}{' '}
                            </InputLabel>{' '}
                          </Box>
                          <Box className={classes.dataLimitInfoStyles}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SOFT_LIMIT_ALLOWS_TO_GO_OVER_SPECIFIED_QUOTA_LIMIT'
                              )}
                            >
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                color='primary'
                              />
                            </LightTooltip>
                          </Box>
                          <Select
                            data={quotaLimitData}
                            name='isSoftLimit'
                            onChange={(event) => this.changeValue(event)}
                            value={
                              plan.isSoftLimit
                                ? quotaLimitData[0].id
                                : quotaLimitData[1].id
                            }
                            disabled={disableFields}
                          />
                        </div>
                      </Grid>
                      {plan.isSoftLimit ? (
                        <React.Fragment>
                          <Grid item sm={3}>
                            <div
                              className={`${classes.inputgroup} ${classes.marginStyle} ${classes.marginTopDivStyle} ${classes.marginBottomDivStyle}`}
                            >
                              <Box
                                display='inline-block'
                                className={classes.inputgroup}
                              >
                                <InputLabel
                                  htmlFor='component-simple'
                                  required
                                  className={`${classes.centerAlignStyle} ${classes.labelStyles}`}
                                  classes={{ asterisk: classes.labelAsterisk }}
                                >
                                  {i18next.t(
                                    'DYNAMIC_FORM.PRICING_PLAN_DIALOG.OVER_USAGE_PRICE'
                                  )}{' '}
                                </InputLabel>
                              </Box>
                              <Box className={classes.usageInfoStyles}>
                                <LightTooltip
                                  title={i18next.t(
                                    'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SPECIFY_THE_PRICE_THAT_WOULD_BE_CHARGED_TO_THE_USER_ONCE_IT_CROSESS_PRESCRIBED_QUOTA'
                                  )}
                                >
                                  <InfoOutlinedIcon
                                    className={classes.infoIcon}
                                    color='primary'
                                  />
                                </LightTooltip>
                              </Box>
                              <Grid container sm className={classes.inputgroup}>
                                <Grid
                                  item
                                  sm={1}
                                  className={classes.dataBandwidthOverPriceStyle}
                                >
                                  <h2>&#x20b9;</h2>
                                </Grid>
                                <Grid
                                  item
                                  sm={11}
                                  className={classes.dataBandwidthOverPriceStyle}
                                >
                                  <TextField
                                    placeholder={i18next.t(
                                      'DYNAMIC_FORM.SHARED.ENTER_PRICE'
                                    )}
                                    name='overUsagePrice'
                                    value={plan.overUsagePrice}
                                    onChange={(event) => this.changeValue(event)}
                                    error={
                                      validation.currentPlan.overUsagePrice.isInvalid
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      validation.currentPlan.overUsagePrice.isInvalid
                                        ? validation.currentPlan.overUsagePrice
                                            .message
                                        : ''
                                    }
                                    className={classes.priceStyle}
                                    inputProps={{ maxLength: 16 }}
                                    disabled={disableFields}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item sm={1} align='center'>
                            <Box mt={5}>
                              {i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.CHARGEABLE_PER'
                              )}{' '}
                              {this.state.bytes}
                            </Box>
                          </Grid>
                        </React.Fragment>
                      ) : null}
                    </Grid>
                  )}
                </React.Fragment>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={plan.burstControl}
                    onChange={(event) => this.handleCheckBoxChange(event)}
                    name='burstControl'
                    color='primary'
                    value={plan.burstControl}
                    disabled={disableFields}
                  />
                }
                label='Burst Control'
              />
              <LightTooltip
                title={i18next.t(
                  'DYNAMIC_FORM.PRICING_PLAN_DIALOG.BURST_CONTROL_SPECIFIES_THE_MAXIMUM_NUMBER_OF_API_CALLS'
                )}
                aria-label='view'
              >
                <InfoOutlinedIcon
                  fontSize='small'
                  color='primary'
                  className={classes.infoIconStyle}
                />
              </LightTooltip>

              <Grid container sm spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    placeholder={i18next.t(
                      'DYNAMIC_FORM.PRICING_PLAN.ENTER_BURST_CONTROL_LIMIT'
                    )}
                    name='burstControlLimitCount'
                    disabled={!plan.burstControl || disableFields}
                    onChange={(event) => this.changeValue(event)}
                    value={plan.burstControlLimitCount}
                    error={
                      plan.burstControl &&
                      (validation.currentPlan.burstControlLimitCount.isInvalid ||
                        burstControlError !== '')
                        ? true
                        : false
                    }
                    helperText={
                      plan.burstControl &&
                      validation.currentPlan.burstControlLimitCount.isInvalid
                        ? validation.currentPlan.burstControlLimitCount.message
                        : burstControlError
                    }
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item sm={3}>
                  <div>
                    <Select
                      data={this.props.pricingMasterData.burst_time_unit}
                      name='burstTimeUnit'
                      disabled={!plan.burstControl || disableFields}
                      onChange={(event) => this.changeValue(event)}
                      value={plan.burstTimeUnit}
                    />
                  </div>
                </Grid>
                <Grid item sm={5}></Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={classes.paymentLabel}>
                    {i18next.t(
                      'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PAYMENT_PREFERENCE'
                    )}
                  </Typography>
                  <Typography>
                    {i18next.t(
                      'DYNAMIC_FORM.PRICING_PLAN_DIALOG.IS_PAYMENT_STEP_REQUIRED_TO_COMPLETE_ORDER_PLACEMENT'
                    )}
                  </Typography>
                </Grid>
                <FormControl component='fieldset' className={classes.bottomMargin}>
                  <RadioGroup
                    aria-label='position'
                    name='paymentPreferenceOption'
                    value={this.state.currentPlan.paymentPreference}
                    row
                    className={classes.radioGroupStyle}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div className={classes.flexStyle}>
                          <FormControlLabel
                            className={classes.formLabelStyle}
                            value='false'
                            control={<Radio color='primary' />}
                            label='No'
                            onChange={(event) => this.changeValue(event)}
                            disabled={disableFields}
                          />
                          <Box className={classes.infoBox}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.BUYER_WILL_BE_ABLE_TO_SUBSCRIBE_DIRECTLY'
                              )}
                              placement='bottom-start'
                              aria-label='view'
                              className={classes.infoLimitStyle}
                            >
                              <InfoOutlinedIcon fontSize='small' color='primary' />
                            </LightTooltip>
                          </Box>
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div className={classes.flexStyle}>
                          <FormControlLabel
                            className={classes.formLabelStyle}
                            value='true'
                            control={<Radio color='primary' />}
                            label='Yes'
                            onChange={(event) => this.changeValue(event)}
                            disabled={disableFields}
                          />
                          <Box className={classes.infoBox}>
                            <LightTooltip
                              title={i18next.t(
                                'DYNAMIC_FORM.PRICING_PLAN_DIALOG.BUYER_WILL_BE_MANADATORILY_REQUIRED_TO_PROVIDE_THE_PAYMENT_INFO'
                              )}
                              placement='bottom-start'
                              aria-label='view'
                              className={classes.infoLimitStyle}
                            >
                              <InfoOutlinedIcon fontSize='small' color='primary' />
                            </LightTooltip>
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid container>
                <Grid item sm>
                  <Box mt={1} display='flex' justifyContent='flex-end'>
                    <Box mr={2}>
                      <Button
                        variant='contained'
                        label={this.state.saveButtonLabel}
                        onClick={this.savePlan}
                        disabled={disableSave}
                      />
                    </Box>
                    <Button
                      variant='outlined'
                      onClick={this.props.onClose}
                      label='Cancel'
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(PrivatePlanDialog);
