import axios from 'axios';
import { GET_CONTRACT_INFO, POST_CONSUMPTION } from '../configuration';

export function getContractInfo(dcsOrderId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_CONTRACT_INFO + '?dcsOrderId=' + dcsOrderId,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_CONTRACT_DATA',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function postConsumption(payload) {
  return (dispatch) =>
    axios({
      method: 'POST',
      url: POST_CONSUMPTION,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error?.response?.status !== 400)
          dispatch({
            type: 'ERROR',
            error: error
          });
        return error?.response;
      });
}
