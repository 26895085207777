export default (obj) => {
  if (obj) {
    if (typeof obj === 'string') {
      return obj.trim().length === 0;
    } else if (typeof obj === 'object' && Object.keys(obj).length > 0) {
      return false;
    }
  }
  return true;
};
