import React from 'react';
import { DialogBox } from '../../../msreact-kit/packages/html-components';

import './index.scss';

const PreviewModal = ({ mediaUrl, isVideo, handlePreviewModal }) => {
  return (
    <div className='preview-modal'>
      <DialogBox
        title=' '
        handleCancel={handlePreviewModal}
        isPrimaryBtn={false}
        isDivider={true}
        cssName='preview-modal'
      >
        <div className='m-dialog__content'>
          {!isVideo ? (
            <img src={mediaUrl} className='preview-modal__img' />
          ) : (
            <video controls autoPlay className='preview-modal__video'>
              <source src={mediaUrl} type='video/mp4' />
            </video>
          )}
        </div>
      </DialogBox>
    </div>
  );
};

export default PreviewModal;
