import i18next from 'i18next';
import _ from 'lodash';
import { DynamicFieldConstants } from '../constants';
import { FieldConstants } from '../constants/field-constants';
import { IS_MKOSINT_TENANT } from '../../actions/configuration';

let cloneIgnoreAttributes = ['productName', 'stock', 'hsnCode'];
if (IS_MKOSINT_TENANT) {
  cloneIgnoreAttributes = ['stock', 'hsnCode'];
}
const cloneIgnoreSections = ['media&documents', 'documents'];

export function getNestedVal(additionalInfo, xPath) {
  for (let index = 0, n = xPath.length; index < n; ++index) {
    let value = xPath[index];
    if (value in additionalInfo) {
      additionalInfo = additionalInfo[value];
    } else {
      return;
    }
  }
  return additionalInfo;
}

export const getErrorMessage = (item) => {
  //If the old value does not match the current regex, throw an error message
  const regexPattern = item?.validation?.rule?.regex?.pattern;
  if (item.value && regexPattern?.length > 0) {
    const regexExp = new RegExp(regexPattern, 'gmu');
    if (!regexExp.test(item.value)) {
      return `${item.displayName} ${i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_INVALID'
      )}.`;
    }
  }
};

export const dynamicCompare = (option1, option2) => {
  let labelA;
  let labelB;
  if (option1.label && option2.label) {
    labelA = option1.label;
    labelB = option2.label;
  } else if (option1.value && option2.value) {
    labelA = option1.value;
    labelB = option2.value;
  }
  if (labelA && labelB) {
    if (!isNaN(labelA) && !isNaN(labelB)) {
      return Number(labelA) - Number(labelB);
    }

    return labelA.localeCompare(labelB);
  }
};

const getFieldValue = (item, assignField) => {
  switch (item.name) {
    case FieldConstants.countryOfOrigin:
      return (
        item.options.find((option) => option.value === assignField.value)?.label ??
        assignField?.value
      );
    case FieldConstants.countryOfUsage:
      return (
        item.options.find((option) => option.label === assignField.value)?.value ??
        assignField?.value
      );
    default:
      return assignField?.value;
  }
};

const getKeyValue = (item, assignField) => {
  return (
    (item.name === FieldConstants.countryOfOrigin &&
      item.options.find(
        (option) =>
          option.label === assignField.value || option.value === assignField.value
      )?.value) ||
    assignField?.value
  );
};

export const attributeParser = (
  element,
  additionalInfo,
  documents,
  quantity,
  isClone
) => {
  const parsedAttributesWithValue = [];
  const parsedAttributes = element.map((item) => {
    const result = item.xpath
      .split('/')
      .map((element) => element.trim().toString())
      .filter((element) => element !== '');
    const assignField = getNestedVal(additionalInfo, result);
    if (
      item.attributeType === DynamicFieldConstants.FILE ||
      item.attributeType === DynamicFieldConstants.UPLOAD_IMAGE
    ) {
      const filteredDocuments = documents.filter(
        (docs) => docs.docTypeId === item.attributeId && docs.uid !== null
      );
      item.value = filteredDocuments;
    } else if (item.attributeType === DynamicFieldConstants.TEXT_AND_FILE) {
      const filteredDocuments = documents.filter(
        (docs) =>
          docs.docTypeId === item.attributes[0].attributeId && docs.uid !== null
      );
      item.value = assignField;
      item.attributes[0].value = filteredDocuments;
    } else {
      if (
        item.attributeType === DynamicFieldConstants.MULTI_SELECT &&
        item.name === 'productBusinessCategoryMap'
      ) {
        item.value = assignField.map(function (arrObj) {
          return {
            id: arrObj.businessCategory.businessCategoryId,
            key: arrObj.businessCategory.businessCategoryId,
            value: arrObj.businessCategory.businessCategoryName
          };
        });
      } else if (item.attributeType === DynamicFieldConstants.MULTI_SELECT) {
        if (Array.isArray(assignField)) {
          item.value = assignField?.map(function (arrObj) {
            return {
              id: arrObj.id,
              value: getFieldValue(item, arrObj),
              key: arrObj.id,
              _id: arrObj._id
            };
          });
        } else {
          item.value = assignField?.id
            ? [
                {
                  id: assignField.id,
                  value: assignField.name ? assignField.name : assignField.value,
                  key: assignField.id,
                  _id: assignField._id
                }
              ]
            : [];
        }
      } else if (
        item.attributeType === DynamicFieldConstants.SINGLE_SELECT &&
        item.name === FieldConstants.subcategory
      ) {
        if (assignField) {
          item.value = {
            label: assignField?.name,
            value: assignField?.name,
            id: assignField?.id
          };
        }
      } else if (item.attributeType === DynamicFieldConstants.SINGLE_SELECT) {
        if (assignField) {
          item.value = {
            label: getFieldValue(item, assignField),
            value: getKeyValue(item, assignField),
            id: assignField?.key
          };
        }
      } else {
        if (isClone && cloneIgnoreAttributes.includes(item.name)) {
          item.value = '';
        } else {
          if (item.name === FieldConstants.hsnCode) {
            if (!isClone) {
              item.value = additionalInfo.prodBasicInfo.saccode;
            }
          } else if (item.name === FieldConstants.stock) {
            item.value = quantity?.quantity ? quantity.quantity : '';
          } else {
            if (assignField && !assignField?.value) {
              if (
                _.isNumber(assignField) &&
                item.attributeType === DynamicFieldConstants.TEXT
              ) {
                item.value = assignField.toString();
                const isError = getErrorMessage(item);
                if (isError) {
                  item.error = isError;
                }
              } else {
                item.value = assignField;
                const isError = getErrorMessage(item);
                if (isError) {
                  item.error = isError;
                }
              }
            }
          }
        }
      }
    }

    parsedAttributesWithValue.push({
      name: item.name,
      value: item.value
    });

    return item;
  });

  return { parsedAttributes, parsedAttributesWithValue };
};

const groupParser = (element, additionalInfo, documents) => {
  const parsedAttributes = element.map((item) => {
    const result = item.xpath
      .split('/')
      .map((element) => element.trim().toString())
      .filter((element) => element !== '');
    const assignField = getNestedVal(additionalInfo, result);
    const sortFields =
      assignField &&
      assignField.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
    const groupElement = element;

    for (let index = 1; index < sortFields?.length; index++) {
      const copyElement = JSON.parse(JSON.stringify(element[0]));
      copyElement.name = copyElement.name + index;
      const fieldData = copyElement.attributes.map((fieldItem) => {
        fieldItem.errror = '';
        fieldItem.value = '';
        return fieldItem;
      });
      copyElement.attributes = fieldData;
      groupElement.push(copyElement);
    }
    const modifiedData =
      groupElement &&
      groupElement.forEach((groupData, index) => {
        groupData.attributes.forEach((item) => {
          if (
            item.attributeType === DynamicFieldConstants.FILE ||
            item.attributeType === DynamicFieldConstants.UPLOAD_IMAGE
          ) {
            const filteredDocuments = documents.filter(
              (docs) => docs.docTypeId === item.attributeId && docs.uid !== null
            );

            if (filteredDocuments && filteredDocuments?.length > 0) {
              if (assignField && assignField[index]) {
                const getDocByUiId = filteredDocuments.filter(
                  (item) =>
                    item.productDocMetaInfoId === assignField[index]['attachmentKey']
                );
                item.value = getDocByUiId;
              }
            }
            if (assignField && assignField[index]?._id) {
              item._id = assignField[index]?._id;
            }
          } else {
            item.value =
              assignField && assignField[index] ? assignField[index][item.name] : '';
            if (assignField && assignField[index]?._id) {
              item._id = assignField[index]?._id;
            }
          }
        });
      });
    return modifiedData;
  });

  return parsedAttributes;
};

export const cloneFunction = (
  response,
  additionalInfo,
  documents,
  quantity,
  isClone
) => {
  response = _.cloneDeep(response);
  additionalInfo = _.cloneDeep(additionalInfo);
  documents = _.cloneDeep(documents);
  return {
    pricingPlanMaxCount: response.pricingPlanMaxCount,
    billingType: response.billingType,
    billingCycle: response.billingCycle,
    parentCategory: response.parentCategory,
    rateLimitTypes: response.rateLimitTypes,
    category: response.category,
    status: response.status,
    quotations: response.quotations,
    isQuotation: response.isQuotation,
    steps: response.steps.map((element) => {
      if (isClone && cloneIgnoreSections.includes(element.name)) {
        return element;
      }

      if (element?.attributes && element?.attributes?.length > 0) {
        const { parsedAttributes } = attributeParser(
          element.attributes,
          additionalInfo,
          documents,
          quantity,
          isClone
        );
        element.attributes = parsedAttributes;
      }
      if (element?.sections && element?.sections?.length > 0) {
        element.sections.forEach((sectionAttributes) => {
          if (isClone && cloneIgnoreSections.includes(sectionAttributes.name)) {
            return;
          }

          if (
            sectionAttributes?.attributes &&
            sectionAttributes?.attributes?.length > 0
          ) {
            const { parsedAttributes } = attributeParser(
              sectionAttributes.attributes,
              additionalInfo,
              documents,
              quantity,
              isClone
            );
            sectionAttributes.attributes = parsedAttributes;
          }
          if (sectionAttributes?.groups && sectionAttributes?.groups?.length > 0) {
            groupParser(sectionAttributes?.groups, additionalInfo, documents);
          }
        });
      }
      return element;
    })
  };
};
