import React from 'react';
import PropTypes from 'prop-types';
import DialogBox from '../../../../../msreact-kit/html-components/dialog-box';
import i18next from 'i18next';

const OrderConfirm = ({ handleSubmitOrder, handleClose, disabled }) => {
  return (
    <DialogBox
      isModal={true}
      isSuccess={true}
      title={i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ORDER_CONFIRM_POPUP.ORDER_CONFIRMATION'
      )}
      subTitle={i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ORDER_CONFIRM_POPUP.CLICK_ON_CONFIRM_BUTTON_TO_ACCEPT_THE_ORDER'
      )}
      isSecondaryBtn={false}
      primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.CONFIRM')}
      handleConfirm={handleSubmitOrder}
      isCloseIcon={true}
      handleCancel={handleClose}
      disabled={disabled}
    />
  );
};

OrderConfirm.propTypes = {
  handleSubmitOrder: PropTypes.func
};

export default OrderConfirm;
