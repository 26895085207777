import isEmpty from './isEmpty';
export default function customValidator(str, type) {
  switch (type) {
    case 'REQUEST_COUNT':
    case 'BURST_CONTROL':
      return !isMaxInteger(str);
    case 'PRICE_PER_REQUEST':
    case 'OVER_USAGE_PRICE':
      return !isMaxDecimal(str);
    case 'PRICE':
      return !isMaxPrice(str);
    case 'CONDITION_GROUP_NAME':
      return isValidName(str);
    case 'IP_ADDRESS':
      return isValidIP(str);
    case 'LIMIT_COUNT':
      return !isMaxInteger(str);
    case 'EMAIL_ID':
      return isValidEmail(str);
    default:
      return false;
  }
}

function isValidIP(str) {
  let expression =
    /^((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isValidName(str) {
  let expression = /^[a-zA-Z0-9-_]+$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

function isMaxInteger(str) {
  const maxLength = 2147483647; //Maximum length allowed
  if (!isEmpty(str)) {
    let number = parseInt(str);
    return number <= maxLength;
  } else {
    return false;
  }
}

function isMaxDecimal(str) {
  let expression = /^\d{1,10}(\.\d{1,5})?$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

export function isMaxPrice(str) {
  let expression = /^\d{1,10}(\.\d{1,2})?$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}

export function isValidEmail(str) {
  const expression =
    /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
  if (!isEmpty(str)) {
    return expression.test(str);
  } else {
    return false;
  }
}
