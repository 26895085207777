const attributes = {
  productDetailedInfo_endPointDetails_parameters_value: {
    id: 'a5bd7fd0-3df8-45d6-a3ad-8ea000625366'
  },
  productDetailedInfo_accessControls_secretParameter_value: {
    id: '92da20fd-203c-4f5c-9b17-89ae16346e16'
  },
  productDetailedInfo_endPointDetails_headers: {
    id: '7ae5380c-faa3-45f5-b038-663bd6426357'
  },
  productDetailedInfo_endPointDetails: {
    id: 'e2754e5f-76ab-469a-925b-986adab42637'
  },
  productDetailedInfo_endPointDetails_parameters_description: {
    id: 'a265c404-95b2-4576-b8ab-372953c38664'
  },
  productDetailedInfo_endPointDetails_description: {
    id: 'de6da404-4f1f-4235-89a3-2db8fe9d9c91'
  },
  productDetailedInfo_endPointDetails_parameters_isRequired: {
    id: '8e9fe8bb-8aa8-4c95-b002-7451f919190d'
  },
  productDetailedInfo_accessControls_authDetails_verificationURL: {
    id: '38367a60-ace2-4180-98dc-e86e4b907a63'
  },
  productDetailedInfo_endPointDetails_headers_datatype: {
    id: '624358b6-740c-4d26-a8aa-456f6247cfb2'
  },
  productDetailedInfo_endpointGroups_groupDesc: {
    id: 'e22c31fa-9e9c-4a13-8878-986ad2b3b69a'
  },
  productDetailedInfo_endPointDetails_pathParameter_value: {
    id: 'b9eb1265-a451-42bd-be11-feade1c1c5bb'
  },
  productDetailedInfo_accessControls_authDetails: {
    id: 'c7ecdd06-4d69-4004-bf88-fce3a5f8e8be'
  },
  prodBasicInfo_productLongDesc: { id: 'c6bf4b13-ab5a-42d8-aa2f-8485e1488b20' },
  productDetailedInfo_endPointDetails_formurlEncoded_description: {
    id: '70a5cad5-1b28-4765-b0ee-78afd801ef23'
  },
  prodBasicInfo_status: { id: '85b172d0-db73-4863-b9b1-1aba77e3beab' },
  productDetailedInfo_endPointDetails_contentType: {
    id: 'ed33e6a3-9f09-4ee4-a863-0d66b7f6ca0b'
  },
  productDetailedInfo_endPointDetails_formdata_isRequired: {
    id: '8e8ad519-d3b4-4e7a-8689-783b221f7555'
  },
  productDetailedInfo_endpointGroups_groupName: {
    id: 'c8f63cf4-1a80-49ee-aa27-df5a006ccd0e'
  },
  productDetailedInfo_endPointDetails_formurlEncoded_datatype: {
    id: 'df4578e0-00c9-4d82-9b74-0f3e9ed03f7a'
  },
  productDetailedInfo_accessControls_authDetails_separator: {
    id: '1907154e-75dc-45d8-ab58-14ee7f313454'
  },
  productDetailedInfo_endPointDetails_pathParameter: {
    id: 'a3cabbfc-7d97-4d36-a139-02666ebfd6c3'
  },
  productDetailedInfo_endPointDetails_pathParameter_name: {
    id: 'ecdc5b91-dbf3-4016-86d6-2c81f0f06981'
  },
  productDetailedInfo_endPointDetails_pathParameter_description: {
    id: 'd2d38dd1-0978-412f-adae-e8470ebad8b1'
  },
  prodBasicInfo_productBusinessCategoryMap: {
    id: '65d90d8c-fe68-49db-a974-3071c3d8c0a6'
  },
  productDetailedInfo_accessControls: { id: '7754d20d-af28-467c-8e2a-27eee4555a66' },
  productDetailedInfo_endPointDetails_formurlEncoded: {
    id: '84c12071-1425-4c18-a99c-7d6f2ecd353c'
  },
  productDetailedInfo_endPointDetails_headers_name: {
    id: '56edcb88-0665-46eb-be04-053036122283'
  },
  productDetailedInfo_accessControls_authDetails_grantType: {
    id: '2f961b96-ff66-49b9-ad29-a891823076d1'
  },
  productDetailedInfo_endPointDetails_body: {
    id: '62470595-291c-43d3-b4f8-4a0e5e5d9c33'
  },
  productDetailedInfo_endPointDetails_body_datatype: {
    id: '4fce2f77-6a30-4a87-9011-f02b5010f120'
  },
  productDetailedInfo_endPointDetails_path: {
    id: '77180c28-f15b-49a9-952d-12bcf918b514'
  },
  prodBasicInfo_displayName: { id: 'd24ea0f2-5ab2-4a73-86f1-967b87fefa5b' },
  productDetailedInfo_endPointDetails_pathParameter_isRequired: {
    id: 'dfe3cfa7-39b1-4aee-b725-8c896ae1312f'
  },
  productDetailedInfo_accessControls_secretParameter_name: {
    id: '6ff15f44-0c31-4b8d-a0e9-2a4159fc2889'
  },
  prodBasicInfo_productBusinessCategoryMap_businessCategory: {
    id: '1b4d76dc-4393-45c9-ad55-8f14df6029a5'
  },
  productDetailedInfo_endPointDetails_headers_value: {
    id: 'b8302501-8f37-4508-b249-5c39c058950e'
  },
  productDetailedInfo: { id: 'c2b094ff-ee91-4713-a41a-91edd0ba5942' },
  productDetailedInfo_endPointDetails_method: {
    id: '505662e2-c25e-41ca-8a12-0508076ab7ea'
  },
  productDetailedInfo_endPointDetails_formurlEncoded_isRequired: {
    id: 'c96991d1-e674-4723-8610-194f6bca4554'
  },
  prodBasicInfo_productShortDesc: { id: '56272339-7c45-4e9b-93f6-e1f5f1fc74b9' },
  productDetailedInfo_endPointDetails_body_name: {
    id: '853fac51-0f9a-4c5c-a939-6c3d2a9b65b8'
  },
  prodBasicInfo_productBusinessCategoryMap_businessCategory_businessCategoryId: {
    id: '15a95421-478c-4d6a-a2b1-a861199b6951'
  },
  productDetailedInfo_authorizationKey: {
    id: '418ac0aa-dc31-4931-bd04-e6c20d4765f9'
  },
  prodBasicInfo_createdDate: { id: '7436a57c-b515-4432-8ab1-204acef57512' },
  productDetailedInfo_endPointDetails_formurlEncoded_name: {
    id: '0a1074e7-c185-4ccf-8283-4cff107dde0c'
  },
  productDetailedInfo_endPointDetails_formdata_datatype: {
    id: '9c745377-cb54-4ea0-ac63-844493d628a8'
  },
  productDetailedInfo_accessControls_authDetails_authType: {
    id: '0d6d6e13-d0bb-4b89-a711-113b4a04512d'
  },
  prodBasicInfo: { id: '1d66d475-fb6e-4664-844e-33b4a5e9cb28' },
  productDetailedInfo_endPointDetails_parameters: {
    id: 'd51730dd-bdc3-4352-878d-2f524c7e02aa'
  },
  prodBasicInfo_createdBy: { id: '049a1c0a-8091-4a79-9a9a-d83c8c00af7e' },
  prodBasicInfo_productCategory: { id: '66c6bb77-ef5b-4bf1-8c15-4d057ebc9595' },
  productDetailedInfo_baseUrl: { id: '7a47026d-9742-4a08-8c5d-d7208de6e54f' },
  prodBasicInfo_termsAndConditions: { id: '72c33a06-2eb7-4302-a759-36cc84dde610' },
  productDetailedInfo_endPointDetails_name: {
    id: '8a61a16e-3280-444f-b353-774747371b05'
  },
  productDetailedInfo_accessControls_authDetails_authorizationURL: {
    id: '346d573a-7e2b-4928-b265-f8d160740d46'
  },
  productDetailedInfo_endPointDetails_formdata_name: {
    id: '156ce038-96be-4c9a-930d-b8fb63009f24'
  },
  productDetailedInfo_endPointDetails_parameters_datatype: {
    id: '6458eca6-b5d9-4a5a-89ed-7414e23b0e55'
  },
  productDetailedInfo_endpointGroups: { id: 'e6bb5d24-88f6-4559-8b2f-a612771028f4' },
  productDetailedInfo_accessControls_authDetails_authTokenUrl: {
    id: 'a67eea7e-90fa-4de2-a8a0-1da289cd5b7b'
  },
  productDetailedInfo_endPointDetails_headers_isRequired: {
    id: 'e5762b9d-477b-490a-a3b8-cffd839dc646'
  },
  productDetailedInfo_accessControls_secretParameter: {
    id: 'f2948551-1149-429f-b937-462c01a4c4bf'
  },
  productDetailedInfo_accessControls_authDetails_scope: {
    id: 'f5797d47-7568-40c9-8523-fdb99810b4ef'
  },
  productDetailedInfo_accessControls_secretParameter_description: {
    id: '46c7a4d6-6756-4f32-af42-ced26b249dd7'
  },
  productDetailedInfo_endPointDetails_group: {
    id: '96300bcc-8c92-4905-a366-436ff9ada7b5'
  },
  productDetailedInfo_endPointDetails_formdata: {
    id: '2031fd15-f1c8-404b-a1a3-4a9ba10a3f5f'
  },
  productDetailedInfo_endPointDetails_parameters_name: {
    id: 'f8bee1be-a50e-4077-b570-d3bc354c343c'
  },
  prodBasicInfo_comments: { id: '98fa0e85-70fe-44db-b350-9eeee3b91548' },
  productDetailedInfo_accessControls_secretHeaders: {
    id: '6cc2f432-6e40-47ec-9902-114c28fb22df'
  },
  productDetailedInfo_accessControls_authDetails_clientAuthentication: {
    id: 'b3797a77-3888-4afd-8dc8-85dc1a478444'
  },
  productDetailedInfo_accessControls_secretHeaders_description: {
    id: 'c92d52ed-010a-4180-9645-71fa560a0bbb'
  },
  productDetailedInfo_endPointDetails_formdata_description: {
    id: '7c08e8c3-401f-4af2-bc87-b3da1984483e'
  },
  productDetailedInfo_accessControls_secretHeaders_name: {
    id: 'd0287d85-c62a-4902-b5ff-39144f59780c'
  },
  productDetailedInfo_accessControls_secretHeaders_value: {
    id: 'd313896a-0b14-4049-8998-18ab57ef5178'
  },
  productDetailedInfo_endPointDetails_body_isRequired: {
    id: '60424bda-79a0-4e43-bb58-ef74b13fcaad'
  },
  productDetailedInfo_endPointDetails_pathParameter_datatype: {
    id: '209e35da-d643-488e-8434-e491fefa444e'
  },
  productDetailedInfo_endPointDetails_headers_description: {
    id: 'b2b47fc2-dd54-475b-9584-2413b0f476bd'
  },
  productDetailedInfo_endPointDetails_body_description: {
    id: 'b59e4809-7d91-4d32-ba25-81fe78780dcc'
  }
};

export default attributes;
