import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { orderStatus } from '../../../../shared/shared-function/helperFunctions';
import {
  convertUTCToLocalDate,
  convertUTCToLocalDateTime
} from '../../../../utils/date-time-utils';
import './index.scss';

const VerticleCommentsProgressBar = (props) => {
  const { stepperOption, title } = props;

  const handleFileDownload = (element) => {
    const fileUrl = element?.details?.blobFileUrl; // replace with the actual path to your file
    const fileName = element?.details?.fileName; // replace with the actual filename of your file
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const url = window.URL.createObjectURL(xhr.response);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    };
    xhr.send();
  };

  const statusNameEdit = (status) => {
    switch (status) {
      case orderStatus.INTRANSIT:
        return i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.INTRANSIT');
      case orderStatus.ORDER_REJECTED:
        return i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.ORDER_REJECTED');
      case orderStatus.REJECTED_WITH_POD:
        return i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.REJECTED_WITH_POD');
      case orderStatus.CONFIRMED_ORDER_CANCELLED:
        return i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.ORDER_CANCELLED');
      default:
        let name = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        return name.split('_').join(' ');
    }
  };

  return (
    <div data-testid='step-indicator-comments' className='step-indicator-comments'>
      <div className='step-indicator__details'>
        <h1 className='comments-title'>{title}</h1>
        <div className='step-indicator__m-container'>
          <div
            className='step-indicator__m-progress-bar a-progress-indicator -determinate'
            id='content-progress'
            role='progressbar'
            aria-label='progress bar determinate progress indicator'
            aria-valuemin='0'
            aria-valuemax='100'
            aria-valuenow='37'
            tabIndex='0'
          >
            <div className='a-progress-indicator__inner-bar'></div>
          </div>
        </div>
      </div>
      <div className='step-indicator__container'>
        {stepperOption &&
          stepperOption?.length > 0 &&
          stepperOption.map((element, index) => (
            <div
              key={`${element?.displayName}`}
              className={`comments-indicator`}
              tabIndex='0'
            >
              {stepperOption.length > 1 && (
                <div className='step-indicator__v-stepper'>
                  <div
                    className={`comments-node ${index === 0 ? 'active-node' : ''}`}
                  ></div>
                  {index !== stepperOption.length - 1 && (
                    <div
                      className={`comments-node-divider ${
                        index === stepperOption.length - 2 && 'last-node'
                      }`}
                    ></div>
                  )}
                </div>
              )}
              <div className='comments-step-indicator-wrapper'>
                <div className='comments-content-indicator'>
                  <div className='comment-description'>
                    <div className='comment-content'>
                      <div className='comment-content-container commented-by-container'>
                        <h5>
                          {' '}
                          {element?.type === 'REMARKS'
                            ? statusNameEdit(element?.status)
                            : `${i18next.t(
                                'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.COMMENTED_BY'
                              )} ${element?.createdBy?.fullName}`}
                        </h5>
                        {element?.createdDate && (
                          <p className='comment-timeline'>
                            {convertUTCToLocalDateTime(element?.createdDate)}
                          </p>
                        )}
                      </div>
                      <div className='status-container'>
                        {element?.status === 'ORDER_REJECTED' &&
                          element?.type === 'REMARKS' && (
                            <>
                              <h5 className='upload-pod'>
                                {element?.details?.description}
                              </h5>
                              <h5 className='reject-pod'>
                                {i18next.t('DYNAMIC_FORM.SHARED.REMARK')}:
                              </h5>
                            </>
                          )}
                        <p className='status-message'>{element?.message}</p>
                        {element?.details?.fileName &&
                          element?.type === 'REMARKS' && (
                            <>
                              {element?.status === orderStatus.REJECTED_WITH_POD && (
                                <>
                                  <p className='upload-pod pod-heading'>
                                    {i18next.t(
                                      'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.REJECTED_POD'
                                    )}
                                    :
                                  </p>
                                  <a
                                    className='ms-pointer align-center'
                                    onClick={() => handleFileDownload(element)}
                                  >
                                    <i className='a-icon boschicon-bosch-ic-document' />
                                    {element?.details?.fileName}
                                  </a>
                                </>
                              )}
                              {element?.status === orderStatus.DELIVERED && (
                                <>
                                  <p className='upload-pod pod-heading'>
                                    {i18next.t(
                                      'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.UPLOADED_POD'
                                    )}
                                    :
                                  </p>
                                  <a
                                    className='ms-pointer align-center'
                                    onClick={() => handleFileDownload(element)}
                                  >
                                    <i className='a-icon boschicon-bosch-ic-document' />
                                    {element?.details?.fileName}
                                  </a>
                                </>
                              )}
                            </>
                          )}
                        {element?.status === orderStatus.INTRANSIT &&
                          element?.type === 'REMARKS' && (
                            <div className='remark-container'>
                              <div className='logistic-details'>
                                <p className='pod-heading'>
                                  {i18next.t(
                                    'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.LOGISTICS_NAME'
                                  )}
                                  : {element?.details?.logisticsName}
                                </p>{' '}
                                {element?.details?.trackingUrl && (
                                  <p className='pod-heading'>
                                    {i18next.t(
                                      'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.TRACKING_URL'
                                    )}
                                    :{' '}
                                    <a
                                      href={element?.details.trackingUrl}
                                      rel='noreferrer'
                                      target='_blank'
                                    >
                                      {element?.details?.trackingUrl?.length > 40
                                        ? `${element.details.trackingUrl.substring(
                                            0,
                                            40
                                          )}...`
                                        : element?.details?.trackingUrl}
                                    </a>
                                  </p>
                                )}
                              </div>
                              <div className='logistic-details'>
                                {element?.details?.expectedDeliveryDate !== null && (
                                  <p className='pod-heading'>
                                    {i18next.t(
                                      'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.EXPECTED_DATE'
                                    )}
                                    :{' '}
                                    {convertUTCToLocalDate(
                                      element?.details?.expectedDeliveryDate
                                    )}
                                  </p>
                                )}{' '}
                                <p className='pod-heading'>
                                  {i18next.t(
                                    'ORDER_MANAGEMENT.VERTICAL_COMMENT_PROGRESSBAR.LR_NUMBER'
                                  )}
                                  : {element?.details?.lrNumber}
                                </p>{' '}
                                {element?.details?.blobFileUrl && (
                                  <p className='pod-heading'>
                                    <a onClick={() => handleFileDownload(element)}>
                                      <i className='a-icon boschicon-bosch-ic-document' />
                                      {element?.details?.fileName}
                                    </a>
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

VerticleCommentsProgressBar.propTypes = {
  stepperOption: PropTypes.array,
  activeCategory: PropTypes.number,
  setActiveCategory: PropTypes.func,
  title: PropTypes.string
};

VerticleCommentsProgressBar.defaultProps = {
  stepperOption: [
    { displayName: 'one', description: 'two' },
    { displayName: 'one', description: 'two' }
  ],
  setActiveCategory: () => {},
  title: 'Comments'
};

export default VerticleCommentsProgressBar;
