const initialState = {
  allMasterData: {},
  totalSubCategoryData: [],
  isDataFetched: false
};

// Not consumed
const MasterDataReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_MASTER_DATA_PREVIEW':
      return Object.assign({}, state, { allMasterData: action.payload });
    case 'GET_ORDER_MASTER_DATA_PREVIEW':
      return Object.assign({}, state, { orderMasterData: action.payload });
    case 'SUBCATEGORY_DATA_PREVIEW':
      return Object.assign({}, state, { totalSubCategoryData: action.payload });
    case 'IS_DATA_FETCHED_PREVIEW':
      return Object.assign({}, state, { isDataFetched: action.payload });
    default:
      return state;
  }
};

export default MasterDataReducer_Preview;
