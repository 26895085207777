import React from 'react';
import PropTypes from 'prop-types';

import Button from './button';

const SecondaryButton = ({ ...props }) => {
  return (
    <Button
      {...props}
      className={props.className ? props.className : 'a-button--secondary'}
    />
  );
};

SecondaryButton.propTypes = {
  className: PropTypes.string
};

SecondaryButton.defaultProps = {
  className: ''
};

export default SecondaryButton;
