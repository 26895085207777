import axios from 'axios';
import * as Constants from './configuration';
import { getRegionCode } from '../utils/get-region-details';

export function getCountryList() {
  return (dispatch) =>
    axios
      .get(Constants.REGISTER_USER_ACTION.GET_COUNTRY_LIST, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'COUNTRY_LIST',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getRegionSpecificCountryList() {
  const regionCode = getRegionCode();
  return (dispatch) =>
    axios
      .get(
        Constants.REGISTER_USER_ACTION.GET_COUNTRY_LIST_USER_ONBOARD +
          `${regionCode}?typeOfUsage=USER_ONBOARD`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'REGION_SPECIFIC_COUNTRY_LIST',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
