import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllCategoryList } from '../../actions/CategoryManagementAction';
import NavigationButtons from '../../msreact-kit/html-components/navigation-buttons';
import { CategoryCard } from '../../msreact-kit/packages/html-components';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import Loader from '../../components/Shared/Utils/Loader';
import idConstants from '../../data/idConstants';
import Images from '../../assets/img';
import './index.scss';

const ListCategory = ({ ...props }) => {
  const ListCategoryData = {
    description: i18next.t('DYNAMIC_FORM.LIST_CATEGORY.ADD_HARDWARE_MANUALLY'),
    subDescription: i18next.t(
      'DYNAMIC_FORM.LIST_CATEGORY.PLEASE_CHOOSE_YOUR_HARDWARE_TYPE'
    )
  };
  const categoryId = idConstants.hardware.parentCategoryId;

  const dispatch = useDispatch();
  const history = useHistory();
  const [handleLoader] = useHandleLoader();

  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentParentId, setCurrentParentId] = useState(categoryId);
  const [parentIds, setParentIds] = useState([categoryId]);

  const { loader, categories } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader,
      categories: state.CategoryManagementReducer.categoryAllList
    };
  });

  useEffect(() => {
    handleLoader();
    dispatch(getAllCategoryList());
  }, []);

  useEffect(() => {
    if (parentIds.length > 0) {
      setCurrentParentId(parentIds[parentIds.length - 1]);
    } else setCurrentParentId(categoryId);
  }, [parentIds]);

  const nextHandler = () => {
    let updatedParent = 0;
    categories.forEach((item) => {
      if (item.parentCategoryId === selectedCategory) {
        setCurrentParentId(selectedCategory);
        setSelectedCategory('');
        if (updatedParent === 0) setParentIds((prev) => [...prev, selectedCategory]);
        updatedParent = 1;
      }
    });
    if (updatedParent === 0)
      props.history.push({
        pathname: '/hardware/create',
        state: { productCategoryId: selectedCategory }
      });
  };

  const prevHandler = () => {
    if (currentParentId !== categoryId) {
      setParentIds((prev) => {
        if (prev.length === 1) return [prev];
        const obj = [...prev];
        return [...obj];
      });
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <div>
      {loader && <Loader />}
      <div className='list-category__header'>
        <h5>{ListCategoryData.description}</h5>
        <p className='-size-xs'>{ListCategoryData.subDescription}</p>
      </div>
      <div className='list-category'>
        <div className='list-category__left' key={parentIds[parentIds.length - 1]}>
          {categories &&
            categories.map((item) => {
              if (
                item?.parentCategoryId !== null &&
                item?.parentCategoryId === currentParentId
              ) {
                return (
                  <div
                    className='list-category__custom-radio-long'
                    key={item.categoryId}
                  >
                    <div className='list-category__category-thumb-container'>
                      <img
                        className='list-category__category-thumb-img'
                        src={
                          item.thumbnailImageUrl
                            ? item.thumbnailImageUrl
                            : Images.imagery
                        }
                        alt={i18next.t('DYNAMIC_FORM.LIST_CATEGORY.PRODUCT_IMAGE')}
                      />
                    </div>
                    <CategoryCard
                      id={item.categoryId}
                      description={item.parentCategoryId + 'category'}
                      radioCheck={selectedCategory}
                      displayName={
                        <img
                          src={item.img}
                          alt=''
                          className='list-category__custom-radio-long__image'
                        />
                      }
                      changeHandler={(event) => {
                        setSelectedCategory(event.id);
                      }}
                    />
                    <div className='list-category__custom-radio-long__bottom-text highlight'>
                      {item.displayName}
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>

        <div className='list-category__right'>
          <img
            src={Images.addProduct}
            alt=''
            className='list-category__right__image'
          />
        </div>
      </div>
      <div className='list-category__footer'>
        <NavigationButtons
          nextStepLabel={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT')}
          handleNext={nextHandler}
          prevStepLabel={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.BACK')}
          handlePrevious={prevHandler}
          nextStepDisabled={selectedCategory.length === 0}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};

export default ListCategory;
