import IdConstants from '../data/idConstants';

const progressing = (bool, docTypeId) => {
  if (bool) {
    let progressDataObj = { progressFlag: bool };
    if (docTypeId) {
      if (docTypeId === IdConstants.documentType.thumbnailImg) {
        progressDataObj.documentSection = 'thumbnail';
      } else if (docTypeId === IdConstants.documentType.techDocs) {
        progressDataObj.documentSection = 'techDocs';
      } else if (docTypeId === IdConstants.documentType.videoFile) {
        progressDataObj.documentSection = 'videoFiles';
      }
    }
    return {
      type: 'SHOW_PROGRESS',
      data: progressDataObj
    };
  } else {
    let progressDataObj = { progressFlag: bool };
    return {
      type: 'HIDE_PROGRESS',
      data: progressDataObj
    };
  }
};

export default progressing;
