import React, { Component } from 'react';
import Tooltip from '../../../Shared/Tooltip';
import { Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import GroupsIcon from '@material-ui/icons/GroupOutlined';
import PlanDetailsDialog from './PlanDetailsDialog';
import IdConstants from '../../../../data/idConstants';
import Images from '../../../../assets/img';
import { minPlanBenefitsToShow } from '../../../../shared/constants/field-constants';
import CustomToolTip from '../../custom-tooltip';
import i18next from 'i18next';
import {
  Badge,
  ToggleButton,
  IconButton,
  SecondaryButton
} from '../../../../msreact-kit/packages/html-components';
import { getPricingPlanStatus } from '../../../../utils';

class PricingPlanCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handlePreviewState: false,
      openViewDialog: false
    };
  }

  getPriceRange(plan) {
    if (
      plan.rateLimit?.slabLimitDetails &&
      plan.rateLimit?.slabLimitDetails?.length !== 0
    ) {
      let priceValues = [];
      let minPrice = '';
      let maxPrice = '';
      plan.rateLimit?.slabLimitDetails?.map((tier) => {
        priceValues.push(tier.cost);
        return true;
      });
      //checking if all values are same
      if (priceValues.every((item) => item === priceValues[0])) {
        minPrice = priceValues[0];
      } else {
        priceValues.sort(function (firstElement, secondElement) {
          return firstElement - secondElement;
        });
        minPrice = priceValues[0];
        if (priceValues.length > 1) {
          maxPrice = priceValues[priceValues.length - 1];
        }
      }
      return (
        <>
          {minPrice !== '' && (
            <span>
              {minPrice} {plan.currency?.currencyCode}
            </span>
          )}
          {maxPrice !== '' && (
            <span>
              {' '}
              - {maxPrice} {plan.currency?.currencyCode}
            </span>
          )}
        </>
      );
    }
  }

  closeViewDialog = () => {
    this.setState({
      openViewDialog: false
    });
  };

  openViewModal = () => {
    this.setState({
      openViewDialog: true
    });
  };

  deletePlan = (plan) => {
    this.props.deletePlan(plan);
  };
  handleSubscribe = (plan) => {
    if (this.props.isBuyer !== undefined && this.props.isBuyer) {
      this.props.subscribePlan(plan);
    } else {
      this.setState({ handlePreviewState: true });
    }
  };

  handleRenderRateLimitType = () => {
    const tempRateLimit = this.props.plan.rateLimit;
    if (
      tempRateLimit.rateLimitType?.limitType ===
      IdConstants.pricing.reqCountLimitType
    ) {
      return tempRateLimit?.chargeableUnit?.chargeableUnitType ===
        IdConstants.pricing.fullSlabType
        ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.FULL_SLAB')
        : i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER_REQUEST');
    } else {
      return tempRateLimit
        ? 'Per ' + tempRateLimit?.bandwidthUnitType?.bandwidthUnit + ' (Data Volume)'
        : '';
    }
  };

  render() {
    let plan = this.props.plan;
    let card = plan.cardContent;
    let background = '';
    const { type, cardType } = getPricingPlanStatus(
      plan?.status?.description,
      this?.props?.prodBasicInfo?.status?.statusId
    );

    switch (plan.planName) {
      case 'BASIC':
        background = 'background_Basic';
        break;
      case 'GOLD':
        background = 'background_Gold';
        break;
      case 'PREMIUM':
        background = 'background_Premium';
        break;
      default:
        background = 'background_Custom';
    }
    return (
      <div className='pricing-card' style={{ opacity: plan?.enabled ? 1 : 0.5 }}>
        <div className='pricing-card__top'>
          <CustomToolTip
            id={card?.planName}
            tooltipData={card?.planName}
            width='dynamic'
            className='pricing-card__top-customtooltip'
          >
            <p
              className='pricing-card__name  pricing-card__top-items'
              id={card?.planName}
            >
              {card?.planName}
            </p>
          </CustomToolTip>
          {plan?.status?.description && (
            <Badge customClass={`pricing-card__status-badge ${cardType}`}>
              {i18next.t(type)}
            </Badge>
          )}
          <div>
            <p className='pricing-card__price pricing-card__top-items '>
              {this.getPriceRange(this.props?.plan)}
            </p>
          </div>
          <div className='pricing-card__description pricing-card__top-items'>
            {this.handleRenderRateLimitType()}
          </div>
        </div>
        <div className='pricing-card__list'>
          {plan?.privatePlan && (
            <div className='pricing-card__list__user-group-box'>
              {plan?.user ? (
                <div className='pricing-card__display-flex'>
                  <PersonIcon className='pricing-card__list__user-group-icon' />
                  <Tooltip title={plan?.userName}>
                    <Typography
                      variant='subtitle2'
                      className='pricing-card__list__user-group-desc'
                    >
                      {plan?.userName}
                    </Typography>
                  </Tooltip>
                </div>
              ) : (
                <div className='pricing-card__display-flex'>
                  <GroupsIcon className='pricing-card__list__user-group-icon' />
                  <Tooltip title={plan?.businessGroupName}>
                    <Typography
                      variant='caption'
                      className='pricing-card__list__user-group-desc'
                    >
                      {plan?.businessGroupName}
                    </Typography>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
          {plan?.planBenefits?.length ? (
            plan?.planBenefits?.slice(0, minPlanBenefitsToShow)?.map((item) => {
              return (
                <CustomToolTip
                  id={item?.planBenefitId}
                  tooltipData={item?.benefitDescription}
                  width='dynamic'
                  key={item?.planBenefitId}
                >
                  <div className='list-item' id={item?.planBenefitId}>
                    <img
                      className='list-item__checkmark'
                      src={Images.checkMarkBold}
                      alt=''
                      width={16}
                      height={16}
                    />
                    {item.benefitDescription}
                  </div>
                </CustomToolTip>
              );
            })
          ) : (
            /** The following block can be removed after exiting plans migration is done. */
            <>
              <div className='list-item'>
                <img
                  className='list-item__checkmark'
                  src={Images.checkMarkBold}
                  alt=''
                  width={16}
                  height={16}
                />
                {card.endpoints}
              </div>
              <div className='list-item'>
                <img
                  className='list-item__checkmark'
                  src={Images.checkMarkBold}
                  alt=''
                  width={16}
                  height={16}
                />
                {!card.billing.isPayPerCall
                  ? i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.SINGLE_APPLICATION')
                  : i18next.t(
                      'DYNAMIC_FORM.PRICING_PLAN_DIALOG.UNLIMITED_APPLICATION'
                    )}
              </div>
              {/*to be enabled in future for flat pricing*/}
              {/* {!this.props.plan.isSlabwisePricing && (
                <div className='list-item'>
                  <img
                    className='list-item__checkmark'
                    src={Images.checkMarkBold}
                    alt=''
                    width={16}
                    height={16}
                  />
                  {this.props.plan.softLimit
                    ? i18next.t(
                        'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.UNLIMITED_ACCESS'
                      )
                    : i18next.t(
                        'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.LIMITED_ACCESS'
                      )}
                </div>
              )} */}
              <div className='list-item'>
                <img
                  className='list-item__checkmark'
                  src={Images.checkMarkBold}
                  alt=''
                  width={16}
                  height={16}
                />
                {this.props.plan.softLimit
                  ? i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.UNLIMITED_ACCESS'
                    )
                  : i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.LIMITED_ACCESS'
                    )}
              </div>
              <div className='list-item'>
                <img
                  className='list-item__checkmark'
                  src={Images.checkMarkBold}
                  alt=''
                  width={16}
                  height={16}
                />
                {this.props.plan.planBillingCycle?.billingCycleType ===
                IdConstants.pricing.yearBillingCycleType
                  ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.YEARLY')
                  : i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY'
                    )}{' '}
                Billing
              </div>
            </>
          )}
        </div>
        {this.props?.isPreview ? (
          <div className='pricing-card__bottom'>
            <SecondaryButton
              label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.VIEW_DETAILS')}
              onClick={() => this.setState({ handlePreviewState: true })}
            />
            <SecondaryButton
              label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBSCRIBE')}
              onClick={() => this.handleSubscribe(plan)}
            />
          </div>
        ) : (
          <div className='pricing-card__bottom'>
            <SecondaryButton
              label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.VIEW_DETAILS')}
              onClick={() => this.openViewModal()}
            />
            {!this.props.disableFields && (
              <div className='pricing-card__display-flex'>
                <IconButton
                  label=''
                  className='pricing-card__bottom-button'
                  iconCss='pricing-card__bottom-button__icon'
                  iconTitle={i18next.t(
                    'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.EDIT_PLAN'
                  )}
                  onClick={() => this.props?.editPricingPlan(plan)}
                  src={Images.editIcon}
                  isIcon={false}
                />
                <IconButton
                  label=''
                  className='pricing-card__bottom-button'
                  iconCss='pricing-card__bottom-button__icon'
                  iconTitle={i18next.t(
                    'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DELETE_PLAN'
                  )}
                  onClick={() => this.deletePlan(plan)}
                  src={Images.deleteIcon}
                  isIcon={false}
                />
                {!this.props?.isPreview && !this.props?.disableFields && (
                  <Tooltip
                    title={
                      plan?.enabled
                        ? i18next.t(
                            'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DEACTIVATE_PLAN'
                          )
                        : i18next.t(
                            'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.ACTIVATE_PLAN'
                          )
                    }
                  >
                    <div className='toggle-button-div'>
                      <ToggleButton
                        label=''
                        name={plan?.planName}
                        id={plan?.planId}
                        checked={plan?.enabled}
                        handleChange={() => this.props?.changePlanStatus(plan)}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        )}

        {this.state.openViewDialog && (
          <PlanDetailsDialog
            planDetails={card}
            className={background}
            onClose={this.closeViewDialog}
            open={this.state.openViewDialog}
            {...this.props}
          ></PlanDetailsDialog>
        )}

        {this.state.handlePreviewState && !this.props?.isBuyer && (
          <Typography variant='span' className='pricing-card__not-supported-msg'>
            {i18next.t(
              'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.FUNCTION_NOT_SUPPORTED_IN_PREVIEW'
            )}
          </Typography>
        )}
      </div>
    );
  }
}

export default PricingPlanCard;
