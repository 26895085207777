import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';

import { getQuotationForm } from '../../../actions/QuotationAction';
import QuotationSummaryCard from '../QuotationSummary/QuotationSummaryCard';
import useHandleLoader from '../../../msreact-kit/hooks/useHandleLoader';
import Loader from '../../Shared/Utils/Loader';
import { convertUTCToLocalDateTime } from '../../../utils/date-time-utils';
import idConstants from '../../../data/idConstants';
import { quoteStatus } from '../../../data/appConstants';
import CategoryHighlighter from '../../order-management/category-highlight';
import variables from '../../../variables.module.scss';

import './index.scss';

const QuotationSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [handleLoader] = useHandleLoader();

  const { loader } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader
    };
  });

  const locationState = history.location.state;
  const productSubCategoryId = locationState.productSubCategory.productSubCategoryId;
  const { status } = locationState;

  const handleNexBtnDisable = () =>
    !(
      status.quotationStatusDescription === quoteStatus.inDraft.description ||
      status.quotationStatusDescription === quoteStatus.requested.description
    );

  useEffect(() => {
    handleLoader();
    dispatch(
      getQuotationForm(
        idConstants.professionalServices.categoryId,
        productSubCategoryId
      )
    );
  }, []);

  return (
    <div className='quotations'>
      {loader && <Loader />}
      <p className='quotations__heading highlight -size-xl'>
        {i18next.t('QUOTATIONS.QUOTATION')}
      </p>
      <div className='quotations__wrapper'>
        <div className='quotations__details'>
          <p className='highlight'>
            {i18next.t('QUOTATIONS.QUOTATION_ID')} : {locationState?.quotationId}
          </p>
          <CategoryHighlighter
            category={convertUTCToLocalDateTime(locationState?.submittedDate)}
            categoryColor={variables.badgeMainBg}
          />
        </div>
        {!handleNexBtnDisable() && (
          <div className='quotations__warning'>
            <i className='a-icon boschicon-bosch-ic-alert-warning'></i>
            <p className='quotations__warning__expiry -size-xl'>
              {locationState?.expireIn}{' '}
              {i18next.t('QUOTATIONS.DAYS_LEFT_TO_SUBMIT_QUOTATION')}
            </p>
          </div>
        )}
        <QuotationSummaryCard />
      </div>
    </div>
  );
};
export default QuotationSummary;
