import React, { Component } from 'react';
import PricingPlanDialog from './PricingPlanDialog';
import PrivatePlanDialog from './PrivatePlanDialog';
import Snackbar from '../../../Shared/Utils/SnackBar';
import Loader from '../../../Shared/Utils/Loader';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import { trackEvent } from '../../../../utils/analytics';
import { createTheme, withStyles } from '@material-ui/core/styles';
import DialogText from '../../../../data/dialogText';
import InfoModal from '../../../Shared/Utils/Modal/InformationModal';
import {
  TENANT,
  IS_MKOSINT_TENANT,
  SELLER_TENANT_ID
} from '../../../../actions/configuration';
import './index.scss';
import PricingPlanCard from '../../../Shared/Utils/PricingPlanCard';
import '../add-api.scss';
import ToastMessages from '../../../../data/toastMessages';
import {
  getDraftedPricingPlan,
  isShowPricingPlanNotification,
  handlePricingPlanDeletion,
  canPricingPlanBeEdited
} from '../../../../shared/shared-function/services';
import IdConstants from '../../../../data/idConstants';
import { GAConstants, GAEventAction } from '../../../../data/ga-constants';
import isEmpty from '../../../../validator/isEmpty';
import Dropdown from '../../../../components/Shared/Inputs/Dropdown';
import isDataType from '../../../../validator/isDataType';
import { regionDetails, defaultSlabDetails } from '../../../../data/appConstants';
import variables from '../../../../variables.module.scss';
import {
  DialogBox,
  TextInput
} from '../../../../msreact-kit/packages/html-components';
import PricingNotification from '../../../pricing/pricing-notification';
import Images from '../../../../../src/assets/img';
import i18next from 'i18next';
import { getRegionCode } from '../../../../utils/get-region-details';
import { getPricingPlanType, getTryOutPlan } from '../../../pricing/utils';
import getUserId from '../../../../utils/get-user-id';

const useStyles = {
  card: {
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    width: '100%',
    height: '350px',
    display: 'grid',
    justifyContent: 'center',
    borderTopColor: '#0000008c',
    borderBottomColor: '#0000008c',
    padding: '80px 0px 80px 0px',
    cursor: 'pointer',
    fontFamily: variables.generalBoschFont
  },
  addCircleIcon: {
    fill: '#cacaca',
    width: '70px',
    height: '70px',
    justifySelf: 'center',
    marginTop: '2.5rem'
  },
  grid: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'center'
  },
  standardBoxStyle: {
    border: '1px solid lightgray',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '2px',
    marginLeft: '230px'
  },
  privateBoxStyle: {
    border: '1px solid lightgray',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '2px'
  },
  planDescription: {
    marginTop: '20px'
  },
  boxHeading: {
    marginBottom: '10px'
  },
  tryOutTextStyle: {
    fontSize: '0.9rem',
    fontFamily: variables.mediumBoschFont
  },
  fileDownloadParent: {
    position: 'relative'
  },
  closeModal: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  modalHeaderStyles: {
    textAlign: 'center',
    position: 'relative',
    left: '25px'
  },
  downloadIcon: {
    margin: '0',
    padding: '0'
  },
  dropdownWrapper: {
    marginTop: '50px',
    marginBottom: '20px',
    minHeight: '125px'
  },
  inputLabel: {
    marginTop: '20px',
    '& > *': {
      margin: '5px 0px',
      fontSize: '0.8rem',
      fontFamily: variables.mediumBoschFont,
      color: '#333'
    }
  },
  labelAsterisk: {
    color: 'red'
  }
};
const theme = (theme) =>
  createTheme({
    overrides: {
      MuiTypography: {
        body1: {
          fontFamily: variables.generalBoschFont
        }
      }
    }
  });
class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openPrivatePricingDialog: false,
      editPlan: {},
      isEditPlan: false,
      snackBarFlag: false,
      snackBarMsg: '',
      snackBarSeverity: '',
      openConfirmModal: false,
      modalHeading: '',
      confirmModalText: '',
      deleteIndex: '',
      raised: false,
      openInfoModal: false,
      apiPlans: [],
      apiPvtPlans: [],
      openPreviewModalState: false,
      currentPlanSelection: 'Standard',
      allowTryOut: false,
      currentPlan: _.cloneDeep(getTryOutPlan()),
      requestCount: 1,
      tryOutPlan: {},
      downloadFileModalState: false,
      currentFormat: '',
      productSacCode: '',
      confirmLabel: {}
    };
    this.changeState = this.changeState.bind(this);
    this.updateSnackBar = this.updateSnackBar.bind(this);
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeInfoPopup = this.closeInfoPopup.bind(this);
  }

  changeState() {
    this.setState({
      snackBarFlag: false
    });
  }

  componentDidMount() {
    if (this.props?.apiPlans?.length > 0) {
      this.populateCards();
      let prodSacCode = this.props?.prodBasicInfo?.saccode
        ? this.props.prodBasicInfo.saccode
        : '';
      this.setState({
        productSacCode: prodSacCode
      });
    }
    this.props.getApprovedUsers();
    this.props.getOrgGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props?.apiPlans !== prevProps.apiPlans) {
      this.populateCards();
    }
    if (
      this.props?.prodBasicInfo !== prevProps.prodBasicInfo &&
      this.props?.prodBasicInfo?.productId
    ) {
      let prodSacCode = this.props?.prodBasicInfo?.saccode
        ? this.props.prodBasicInfo.saccode
        : '';
      this.setState({
        productSacCode: prodSacCode
      });
    }
  }

  populateCards() {
    let displayCards = [];
    let displayPvtCards = [];
    let plans = this.props.apiPlans;
    plans.map((plan) => {
      let isDataBandwidth = false;
      let displayCard = {
        planName: plan.planName,
        description: plan.description,
        endpoints: 'Access to All Endpoints',
        billing: {
          cost: '',
          duration: '',
          isPayPerCall: false
        },
        status: '',
        limit: '',
        quota: {
          isSelected: false,
          value: ''
        },
        burstControl: {
          isSelected: false,
          value: ''
        },
        isPlanSubscribed: false
      };
      displayCard.isPlanSubscribed = plan.subscribed;
      if (
        plan.planBillingCycle.billingCycleType ===
        IdConstants.pricing.payPerCallBillingCycleType
      ) {
        displayCard.billing.cost = 'Pay Per Call';
        displayCard.limit =
          '₹ ' + plan.softLimitData?.additionalCost + ' per each request';
        displayCard.billing.isPayPerCall = true;
      } else {
        displayCard.billing.cost = plan.planCost;
        if (
          plan.planBillingCycle.billingCycleType ===
          IdConstants.pricing.yearBillingCycleType
        ) {
          displayCard.billing.duration = '/Year';
        } else {
          displayCard.billing.duration = '/Month';
        }
        if (
          plan.rateLimit?.rateLimitType?.limitType ===
          IdConstants.pricing.bandWidthRateLimitType
        ) {
          displayCard.limit =
            plan.rateLimit?.limitCount +
            plan.rateLimit?.bandwidthUnitType?.bandwidthUnit +
            ' data';
          isDataBandwidth = true;
        } else {
          displayCard.limit = plan.rateLimit?.limitCount + ' requests';
        }
        if (
          plan.rateLimit?.timeUnit?.timeUnitCode ===
          IdConstants.pricing.monthTimeUnitCode
        ) {
          displayCard.limit = displayCard.limit + '/month';
        } else {
          displayCard.limit = displayCard.limit + '/day';
        }
        displayCard.quota.isSelected = true;
        if (plan.softLimit) {
          if (isDataBandwidth) {
            displayCard.quota.value =
              '₹ ' +
              plan.softLimitData?.additionalCost +
              ' extra per each ' +
              plan.rateLimit?.bandwidthUnitType?.bandwidthUnit;
          } else if (plan.softLimitData) {
            displayCard.quota.value =
              '₹ ' + plan.softLimitData?.additionalCost + ' extra per each request';
          }
        } else {
          displayCard.quota.value = 'Hardlimit';
        }
      }
      if (plan.burstControlLimit !== null) {
        displayCard.burstControl.isSelected = true;
        switch (plan?.burstControlLimit?.timeUnit?.timeUnitCode) {
          case IdConstants.pricing.hourBurstControl:
            displayCard.burstControl.value =
              'Maximum ' + plan.burstControlLimit.limitCount + ' requests/hour';
            break;
          case IdConstants.pricing.minuteBurstControl:
            displayCard.burstControl.value =
              'Maximum ' + plan.burstControlLimit.limitCount + ' requests/minute';
            break;
          case IdConstants.pricing.secondBurstControl:
            displayCard.burstControl.value =
              'Maximum ' + plan.burstControlLimit.limitCount + ' requests/second';
            break;
          default:
            break;
        }
      }
      if (plan.enabled) {
        displayCard.status = 'Active';
      } else {
        displayCard.status = 'Inactive';
      }
      if (plan.tryOut) {
        this.setState((prevState) => ({
          currentPlan: {
            ...prevState.currentPlan,
            planId: plan.planId,
            planName: plan.planName,
            requestCount: plan.rateLimit?.limitCount
          },
          requestCount: plan.rateLimit?.limitCount,
          tryOutPlan: {
            planId: plan.planId,
            enabled: plan.enabled,
            userDetails: {
              userId: getUserId(),
              typeOfUser: IdConstants.typeOfUsers.b2b,
              b2bIdentifier: this.props?.prodBasicInfo?.companyNo
            }
          }
        }));
      }
      if (plan.tryOut && plan.enabled) {
        this.setState({
          allowTryOut: true
        });
      }
      plan.cardContent = displayCard;
      if (plan.privatePlan) {
        displayPvtCards.push(plan);
      } else {
        displayCards.push(plan);
      }
      return true;
    });
    let sortedCards = displayCards;
    sortedCards.sort((firstCardName, SecondCardName) =>
      firstCardName.planName
        .toLowerCase()
        .localeCompare(SecondCardName.planName.toLowerCase())
    );
    this.setState({
      apiPlans: sortedCards,
      apiPvtPlans: displayPvtCards
    });
  }

  addPlan = () => {
    switch (this.state.currentPlanSelection) {
      case 'Standard':
        this.setState({
          openDialog: true,
          isEditPlan: false
        });
        break;
      case 'Private':
        this.setState({
          openPrivatePricingDialog: true,
          isEditPlan: false
        });
        break;
      default:
        break;
    }
  };

  closeDialog = () => {
    switch (this.state.currentPlanSelection) {
      case 'Standard':
        this.setState({
          openDialog: false
        });
        break;
      case 'Private':
        this.setState({
          openPrivatePricingDialog: false
        });
        break;
      default:
        break;
    }
  };

  editPricingPlan = async (plan) => {
    if (canPricingPlanBeEdited(plan)) {
      await this.props.getBurstControlInfo(plan.planId).then((response) => {
        if (response) {
          plan.burstControlLimit = response;
        }
      });
      switch (this.state.currentPlanSelection) {
        case 'Standard':
          this.setState({
            openDialog: true,
            editPlan: plan,
            isEditPlan: true
          });
          break;
        case 'Private':
          this.setState({
            openPrivatePricingDialog: true,
            editPlan: plan,
            isEditPlan: true
          });
          break;
        default:
          break;
      }
    } else {
      this.setState({
        openInfoModal: true,
        confirmModalText: i18next.t('SHARED.CAN_NOT_EDIT_INFORMATION'),
        modalHeading: i18next.t('MY_ACCOUNT.INFORMATION'),
        editPlan: plan
      });
    }
  };

  deletePlan = (plan) => {
    const activePlans = this.props?.apiPlans?.filter(
      (filteredPlan) =>
        filteredPlan.enabled === true && filteredPlan.tryOut === false
    );

    const pricingPlanDeletion = handlePricingPlanDeletion(
      plan,
      this.props?.prodBasicInfo?.status?.statusId,
      activePlans
    );

    if (pricingPlanDeletion) {
      this.setState({
        openInfoModal: true,
        confirmModalText: i18next.t(pricingPlanDeletion),
        modalHeading: i18next.t('MY_ACCOUNT.INFORMATION'),
        editPlan: plan
      });
      return;
    }

    this.setState({
      openConfirmModal: true,
      confirmModalText: i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ACTION_WILL_PERMANENTLY_REMOVE_THE_PLAN'
      ),
      modalHeading:
        `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ARE_YOU_SURE_WANT_TO_DELETE')} "` +
        plan?.planName +
        `" ${i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN').toLowerCase()}?`,
      editPlan: plan,
      confirmLabel: {
        primaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.YES'),
        secondaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NO')
      }
    });
  };

  showPopUpWithNoAction = (message, plan) => {
    this.setState({
      openInfoModal: true,
      confirmModalText: message,
      modalHeading: i18next.t('MY_ACCOUNT.INFORMATION'),
      editPlan: plan
    });
  };

  dispatchUpdatePricingPlanStatus = (plan, productId, message) => {
    const userDetails = {
      userId: getUserId(),
      typeOfUser: IdConstants.typeOfUsers.b2b,
      b2bIdentifier: this.props?.prodBasicInfo?.companyNo
    };
    plan.userDetails = plan.userDetails !== null ? plan.userDetails : userDetails;
    this.props
      .updatePricingPlanStatus(plan, productId)
      .then((response) => {
        this.showPopUpWithNoAction(i18next.t(message), plan);
        this.props.updateInfo(this.props.info, 'isPlanEdited', true);
      })
      .catch((error) => {
        error && this.showPopUpWithNoAction(error, plan);
      });
  };

  deactivateSubscribedPlan = (plan) => {
    this.setState({
      openConfirmModal: true,
      modalHeading:
        `${i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_TITLE')} "` +
        plan?.planName +
        `" ${i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN').toLowerCase()}?`,
      confirmModalText: i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_BODY'),
      editPlan: plan,
      confirmLabel: {
        primaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.YES'),
        secondaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NO')
      }
    });
  };

  changePlanStatus = (plan) => {
    if (plan?.enabled) {
      if (plan?.subscribed) {
        this.deactivateSubscribedPlan(plan);
      } else {
        this.setState({
          openConfirmModal: true,
          confirmModalText: i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_BODY'),
          modalHeading:
            `${i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_TITLE')} "` +
            plan?.planName +
            `" ${i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN').toLowerCase()}?`,
          editPlan: plan,
          confirmLabel: {
            primaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.YES'),
            secondaryLabel: i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NO')
          }
        });
      }
    } else {
      this.dispatchUpdatePricingPlanStatus(
        plan,
        this.props?.prodBasicInfo?.productId,
        'SHARED.PLAN_IS_ACTIVATED'
      );
    }
  };

  updateSnackBar(value, msg, severity) {
    this.setState({
      snackBarFlag: value,
      snackBarMsg: msg,
      snackBarSeverity: severity
    });
  }

  openSubmitConfirmModal = () => {
    this.setState({
      openConfirmModal: true,
      modalHeading: DialogText.submitConfirmation.modalHeading,
      confirmModalText: DialogText.submitConfirmation.confirmModalText,
      confirmLabel: {}
    });
  };

  onMouseOver = () => {
    this.setState({ raised: true });
  };
  onMouseOut = () => {
    this.setState({ raised: false });
  };

  handleOpenConfirmModal() {
    this.setState({ openConfirmModal: false });
  }

  closeInfoPopup() {
    this.setState({ openInfoModal: false });
  }
  okBtnAction = (data) => {
    const switchCondition =
      this.state.modalHeading === i18next.t('MY_ACCOUNT.INFORMATION')
        ? this.state.modalHeading
        : this.state.confirmModalText;
    switch (switchCondition) {
      case i18next.t('SHARED.SUBSCRIBED_PLAN_DEACTIVATE_BODY'):
        this.dispatchUpdatePricingPlanStatus(
          this.state.editPlan,
          this.props.prodBasicInfo.productId,
          'SHARED.PLAN_IS_DEACTIVATED'
        );
        break;
      case i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ACTION_WILL_PERMANENTLY_REMOVE_THE_PLAN'
      ):
        this.props
          .deletePricingPlan(
            this.state.editPlan,
            this.props.prodBasicInfo.productId,
            IdConstants.api.categoryId,
            this.props?.prodBasicInfo?.status?.description,
            this.props?.prodBasicInfo?.companyNo
          )
          .then(() => {
            if (!this.props.deletePlanError) {
              this.updateSnackBar(
                true,
                i18next.t(
                  'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.PRICING_PLAN_DELETED_SUCCESSFULLY'
                ),
                'success'
              );
              this.props.updateInfo(this.props.info, 'isPlanEdited', true);
            } else {
              this.updateSnackBar(true, this.props.deletePlanError, 'error');
            }
          });
        break;
      case i18next.t('MY_ACCOUNT.INFORMATION'):
      case i18next.t('SHARED.CAN_NOT_EDIT_INFORMATION'):
      case i18next.t('SHARED.PLAN_IS_DEACTIVATED'):
      case i18next.t('SHARED.PLAN_IS_ACTIVATED'):
      case i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DO_NOT_HAVE_PERMISSION_TO_DELETE_PRICING_PLAN'
      ):
      case i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.AT_LEAST_ONE_PRICING_PLAN_REQUIRED'
      ):
        this.setState({ openInfoModal: false });
        break;
      default:
        this.handleSubmit();
        break;
    }
    this.setState({ openConfirmModal: false });
  };

  updatePricingStatus = async (plansArray, expectedStatus) => {
    return await this.props.updatePricingStatus(
      plansArray,
      this.props?.prodBasicInfo?.productId,
      expectedStatus
    );
  };

  handleSubmit() {
    if (getRegionCode() !== regionDetails[0].regionCode)
      delete this.state.productSacCode;
    //GA-309-API
    let gaCategory = this.props?.info?.isEdit === true ? 'edit' : 'create';
    trackEvent(
      `${gaCategory}_product_pricing_plan`,
      GAEventAction.clicked,
      this.props.prodBasicInfo?.productName
    );

    if (!this.props.error && isEmpty(this.props.errorInfo)) {
      let updateProdData = {};
      updateProdData.productSACCode = this.state.productSacCode;
      this.props
        .updateProductInfo(
          this.props.prodBasicInfo,
          null,
          this.props?.prodBasicInfo?.productId,
          this.props,
          updateProdData
        )
        .then(async () => {
          const draftedPlans = await getDraftedPricingPlan(this?.props?.apiPlans);
          if (draftedPlans?.length > 0) {
            if (
              !this.updatePricingStatus(draftedPlans, IdConstants.submitted.statusId)
            ) {
              return;
            }
          }

          this.props
            .updateProductStatus(
              IdConstants.submitted.statusId,
              this.props?.prodBasicInfo?.productId,
              this.props,
              GAConstants.isTriggerGAEvent
            )
            .then(() => {
              if (!isEmpty(this.props.errorInfo)) {
                this.setState(
                  {
                    snackBarFlag: true,
                    snackBarSeverity: 'error',
                    snackBarMsg: this.props.errorInfo.message
                  },
                  function () {
                    this.props.resetErrorInfo();
                  }
                );
              } else {
                this.props.updateInfo(this.props.info, 'showPopup', false);
                this.props.updateInfo(this.props.info, 'reset', false);
              }
            });
        });
    } else if (!isEmpty(this.props.errorInfo)) {
      this.setState(
        {
          snackBarFlag: true,
          snackBarSeverity: 'error',
          snackBarMsg: this.props.errorInfo.message
        },
        function () {
          this.props.resetErrorInfo();
        }
      );
    }
  }

  openPreviewModal = () => {
    this.setState({ openPreviewModalState: !this.state.openPreviewModalState });
  };

  updateProductStatusToDraft = () => {
    if (
      this.props?.prodBasicInfo?.status?.statusId ===
        IdConstants.rejected.statusId ||
      (this.state.isEditPlan &&
        this.props?.prodBasicInfo?.status?.statusId ===
          IdConstants.submitted.statusId &&
        this.props?.apiPlans?.filter(
          (plan) => plan.status.description === IdConstants.submitted.description
        ).length === 1)
    ) {
      this.props.updateProductStatus(
        IdConstants.drafted.statusId,
        this.props?.prodBasicInfo?.productId,
        this.props
      );
    }
  };

  createPricingPlan = (state) => {
    this.props.auditLogging(
      IdConstants.auditLogsModuleIds.pricingModuleId,
      state,
      'request',
      'success',
      IdConstants.api.categoryId
    );
    this.props
      .createPricingPlan(
        state,
        this.props?.prodBasicInfo?.productId,
        IdConstants.api.categoryId,
        GAConstants.isTriggerGAEvent
      )
      .then(() => {
        if (!this.props.error && isEmpty(this.props?.errorInfo)) {
          this.closeDialog();
          this.updateSnackBar(true, ToastMessages.addPlan.msg, 'success');
          this.updateProductStatusToDraft();
          this.props.auditLogging(
            IdConstants.auditLogsModuleIds.pricingModuleId,
            this.props?.apiPlan,
            'response',
            'success',
            IdConstants.api.categoryId
          );
        } else if (!isEmpty(this.props.errorInfo)) {
          this.updateSnackBar(true, this.props.errorInfo.message, 'error');
          this.props.resetErrorInfo();
          this.props.auditLogging(
            IdConstants.auditLogsModuleIds.pricingModuleId,
            this.props.errorInfo,
            'response',
            'failure',
            IdConstants.api.categoryId
          );
        }
      });
  };

  updatePricingPlan = (state) => {
    this.props
      .updatePricingPlan(
        state,
        this.props?.prodBasicInfo?.productId,
        IdConstants.api.categoryId
      )
      .then(async () => {
        if (!this.props.error && isEmpty(this.props.errorInfo)) {
          this.closeDialog();
          this.updateSnackBar(true, ToastMessages.update.msg, 'success');
          this.props.updateInfo(this.props.info, 'isPlanEdited', true);
          this.updateProductStatusToDraft();
          if (
            this.props?.apiPlans?.find(
              (item) => item.planId === state.currentPlan.planId
            )?.statusId !== IdConstants.drafted.statusId
          ) {
            if (
              !(await this.updatePricingStatus(
                [state.currentPlan.planId],
                IdConstants.drafted.statusId
              ))
            ) {
              return;
            } else {
              this.props.getApiPlans(this.props?.prodBasicInfo?.productId);
            }
          } else {
            this.props.getApiPlans(this.props?.prodBasicInfo?.productId);
          }
        } else if (!isEmpty(this.props.errorInfo)) {
          this.updateSnackBar(true, this.props.errorInfo.message, 'error');
          this.props.resetErrorInfo();
        }
      });
  };

  setCurrentPlan = (plan) => {
    this.setState({ currentPlanSelection: plan });
  };

  handleTryOutChange = (event) => {
    this.setState(
      {
        allowTryOut: event.target.checked
      },
      function () {
        if (!this.state.currentPlan.planId) {
          let prodId = this.props?.prodBasicInfo?.productId;
          //Taking last 8 chars of product Id to create unique tryout plan for product
          let planName =
            prodId.toUpperCase().substring(prodId.length - 8, prodId.length) +
            '_TRYOUT';
          //setting default values for creating tryout plan.
          let chargeableUnit =
            this.props?.pricingPlanMasterData?.chargeable_unit?.find(
              function (element) {
                return element.name === IdConstants.pricing.perReqHitSlabType;
              }
            );
          let selectedChargeableUnit = {
            chargeableUnitType: chargeableUnit?.name
          };
          delete defaultSlabDetails.disableUnlimited;
          const priceSlabs = [
            {
              ...defaultSlabDetails,
              maxValue: '10'
            }
          ];
          if (this.state.allowTryOut) {
            this.setState(
              (prevState) => ({
                currentPlan: {
                  ...prevState.currentPlan,
                  chargeableUnit: selectedChargeableUnit,
                  planName: planName,
                  billingCycleType: IdConstants.pricing.monthBillingCycleType,
                  requestCount: this.state.requestCount,
                  userDetails: {
                    userId: getUserId(),
                    typeOfUser: IdConstants.typeOfUsers.b2b,
                    b2bIdentifier: this.props?.prodBasicInfo?.companyNo
                  },
                  planDetails: {
                    planType: IdConstants.pricingPlanType.tryout,
                    paymentType: IdConstants.pricingPaymentType.notApplicable,
                    paymentModePref: IdConstants.pricingPaymentType.notApplicable,
                    occurrence: 1
                  },
                  isTryout: true
                },
                priceSlabs: priceSlabs,
                pricingPlanTypeTabValue: _.cloneDeep(getPricingPlanType())
              }),
              function () {
                this.props
                  .createPricingPlan(
                    this.state,
                    this.props?.prodBasicInfo?.productId,
                    IdConstants.api.categoryId
                  )
                  .then(() => {
                    this.setState((prevState) => ({
                      currentPlan: {
                        ...prevState.currentPlan,
                        planId: this.props?.apiPlan?.planId
                      },
                      tryOutPlan: {
                        planId: this.props.apiPlan.planId,
                        enabled: this.props.apiPlan.enabled
                      }
                    }));
                    this.props.updateInfo(this.props?.info, 'isPlanEdited', true);
                  });
              }
            );
          }
        } else {
          this.setState({
            tryOutError: false
          });
          this.props
            .updatePricingPlanStatus(
              this.state.tryOutPlan,
              this.props?.prodBasicInfo?.productId
            )
            .then(() => {
              if (this.props.updateApiPlanError) {
                this.setState({
                  snackBarFlag: true,
                  snackBarMsg: this.props.updateApiPlanError.toString(),
                  snackBarSeverity: 'error',
                  allowTryOut: !this.state.allowTryOut
                });
                return;
              }
              this.props.updateInfo(this.props.info, 'isPlanEdited', true);
            });
        }
      }
    );
  };

  onChangeRequestCount = (event) => {
    if (event.target.value === '' || event.target.value === '0') {
      this.setState({
        tryOutError: true,
        requestCount: ''
      });
    } else {
      this.props.updateInfo(this.props.info, 'isPlanEdited', true);
      this.props.updateInfo(this.props.info, 'isTryOutPlanEdited', true);
      const expression = /^([1-9]\d*)$/;
      if (expression.test(event.target.value.trim())) {
        this.setState(
          {
            requestCount: event.target.value
          },
          function () {
            if (this.state.requestCount.length > 0) {
              this.setState({
                tryOutError: false
              });
            }
          }
        );
      }
    }
  };

  onBlurRequestCount = () => {
    let value = this.state.requestCount;
    if (value.length > 0) {
      this.setState(
        (prevState) => ({
          currentPlan: {
            ...prevState.currentPlan,
            requestCount: value
          }
        }),
        function () {
          this.props
            .updatePricingPlan(
              this.state,
              this.props?.prodBasicInfo?.productId,
              IdConstants.api.categoryId
            )
            .then(() => {
              this.props.updateInfo(this.props.info, 'isTryOutPlanEdited', false);
              this.setState((prevState) => ({
                currentPlan: {
                  ...prevState.currentPlan,
                  planId: this.props.apiPlan.planId
                },
                tryOutPlan: {
                  planId: this.props.apiPlan.planId,
                  enabled: this.props.apiPlan.enabled
                }
              }));
            });
        }
      );
    }
  };

  updateFormat = (format) => {
    this.setState({ currentFormat: format });
  };

  closeDownloadFile = () => {
    this.setState({
      downloadFileModalState: false,
      currentFormat: ''
    });
  };

  openDownloadFile = () => {
    this.setState({
      downloadFileModalState: true,
      currentFormat: ''
    });
  };

  changeSACCodeInput = (event) => {
    let val = event.value;
    this.setState({
      productSacCode: val
    });
    if (val === '') {
      this.setState({
        prodSacCodeErr: i18next.t('DYNAMIC_FORM.SHARED.SAC_HSN_CODE_REQUIRED')
      });
    } else if (isDataType(val, 'NUMBER')) {
      this.setState({
        prodSacCodeErr: i18next.t('DYNAMIC_FORM.SHARED.SAC_HSN_CODE_NUMBER')
      });
    } else {
      this.setState({
        prodSacCodeErr: ''
      });
    }
    this.props.updateInfo(this.props?.info, 'isApiEdited', true);
  };

  validateSubmit = () => {
    if (this.props?.apiPlans !== undefined && this.props.apiPlans.length > 0) {
      let atleastOneActive = this.props.apiPlans.some(function (item) {
        return item.enabled === true && item.tryOut === false;
      });
      let isValidPricingSection = false;
      const isValidPlan = atleastOneActive && !this.state.tryOutError;
      const isValidSacCode = this.state.productSacCode && !this.state.prodSacCodeErr;

      if (SELLER_TENANT_ID === TENANT.MKOSINT) {
        isValidPricingSection = isValidPlan;
      } else {
        if (getRegionCode() !== regionDetails[0].regionCode)
          isValidPricingSection = isValidPlan;
        else isValidPricingSection = isValidPlan && isValidSacCode;
      }

      if (isValidPricingSection) {
        return false;
      }
    }
    return true;
  };

  render() {
    const { classes } = this.props;
    let standardPlansCount = [];
    if (this.props.apiPlans) {
      this.props.apiPlans.forEach((plan) => {
        if (plan.privatePlan === false && !plan.tryOut) {
          standardPlansCount.push(plan);
        }
      });
    }
    const disabled = this.validateSubmit();
    this.props.handleButtonState(disabled);
    let { downloadFileModalState } = this.state;
    let prodDetailedInfo = this.props.prodDetailedInfo ?? {};
    let endpointsData = prodDetailedInfo?.endpointsData ?? [];

    return (
      <React.Fragment>
        {this.props.loader.loader ? <Loader /> : null}
        <div className={classes.fileDownloadParent}>
          <span
            className={
              endpointsData.length === 0 ? 'file_download_disabled' : 'file_download'
            }
          >
            <span onClick={this.openDownloadFile} className='file_download-text'>
              {i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_SWAGGER_FILE')}{' '}
              <img
                className='download-image-swagger'
                src={Images.downloadIcon}
                alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOWNLOAD_IMAGE_SWAGGER')}
              />
            </span>
          </span>
          {endpointsData.length > 0 && downloadFileModalState && (
            <ConfirmModal
              heading={i18next.t(
                'DYNAMIC_FORM.PRICING_PLAN.DOWNLOAD_THE_SWAGGER_FILE'
              )}
              submitLabel={i18next.t('DYNAMIC_FORM.LABEL.DOWNLOAD')}
              okBtnAction={() => {
                this.props.downloadFile(
                  this.state.currentFormat,
                  this.props?.prodBasicInfo?.productId
                );
              }}
              dropdownComponent={<Dropdown updateFormat={this.updateFormat} />}
              callback={this.closeDownloadFile}
              closeModal={classes.closeModal}
              modalHeaderStyles={classes.modalHeaderStyles}
              dialogPosition={'center'}
              currentFormat={this.state.currentFormat}
              dropdownWrapper={classes.dropdownWrapper}
            />
          )}
        </div>
        <div>
          <div className={classes.boxHeading}>
            <div className='heading-style -size-l highlight'>
              {i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN')}
            </div>
          </div>
          <div className='pricing_description'>
            {!this.props.disableFields &&
              (this.state.currentPlanSelection === 'Standard' ? (
                <div className='subheading-style'>
                  <p>
                    {i18next.t('DYNAMIC_FORM.PRICING_PLAN.CREATE_PRICING_PLAN_INFO')}
                  </p>
                  <p>
                    {i18next.t('DYNAMIC_FORM.PRICING_PLAN.CREATE_PRICING_PLAN_NOTE')}
                  </p>
                </div>
              ) : (
                <div className='subheading-style'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN.PLEASE_CREATE_PRIVATE_PLAN')}
                </div>
              ))}
          </div>
        </div>
        <div className='container__pricing__card'>
          {(() => {
            if (
              this.props.apiPlans !== undefined &&
              this.props.apiPlans.length > 0 &&
              this.state.currentPlanSelection === 'Standard'
            ) {
              return this.state.apiPlans.map(
                (plan) =>
                  !plan.tryOut && (
                    <div className='pricing_grid'>
                      <PricingPlanCard
                        plan={plan}
                        changePlanStatus={this.changePlanStatus}
                        deletePlan={this.deletePlan}
                        editPricingPlan={this.editPricingPlan}
                        {...this.props}
                      />
                    </div>
                  )
              );
            }
          })()}
          {(() => {
            if (
              this.props.apiPlans !== undefined &&
              standardPlansCount.length < 10 &&
              this.state.currentPlanSelection === 'Standard' &&
              this.props.prodBasicInfo?.status
            ) {
              return (
                <div className='card__grid'>
                  {!this.props.disableFields && (
                    <div
                      onClick={this.addPlan}
                      onMouseOver={this.onMouseOver}
                      onMouseOut={this.onMouseOut}
                      className='card__div'
                    >
                      <div className='image_div'>
                        <img
                          src={Images.addIcon}
                          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ADD_ICON')}
                        />
                      </div>
                      <p>{i18next.t('DYNAMIC_FORM.LABEL.ADD_PRICING_PLAN')}</p>
                    </div>
                  )}
                </div>
              );
            }
          })()}
          {(() => {
            if (
              this.props.apiPlans !== undefined &&
              this.props.apiPlans.length > 0 &&
              this.state.currentPlanSelection === 'Private'
            ) {
              return this.state.apiPvtPlans.length > 0
                ? this.state.apiPvtPlans.map((plan) => {
                    return (
                      <div key={plan.enabled}>
                        <PricingPlanCard
                          plan={plan}
                          changePlanStatus={this.changePlanStatus}
                          deletePlan={this.deletePlan}
                          editPlan={this.editPricingPlan}
                          {...this.props}
                        />
                      </div>
                    );
                  })
                : this.props.disableFields && (
                    <div className={classes.grid}>
                      <label>
                        {i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN.NO_PRIVATE_PLAN_IS_CURRENTLY_AVAILABLE'
                        )}
                      </label>
                    </div>
                  );
            }
          })()}
          {(() => {
            if (this.state.currentPlanSelection === 'Private') {
              return (
                <div className={classes.grid}>
                  {!this.props.disableFields && (
                    <div
                      onClick={this.addPlan}
                      onMouseOver={this.onMouseOver}
                      onMouseOut={this.onMouseOut}
                      className={classes.card}
                    >
                      <div className='img_div'>
                        <img
                          src={Images.addIcon}
                          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ADD_ICON')}
                        />
                      </div>
                      <div>
                        {i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD_PRIVATE_PLAN')}
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })()}
        </div>
        <div className='info_div'>
          <div className='info_div_wrapper'>
            <div>
              <img
                src={Images.infoBgBlueIcon}
                alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON')}
              />
            </div>
            <div className='pl__1'>
              <div className={classes.tryOutTextStyle}>
                {i18next.t('DYNAMIC_FORM.SHARED.TAXES_APPLICABLE')}{' '}
              </div>
            </div>
          </div>
        </div>

        {isShowPricingPlanNotification(
          this?.props?.prodBasicInfo?.status?.statusId,
          this?.props?.apiPlans
        ) && (
          <div className='api-notification-container'>
            <PricingNotification image={Images.documentView}>
              <p className='pricing-notification-info'>
                {i18next.t(
                  'DYNAMIC_FORM.PRICING_PLAN.SUBMIT_DRAFT_PRICING_PLAN_FOR_APPROVAL'
                )}
              </p>
            </PricingNotification>
          </div>
        )}
        {!IS_MKOSINT_TENANT && getRegionCode() === regionDetails[0].regionCode && (
          <div>
            <div className={classes.inputLabel}>
              <TextInput
                displayName={i18next.t('DYNAMIC_FORM.SHARED.SAC_HSN_CODE')}
                maxLength='6'
                placeHolder={i18next.t('DYNAMIC_FORM.SHARED.ENTER_SAC_HSN_CODE')}
                value={this.state.productSacCode}
                name='productSacCode'
                handleChange={(event) => this.changeSACCodeInput(event)}
                disabled={
                  this.props.editableDetails.isConsumer || this.props.disableFields
                }
                validation={{
                  rule: {
                    required: {
                      isRequired:
                        getRegionCode() !== regionDetails[0].regionCode
                          ? false
                          : true
                    },
                    length: {
                      max: 6,
                      errorCode: 'MS105'
                    }
                  }
                }}
                error={this.state.prodSacCodeErr}
              />
            </div>
          </div>
        )}

        {(() => {
          if (this.state.currentPlanSelection === 'Standard') {
            return (
              <React.Fragment>
                <div className=' input__type'>
                  <input
                    type='checkbox'
                    checked={this.state.allowTryOut}
                    onChange={(event) => this.handleTryOutChange(event)}
                    disabled={this.props.disableFields}
                    color='primary'
                  />
                  <div className='pl__input'>
                    {i18next.t(
                      'DYNAMIC_FORM.PRICING_PLAN.ALLOW_USER_TO_TRYOUT_MY_API'
                    )}
                  </div>
                </div>
                <div className='info_div'>
                  <div className='info_div_wrapper'>
                    <div>
                      <img
                        src={Images.infoBgBlueIcon}
                        alt={i18next.t(
                          'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                        )}
                      />
                    </div>
                    <div className='pl__1'>
                      <p>
                        {i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN.THE_BUYER_CHOOSES_TRYOUT_OPTION'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          }
        })()}
        <div>
          {this.state.openDialog ? (
            <PricingPlanDialog
              onClose={this.closeDialog}
              open={this.state.openDialog}
              {...this.props}
              editPlanDetails={this.state.editPlan}
              isEditPlan={this.state.isEditPlan}
              createPlan={this.createPricingPlan}
              updatePlan={this.updatePricingPlan}
            ></PricingPlanDialog>
          ) : null}
          {this.state.openPrivatePricingDialog ? (
            <PrivatePlanDialog
              onClose={this.closeDialog}
              open={this.state.openPrivatePricingDialog}
              {...this.props}
              editPlanDetails={this.state.editPlan}
              isEditPlan={this.state.isEditPlan}
              createPlan={this.createPricingPlan}
              updatePlan={this.updatePricingPlan}
            ></PrivatePlanDialog>
          ) : null}
          {!this.props.loader.loader && this.state.snackBarFlag ? (
            <Snackbar
              severity={this.state.snackBarSeverity}
              message={this.state.snackBarMsg}
              changeState={this.changeState}
            />
          ) : null}
        </div>
        {(() => {
          if (this.state.openConfirmModal === true) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={this.state?.confirmLabel?.primaryLabel ? false : true}
                handleConfirm={() => {
                  this.okBtnAction();
                }}
                handleCancel={() => {
                  this.handleOpenConfirmModal();
                }}
                primaryLabel={
                  this.state?.confirmLabel?.primaryLabel
                    ? this.state?.confirmLabel?.primaryLabel
                    : i18next.t('DYNAMIC_FORM.LABEL.SUBMIT')
                }
                secondaryLabel={
                  this.state?.confirmLabel?.secondaryLabel
                    ? this.state?.confirmLabel?.secondaryLabel
                    : i18next.t('DYNAMIC_FORM.LABEL.CANCEL')
                }
              />
            );
          }
          if (this.state.openInfoModal === true) {
            return (
              <InfoModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                okBtnAction={this.okBtnAction}
                onClose={this.closeInfoPopup}
              ></InfoModal>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Pricing);
