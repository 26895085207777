const initialState = {
  OnBoardingFormData: []
};

const AdminOnboardingFormReducer = (state = initialState, action) => {
  if (action.type === 'GET_OnBoarding_Form_DETAILS') {
    return Object.assign({}, state, { OnBoardingFormData: action.payload });
  }

  return state;
};

export default AdminOnboardingFormReducer;
