import isEmpty from './isEmpty';
import isURL, { isLocalhostIP } from './isURL';
import isDataType from './isDataType';
import customValidator from './customValidator';

const validator = {
  isEmpty,
  isURL,
  isLocalhostIP,
  isDataType,
  customValidator
};

export default validator;
