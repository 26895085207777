import isEmpty from '../../validator/isEmpty';
import { contentTypeConstants, parameterConstants } from '../../data/appConstants';

export const formatEndpointDetails = (productDetails) => {
  const isValidRequestExample = [],
    isValidResponseExample = [];
  const groupDataResponse = productDetails?.endpointGroups;
  const groupData = [];
  if (groupDataResponse?.length > 0) {
    groupDataResponse.map((value) => {
      if (isEmpty(value.groupName)) {
        return;
      }
      groupData.push({
        name: value.groupName
      });
      return true;
    });
  }
  const endpointsResponse = productDetails?.endPointDetails;
  const endpointsData = [];
  if (endpointsResponse?.length > 0) {
    endpointsResponse.forEach((value, index) => {
      const headers = [];
      if (isEmpty(value.name)) {
        return;
      }
      value.headers?.map((header) => {
        headers.push({
          parameter: parameterConstants.headerConst,
          dataType: header.datatype !== undefined ? camelize(header.datatype) : '',
          name: header.name,
          description:
            header.description && header.description !== 'null'
              ? header.description
              : '',
          required: header.isRequired !== undefined ? header.isRequired : ''
        });
        return true;
      });

      const parameters = [];
      value.parameters?.map((parameter) => {
        parameters.push({
          parameter: parameterConstants.queryConst,
          dataType:
            parameter.datatype !== undefined ? camelize(parameter.datatype) : '',
          name: parameter.name,
          description:
            parameter.description && parameter.description !== 'null'
              ? parameter.description
              : '',
          required: parameter.isRequired !== undefined ? parameter.isRequired : ''
        });
        return true;
      });
      const formData = [];
      value.formdata?.map((form) => {
        formData.push({
          parameter: parameterConstants.formDataConst,
          dataType: camelize(form.datatype),
          name: form.name,
          description:
            form.description && form.description !== 'null' ? form.description : '',
          required: form.isRequired
        });
        return true;
      });
      const formUrlEncoded = [];
      value.formurlEncoded?.map((form) => {
        formUrlEncoded.push({
          parameter: parameterConstants.formUrlEncodedConst,
          dataType: camelize(form.datatype),
          name: form.name,
          description:
            form.description && form.description !== 'null' ? form.description : '',
          required: form.isRequired
        });
        return true;
      });
      const body = [];
      value.body?.map((bodyData) => {
        body.push({
          parameter: parameterConstants.rawBodyConst,
          dataType: camelize(bodyData.datatype),
          name: bodyData.name,
          description:
            bodyData.description && bodyData.description !== 'null'
              ? bodyData.description
              : '',
          required: bodyData.isRequired
        });
        return true;
      });
      const requestSample = [];
      value.requestSample?.map((requestData) => {
        if (requestData.request) {
          isValidRequestExample.push(
            validateExample(value.contentType[0], requestData.request)
          );
        }
        requestSample.push({
          request: requestData.request
        });
        return true;
      });
      const responseSample = [];
      value.responseSample?.map((responseData) => {
        if (responseData.response) {
          isValidResponseExample.push(
            validateExample(responseData.contentType, responseData.response)
          );
        }
        responseSample.push({
          contentType: responseData.contentType,
          response: responseData.response,
          responseStatusCode: responseData.responseStatusCode
        });
        return true;
      });
      const pathParameterData = [];
      value.pathParameter?.map((pathData) => {
        pathParameterData.push({
          Name: pathData.name !== undefined ? pathData.name : '',
          DataType:
            pathData.datatype !== undefined ? camelize(pathData.datatype) : '',
          Description:
            pathData.description !== undefined && pathData.description !== 'null'
              ? pathData.description
              : '',
          Required: pathData.isRequired !== undefined ? pathData.isRequired : ''
        });
        return true;
      });
      endpointsData.push({
        header: headers,
        method: value.method !== undefined ? value.method : '',
        name: value.name !== undefined ? value.name : 'endpoint' + (index + 1),
        query: parameters,
        formData: formData,
        formUrlEncoded: formUrlEncoded,
        body: body,
        requestSample: requestSample,
        responseSample: responseSample,
        group: value.group !== undefined ? value.group : [],
        description: value.description !== undefined ? value.description : '',
        relativePath: value.path !== undefined ? value.path : '',
        chargeableStatusCode: value.chargeableStatusCode,
        paramCount: headers.length + parameters.length,
        relativePathData: pathParameterData !== undefined ? pathParameterData : '',
        contentType: value.contentType
      });
    });
  }
  const prodDetInfo = {
    groupData: groupData,
    endpointsData: endpointsData,
    isValidRequestExample: isValidRequestExample,
    isValidResponseExample: isValidResponseExample
  };
  return prodDetInfo;
};

const validateExample = (contentType, value) => {
  switch (contentType) {
    case contentTypeConstants.applicationJson:
      return isValidJSONString(value);
    case contentTypeConstants.applicationXml:
      return XMLValidator.validate(value, {
        allowBooleanAttributes: true
      }) === true
        ? true
        : false;
    default:
      return false;
  }
};

const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

const camelize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
