import React from 'react';
import InlineEditTable from './InlineEditTable';
import { DialogBox } from '../../../../msreact-kit/packages/html-components';
import './index.scss';
import i18next from 'i18next';

export default function EndpointsDialog(props) {
  const handleClose = () => {
    props.callback();
  };

  const paramData = (data) => {
    props.paramData(data, props.index);
  };

  return (
    <DialogBox
      cssName='confirmation__modal__revamped configure-parameter'
      handleCancel={handleClose}
      title={i18next.t('DYNAMIC_FORM.ADD_API.CONFIGURE_PARAMETER')}
      primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.SUBMIT')}
    >
      <div className='inline-edit-table'>
        <InlineEditTable
          paramData={paramData}
          callback={handleClose}
          isEdit={props.isEdit}
          paramDataValues={props.paramDataValues}
          changeEditValue={props.changeEditValue}
          endpointData={props.endpointData}
          headerData={props.headerData}
          queryData={props.queryData}
          index={props.index}
          paramCount={props.paramCount}
          deleteParam={props.deleteParam}
          submitData={props.submitData}
          snackBar={props.snackBar}
          contentTypeData={props.contentTypeData}
          paramDataTypes={props.paramDataTypes}
          parameterOptions={props.parameterOptions}
          updateFormUrlEncoded={props.updateFormUrlEncoded}
          updateFormData={props.updateFormData}
          updateBody={props.updateBody}
          updateRequestSample={props.updateRequestSample}
          updateResponseSample={props.updateResponseSample}
          updateContentType={props.updateContentType}
          updateEndpointsData={props.updateEndpointsData}
          editableAttributes={props.editableAttributes}
          disableFields={props.disableFields}
        />
      </div>
    </DialogBox>
  );
}
