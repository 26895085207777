const initialState = {
  throttlingPolicies: [],
  saveThrottlingPolicyRes: {},
  saveThrottlingPolicyErr: null,
  isThrottlePolicyUnique: true,
  isValidCondGrpName: true,
  conditionGroup: {},
  conditionGroupError: {},
  throttleConditionGroups: {},
  throttlePoliyUserApiList: [],
  throttlePoliyIdApiList: [],
  saveThrottlingPolicyApiRes: {},
  saveThrottlingPolicyApiErr: null,
  productThrottlePolicy: {},
  swapThrottlePolicyRes: {},
  swapThrottlePolicyErr: null,
  isTrottlePolicyConsumed: false,
  deletePolicyError: {},
  deleteConditionGrpError: {}
};

const ApiThrottlingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'THROTTLING_POLICIES':
      return Object.assign({}, state, { throttlingPolicies: action.payload });
    case 'SAVE_THROTTLING_POLICY':
      return Object.assign({}, state, {
        saveThrottlingPolicyRes: action.payload,
        saveThrottlingPolicyErr: null
      });
    case 'SAVE_THROTTLING_POLICY_ERR':
      return Object.assign({}, state, {
        saveThrottlingPolicyRes: {},
        saveThrottlingPolicyErr: action.error
      });
    case 'THROTTLE_POLICY_UNIQUE':
      return Object.assign({}, state, { isThrottlePolicyUnique: action.value });
    case 'CONDITION_GROUP_NAME_UNIQUE':
      return Object.assign({}, state, { isValidCondGrpName: action.value });
    case 'CREATE_THROTTLE_CONDITION_GROUP':
      return Object.assign({}, state, {
        conditionGroup: action.payload,
        conditionGroupError: {}
      });
    case 'THROTTLE_CONDITION_GROUP_ERROR':
      return Object.assign({}, state, { conditionGroupError: action.error });
    case 'THROTTLE_POLICY_DETAILS':
      return Object.assign({}, state, { throttlePolicyDetails: action.payload });
    case 'THROTTLLE_POLICY_CONSUMPTION':
      return Object.assign({}, state, { isTrottlePolicyConsumed: action.value });
    case 'THROTTLE_POLICY_USER_API_LIST':
      return Object.assign({}, state, { throttlePoliyUserApiList: action.payload });
    case 'THROTTLE_POLICY_ID_API_LIST':
      return Object.assign({}, state, { throttlePoliyIdApiList: action.payload });
    case 'SAVE_THROTTLING_POLICY_API':
      return Object.assign({}, state, {
        saveThrottlingPolicyApiRes: action.payload,
        saveThrottlingPolicyApiErr: null
      });
    case 'SAVE_THROTTLING_POLICY_API_ERR':
      return Object.assign({}, state, {
        saveThrottlingPolicyApiRes: {},
        saveThrottlingPolicyApiErr: action.error
      });
    case 'DELETE_API_THROTTLING_POLICY':
      return Object.assign({}, state, {
        deleteThrottlingPolicyApiRes: action.payload
      });
    case 'DELETE_THROTTLE_POLICY_ERROR':
      return Object.assign({}, state, { deletePolicyError: action.error });
    case 'DELETE_THROTTLE_CONDITION_GROUP_ERROR':
      return Object.assign({}, state, { deleteConditionGrpError: action.error });
    case 'PRODUCT_THROTTLE_POLICY_ID':
      return Object.assign({}, state, { productThrottlePolicy: action.payload });
    case 'SWAP_THROTTLING_POLICY_API':
      return Object.assign({}, state, {
        swapThrottlePolicyRes: action.payload,
        swapThrottlePolicyErr: null
      });
    case 'SWAP_THROTTLING_POLICY_API_ERR':
      return Object.assign({}, state, {
        swapThrottlePolicyRes: {},
        swapThrottlePolicyErr: action.error
      });
    default:
      return state;
  }
};

export default ApiThrottlingReducer;
