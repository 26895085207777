import axios from 'axios';
import { GET_ONBOARDING_FORM, GET_CATEGORY } from '../actions/configuration';

export function getAllCategoryList() {
  return (dispatch) => {
    axios
      .get(`${GET_ONBOARDING_FORM}?all=all`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'CATEGORY_ALL_LIST',
          payload: response?.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
  };
}

export function getCategories(statusId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: GET_CATEGORY + `?productCount=true&status=${statusId}`,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'CATEGORY_LIST',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}
