import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import {
  Dropdown,
  TextArea,
  TextInput
} from '../../msreact-kit/packages/html-components';
import { FieldConstants } from '../../shared/constants/field-constants';
import DynamicFieldConstants from '../../shared/constants/dynamic-field-constants';
import { sortByDisplayOrder } from '../../utils';
import './index.scss';

const Quotations = ({ title, description, onBoardingFormData }) => {
  const [attributes, setAttributes] = useState(null);

  useEffect(() => {
    if (onBoardingFormData.steps) {
      const findValIndex = onBoardingFormData?.steps[0]['attributes'].findIndex(
        (item) => item.name === FieldConstants.subcategory
      );
      const categorySelected =
        onBoardingFormData.steps[0]['attributes'][findValIndex]?.value;
      if (categorySelected?.id) {
        const selectedQuotation = onBoardingFormData.quotations.find(
          (item) => item.productSubCategoryId === categorySelected?.id
        );
        if (selectedQuotation)
          setAttributes(sortByDisplayOrder(selectedQuotation?.attributes));
        else
          setAttributes(
            sortByDisplayOrder(onBoardingFormData.quotations[0]?.attributes)
          );
      } else {
        setAttributes(
          sortByDisplayOrder(onBoardingFormData.quotations[0]?.attributes)
        );
      }
    }
  }, []);

  const handleAttributeType = (type, data) => {
    switch (type) {
      case DynamicFieldConstants.TEXT:
      case DynamicFieldConstants.NUMBER:
        return (
          <TextInput
            placeHolder={data?.placeHolder}
            displayName={data?.displayName}
            name={data?.name}
            disabled={true}
            value={data?.placeHolder}
          />
        );
      case DynamicFieldConstants.SINGLE_SELECT:
        return (
          <Dropdown
            displayName={data?.displayName}
            options={data?.options?.map(({ id, label }) => ({
              label: label,
              id: id,
              name: label
            }))}
            placeHolder={data?.placeHolder}
            name={data?.name}
            disabled={true}
            value={data?.placeHolder}
          />
        );
      case DynamicFieldConstants.TEXT_AREA:
        return (
          <div className='home-wrapper__form__input'>
            <TextArea
              displayName={data?.displayName}
              placeHolder={data?.placeHolder}
              disabled={true}
              value={data?.placeHolder}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='quotations'>
      <span className='quotations__header-label'>{title}</span>

      <div className='quotations__container'>
        <div className='quotations__name-div'>
          <div className={'context__tab-background-font-color context__box-style'}>
            <p>{i18next.t('QUOTATION.REQUEST_FOR_QUOTATIONS')}</p>
          </div>
        </div>

        <div className='quotations__whole-div__icon-wrapper'>
          <i className='a-icon boschicon-bosch-ic-alert-info' />
          <p className='quotations__plan-info'>
            <span className='quotations__plan-info-heading'>
              {i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING_PLAN')}
            </span>
            - {description}
          </p>
        </div>
        <div className='quotations-home-wrapper__form__wrapper'>
          {attributes?.map((item) => (
            <div
              className={`${
                item.width == '100'
                  ? 'home-wrapper__form__document'
                  : 'home-wrapper__form__input'
              }`}
              key={item.id}
            >
              {handleAttributeType(item?.attributeType, item)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Quotations;
