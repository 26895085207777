import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import loading from '../../actions/LoaderAction';
import Loader from '../../components/Shared/Utils/Loader';
import APIThrottlingPolicies from '../../components/api/APIThrottling/APIThrottlingPolicies';
import AddThrottlingPolicy from '../../components/api/APIThrottling/AddThrottlingPolicy';
import {
  getThrottlingPolicies,
  saveThrottlingPolicy,
  updateThrottlingPolicy,
  validateThrottlingPolicyName,
  validateConditionGroupName,
  createThrottleConditionGroup,
  getThrottlePolicyDetails,
  resetThrottleConditionGroups,
  getApiListForUser,
  getApiListForThrottlingPolicy,
  addApiToThrottlingPolicy,
  deleteApiFromThrottlingPolicy,
  resetApiListForThrottlingPolicy,
  resetConditionGroupError,
  updateThrottleConditionGroup,
  checkForTrottlePolicyConsmuption,
  deleteThrottlePolicy,
  deleteThrottleConditionGroup,
  resetThrottlePolicyConsumption
} from '../../actions/api/ApiThrottlingPolicyAction';
import getAllMasterData, {
  getPricingPlanMasterData
} from '../../actions/MasterDataAction';
import navigationOptions from '../../data/navigationOptionsSeller';
import IdConstants from '../../data/idConstants';

class ApiThrottlingContainer extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        self.props.loading(true);
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.props.loading(false);
        return response;
      },
      function (error) {
        self.props.loading(false);
        return Promise.reject(error);
      }
    );
    this.state = {
      iplist: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let ipListFromResponse = [];
    this.props.getThrottlingPolicies().then((result) => {
      result?.map((policy) => {
        this.props
          .getThrottlePolicyDetails(policy.throttlePolicyId, 'true')
          .then((response) => {
            response?.conditionGroups?.map((condition) => {
              ipListFromResponse.push({
                policyId: policy.throttlePolicyId,
                ip: condition.conditions[0].paramValue
              });
              return true;
            });
          });
        return true;
      });
      this.setState({ iplist: ipListFromResponse });
    });
    const apiCategoryId = IdConstants.api.categoryId;
    this.props.getAllMasterData(apiCategoryId);
    this.props.getPricingPlanMasterData(apiCategoryId);
  }

  addIpToList = (policyId, ip) => {
    let list = {};
    list.policyId = policyId;
    list.ip = ip;
    this.setState({ iplist: [...this.state.iplist, list] });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.loader.loader && <Loader />}
        {this.props.location &&
        this.props.location.pathname ===
          navigationOptions
            .find(
              (item) =>
                item.sidebarKey === IdConstants.sidebarConstants.myProducts.key
            )
            .subMenus.find(
              (subItem) =>
                subItem.sidebarKey === IdConstants.sidebarConstants.apiProduct.key
            )
            .subItemlevel2.find(
              (subItemlevel2) =>
                subItemlevel2.sidebarKey ===
                IdConstants.sidebarConstants.throttlePolicy.key
            ).url ? (
          <APIThrottlingPolicies {...this.props} />
        ) : (
          <AddThrottlingPolicy
            {...this.props}
            iplist={this.state.iplist}
            addIpToList={(policyId, ip) => this.addIpToList(policyId, ip)}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loading,
      getThrottlingPolicies,
      saveThrottlingPolicy,
      updateThrottlingPolicy,
      validateThrottlingPolicyName,
      validateConditionGroupName,
      createThrottleConditionGroup,
      getThrottlePolicyDetails,
      getAllMasterData,
      resetThrottleConditionGroups,
      getApiListForUser,
      getApiListForThrottlingPolicy,
      addApiToThrottlingPolicy,
      deleteApiFromThrottlingPolicy,
      resetApiListForThrottlingPolicy,
      resetConditionGroupError,
      updateThrottleConditionGroup,
      checkForTrottlePolicyConsmuption,
      deleteThrottlePolicy,
      deleteThrottleConditionGroup,
      resetThrottlePolicyConsumption,
      getPricingPlanMasterData
    },
    dispatch
  );
}

const mapStateToProps = (redux_state) => ({
  loader: redux_state.LoaderReducer,
  throttlingPolicies: redux_state.ApiThrottlingReducer.throttlingPolicies,
  saveThrottlingPolicyRes: redux_state.ApiThrottlingReducer.saveThrottlingPolicyRes,
  saveThrottlingPolicyErr: redux_state.ApiThrottlingReducer.saveThrottlingPolicyErr,
  isThrottlePolicyUnique: redux_state.ApiThrottlingReducer.isThrottlePolicyUnique,
  isValidCondGrpName: redux_state.ApiThrottlingReducer.isValidCondGrpName,
  conditionGroup: redux_state.ApiThrottlingReducer.conditionGroup,
  conditionGroupError: redux_state.ApiThrottlingReducer.conditionGroupError,
  throttlePolicyDetails: redux_state.ApiThrottlingReducer.throttlePolicyDetails,
  isTrottlePolicyConsumed: redux_state.ApiThrottlingReducer.isTrottlePolicyConsumed,
  allMasterData: redux_state.MasterDataReducer.allMasterData,
  pricingPlanMasterData: redux_state.MasterDataReducer.pricingPlanMasterData,
  throttlePoliyUserApiList:
    redux_state.ApiThrottlingReducer.throttlePoliyUserApiList,
  throttlePoliyIdApiList: redux_state.ApiThrottlingReducer.throttlePoliyIdApiList,
  saveThrottlingPolicyApiRes:
    redux_state.ApiThrottlingReducer.saveThrottlingPolicyApiRes,
  saveThrottlingPolicyApiErr:
    redux_state.ApiThrottlingReducer.saveThrottlingPolicyApiErr,
  deleteThrottlingPolicyApiRes:
    redux_state.ApiThrottlingReducer.deleteThrottlingPolicyApiRes,
  deletePolicyError: redux_state.ApiThrottlingReducer.deletePolicyError,
  deleteConditionGrpError: redux_state.ApiThrottlingReducer.deleteConditionGrpError
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiThrottlingContainer);
