import React, { useRef, useEffect } from 'react';
import i18next from 'i18next';
import CheckBox from '../check-box';
import Images from '../../../assets/img';
import './index.scss';

const Filter = ({ data, handleFilter, cssName }) => {
  const handleGrouping = () => {
    const element = document.getElementById('add');
    element.classList.toggle('-visible');
  };
  const handleChange = (event) => {
    handleFilter(event);
  };

  const handleClickOutside = () => {
    const element = document.getElementById('add');
    element.classList.remove('-visible');
  };

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

    return ref;
  };

  const wrapperRef = useOutsideClick(handleClickOutside);

  return (
    <div ref={wrapperRef} className='context-menu'>
      <div className='box'>
        <div
          className='context-menu__box -size-m cursor-pointer'
          onClick={() => handleGrouping()}
        >
          <img
            src={Images.filter}
            data-testid='name'
            className='filter__img'
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.FILTER')}
          />
        </div>
        <nav
          id='add'
          className='o-context-menu'
          aria-label='Context Menu Navigation'
          aria-hidden='false'
        >
          <div className='m-popover  m-popover__left -without-arrow'>
            <div className='a-box -floating'>
              <div className='m-popover__content'>
                <ul
                  className={`o-context-menu__menuItems ${cssName}`}
                  role='menubar'
                >
                  {data?.length > 0 &&
                    data?.map((item) => (
                      <li key={item.id} className='a-menu-item' role='none'>
                        <a
                          role='menuitem'
                          className='a-menu-item__link'
                          aria-disabled='false'
                          aria-controls='group-id'
                        >
                          <CheckBox
                            className='a-menu-item__label'
                            handleChange={handleChange}
                            displayName={
                              item.count > 0
                                ? `${item.label} (${item.count})`
                                : item.label
                            }
                            id={item.id}
                            name={item.id}
                            checked={item.isSelected}
                            disabled={item?.disabled}
                          />
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Filter;
