import React from 'react';
import './index.scss';
import { getRegionCode } from '../../../utils/get-region-details';
import { regionDetails } from '../../../data/appConstants';

const Link = ({ type, isUnderlined, ...props }) => {
  let cssName = props.className,
    textStyle = '';
  if (type === 'secondary') {
    cssName = ' link-secondary';
    if (getRegionCode() === regionDetails[0].regionCode) {
      textStyle = 'content-align';
    }
  }

  if (!isUnderlined) {
    cssName += ' no-underline';
  }

  return (
    <a
      className={cssName}
      aria-label={props.label}
      href={props.to}
      target={props.target}
      onClick={props.onClick}
    >
      <span className={textStyle}>{props.label}</span>
      <span>
        <i
          className='a-icon ui-ic-nosafe-lr-right-small'
          title='nosafe-lr-right-small'
        ></i>
      </span>
    </a>
  );
};

export default Link;
