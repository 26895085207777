import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { NavigationButtons } from '../../packages/html-components';
import Images from '../../../assets/img';
import './index.scss';

const SideBar = ({
  label,
  nextBtnLabel,
  prevBtnLabel,
  children,
  handleSideBar,
  nextStepDisabled,
  customClass,
  childrenClass,
  ...props
}) => {
  return (
    <div className='side-bar-background'>
      <div className={`side-bar-container ${customClass}`}>
        <div className={`side-bar ${customClass && customClass + '_body'}`}>
          <div className={`side-bar-header ${customClass}`}>
            <span className='side-bar-header__label'>{label}</span>
            <img
              className='side-bar-header__close-icon '
              src={Images.closeIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.CLOSE_SIDE_BAR')}
              width={16}
              height={16}
              onClick={handleSideBar}
            />
            <hr className='a-divider side-bar-header-divider' />
          </div>
          <div className={`side-bar-children-components ${childrenClass}`}>
            {children}
          </div>
          <div className={`side-bar-footer ${customClass}`}>
            <NavigationButtons
              prevStepLabel={prevBtnLabel}
              nextStepLabel={nextBtnLabel}
              nextStepDisabled={nextStepDisabled}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  handleSideBar: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  nextBtnLabel: PropTypes.string,
  prevBtnLabel: PropTypes.string,
  isShowPrevStepBtn: PropTypes.bool
};

SideBar.defaultProps = {
  label: '',
  nextBtnLabel: 'Next',
  prevBtnLabel: 'Back',
  isShowPrevStepBtn: false
};

export default SideBar;
