import React, { useState, useRef, useEffect } from 'react';
import { PrimaryButton } from '../../../msreact-kit/packages/html-components';
import './index.scss';

export default function MenuDropdown(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownWrapperRef = useRef(null);
  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownWrapperRef.current &&
        !dropdownWrapperRef.current.contains(event.target)
      ) {
        onClickOutside();
      }
    };
    const onClickOutside = () => {
      setAnchorEl(false);
    };
    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event);
    });
    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event);
      });
    };
  }, []);

  return (
    <React.Fragment>
      <PrimaryButton
        label={props.buttonLabel}
        onClick={handleClick}
        disabled={props.disabled}
      >
        {props.childrenData}
      </PrimaryButton>
      {anchorEl && (
        <div className='frontend-kit-example_context-menu' ref={dropdownWrapperRef}>
          {props.activeId === props.id && (
            <nav
              id='add'
              className='o-context-menu -visible'
              aria-label='Context Menu Navigation undefined'
              aria-hidden='false'
            >
              <div className={`m-popover -without-arrow ${props.cssName}`}>
                <div className='a-box -floating'>
                  <div className='m-popover__content card-style'>
                    <ul className='o-context-menu__menuItems' role='menubar'>
                      {props?.dropDownData?.map((item) => {
                        return (
                          <li className='a-menu-item' role='none' key={item.id}>
                            <a
                              role='menuitem'
                              className='a-menu-item__link'
                              aria-disabled='false'
                              aria-controls='group-id undefined'
                              onClick={() => {
                                item.handler();
                              }}
                            >
                              <span className='a-menu-item__label'>
                                {item.label}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
