import axios from 'axios';
import * as Constants from './configuration';

export function checkForAccountDeletion() {
  return (dispatch) =>
    axios
      .get(Constants.DELETE_ACTION.ACCOUNT_DELETION_CHECK, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'ACCOUNT_DELETION_CHECK',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'ACCOUNT_DELETION_CHECK',
            payload: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function requestForAccountDeletion() {
  return (dispatch) =>
    axios
      .post(Constants.DELETE_ACTION.REQUEST_FOR_ACCOUT_DELETION, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch({
          type: 'ACCOUNT_DELETION_REQUEST',
          payload: response.status
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'ACCOUNT_DELETION_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function resetAccountDeletion() {
  return {
    type: 'RESET_ACCOUNT_DELETION',
    payload: {}
  };
}

export const checkForAccountDeregistration = () => {
  return axios.get(Constants.DELETE_ACTION.ACCOUNT_DELETION_CHECK, {
    headers: {
      'Content-Type': 'application/json'
    },
    data: {}
  });
};

export const deleteSellerAccountFromMS = () => {
  return axios.delete(Constants.DELETE_ACTION.DELETE_MS_ACCOUNT, {
    headers: {
      'content-type': 'application/json'
    }
  });
};
