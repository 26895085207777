import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import FileUpload from '../file-upload';
import { SecondaryButton } from '../../button';
import DocNotes from '../doc-notes';
import Errors from '../../errors';
import fileNameWithEllipsis from '../../../../utils/file-name-with-ellipsis';
import Images from '../../../../assets/img';

import './index.scss';

const TermsAndConditionDoc = ({
  displayName,
  placeHolder,
  value,
  handleChange,
  handleDeleteFile,
  customClass,
  handlePreviewElement,
  error,
  disabled,
  isView,
  ...props
}) => {
  const handleDelete = (event, file) => {
    event.stopPropagation();
    handleDeleteFile(file);
  };

  const files =
    value?.length > 0 &&
    value.flat().map((file) => (
      <div onClick={() => handlePreviewElement(file)} key={file.documentName}>
        <div key={file?.documentName} className='upload__list_file'>
          <img
            src={Images.documentDoc}
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOCUMENT')}
            className='upload__list_doc'
          />
          <label className='-size-m'>
            {fileNameWithEllipsis(file?.documentName)}
          </label>
        </div>
      </div>
    ));

  return (
    <React.Fragment>
      <h4 className='-size-l mb12'>{displayName}</h4>
      <div className={`term-and-condition-doc ${customClass}`}>
        <div className='term-and-condition-doc__container'>
          <FileUpload
            className={`upload__user ${disabled && 'ms-disabled'} ${
              files?.length > 0 && 'term-and-condition-doc__bg-gray'
            }`}
            handleChange={handleChange}
            value={value}
            disabled={disabled}
            {...props}
          >
            {value?.length > 0 ? (
              files
            ) : (
              <>
                <label className='-size-m'>
                  {placeHolder?.length > 0
                    ? placeHolder
                    : i18next.t('DYNAMIC_FORM.LABEL.DROP_FILES_HERE_TO_IMPORT_THEM')}
                </label>
                <div className='pdu__divider'>
                  <div className='a-divider pdu__width' />
                  <label className='pdu__label_space'>
                    {i18next.t('DYNAMIC_FORM.LABEL.OR')}
                  </label>
                  <div className='a-divider pdu__width' />
                </div>
                <div className={disabled && 'ms-disabled-pointer'}>
                  <SecondaryButton
                    label={
                      props?.validation?.maxFiles > 1
                        ? i18next.t('DYNAMIC_FORM.LABEL.SELECT_FILES')
                        : i18next.t('DYNAMIC_FORM.LABEL.SELECT_FILE')
                    }
                    disabled={disabled}
                  />
                </div>
              </>
            )}
          </FileUpload>
          <DocNotes {...props.validation} />
        </div>

        {error && <Errors errorMessaage={error} />}

        {!isView && files?.length > 0 && (
          <img
            src={Images.deleteIcon}
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE')}
            onClick={(event) => handleDelete(event, value[0])}
            className='upload__list_del'
            data-testid='upload__list_del'
          />
        )}
      </div>
    </React.Fragment>
  );
};

TermsAndConditionDoc.propTypes = {
  displayName: PropTypes.string,
  placeHolder: PropTypes.string
};

TermsAndConditionDoc.defaultProps = {
  displayName: '',
  placeHolder: i18next.t('DYNAMIC_FORM.LABEL.DROP_FILES_HERE_TO_IMPORT_THEM')
};

export default TermsAndConditionDoc;
