import idConstants from '../../data/idConstants';

export const handleCheckUploadType = (documentName) => {
  const extension = documentName?.split('.').pop();

  if (!extension) return false;

  const videoExtension = ['mp4', 'avi'];
  return videoExtension.includes(extension);
};

export const downloadFile = (url, fileName) => {
  const response = new Promise((resolve) => {
    fetch(url).then((response) =>
      response.blob().then((blobResult) => {
        const filename = fileName
          ? fileName
          : url.substring(url.lastIndexOf('/') + 1).split('?')[0];
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blobResult);
        a.setAttribute('download', filename);
        a.click();
        resolve(true);
      })
    );
  });
  return response;
};

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

export const handleDownload = (uploadedFile) => {
  if (uploadedFile) {
    const fileUrl = URL.createObjectURL(uploadedFile);
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = uploadedFile.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileUrl);
  }
};

export const isShowPricingPlanNotification = (
  status,
  plans,
  statusId = idConstants.drafted.statusId
) => {
  if (
    plans?.length > 0 &&
    status !== idConstants.drafted.statusId &&
    status !== idConstants.rejected.statusId
  ) {
    const isDraftPlanPresent = plans.find(
      (plan) => plan?.status?.statusId === statusId
    );
    if (isDraftPlanPresent) {
      return true;
    }
  }
  return false;
};

export const getDraftedPricingPlan = (pricingPlans) => {
  return pricingPlans
    ?.filter((item) => item?.status?.description === idConstants.drafted.description)
    .map((item) => item.planId);
};

export const canPricingPlanBeEdited = (plan) => {
  return [
    idConstants?.drafted?.statusId,
    idConstants?.submitted?.statusId,
    idConstants?.rejected?.statusId
  ]?.includes(plan?.status?.statusId);
};

export const handlePricingPlanDeletion = (plan, statusId, pricingPlans) => {
  if (plan?.skuVariantId) {
    return 'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.DO_NOT_HAVE_PERMISSION_TO_DELETE_PRICING_PLAN';
  }

  if (statusId !== idConstants.drafted.statusId) {
    const filteredData = pricingPlans?.filter((plan) =>
      statusId === idConstants.submitted.statusId ||
      statusId === idConstants.rejected.statusId
        ? plan.status.description === idConstants.submitted.description ||
          plan.status.description === idConstants.published.description
        : plan.status.description === idConstants.published.description
    );

    if (filteredData?.length === 1 && plan?.planId === filteredData[0]?.planId) {
      return 'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.AT_LEAST_ONE_PRICING_PLAN_REQUIRED';
    }
  }
};

export const getUserDetailsData = (companyId, itemId, categoryId) => {
  return {
    userDetails: {
      typeOfUser: idConstants.typeOfUsers.b2b,
      b2bIdentifier: companyId ?? '',
      userId: localStorage.getItem('userId')
    },
    mappingInfo: {
      itemId: itemId
    },
    classifiers: {
      categoryId: categoryId
    }
  };
};
