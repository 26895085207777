const initialState = {
  quotationList: {},
  quotationForm: {},
  quotationDocuments: [],
  sellerQuotationDocuments: [],
  buyerQuotationDocuments: [],
  sellerQuotationImages: [],
  buyerQuotationImages: [],
  sellerDocumentConfig: {},
  sellerImageConfig: {},
  quotationFormValues: {},
  quotationPrice: {}
};

const QuotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QUOTATION_LISTING':
      return {
        ...state,
        quotationList: action.payload
      };
    case 'QUOTATION_FORM':
      return {
        ...state,
        quotationForm: action.payload
      };
    case 'QUOTATION_DOCUMENTS':
      return {
        ...state,
        quotationDocuments: action.payload
      };
    case 'SELLER_QUOTATION_DOCUMENTS':
      return {
        ...state,
        sellerQuotationDocuments: action.payload
      };
    case 'BUYER_QUOTATION_DOCUMENTS':
      return {
        ...state,
        buyerQuotationDocuments: action.payload
      };
    case 'SELLER_QUOTATION_IMAGES':
      return {
        ...state,
        sellerQuotationImages: action.payload
      };
    case 'BUYER_QUOTATION_IMAGES':
      return {
        ...state,
        buyerQuotationImages: action.payload
      };
    case 'SELLER_DOCUMENT_CONFIG':
      return {
        ...state,
        sellerDocumentConfig: action.payload
      };
    case 'SELLER_IMAGE_CONFIG':
      return {
        ...state,
        sellerImageConfig: action.payload
      };
    case 'QUOTATION_FORM_VALUES':
      return {
        ...state,
        quotationFormValues: action.payload
      };
    case 'QUOTATION_PRICE':
      return {
        ...state,
        quotationPrice: action.payload
      };
    default:
      return state;
  }
};

export default QuotationReducer;
