import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import Button from '../../../Shared/Inputs/Button';
import TextField from '../../../Shared/Inputs/TextField';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '../../../Shared/Inputs/Select';
import Snackbar from '../../../Shared/Utils/SnackBar';
import { ipConditionTypes, accessTypes } from '../../../../data/appConstants';
import ToggleSwitch from '../../../Shared/ToggleSwitch';
import '../api-throttling.scss';
import customValidator from '../../../../validator/customValidator';
import IdConstants from '../../../../data/idConstants';
import isEmpty from '../../../../validator/isEmpty';
import ConditionTable from '../ConditionTable';
import RadioButton from '../../../Shared/RadioButton';
import variables from '../../../../variables.module.scss';

const useStyles = {
  labelAsterisk: {
    color: 'red'
  },
  typographyStyle: {
    fontSize: '0.7rem',
    color: '#8c8c8c',
    textAlign: 'right'
  },
  inputgroup: {
    marginTop: '20px',
    '& > *': {
      margin: '5px 0px',
      fontSize: '0.8rem',
      fontFamily: variables.mediumBoschFont,
      color: '#333'
    }
  }
};

class ConditionGroup extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
      currentGroup: {},
      conditionGroupObj: {
        conditionGrpName: '',
        description: '',
        throttleConditionGrpId: ''
      },
      ipConditionObj: {
        throttleType: {
          throttleTypeId: IdConstants.throttlePolicy.ipThrottleTypeId
        },
        paramName: 'SPECIFIC_IP',
        paramValue: '',
        toRange: '',
        active: false,
        accessType: 'ALLOW_ACCESS',
        allowed: true
      },
      headerConditionObj: {
        throttleType: {
          throttleTypeId: IdConstants.throttlePolicy.headerThrottleTypeId
        },
        active: false,
        accessType: 'ALLOW_ACCESS',
        allowed: true
      },
      queryConditionObj: {
        throttleType: {
          throttleTypeId: IdConstants.throttlePolicy.queryThrottleTypeId
        },
        active: false,
        accessType: 'ALLOW_ACCESS',
        allowed: true
      },
      validName: '',
      validIP: '',
      validToRange: '',
      snackBarMsg: '',
      snackBarType: '',
      displaySnackBar: false,
      headerData: [],
      queryData: []
    };
  }

  componentDidMount() {
    this.props.resetConditionGroupError();
    this.setState({
      currentGroup: this.props.allMasterData?.throttle_condition_type[0]
    });
    if (this.props.isEdit) {
      let groupDetails = this.props.groupDetails;
      let headerData = [];
      let queryData = [];
      this.setState({
        conditionGroupObj: {
          conditionGrpName: groupDetails.conditionGrpName,
          description: groupDetails.description,
          throttleConditionGrpId: groupDetails.throttleConditionGrpId
        }
      });
      groupDetails?.conditions?.map((condition) => {
        switch (condition.throttleTypeId) {
          case IdConstants.throttlePolicy.ipThrottleTypeId:
            this.setState({
              ipConditionObj: {
                throttleType: {
                  throttleTypeId: IdConstants.throttlePolicy.ipThrottleTypeId
                },
                paramName: condition.paramName,
                paramValue: condition.paramValue,
                toRange: condition.toRange === null ? '' : condition.toRange,
                active: condition.active,
                accessType: condition.allowed ? 'ALLOW_ACCESS' : 'RESTRICT_ACCESS',
                allowed: condition.allowed
              }
            });
            break;
          case IdConstants.throttlePolicy.headerThrottleTypeId:
            this.setState({
              headerConditionObj: {
                throttleType: {
                  throttleTypeId: IdConstants.throttlePolicy.headerThrottleTypeId
                },
                active: condition.active,
                accessType: condition.allowed ? 'ALLOW_ACCESS' : 'RESTRICT_ACCESS',
                allowed: condition.allowed
              }
            });
            headerData.push({
              paramName: condition.paramName,
              paramValue: condition.paramValue
            });
            break;
          case IdConstants.throttlePolicy.queryThrottleTypeId:
            this.setState({
              queryConditionObj: {
                throttleType: {
                  throttleTypeId: IdConstants.throttlePolicy.queryThrottleTypeId
                },
                active: condition.active,
                accessType: condition.allowed ? 'ALLOW_ACCESS' : 'RESTRICT_ACCESS',
                allowed: condition.allowed
              }
            });
            queryData.push({
              paramName: condition.paramName,
              paramValue: condition.paramValue
            });
            break;
          default:
            break;
        }
        return true;
      });
      this.setState({
        headerData: headerData,
        queryData: queryData
      });
    }
  }

  handleClose = (event) => {
    this.setState({ open: false });
    this.props.callback(false);
  };

  handleFormSubmit = (event) => {
    let conditions = [];
    this.props.addIpToList(
      this.props.policyId,
      this.state.ipConditionObj.paramValue
    );
    if (this.state.ipConditionObj.active) {
      conditions.push(this.state.ipConditionObj);
    } else {
      if (this.state.ipConditionObj.paramValue !== '' && this.state.validIP === '') {
        if (
          this.state.ipConditionObj.paramName === 'SPECIFIC_IP' ||
          (this.state.ipConditionObj.toRange !== '' &&
            this.state.validToRange === '')
        ) {
          conditions.push(this.state.ipConditionObj);
        }
      }
    }
    this.state.headerData.forEach((headerCondition) => {
      conditions.push({
        paramName: headerCondition.paramName,
        paramValue: headerCondition.paramValue,
        allowed: this.state.headerConditionObj.allowed,
        active: this.state.headerConditionObj.active,
        throttleType: this.state.headerConditionObj.throttleType
      });
    });
    this.state.queryData.forEach((queryCondition) => {
      conditions.push({
        paramName: queryCondition.paramName,
        paramValue: queryCondition.paramValue,
        allowed: this.state.queryConditionObj.allowed,
        active: this.state.queryConditionObj.active,
        throttleType: this.state.queryConditionObj.throttleType
      });
    });
    this.props
      .validateConditionGroupName(this.state.conditionGroupObj, this.props.policyId)
      .then(() => {
        if (this.props.isValidCondGrpName) {
          if (this.props.isEdit) {
            this.props
              .updateThrottleConditionGroup(
                this.state.conditionGroupObj,
                conditions,
                this.props.policyId
              )
              .then(() => {
                if (!isEmpty(this.props.conditionGroupError)) {
                  this.setState({
                    displaySnackBar: true,
                    snackBarMsg: this.props.conditionGroupError.message,
                    snackBarType: 'error'
                  });
                } else {
                  this.props.callback(true);
                }
              });
          } else {
            this.props
              .createThrottleConditionGroup(
                this.state.conditionGroupObj,
                conditions,
                this.props.policyId
              )
              .then(() => {
                if (!isEmpty(this.props.conditionGroupError)) {
                  this.setState({
                    displaySnackBar: true,
                    snackBarMsg: this.props.conditionGroupError.message,
                    snackBarType: 'error'
                  });
                } else {
                  this.props.callback(true);
                }
              });
          }
        } else {
          this.setState({
            validName: 'Group Name already exists'
          });
        }
      });
  };

  setCurrentGroup = (group) => {
    this.setState({
      currentGroup: group
    });
  };

  changeValue = (event, obj, value) => {
    let name = event.target.name;
    this.setState({
      [obj]: {
        ...this.state[obj],
        [name]: event.target.value
      }
    });
    if (event.target.name === 'paramName') {
      this.setState((prevState) => ({
        ipConditionObj: {
          ...prevState.ipConditionObj,
          paramValue: ''
        }
      }));
    }
    switch (name) {
      case 'conditionGrpName':
        if (event.target.value === '') {
          this.setState({
            validName: 'Group Name should not be empty'
          });
        } else if (!customValidator(event.target.value, 'CONDITION_GROUP_NAME')) {
          this.setState({
            validName: 'Name must be alphanumeric'
          });
        } else {
          this.setState({
            validName: ''
          });
        }
        break;
      case 'paramValue':
        customValidator(event.target.value, 'IP_ADDRESS')
          ? this.setState({
              validIP: ''
            })
          : this.setState({
              validIP: 'Not a valid IP address'
            });
        if (value === 'specificIp') {
          this.props?.iplist?.map((iplist) => {
            if (
              iplist.policyId === this.props?.policyId &&
              iplist.ip === event.target.value
            ) {
              this.setState({
                validIP: 'IP Address already exists for this policy'
              });
            }
            return true;
          });
        }
        break;
      case 'toRange':
        customValidator(event.target.value, 'IP_ADDRESS')
          ? this.setState({
              validToRange: ''
            })
          : this.setState({
              validToRange: 'Not a valid IP address'
            });
        break;
      default:
        break;
    }
  };

  changeRadioButton = (event, obj) => {
    if (event.target.value === 'ALLOW_ACCESS') {
      this.setState({
        [obj]: {
          ...this.state[obj],
          allowed: true,
          [event.target.name]: event.target.value
        }
      });
    } else {
      this.setState({
        [obj]: {
          ...this.state[obj],
          allowed: false,
          [event.target.name]: event.target.value
        }
      });
    }
  };

  blurValue(event) {
    if (event.target.value !== '') {
      this.validateThrottleCondGrpName(event.target.value);
    }
  }
  validateThrottleCondGrpName(name) {
    this.props.validateConditionGroupName(
      this.state.conditionGroupObj,
      this.props.policyId
    );
  }

  toggleCondition = (event, obj) => {
    this.setState({
      [obj]: {
        ...this.state[obj],
        active: event.target.checked
      }
    });
  };

  validateForm = () => {
    let isGroupNameValid =
      this.state.conditionGroupObj.conditionGrpName !== '' &&
      this.state.validName === '' &&
      this.props.isValidCondGrpName;
    let ipConditionFlag = true;
    let headerConditionFlag = true;
    let queryConditionFlag = true;
    let atleastOneActive =
      this.state.ipConditionObj.active ||
      this.state.headerConditionObj.active ||
      this.state.queryConditionObj.active;
    if (this.state.ipConditionObj.active) {
      if (this.state.ipConditionObj.paramValue === '' || this.state.validIP !== '') {
        ipConditionFlag = false;
      } else if (this.state.ipConditionObj.paramName === 'IP_RANGE') {
        if (
          this.state.ipConditionObj.toRange === '' ||
          this.state.validToRange !== ''
        ) {
          ipConditionFlag = false;
        }
      }
    }
    if (this.state.headerConditionObj.active) {
      if (this.state.headerData.length === 0) {
        headerConditionFlag = false;
      }
    }
    if (this.state.queryConditionObj.active) {
      if (this.state.queryData.length === 0) {
        queryConditionFlag = false;
      }
    }
    return (
      isGroupNameValid &&
      ipConditionFlag &&
      headerConditionFlag &&
      queryConditionFlag &&
      atleastOneActive
    );
  };

  changeState = () => {
    this.setState({
      displaySnackBar: false
    });
  };

  saveTableData = (data, conditionType) => {
    this.setState({
      [conditionType]: data
    });
  };

  render() {
    const { classes } = this.props;
    let helperText = this.state.validName;
    if (!this.props.isValidCondGrpName) {
      helperText = 'Group name already exists';
    }
    let isFormValid = this.validateForm();
    return (
      <React.Fragment>
        {this.state.displaySnackBar && (
          <Snackbar
            severity={this.state.snackBarType}
            message={this.state.snackBarMsg}
            changeState={this.changeState}
          />
        )}
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.open}
          onClose={this.handleClose}
          disableBackdropClick={true}
        >
          <div className='div-style'>
            <Grid container>
              <Grid item xs={11}>
                <h2 id='simple-modal-title' className='condition-group-heading'>
                  {this.props.modalContent}
                </h2>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={this.handleClose}>
                  <CloseIcon size='small' />
                </IconButton>
              </Grid>
            </Grid>
            {!this.props.isEdit && (
              <Box className='font-regular'>
                At least one condition must be enabled to Add a Condition group
              </Box>
            )}
            {this.props.isTrottlePolicyConsumed && (
              <Box mt={1} className='font-regular'>
                Assigned API has active subscriptions, hence Only Description field
                is editable. All other fields are disabled.
              </Box>
            )}
            <Grid container>
              <Grid item sm={7}>
                <div className={classes.inputgroup}>
                  <InputLabel
                    htmlFor='component-simple'
                    required
                    classes={{ asterisk: classes.labelAsterisk }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    id='conditionGrpName'
                    placeholder='Enter Condition Group Name'
                    name='conditionGrpName'
                    onBlur={(event) => this.blurValue(event)}
                    onChange={(event) =>
                      this.changeValue(event, 'conditionGroupObj')
                    }
                    inputProps={{ maxLength: 20 }}
                    value={this.state.conditionGroupObj.conditionGrpName}
                    title={this.state.conditionGroupObj.conditionGrpName}
                    error={
                      this.props.isValidCondGrpName && this.state.validName === ''
                        ? false
                        : true
                    }
                    helperText={helperText}
                    disabled={this.props.isTrottlePolicyConsumed}
                    fullWidth
                  />
                  <Typography className={classes.typographyStyle}>
                    Max Char - 20
                  </Typography>
                </div>
                <div className={classes.inputgroup}>
                  <InputLabel htmlFor='component-simple'>Description</InputLabel>
                  <TextField
                    id='description'
                    placeholder='Enter Description'
                    name='description'
                    onChange={(event) =>
                      this.changeValue(event, 'conditionGroupObj')
                    }
                    inputProps={{ maxLength: 500 }}
                    multiline
                    rows={4}
                    value={this.state.conditionGroupObj.description}
                    title={this.state.conditionGroupObj.description}
                    disabled={this.props.isTrottlePolicyConsumed}
                    fullWidth
                  />
                  <Typography className={classes.typographyStyle}>
                    Max Char - 500
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Box my={4}>
              {this.props.allMasterData.throttle_condition_type.map((group) => (
                <Box
                  key={group.description}
                  disabled
                  px={2}
                  py={1}
                  display='inline'
                  className='box-style'
                  onClick={() => this.setCurrentGroup(group)}
                  style={{
                    background:
                      group === this.state.currentGroup ? 'lightgray' : 'white'
                  }}
                >
                  {group.description}
                </Box>
              ))}
            </Box>

            {this.state.currentGroup.id ===
              IdConstants.throttlePolicy.ipThrottleTypeId && (
              <Grid container>
                <Grid container>
                  <Grid item>
                    <Box mr={1} className='font-medium'>
                      Enable IP Condition
                    </Box>
                  </Grid>
                  <ToggleSwitch
                    checked={this.state.ipConditionObj.active}
                    onChange={(event) =>
                      this.toggleCondition(event, 'ipConditionObj')
                    }
                    name='active'
                    disabled={this.props.isTrottlePolicyConsumed}
                  />
                </Grid>
                <Grid item sm={7}>
                  <div className={classes.inputgroup}>
                    <InputLabel
                      required
                      classes={{ asterisk: classes.labelAsterisk }}
                    >
                      IP Condition Type
                    </InputLabel>
                    <Select
                      data={ipConditionTypes}
                      name='paramName'
                      onChange={(event) => this.changeValue(event, 'ipConditionObj')}
                      value={this.state.ipConditionObj.paramName}
                      disabled={
                        !this.state.ipConditionObj.active ||
                        this.props.isTrottlePolicyConsumed
                      }
                    />
                  </div>
                  {this.state.ipConditionObj.paramName === 'SPECIFIC_IP' ? (
                    <div className={classes.inputgroup}>
                      <InputLabel
                        required
                        classes={{ asterisk: classes.labelAsterisk }}
                      >
                        IP Address
                      </InputLabel>
                      <TextField
                        id='filled-basic'
                        placeholder='Enter IP Address'
                        name='paramValue'
                        onChange={(event) =>
                          this.changeValue(event, 'ipConditionObj', 'specificIp')
                        }
                        inputProps={{ maxLength: 15 }}
                        value={this.state.ipConditionObj.paramValue}
                        title={this.state.ipConditionObj.paramValue}
                        error={
                          this.state.validIP && this.state.ipConditionObj.active
                            ? true
                            : false
                        }
                        helperText={
                          this.state.ipConditionObj.active ? this.state.validIP : ''
                        }
                        disabled={
                          !this.state.ipConditionObj.active ||
                          this.props.isTrottlePolicyConsumed
                        }
                        fullWidth
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className={classes.inputgroup}>
                        <InputLabel
                          required
                          classes={{ asterisk: classes.labelAsterisk }}
                        >
                          Starting IP Address
                        </InputLabel>
                        <TextField
                          id='filled-basic'
                          placeholder='Enter Starting IP Address'
                          name='paramValue'
                          onChange={(event) =>
                            this.changeValue(event, 'ipConditionObj')
                          }
                          inputProps={{ maxLength: 15 }}
                          value={this.state.ipConditionObj.paramValue}
                          title={this.state.ipConditionObj.paramValue}
                          error={
                            this.state.validIP && this.state.ipConditionObj.active
                              ? true
                              : false
                          }
                          helperText={
                            this.state.ipConditionObj.active
                              ? this.state.validIP
                              : ''
                          }
                          disabled={
                            !this.state.ipConditionObj.active ||
                            this.props.isTrottlePolicyConsumed
                          }
                          fullWidth
                        />
                      </div>
                      <div className={classes.inputgroup}>
                        <InputLabel
                          required
                          classes={{ asterisk: classes.labelAsterisk }}
                        >
                          Ending IP Address
                        </InputLabel>
                        <TextField
                          id='filled-basic'
                          placeholder='Enter Ending IP Address'
                          name='toRange'
                          onChange={(event) =>
                            this.changeValue(event, 'ipConditionObj')
                          }
                          inputProps={{ maxLength: 15 }}
                          value={this.state.ipConditionObj.toRange}
                          title={this.state.ipConditionObj.toRange}
                          error={
                            this.state.validToRange &&
                            this.state.ipConditionObj.active
                              ? true
                              : false
                          }
                          helperText={
                            this.state.ipConditionObj.active
                              ? this.state.validToRange
                              : ''
                          }
                          disabled={
                            !this.state.ipConditionObj.active ||
                            this.props.isTrottlePolicyConsumed
                          }
                          fullWidth
                        />
                      </div>
                    </React.Fragment>
                  )}
                </Grid>
                <RadioButton
                  checkedItem={this.state.ipConditionObj.accessType}
                  name='accessType'
                  onChange={(event) =>
                    this.changeRadioButton(event, 'ipConditionObj')
                  }
                  disabled={
                    !this.state.ipConditionObj.active ||
                    this.props.isTrottlePolicyConsumed
                  }
                  data={accessTypes}
                />
              </Grid>
            )}
            {this.state.currentGroup.id ===
              IdConstants.throttlePolicy.headerThrottleTypeId && (
              <React.Fragment>
                <Grid container>
                  <Grid container>
                    <Grid item>
                      <Box mr={1} className='font-medium'>
                        Enable Header Condition
                      </Box>
                    </Grid>
                    <ToggleSwitch
                      checked={this.state.headerConditionObj.active}
                      onChange={(event) =>
                        this.toggleCondition(event, 'headerConditionObj')
                      }
                      name='active'
                      disabled={this.props.isTrottlePolicyConsumed}
                    />
                  </Grid>
                </Grid>
                <ConditionTable
                  isActive={
                    this.state.headerConditionObj.active &&
                    !this.props.isTrottlePolicyConsumed
                  }
                  disabled={this.props.isTrottlePolicyConsumed}
                  conditionType='headerData'
                  toolTip='Add Header condition'
                  parentCallBack={this.saveTableData}
                  headerData={this.state.headerData}
                />
                <br />
                <RadioButton
                  checkedItem={this.state.headerConditionObj.accessType}
                  name='accessType'
                  onChange={(event) =>
                    this.changeRadioButton(event, 'headerConditionObj')
                  }
                  disabled={
                    !this.state.headerConditionObj.active ||
                    this.props.isTrottlePolicyConsumed
                  }
                  data={accessTypes}
                />
              </React.Fragment>
            )}
            {this.state.currentGroup.id ===
              IdConstants.throttlePolicy.queryThrottleTypeId && (
              <React.Fragment>
                <Grid container>
                  <Grid container>
                    <Grid item>
                      <Box mr={1} className='font-medium'>
                        Enable Query param Condition
                      </Box>
                    </Grid>
                    <ToggleSwitch
                      checked={this.state.queryConditionObj.active}
                      onChange={(event) =>
                        this.toggleCondition(event, 'queryConditionObj')
                      }
                      name='active'
                      disabled={this.props.isTrottlePolicyConsumed}
                    />
                  </Grid>
                </Grid>
                <ConditionTable
                  isActive={
                    this.state.queryConditionObj.active &&
                    !this.props.isTrottlePolicyConsumed
                  }
                  conditionType='queryData'
                  toolTip='Add Query param Condition'
                  parentCallBack={this.saveTableData}
                  queryData={this.state.queryData}
                />
                <br />
                <RadioButton
                  checkedItem={this.state.queryConditionObj.accessType}
                  name='accessType'
                  onChange={(event) =>
                    this.changeRadioButton(event, 'queryConditionObj')
                  }
                  disabled={
                    !this.state.queryConditionObj.active ||
                    this.props.isTrottlePolicyConsumed
                  }
                  data={accessTypes}
                />
              </React.Fragment>
            )}

            <Grid container>
              <Grid item sm>
                <Box mt={1} display='flex' justifyContent='flex-end'>
                  {!this.props.isTrottlePolicyConsumed && (
                    <Button
                      variant='contained'
                      style={{ marginRight: '1rem' }}
                      label='Save'
                      onClick={this.handleFormSubmit}
                      disabled={!isFormValid}
                    />
                  )}
                  <Button
                    variant='outlined'
                    onClick={this.handleClose}
                    label='Cancel'
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ConditionGroup);
