const intialState = {
  rejectReasons: []
};

const rejectReasonReducer = (state = intialState, action) => {
  if (action.type === 'SET_REJECT_REASONS') {
    return {
      ...state,
      rejectReasons: action.payload
    };
  }
  return state;
};

export default rejectReasonReducer;
