export const getInitialProdDetailsSettingsStepper = (
  prodDetailedInfo,
  prodDetailedInfoFromSource
) => {
  prodDetailedInfo.apiType =
    prodDetailedInfo.apiType ?? prodDetailedInfoFromSource.apiType ?? '';
  prodDetailedInfo.baseUrl =
    prodDetailedInfo.baseUrl ?? prodDetailedInfoFromSource.baseUrl ?? '';
  prodDetailedInfo.authorizationKey =
    prodDetailedInfo.authorizationKey ??
    prodDetailedInfoFromSource.authorizationKey ??
    '';
  prodDetailedInfo.authType =
    prodDetailedInfo.authType ?? prodDetailedInfoFromSource.authType ?? '';
  prodDetailedInfo.grantType =
    prodDetailedInfo.grantType ?? prodDetailedInfoFromSource.grantType ?? '';
  prodDetailedInfo.authorizationURL =
    prodDetailedInfo.authorizationURL ??
    prodDetailedInfoFromSource.authorizationURL ??
    '';
  prodDetailedInfo.verificationURL =
    prodDetailedInfo.verificationURL ??
    prodDetailedInfoFromSource.verificationURL ??
    '';
  prodDetailedInfo.authTokenUrl =
    prodDetailedInfo.authTokenUrl ?? prodDetailedInfoFromSource.authTokenUrl ?? '';
  prodDetailedInfo.separator =
    prodDetailedInfo.separator ?? prodDetailedInfoFromSource.separator ?? '2';
  prodDetailedInfo.scope =
    prodDetailedInfo.scope ?? prodDetailedInfoFromSource.scope ?? '';
  prodDetailedInfo.secretHeaderParamData =
    prodDetailedInfo.secretHeaderParamData ??
    prodDetailedInfoFromSource.secretHeaderParamData ??
    [];
  prodDetailedInfo.groupData =
    prodDetailedInfo.groupData ?? prodDetailedInfoFromSource.groupData ?? [];
  prodDetailedInfo.endpointsData =
    prodDetailedInfo.endpointsData ?? prodDetailedInfoFromSource.endpointsData ?? [];
  return prodDetailedInfo;
};

export const getProdDetailsSettingsStepperUpdate = (
  prodDetailedInfo,
  currentState,
  prodDetailedInfoFromSource
) => {
  prodDetailedInfo.apiType = prodDetailedInfo.apiType?.length
    ? prodDetailedInfo.apiType
    : (currentState.prodDetailedInfo.apiType ??
      prodDetailedInfoFromSource.apiType ??
      '');
  prodDetailedInfo.baseUrl = prodDetailedInfo.baseUrl?.length
    ? prodDetailedInfo.baseUrl
    : (currentState.prodDetailedInfo.baseUrl ??
      prodDetailedInfoFromSource.baseUrl ??
      '');
  prodDetailedInfo.authorizationKey = prodDetailedInfo.authorizationKey?.length
    ? prodDetailedInfo.authorizationKey
    : (currentState.prodDetailedInfo.authorizationKey ??
      prodDetailedInfoFromSource.authorizationKey ??
      '');
  prodDetailedInfo.authType = prodDetailedInfo.authType?.length
    ? prodDetailedInfo.authType
    : (currentState.prodDetailedInfo.authType ??
      prodDetailedInfoFromSource.authType ??
      '');
  prodDetailedInfo.grantType = prodDetailedInfo.grantType?.length
    ? prodDetailedInfo.grantType
    : (currentState.prodDetailedInfo.grantType ??
      prodDetailedInfoFromSource.grantType ??
      '');
  prodDetailedInfo.authorizationURL = prodDetailedInfo.authorizationURL?.length
    ? prodDetailedInfo.authorizationURL
    : (currentState.prodDetailedInfo.authorizationURL ??
      prodDetailedInfoFromSource.authorizationURL ??
      '');
  prodDetailedInfo.verificationURL = prodDetailedInfo.verificationURL?.length
    ? prodDetailedInfo.verificationURL
    : (currentState.prodDetailedInfo.verificationURL ??
      prodDetailedInfoFromSource.verificationURL ??
      '');
  prodDetailedInfo.authTokenUrl = prodDetailedInfo.authTokenUrl?.length
    ? prodDetailedInfo.authTokenUrl
    : (currentState.prodDetailedInfo.authTokenUrl ??
      prodDetailedInfoFromSource.authTokenUrl ??
      '');
  prodDetailedInfo.separator = prodDetailedInfo.separator?.length
    ? prodDetailedInfo.separator
    : (currentState.prodDetailedInfo.separator ??
      prodDetailedInfoFromSource.separator ??
      '2');
  prodDetailedInfo.scope = prodDetailedInfo.scope?.length
    ? prodDetailedInfo.scope
    : (currentState.prodDetailedInfo.scope ??
      prodDetailedInfoFromSource.scope ??
      '');
  prodDetailedInfo.secretHeaderParamData = prodDetailedInfo.secretHeaderParamData
    ?.length
    ? prodDetailedInfo.secretHeaderParamData
    : (currentState.prodDetailedInfo.secretHeaderParamData ??
      prodDetailedInfoFromSource.secretHeaderParamData ??
      []);
  prodDetailedInfo.groupData = prodDetailedInfo.groupData?.length
    ? prodDetailedInfo.groupData
    : (currentState.prodDetailedInfo.groupData ??
      prodDetailedInfoFromSource.groupData ??
      []);
  prodDetailedInfo.endpointsData = prodDetailedInfo.endpointsData
    ? prodDetailedInfo.endpointsData
    : (currentState.prodDetailedInfo.endpointsData ??
      prodDetailedInfoFromSource.endpointsData ??
      []);
  return prodDetailedInfo;
};
