import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';

import SimpleDropdown from '../simple-dropdown/SimpleDropDown';
import Images from '../../../assets/img';

import './index.scss';

const UpDownArrow = ({ id, handleOrderUp, handleOrderDown, handleGrouping }) => {
  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownWrapperRef.current &&
        !dropdownWrapperRef.current.contains(event.target)
      ) {
        onClickOutside();
      }
    };

    const onClickOutside = () => {
      handleGrouping();
    };

    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event);
    });
    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event);
      });
    };
  }, []);

  const getDropdownData = () => {
    return [
      {
        id: 1,
        label: i18next.t('DYNAMIC_FORM.SELECT.MOVE_UP'),
        handler: () => {
          handleOrderUp(id);
        }
      },
      {
        id: 2,
        label: i18next.t('DYNAMIC_FORM.SELECT.MOVE_DOWN'),
        handler: () => {
          handleOrderDown(id);
        }
      }
    ];
  };

  const handleCloseModal = () => {
    const popoverContentContainer = document.getElementById('popover-content');
    if (!popoverContentContainer) return;
    popoverContentContainer.style.display = 'none';
    while (popoverContentContainer.hasChildNodes()) {
      popoverContentContainer.removeChild(popoverContentContainer.firstChild);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth - 100;
    const screenH = window.innerHeight - 300;
    const menuData = getDropdownData();
    const rootW = 150;
    const rootH = menuData.length > 0 ? 39 * menuData.length : 90;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;
    let style = {};

    if (right) {
      style.left = `${clickX + 5}px`;
    }

    if (left) {
      style.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      style.top = `${clickY - rootH - 5}px`;
    }
    let popoverContentContainer = document.getElementById('popover-content');
    popoverContentContainer.onclick = () => {
      handleCloseModal();
    };
    if (popoverContentContainer) {
      popoverContentContainer.innerHTML = '';
      popoverContentContainer.style.display = 'block';
      const contextMenuElement = document.createElement('context-menu');
      contextMenuElement.style.left = style.left;
      contextMenuElement.style.top = style.top;
      contextMenuElement.style.width = rootW + 'px';
      contextMenuElement.style.height = rootH + 'px';
      contextMenuElement.style.position = 'absolute';

      ReactDOM.render(
        <SimpleDropdown dropDownData={menuData} />,
        contextMenuElement
      );
      popoverContentContainer.append(contextMenuElement);
    }
  };

  return (
    <div>
      <div className='frontend-kit-example_context-menu'>
        <div className='example-text-context-menu -size-m'>
          <img
            src={Images.upDown}
            onClick={handleClick}
            data-testid='name'
            className='arrow__img'
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ARROW')}
          />
        </div>
      </div>
    </div>
  );
};

export default UpDownArrow;
