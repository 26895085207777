import React from 'react';
import PropTypes from 'prop-types';
import CustomToolTip from '../../../components/Shared/custom-tooltip';

const Errors = ({ errorMessaage, cssName, ...props }) => {
  return (
    <>
      {errorMessaage && (
        <div
          className={`error-notification a-notification a-notification--text -error ${cssName}`}
          role='alert'
        >
          <i
            className='a-icon boschicon-bosch-ic-alert-error'
            title='alert-error'
          ></i>
          <CustomToolTip
            className={props?.class}
            id={props?.id}
            tooltipData={errorMessaage}
            width='dynamic'
          >
            <div
              id={props?.id}
              className={
                props.isEllipsis
                  ? cssName + '-notification-label text-ellipsis'
                  : 'error-notification__label a-notification__content'
              }
            >
              {errorMessaage}
            </div>
          </CustomToolTip>
        </div>
      )}
    </>
  );
};

Errors.propTypes = {
  className: PropTypes.string,
  isEllipsis: PropTypes.number
};

Errors.defaultProps = {
  className: '',
  isEllipsis: false
};

export default Errors;
