import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import store from '../../../store';
import Card from '../product-card';
import Snackbar from '../../../components/Shared/Utils/SnackBar';
import idConstants from '../../../data/idConstants';
import { getRejectReasonForProduct } from '../../../actions/RejectionReasonAction';
import {
  getEditableDetails,
  getProductDetailedInfo
} from '../../../actions/api/SolutionAction';
import { toLowercaseReplaceSpace } from '../../../shared/shared-function/helperFunctions';
import { bindActionCreators } from 'redux';

const options = [
  i18next.t('DYNAMIC_FORM.LABEL.EDIT'),
  i18next.t('DYNAMIC_FORM.LABEL.VIEW'),
  i18next.t('DYNAMIC_FORM.LABEL.STOCK_UPDATE'),
  i18next.t('DYNAMIC_FORM.LABEL.CLONE')
];

class ProductListView extends Component {
  state = {
    view: false,
    selectedCard: '',
    openRejectedModal: ''
  };

  componentDidUpdate(prevProps) {
    if (this.props.partNumberData !== prevProps.partNumberData) {
      if (
        this.props.partNumberData?.status === 200 &&
        this.props.partNumberData?.sellerPartId
      ) {
        this.setState({
          snackBarType: 'success',
          snackBarMsg: i18next.t(
            'DYNAMIC_FORM.PART_UPDATE.PART_NUMBER_UPDATED_SUCCESSFULLY'
          ),
          displaySnackBar: true
        });
        return;
      }
      if (
        this.props.partNumberData?.status !== 200 &&
        !this.props.partNumberData?.sellerPartId
      ) {
        this.setState({
          snackBarType: 'error',
          snackBarMsg: i18next.t(
            'DYNAMIC_FORM.PART_UPDATE.UPDATING_PART_NUMBER_ERROR'
          ),
          displaySnackBar: true
        });
        return;
      }
    }
  }

  handlePublish = (data) => {
    this.props.handlePublish(data).then(() => {
      this.setState({
        snackBarType: 'success',
        snackBarMsg: i18next.t(
          'DYNAMIC_FORM.PRODUCT_CONTAINER.PUBLISHED_SUCCESSFULLY'
        ),
        displaySnackBar: true
      });
      this.props.handleUpdateProducts && this.props.handleUpdateProducts();
    });
  };

  changeState = () => {
    this.setState({
      displaySnackBar: false,
      snackBarMsg: ''
    });
  };

  clickedHandle = (card) => {
    if (
      !(
        card.status.description === 'READY_FOR_PUBLISH' ||
        card.status.description === 'PUBLISH_IN_PROGRESS'
      )
    ) {
      this.setState(
        {
          view: true,
          selectedCard: card
        },
        this.props.productCategoryId === idConstants.automotiveSpares.categoryId
          ? () => {
              if (this.state.selectedCard.status.description !== 'DRAFTED') {
                this.props.history.push({
                  pathname: `/automotive-spares/individual-form`,
                  key: 'view',
                  state: {
                    ...this.props.data,
                    isEdit: false,
                    productId: this.state.selectedCard.productId,
                    productCategoryId: this.state.selectedCard.productCategoryId,
                    isView: true,
                    submitted: false
                  }
                });
              } else {
                this.props.history.push({
                  pathname: `/automotive-spares/individual-form`,
                  key: 'edit',
                  state: {
                    ...this.props.data,
                    isEdit: true,
                    productId: this.state.selectedCard.productId,
                    productCategoryId: this.state.selectedCard.productCategoryId,
                    isView: false,
                    submitted: false,
                    redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
                      this.state.selectedCard.productCategoryName
                    )}`
                  }
                });
              }
            }
          : () => {
              if (this.state.selectedCard.status.description !== 'DRAFTED') {
                this.props.history.push({
                  pathname: `/product/view`,
                  key: 'view',
                  state: {
                    ...this.props.data,
                    isEdit: false,
                    productId: this.state.selectedCard.productId,
                    productCategoryId: this.state.selectedCard.productCategoryId,
                    isView: true,
                    submitted: false
                  }
                });
              } else {
                this.props.history.push({
                  pathname: `/product/edit`,
                  key: 'edit',
                  state: {
                    ...this.props.data,
                    isEdit: true,
                    productId: this.state.selectedCard.productId,
                    productCategoryId: this.state.selectedCard.productCategoryId,
                    isView: false,
                    submitted: false,
                    redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
                      this.state.selectedCard.productCategoryName
                    )}`
                  }
                });
              }
            }
      );
    }
  };

  openRejectModal = (description, value) => {
    const data = Promise.all([
      store.dispatch(getEditableDetails(value.productId)),
      store.dispatch(
        getProductDetailedInfo(value.productId, value.productCategoryId)
      ),
      store.dispatch(getRejectReasonForProduct(value.productId))
    ]);
    data.then((res) => {
      if (description === 'REJECTED') {
        this.setState((prev) => {
          return {
            ...prev,
            openRejectedModal: true
          };
        });
      }
    });
  };

  closeRejectModal = (productId, productCategoryId) => {
    if (productId && productCategoryId) {
      this.props.history.push({
        pathname: `/product/view`,
        key: 'view',
        state: {
          isEdit: false,
          productId: productId,
          productCategoryId: productCategoryId,
          isView: true,
          publishData: null,
          submitted: false
        }
      });
    }
    this.setState((prev) => {
      return {
        ...prev,
        openRejectedModal: false
      };
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.displaySnackBar && (
          <Snackbar
            severity={this.state.snackBarType}
            message={this.state.snackBarMsg}
            changeState={this.changeState}
          />
        )}
        <div className='api-list-view'>
          <div className='api-list-view_box'>
            {this.props?.data?.map((card, index) => (
              <div className='api-list-view_box_card' key={card.productId}>
                <Card
                  data={card}
                  options={options}
                  path={this.props.path}
                  handlePublish={this.handlePublish}
                  info={this.props.info}
                  updateInfo={this.props.updateInfo}
                  updatePrevSession={this.props.updatePrevSession}
                  resetEditableDetails={this.props.resetEditableDetails}
                  handleDeleteProduct={this.props.handleDeleteProduct}
                  location={this.props.location}
                  history={this.props.history}
                  getProductQuantity={this.props.getProductQuantity}
                  openRejectModal={this.openRejectModal}
                  closeRejectModal={this.closeRejectModal}
                  openRejectedModal={this.state.openRejectedModal}
                  loading={this.props.loading}
                  clickedHandle={() => this.clickedHandle(card)}
                  rootProductCategoryId={this.props.rootProductCategoryId}
                  handleUpdateProducts={this?.props?.handleUpdateProducts}
                  getPartNumber={this.props.getPartNumber}
                />
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  rejectReasons: redux_state.rejectReasonReducer.rejectReasons,
  partNumberData: redux_state.SettingsReducer.partNumberData
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRejectReasonForProduct,
      getEditableDetails,
      getProductDetailedInfo
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListView);
