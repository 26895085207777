import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Badge = (props) => {
  const { customClass, type, children } = props;
  return (
    <div
      className={`a-badge badge-container ${customClass} ${type}`}
      tabIndex='0'
      role='status'
    >
      {children}
    </div>
  );
};

Badge.propTypes = {
  customClass: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.string
};

Badge.defaultProps = {
  customClass: '',
  type: '',
  children: ''
};

export default Badge;
