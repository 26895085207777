import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Images from '../../../assets/img';

import { withStyles } from '@material-ui/core/styles';
import UserAccount from '../../../components/UserAccount';
import { clearLoginStorage } from '../../../actions/LoginAction';
import { getCountryList } from '../../../actions/CountryAction';
import { getProductCategories } from '../../../actions/MasterDataAction';
import SideNavigation from '../../../components/order-management/side-navigation';
import variables from '../../../variables.module.scss';
import './index.scss';
import i18next from 'i18next';

const drawerWidth = 0;

const useStyles = (theme) => ({
  root: {
    display: 'flex'
  },
  logo: {
    width: '9rem',
    paddingTop: '1px'
  },
  defaultColor: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  selected: {
    '&.Mui-selected:hover': {
      backgroundColor: '#005691',
      color: 'white'
    },
    '&.Mui-selected': {
      backgroundColor: '#005691',
      color: 'white'
    }
  },
  appBar: {
    background: 'white',
    marginTop: theme.spacing(1.8),
    zIndex: 2,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  leftShift: {
    marginLeft: '73px'
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    marginTop: theme.spacing(1.8),
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    marginTop: theme.spacing(1.8),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'pre-wrap',
    padding: 0,
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    maxHeight: '100vh',
    position: 'absolute',
    bottom: '60px',
    top: '14.4px',
    left: 0,
    right: 0
  },
  mainTypography: {
    border: '11px solid rgba(0, 0, 0, 0.09)',
    minHeight: '100%'
  },
  switchMarketLink: {
    fontFamily: variables.lightBoschFont,
    color: 'blue',
    cursor: 'pointer',
    marginRight: '10px'
  },
  switchlinkStyle: {
    marginLeft: '5px',
    cursor: 'pointer'
  }
});

class CollapsableSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      modalContent: '',
      modalHeading: '',
      exitDialog: false,
      noRole: false,
      menu: false
    };
  }

  handleMenuClick = (data) => this.setState({ menu: data });

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className='header'>
          <div
            style={{ backgroundImage: `url(${Images.boschStrip})` }}
            className='bosch-strip strip-small'
          />
          <div className='header__container'>
            <img src={Images.boschLogo} title='Bosch logo' className='bosch-logo' />
            <div className='header__account-wrapper'>
              <h1 className='header__title -size-l d-sm-none'>
                {i18next.t('SHARED.UNIFIED_SELLER_PORTAL')}
              </h1>
              {
                <UserAccount
                  handleMenuClick={this.handleMenuClick}
                  open={this.state.menu}
                  {...this.props}
                />
              }
            </div>
          </div>
          <div className='d-lg-none'>
            <SideNavigation
              open={this.state.menu}
              handleMenuClick={this.handleMenuClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  user: redux_state.loginReducer.user,
  breadCrumbValue: redux_state.SolutionReducer.breadCrumbValue,
  accessToken: redux_state.loginReducer.accessToken,
  productCategories: redux_state.MasterDataReducer.productCategories
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearLoginStorage,
      getCountryList,
      getProductCategories
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CollapsableSideBar));
