import React from 'react';
import i18next from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  Box,
  Paper,
  Card,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CheckIcon from '@material-ui/icons/Check';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IdConstants from '../../../../../data/idConstants';
import LightTooltip from '../../../Tooltip';
import Images from '../../../../../assets/img';
import './dialog-styles.scss';

const StyledTableRow = withStyles((theme) => ({
  root: {
    'nth-child(7n+1) ': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: '3px',
    borderBottom: '1px solid #e4e4e4'
  },
  body: {
    fontSize: 14,
    padding: '3px',
    paddingLeft: '10px',
    width: 'auto',
    borderBottom: '1px solid #e4e4e4'
  }
}))(TableCell);

const pricingPlanRowData = [
  {
    id: 1,
    value: 'DYNAMIC_FORM.PRICING_PLAN_DIALOG.SL_NO',
    style: 'sl-no-content'
  },
  {
    id: 2,
    value: 'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICING_TIER',
    style: 'pricing-tier-content'
  },
  {
    id: 3,
    value: 'DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA',
    style: 'quota-content'
  },
  {
    id: 4,
    value: 'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICE',
    style: 'price-content'
  },
  {
    id: 5,
    value: 'DYNAMIC_FORM.PRICING_PLAN_DIALOG.UNIT',
    style: 'unit-content'
  }
];

const getPriceRange = (plan) => {
  if (
    plan.rateLimit?.slabLimitDetails &&
    plan.rateLimit?.slabLimitDetails?.length !== 0
  ) {
    let priceValues = [];
    let minPrice = '';
    let maxPrice = '';
    plan.rateLimit?.slabLimitDetails?.map((tier) => {
      priceValues.push(tier.cost);
      return true;
    });
    //checking if all values are same
    if (priceValues.every((item) => item === priceValues[0])) {
      minPrice = priceValues[0];
    } else {
      priceValues.sort(function (firstElement, secondElement) {
        return firstElement - secondElement;
      });
      minPrice = priceValues[0];
      if (priceValues.length > 1) {
        maxPrice = priceValues[priceValues.length - 1];
      }
    }
    return (
      <>
        {minPrice !== '' && (
          <span className='bold-style'>
            {parseFloat(minPrice).toFixed(2)} {plan.currency?.currencyCode}
          </span>
        )}
        {maxPrice !== '' && (
          <span className='bold-style'>
            {' '}
            - {parseFloat(maxPrice).toFixed(2)} {plan.currency?.currencyCode}
          </span>
        )}
      </>
    );
  }
};

function PlanDetailsDialog(props) {
  const handleGridSize = () => {
    const tempRateLimit = props.plan?.rateLimit;
    if (
      tempRateLimit?.rateLimitType?.limitType ===
      IdConstants.pricing.reqCountLimitType
    ) {
      return tempRateLimit?.chargeableUnit?.chargeableUnitType ===
        IdConstants.pricing.fullSlabType
        ? '1'
        : '4';
    } else return '4';
  };

  const handleJustifyContentType = () => {
    const tempRateLimit = props.plan?.rateLimit;
    if (
      tempRateLimit?.rateLimitType?.limitType ===
      IdConstants.pricing.reqCountLimitType
    ) {
      return tempRateLimit?.chargeableUnit?.chargeableUnitType ===
        IdConstants.pricing.fullSlabType
        ? 'flex-start'
        : 'space-around';
    } else return 'space-around';
  };

  const handleRenderRateLimitType = () => {
    const tempRateLimit = this.props.plan.rateLimit;
    if (
      tempRateLimit.rateLimitType?.limitType ===
      IdConstants.pricing.reqCountLimitType
    ) {
      return tempRateLimit?.chargeableUnit?.chargeableUnitType ===
        IdConstants.pricing.fullSlabType
        ? i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.FULL_SLAB')
        : i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PER_REQUEST');
    } else {
      return tempRateLimit
        ? i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER') +
            tempRateLimit?.bandwidthUnitType?.bandwidthUnit +
            `(${i18next.t(DYNAMIC_FORM.SHARED.DATA_VOLUME)})`
        : '';
    }
  };

  const displayPricingTier = (tier) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={handleGridSize}>
          <Box display='flex' justifyContent='flex-end' alignItems='center'>
            {tier.minValue}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box display='flex' justifyContent='center' alignItems='center'>
            {tier.maxValue === '-1' ? '+' : '-'}
          </Box>
        </Grid>
        <Grid item xs={6}>
          {tier.maxValue !== '-1' ? (
            props.plan.rateLimit?.rateLimitType?.limitType ===
            IdConstants.pricing.bandWidthRateLimitType ? (
              <Box display='flex' justifyContent='flex-start' alignItems='center'>
                <span>
                  {tier.maxValue +
                    ' ' +
                    props.plan.rateLimit?.bandwidthUnitType?.bandwidthUnit}
                </span>
              </Box>
            ) : (
              <Box display='flex' justifyContent='flex-start' alignItems='center'>
                <span>{tier.maxValue}</span>&nbsp;
                <span>{i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.REQS')}</span>
              </Box>
            )
          ) : props.plan.rateLimit?.rateLimitType?.limitType ===
            IdConstants.pricing.bandWidthRateLimitType ? (
            <Box display='flex' justifyContent='flex-start' alignItems='center'>
              <span>{props.plan.rateLimit?.bandwidthUnitType?.bandwidthUnit}</span>
            </Box>
          ) : (
            <Box display='flex' justifyContent='flex-start' alignItems='center'>
              <span>{i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.REQS')}</span>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  };
  const displayCost = (tier) => {
    return tier.cost;
  };
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className='div-content-style'>
        <Grid container className={props.className}>
          <Grid item className='plan-details-display'>
            <Box align='center' color='white' ml={3} my={1}>
              <Typography
                align='center'
                variant='p'
                component='p'
                className='plan-name-style'
              >
                {props.planDetails.planName}
              </Typography>
              <Box className='price-display-margin'>{getPriceRange(props.plan)}</Box>
              <Typography className='slab-style'>
                {handleRenderRateLimitType}
              </Typography>
            </Box>
          </Grid>
          <IconButton onClick={props.onClose} className='close-icon-style'>
            <CloseIcon size='small' />
          </IconButton>
        </Grid>
        <div className='content-style'>
          <span className='bold-desc'>
            {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_DESCRIPTION')} -
          </span>
          <Grid item xs={12}>
            <span id='simple-modal-title' className='dialog-heading-style'>
              {props.planDetails.description}
            </span>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} className='table-container'>
              <Table
                className='table-style'
                aria-label='Slab Based Details'
                size='small'
              >
                <TableHead>
                  <TableRow>
                    {pricingPlanRowData?.map((data) => (
                      <StyledTableCell
                        key={data.id}
                        align={'left'}
                        className={data.style}
                      >
                        {data.value !==
                        i18next.t(
                          'DYNAMIC_FORM.PRICING_PLAN_DIALOG.PRICING_TIER'
                        ) ? (
                          i18next.t(data.value)
                        ) : (
                          <Grid container justifyContent={handleJustifyContentType}>
                            <Grid item xs={6}>
                              {i18next.t(data.value)}
                            </Grid>
                          </Grid>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(() => {
                    if (
                      props.plan.rateLimit?.slabLimitDetails &&
                      props.plan.rateLimit?.slabLimitDetails?.length !== 0
                    ) {
                      return props.plan.rateLimit?.slabLimitDetails?.map((item) => (
                        <StyledTableRow key={item.slabLevel}>
                          <StyledTableCell align='left'>
                            {item.slabLevel + 1}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {displayPricingTier(item)}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {props.plan.rateLimit?.timeUnit?.timeUnitCode ===
                            IdConstants.pricing.monthTimeUnitCode
                              ? i18next.t(
                                  'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY'
                                )
                              : i18next.t(
                                  'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DAILY'
                                )}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {displayCost(item)} {props.plan?.currency?.currencyCode}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {props.plan.rateLimit?.rateLimitType?.limitType ===
                              IdConstants.pricing.reqCountLimitType &&
                              i18next.t(
                                'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PER'
                              ) +
                                ' ' +
                                i18next.t('DYNAMIC_FORM.PRICING_PLAN.REQUEST')}
                          </StyledTableCell>
                        </StyledTableRow>
                      ));
                    } else {
                      return (
                        <StyledTableRow>
                          <StyledTableCell colSpan={pricingPlanRowData.length}>
                            <Typography className={`bold-style no-data-msg`}>
                              {i18next.t('NO_DATA_AVAILABLE')}!
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className='card-display'>
            <Card className={`card-margin details-card`}>
              <CardMedia component='img' alt='' />
              <CardContent>
                <span className='italic-style'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.BILLING_CYCLE')}
                </span>
                <ListItem className='list-style'>
                  <ListItemIcon className='list-item-icon-style'>
                    <CheckIcon className={`check-icon-style iconstyle`}></CheckIcon>
                  </ListItemIcon>
                  <ListItemText className='list-item-text'>
                    <Typography>
                      {props.plan.planBillingCycle?.billingCycleType ===
                      IdConstants.pricing.yearBillingCycleType
                        ? i18next.t(
                            'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.YEARLY'
                          )
                        : i18next.t(
                            'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY'
                          )}
                    </Typography>
                  </ListItemText>
                  <LightTooltip
                    title={i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PLAN_DETAILS_DIALOG.BILLING_CYCLE_INFO'
                    )}
                    aria-label='view'
                  >
                    <img
                      className='info-icon-cursor-styling'
                      src={Images.infoBgBlueIcon}
                      alt='Info'
                    />
                  </LightTooltip>
                </ListItem>
                <span className='italic-style'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CONSUMPTION_QUOTA')}
                </span>
                <ListItem className='list-style'>
                  <ListItemIcon className='list-item-icon-style'>
                    <CheckIcon className={`check-icon-style iconstyle`}></CheckIcon>
                  </ListItemIcon>
                  <ListItemText className='list-item-text'>
                    <Typography>
                      {props.plan.rateLimit?.timeUnit?.timeUnitCode ===
                      IdConstants.pricing.monthTimeUnitCode
                        ? i18next.t(
                            'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.MONTHLY'
                          )
                        : i18next.t(
                            'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DAILY'
                          )}
                    </Typography>
                  </ListItemText>
                  <LightTooltip
                    title={i18next.t(
                      'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.PLAN_DETAILS_DIALOG.CONSUMPTION_QUOTA_INFO'
                    )}
                    aria-label='view'
                  >
                    <img
                      className='info-icon-cursor-styling'
                      src={Images.infoBgBlueIcon}
                      alt='Info'
                    />
                  </LightTooltip>
                </ListItem>
                <span className='italic-style'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.CONSUMPTION_UNIT')}
                </span>
                <ListItem className='list-style'>
                  <ListItemIcon className='list-item-icon-style'>
                    <CheckIcon className={`check-icon-style iconstyle`}></CheckIcon>
                  </ListItemIcon>
                  <ListItemText className='list-item-text'>
                    <Typography>
                      {props.plan.rateLimit?.rateLimitType?.limitType ===
                        IdConstants.pricing.reqCountLimitType &&
                        i18next.t('DYNAMIC_FORM.PRICING_PLAN.REQUEST')}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </CardContent>
            </Card>
            <Card className='details-card'>
              <CardMedia component='img' alt='' />
              <CardContent>
                {props?.plan?.planBenefits && props?.plan?.planBenefits.length && (
                  <>
                    <span className='italic-style'>
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.PLAN_BENEFITS')}
                    </span>
                    {props?.plan?.planBenefits.map((planBenefit) => (
                      <ListItem
                        className='list-style'
                        key={planBenefit?.planBenefitId}
                      >
                        <ListItemIcon className='list-item-icon-style'>
                          <CheckIcon
                            className={`check-icon-style iconstyle`}
                          ></CheckIcon>
                        </ListItemIcon>
                        <ListItemText className='list-item-text'>
                          <LightTooltip title={planBenefit.benefitDescription}>
                            <Typography className='plan-benefit-item'>
                              {planBenefit.benefitDescription}
                            </Typography>
                          </LightTooltip>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </div>
      </div>
    </Modal>
  );
}

export default PlanDetailsDialog;
