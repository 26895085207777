const initialState = {
  serviceDefinitionList: [],
  apiGuide: {}
};
const ApiGuideReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SERVICE_DEFINITION_LIST_PREVIEW':
      return Object.assign({}, state, { serviceDefinitionList: action.payload });
    case 'SET_API_GUIDE_PREVIEW':
      return Object.assign({}, state, { apiGuide: action.payload });
    default:
      return state;
  }
};

export default ApiGuideReducer_Preview;
