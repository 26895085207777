import React from 'react';
import Images from '../../../assets/img';
import i18next from 'i18next';
import './index.scss';
import { useHistory } from 'react-router-dom';
import CustomToolTip from '../../../components/Shared/custom-tooltip';

const Breadcrumb = ({ items, className }) => {
  const history = useHistory();

  if (!items) return null;
  if (items && items.length < 1) return null;

  const customClassName = className ? `breadcrumb ${className}` : 'breadcrumb';

  const renderContent = (item, index) => {
    if (item.to) {
      return (
        <a
          itemProp='item'
          className={`bread-crumb__container__items ${
            index === items.length - 1 && 'disabled'
          }`}
          href={item.to}
          onClick={(event) => {
            if (item.from && item.from === '/dashboard/my-products/') {
              history.goBack();
              event.preventDefault();
              return false;
            }
            if (index === items.length - 1) {
              event.preventDefault();
              return false;
            }
          }}
        >
          <span className='' itemProp='name' id={item?.title}>
            {item.title}
          </span>
        </a>
      );
    }

    return (
      <CustomToolTip
        id={item?.title}
        tooltipData={item?.title}
        width='dynamic'
        className='breadcrumb__item-list-element-tooltip'
      >
        <div
          itemProp='item'
          className={`${
            (index === items.length - 1 || !item.to) && 'text-ellipsis disabled'
          }`}
          href={item.to ? item.to : '#'}
          onClick={(event) => {
            if (index === items.length - 1 || !item.to) {
              if (index !== items.length - 1) {
                item.handler?.();
              }
              event.preventDefault();
              return false;
            }
          }}
          id={item?.title}
        >
          <span itemProp='name'>{item.title}</span>
        </div>
      </CustomToolTip>
    );
  };

  return (
    <div
      className={customClassName}
      itemScope={true}
      itemType='https://schema.org/BreadcrumbList'
    >
      <div
        itemProp='itemListElement'
        itemScope={true}
        itemType='https://schema.org/ListItem'
        className='breadcrumb__item-list-element'
      >
        <a className='bread-crumb__container__items' itemProp='item' href='/'>
          <span itemProp='name'>{i18next.t('DYNAMIC_FORM.BREADCRUMB.HOME')}</span>
        </a>
        <meta itemProp='position' content='1' />
      </div>
      {items.map((item, index) => (
        <>
          {item.title && (
            <img
              src={Images.forwardIcon}
              alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.ARROW')}
            />
          )}
          <div
            itemProp='itemListElement'
            itemScope={true}
            itemType='https://schema.org/ListItem'
            className='breadcrumb__item-list-element'
          >
            {renderContent(item, index)}
            <meta itemProp='position' content={index + 2} />
          </div>
        </>
      ))}
    </div>
  );
};

export default Breadcrumb;
