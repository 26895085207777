import React from 'react';
import PropTypes from 'prop-types';

import Button from './button';
import Tooltip from '../../../components/Shared/Tooltip';

const IconButton = ({ iconTitle, className, iconCss, isIcon, ...props }) => {
  let cssName = 'a-button a-button--integrated -without-label';
  if (className) {
    cssName += ` ${className}`;
  }

  return (
    <Button {...props} className={cssName}>
      {isIcon === true ? (
        <i
          className={`a-icon a-button__icon ${iconCss}`}
          title={iconTitle}
          width={24}
          height={24}
        />
      ) : (
        <Tooltip title={iconTitle}>
          <img className={iconCss} src={props.src} alt={iconTitle} />
        </Tooltip>
      )}
    </Button>
  );
};

IconButton.propTypes = { className: PropTypes.string };
IconButton.defaultProps = { className: '' };

export default IconButton;
