import axios from 'axios';
import * as Constants from './configuration';
import { checkUserAgreementAcceptance, regenerateAAATokens } from './LoginAction';
import i18next from 'i18next';
import store from '../store';
import errorConstants from '../data/errorConstants';
import idConstants from '../data/idConstants';

export function saveUserDetails(reqObject, dispatch) {
  const userPayload = {
    data: reqObject,
    metaInfo: {
      b2bIdentifier: '',
      userId: localStorage.getItem('userId'),
      typeOfUser: idConstants.typeOfUsers.b2b.toUpperCase(),
      role: Constants.AAA_CONFIG.SELLER_ROLE
    }
  };

  return axios({
    method: 'POST',
    url: Constants.USER_SERVICE_CAPABILITIES_ACTION.REGISTER_USER,
    data: userPayload,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      dispatch({
        type: 'USER_COMPANY_STATUS',
        payload: response?.data
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: 'USER_REGISTRATION_ERROR_ALERT',
        error: i18next.t('INTERNAL_SELLER.TOAST_MESSAGES.USER_REGISTRATION_ERROR')
      });
      return error?.response;
    });
}

const preparePayloadForConsent = (consentData) => {
  const consentDocMetaPayload = [];
  consentData?.forEach((consentItem) => {
    const consentDTO = {
      docMetaInfoId: consentItem.docMetaInfoId,
      documentTypeId: consentItem.documentTypeId,
      userConsentNeeded: consentItem.userConsentNeeded,
      accepted: true
    };
    consentDocMetaPayload.push(consentDTO);
  });
  const consentPayload = {
    data: {
      docMetaInfo: consentDocMetaPayload,
      retrieveCompanyDetails: false
    },
    metaInfo: {
      b2bIdentifier: '',
      userId: localStorage.getItem('userId'),
      typeOfUser: idConstants.typeOfUsers.b2b.toUpperCase(),
      role: Constants.AAA_CONFIG.SELLER_ROLE
    }
  };
  return consentPayload;
};

export const submitTermsConsent = () => async (dispatch) => {
  if (store.getState().loginReducer.checkUserAgreement.length === 0) {
    dispatch(checkUserAgreementAcceptance()).then((response) => {
      dispatch(callConsentApi(preparePayloadForConsent(response)));
    });
  } else {
    await dispatch(
      callConsentApi(
        preparePayloadForConsent(store.getState().loginReducer.checkUserAgreement)
      )
    );
  }
};

export const callConsentApi = (payload) => async (dispatch) => {
  await axios
    .post(`${Constants.REGISTER_ACTION.ACCEPT_USER_COMPANY_TERMS}`, payload, {
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(async (response) => {
      await dispatch(setCompanyId());
    })
    .catch(async (error) => {
      if (error?.response?.data?.error === errorConstants.consentAlreadyAvailable) {
        await dispatch(setCompanyId());
      } else
        dispatch({
          type: 'USER_REGISTRATION_ERROR_ALERT',
          error: i18next.t('INTERNAL_SELLER.TOAST_MESSAGES.USER_REGISTRATION_ERROR')
        });
    });
};

export function setCompanyId() {
  return async (dispatch) =>
    await axios({
      method: 'POST',
      url: Constants.REGISTER_ACTION.CREATE_COMPANY,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async (response) => {
        await dispatch(regenerateAAATokens());
        dispatch({
          type: 'USER_REGISTRATION_SUCCESS_ALERT',
          payload: i18next.t(
            'INTERNAL_SELLER.TOAST_MESSAGES.USER_REGISTERED_SUCCESSFULLY'
          )
        });
        dispatch({
          type: 'USER_COMPANY_STATUS',
          payload: response?.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'USER_REGISTRATION_ERROR_ALERT',
          error: i18next.t('INTERNAL_SELLER.TOAST_MESSAGES.USER_REGISTRATION_ERROR')
        });
      });
}

export function setUserDetails(obj) {
  return (dispatch) =>
    dispatch({
      type: 'USER_DETAILS',
      payload: obj
    });
}
