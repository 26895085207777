import React, { Component } from 'react';
import Card from '../../Shared/Utils/card';
import DisplayOptions from '../../../data/displayOptions';
import IdConstants from '../../../data/idConstants';
import './index.scss';

export default class APIListView extends Component {
  state = {
    view: false,
    selectedCard: ''
  };

  handlePublish = (data) => {
    this.props.handlePublish(data);
  };

  clickedHandle = (card) => {
    if (
      !(
        card.status.description === 'READY_FOR_PUBLISH' ||
        card.status.description === 'PUBLISH_IN_PROGRESS'
      )
    ) {
      this.setState(
        {
          view: true,
          selectedCard: card
        },
        () => {
          if (this.state.selectedCard.status.description !== 'DRAFTED') {
            this.props.updateInfo(this.props.info, 'reset', false);
            this.props.updateInfo(this.props.info, 'isView', true);
          } else {
            this.props.updateInfo(this.props.info, 'reset', false);
            this.props.updateInfo(this.props.info, 'isEdit', true);
            this.props.updateInfo(this.props.info, 'showPopup', true);
          }
          this.props?.resetPublishInfo();
        }
      );
    }
  };

  isViewableFlag = () => {
    return this.state.selectedCard.status.description !== 'DRAFTED' ? true : false;
  };

  render() {
    if (this.state.view) {
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[this.props.info.previousSession].url
        }/addapi/step1`,
        state: {
          ...this.props.location.state,
          isEdit: true,
          productId: this.state.selectedCard.productId,
          isView: this.isViewableFlag(),
          publishData: null,
          submitted: false
        },
        key: 'step1'
      });
    }

    return (
      <React.Fragment>
        <div className='api-list-view'>
          <div className='api-list-view_box'>
            {this.props.data.map((card) => (
              <div className='api-list-view_box_card' key={card.id}>
                <Card
                  data={card}
                  handlePublish={this.handlePublish}
                  info={this.props.info}
                  updateInfo={this.props.updateInfo}
                  updatePrevSession={this.props.updatePrevSession}
                  resetEditableDetails={this.props.resetEditableDetails}
                  handleDeleteProduct={this.props.handleDeleteAPI}
                  location={this.props.location}
                  history={this.props.history}
                  resetPublishInfo={this.props.resetPublishInfo}
                  getProductQuantity={this.props.getProductQuantity}
                  clickedHandle={() => this.clickedHandle(card)}
                  handleUpdateProducts={this.props.handleUpdateProducts}
                />
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
