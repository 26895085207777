import idConstants from '../data/idConstants';

const displayOptions = [
  {
    productType: 'API',
    categoryId: idConstants.api.categoryId,
    filters: [
      {
        id: 'All',
        title: 'All',
        status: 'ALL',
        count: 0,
        searchCount: 0,
        color: '#00000',
        backGroundColor: '#fc9c9c',
        index: 0,
        url: `/dashboard/myproducts/myapi/all`,
        key: `myapi-all`
      },
      {
        id: 'ff841ba0-2141-4c57-b26a-d5af00e03782',
        title: 'Published',
        status: 'PUBLISHED',
        count: 0,
        searchCount: 0,
        color:
          'linear-gradient(90deg, rgba(0,168,176,1) 0%, rgba(120,190,32,1) 100%)',
        backGroundColor: '#e3f9e3',
        index: 1,
        url: `/dashboard/myproducts/myapi/published`,
        key: `myapi-published`
      },
      {
        id: 'b3ea7ad1-2468-417d-8051-d31dd227fbe8',
        title: 'Publish Pending',
        status: 'TENANT_PUBLISH',
        count: 0,
        searchCount: 0,
        color: '#999',
        backGroundColor: '#fc9c9c',
        index: 2,
        url: `/dashboard/myproducts/myapi/publish-pending`,
        key: `myapi-publish-pending`
      },
      {
        id: '6a8f9734-cace-4469-a8d5-342d2b8c9ba9',
        title: 'Approved',
        status: 'APPROVED',
        count: 0,
        searchCount: 0,
        color: '#03a76a',
        backGroundColor: '#e3f9e3',
        index: 3,
        url: `/dashboard/myproducts/myapi/approved`,
        key: `myapi-approved`
      },
      {
        id: 'd5f25fa8-7efa-41f5-9e05-17dd1a9428ff',
        title: 'Rejected',
        status: 'REJECTED',
        count: 0,
        searchCount: 0,
        color: 'red',
        backGroundColor: '#fc9c9c',
        index: 4,
        url: `/dashboard/myproducts/myapi/rejected`,
        key: `myapi-rejected`
      },
      {
        id: '6d78da39-181d-4ead-b5ff-b11559cd171a',
        title: 'Submitted',
        status: 'SUBMITTED',
        count: 0,
        searchCount: 0,
        color: '#fdaf17',
        backGroundColor: '#fee6cd',
        index: 5,
        url: `/dashboard/myproducts/myapi/submitted`,
        key: `myapi-submitted`
      },
      {
        id: '742cffe3-4754-4479-9369-f72368674bb9',
        title: 'In Draft',
        status: 'DRAFTED',
        count: 0,
        searchCount: 0,
        color: '#999',
        backGroundColor: '#fc9c9c',
        index: 6,
        url: `/dashboard/myproducts/myapi/indraft`,
        key: `myapi-indraft`
      }
    ]
  },
  {
    productType: 'Dynamic Products',
    filters: [
      {
        id: 'All',
        title: 'All',
        status: 'ALL',
        count: 1,
        searchCount: 0,
        color: '#00000',
        backGroundColor: '#fc9c9c',
        index: 0
      },
      {
        id: 'ff841ba0-2141-4c57-b26a-d5af00e03782',
        title: 'Published',
        status: 'PUBLISHED',
        count: 0,
        searchCount: 0,
        color:
          'linear-gradient(90deg, rgba(0,168,176,1) 0%, rgba(120,190,32,1) 100%)',
        backGroundColor: '#e3f9e3',
        index: 1
      },
      {
        id: 'b3ea7ad1-2468-417d-8051-d31dd227fbe8',
        title: 'Publish Pending',
        status: 'TENANT_PUBLISH',
        count: 0,
        searchCount: 0,
        color: '#999',
        backGroundColor: '#fc9c9c',
        index: 2
      },
      {
        id: '6a8f9734-cace-4469-a8d5-342d2b8c9ba9',
        title: 'Approved',
        status: 'APPROVED',
        count: 0,
        searchCount: 0,
        color: '#03a76a',
        backGroundColor: '#e3f9e3',
        index: 3
      },
      {
        id: 'd5f25fa8-7efa-41f5-9e05-17dd1a9428ff',
        title: 'Rejected',
        status: 'REJECTED',
        count: 0,
        searchCount: 0,
        color: 'red',
        backGroundColor: '#fc9c9c',
        index: 4
      },
      {
        id: '6d78da39-181d-4ead-b5ff-b11559cd171a',
        title: 'Submitted',
        status: 'SUBMITTED',
        count: 0,
        searchCount: 0,
        color: '#fdaf17',
        backGroundColor: '#fee6cd',
        index: 5
      },
      {
        id: '742cffe3-4754-4479-9369-f72368674bb9',
        title: 'In Draft',
        status: 'DRAFTED',
        count: 0,
        searchCount: 0,
        color: '#999',
        backGroundColor: '#fc9c9c',
        index: 6
      }
    ]
  }
];

export default displayOptions;
