import axios from 'axios';
import * as Constants from './configuration';
import ToastMessages from '../data/toastMessages';

//can be checked in future whether to add it to above common function or to remove this block
export function uploadCheque(params) {
  const bodyFormData = new FormData();
  bodyFormData.append('data', params.data);
  bodyFormData.append('documentTypeId', params.documentTypeId);

  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        Constants.REGISTER_DOCUMENT.FILE + params.companyId + '?documentType=CHEQUE',
      data: bodyFormData,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'CHEQUE_DOCUMENT',
          payload: response.data
        });
        dispatch({
          type: 'SUCCESS',
          success: ToastMessages.docUpload.msg
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'UPLOAD_CHEQUE_ERROR',
          payload: ToastMessages.docUploadErr.msg
        });
        return error.response;
      });
}

export function documentDownload(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          Constants.REGISTER_DOCUMENT.DOWNLOAD_DOCUMENTS +
            params.fileId +
            '?role=ADMIN&documentTypeId=' +
            params.docId,
          {
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json'
            },
            data: {}
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    });
}
