import React, { Component } from 'react';
import i18next from 'i18next';
import { DialogBox } from '../../../../../msreact-kit/packages/html-components';
import './index.scss';

class InformationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.onClose();
  };

  handleOk = (event) => {
    this.setState({ open: false });
    this.props.okBtnAction(event);
    this.props.onClose(event);
  };

  render() {
    return (
      <div className={`modal ${this.state.open ? 'modal__open' : 'modal__close'}`}>
        <DialogBox
          cssName='modal__information'
          primaryLabel={
            this.props?.primaryLabel ?? i18next.t('DYNAMIC_FORM.SHARED.BUTTON.OK')
          }
          handleCancel={this.handleClose}
          title={this.props.heading}
          handleConfirm={this.handleOk}
          secondaryLabel={this.props.secondaryLabel}
          isSecondaryBtn={this.props.secondaryLabel ? true : false}
          isTitleIconRequired={this.props.isTitleIconRequired}
          handleSecondaryBtnClick={this.props.handleSecondaryBtnClick}
          isCloseInfoIcon={this.props.isCloseIcon}
        >
          <div className='modal__dialog__body'>
            <p className='-size-m'>{this.props.modalContent}</p>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default InformationModal;
