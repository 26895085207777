import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getDynamicFormData,
  validateProductUniqueness,
  saveFormBasicInfo,
  saveFormAdditionalInfo,
  handleResetForm,
  uploadFile,
  getProductDocsListById,
  updateFormAdditionalInfo
} from '../../../actions/api/hardwareAction';
import { getProductDetailedInfo } from '../../../actions/api/SolutionAction';
import {
  getProductQuantity,
  stockAvailApi
} from '../../../actions/api/stockAvailAction';
import {
  TextInput,
  NumberInput,
  Breadcrumb,
  NavigationButtons,
  ImageUpload,
  PreviewModal
} from '../../../msreact-kit/packages/html-components';
import {
  createPricingPlan,
  getApiPlans
} from '../../../actions/api/pricing-plan-action';
import DynamicFieldConstants from '../../../shared/constants/dynamic-field-constants';
import Footer from '../../../components/BaseLayout/footer';
import IdConstants from '../../../data/idConstants';
import { FieldConstants } from '../../../shared/constants/field-constants';
import useHandleLoader from '../../../msreact-kit/hooks/useHandleLoader';
import Loader from '../../../components/Shared/Utils/Loader';
import { parseDocValidation } from '../../../shared/shared-function/parse-file-type';
import { retrieveUserCompanyStatus } from '../../../actions/CompanyAction';

import './index.scss';

const AutoMotiveForm = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isView = location?.state?.isView;
  const isEdit = location?.state?.isEdit;
  const categoryId = location?.state?.productCategoryId;
  const productId = location?.state?.productId;
  const [handleLoader] = useHandleLoader();
  const [formData, setFormData] = useState('');
  const [formValues, setFormValues] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const [event, setEvent] = useState();
  const [data, setData] = useState();
  const [imageData, setImageData] = useState();

  const {
    loader,
    prodNameError,
    prodDetailedInfo,
    productBasicInfo,
    productQuantityDetails,
    pricingPlans,
    userCompanyStatus
  } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader,
      prodNameError: state.SolutionReducer.prodNameError,
      prodDetailedInfo: state.SolutionReducer.prodDetailedInfo,
      productBasicInfo: state.SolutionReducer.prodBasicInfo,
      productQuantityDetails: state.StockAvailReducer.productQuantityDetails,
      pricingPlans: state.ProductPricingPlanReducer.pricingPlans,
      userCompanyStatus: state.CompanyDetailsReducer.userCompanyStatus
    };
  });
  useEffect(() => {
    if (prodNameError && prodNameError.error) {
      setErrors((prevState) => {
        return {
          ...prevState,
          productName: prodNameError.message
        };
      });
    }
  }, [prodNameError]);

  const handleClose = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!userCompanyStatus) {
      dispatch(retrieveUserCompanyStatus());
    }
    handleLoader();
    dispatch(handleResetForm());
    dispatch(getDynamicFormData(IdConstants.automotiveSpares.categoryId)).then(
      (res) => {
        setFormData(res);
        if (location?.state?.isEdit || location?.state?.isView) {
          dispatch(getProductDetailedInfo(productId, categoryId));
          dispatch(getProductQuantity(productId));
          dispatch(getApiPlans(productId));
          dispatch(
            getProductDocsListById(productId, IdConstants.documentType.thumbnailImg)
          ).then((res) => {
            setImageData(res);
          });
        }
      }
    );
  }, []);

  useEffect(() => {
    if (isView || isEdit) {
      setFormValues({
        quantity: productQuantityDetails?.quantity,
        unitCost: prodDetailedInfo?.unitCost,
        productName: productBasicInfo?.productName,
        partNumber: prodDetailedInfo?.productDetail?.genericInformation?.partNumber,
        unitPrice: prodDetailedInfo?.unitPrice,
        attachment: imageData
      });
    }
  }, [productQuantityDetails, prodDetailedInfo]);

  const handleInputChange = (event) => {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [event.name]: event.value
      };
    });
  };

  const blurValue = (event) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [event.name]: event.error
      };
    });

    if (!event?.value || !event.value.toString() || event.error) return;

    if (event.name === FieldConstants.productName && !isEdit) {
      checkProductUniqueness(event.value);
    }
  };

  const checkProductUniqueness = (name) => {
    if (name?.trim().length > 0) {
      dispatch(
        validateProductUniqueness(name, IdConstants.automotiveSpares.categoryId)
      );
    }
  };

  const getProductName = () => {
    if (formValues && formValues[FieldConstants.productName]) {
      return formValues[FieldConstants.productName];
    }
    return '';
  };

  const reqObj = {
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.automotiveSpares.categoryId
      },
      productName: formValues[FieldConstants.productName]
    }
  };

  const additionalReqObj = {
    productDetailedInfo: {
      unitCost: formValues[FieldConstants.unitCost],
      unitPrice: formValues[FieldConstants.unitPrice],
      quantity: formValues[FieldConstants.quantity],
      productDetail: {
        genericInformation: {
          partNumber: formValues[FieldConstants.partNumber]
        }
      }
    },
    prodBasicInfo: {
      productCategory: {
        categoryId: IdConstants.automotiveSpares.categoryId
      }
    }
  };

  const handleNextStep = () => {
    if (productBasicInfo?.productId) {
      dispatch(
        updateFormAdditionalInfo(additionalReqObj, productBasicInfo.productId)
      );
      const payload = {
        productId: productBasicInfo.productId,
        quantity: formValues[FieldConstants.quantity],
        type: 'REFURBISH',
        orderId: null
      };
      dispatch(stockAvailApi(payload));

      const docTypeId = data?.options[0]?.documentTypeId;
      if (event?.value[0]) {
        dispatch(
          uploadFile(
            event?.value[0],
            productBasicInfo?.productId,
            docTypeId,
            IdConstants.automotiveSpares.categoryId
          )
        );
      }
      dispatch(getProductDocsListById(productBasicInfo?.productId, docTypeId));
      handlePublish(productBasicInfo, formValues);
    } else {
      dispatch(saveFormBasicInfo(reqObj, userCompanyStatus?.companyId)).then(
        (res) => {
          if (res) {
            dispatch(
              saveFormAdditionalInfo(
                additionalReqObj,
                res.productId,
                userCompanyStatus?.companyId
              )
            );

            const payload = {
              productId: res.productId,
              quantity: formValues[FieldConstants.quantity],
              type: 'REFURBISH',
              orderId: null
            };
            dispatch(stockAvailApi(payload));

            if (pricingPlans?.length === 0) {
              const userDetails = {
                userId: localStorage.getItem('userId'),
                typeOfUser: IdConstants.typeOfUsers.b2b,
                b2bIdentifier: res?.companyNo
              };
              dispatch(
                createPricingPlan(
                  {
                    isQuotationFlow: true,
                    planCost: Number(formValues[FieldConstants.unitCost]),
                    categoryId: IdConstants.automotiveSpares.categoryId,
                    currentPlan: {
                      userDetails
                    }
                  },
                  res.productId,
                  IdConstants.automotiveSpares.categoryId
                )
              );
            }

            const docTypeId = data?.options[0]?.documentTypeId;
            if (event?.value[0]) {
              dispatch(
                uploadFile(
                  event?.value[0],
                  res?.productId,
                  docTypeId,
                  IdConstants.automotiveSpares.categoryId
                )
              );
            }
            dispatch(getProductDocsListById(res?.productId, docTypeId));
            handlePublish(res, formValues);
          }
        }
      );
    }
  };

  const handlePublish = (res, formValues) => {
    history.push({
      pathname: `/add-product`,
      state: {
        basicInfo: res,
        prodDetailedInfo: formValues,
        categoryId: formData.category
      }
    });
  };

  const removeInitialText = (inputString) => {
    if (inputString?.length) {
      const regex = /\b(?:add|enter|upload|more)\b/gi;
      return inputString.replace(regex, '');
    }
  };

  const handleFileChange = (event, data) => {
    setEvent(event);
    setData(data);
    const file = event?.value[0];
    const blob = new Blob([file], { type: file.type });
    const blobUrl = URL.createObjectURL(blob);
    const imgObj = {
      blobFileUrl: blobUrl,
      documentSize: file?.size,
      documentName: file?.name
    };
    const imgObjArr = [];
    imgObjArr.push(imgObj);
    setImage(imgObjArr);
  };

  const handlePreviewModal = (image) => {
    setShowPreviewModal((prevState) => !prevState);
    setPreviewData(image);
  };

  const handleDeleteImage = () => {
    setImage(null);
  };

  const handleAttributeType = (type, data) => {
    switch (type) {
      case DynamicFieldConstants.TEXT:
        return (
          <div className='individual-onboarding__container__home-wrapper__form'>
            {isView ? (
              <TextInput
                name={data?.name}
                value={formValues[data.name]}
                displayName={removeInitialText(data?.displayName)}
              />
            ) : (
              <TextInput
                placeHolder={data?.placeHolder}
                displayName={data?.displayName}
                handleBlur={blurValue}
                name={data?.name}
                value={formValues[data.name]}
                handleChange={handleInputChange}
                validation={JSON.parse(data?.validation)}
                error={errors[data.name]}
              />
            )}
          </div>
        );
      case DynamicFieldConstants.NUMBER:
        return (
          <div className='individual-onboarding__container__home-wrapper__form'>
            {isView ? (
              <NumberInput
                displayName={removeInitialText(data?.displayName)}
                name={data?.name}
                value={formValues[data.name]}
              />
            ) : (
              <NumberInput
                placeHolder={data?.placeHolder}
                displayName={data?.displayName}
                handleBlur={blurValue}
                name={data?.name}
                value={formValues[data.name]}
                handleChange={handleInputChange}
                validation={JSON.parse(data?.validation)}
                error={errors[data.name]}
                description={data?.description}
              />
            )}
          </div>
        );
      case DynamicFieldConstants.FILE:
        const validation = parseDocValidation(data?.options);
        return (
          <div className='individual-onboarding__container__home-wrapper__form'>
            {isView ? (
              <ImageUpload
                displayName={removeInitialText(data?.displayName)}
                name={data?.name}
                value={formValues[data.name]}
                isHideIcon={true}
                validation={validation}
                isView={true}
              />
            ) : (
              <ImageUpload
                placeHolder={data?.placeHolder}
                displayName={data?.displayName}
                name={data?.name}
                value={image}
                handleChange={(event) => handleFileChange(event, data)}
                error={errors[data.name]}
                description={data?.description}
                handleDeleteFile={handleDeleteImage}
                handlePreviewElement={handlePreviewModal}
                validation={validation}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const checkFormValidity = () => {
    if (!formData) return true;

    for (const attribute of filteredAttributes) {
      const parseValidation = JSON.parse(attribute?.validation);
      if (
        (!formValues[attribute.name] &&
          parseValidation?.rule?.required?.isRequired === true) ||
        errors[attribute.name]
      ) {
        return false;
      }
    }
    return true;
  };

  const getFormData = () => {
    const fieldsData = [];

    formData?.steps?.forEach((element) => {
      if (element?.attributes && element?.attributes?.length > 0) {
        fieldsData.push(...element.attributes);
      }
      if (element?.sections && element?.sections?.length > 0) {
        element.sections.forEach((sectionAttributes) => {
          if (
            sectionAttributes?.attributes &&
            sectionAttributes?.attributes?.length > 0
          ) {
            fieldsData.push(...sectionAttributes?.attributes);
          }
          if (sectionAttributes?.groups && sectionAttributes?.groups?.length > 0) {
            sectionAttributes.groups.forEach((groupAttributes) => {
              if (
                groupAttributes?.attributes &&
                groupAttributes?.attributes?.length > 0
              ) {
                fieldsData.push(...groupAttributes.attributes);
              }
            });
          }
        });
      }
    });
    return fieldsData;
  };

  const filteredAttributes = getFormData().filter((attribute) =>
    [
      'productName',
      'partNumber',
      'quantity',
      'unitPrice',
      'unitCost',
      'attachment'
    ].includes(attribute.name)
  );

  return (
    <React.Fragment>
      {loader && <Loader />}
      <div className='individual-onboarding'>
        <div className='individual-onboarding__breadcrumb'>
          <Breadcrumb
            items={[
              {
                title: i18next.t('AUTOMOTIVE.AUTOMOTIVE_SPARES'),
                from: '/dashboard/my-products/',
                to: '/'
              },
              {
                title: getProductName()
              }
            ]}
            className='bread-crumb__container__arrow'
          />
        </div>
        <div className='individual-onboarding__container'>
          <div className='individual-onboarding__container__form'>
            <h1 className='individual-onboarding__container__form__header-label'>
              {isView
                ? i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.PRODUCT_DETAILS')
                : i18next.t('AUTOMOTIVE.ADD_INDIVIDUAL_AUTOMOTIVE_SPARE')}
            </h1>
            <div className='individual-onboarding__container__form__home-wrapper__form__wrapper'>
              {filteredAttributes
                ?.sort((a, b) => a.displayOrder - b.displayOrder)
                .map((item) => (
                  <div
                    className='individual-onboarding__container__form__home-wrapper__form'
                    key={item.id}
                  >
                    {handleAttributeType(item?.attributeType, item)}
                  </div>
                ))}
            </div>
          </div>
          <div className='individual-onboarding__container__navigation'>
            {isView ? (
              <NavigationButtons
                isShowPrevStepBtn={false}
                handleClose={handleClose}
                handleNext={handleClose}
                nextStepLabel={i18next.t('DYNAMIC_FORM.LABEL.DONE')}
              />
            ) : (
              <NavigationButtons
                isShowPrevStepBtn={false}
                handleClose={handleClose}
                handleNext={handleNextStep}
                nextStepDisabled={!checkFormValidity()}
                nextStepLabel={i18next.t('DYNAMIC_FORM.LABEL.NEXT')}
              />
            )}
          </div>
        </div>
        <div className='individual-onboarding__footer'>
          <Footer />
        </div>

        {showPreviewModal && previewData && (
          <PreviewModal
            mediaUrl={previewData.name}
            handlePreviewModal={handlePreviewModal}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default AutoMotiveForm;
