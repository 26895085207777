import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  filled: {
    background: '#005691',
    color: 'white',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#0a74bd'
    },
    '&:disabled': {
      background: 'lightGray',
      color: 'white'
    }
  },
  outlined: {
    border: '1px solid #005691',
    color: '#005691',
    borderRadius: '0px',
    '&:hover': {
      color: '#0a74bd'
    },
    '&:disabled': {
      border: '1px solid darkGray',
      color: 'darkGray'
    }
  },
  label: {
    textTransform: 'capitalize'
  },
  searchIcon: {
    height: 40,
    borderRadius: 3
  }
});

export default function StylledButton(props) {
  const classes = useStyles();

  let cssName;
  if (props.isSearchIcon === true) {
    cssName = classes.searchIcon;
  } else if (props.className) {
    cssName = props.className;
  } else {
    cssName = '';
  }

  return (
    <div>
      {(() => {
        if (props.variant === 'outlined') {
          return (
            <Button
              style={props.style}
              classes={{ root: classes.outlined, label: classes.label }}
              disabled={props.disabled}
              onClick={props.onClick}
            >
              {' '}
              {props.label}{' '}
            </Button>
          );
        } else {
          return (
            <Button
              style={props.style}
              className={cssName}
              classes={{ root: classes.filled, label: classes.label }}
              disabled={props.disabled}
              onClick={props.onClick}
            >
              {' '}
              {props.label}{' '}
            </Button>
          );
        }
      })()}
    </div>
  );
}
