import React from 'react';
import Footer from '../../../components/BaseLayout/footer';
import Header from '../../../msreact-kit/html-components/header';
import './index.scss';

const LayoutContainer = (props) => {
  return (
    <div className='document-body-container'>
      <Header {...props} />
      <div className='document-body'>{props.children}</div>
      <Footer />
    </div>
  );
};

LayoutContainer.propTypes = {};

export default LayoutContainer;
