import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard';
// Commenting as per MS-16579
// import Faq from '../components/faq';
import ApiAlertsContainer from '../containers/api/apiAlertsContainer';
import ApiStatisticsContainer from '../containers/api/api-statistics-container';
import ApiThrottlingContainer from '../containers/api/apiThrottlingContainer';
import LoginContainer from '../containers/loginContainer';
import MySolutionContainer from '../containers/api/my-solution-container';
import SidebarContainer from '../containers/SidebarContainer';
import AddSolutionContainer from '../containers/api/add-solution-container';
import DisplayOptions from '../data/displayOptions';
import StepperNavigation from '../data/stepperNavigation';
import MyProducts from '../components/MyProducts';
import IdConstants from '../data/idConstants';
import AuthCallback from '../components/callbacks/AuthCallback';
import Profile from '../components/view-profile';
import FooterContainer from '../containers/footer';
import * as Constants from '../actions/configuration';
import Home from '../pages/home';
import ViewForm from '../pages/view-form';
import OrderSummary from '../components/order-management/order-summary-page';
import UnifiedCategoryContainer from '../containers/UnifiedCategory/UnifiedCategoryContainer';
import OrderListing from '../components/order-management/order-listing';
import OrderHistory from '../components/order-management/order-history';
import ListCategory from '../pages/list-category';
import QuotationListing from '../components/quotations/quotations-listing';
import QuotationSummary from '../components/quotations/QuotationSummary';
import AutoMotiveForm from '../containers/auto-spares-container/individual-onboarding/AutoMotiveForm';
import MultipleTenantSelection from '../containers/multiple-tenant-selection-container';

export const ParentRoutes = () => {
  return (
    <>
      <Switch>
        {/* Commented for future use */}
        {/* <Route path='/category-list' exact component={ListCategory} /> */}
        <Route path='/dashboard' exact component={SidebarContainer} />
        <Route path='/category-list' exact component={ListCategory} />
        <Route path='/product/create' exact component={Home} />
        <Route path='/product/edit' exact component={Home} />
        <Route path='/product/view' exact component={ViewForm} />
        <Route
          path='/automotive-spares/individual-form'
          exact
          component={AutoMotiveForm}
        />
        <Route path='/add-product' exact component={MultipleTenantSelection} />
        <Route path='/corporate-information' exact component={FooterContainer} />
        <Route path='/legal-notice' exact component={FooterContainer} />
        <Route path='/data-protection-policy' exact component={FooterContainer} />
        <Route path='/terms-of-use' exact component={FooterContainer} />
        <Route path='/profile' exact component={SidebarContainer} />
        <Route path='/dashboard/*' exact component={SidebarContainer} />
        <Route path='/logout' exact component={LoginContainer} />
        <Route path='/login' exact component={LoginContainer} />
        <Route
          path={'/' + Constants.CALLBACK_PARAM.AAA_CALLBACK}
          exact
          component={AuthCallback}
        />
        <Route
          path={'/' + Constants.CALLBACK_PARAM.DCS_CALLBACK}
          exact
          component={AuthCallback}
        />
        <Route
          path={'/' + Constants.CALLBACK_PARAM.SWITCH_CALLBACK}
          exact
          component={AuthCallback}
        />
        <Route
          path={'/' + Constants.CALLBACK_PARAM.DEREGISTRATION_CALLBACK}
          exact
          component={AuthCallback}
        />
        <Route
          path={'/' + Constants.CALLBACK_PARAM.FEDERATED_CALLBACK}
          exact
          component={AuthCallback}
        />
        <Redirect from='/' to='/login' />
      </Switch>
    </>
  );
};

export const DashboardRoutes = (props) => {
  return (
    <>
      <Route path='/dashboard' exact component={Dashboard} />
      <Route path='/dashboard/order-management' exact component={OrderListing} />
      <Route path='/dashboard/order-history' exact component={OrderHistory} />
      <Route
        path='/dashboard/order-management/order-details/:productOrderDetailId'
        exact
        component={OrderSummary}
      />
      <Route
        path='/dashboard/proposal-request/quotation-summary'
        exact
        component={QuotationSummary}
      />
      <Route path='/dashboard/proposal-request' exact component={QuotationListing} />
      {/* Changing as per MS-16579 to hide FAQ page */}
      <Route path='/dashboard/faq'>
        <Redirect to='/dashboard' exact component={Dashboard} />
      </Route>
      <Route path='/profile' exact component={Profile} />
      <Route
        path='/dashboard/myproducts/myapi'
        exact
        component={MySolutionContainer}
      />
      <Route path='/dashboard/myproducts' exact component={MyProducts} />
      <Route
        exact
        path='/dashboard/my-products/:name'
        component={UnifiedCategoryContainer}
      />
      {StepperNavigation.map((stepper) => (
        <Route
          path={`${
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[props.currentState].url
          }/addapi${stepper.url}`}
          key={stepper.key}
          exact
          component={AddSolutionContainer}
        />
      ))}
      <Route
        path='/dashboard/myproducts/myapi/apistatistics'
        exact
        component={ApiStatisticsContainer}
      />
      <Route
        path='/dashboard/myproducts/myapi/apithrottling'
        key='apithrottling'
        exact
        component={ApiThrottlingContainer}
      />
      <Route
        path='/dashboard/myproducts/myapi/apithrottling/addthrottlingpolicy'
        key='addApiThrottling'
        exact
        component={ApiThrottlingContainer}
      />
      <Route
        path='/dashboard/myproducts/myapi/apialerts'
        exact
        component={ApiAlertsContainer}
      />
      {DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.apiProduct.title
      ).filters.map((option) => (
        <>
          <Route
            path={option.url}
            key={option.key}
            exact
            component={MySolutionContainer}
          />
          <Route
            path={`${option.url}/addapi`}
            exact
            component={AddSolutionContainer}
          />
        </>
      ))}
    </>
  );
};
