import { capitalizeFirstLetter } from './index';

export const getRateLimitType = (rateLimits, limitType) => {
  return (
    rateLimits?.find(
      (limitObj) => limitObj.type?.toLowerCase() === limitType?.toLowerCase()
    ) || null
  );
};

export const getRateLimitTypeName = (rateLimits, limitType) => {
  const rateLimitObj = getRateLimitType(rateLimits, limitType);
  return rateLimitObj?.name || (limitType ? capitalizeFirstLetter(limitType) : '');
};
