import axios from 'axios';
import * as Constants from './configuration';
import idConstants from '../data/idConstants';
import { SAVE_HARDWARE_PRODUCTS } from './ActionTypes';

export function getProductsByStatus(
  statusId,
  searchText,
  page,
  size,
  products,
  categoryId
) {
  let prodStatusId;
  let data = [];
  let hardware = [];

  if (statusId !== 'All') {
    prodStatusId = statusId;
  }

  return (dispatch) =>
    axios
      .get(
        Constants.SOLUTION_ACTION.GET_ALL_PRODUCTS,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: size,
            prodStatusId: prodStatusId,
            txtInProdName: searchText,
            prodCategoryId: categoryId
          }
        }
      )
      .then((response) => {
        if (!categoryId) {
          data = products.concat(
            response.data.filter((product) => {
              return product.productCategoryId === idConstants.api.categoryId;
            })
          );
          hardware = response.data.filter((product) => {
            return product.productCategoryId === idConstants.hardware.categoryId;
          });
          dispatch({
            type: SAVE_HARDWARE_PRODUCTS,
            payload: hardware
          });
        } else {
          data = products.concat(response.data);
        }
        dispatch({
          type: 'GET_ALL_PRODUCTS',
          payload: data,
          headers: response.headers
        });
        dispatch({
          type: 'GET_ALL_PRODUCTS_LOADED',
          payload: true
        });
        return data;
      })
      .catch((error) => {
        const response = error.response;
        dispatch({
          type: 'GET_ALL_PRODUCTS_LOADED',
          payload: true
        });
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function getProductsCount(statusId, searchText, isSearch, categoryId) {
  let text = '';
  let prodStatusId = '';
  let prodCategoryId = '';
  if (searchText) {
    text = 'txtInProdName=' + searchText;
  }
  if (statusId && statusId !== 'All') {
    prodStatusId = 'productStatusId=' + statusId;
  }
  if (categoryId) {
    prodCategoryId = `?prodCategoryId=${categoryId}`;
  }

  return (dispatch) =>
    axios
      .get(
        Constants.SOLUTION_ACTION.GET_PRODUCTS_COUNT +
          `${prodCategoryId}&${text}&${prodStatusId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        if (isSearch) {
          dispatch({
            type: 'GET_PRODUCTS_COUNT',
            payload: response.data
          });
        } else {
          dispatch({
            type: 'GET_ALL_PRODUCTS_COUNT',
            payload: response.data
          });
        }
        return response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function resetProducts() {
  return {
    type: 'GET_ALL_PRODUCTS',
    payload: []
  };
}

export function resetProductsLoadedFlag() {
  return {
    type: 'GET_ALL_PRODUCTS_LOADED',
    payload: false
  };
}

export function getSkuIdDetails(productId) {
  return (dispatch) => {
    axios
      .get(
        Constants.PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SKU_IDS_SERVICE +
          `?itemId=${productId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        if (response.data)
          dispatch({
            type: 'PRODUCT_SHARES_DATA',
            payload: response.data
          });
        return response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
  };
}

export function getTenantProductStatus(productId) {
  return (dispatch) =>
    axios
      .get(Constants.TENANT_PRODUCT_STATUS + `/${productId}/status`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        if (response.data)
          dispatch({
            type: 'TENANT_PRODUCT_STATUS',
            payload: response.data
          });
        return response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
