import React from 'react';
import i18next from 'i18next';
import { getPricingPlanStatus } from '../../../../utils';
import {
  Badge,
  IconButton,
  ToggleButton
} from '../../../../msreact-kit/packages/html-components';
import Tooltip from '../../../../components/Shared/Tooltip';
import Images from '../../../../assets/img';
import './index.scss';

const ContactSalesPricingCard = ({
  handleDelete,
  status,
  productStatusId,
  changePlanStatus,
  pricingPlanCardData
}) => {
  const { type, cardType } = getPricingPlanStatus(status, productStatusId);

  return (
    <div
      className='contact-sales pricing-card'
      style={{ opacity: pricingPlanCardData?.enabled ? 1 : 0.5 }}
    >
      <div className='contact-sales__container'>
        <p className='container-title'>
          {i18next.t('DYNAMIC_FORM.CONTACT_SALES.SALES_CARD_HEADING')}
        </p>
        {status && (
          <Badge customClass={`pricing-card__status-badge ${cardType}`}>
            {i18next.t(type)}
          </Badge>
        )}
        <img
          src={Images.maleWorkingOnComputerBehindBlue}
          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.CONTACT_SALES_IMG')}
          className='container-img'
        />
        <div className='pricing-card__display-flex'>
          <IconButton
            label=''
            className='pricing-card__bottom-button'
            iconCss='pricing-card__bottom-button__icon'
            iconTitle={i18next.t('DYNAMIC_FORM.ICON_TITLE.DELETE')}
            onClick={handleDelete}
            src={Images.deleteIcon}
            isIcon={false}
          />
          <Tooltip
            title={
              pricingPlanCardData?.enabled
                ? i18next.t(
                    'API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.DEACTIVATE_PLAN'
                  )
                : i18next.t('API_DETAIL.PRICING_TAB.PRICING_PLAN_CARD.ACTIVATE_PLAN')
            }
          >
            <div className='toggle-button-div'>
              <ToggleButton
                label=''
                name={pricingPlanCardData?.planName}
                id={pricingPlanCardData?.planId}
                checked={pricingPlanCardData?.enabled}
                handleChange={() => changePlanStatus(pricingPlanCardData)}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ContactSalesPricingCard;
