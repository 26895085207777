const initialState = {
  apiPlans: [],
  apiPlan: {},
  validationError: {},
  approvedUsers: [],
  orgGrps: [],
  updateApiPlanError: '',
  deletePlanError: '',
  updatedPricingStatus: [],
  isShowSuccessMsg: false
};

const PricingPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_API_PLANS':
      return Object.assign({}, state, { apiPlans: action.payload });
    case 'CREATE_API_PLAN':
      return Object.assign({}, state, { apiPlan: action.payload });
    case 'UPDATE_API_PLAN':
      return Object.assign({}, state, { apiPlan: action.payload });
    case 'UPDATE_API_PLAN_STATUS':
      return Object.assign({}, state, { apiPlan: action.payload });
    case 'UPDATE_API_PLAN_ERROR':
      return Object.assign({}, state, { updateApiPlanError: action.error });
    case 'DELETE_PLAN':
      return Object.assign({}, state, { apiPlan: action.payload });
    case 'DELETE_PLAN_ERROR':
      return Object.assign({}, state, { deletePlanError: action.error });
    case 'VALIDATION_ERROR':
      return Object.assign({}, state, { validationError: action.error });
    case 'RESET_PLAN':
      return Object.assign({}, state, { apiPlans: [] });
    case 'APPROVED_USERS':
      return Object.assign({}, state, { approvedUsers: action.payload });
    case 'ORG_GROUPS':
      return Object.assign({}, state, { orgGrps: action.payload });
    case 'UPDATE_PRICING_STATUS':
      return Object.assign({}, state, { updatedPricingStatus: action.payload });
    case 'SHOW_SUCCESS_MESSAGE':
      return Object.assign({}, state, { isShowSuccessMsg: action.payload });
    default:
      return state;
  }
};

export default PricingPlanReducer;
