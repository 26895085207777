import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import VerticleCommentsProgressBar from './vertical-comment-progressbar';
import './index.scss';

const DynamicComment = () => {
  const commentsData = useSelector((state) => state?.OrderSummaryReducer?.comments);

  if (!commentsData || commentsData.length === 0) {
    return null;
  }

  return (
    <VerticleCommentsProgressBar
      stepperOption={commentsData}
      title={i18next.t('ORDER_MANAGEMENT.DYNAMIC_COMMENTS.COMMENTS')}
    />
  );
};

export default DynamicComment;
