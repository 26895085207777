const fileNameWithEllipsis = (filename) => {
  if (!filename) return;

  filename = filename.trim();
  const maxFileNameLength = 25;
  const maxExtensionLength = 5;
  if (filename?.length <= maxFileNameLength) {
    return filename;
  }

  const extensionIndex = filename?.lastIndexOf('.');
  if (extensionIndex !== -1) {
    const fileNameWithoutExtension = filename?.substring(0, extensionIndex);
    const extension = filename?.substring(extensionIndex + 1);
    const trimmedFileName = fileNameWithoutExtension.substring(
      0,
      maxFileNameLength - maxExtensionLength
    );
    return trimmedFileName + '...' + extension;
  }

  return filename?.substring(0, maxFileNameLength);
};

export default fileNameWithEllipsis;
