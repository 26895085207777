import {
  initialBankDetailsState,
  initialBankDetailsErrorState
} from '../data/bankData';

const initialState = {
  bankDetails: { ...initialBankDetailsState },
  bankDetailsError: { ...initialBankDetailsErrorState },
  msCompanyDetails: '',
  userDeregistered: '',
  userRegistrationSuccessMsg: '',
  userDetails: {},
  userRegistrationErrorMsg: ''
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BANK_DETAILS':
      return Object.assign({}, state, { bankDetails: action.payload });
    case 'BANK_DETAILS_ERR':
      return Object.assign({}, state, { bankDetailsError: action.payload });
    case 'MS_COMPANY_DETAILS':
      return { ...state, msCompanyDetails: action.payload };
    case 'USER_DEREGISTERED':
      return { ...state, userDeregistered: action.payload };
    case 'USER_DETAILS':
      return Object.assign({}, state, { userDetails: action.payload });
    case 'USER_REGISTRATION_SUCCESS_ALERT':
      return { ...state, userRegistrationSuccessMsg: action.payload };
    case 'USER_REGISTRATION_ERROR_ALERT':
      return { ...state, userRegistrationErrorMsg: action.error };
    default:
      return state;
  }
};

export default ProfileReducer;
