import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  confirmOrder,
  addTransitDetails,
  postComment,
  addShippingRemark,
  uploadPodDocument,
  submitRejection,
  rejectPod
} from '../../../actions/api/OrderSummaryAction';
import AddComment from './order-summary-popups/add-comment';
import AddTransit from './order-summary-popups/add-transit-popup';
import OrderConfirm from './order-summary-popups/order-confirmed-popup';
import RejectionRemarks from './order-summary-popups/rejection-remarks-popup';
import UploadFilePopup from './order-summary-popups/uploadfile-popup';
import idConstants from '../../../data/idConstants';
import i18next from 'i18next';

const DynamicPopUp = ({
  onClose,
  productOrderId,
  productOrderDetailId,
  commentId,
  showPODDetails,
  showConfirmOrder,
  showRejectOrder,
  comment,
  shippingRemark,
  transitDetail,
  isCancellable,
  tenantId
}) => {
  const dispatch = useDispatch();
  const [rejectionTextAreaValue, setRejectionTextAreaValue] = useState('');
  const [commentValue, setCommentValue] = useState('');
  const [shippingRemarkValue, setShippingRemarkValue] = useState('');
  const [fileUploadRemark, setFileUploadRemark] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [rejectedItemValue, setRejectedItemValue] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);

  const { statusValue, categoryId, productId, isPodRequired, orderDescription } =
    useSelector((state) => {
      return {
        statusValue: state?.OrderSummaryReducer?.statusChange,
        categoryId:
          state?.OrderSummaryReducer?.orderDescription?.orderSubscriptionMap
            ?.productCategoryId,
        productId:
          state?.OrderSummaryReducer?.orderDescription?.productOrderDto?.productId,
        isPodRequired: state?.OrderSummaryReducer?.orderDescription.podRequired,
        orderDescription: state?.OrderSummaryReducer?.orderDescription
      };
    });

  useEffect(() => {
    onClose();
  }, [statusValue]);

  const onClosePopUp = () => {
    setCommentValue(() => '');
    setShippingRemarkValue(() => '');
    setFileUploadRemark(() => '');
    setFile(() => '');
    onClose();
  };

  const handleConfirmOrder = () => {
    setDisabledButton(() => true);
    dispatch(confirmOrder(productOrderDetailId, orderDescription));
    onClose();
  };

  const handleUserInputComment = ({ value }) => {
    setCommentValue(() => value);
  };

  const handleUserShippingRemark = ({ value }) => {
    setShippingRemarkValue(value);
  };

  const handleCommentSubmit = () => {
    dispatch(postComment(commentId, commentValue, productOrderDetailId));
  };

  const shippingRemarkSubmit = () => {
    dispatch(
      addShippingRemark(productOrderDetailId, shippingRemarkValue, orderDescription)
    );
  };

  const handleTransitSubmit = (data) => {
    // commented code to be used in upload file future release
    let bodyFormData;
    if (file) {
      bodyFormData = new FormData();
      bodyFormData.append('data', file[0]);
      bodyFormData.append(
        'documentTypeId',
        idConstants.documentType.inTransitDocument
      );
    }
    onClose();
    dispatch(
      addTransitDetails(productOrderDetailId, data, bodyFormData, orderDescription)
    );
    setFile(null);
  };

  const handleFileUploadChange = (data) => {
    if (data.value.length > 1) {
      return setFileError(() => true);
    } else {
      setFileError(() => false);
      let file = data.value[0];
      file['documentName'] = file.name;
      setFile(data.value);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleFileUploadRemark = ({ value }) => {
    setFileUploadRemark(() => value);
  };

  const submitFileUpload = () => {
    let bodyFormData;
    if (file?.length > 0) {
      bodyFormData = new FormData();
      bodyFormData.append('data', file[0]);
      bodyFormData.append('documentTypeId', idConstants.documentType.podDocument);
    }
    dispatch(
      uploadPodDocument(
        bodyFormData,
        fileUploadRemark,
        productOrderDetailId,
        orderDescription
      )
    );
    setFile(null);
  };

  const rejectFileUpload = () => {
    let bodyFormData;
    if (file?.length > 0) {
      bodyFormData = new FormData();
      bodyFormData.append('data', file[0]);
      bodyFormData.append('documentTypeId', idConstants.documentType.podDocument);
    }
    dispatch(
      rejectPod(
        bodyFormData,
        fileUploadRemark,
        productOrderDetailId,
        orderDescription
      )
    );
    setFile(null);
  };

  const handleRejectionRemarkChange = (value) => {
    setRejectionTextAreaValue(() => value?.value);
  };

  const handleRejectionSubmit = () => {
    const data = {
      rejectedItemValue,
      productId,
      productOrderId,
      categoryId,
      rejectionTextAreaValue
    };
    dispatch(submitRejection(data, productOrderDetailId, orderDescription));
  };

  const handleSetRejectedItemValue = (data) => {
    setRejectedItemValue(() => data);
  };

  if (transitDetail)
    return (
      <AddTransit
        handleSubmitTransit={handleTransitSubmit}
        handleCloseTransit={onClosePopUp}
        // commented code to be used with future release
        handleRemoveFile={handleRemoveFile}
        currentValue={file}
        onFileUploadChange={handleFileUploadChange}
      />
    );

  if (showRejectOrder)
    return (
      <RejectionRemarks
        currentRejectReason={rejectedItemValue}
        handleSelectedOption={handleSetRejectedItemValue}
        value={rejectionTextAreaValue}
        handleChange={handleRejectionRemarkChange}
        handleSubmitRejection={handleRejectionSubmit}
        rejectionRemarkLength={rejectionTextAreaValue.length}
        onClose={onClosePopUp}
        setRejectedItemValue={setRejectedItemValue}
        setRejectionTextAreaValue={setRejectionTextAreaValue}
      />
    );

  if (comment)
    return (
      <AddComment
        error={
          commentValue.length > 1000 &&
          i18next.t(
            'ORDER_MANAGEMENT.DYNAMIC_POPUP.CANNOT_EXCEED_MAX_CHARACTER_LENGTH'
          )
        }
        currentCommentLength={commentValue.length}
        handleSubmitComment={handleCommentSubmit}
        onInput={handleUserInputComment}
        header={i18next.t('ORDER_MANAGEMENT.DYNAMIC_POPUP.ADD_COMMENT')}
        subHeader={i18next.t(
          'ORDER_MANAGEMENT.DYNAMIC_POPUP.PLEASE_ADD_YOUR_COMMENTS'
        )}
        handleCloseCommentBox={onClosePopUp}
        value={commentValue}
      />
    );

  if (shippingRemark)
    return (
      <AddComment
        error={
          shippingRemarkValue.length > 1000 &&
          i18next.t(
            'ORDER_MANAGEMENT.DYNAMIC_POPUP.CANNOT_EXCEED_MAX_CHARACTER_LENGTH'
          )
        }
        currentCommentLength={shippingRemarkValue.length}
        handleSubmitComment={shippingRemarkSubmit}
        onInput={handleUserShippingRemark}
        header={i18next.t(
          'ORDER_MANAGEMENT.DYNAMIC_POPUP.WRITE_REMARK_TO_READY_TO_SHIP'
        )}
        subHeader={i18next.t(
          'ORDER_MANAGEMENT.DYNAMIC_POPUP.PLEASE_ADD_YOUR_COMMENTS'
        )}
        handleCloseCommentBox={onClosePopUp}
        value={shippingRemarkValue}
      />
    );

  if (showPODDetails)
    return (
      <UploadFilePopup
        fileError={
          fileError &&
          i18next.t('ORDER_MANAGEMENT.DYNAMIC_POPUP.MAX_1_FILE_CAN_BE_UPLOADED')
        }
        filePopup={true}
        error={
          fileUploadRemark.length > 1000 &&
          i18next.t(
            'ORDER_MANAGEMENT.DYNAMIC_POPUP.CANNOT_EXCEED_MAX_CHARACTER_LENGTH'
          )
        }
        fileUploadRemarkLength={fileUploadRemark.length}
        handleRemoveFile={handleRemoveFile}
        currentValue={file}
        onFileUploadSubmit={submitFileUpload}
        onInput={handleFileUploadRemark}
        onFileUploadChange={handleFileUploadChange}
        onCloseFileUploadBox={onClosePopUp}
        value={fileUploadRemark}
        isPodRequired={isPodRequired}
        rejectFileUpload={rejectFileUpload}
        isCancellable={isCancellable}
        tenantId={tenantId}
      />
    );

  if (showConfirmOrder)
    return (
      <OrderConfirm
        disabled={disabledButton}
        handleClose={onClosePopUp}
        handleSubmitOrder={handleConfirmOrder}
      />
    );

  return null;
};

DynamicPopUp.propTypes = {
  onClose: PropTypes.func,
  productOrderDetailId: PropTypes.string,
  commentId: PropTypes.string,
  showPODDetails: PropTypes.bool,
  showConfirmOrder: PropTypes.bool,
  showRejectOrder: PropTypes.bool,
  comment: PropTypes.bool,
  shippingRemark: PropTypes.bool,
  transitDetail: PropTypes.bool,
  tenantId: PropTypes.string
};

export default DynamicPopUp;
