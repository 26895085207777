import React from 'react';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';

const Pagination = (props) => {
  const {
    onPageChange,
    paginationData,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    paginationData,
    siblingCount,
    pageSize
  });

  if (paginationRange && paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  return (
    <ul className={`pagination-container ${className ? className : ''}`}>
      <li
        className={`pagination-item ${currentPage === 0 && 'disabled'}`}
        onClick={onPrevious}
      >
        <div className='arrow left' />
      </li>
      {paginationRange &&
        paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber} className='pagination-item dots'>
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={pageNumber}
              className={`pagination-item ${
                pageNumber - 1 === currentPage && 'selected'
              }`}
              onClick={() => onPageChange(pageNumber - 1)}
            >
              {pageNumber}
            </li>
          );
        })}
      <li
        className={`pagination-item ${currentPage === lastPage - 1 && 'disabled'}`}
        onClick={onNext}
      >
        <div className='arrow right' />
      </li>
    </ul>
  );
};

export default Pagination;
