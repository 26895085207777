import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import {
  getColourNode,
  orderStatus as orderStatusList
} from '../../../../shared/shared-function/helperFunctions';
import { StepIndicator } from '../../../../msreact-kit/packages/html-components';
import { convertUTCToLocalDateFormat } from '../../../../utils/date-time-utils';
import './index.scss';

const OrderStatus = () => {
  const [data, setData] = useState([]);
  const orderStatus = useSelector((state) => state.OrderSummaryReducer.orderStatus);

  useEffect(() => {
    if (orderStatus.length > 0) {
      const formatedNewData = orderStatus.map((element) => {
        let displayName = '';
        switch (element.status) {
          case orderStatusList.ORDER_CREATED:
            displayName = i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.CREATED');
            break;
          case orderStatusList.ORDER_CONFIRMED:
            displayName = i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.CONFIRMED');
            break;
          case orderStatusList.INTRANSIT:
            displayName = i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.INTRANSIT');
            break;
          case orderStatusList.REJECTED_WITH_POD:
            displayName = i18next.t(
              'ORDER_MANAGEMENT.ORDER_STATUS.REJECTED_WITH_POD'
            );
            break;
          case orderStatusList.ORDER_REJECTED:
            displayName = i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.REJECTED');
            break;
          case orderStatusList.ORDER_CANCELLED:
          case orderStatusList.CONFIRMED_ORDER_CANCELLED:
            displayName = i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.CANCELLED');
            break;
          default:
            displayName = element?.status?.split('_').join(' ');
            break;
        }

        return {
          displayName:
            element.status === orderStatusList.REJECTED_WITH_POD
              ? displayName
              : displayName.charAt(0).toUpperCase() +
                displayName.slice(1).toLowerCase(),
          desc: element.statusUpdateTime
            ? convertUTCToLocalDateFormat(
                element.statusUpdateTime,
                'DD/MM/YYYY, h:mm A'
              )
            : '',
          ...element
        };
      });
      setData(formatedNewData);
    }
  }, [orderStatus]);

  const currentCount = getColourNode(data);
  const activeGrouping = currentCount.currentNode;
  const size = currentCount.maxLength;

  if (size < 1) return null;

  return (
    <>
      <hr className='a-divider -within-text' />
      <div className='order-status'>
        <h5 className='order-status__header'>
          {i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.STATUS')}
        </h5>
        <div className='order-status__indicator mobile-indicator'>
          <div className='order-status__indicator m-step-indicator m-step-indicator--mobile -small'>
            <StepIndicator
              size={size}
              data={data}
              active={activeGrouping}
              customClass='order-status-node'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderStatus;
