import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import fullscreen from 'highcharts/modules/full-screen';

fullscreen(Highcharts);

Highcharts.setOptions({
  global: {
    useUTC: false
  }
});

class MetricsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.apiUsageRef = React.createRef();
    this.apiPerformanceRef = React.createRef();
    this.apiThrottleRef = React.createRef();
    this.apiFailedRef = React.createRef();
    this.endpointsRef = React.createRef();
    this.apiSubscription = React.createRef();
    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptionsApiUsage: {
        chart: {
          type: 'spline',
          zoomType: 'x',
          backgroundColor: 'rgb(243, 251, 255)'
        },
        credits: {
          enabled: false
        },
        title: {
          text: 'API Usage'
        },
        legend: {
          title: {
            style: {
              fontStyle: 'italic'
            }
          },
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -10,
          y: 100
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter: function () {
              return Highcharts.dateFormat('%e %b', this.value);
            }
          },
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'API Usage'
          },
          lineWidth: 1
        },
        tooltip: {
          backgroundColor: '#00FF00',
          borderWidth: 0,
          formatter: function () {
            return (
              '<b>' +
              this.series.name +
              '</b><br>No. of Requests: <b>' +
              this.y +
              '</b><br/> Day: <b>' +
              Highcharts.dateFormat('%e %b', this.x) +
              '</b>'
            );
          }
        },
        series: [],
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        }
      },
      chartOptionsApiPerformance: {},
      chartOptionsApiThrottled: {},
      chartOptionsApiFailed: {},
      chartOptionsEndpoints: {},
      chartOptionsApiSubscriptions: {}
    };
  }

  componentDidUpdate(prevProps) {
    for (let chart of Highcharts.charts) {
      if (chart !== undefined) {
        chart.reflow();
      }
    }
    let axisFormat = this.props?.hourBased ? '%H:%M' : '%e %b';
    let axisTitle = this.props?.hourBased ? 'Time' : 'Days';
    let usageData = [];
    let performanceData = [];
    let throttledData = [];
    let endpointData = [];
    let failedRequestData = [];
    let apiSubscriptionData = [];
    if (prevProps !== this.props && this.props?.usageMetrics?.tracking) {
      this.props.usageMetrics.tracking.forEach((api) => {
        const { apiName, apiMetrics } = api;
        apiMetrics.forEach((apiMetric) => {
          const { apMetrics, analyticsDtos: analytics } = apiMetric;
          if (apMetrics === 'API_USAGE') {
            let data = [];
            if (analytics.length > 0) {
              analytics[0].overTimeAnalysis.forEach((timeanalysis) => {
                return data.push([
                  parseInt(timeanalysis.timestamp),
                  parseInt(timeanalysis.reqCount)
                ]);
              });
              usageData.push({ name: apiName, data: data });
            }
          } else if (apMetrics === 'API_PERFORMANCE') {
            let data = [];
            if (analytics.length > 0) {
              analytics[0].overTimeAnalysis.forEach((timeanalysis) => {
                data.push([
                  parseInt(timeanalysis.timestamp),
                  parseInt(timeanalysis.avgReqTime)
                ]);
              });
              performanceData.push({ name: apiName, data: data });
            }
          } else if (apMetrics === 'API_THROTTLE_REQ') {
            let data = [];
            if (analytics.length > 0 && parseInt(analytics[0].totalReq) !== 0) {
              analytics[0].overTimeAnalysis.forEach((timeanalysis) => {
                data.push([
                  parseInt(timeanalysis.timestamp),
                  parseInt(timeanalysis.reqCount)
                ]);
              });
              throttledData.push({ name: apiName, data: data });
            }
          } else if (apMetrics === 'API_RESOURCE_USAGE') {
            analytics.forEach((endPointinfo) => {
              let data = [];
              endPointinfo.overTimeAnalysis.forEach((timeanalysis) => {
                data.push([
                  parseInt(timeanalysis.timestamp),
                  parseInt(timeanalysis.reqCount)
                ]);
              });
              endpointData.push({
                name: endPointinfo.endPointPath,
                data: data
              });
            });
          } else if (apMetrics === 'API_FAILED_REQ') {
            let data = [];
            if (analytics.length > 0) {
              analytics[0].overTimeAnalysis.forEach((timeanalysis) => {
                data.push([
                  parseInt(timeanalysis.timestamp),
                  parseInt(timeanalysis.reqCount)
                ]);
              });
              failedRequestData.push({ name: apiName, data: data });
            }
          } else if (apMetrics === 'SUBSCRIPTION_COUNT') {
            let data = [];
            if (analytics.length > 0) {
              analytics[0].overTimeAnalysis.forEach((timeanalysis) => {
                data.push([
                  parseInt(timeanalysis.timestamp),
                  parseInt(timeanalysis.subscriptionCount)
                ]);
              });
              apiSubscriptionData.push({ name: apiName, data: data });
            }
          }
        });
      });
      this.setState({
        chartOptionsApiUsage: {
          ...this.state.chartOptionsApiUsage,
          tooltip: {
            backgroundColor: '#00FF00',
            borderWidth: 0,
            formatter: function () {
              return (
                '<b>' +
                this.series.name +
                '</b><br>No. of Requests: <b>' +
                this.y +
                '</b><br/>' +
                axisTitle +
                ':' +
                '<b>' +
                Highcharts.dateFormat(axisFormat, this.x) +
                '</b>'
              );
            }
          },
          xAxis: {
            labels: {
              formatter: function () {
                return Highcharts.dateFormat(axisFormat, this.value);
              }
            }
          },
          series: usageData
        }
      });
      this.setState({
        chartOptionsApiPerformance: {
          ...this.state.chartOptionsApiUsage,
          title: {
            text: 'API Performance'
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: ['viewFullscreen']
              }
            }
          },
          tooltip: {
            backgroundColor: '#00FF00',
            borderWidth: 0,
            formatter: function () {
              return (
                '<b>' +
                this.series.name +
                '</b><br>Average Response Time: <b>' +
                this.y +
                '</b><br/>' +
                axisTitle +
                ':' +
                '<b>' +
                Highcharts.dateFormat(axisFormat, this.x) +
                '</b>'
              );
            }
          },
          xAxis: {
            labels: {
              formatter: function () {
                return Highcharts.dateFormat(axisFormat, this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'API Performance (ms)'
            },
            lineWidth: 1
          },
          series: performanceData
        }
      });
      this.setState({
        chartOptionsApiThrottled: {
          ...this.state.chartOptionsApiUsage,
          title: {
            text: 'Throttled-Out Requests'
          },
          xAxis: {
            labels: {
              formatter: function () {
                return Highcharts.dateFormat(axisFormat, this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'Throttled-Out Requests'
            },
            lineWidth: 1
          },
          series: throttledData
        }
      });
      this.setState({
        chartOptionsEndpoints: {
          ...this.state.chartOptionsApiUsage,
          title: {
            text: 'Usage by Endpoints'
          },
          xAxis: {
            labels: {
              formatter: function () {
                return Highcharts.dateFormat(axisFormat, this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'Usage by Endpoints'
            },
            lineWidth: 1
          },
          series: endpointData
        }
      });
      this.setState({
        chartOptionsApiFailed: {
          ...this.state.chartOptionsApiUsage,
          title: {
            text: 'Failed Request'
          },
          xAxis: {
            labels: {
              formatter: function () {
                return Highcharts.dateFormat(axisFormat, this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'Failed Request'
            },
            lineWidth: 1
          },
          series: failedRequestData
        }
      });
      this.setState({
        chartOptionsApiSubscriptions: {
          ...this.state.chartOptionsApiUsage,
          title: {
            text: 'API Subscriptions'
          },
          xAxis: {
            labels: {
              formatter: function () {
                return Highcharts.dateFormat(axisFormat, this.value);
              }
            }
          },
          yAxis: {
            title: {
              text: 'API Subscriptions'
            },
            lineWidth: 1
          },
          series: apiSubscriptionData
        }
      });
    }
  }

  render() {
    const {
      chartOptionsApiUsage,
      chartOptionsApiPerformance,
      chartOptionsApiThrottled,
      chartOptionsApiFailed,
      chartOptionsEndpoints,
      chartOptionsApiSubscriptions
    } = this.state;
    return (
      <React.Fragment>
        <div className='graph-container'>
          {this.props.selectedValues.indexOf('API_USAGE') > -1 && (
            <div className='graph-container_card'>
              <div className='graph-container_card_items'>
                {chartOptionsApiUsage.series.length > 0 ? (
                  <div className='graph-container_card_items_graph'>
                    <HighchartsReact
                      containerProps={{ className: 'high-chart' }}
                      highcharts={Highcharts}
                      options={chartOptionsApiUsage}
                      ref={this.apiUsageRef}
                    />
                  </div>
                ) : (
                  <>
                    <p className='highlight api-data'>API Usage</p>
                    <p className='highlight api-data'>No data is available!</p>
                  </>
                )}
              </div>
            </div>
          )}
          {this.props.selectedValues?.indexOf('API_PERFORMANCE') > -1 && (
            <div className='graph-container_card'>
              <div className='graph-container_card_items'>
                {chartOptionsApiPerformance.series !== undefined &&
                chartOptionsApiPerformance.series.length > 0 ? (
                  <div className='graph-container_card_items_graph'>
                    <HighchartsReact
                      containerProps={{ className: 'high-charts' }}
                      highcharts={Highcharts}
                      options={chartOptionsApiPerformance}
                      ref={this.apiPerformanceRef}
                    />
                  </div>
                ) : (
                  <>
                    <p className='highlight api-data'>API Performance</p>
                    <p className='highlight api-data'>No data is available!</p>
                  </>
                )}
              </div>
            </div>
          )}
          {this.props.selectedValues?.indexOf('API_THROTTLE_REQ') > -1 && (
            <div className='graph-container_card'>
              <div className='graph-container_card_items'>
                {chartOptionsApiThrottled.series !== undefined &&
                chartOptionsApiThrottled.series.length > 0 ? (
                  <div className='graph-container_card_items_graph'>
                    <HighchartsReact
                      containerProps={{ className: 'high-charts' }}
                      highcharts={Highcharts}
                      options={chartOptionsApiThrottled}
                      ref={this.apiThrottleRef}
                    />
                  </div>
                ) : (
                  <>
                    <p className='highlight api-data'>Throttled-Out Requests</p>
                    <p className='highlight api-data'>No data is available!</p>
                  </>
                )}
              </div>
            </div>
          )}
          {this.props.selectedValues?.indexOf('API_FAILED_REQ') > -1 && (
            <div className='graph-container_card'>
              <div className='graph-container_card_items'>
                {chartOptionsApiFailed.series !== undefined &&
                chartOptionsApiFailed.series.length > 0 ? (
                  <div className='graph-container_card_items_graph'>
                    <HighchartsReact
                      containerProps={{ className: 'high-charts' }}
                      highcharts={Highcharts}
                      options={chartOptionsApiFailed}
                      ref={this.apiFailedRef}
                    />
                  </div>
                ) : (
                  <>
                    <p className='highlight api-data'>Failed Requests</p>
                    <p className='highlight api-data'>No data is available!</p>
                  </>
                )}
              </div>
            </div>
          )}
          {this.props.selectedValues?.indexOf('SUBSCRIPTION_COUNT') > -1 && (
            <div className='graph-container_card'>
              <div className='graph-container_card_items'>
                {chartOptionsApiSubscriptions.series !== undefined &&
                chartOptionsApiSubscriptions.series.length > 0 ? (
                  <div className='graph-container_card_items_graph'>
                    <HighchartsReact
                      containerProps={{ className: 'high-charts' }}
                      highcharts={Highcharts}
                      options={chartOptionsApiSubscriptions}
                      ref={this.apiSubscription}
                    />
                  </div>
                ) : (
                  <>
                    <p className='highlight api-data'>API Subscriptions</p>
                    <p className='highlight api-data'>No data is available!</p>
                  </>
                )}
              </div>
            </div>
          )}
          {this.props.selectedValues?.indexOf('RESOURCE_USAGE') > -1 && (
            <div className='graph-container_card'>
              <div className='graph-container_card_items'>
                {chartOptionsEndpoints.series !== undefined &&
                chartOptionsEndpoints.series.length > 0 ? (
                  <div className='graph-container_card_items_graph'>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptionsEndpoints}
                      ref={this.endpointsRef}
                    />
                  </div>
                ) : (
                  <>
                    <p className='highlight api-data'>API Usage by EndPoints</p>
                    <p className='highlight api-data'>No data is available!</p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default MetricsGraph;
