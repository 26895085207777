import axios from 'axios';
import { useDispatch } from 'react-redux';
import loading from '../../actions/LoaderAction';

const useHandleLoader = () => {
  const dispatch = useDispatch();
  let count = 0;
  const handleLoader = () => {
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        count = count + 1;
        dispatch(loading(true));
        return config;
      },
      function (error) {
        count = count - 1;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        count = count - 1;
        if (count === 0) {
          dispatch(loading(false));
        }
        return response;
      },
      function (error) {
        count = count - 1;
        if (count === 0) {
          dispatch(loading(false));
        }
        return Promise.reject(error);
      }
    );
  };

  return [handleLoader];
};
export default useHandleLoader;
