import React from 'react';
import Dialog from '../../../dialog';
import PropTypes from 'prop-types';
import { TextArea } from '../../../../../msreact-kit/packages/html-components';
import '../index.scss';
import i18next from 'i18next';

const AddComment = ({
  handleCloseCommentBox,
  header,
  subHeader,
  onInput,
  handleSubmitComment,
  currentCommentLength,
  error,
  value
}) => {
  const commentData = {
    attributeId: null,
    name: 'addComment',
    displayName: subHeader,
    description: '',
    xpath: '',
    jsonType: null,
    handleChange: onInput,
    attributeType: 'TEXT AREA',
    placeHolder: i18next.t(
      'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.WRITE_HERE1'
    ),
    displayOrder: 4,
    isVisibleForBuyer: null,
    isElementRequired: true,
    stateChangeOnEdit: false,
    isEncryptable: false,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: ''
        },
        length: {
          min: 1,
          max: 1000,
          errorCode: 'MS105',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: null,
    sections: [],
    value: value
  };

  return (
    <div className='add-comment'>
      <div className='add-comment__container'>
        <Dialog
          header={header}
          isPrimary={false}
          secondLabel={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT')}
          isTertiary={false}
          isTitle={false}
          isDivider={false}
          onCloseDialog={handleCloseCommentBox}
          handleSubmitClick={handleSubmitComment}
          commentBoxLength={currentCommentLength}
        >
          <TextArea error={error} {...commentData}></TextArea>
        </Dialog>
      </div>
    </div>
  );
};

AddComment.propTypes = {
  handleCloseCommentBox: PropTypes.func,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  onInput: PropTypes.string,
  handleSubmitComment: PropTypes.func,
  currentCommentLength: PropTypes.number,
  value: PropTypes.string
};

export default AddComment;
