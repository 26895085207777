const initialState = {
  sellerNames: []
};
const SellerNamesReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SELLER_NAMES_PREVIEW':
      return {
        sellerNames: action.payload
      };
    case 'CLEAR_SELLER_NAMES_PREVIEW':
      return {
        sellerNames: []
      };
    default:
      return state;
  }
};

export default SellerNamesReducer_Preview;
