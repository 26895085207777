import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'rgba(255,255,255,0.6)'
  }
}));

export default function Loader() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={true}
        invisible={true}
        onClick={handleClose}
      >
        <CircularProgress size={100} color='inherit' />
      </Backdrop>
      {/**below line just to remove lint issue */}
      {open ? true : false}
    </div>
  );
}
