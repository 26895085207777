import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Search = ({ placeholder, onSearch }) => {
  const [searchVal, setSearchVal] = useState('');
  const handleChange = (event) => {
    event.preventDefault();
    setSearchVal(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearch(searchVal);
    }
  };

  return (
    <form className='m-search-form' autoComplete='off'>
      <div className='a-text-field a-text-field--search product__search'>
        <input
          type='search'
          id='search-default'
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          value={searchVal}
        />
        <button
          type='button'
          onClick={() => onSearch(searchVal)}
          className='a-text-field__icon-search'
        >
          <i className='a-icon ui-ic-search' title='search'></i>
        </button>
        {searchVal && (
          <button
            type='button'
            onClick={() => {
              onSearch('');
              setSearchVal('');
            }}
            className='a-text-field__icon-close'
          >
            <i className='a-icon ui-ic-close-small' title='close'></i>
          </button>
        )}
      </div>
    </form>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func
};

Search.defaultProps = {
  placeholder: 'Search'
};

export default Search;
