import React from 'react';
import {
  Tabs,
  Tab,
  Typography,
  Box,
  NativeSelect,
  InputLabel,
  Stack,
  TextField,
  FormControl
} from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { XMLValidator } from 'fast-xml-parser';
import xmlFormatter from 'xml-formatter';
import styles from './payload.module.scss';
import {
  payloadConstants,
  contentTypeConstants,
  samplePayloads
} from '../../../../../data/appConstants';
import toastMessages from '../../../../../data/toastMessages';
import { EditorBlock } from '@mobilitystore/prettify-editor-interface';
import LightTooltip from '../../../../Shared/Tooltip';
import Images from '../../../../../assets/img';
import generateRandomId from '../../../../../utils/generate-random-id';
import i18next from 'i18next';

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const EndpointBodyPayload = ({
  title,
  endPointName,
  selectedContentType,
  selectedResponseCode,
  contentTypes,
  bodyPlaceholderText,
  schemaPlaceholderText,
  type,
  isSchemaDisabled,
  isBuyerView,
  isEditable,
  changeContentType,
  changeResponseCodeValue,
  setSaveButtonState,
  bodyValue,
  setBodySampleValue,
  schemaValue,
  isContentTypeDisabled,
  isRequiredParameter
}) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  });

  const isMobilePortalView = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(0);
  const [body, setBody] = React.useState(bodyValue);
  const [contentTypeValue, setContentTypeValue] =
    React.useState(selectedContentType);
  const [responseCodeValue, setResponseCodeValue] = React.useState(
    selectedResponseCode ? selectedResponseCode : ''
  );
  const [errorText, setErrorText] = React.useState(null);
  const [successText, setSuccessText] = React.useState(null);
  const contentTypeData = contentTypes;
  const vaildResponseCodeRegex = /^[1-5]\d{2}$/;
  // to have unique editor model for each path
  const [uniqueID, setUniqueID] = React.useState(0);
  const [tooltipHandler, setTooltipHandler] = React.useState(false);

  const stateRefContentType = React.useRef();
  const stateRefCode = React.useRef();
  stateRefContentType.current = contentTypeValue;
  stateRefCode.current = responseCodeValue;

  const editOption =
    (contentTypeValue === contentTypeConstants.notApplicable &&
      type === payloadConstants.request) ||
    ((contentTypeValue === contentTypeConstants.notApplicable ||
      !vaildResponseCodeRegex.test(selectedResponseCode)) &&
      type === payloadConstants.response) ||
    (isBuyerView && type === payloadConstants.response) ||
    !isEditable;

  const lang =
    contentTypeValue === contentTypeConstants.applicationJson ? 'json' : 'xml';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setContentTypeValue(selectedContentType);
  }, [selectedContentType]);

  React.useEffect(() => {
    setUniqueID(generateRandomId());
    setSuccessText(null);
    setErrorText(null);
  }, [endPointName]);

  React.useEffect(() => {
    if (type === payloadConstants.response && isBuyerView && !bodyValue) {
      selectedContentType === contentTypeConstants.applicationJson
        ? validateBody(samplePayloads.json, selectedContentType)
        : validateBody(samplePayloads.xml, selectedContentType);
    } else {
      prettifyBody(bodyValue ? bodyValue : bodyPlaceholderText, selectedContentType);
    }
  }, [bodyValue]);

  React.useEffect(() => {
    if (body === '' || body === bodyPlaceholderText) {
      setErrorText(null);
      setSuccessText(null);
      setSaveButtonState(false);
    }
  }, [body]);

  React.useEffect(() => {
    if (isBuyerView) {
      let buttonStateForTestEndpoint = true;
      if (contentTypeValue === contentTypeConstants.applicationJson && bodyValue) {
        buttonStateForTestEndpoint = isValidJSONString(bodyValue);
      } else if (
        contentTypeValue === contentTypeConstants.applicationXml &&
        bodyValue
      ) {
        buttonStateForTestEndpoint =
          XMLValidator.validate(bodyValue, {
            allowBooleanAttributes: true
          }) === true
            ? true
            : false;
      }
      setSaveButtonState(
        isRequiredParameter && !bodyValue
          ? true
          : !buttonStateForTestEndpoint
            ? true
            : false
      );
    }
  }, []);

  const setBodyValue = (value) => {
    validateBody(value, contentTypeValue);
  };

  const validateBody = (value, contentType) => {
    switch (contentType) {
      case contentTypeConstants.applicationJson:
        setJSONBody(value);
        break;
      case contentTypeConstants.applicationXml:
        setXMLBody(value);
        break;
    }
  };

  const prettifyBody = (value, contentType) => {
    switch (contentType) {
      case contentTypeConstants.applicationJson:
        setBody(value);
        setBodySampleValue(value);
        break;
      case contentTypeConstants.applicationXml:
        setBody(
          value &&
            XMLValidator.validate(value, {
              allowBooleanAttributes: true
            }) === true
            ? xmlFormatter(value)
            : value
        );
        setBodySampleValue(
          value &&
            XMLValidator.validate(value, {
              allowBooleanAttributes: true
            }) === true
            ? xmlFormatter(value)
            : value
        );
        break;
      default:
        setBody('');
        setBodySampleValue('');
        break;
    }
  };

  const setJSONBody = (value) => {
    if (value) {
      if (isValidJSONString(value)) {
        setBody(value);
        setBodySampleValue(value);
        setSuccessText(
          isBuyerView
            ? toastMessages.successTextBuyer.msg
            : toastMessages.validExample.msg
        );
        setErrorText(null);
        setSaveButtonState(false);
      } else {
        setBody(value);
        setBodySampleValue(value);
        setSuccessText(null);
        setErrorText(
          isBuyerView
            ? toastMessages.errorTextBuyer.msg
            : toastMessages.invalidExample.msg
        );
        setSaveButtonState(true);
      }
    } else {
      setSuccessText(null);
      setErrorText(null);
      setSaveButtonState(isRequiredParameter ? true : false);
    }
  };

  const setXMLBody = (value) => {
    if (value) {
      if (
        XMLValidator.validate(value, {
          allowBooleanAttributes: true
        }) === true
      ) {
        setBody(xmlFormatter(value));
        setBodySampleValue(xmlFormatter(value));
        setSuccessText(
          isBuyerView
            ? toastMessages.successTextBuyer.msg
            : toastMessages.validExample.msg
        );
        setErrorText(null);
        setSaveButtonState(false);
      } else {
        setBody(value);
        setBodySampleValue(value);
        setSuccessText(null);
        setErrorText(
          isBuyerView
            ? toastMessages.errorTextBuyer.msg
            : toastMessages.invalidExample.msg
        );
        setSaveButtonState(true);
      }
    } else {
      setSuccessText(null);
      setErrorText(null);
      setSaveButtonState(isRequiredParameter ? true : false);
    }
  };

  const isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  };

  const handlePrettifyJson = () => {
    setBody(JSON.stringify(JSON.parse(body, null), null, 2));
    setBodySampleValue(JSON.stringify(JSON.parse(body, null), null, 2));
  };

  const handleContentTypeChange = (event) => {
    setBody(bodyPlaceholderText);
    setBodySampleValue(bodyPlaceholderText);
    setSuccessText(null);
    setErrorText(null);
    setUniqueID(uniqueID + 1);
    switch (type) {
      case payloadConstants.request:
        setSaveButtonState(false);
        break;
      case payloadConstants.response:
        if (
          event.target.value !== contentTypeConstants.notApplicable &&
          type === payloadConstants.response &&
          responseCodeValue === ''
        ) {
          setSaveButtonState(true);
        }

        if (
          event.target.value !== contentTypeConstants.notApplicable &&
          type === payloadConstants.response &&
          responseCodeValue !== ''
        ) {
          setSaveButtonState(false);
        }

        if (event.target.value === contentTypeConstants.notApplicable) {
          setSaveButtonState(false);
          setResponseCodeValue('');
          changeResponseCodeValue('');
        }
        break;
    }
    setContentTypeValue(event.target.value);
    changeContentType(event);
  };

  const handleResponseCodeChange = (event) => {
    setSuccessText(null);
    setErrorText(null);
    if (
      !vaildResponseCodeRegex.test(event.target.value) ||
      (event.target.value === '' &&
        contentTypeValue !== contentTypeConstants.notApplicable)
    ) {
      setErrorText(toastMessages.invalidResponseCode.msg);
      setSaveButtonState(true);
    } else {
      setSaveButtonState(false);
    }
    setResponseCodeValue(event.target.value);
    changeResponseCodeValue(event.target.value);
  };

  return (
    <Box>
      <Typography
        variant='h6'
        className={
          isBuyerView
            ? title.indexOf('*') !== -1 || title !== 'Request Body'
              ? styles['no-bold-sample-title-preview']
              : styles['sample-title-preview']
            : styles['sample-title']
        }
      >
        {title.indexOf('*') !== -1 ? title.split('*')[0] : title}
        {title.indexOf('*') !== -1 && <span className='label-asterisk'>*</span>}
        {isMobilePortalView &&
          (title === 'Request Example' ||
            title === 'Request Body' ||
            title === 'Response Example') && (
            <LightTooltip
              open={tooltipHandler}
              onOpen={() => setTooltipHandler(true)}
              onClose={() => setTooltipHandler(false)}
              enterTouchDelay={0}
              title={'This editor is best experienced in desktop browsers only.'}
              aria-label='view'
            >
              <span
                onMouseOver={() => {
                  setTooltipHandler(true);
                }}
                //onclick for responsive mode
                onClick={() => {
                  setTooltipHandler(true);
                }}
              >
                <img
                  className={styles['info-icon-cursor-styling']}
                  src={Images.infoBgBlueIcon}
                  alt='Info'
                />
              </span>
            </LightTooltip>
          )}
      </Typography>
      <div
        className={`${styles['select-container']} ${(isBuyerView || isContentTypeDisabled) && styles['select-container-view']}`}
      >
        {contentTypeValue === contentTypeConstants.applicationJson &&
          isValidJSONString(body) && (
            <LightTooltip title={i18next.t('ENDPOINTS.FORMAT_JSON')}>
              <button
                className={styles['format-button']}
                onClick={handlePrettifyJson}
              >
                {'{ }'}
              </button>
            </LightTooltip>
          )}
        <FormControl className={`${styles['content-type-container']}`}>
          <InputLabel
            variant='standard'
            htmlFor='request-payload-content-type'
            className={`${styles['select-label']}`}
          >
            {i18next.t('DYNAMIC_FORM.ADD_API.CONTENT_TYPE')}
          </InputLabel>
          <NativeSelect
            className={styles['content-type-select-field']}
            classes={{
              root: styles['content-type-select-field-root'],
              disabled: styles['content-type-select-field-disabled'],
              select: styles['content-type-select-field-select']
            }}
            native
            disabled={
              (isBuyerView && type === payloadConstants.response) ||
              isContentTypeDisabled ||
              !isEditable
            }
            name='contentType'
            inputProps={{
              id: 'request-payload-content-type'
            }}
            IconComponent={() => (
              <i
                className={`${styles['content-type-select-field-icon']} a-icon ui-ic-down`}
              ></i>
            )}
            onChange={(event) => handleContentTypeChange(event)}
            value={contentTypeValue}
          >
            <option value={contentTypeConstants.notApplicable}>
              {i18next.t('DYNAMIC_FORM.ADD_API.PLEASE_SELECT')}
            </option>
            {contentTypeData.map((item) => {
              return (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='simple tabs example'
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab className={styles['samples-tab-panel']} label='Body' />
        <Tab
          disabled={isSchemaDisabled}
          className={styles['samples-tab-panel']}
          label='Schema'
        />
      </Tabs>
      {type === payloadConstants.response && (
        <div className={`${styles['response-select-container']}`}>
          <TextField
            className={styles['content-type-select-field']}
            classes={{
              root: styles['response-code-text-field'],
              disabled: styles['content-type-select-field-disabled']
            }}
            size='small'
            required
            disabled={
              (isBuyerView && type === payloadConstants.response) ||
              contentTypeValue === contentTypeConstants.notApplicable ||
              !isEditable
            }
            name='responseCode'
            placeholder={i18next.t('DYNAMIC_FORM.ADD_API.EX')}
            error={
              errorText &&
              errorText === toastMessages.invalidResponseCode.msg &&
              responseCodeValue
            }
            helperText={
              errorText &&
              errorText === toastMessages.invalidResponseCode.msg &&
              responseCodeValue
                ? toastMessages.invalidResponseCode.msg
                : ''
            }
            variant='filled'
            label={i18next.t('DYNAMIC_FORM.ADD_API.RESPONSE_CODE')}
            InputLabelProps={{
              shrink: true,
              className: styles['response-code-label']
            }}
            onChange={(event) => handleResponseCodeChange(event)}
            value={responseCodeValue}
            InputProps={{ disableUnderline: true }}
            inputProps={{ minLength: 3, maxLength: 3 }}
          ></TextField>
        </div>
      )}

      <TabPanel value={value} index={0}>
        <EditorBlock
          height='40vh'
          theme='vs-dark'
          defaultLanguage={lang}
          defaultValue={bodyPlaceholderText}
          value={body}
          onChangeHandler={(value) => setBodyValue(value)}
          editOption={editOption}
          fontFamily={'none'}
          path={
            uniqueID +
            endPointName?.replace(/[^a-zA-Z ]/g, '') +
            title +
            lang +
            contentTypeValue
          }
          onFocusHandler={(value) => {
            if (
              (type === payloadConstants.response && stateRefCode.current !== '') ||
              type === payloadConstants.request
            ) {
              if (
                value === bodyPlaceholderText &&
                stateRefContentType.current !== contentTypeConstants.notApplicable
              ) {
                setBody('');
              }
            }
          }}
          onBlurHandler={(value) => {
            if (value === '') {
              setBody(bodyPlaceholderText);
            }
          }}
        />
        {((!isBuyerView &&
          errorText &&
          errorText !== toastMessages.invalidResponseCode.msg) ||
          (isBuyerView && type === payloadConstants.request && errorText)) && (
          <Stack direction={'row'} spacing={1} alignItems='center'>
            <span>
              <i
                className={`a-icon ui-ic-alert-info ${styles['error-text-icon']}`}
              ></i>
            </span>
            <span className={styles['error-text']}>{errorText}</span>
          </Stack>
        )}
        {((!isBuyerView && successText) ||
          (isBuyerView && type === payloadConstants.request && successText)) && (
          <Stack direction={'row'} spacing={1} alignItems='center'>
            <span>
              <i
                className={`a-icon ui-ic-alert-success ${styles['success-text-icon']}`}
              ></i>
            </span>
            <span className={styles['success-text']}>{successText}</span>
          </Stack>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TextareaAutosize
          aria-label='empty textarea'
          disabled={
            (isBuyerView && type === payloadConstants.response) || !isEditable
          }
          placeholder={schemaPlaceholderText}
          className={styles['text-area-style']}
          maxRows={15}
          onChange={(event) => setSchemaValue(event, type)}
          value={schemaValue}
        />
        {errorText && (
          <Stack direction={'row'} spacing={1} alignItems='center'>
            <span>
              <i
                className={`a-icon ui-ic-alert-info ${styles['error-text-icon']}`}
              ></i>
            </span>
            <span className={styles['error-text']}>{errorText}</span>
          </Stack>
        )}
        {successText && (
          <Stack direction={'row'} spacing={1} alignItems='center'>
            <span>
              <i
                className={`a-icon ui-ic-alert-success ${styles['success-text-icon']}`}
              ></i>
            </span>
            <span className={styles['success-text']}>{successText}</span>
          </Stack>
        )}
      </TabPanel>
    </Box>
  );
};

EndpointBodyPayload.propTypes = {
  title: PropTypes.string.isRequired,
  selectedContentType: PropTypes.string.isRequired,
  selectedResponseCode: PropTypes.string,
  contentTypes: PropTypes.object.isRequired,
  bodyPlaceholderText: PropTypes.string.isRequired,
  schemaPlaceholderText: PropTypes.string.isRequired,
  type: PropTypes.oneOf([payloadConstants.request, payloadConstants.response])
    .isRequired,
  isSchemaDisabled: PropTypes.bool.isRequired,
  isBuyerView: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  bodyValue: PropTypes.string.isRequired,
  schemaValue: PropTypes.string.isRequired,
  isContentTypeDisabled: PropTypes.bool.isRequired,
  isRequiredParameter: PropTypes.bool.isRequired
};

export default EndpointBodyPayload;
