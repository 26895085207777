import axios from 'axios';
import * as Constants from './configuration';
import IdConstants from '../data/idConstants';

export default function uploadFile(imageFile, productId, allFiles, docTypeId) {
  let actionType;
  let bodyFormData = new FormData();
  bodyFormData.append('data', imageFile);
  bodyFormData.append('categoryId', IdConstants.api.categoryId);
  bodyFormData.append('documentTypeId', docTypeId);
  if (docTypeId === IdConstants.documentType.videoFile) {
    actionType = 'videoFiles';
  } else {
    actionType = 'uploadFiles';
  }
  return (dispatch) =>
    axios({
      method: 'POST',
      url: Constants.FILE_UPLOAD.UPLOADFILE + productId + '/files',
      data: bodyFormData,
      actionType: actionType,
      docTypeId: docTypeId
    })
      .then(() => {
        if (docTypeId === IdConstants.documentType.thumbnailImg) {
          dispatch({
            type: 'UPLOAD_THUMNAIL_FILES',
            payload: allFiles
          });
        } else {
          dispatch({
            type: 'UPLOAD_TECH_FILES',
            payload: allFiles
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'UPLOAD_FILES_ERR',
          payload: error
        });
        dispatch({
          type: 'UPLOAD_FILES_ERR_STATUS',
          payload: error?.response?.data
        });
      });
}

export function deleteFile(productId, allFiles, file, docTypeId) {
  return (dispatch) =>
    axios
      .delete(
        Constants.FILE_UPLOAD.UPLOADFILE +
          productId +
          '/files?data=' +
          encodeURIComponent(file.name) +
          '&categoryId=' +
          IdConstants.api.categoryId +
          '&documentTypeId=' +
          docTypeId,
        {
          headers: {
            'Content-Type': 'application/json',
            Validate: 'false'
          },
          data: {},
          actionType: 'uploadFiles',
          docTypeId: docTypeId
        }
      )
      .then(() => {
        if (docTypeId === IdConstants.documentType.thumbnailImg) {
          dispatch({
            type: 'DELETE_THUMBNAIL_FILES',
            payload: []
          });
        } else {
          dispatch({
            type: 'DELETE_TECH_FILES',
            payload: allFiles
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error
        });
      });
}

export function addVideoLink(videoLinkURL, productId, docTypeId, methodType) {
  let bodyFormData = new FormData();
  bodyFormData.append('link', videoLinkURL);
  bodyFormData.append('categoryId', IdConstants.api.categoryId);
  bodyFormData.append('documentTypeId', docTypeId);

  return (dispatch) =>
    axios({
      method: methodType,
      url: Constants.FILE_UPLOAD.UPLOADFILE + productId + '/filesLink',
      data: bodyFormData,
      docTypeId: docTypeId
    })
      .then((response) => {
        dispatch({
          type: 'UPLOAD_VIDEO_LINKS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'UPLOAD_VIDEO_LINKS_ERR',
          payload: error
        });
      });
}

export function resetFiles() {
  return {
    type: 'RESET_FILES'
  };
}
