import React, { useState, useRef, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Errors from '../errors';
import CheckBox from '../check-box';
import { handleMaxCountText } from '../../../shared/shared-function/helperFunctions';

import './index.scss';

const MultiSelect = ({
  description,
  options,
  displayName,
  classname,
  disabled,
  values,
  handleChange,
  error,
  name,
  placeHolder,
  validation
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dropdownOption, setDropdownOption] = useState([]);

  let val = {};
  let length = {};
  let required = {};
  if (validation) {
    val = validation;
    const { rule } = val;
    length = rule?.length;
    required = rule?.required;
  }

  useEffect(() => {
    setDropdownOption(options);
  }, [options]);

  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownWrapperRef.current &&
        !dropdownWrapperRef.current.contains(event.target)
      ) {
        onClickOutside();
      }
    };

    const onClickOutside = () => {
      setShowDropdown(false);
    };

    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event);
    });
    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event);
      });
    };
  }, []);

  const handleDropdownOption = () => {
    if (disabled) return;
    setShowDropdown((prevState) => !prevState);
  };

  const handleClick = (item) => {
    const selectedValueCpy = [...values];
    const findIndex =
      values &&
      values?.length > 0 &&
      values.findIndex((value) => value.value === item.value);

    if (values?.length > 0 && findIndex >= 0) {
      selectedValueCpy.splice(findIndex, 1);
    } else {
      if (length?.max && length?.max?.toString() === values?.length?.toString()) {
        return;
      }
      selectedValueCpy.push(item);
    }

    const data = {
      value: selectedValueCpy,
      error: '',
      name: name
    };
    if (selectedValueCpy.length > 0 && selectedValueCpy.length < length?.min) {
      data.error = `${displayName} (${i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MIN'
      )} - ${length?.min}) ${i18next
        .t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ALLOWED')
        .toLowerCase()}.`;
    } else if (
      selectedValueCpy.length > 0 &&
      selectedValueCpy.length > length?.max
    ) {
      data.error = `${displayName} (${i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX'
      )} - ${length?.max}) ${i18next
        .t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ALLOWED')
        .toLowerCase()}.`;
    }

    handleChange(data);
    handleDropdownOption();
    setSearchValue('');
  };

  const handleRemove = (item) => {
    const filteredValues = values.filter(
      (filterData) => filterData.value !== item.value
    );
    const data = {
      value: filteredValues,
      error: '',
      name: name,
      removedItem: item
    };

    if (required?.isRequired && filteredValues.length === 0) {
      data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
      data.isInValid = true;
    }

    if (filteredValues.length > 0 && filteredValues.length < length?.min) {
      data.error = `${displayName} (${i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MIN'
      )} - ${length?.min}) ${i18next
        .t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ALLOWED')
        .toLowerCase()}.`;
    } else if (filteredValues.length > 0 && filteredValues.length > length?.max) {
      data.error = `${displayName} (${i18next.t(
        'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX'
      )} - ${length?.max}) ${i18next
        .t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ALLOWED')
        .toLowerCase()}.`;
    }
    handleChange(data);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setShowDropdown(true);
    setSearchValue(value);

    const filteredVal = options.filter((entry) =>
      Object.values(entry).some(
        (filteredValue) =>
          typeof filteredValue === 'string' &&
          filteredValue.toLowerCase().includes(value.toLowerCase())
      )
    );
    setDropdownOption(filteredVal);
  };

  const isDisabled = (isSelected) => {
    return (
      !isSelected &&
      length?.max &&
      length?.max?.toString() === values?.length?.toString()
    );
  };

  return (
    <div className={`multi-select ${classname}`} ref={dropdownWrapperRef}>
      <div className='multi-select__wrapper'>
        <div
          className='a-text-field a-text-field--search'
          onClick={handleDropdownOption}
        >
          <label className='multi-select__label' htmlFor={name}>
            {displayName}
            {required?.isRequired && (
              <span className='multi-select__required'>*</span>
            )}
          </label>
          <input
            disabled={disabled}
            placeholder={values?.length !== 0 ? '' : placeHolder}
            type='text'
            id={name}
            value={searchValue}
            onChange={handleSearch}
            data-testid={name}
          />

          <button type='submit' className='a-text-field__icon-search'>
            <i className='a-icon arrow boschicon-bosch-ic-down' title='down' />
          </button>
        </div>
      </div>
      {showDropdown && (
        <div className='multi-select__options'>
          {dropdownOption &&
            dropdownOption.map((option, index) => {
              const isSelected =
                values && values.some((item) => item.value === option.value);
              return (
                <div
                  className='multi-select__option'
                  onClick={() => handleClick(option)}
                  key={option.id}
                  data-testid={option.value + index}
                >
                  <CheckBox
                    displayName={option.value}
                    className='multi-select__option-checkbox ms-pointer'
                    checked={isSelected}
                    disabled={isDisabled(isSelected)}
                  />
                </div>
              );
            })}

          {(dropdownOption?.length === 0 || !dropdownOption) && (
            <p>{i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.NO_DATA_FOUND')}</p>
          )}
        </div>
      )}
      {error ? (
        <Errors errorMessaage={error} />
      ) : (
        <div className='input__details'>
          <p className='input__addinal-info -size-s'>{description}</p>
          {length?.max && (
            <p className='input__addinal-info input__max-char -size-s'>{`${i18next.t(
              'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX'
            )} - ${handleMaxCountText(length?.max, values.length, true)}`}</p>
          )}
        </div>
      )}
      {values?.length !== 0 &&
        displayName !== i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.API') && (
          <div className='multi-select__selected-values'>
            {values.map((data) => (
              <div
                tabIndex='0'
                className='a-chip -btnClose -image multi-select__chip'
                role='button'
                aria-labelledby='chip-label-id-close-button image'
                key={data.value}
                onClick={() => handleRemove(data)}
                data-testid={data.value}
              >
                <div id='chip-label-id-close-button image' className='a-chip__label'>
                  {data.value}
                </div>
                <div className='a-chip__close'></div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  classname: PropTypes.string,
  disabled: PropTypes.bool,
  isElementRequired: PropTypes.bool,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  values: PropTypes.array
};

MultiSelect.defaultProps = {
  options: [],
  label: '',
  classname: '',
  disabled: false,
  isElementRequired: false,
  id: '',
  error: '',
  placeHolder: 'Select item',
  values: []
};

export default MultiSelect;
