import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import RichTextEditor from '../../../components/Shared/RichTextEditor';
import Errors from '../errors';
import { isValidJSONString } from '../../../utils';
import { DynamicFieldConstants } from '../../../shared/constants';
import './index.scss';

const RichTextEditorInput = (props) => {
  const [editorValue, setEditorValue] = useState('');
  const [isEditValue, setIsEditValue] = useState(true);
  const [charCountEntered, setCharCountEntered] = useState(0);

  const {
    value,
    description,
    displayName,
    name,
    validation,
    handleChange,
    error,
    placeHolder,
    disabled
  } = props;

  useEffect(() => {
    if (value && isEditValue) {
      setEditorValue(value);
      setIsEditValue(true);
    }
  }, [value]);

  let val = {};
  let length = {};
  let regex = {};
  let required = {};
  if (validation) {
    val = validation;
    const { rule } = val;
    length = rule?.length ? rule?.length : { max: 4000 };
    regex = rule?.regex;
    required = rule?.required;
  }

  const validateInputs = (richTextValue) => {
    const data = {
      value: JSON.stringify(richTextValue),
      error: '',
      name: name
    };
    const parsedVal = handleGetTextValue(richTextValue);
    const regexExp = new RegExp(regex?.pattern, 'gmu');
    switch (true) {
      case parsedVal.length === 0 &&
        props.attributeType == DynamicFieldConstants.TEXT_AND_FILE &&
        required?.errorMessage &&
        (!props.attributes?.[0]?.value ||
          props.attributes?.[0]?.value?.length === 0):
      case parsedVal.length === 0 &&
        props.attributeType !== DynamicFieldConstants.TEXT_AND_FILE &&
        required?.isRequired:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
        data.isInValid = true;
        return data;
      case parsedVal.length > 0 &&
        regexExp &&
        regexExp.toString().length > 0 &&
        !regexExp?.test(parsedVal):
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_INVALID')}.`;
        data.isInValid = true;
        return data;
      case parsedVal.length > 0 && parsedVal.length < length?.min:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_TOO_SHORT')} (${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MIN_CHAR')} - ${length?.min}).`;
        data.isInValid = true;
        return data;
      case parsedVal.length > 0 && parsedVal.length > length?.max:
        data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_TOO_LONG')} (${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} - ${length?.max}).`;
        data.isInValid = true;
        return data;
      default:
        return data;
    }
  };

  const handleInputChange = (richTextValue) => {
    setIsEditValue(false);
    const data = validateInputs(richTextValue);
    if (data?.error?.length === 0) {
      handleChange?.(data);
    }
  };

  const handlevalidation = (event) => {
    const data = validateInputs(event?.rawJsObject);
    data.value = event?.hasText ? data.value : '';
    data.eventType = 'blur';
    handleChange?.(data);
  };

  const isRequiredField = () => {
    if (props.attributeType === DynamicFieldConstants.TEXT_AND_FILE) {
      return (
        required?.errorMessage &&
        (!props.attributes?.[0]?.value || props.attributes?.[0]?.value?.length === 0)
      );
    } else {
      return required?.isRequired;
    }
  };

  const handleGetTextValue = (textValue) => {
    const values = [];
    if (textValue?.blocks) {
      for (const item of textValue.blocks) {
        if (item.text !== '') {
          values.push(item.text);
        }
      }
    }
    return values.join('');
  };

  return (
    <div className='rich-text-editor'>
      <label className='rich-text-editor__label'>
        {displayName}
        {isRequiredField() && <span className='rich-text-editor__required'>*</span>}
      </label>
      <RichTextEditor
        setCharCountEntered={setCharCountEntered}
        value={editorValue ? editorValue : ''}
        placeholder={placeHolder}
        inlineBlockStyles={[
          'BOLD',
          'ITALIC',
          'UNDERLINE',
          'unordered-list-item',
          'ordered-list-item'
        ]}
        headerStyles={[
          'Paragraph',
          'Heading 1',
          'Heading 2',
          'Heading 3',
          'Heading 4',
          'Heading 5',
          'Heading 6'
        ]}
        linkToBeShown={true}
        onChangeEditor={() => {}}
        storeToRedux={() => {}}
        handleInputChange={(richTextValue) => handleInputChange(richTextValue)}
        isValidJSONString={isValidJSONString}
        isMandatoryField={false}
        validationText={''}
        onBlureEditor={handlevalidation}
        disabled={disabled}
        maxLength={length?.max ? length?.max : 4000}
      />
      {error ? (
        <Errors errorMessaage={error} />
      ) : (
        <div className='input__details'>
          <p className='input__addinal-info -size-s'>{description}</p>
          {length?.max && (
            <p className='input__addinal-info input__max-char -size-s'>{`${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_CHAR')} -
            ${length?.max - charCountEntered}`}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default RichTextEditorInput;
