import axios from 'axios';
import { GET_TENANT_NAME } from '../../actions/configuration';
import * as ACTION_TYPES from '../ActionTypes';

export function getTenantList(productId, regionCode) {
  let url = `${GET_TENANT_NAME}/${productId}/publish`;

  if (regionCode) {
    url += `?regionCode=${regionCode}`;
  }

  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER,
      payload: true
    });
    return axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.GET_TENANT_NAME,
          payload: response.data
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: ACTION_TYPES.SET_LOADER,
          payload: false
        });
      });
  };
}

export function clearTenantList() {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_TENANT_LIST',
      payload: []
    });
  };
}
