import React from 'react';

import OrderCard from '../order-card';

import './index.scss';

const OrderListing = () => {
  return <OrderCard isListing={true} />;
};

export default OrderListing;
