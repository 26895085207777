import React from 'react';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { NavLink } from 'react-router-dom';
import './breadcrumb.css';
import variables from '../../../../variables.module.scss';

export default function Breadcrumb(props) {
  return (
    <div className='App breadcrumb-style'>
      <Breadcrumbs
        separator={<b> &gt; </b>}
        item={NavLink}
        finalProps={{
          style: {
            margin: '0.5rem',
            fontFamily: variables.lightBoschFont,
            textDecoration: 'none',
            color: '#000000',
            fontWeight: 'bold',
            cursor: 'initial',
            pointerEvents: 'none' // to prevent click events on last element in breadcrumb
          }
        }}
      />
      {props.children}
    </div>
  );
}
