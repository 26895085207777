const initialState = {
  loader: false
};
const LoaderReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_LOADER_PREVIEW':
      return Object.assign({}, state, { loader: action.data });
    case 'HIDE_LOADER_PREVIEW':
      return Object.assign({}, state, { loader: action.data });
    default:
      return state;
  }
};

export default LoaderReducer_Preview;
