import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../../../../msreact-kit/html-components/button';
import { responseCodeHeading } from '../../../../../../data/appConstants';
import '../index.scss';
import { StatusCodes } from 'http-status-codes';
import i18next from 'i18next';
import ChargeableCodesTable from '../../../../DataDisplay/Table';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Httpcodes(props) {
  const httpSeries = [
    {
      id: '2',
      name: '2xx',
      label: '2XX',
      httpCodes: []
    },
    {
      id: '4',
      name: '4xx',
      label: '4XX',
      httpCodes: []
    },
    {
      id: '5',
      name: '5xx',
      label: '5XX',
      httpCodes: []
    }
  ];

  const [updatedSeries] = useState([]);
  const [newRow, setNewRow] = useState([
    {
      statusCodeClass: '2xx',
      value: ['200', '201', '202', '203', '204', '205', '206', '207'],
      isApplyAll: true,
      statusCodeDisable: true,
      responseCodesDisable: true,
      responseCodesData: ['200', '201', '202', '203', '204', '205', '206', '207'],
      saveIcon: false,
      _id: ''
    }
  ]);
  const [isCodeDropdownOpen, setIsCodeDropdownOpen] = useState(false);
  const [disableIcon, setDisableIcon] = useState(props?.disableEdit);
  const [saveRowIcon, setSaveRowIcon] = useState(true);
  const [selectedHttpCodes, setSelectedHttpCodes] = useState([]);
  const [index, setIndex] = useState();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(props?.disableEdit);
  const [disableCls, setDisabledCls] = useState([]);

  const codes = Object.keys(StatusCodes);
  const numberArray = [];
  codes.forEach((eachItem) => {
    if (!isNaN(eachItem)) {
      numberArray.push(eachItem);
    }
  });

  useEffect(() => {
    if (updatedSeries.length != 3) {
      httpSeries.forEach((id) => {
        numberArray.forEach((num) => {
          if (num.split('')[0] === id.id) id.httpCodes.push(num);
        });
        updatedSeries.push(id);
      });
    }
  }, [updatedSeries]);

  useEffect(() => {
    if (props?.chargeableCodes != undefined) {
      if (props?.chargeableCodes?.length == 0) {
        setNewRow([]);
      } else {
        let temparray = [];
        props?.chargeableCodes?.forEach((item) => {
          let value = [];
          let responseCodesData = updatedSeries.filter(
            (id) => id.name == item.statusCodeClass
          )[0].httpCodes;
          if (item.isApplyAll == 0) {
            value = item.value.split(',');
          } else {
            value = responseCodesData;
          }
          temparray.push({
            statusCodeClass: item.statusCodeClass,
            value: value,
            isApplyAll: item.isApplyAll == 0 ? false : true,
            statusCodeDisable: true,
            responseCodesDisable: true,
            responseCodesData: responseCodesData,
            saveIcon: false,
            _id: item._id
          });
          return true;
        });
        setNewRow(temparray);
      }
    }
  }, [props?.chargeableCodes, updatedSeries]);

  const handleAddRow = () => {
    const item = {
      statusCodeClass: '',
      value: [],
      isApplyAll: '',
      statusCodeDisable: '',
      responseCodesDisable: '',
      responseCodesData: [],
      saveIcon: true,
      _id: ''
    };
    setSaveRowIcon(true);
    setButtonEnable(true);
    setDisableIcon(true);
    props.disableSaveButton(true);
    setNewRow([...newRow, item]);
  };

  const updatePayload = (event, index) => {
    const row = [...newRow];
    row[index] = {
      ...row[index],
      statusCodeClass: event.target.value,
      responseCodesData: updatedSeries.filter(
        (item) => item.name === event.target.value
      )[0].httpCodes
    };
    setNewRow(row);
  };

  const handleSaveRow = (index) => {
    const row = [...newRow];
    setButtonEnable(false);
    props.disableSaveButton(false);
    row[index] = {
      ...row[index],
      statusCodeDisable: true,
      responseCodesDisable: true,
      saveIcon: false
    };
    setNewRow(row);
    setIsAllSelected(false);
    setSaveRowIcon(false);
    setDisableIcon(false);
  };

  const handleEditRow = (index) => {
    const row = [...newRow];
    row[index] = {
      ...row[index],
      responseCodesDisable: false,
      saveIcon: true
    };
    setNewRow(row);
    setSaveRowIcon(false);
    setDisableIcon(true);
    setButtonEnable(true);
    props.disableSaveButton(true);
  };

  useEffect(() => {
    if (index >= 0) {
      const row = [...newRow];
      row[index] = {
        ...row[index],
        value: selectedHttpCodes,
        isApplyAll:
          selectedHttpCodes.length > 0 &&
          selectedHttpCodes.length === newRow[index].responseCodesData.length
      };
      setNewRow(row);
    }
  }, [selectedHttpCodes]);

  const handleDeleteRow = (index) => {
    const row = [...newRow];
    row.splice(index, 1);
    setNewRow(row);
    setButtonEnable(false);
    props.disableSaveButton(false);
    setIsAllSelected(false);
    setDisableIcon(false);
  };

  useEffect(() => {
    let tempArray = [];
    if (newRow.length > 0) {
      newRow.forEach((id) => {
        tempArray.push(id.statusCodeClass);
      });
    }
    if (newRow.length >= 3) {
      newRow.forEach((id) => {
        if (!id.saveIcon) {
          setButtonEnable(false);
        }
        setButtonEnable(true);
      });
    }
    let finalArrayItems = newRow
      ?.map((item) => {
        if (item.statusCodeClass && item.value) {
          if (item._id) {
            if (item.isApplyAll) {
              return {
                statusCodeClass: item.statusCodeClass,
                isApplyAll: item.isApplyAll ? '1' : '0',
                _id: item._id
              };
            } else {
              return {
                statusCodeClass: item.statusCodeClass,
                value: item.value.toString(),
                isApplyAll: item.isApplyAll ? '1' : '0',
                _id: item._id
              };
            }
          } else {
            if (item.isApplyAll) {
              return {
                statusCodeClass: item.statusCodeClass,
                isApplyAll: item.isApplyAll ? '1' : '0'
              };
            } else {
              return {
                statusCodeClass: item.statusCodeClass,
                value: item.value.toString(),
                isApplyAll: item.isApplyAll ? '1' : '0'
              };
            }
          }
        }
        return null;
      })
      .filter((value) => value !== null);

    setDisabledCls(tempArray);
    props.updateChargeableCodes(finalArrayItems);
  }, [newRow]);

  const handleDropdownToggle = () => {
    setIsCodeDropdownOpen(!isCodeDropdownOpen);
  };

  const handleChange = (event, index) => {
    const { value, checked } = event.target;
    setIndex(index);
    setSaveRowIcon(false);
    if (value === 'all') {
      if (checked) {
        setIsAllSelected(true);
        setSelectedHttpCodes(newRow[index].responseCodesData);
        return;
      } else {
        setIsAllSelected(false);
        setSelectedHttpCodes([]);
        setSaveRowIcon(true);
      }
    } else {
      let tempArray = newRow[index].value;
      if (checked) {
        tempArray.push(event.target.value);
      } else {
        tempArray = tempArray.toSpliced(tempArray.indexOf(event.target.value), 1);
      }
      setSelectedHttpCodes(tempArray);
      if (tempArray.length === newRow[index].responseCodesData.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
      if (tempArray.length == 0) setSaveRowIcon(true);
    }
  };

  return (
    <>
      <div className='chargeable-section-container'>
        <div className='chargeable-section-wrapper'>
          <p className='response-code-heading'>
            {i18next.t('ENDPOINTS.RESPONSE_CODE_TEXT')}
          </p>
          <p className='response-code-caption'>
            {i18next.t('ENDPOINTS.STATUS_CODE_TEXT')}
          </p>
        </div>
        <div className='add-button-wrapper'>
          <PrimaryButton
            label='Add'
            onClick={handleAddRow}
            disabled={buttonEnable}
          />
        </div>
      </div>
      <div className='http-code-table-container'>
        <ChargeableCodesTable
          header={responseCodeHeading}
          data={[]}
          table='chargeableTable'
          updatedSeries={updatedSeries}
          newRow={newRow}
          updatePayload={updatePayload}
          {...props}
          disableCls={disableCls}
          saveRowIcon={saveRowIcon}
          handleSaveRow={handleSaveRow}
          handleDeleteRow={handleDeleteRow}
          handleDropdownToggle={handleDropdownToggle}
          isCodeDropdownOpen={isCodeDropdownOpen}
          setIsCodeDropdownOpen={setIsCodeDropdownOpen}
          handleChange={handleChange}
          selectedHttpCodes={selectedHttpCodes}
          isAllSelected={isAllSelected}
          handleEditRow={handleEditRow}
          disableIcon={disableIcon}
        />
      </div>
      {newRow.length <= 0 && (
        <div className='info-text-no-data'>
          <InfoOutlinedIcon
            title='alert-info'
            fontSize='medium'
            sx={{ color: '#007BC0', stroke: '#FFFFFF', strokeWidth: 1 }}
          />{' '}
          &nbsp;&nbsp;
          {i18next.t('ENDPOINTS.WHEN_NO_CHARGEABLE_CODES_SELECTED')}{' '}
        </div>
      )}
    </>
  );
}
