import axios from 'axios';
import { BOSCH_SYSTEM } from '../configuration';

export const getProductDetailsFromBoschSystem = async (payload) => {
  const response = await axios({
    method: 'GET',
    url: `${BOSCH_SYSTEM.GET_PRODUCT_DETAILS}?productUniqueId=${payload.productId}`,
    data: {},
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};
