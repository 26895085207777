import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { SecondaryButton, UpDownArrow } from '../../packages/html-components';
import Images from '../../../assets/img';
import generateRandomId from '../../../utils/generate-random-id';
import './index.scss';

const GroupingCard = (props) => {
  const [groupData, setGroupData] = useState([]);
  const [groupValidation, setGroupValidation] = useState({});
  const [activeId, setActiveId] = useState('');

  const { group, handleGetFieldInput, handleGroupData, isView, handleDeleteGroup } =
    props;

  useEffect(() => {
    if (group) {
      setGroupData(group);
      const validations = {};
      const parseValidation = group[0]?.validation;
      if (parseValidation?.rule && parseValidation?.rule?.length) {
        Object.values(parseValidation?.rule).forEach((item) =>
          Object.keys(item).forEach((data) => (validations[data] = item[data]))
        );
      }
      setGroupValidation(validations);
    }
  }, [group]);

  const handleGrouping = (id) => {
    setActiveId(id);
  };

  const handleAddItem = () => {
    const addItem = JSON.parse(JSON.stringify(group[0]));
    addItem.name = addItem.name + generateRandomId();

    const fieldData = addItem.attributes.map((fieldItem) => {
      fieldItem.error = '';
      fieldItem.value = '';
      fieldItem._id = null;
      fieldItem.attachmentKey = null;
      fieldItem.productDocMetaInfoId = null;
      return fieldItem;
    });
    addItem.attributes = fieldData;
    const groupDataCpy = [...group, addItem];
    setGroupData(JSON.parse(JSON.stringify(groupDataCpy)));
    handleGroupData(JSON.parse(JSON.stringify(groupDataCpy)));
  };

  const handleDelete = (id, item) => {
    const isMinVal = handleDisableBtn(groupValidation.min);
    if (isMinVal || groupData?.length === 1) {
      return;
    }
    const filteredGroup = groupData.filter((data, index) => index !== id);
    const groupDataCpy = [...filteredGroup];
    setGroupData(groupDataCpy);
    if (handleDeleteGroup) {
      const groupId = item;
      if (groupId) {
        handleDeleteGroup(groupId, groupDataCpy);
      }
    } else {
      handleGroupData(groupDataCpy);
    }
  };

  const handleOrderUp = (id) => {
    const groupDataCpy = [...groupData];

    const fromIndex = groupDataCpy.findIndex((data) => data.name === id);
    const toIndex = fromIndex - 1;

    if (fromIndex > 0 && fromIndex < groupDataCpy.length) {
      const element = groupDataCpy.splice(fromIndex, 1)[0];
      groupDataCpy.splice(toIndex, 0, element);
    }
    setGroupData(groupDataCpy);
    handleGroupData(groupDataCpy);
    handleGrouping();
  };

  const handleOrderDown = (id) => {
    const groupDataCpy = [...groupData];

    const fromIndex = groupDataCpy.findIndex((data) => data.name === id);
    const toIndex = fromIndex + 1;

    if (fromIndex >= 0 && fromIndex < groupDataCpy.length - 1) {
      const element = groupDataCpy.splice(fromIndex, 1)[0];
      groupDataCpy.splice(toIndex, 0, element);
    }
    setGroupData(groupDataCpy);
    handleGroupData(groupDataCpy);
    handleGrouping();
  };

  const handleDisableBtn = (validtionType) => {
    return validtionType === Number(groupData?.length);
  };

  return (
    <div className='groupingCard'>
      {groupData && groupData[0]?.displayName && (
        <h1 className='groupingCard__title'>
          {groupData && groupData[0]?.displayName}
        </h1>
      )}
      {group?.map(
        (item, index) =>
          item?.attributes?.length > 0 && (
            <div className='groupingCard__container'>
              <div className='groupingCard__content-container'>
                <div className='groupingCard__index-container'>
                  <p>{index + 1}.</p>
                </div>
                <div className='groupingCard__content'>
                  {item.attributes.map((field) =>
                    handleGetFieldInput({
                      ...field,
                      group: true,
                      groupID: item.name
                    })
                  )}
                </div>
                <div className='groupingCard__image-container'>
                  {!isView && group?.length > 1 && (
                    <UpDownArrow
                      activeId={activeId}
                      handleGrouping={handleGrouping}
                      handleOrderUp={handleOrderUp}
                      handleOrderDown={handleOrderDown}
                      id={item.name}
                    />
                  )}
                  {!isView && group?.length > 1 && (
                    <img
                      src={Images.deleteIcon}
                      className={`groupingCard__image  ${
                        group?.length === 1 && 'ms-disabled'
                      }`}
                      alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETEICON')}
                      onClick={() => handleDelete(index, item)}
                    />
                  )}
                </div>
              </div>
            </div>
          )
      )}

      {!isView && (
        <SecondaryButton
          disabled={
            groupValidation?.max
              ? handleDisableBtn(groupValidation.max)
              : groupData?.length === 10
          }
          onClick={handleAddItem}
          label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')}
        />
      )}
    </div>
  );
};

export default GroupingCard;

GroupingCard.propTypes = {
  isView: PropTypes.bool,
  handleDeleteGroup: PropTypes.func
};

GroupingCard.defaultProps = {
  isView: false
};
