import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ required, label, className, ...props }) => {
  return (
    <div className='a-radio-button'>
      <input type='radio' id='radio-button-1' value='test' required {...props} />
      <label htmlFor='radio-button-1'>{label}</label>
    </div>
  );
};

RadioButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool
};

RadioButton.defaultProps = {
  className: '',
  value: '',
  required: true
};

export default RadioButton;
