const initialState = {
  hardwareProductDetail: {}
};

const HardwareProductReducer = (state = initialState, action) => {
  if (action.type === 'GET_HARDWARE_PRODUCT_DETAILED_INFO') {
    return Object.assign({}, state, {
      hardwareProductDetail: action.payload
    });
  }

  return state;
};

export default HardwareProductReducer;
