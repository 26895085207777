import axios from 'axios';
import * as Constants from '../configuration';

export function getThrottlingPolicies() {
  return (dispatch) =>
    axios
      .get(Constants.API_THROTTLING_ACTION.THROTTLE_POLICY, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'THROTTLING_POLICIES',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function saveThrottlingPolicy(name, description) {
  let jsonObject = {};
  jsonObject.throttlePolicyName = name;
  jsonObject.description = description;
  return (dispatch) =>
    axios({
      method: 'POST',
      url: Constants.API_THROTTLING_ACTION.THROTTLE_POLICY,
      data: jsonObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'SAVE_THROTTLING_POLICY',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'SAVE_THROTTLING_POLICY_ERR',
            error: response
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function updateThrottlingPolicy(name, description, policyId) {
  let jsonObject = {};
  jsonObject.throttlePolicyName = name;
  jsonObject.description = description;
  return (dispatch) =>
    axios({
      method: 'PATCH',
      url: Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + '/' + policyId,
      data: jsonObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'SAVE_THROTTLING_POLICY',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          dispatch({
            type: 'SAVE_THROTTLING_POLICY_ERR',
            error: response
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function validateThrottlingPolicyName(policyName, policyId) {
  policyName = encodeURIComponent(policyName);
  let url =
    policyId !== ''
      ? `/validate?policyName=${policyName}&policyId=${policyId}`
      : `/validate?policyName=${policyName}`;
  return (dispatch) =>
    axios
      .get(Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + url, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'THROTTLE_POLICY_UNIQUE',
          payload: response.data,
          value: true
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch({
            type: 'THROTTLE_POLICY_UNIQUE',
            error: error.response,
            value: false
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function createThrottleConditionGroup(conditionObj, conditions, policyId) {
  let jsonObject = {};
  jsonObject.conditionGrpName = conditionObj.conditionGrpName;
  jsonObject.description = conditionObj.description;
  jsonObject.conditions = conditions;
  return (dispatch) =>
    axios
      .post(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
          `/${policyId}/throttleConditionGrp`,
        jsonObject,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch(getThrottlePolicyDetails(policyId));
        dispatch({
          type: 'CREATE_THROTTLE_CONDITION_GROUP',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          let data =
            response.data !== ''
              ? response.data
              : { message: 'Unable to create Throttle condition group' };
          dispatch({
            type: 'THROTTLE_CONDITION_GROUP_ERROR',
            error: data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function updateThrottleConditionGroup(conditionObj, conditions, policyId) {
  let jsonObject = {};
  jsonObject.conditionGrpName = conditionObj.conditionGrpName;
  jsonObject.description = conditionObj.description;
  jsonObject.conditions = conditions;
  return (dispatch) =>
    axios
      .patch(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
          `/${policyId}/throttleConditionGrp/${conditionObj.throttleConditionGrpId}`,
        jsonObject,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        dispatch(getThrottlePolicyDetails(policyId));
        dispatch({
          type: 'CREATE_THROTTLE_CONDITION_GROUP',
          payload: response.data
        });
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          let data =
            response.data !== ''
              ? response.data
              : { message: 'Unable to update Throttle condition group' };
          dispatch({
            type: 'THROTTLE_CONDITION_GROUP_ERROR',
            error: data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function validateConditionGroupName(data, policyId) {
  let conditionGroupName = encodeURIComponent(data.conditionGrpName);
  let url = `/${policyId}/throttleConditionGrp/validate?conditionGrpName=${conditionGroupName}&conditionGrpId=${data.throttleConditionGrpId}`;
  return (dispatch) =>
    axios
      .get(Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + url, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'CONDITION_GROUP_NAME_UNIQUE',
          payload: response.data,
          value: true
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch({
            type: 'CONDITION_GROUP_NAME_UNIQUE',
            error: error.response,
            value: false
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function resetConditionGroupError() {
  return {
    type: 'CONDITION_GROUP_NAME_UNIQUE',
    value: true
  };
}

export function getThrottlePolicyDetails(policyId, flag) {
  return (dispatch) =>
    axios
      .get(Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + '/' + policyId, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        if (flag === 'true') {
          return response.data;
        }
        dispatch({
          type: 'THROTTLE_POLICY_DETAILS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function resetThrottleConditionGroups() {
  return {
    type: 'THROTTLE_POLICY_DETAILS',
    payload: {}
  };
}

export function getApiListForUser() {
  return (dispatch) =>
    axios
      .get(Constants.API_THROTTLING_ACTION.GET_THROTTLE_POLICY_API, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'THROTTLE_POLICY_USER_API_LIST',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getApiListForThrottlingPolicy(policyId) {
  return (dispatch) =>
    axios
      .get(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
          '/' +
          policyId +
          '/product',
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'THROTTLE_POLICY_ID_API_LIST',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function addApiToThrottlingPolicy(policyId, productId) {
  let jsonObject = {};
  let productIds = [];
  productIds.push(productId);
  jsonObject.productIds = productIds;
  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
        '/' +
        policyId +
        '/product',
      data: jsonObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch(getApiListForThrottlingPolicy(policyId));
        dispatch(getApiListForUser());
        dispatch(getPolicyIdForProduct(productId));
        dispatch({
          type: 'SAVE_THROTTLING_POLICY_API',
          payload: response.data
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch({
            type: 'SAVE_THROTTLING_POLICY_API_ERR',
            error: error.response
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function deleteApiFromThrottlingPolicy(policyId, productId) {
  return (dispatch) =>
    axios
      .delete(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
          '/' +
          policyId +
          '/product/' +
          productId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch(getApiListForThrottlingPolicy(policyId));
        dispatch(getApiListForUser());
        dispatch(getPolicyIdForProduct(productId));
        dispatch({
          type: 'DELETE_API_THROTTLING_POLICY',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function resetApiListForThrottlingPolicy() {
  return {
    type: 'THROTTLE_POLICY_ID_API_LIST',
    payload: []
  };
}

export function checkForTrottlePolicyConsmuption(policyId) {
  return (dispatch) =>
    axios
      .get(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + `/${policyId}/isConsumed`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then(() => {
        dispatch({
          type: 'THROTTLLE_POLICY_CONSUMPTION',
          value: false
        });
      })
      .catch(() => {
        dispatch({
          type: 'THROTTLLE_POLICY_CONSUMPTION',
          value: true
        });
      });
}

export function deleteThrottlePolicy(policyId) {
  return (dispatch) =>
    axios
      .delete(Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + '/' + policyId, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch(getThrottlingPolicies());
        dispatch({
          type: 'DELETE_THROTTLE_POLICY',
          payload: response.data
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch({
            type: 'DELETE_THROTTLE_POLICY_ERROR',
            error: error.response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function deleteThrottleConditionGroup(policyId, conditionGrpId) {
  return (dispatch) =>
    axios
      .delete(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
          `/${policyId}/throttleConditionGrp/${conditionGrpId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch(getThrottlePolicyDetails(policyId));
        dispatch({
          type: 'DELETE_THROTTLE_CONDITION_GROUP',
          payload: response.data
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch({
            type: 'DELETE_THROTTLE_CONDITION_GROUP_ERROR',
            error: error.response.data
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function getPolicyIdForProduct(productId) {
  return (dispatch) =>
    axios
      .get(
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY + '/product/' + productId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'PRODUCT_THROTTLE_POLICY_ID',
          payload: response.data === '' ? {} : response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function resetThrottlePolicy() {
  return {
    type: 'PRODUCT_THROTTLE_POLICY_ID',
    payload: {}
  };
}
export function resetThrottlePolicyConsumption() {
  return {
    type: 'THROTTLLE_POLICY_CONSUMPTION',
    value: false
  };
}

export function swapThrottlingPolicyForAPI(oldPolicyId, newPolicyId, productId) {
  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        Constants.API_THROTTLING_ACTION.THROTTLE_POLICY +
        `/product/${productId}?oldPolicyId=${oldPolicyId}&newPolicyId=${newPolicyId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: {}
    })
      .then((response) => {
        dispatch(getPolicyIdForProduct(productId));
        dispatch({
          type: 'SWAP_THROTTLING_POLICY_API',
          payload: response.data
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch({
            type: 'SWAP_THROTTLING_POLICY_API_ERR',
            error: error.response
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}
