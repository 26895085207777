import React, { useState } from 'react';
import Loader from '../../../Shared/Utils/Loader';
import i18next from 'i18next';
import ExcelJS from 'exceljs';
import { capsWithSpaceToTitleCase } from '../../../../utils';

const GenerateExcel = (props) => {
  const [loader, setLoader] = useState(false);
  const docDownload = true;

  const exportToExcel = (fileName, reportDataForExcel) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName);
    worksheet.columns = [
      {
        header: i18next.t('DYNAMIC_FORM.ADD_API.API_NAME'),
        key: 'apiName',
        width: 35
      },
      {
        header: capsWithSpaceToTitleCase(
          i18next.t('ANALYTICS_AND_TRACKING.TOTAL_API_REQUESTS')
        ),
        key: 'totalRequest',
        width: 20
      },
      {
        header: i18next.t('DYNAMIC_FORM.API_STATISTICS.CHARGEABLE_REQUESTS'),
        key: 'chargeableHits',
        width: 20
      },
      {
        header: i18next.t('DYNAMIC_FORM.API_STATISTICS.NON_CHARGEABLE_REQUESTS'),
        key: 'nonChargeableHits',
        width: 20
      },
      {
        header: capsWithSpaceToTitleCase(
          i18next.t('ANALYTICS_AND_TRACKING.BLOCKED_REQUESTS')
        ),
        key: 'blockedRequest',
        width: 20
      },
      {
        header: i18next.t('ANALYTICS_AND_TRACKING.AVERAGE_LATENCY'),
        key: 'averageLatency',
        width: 20
      },
      {
        header: i18next.t('ANALYTICS_AND_TRACKING.TOTAL_ACTIVE_SUBSCRIPTIONS'),
        key: 'subscribedUsers',
        width: 25
      }
    ];
    reportDataForExcel?.map((row) => worksheet.addRow(row));
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = fileName + '.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const createExcel = () => {
    setLoader(true);
    const reportDataForExcel = [];
    props
      .getApiStatistics(
        props?.showFromDateVal,
        props?.showToDateVal,
        props?.role,
        0,
        0,
        docDownload
      )
      .then((response) => {
        response?.data?.tracking?.forEach((data) => {
          const apiSummaryDataStats = data?.stats?.apiSummary;
          reportDataForExcel?.push({
            apiName: data?.apiName,
            totalRequest: apiSummaryDataStats?.totalReq,
            chargeableHits: apiSummaryDataStats?.chargable,
            nonChargeableHits: apiSummaryDataStats?.nonChargable,
            blockedRequest: apiSummaryDataStats?.blockedReq,
            averageLatency: apiSummaryDataStats?.avgLatency + 'ms',
            subscribedUsers: apiSummaryDataStats?.subscribedUsers
          });
        });
        reportDataForExcel?.length &&
          exportToExcel('APIStatistics', reportDataForExcel);
      });
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <div
        className='export-pdf'
        title={i18next.t('SHARED.EXPORT_TO_EXCEL')}
        onClick={() => createExcel()}
      >
        <i className='simple-menu boschicon-bosch-ic-document-xls -size-2xl search-icon'></i>
      </div>
    </>
  );
};

export default GenerateExcel;
