const DynamicFieldConstants = {
  TEXT: 'TEXT',
  TEXT_AREA: 'TEXTAREA',
  SINGLE_SELECT: 'SINGLESELECT',
  MULTI_SELECT: 'MULTISELECT',
  FILE: 'FILE',
  THUMB_IMAGE: 'THUMBIMAGE',
  COVER_IMAGE: 'COVERIMAGE',
  UPLOAD_IMAGE: 'UPLOADIMAGE',
  UPLOAD_VIDEO: 'UPLOADVIDEO',
  ATTACHMENT: 'ATTACHMENT',
  NUMBER: 'NUMBER',
  TOGGLE: 'TOGGLE',
  RICHTEXTEDITOR: 'RICHTEXTEDITOR',
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  QUOTATION: 'QUOTATION',
  OPTION: 'OPTION',
  GROUP: 'GROUP',
  HIDDEN: 'HIDDEN',
  TEXT_AND_FILE: 'TEXTANDFILE'
};

export default DynamicFieldConstants;
