import { combineReducers } from 'redux';
import SolutionReducer from './api/SolutionReducer';
import LoaderReducer from './LoaderReducer';
import ProgressReducer from './ProgressReducer';
import FileUploadReducer from './FileUploadReducer';
import SnackbarReducer from './SnackbarReducer';
import MasterDataReducer from './MasterDataReducer';
import ProductDetailReducer from './ProductDetailReducer';
import SuccessReducer from './SuccessReducer';
import ErrorReducer from './ErrorReducer';
import PricingPlanReducer from './api/PricingPlanReducer';
import ProductPricingPlanReducer from './api/pricing-plan-reducer';
import loginReducer from './LoginReducer';
import ApiStatisticsReducer from './api/ApiStatisticsReducer';
import rejectReasonReducer from './RejectionReasonReducer';
import EndpointsReducer from './api/EndpointsReducer';
import CountryReducer from './CountryReducer';
import ApiThrottlingReducer from './api/ApiThrottlingReducer';
import ApiAlertsReducer from './api/ApiAlertsReducer';
import ApiSkuIdsReducer from './ApiSkuIdsReducer';
import DeleteAccountReducer from './DeleteAccountReducer';
import CompanyDetailsReducer from './CompanyDetailsReducer';
import SalesRepCompanyIdReducer from './SalesRepCompanyIdReducer';
import ProfileReducer from './ProfileReducer';
import DocumentReducer from './DocumentReducer';
import FormReducer from './api/FormReducer';
import HardwareProductReducer from './api/HardwareProductReducer';
import StockAvailReducer from './api/StockAvailReducer';
import SkuiIdGenerateReducer from './api/AdminSkuIdGenerateReducer';
import CategoryManagementReducer from './CategoryManagementReducer';
import AdminOnboardingFormReducer from './api/AdminOnboardingFormReducer';
import OrdersReducer from './api/OrdersReducer';
import OrderSummaryReducer from './api/OrderSummaryReducer';
import QuotationReducer from './api/QuotationReducer';
import SettingsReducer from './api/SettingsReducer';
import CategoryMappingReducer from './api/CategoryMappingReducer';

// /* Preview Page Reducers */
import LoaderReducer_Preview from '../preview/reducer/LoaderReducer_Preview';
import MasterDataReducer_Preview from '../preview/reducer/MasterDataReducer_Preview';
import LandingReducer_Preview from '../preview/reducer/LandingReducer_Preview';
import ProductDetailReducer_Preview from '../preview/reducer/ProductDetailReducer_Preview';
import ErrorReducer_Preview from '../preview/reducer/ErrorReducer_Preview';
import PricingPlanReducer_Preview from '../preview/reducer/PricingPlanReducer_Preview';
import loginReducer_Preview from '../preview/reducer/LoginReducer_Preview';
import ApiStatisticsReducer_Preview from '../preview/reducer/api/ApiStatisticsReducer_Preview';
import FeaturePricingPlanReducer_Preview from '../preview/reducer/feature/PricingPlanReducer_Preview';
import SellerNamesReducer_Preview from '../preview/reducer/SellerNamesReducer_Preview';
import ManageProductsReducer_Preview from '../preview/reducer/ManageProductsReducer_Preview';
import ApiGuideReducer_Preview from '../preview/reducer/ApiGuideReducer_Preview';
import PublishRequestReducer from './api/PublishRequestReducer';
import ConsumptionReducer from './api/ConsumptionReducer';

/**
 * Combine Reducers In One Object
 */
export default combineReducers({
  SuccessReducer,
  ErrorReducer,
  SolutionReducer,
  LoaderReducer,
  ProgressReducer,
  FileUploadReducer,
  SnackbarReducer,
  MasterDataReducer,
  ProductDetailReducer,
  PricingPlanReducer,
  loginReducer,
  ApiStatisticsReducer,
  rejectReasonReducer,
  EndpointsReducer,
  CountryReducer,
  ApiThrottlingReducer,
  ApiAlertsReducer,
  ApiSkuIdsReducer,
  DeleteAccountReducer,
  CompanyDetailsReducer,
  SalesRepCompanyIdReducer,
  ProfileReducer,
  DocumentReducer,
  ProductPricingPlanReducer,
  FormReducer,
  StockAvailReducer,
  SkuiIdGenerateReducer,
  HardwareProductReducer,
  CategoryManagementReducer,
  AdminOnboardingFormReducer,
  OrderSummaryReducer,
  OrdersReducer,
  QuotationReducer,
  // Preview Reducers
  ManageProductsReducer_Preview,
  LoaderReducer_Preview,
  LandingReducer_Preview,
  ProductDetailReducer_Preview,
  ErrorReducer_Preview,
  MasterDataReducer_Preview,
  PricingPlanReducer_Preview,
  loginReducer_Preview,
  ApiStatisticsReducer_Preview,
  FeaturePricingPlanReducer_Preview,
  SellerNamesReducer_Preview,
  ApiGuideReducer_Preview,
  PublishRequestReducer,
  SettingsReducer,
  CategoryMappingReducer,
  ConsumptionReducer
});
