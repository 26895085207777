import React from 'react';
import PropTypes from 'prop-types';

import Button from './button';

const TertiaryButton = ({ ...props }) => {
  return <Button {...props} className='a-button--tertiary' />;
};

TertiaryButton.propTypes = {
  className: PropTypes.string
};

TertiaryButton.defaultProps = {
  className: ''
};

export default TertiaryButton;
