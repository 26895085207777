import { IS_MKOSINT_TENANT } from '../actions/configuration';

const stepperNavigation = [
  {
    id: 'stepper1',
    title: 'API Description',
    description: 'Add basic details about API',
    url: `/step1`,
    key: `step1`,
    stepNumber: 1
  },
  {
    id: 'stepper2',
    title: 'API Settings',
    description: 'Add URL, Auth type and headers/parameter details',
    url: `/step2`,
    key: `step2`,
    stepNumber: 2
  },
  {
    id: 'stepper3',
    title: 'API EndPoints',
    description: 'Add API groups and Endpoint details',
    url: `/step3`,
    key: `step3`,
    stepNumber: 3
  },
  {
    id: 'stepper4',
    title: 'API Documentation',
    description: 'Attach API documents',
    url: `/step4`,
    key: `step4`,
    stepNumber: 4
  },
  {
    id: 'stepper5',
    title: 'API Pricing',
    description: 'Create plans for your API',
    url: `/step5`,
    key: `step5`,
    stepNumber: 5
  }
];

if (IS_MKOSINT_TENANT)
  stepperNavigation.push({
    id: 'stepper6',
    title: 'API Support',
    description: 'Add support details for your API',
    url: `/step6`,
    key: `step6`,
    stepNumber: 6,
    name: 'support'
  });

export default stepperNavigation;
