import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const SimpleDropdown = ({ dropDownData, id, activeId, handleDropDownModal }) => {
  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownWrapperRef.current &&
        !dropdownWrapperRef.current.contains(event.target)
      ) {
        onClickOutside();
      }
    };

    const onClickOutside = () => {
      handleDropDownModal();
    };

    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event);
    });
    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event);
      });
    };
  }, []);

  return (
    <div className='frontend-kit-example_context-menu'>
      {activeId === id && (
        <nav
          id='add'
          className='o-context-menu -visible'
          aria-label='Context Menu Navigation undefined'
          aria-hidden='false'
          ref={dropdownWrapperRef}
        >
          <div className='m-popover -without-arrow'>
            <div className='a-box -floating'>
              <div className='m-popover__content'>
                <ul className='o-context-menu__menuItems' role='menubar'>
                  {dropDownData?.map?.((item) => {
                    return (
                      <li
                        className={
                          item.disabled ? `a-menu-item-disabled` : `a-menu-item`
                        }
                        role='none'
                        key={item.id}
                      >
                        <a
                          role='menuitem'
                          className='a-menu-item__link'
                          aria-disabled='false'
                          aria-controls='group-id undefined'
                          onClick={() => {
                            item.handler();
                            handleDropDownModal();
                          }}
                        >
                          <span className='a-menu-item__label'>{item.label}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default SimpleDropdown;

SimpleDropdown.propTypes = {
  dropDownData: PropTypes.array,
  id: PropTypes.number,
  activeId: PropTypes.number,
  handleDropDownModal: PropTypes.func
};

SimpleDropdown.defaultProps = {
  CategoryHighlighter_text: 'Level 1',
  CategoryHighlighter_color: 'pink',
  displayName: 'Hardware',
  lastModifiedDate: '12/12/2022',
  createdDate: '12/12/2022',
  dropDownData: [],
  id: undefined,
  activeId: undefined,
  handleDropDownModal: () => null
};
