const initialState = {
  validToken: false,
  user: {},
  errorLogin: null,
  userDetails: {},
  eulaAcceptance: true,
  acceptEulaResult: {},
  acceptEulaErr: null,
  forgotPassword: false,
  checkUserAgreement: '',
  showTermsConditions: false,
  showPrivacyPolicies: false,
  tokenInvalidated: false,
  refreshToken: '',
  accessToken: ''
};

const booleanActionPayload = (payload) => {
  if (payload) {
    return true;
  } else {
    return false;
  }
};

const loginReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER_PREVIEW':
      return {
        ...state,
        validToken: booleanActionPayload(action.payload),
        user: action.payload
      };
    case 'ERROR_LOGIN_PREVIEW':
      return Object.assign({}, state, { errorLogin: action.error });
    case 'SET_USER_DETAILS_PREVIEW':
      return {
        ...state,
        userDetails: action.payload
      };
    case 'EULA_ACCEPTANCE_PREVIEW':
      return Object.assign({}, state, { eulaAcceptance: action.payload });
    case 'ACCEPT_EULA_RESULT_PREVIEW':
      return Object.assign({}, state, {
        acceptEulaResult: action.payload,
        acceptEulaErr: null
      });
    case 'ACCEPT_EULA_ERR_PREVIEW':
      return Object.assign({}, state, {
        acceptEulaResult: {},
        acceptEulaErr: action.error
      });
    case 'FORGOT_PASSWORD_PREVIEW':
      return Object.assign({}, state, { forgotPassword: action.payload });
    case 'CHECK_USER_AGREEMENT_PREVIEW':
      return { ...state, checkUserAgreement: action.payload };
    case 'SHOW_TERMS_CONDITIONS_PREVIEW':
      return { ...state, showTermsConditions: action.payload };
    case 'SHOW_PRIVACY_POLICIES_PREVIEW':
      return { ...state, showPrivacyPolicies: action.payload };
    case 'TOKEN_INVALIDATED_PREVIEW':
      return { ...state, tokenInvalidated: action.payload };
    case 'REFRESH_TOKEN_PREVIEW':
      return { ...state, refreshToken: action.payload };
    case 'ACCESS_TOKEN_PREVIEW':
      return { ...state, accessToken: action.payload };
    default:
      return state;
  }
};

export default loginReducer_Preview;
