import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
  useEffect
} from 'react';
import { useDispatch } from 'react-redux';
import IdConstants from '../../../../data/idConstants';
import ToastMsg from '../../../../data/toastMessages';
import {
  l1SupportValidator,
  l1EscalationValidator,
  l2EscalationValidator
} from '../../../../utils/mkosintSupportValidations';
import StepIndicator from '../../../../msreact-kit/html-components/step-indicator';
import {
  DialogBox,
  TextInput
} from '../../../../msreact-kit/packages/html-components';
import isEmpty from '../../../../validator/isEmpty';
import Loader from '../../../Shared/Utils/Loader';
import SnackBar from '../../../Shared/Utils/SnackBar';
import i18next from 'i18next';
import DisplayOptions from '../../../../data/displayOptions';
import { BUYER_TENANT_ID } from '../../../../actions/configuration';
import {
  publishProduct,
  publishToTenant
} from '../../../../actions/api/hardwareAction';
import { onboardingFormForMkosInt } from '../Documentation/onboardingFormForMkosInt';
import { getDraftedPricingPlan } from '../../../../shared/shared-function/services';

import '../add-api.scss';

const Support = forwardRef((props, ref) => {
  const stepperData = [
    {
      displayName: 'L1 Support',
      key: 'lISupport',
      id: 0
    },
    {
      displayName: 'L1 Escalation',
      key: 'lIEscalation',
      id: 1
    },
    {
      displayName: 'L2 Escalation',
      key: 'lIIEscalation',
      id: 2
    }
  ];
  const supportInputHeadings = [
    {
      name: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.LABEL'),
      key: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.KEY'),
      maxValue: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.MAX_VALUE'),
      description: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.DESCRIPTION'),
      placeholder: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.NAME.PLACEHOLDER')
    },
    {
      name: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.LABEL'),
      key: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.KEY'),
      maxValue: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.MAX_VALUE'),
      description: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.DESCRIPTION'),
      placeholder: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.EMAIL.PLACEHOLDER')
    },
    {
      name: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.LABEL'),
      key: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.KEY'),
      maxValue: i18next.t(
        'INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.MAX_VALUE'
      ),
      description: i18next.t(
        'INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.DESCRIPTION'
      ),
      placeholder: i18next.t(
        'INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_NUMBER.PLACEHOLDER'
      )
    },
    {
      name: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.LABEL'),
      key: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.KEY'),
      maxValue: i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.MAX_VALUE'),
      description: i18next.t(
        'INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.DESCRIPTION'
      ),
      placeholder: i18next.t(
        'INTERNAL_SELLER.ADD_API.SUPPORT.CONTACT_URL.PLACEHOLDER'
      )
    }
  ];

  const successModalContent = {
    title: i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.PUBLISHED_SUCCESSFULLY'),
    subTitle: ''
  };

  const [prodDetailedInfo, setProdDetailedInfo] = useState({
    lISupport: { name: '', email: '', contactNumber: '', contactUrl: '' },
    lIEscalation: { name: '', email: '', contactNumber: '', contactUrl: '' },
    lIIEscalation: { name: '', email: '', contactNumber: '', contactUrl: '' }
  });
  const prevProps = useRef();
  const [l1SupportValidation, setl1SupportValidation] = useState(
    l1SupportValidator.valid()
  );
  const [l1EscalationValidation, setl1EscalationValidation] = useState(
    l1EscalationValidator.valid()
  );
  const [l2EscalationValidation, setl2EscalationValidation] = useState(
    l2EscalationValidator.valid()
  );
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [showSuccessBoxModal, setShowSuccessBoxModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    setValidations();
    if (props.location?.state?.productDetails?.productDetailedInfo) {
      setProdDetailedInfoStateValue(
        props.location?.state?.productDetails.productDetailedInfo
      );
      checkValidationForPrefilledData();
    }
    props.handleButtonLabel();
    changeButtonState();
  }, []);

  useEffect(() => {
    prevProps.current = props;
  }, [props]);

  useEffect(() => {
    changeButtonState();
    if (
      props.prodDetailedInfo === prevProps.prodDetailedInfo ||
      isEmpty(props.prodDetailedInfo)
    )
      return;
    if (
      props.prodDetailedInfo.lISupport ||
      props.prodDetailedInfo.lIEscalation ||
      props.prodDetailedInfo.lIIEscalation
    ) {
      setProdDetailedInfoStateValue(props.prodDetailedInfo);
    }
    checkValidationForPrefilledData();
  }, [props.prodBasicInfo, props.prodDetailedInfo]);

  const changeButtonState = () => {
    let areStepperFieldsValid = false;
    const attribute = stepperData[props.activeGrouping].key;
    switch (attribute) {
      case 'lISupport':
        areStepperFieldsValid = l1SupportValidation.isValid;
        break;
      case 'lIEscalation':
        areStepperFieldsValid = l1EscalationValidation.isValid;
        break;
      case 'lIIEscalation':
        areStepperFieldsValid = l2EscalationValidation.isValid;
        break;
    }
    props.handleButtonState(!areStepperFieldsValid);
  };

  const setValidations = () => {
    setl1SupportValidation(
      l1SupportValidator.validate('', '', {
        prodBasicInfo: props.prodBasicInfo,
        prodDetailedInfo: prodDetailedInfo,
        validation: l1SupportValidation
      })
    );
    setl1EscalationValidation(
      l1EscalationValidator.validate('', '', {
        prodBasicInfo: props.prodBasicInfo,
        prodDetailedInfo: prodDetailedInfo,
        validation: l1EscalationValidation
      })
    );
    setl2EscalationValidation(
      l2EscalationValidator.validate('', '', {
        prodBasicInfo: props.prodBasicInfo,
        prodDetailedInfo: prodDetailedInfo,
        validation: l2EscalationValidation
      })
    );
  };

  const setProdDetailedInfoStateValue = (prodDetailsInfo) => {
    setProdDetailedInfo((prevState) => ({
      ...prevState,
      lISupport: {
        name: prodDetailsInfo.lISupport?.name ?? '',
        email: prodDetailsInfo.lISupport?.email ?? '',
        contactNumber: prodDetailsInfo.lISupport?.contactNumber ?? '',
        contactUrl: prodDetailsInfo.lISupport?.contactUrl ?? ''
      },
      lIEscalation: {
        name: prodDetailsInfo.lIEscalation?.name ?? '',
        email: prodDetailsInfo.lIEscalation?.email ?? '',
        contactNumber: prodDetailsInfo.lIEscalation?.contactNumber ?? '',
        contactUrl: prodDetailsInfo.lIEscalation?.contactUrl ?? ''
      },
      lIIEscalation: {
        name: prodDetailsInfo.lIIEscalation?.name ?? '',
        email: prodDetailsInfo.lIIEscalation?.email ?? '',
        contactNumber: prodDetailsInfo.lIIEscalation?.contactNumber ?? '',
        contactUrl: prodDetailsInfo.lIIEscalation?.contactUrl ?? ''
      }
    }));
    checkValidationForPrefilledData();
  };

  useEffect(() => {
    changeButtonState();
  }, [prodDetailedInfo]);

  useEffect(() => {
    props.updateInfo(props.info, 'supportChanged', false);
    props.handleButtonLabel();
    checkValidationForPrefilledData();
    changeButtonState();
  }, [props.activeGrouping]);

  const areAllFieldsFilled = (stepperKey) => {
    let emptyFieldExists = false;
    Object.keys(prodDetailedInfo[stepperKey]).forEach((item) => {
      if (!prodDetailedInfo[stepperKey][item]) emptyFieldExists = true;
    });
    return !emptyFieldExists;
  };

  const checkValidationForPrefilledData = () => {
    switch (props.activeGrouping) {
      case 0:
        areAllFieldsFilled(stepperData[0].key) &&
          setl1SupportValidation(
            l1SupportValidator.validate('all', '', {
              prodBasicInfo: props.prodBasicInfo,
              prodDetailedInfo: prodDetailedInfo,
              validation: l1SupportValidation
            })
          );
        break;
      case 1:
        areAllFieldsFilled(stepperData[1].key) &&
          setl1EscalationValidation(
            l1EscalationValidator.validate('all', '', {
              prodBasicInfo: props.prodBasicInfo,
              prodDetailedInfo: prodDetailedInfo,
              validation: l1EscalationValidation
            })
          );
        break;
      case 2:
        areAllFieldsFilled(stepperData[2].key) &&
          setl2EscalationValidation(
            l2EscalationValidator.validate('all', '', {
              prodBasicInfo: props.prodBasicInfo,
              prodDetailedInfo: prodDetailedInfo,
              validation: l2EscalationValidation
            })
          );
        break;
    }
    changeButtonState();
  };

  const parentCall = (event) => {
    handleSupportSubmit(event);
  };

  useImperativeHandle(ref, () => ({
    parentCall
  }));

  const handlePublish = async () => {
    const product = {};
    const tenantIds = [];
    product.productCategoryId = props.prodBasicInfo.productCategory.categoryId;
    product.productId = props.prodBasicInfo.productId;

    const draftedPlans = await getDraftedPricingPlan(props?.apiPlans);
    if (draftedPlans?.length > 0) {
      const pricingResponse = await props.updatePricingStatus(
        draftedPlans,
        props?.prodBasicInfo?.productId,
        IdConstants.published.statusId
      );

      if (!pricingResponse) {
        return;
      }
    }

    const publishStatus = dispatch(
      publishProduct(product, 0, tenantIds, props?.userCompanyStatus?.companyId)
    );
    publishStatus.then((statusResponse) => {
      const payload = {
        productId: props.prodBasicInfo.productId,
        tenantIds: [BUYER_TENANT_ID]
      };
      if (statusResponse) {
        dispatch(publishToTenant(payload));
      }
      if (statusResponse) handleSuccessDialogBox();
    });
  };

  const handleSuccessDialogBox = () => {
    setShowSuccessBoxModal((prev) => !prev);
  };

  const handleSupportSubmit = (event) => {
    event.preventDefault();
    const productData = {
      prodBasicInfo: props.prodBasicInfo,
      prodDetailedInfo: prodDetailedInfo
    };
    props.saveProductSupportInfo(productData).then((response) => {
      if (!response && isEmpty(response?.error)) {
        setSnackBarAlert(true);
        setSnackBarSeverity('success');
        setSnackBarMessage(ToastMsg.saveStep.msg);
        if (props.activeGrouping < stepperData.length - 1) {
          props.updateProductStatus(
            IdConstants.drafted.statusId,
            props.prodBasicInfo.productId,
            props
          );
          props.updateInfo(props.info, 'supportChanged', true);
          props.setActiveGrouping(props.activeGrouping + 1);
          props.handleButtonLabel();
          resetDocumentationStepperForm(onboardingFormForMkosInt);
        } else {
          handlePublish();
        }
      } else if (!isEmpty(response?.error)) {
        setSnackBarAlert(true);
        setSnackBarSeverity('error');
        setSnackBarMessage(response?.error.message);
      }
    });
  };

  const resetDocumentationStepperForm = (form) => {
    form.map((item) => {
      if ('value' in item) {
        delete item.value;
      }
      return item;
    });
    this.props.saveFormJsonData(form);
  };

  const onChange = (event, attribute) => {
    const name = event.name;
    const changedAttribute = prodDetailedInfo[attribute];
    changedAttribute[name] = event.value.trimStart();
    setProdDetailedInfo((prevState) => ({
      ...prevState,
      [attribute]: changedAttribute
    }));
    const validatorValue = attribute + '-' + name;
    switch (attribute) {
      case 'lISupport':
        setl1SupportValidation(
          l1SupportValidator.validate(validatorValue, 'prodDetailedInfo', {
            prodBasicInfo: props.prodBasicInfo,
            prodDetailedInfo: prodDetailedInfo,
            validation: l1SupportValidation
          })
        );
        break;
      case 'lIEscalation':
        setl1EscalationValidation(
          l1EscalationValidator.validate(validatorValue, 'prodDetailedInfo', {
            prodBasicInfo: props.prodBasicInfo,
            prodDetailedInfo: prodDetailedInfo,
            validation: l1EscalationValidation
          })
        );
        break;
      case 'lIIEscalation':
        setl2EscalationValidation(
          l2EscalationValidator.validate(validatorValue, 'prodDetailedInfo', {
            prodBasicInfo: props.prodBasicInfo,
            prodDetailedInfo: prodDetailedInfo,
            validation: l2EscalationValidation
          })
        );
    }
    props.updateInfo(props.info, 'supportChanged', true);
    props.handleButtonLabel();
  };

  const onChangeSnackBar = () => {
    if (snackBarSeverity !== i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ERROR')) {
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSnackBarAlert(false);
    setSnackBarMessage('');
    setSnackBarSeverity('');
  };

  const redirectToCategoryPage = () => {
    props.history.push({
      pathname: DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.apiProduct.title
      ).filters[0].url,
      state: {
        ...props.location.state,
        publishData: null
      }
    });
  };

  return (
    <div ref={ref}>
      <StepIndicator
        size='-small'
        data={stepperData}
        active={props.activeGrouping}
      />
      <br />
      {props.activeGrouping === 0 && (
        <div className='input_container'>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[0].name}
              value={prodDetailedInfo.lISupport?.name}
              placeholder={supportInputHeadings[0].placeholder}
              handleChange={(event) => onChange(event, stepperData[0].key)}
              name={supportInputHeadings[0].key}
              maxLength={supportInputHeadings[0].maxValue}
              description={supportInputHeadings[0].description}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[0].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1SupportValidation.prodInfo?.prodDetailedInfo?.['lISupport-name']
                  ?.isInvalid
                  ? l1SupportValidation.prodInfo?.prodDetailedInfo?.[
                      'lISupport-name'
                    ].message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[1].name}
              value={prodDetailedInfo.lISupport?.email}
              placeholder={supportInputHeadings[1].placeholder}
              handleChange={(event) => onChange(event, stepperData[0].key)}
              name={supportInputHeadings[1].key}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[1].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1SupportValidation.prodInfo?.prodDetailedInfo?.['lISupport-email']
                  ?.isInvalid
                  ? l1SupportValidation.prodInfo?.prodDetailedInfo?.[
                      'lISupport-email'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[2].name}
              value={prodDetailedInfo.lISupport?.contactNumber}
              placeholder={supportInputHeadings[2].placeholder}
              handleChange={(event) => onChange(event, stepperData[0].key)}
              name={supportInputHeadings[2].key}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[2].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1SupportValidation.prodInfo?.prodDetailedInfo?.[
                  'lISupport-contactNumber'
                ]?.isInvalid
                  ? l1SupportValidation.prodInfo?.prodDetailedInfo?.[
                      'lISupport-contactNumber'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[3].name}
              value={prodDetailedInfo.lISupport?.contactUrl}
              placeholder={supportInputHeadings[3].placeholder}
              handleChange={(event) => onChange(event, stepperData[0].key)}
              name={supportInputHeadings[3].key}
              description={supportInputHeadings[3].description}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[3].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1SupportValidation.prodInfo?.prodDetailedInfo?.[
                  'lISupport-contactUrl'
                ]?.isInvalid
                  ? l1SupportValidation.prodInfo?.prodDetailedInfo?.[
                      'lISupport-contactUrl'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
        </div>
      )}
      {props.activeGrouping === 1 && (
        <div className='input_container'>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[0].name}
              value={prodDetailedInfo.lIEscalation?.name}
              placeholder={supportInputHeadings[0].placeholder}
              handleChange={(event) => onChange(event, stepperData[1].key)}
              name={supportInputHeadings[0].key}
              maxLength={supportInputHeadings[0].maxValue}
              description={supportInputHeadings[0].description}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[0].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIEscalation-name'
                ]?.isInvalid
                  ? l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIEscalation-name'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[1].name}
              value={prodDetailedInfo.lIEscalation?.email}
              placeholder={supportInputHeadings[1].placeholder}
              handleChange={(event) => onChange(event, stepperData[1].key)}
              name={supportInputHeadings[1].key}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[1].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIEscalation-email'
                ]?.isInvalid
                  ? l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIEscalation-email'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[2].name}
              value={prodDetailedInfo.lIEscalation?.contactNumber}
              placeholder={supportInputHeadings[2].placeholder}
              handleChange={(event) => onChange(event, stepperData[1].key)}
              name={supportInputHeadings[2].key}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[2].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIEscalation-contactNumber'
                ]?.isInvalid
                  ? l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIEscalation-contactNumber'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[3].name}
              value={prodDetailedInfo.lIEscalation?.contactUrl}
              placeholder={supportInputHeadings[3].placeholder}
              handleChange={(event) => onChange(event, stepperData[1].key)}
              name={supportInputHeadings[3].key}
              description={supportInputHeadings[3].description}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[3].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIEscalation-contactUrl'
                ]?.isInvalid
                  ? l1EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIEscalation-contactUrl'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
        </div>
      )}
      {props.activeGrouping === 2 && (
        <div className='input_container'>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[0].name}
              value={prodDetailedInfo.lIIEscalation?.name}
              placeholder={supportInputHeadings[0].placeholder}
              handleChange={(event) => onChange(event, stepperData[2].key)}
              name={supportInputHeadings[0].key}
              maxLength={supportInputHeadings[0].maxValue}
              description={supportInputHeadings[0].description}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[0].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIIEscalation-name'
                ]?.isInvalid
                  ? l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIIEscalation-name'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[1].name}
              value={prodDetailedInfo.lIIEscalation?.email}
              placeholder={supportInputHeadings[1].placeholder}
              handleChange={(event) => onChange(event, stepperData[2].key)}
              name={supportInputHeadings[1].key}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[1].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIIEscalation-email'
                ]?.isInvalid
                  ? l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIIEscalation-email'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[2].name}
              value={prodDetailedInfo.lIIEscalation?.contactNumber}
              placeholder={supportInputHeadings[2].placeholder}
              handleChange={(event) => onChange(event, stepperData[2].key)}
              name={supportInputHeadings[2].key}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[2].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIIEscalation-contactNumber'
                ]?.isInvalid
                  ? l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIIEscalation-contactNumber'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
          <div className='input_container-wrapper'>
            <TextInput
              displayName={supportInputHeadings[3].name}
              value={prodDetailedInfo.lIIEscalation?.contactUrl}
              placeholder={supportInputHeadings[3].placeholder}
              handleChange={(event) => onChange(event, stepperData[2].key)}
              name={supportInputHeadings[3].key}
              description={supportInputHeadings[3].description}
              validation={{
                rule: {
                  required: {
                    isRequired: true
                  },
                  length: {
                    max: supportInputHeadings[3].maxValue,
                    errorCode: 'MS105'
                  }
                }
              }}
              error={
                l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                  'lIIEscalation-contactUrl'
                ]?.isInvalid
                  ? l2EscalationValidation.prodInfo?.prodDetailedInfo?.[
                      'lIIEscalation-contactUrl'
                    ]?.message
                  : ''
              }
              disabled={props.location.state?.isView}
            />
          </div>
        </div>
      )}
      {props.loader.loader && <Loader />}
      {!props.loader.loader && snackBarAlert && (
        <SnackBar
          severity={snackBarSeverity}
          message={snackBarMessage}
          changeState={onChangeSnackBar}
        />
      )}
      {showSuccessBoxModal && (
        <DialogBox
          primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
          {...successModalContent}
          isModal={true}
          isSuccess={true}
          isSecondaryBtn={false}
          handleConfirm={() => {
            handleSuccessDialogBox();
            redirectToCategoryPage();
          }}
          cssName='home__container__success-modal'
        />
      )}
    </div>
  );
});

export default Support;
