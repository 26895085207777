import React from 'react';
import Dialog from '../../../dialog';
import PropTypes from 'prop-types';
import {
  TextArea,
  UserManual
} from '../../../../../msreact-kit/packages/html-components';
import { TENANT } from '../../../../../actions/configuration';
import '../index.scss';
import i18next from 'i18next';

const UploadFilePopup = ({
  onCloseFileUploadBox,
  filePopup,
  fileUploadRemarkLength,
  handleRemoveFile,
  onFileUploadChange,
  fileError,
  currentValue,
  onInput,
  onFileUploadSubmit,
  error,
  value,
  isPodRequired,
  rejectFileUpload,
  isCancellable,
  tenantId
}) => {
  const remarkData = {
    attributeId: null,
    name: 'addRemark',
    displayName: i18next.t('SHARED.REMARKS'),
    description: '',
    xpath: '',
    jsonType: null,
    attributeType: 'TEXT AREA',
    placeHolder: i18next.t(
      'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.REMARKS_PLACEHOLDER'
    ),
    handleChange: onInput,
    displayOrder: 4,
    isVisibleForBuyer: null,
    isElementRequired: true,
    stateChangeOnEdit: false,
    isEncryptable: false,
    maxLength: 1000,
    validation: {
      rule: {
        required: {
          isRequired: true,
          errorCode: 'MS102',
          errorMessage: ''
        },
        length: {
          min: 1,
          max: 1000,
          errorCode: 'MS105',
          errorMessage: ''
        }
      }
    },
    nonEditableAttributeValidation: null,
    optionType: null,
    options: null,
    attributes: null,
    sections: [],
    value: value
  };

  return (
    <div className='uploadpopup'>
      <div className='uploadpopup__container'>
        <Dialog
          header={
            tenantId === TENANT.PSIN
              ? i18next.t(
                  'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.UPLOAD_CLIENT_ID_SECRET'
                )
              : i18next.t(
                  'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.CONFIRM_DELVERY'
                )
          }
          title={i18next.t(
            'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.UPLOAD_BUYER_POD_DOCUMENTS'
          )}
          firstLabel={i18next.t(
            'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.POD_REJECT'
          )}
          secondLabel={i18next.t(
            'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.DELIVERY_CONFIRMED'
          )}
          isTertiary={false}
          isDivider={false}
          cssName='button__rejection__secondary'
          onCloseDialog={onCloseFileUploadBox}
          handleSubmitClick={onFileUploadSubmit}
          commentBoxLength={fileUploadRemarkLength}
          handlePodRequired={filePopup && !currentValue && isPodRequired}
          styleName={'add-comment__container__secondary-button'}
          isCancellable={isCancellable}
          rejectFileUpload={rejectFileUpload}
        >
          <UserManual
            placeHolder={i18next.t(
              'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.UPLOAD_FILE_POPUP.DROP_FILES_HERE_TO_IMPORT_THEM'
            )}
            customClass='category__upload'
            handleChange={onFileUploadChange}
            value={currentValue}
            error={fileError}
            handleDeleteFile={handleRemoveFile}
            acceptedFileTypes={[
              i18next.t('DYNAMIC_FORM.FORMATS.PDF'),
              i18next.t('DYNAMIC_FORM.FORMATS.JPG'),
              i18next.t('DYNAMIC_FORM.FORMATS.JPEG'),
              i18next.t('DYNAMIC_FORM.FORMATS.PNG')
            ]}
            validation={{
              supportedFileExtensions: ['.jpeg', '.jpg', '.png', '.pdf'],
              accept: 'image/png,image/jpg,image/jpeg',
              maxFiles: 1,
              maxSize: 5242880
            }}
            disabled={currentValue?.length > 0}
            isRequired={isPodRequired}
          ></UserManual>
          <div className='pod-remark-area'>
            <TextArea error={error} {...remarkData}></TextArea>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

UploadFilePopup.propTypes = {
  onCloseFileUploadBox: PropTypes.func,
  currentFileUploadRemarkLength: PropTypes.number,
  handleRemoveFile: PropTypes.func,
  onFileUploadChange: PropTypes.func,
  currentValue: PropTypes.string,
  onInput: PropTypes.func,
  onFileUploadSubmit: PropTypes.func,
  tenantId: PropTypes.string
};
export default UploadFilePopup;
