import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Errors from '../errors';
import './index.scss';
import i18next from 'i18next';

const FileUpload = ({
  children,
  className,
  handleChange,
  handleUploadFile,
  value,
  note,
  mediaIcon,
  validation,
  prodId,
  disabled,
  resetError,
  ...props
}) => {
  let valData = validation || {};

  const { supportedFileExtensions, maxFiles, minSize, multiple, noDrag } = valData
    ? valData
    : {};

  const [rejectionError, setRejectionError] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: supportedFileExtensions,
    minSize: minSize,
    multiple: multiple,
    disabled: disabled,
    noDrag: noDrag,
    onDrop: (files) => handleFileChange(files),
    onDropRejected: (files) => handleFileRejection(files)
  });

  useEffect(() => {
    if (resetError === true) {
      setRejectionError([]);
    }
  }, [resetError]);

  const handleFileChange = (file) => {
    setRejectionError([]);
    const newFiles = Array.from(file);

    const data = {
      value: newFiles,
      error: '',
      fieldId: '',
      type: 'FILE'
    };

    const fileRejectionsErrors = handleFileError(newFiles);
    if (fileRejectionsErrors && fileRejectionsErrors.size > 0) {
      return;
    }
    if (newFiles && newFiles?.length > 0) {
      handleChange(data);
    }
  };

  const handleFileRejection = (files) => {
    handleFileError(files);
  };

  const handleFileError = (files) => {
    const fileError = new Set();
    files.forEach((file) => {
      const allowedExtension = valData?.supportedFileExtensions;
      const fileName = `.${file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLowerCase()}`;
      const validFile = allowedExtension?.includes(fileName);
      if (!validFile && allowedExtension) {
        fileError.add(
          `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ONLY_FILES_WITH_THE_FOLLOWING_EXTENSIONS_ARE_ALLOWED')}: ${allowedExtension.join(', ')}`
        );
      }
      if (file.size > valData.maxSize) {
        fileError.add(
          `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.FILE_SIZE_SHOULD_BE_LESS_THAN')} ${(
            valData.maxSize /
            (1024 * 1024)
          ).toFixed(2)}${i18next.t('DYNAMIC_FORM.FORMATS.MB')}.`
        );
      }
      if (files.length > valData.maxFiles) {
        fileError.add(
          `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ONLY')} ${maxFiles} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.FILES_SHOULD_BE_UPLOADED')}`
        );
      }
    });
    const array = Array.from(fileError);

    setRejectionError((prevState) => [...prevState, ...array]);
    return fileError;
  };

  const handleDisable = () => {
    let isDisable = false;

    if (value && value.length > 0) {
      if (maxFiles === value.length) {
        isDisable = true;
      }
    }
    return isDisable;
  };

  return (
    <>
      <div className={`upload ${className}`} {...getRootProps()}>
        {children}
        <input
          type='file'
          {...props}
          {...getInputProps()}
          data-testid='upload'
          disabled={handleDisable()}
          hidden
        />
      </div>
      {rejectionError &&
        rejectionError.length > 0 &&
        rejectionError?.map((formatError) => (
          <div className='upload__error' key={formatError}>
            <Errors errorMessaage={formatError} />
          </div>
        ))}
    </>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.array,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  note: PropTypes.array
};

FileUpload.defaultProps = {
  placeholder: '',
  className: '',
  required: false,
  value: [],
  label: '',
  disabled: false,
  multiple: false,
  note: []
};
