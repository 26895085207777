import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { SecondaryButton } from '../button';

const StepIndicator = ({
  data,
  active,
  size,
  customClass,
  handleClick,
  children,
  isFormEdit
}) => {
  return (
    <div className={`${customClass} m-step-indicator ${size}`}>
      <ul
        className='
      step__form__group-indicator m-step-indicator__steps'
      >
        {data.map((item, index) => (
          <li
            key={item.displayName}
            className={`m-step-indicator__step ${active >= index && '-active'}`}
            onClick={() => {
              handleClick && handleClick(index);
            }}
          >
            <div className='m-step-indicator__node'></div>
            <span
              className={`m-step-indicator__label ${
                active === index && 'highlight'
              }`}
            >
              {item.displayName}
            </span>
            {item.desc && (
              <span className='m-step-indicator__label text -size-s'>
                {item.desc}
              </span>
            )}
          </li>
        ))}
        {isFormEdit && (
          <SecondaryButton label={i18next.t('DYNAMIC_FORM.LABEL.CREATE_GROUP')} />
        )}
      </ul>
      {children}
    </div>
  );
};

export default StepIndicator;

StepIndicator.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  size: PropTypes.string,
  handleClick: PropTypes.func
};

StepIndicator.defaultProps = {
  label: '',
  className: '',
  placeholder: '',
  size: '',
  data: []
};
