import Images from '../../assets/img';

export const cloudSubCategories = [
  {
    name: 'SHARED.INFRASTRUCTURE',
    img: Images.whatWeOffer.cloudInfra,
    link: '/cloud-services/infrastructure',
    parentId: 0,
    description: 'SHARED.INFRASTRUCTURE_DESCRIPTION'
  },
  {
    name: 'SHARED.CLOUD_MANAGED_SERVICES',
    img: Images.whatWeOffer.cloudManagedServices,
    link: '/cloud-services/managed-services',
    parentId: 0,
    description: 'SHARED.CLOUD_MANAGED_SERVICES_DESCRIPTION'
  },
  {
    name: 'SHARED.ENTERPRISE_LICENSING',
    img: Images.whatWeOffer.enterpriseLicensing,
    link: '/cloud-services/enterprise-licensing',
    parentId: 0,
    description: 'SHARED.ENTERPRISE_LICENSING_DESCRIPTION'
  }
];

export const servicesSubCategories = [
  {
    name: 'HOME_PAGE.HEADER_FOOTER.SOFTWARE_SERVICES',
    img: Images.whatWeOffer.softwareServices,
    link: '/services/software-services',
    parentId: 1,
    description: 'HOME_PAGE.HEADER_FOOTER.SOFTWARE_SERVICES_DESCRIPTION'
  },
  {
    name: 'HOME_PAGE.HEADER_FOOTER.PROFESSIONAL_SERVICES',
    img: Images.whatWeOffer.professionalServices,
    link: '/services/professional-services',
    parentId: 1,
    description: 'SHARED.CLOUD_MANAGED_SERVICES_DESCRIPTION'
  },
  {
    name: 'HOME_PAGE.HEADER_FOOTER.COMMUNICATION_SERVICES',
    img: Images.whatWeOffer.communicationServices,
    link: '/services/communication-services',
    parentId: 1,
    description: 'HOME_PAGE.HEADER_FOOTER.COMMUNICATION_SERVICES_DESCRIPTION'
  }
];

export const categoryList = [
  {
    id: 0,
    name: 'SHARED.CLOUD_SERVICES',
    img: Images.whatWeOffer.cloudIcon,
    hasSubcategories: true,
    subCategoriesList: [...cloudSubCategories],
    link: null
  },
  {
    id: 1,
    name: 'SHARED.SERVICES',
    img: Images.whatWeOffer.servicesIcon,
    hasSubcategories: true,
    subCategoriesList: [...servicesSubCategories],
    link: null
  },
  {
    id: 2,
    name: 'SHARED.APIS',
    img: Images.whatWeOffer.apiIcon,
    hasSubcategories: false,
    link: '/api'
  },
  // {
  //     id: 3,
  //     name: 'HOME_PAGE.HEADER_FOOTER.SDKS',
  //     img: Images.whatWeOffer.sdkIcon,
  //     hasSubcategories: false,
  //     link: null
  // },
  {
    id: 4,
    name: 'SHARED.SOLUTIONS',
    img: Images.whatWeOffer.solutionsIcon,
    hasSubcategories: false,
    link: '/solutions'
  },
  {
    id: 5,
    name: 'SHARED.DATA',
    img: Images.whatWeOffer.dataIcon,
    hasSubcategories: false,
    link: '/data'
  }
  // {
  //     id: 6,
  //     name: 'SHARED.HARDWARE',
  //     img: Images.whatWeOffer.hardwareIcon,
  //     hasSubcategories: false,
  //     link: null
  // }
];

export const megaMenuRightContent = [
  {
    img: Images.animatedComputer,
    content: 'HOME_PAGE.HEADER_FOOTER.MEGA_MENU_CONTENT1',
    buttonContent: 'HOME_PAGE.HEADER_FOOTER.MEGA_MENU_BUTTON_CONTENT1',
    url: '/listing'
  },
  {
    img: Images.computer,
    content: 'SHARED.JEST_FEW_CLICKS_AWAY',
    buttonContent: 'SHARED.ABOUT_US',
    url: '/about-us'
  }
];

export const blogResourceContent = [
  {
    img: Images.userOnboardingManual,
    heading: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCE_CONTENT1_HEADING',
    description: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCE_CONTENT1_DESC',
    link: '/product-guides'
  },
  // {
  //     img: Images.blogs,
  //     heading: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCE_CONTENT2_HEADING',
  //     description: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCE_CONTENT2_DESC',
  //     link: '/',
  // },
  {
    img: Images.vidoesResources,
    heading: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCE_CONTENT3_HEADING',
    description: 'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCE_CONTENT3_DESC',
    link: '/product-videos'
  }
];
