import React from 'react';
import i18next from 'i18next';
import './index.scss';

const DocNotes = ({ description, maxFiles, maxSize, supportedFileExtensions }) => {
  let notes = [];
  const acceptedFormats =
    supportedFileExtensions?.length > 0 &&
    supportedFileExtensions.toString().split('.').join(' ').toUpperCase();
  const size = `${maxSize / (1024 * 1024)}${i18next.t('DYNAMIC_FORM.FORMATS.MB')} ${
    maxFiles && maxFiles > 1
      ? i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.EACH')
      : ''
  }`;
  description && notes.push(description);
  maxSize &&
    notes.push(
      `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_FILE_SIZE')}: ${size}`
    );
  notes.push(
    `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.MAX_SUPPORT')}: ${maxFiles ? maxFiles : 1}`
  );
  acceptedFormats &&
    notes.push(
      `${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.SUPPORTED_FORMAT')}: ${acceptedFormats}`
    );

  return (
    <div className='doc'>
      {notes &&
        notes.map((data) => (
          <div key={data} className='doc__note -size-s'>
            {data}
          </div>
        ))}
    </div>
  );
};

export default DocNotes;
