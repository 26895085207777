import React from 'react';
import { isClientWidthExceeded } from '../../../utils';
import './index.scss';

const CustomToolTip = ({ id, tooltipData, width, children, className, isIcon }) => {
  return (
    <div
      key={id}
      className={className}
      data-tooltip={
        isIcon ? tooltipData : isClientWidthExceeded(id) ? tooltipData : null
      }
      data-tooltip-width={width}
    >
      {children}
    </div>
  );
};

export default CustomToolTip;
