import React, { useEffect, useState } from 'react';
import {
  TextInput,
  UserManual
} from '../../../../../msreact-kit/packages/html-components';
import Dialog from '../../../dialog';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../index.scss';
import i18next from 'i18next';
import { regexValues } from '../../../../../shared/constants/field-constants';

const AddTransit = ({
  handleCloseTransit,
  handleSubmitTransit,
  handleRemoveFile,
  currentValue,
  onFileUploadChange,
  fileError
}) => {
  const [logisticsName, setLogisticsName] = useState('');
  const [lrNumber, setLRNumber] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [errorInput, setErrorInput] = useState({
    logisticsError: false,
    lrNumberError: false,
    trackingUrlError: false,
    deliveryDateError: false
  });

  useEffect(() => {
    if (logisticsName?.length)
      setErrorInput((prev) => {
        return { ...prev, logisticsError: false };
      });
    if (lrNumber?.length)
      setErrorInput((prev) => {
        return { ...prev, lrNumberError: false };
      });
    if (trackingUrl?.length)
      setErrorInput((prev) => {
        return { ...prev, trackingUrlError: false };
      });
    if (deliveryDate?.length)
      setErrorInput((prev) => {
        return { ...prev, deliveryDateError: false };
      });
  }, [logisticsName, lrNumber, trackingUrl, deliveryDate]);

  const handleLogisticName = (event) => {
    event.persist();
    setLogisticsName(() => event.target.value);
  };

  const handleLRChange = (event) => {
    event.persist();
    setLRNumber(() => event.target.value);
  };

  const handleTrackingChange = (event) => {
    event.persist();
    setTrackingUrl(() => event.target.value);
  };

  const handleDeliveryChange = (event) => {
    event.persist();
    setDeliveryDate(() => event.target.value);
  };

  let handleBlur = () => {
    if (trackingUrl.length > 0) {
      //Tracking URL is provided by the user, need to validate.
      //Can proceed further if tracking URL is Valid.
      const urlRegex = new RegExp(regexValues.urlRegex, 'g');
      const isValidUrl = urlRegex.test(trackingUrl);
      if (!isValidUrl || trackingUrl.length > 500) {
        setErrorInput((prev) => {
          return { ...prev, trackingUrlError: true };
        });
      } else {
        setErrorInput((prev) => {
          return { ...prev, trackingUrlError: false };
        });
      }
    } else {
      //Tracking URL is not provided by the user, no need to validate.
      //Can proceed further without tracking URL.
      setErrorInput((prev) => {
        return { ...prev, trackingUrlError: false };
      });
    }
  };

  const addTransitData = [
    {
      displayName: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.ENTER_LOGISTICS_NAME'
      ),
      placeHolder: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.WRITE_HERE'
      ),
      required: true,
      value: logisticsName,
      onChange: handleLogisticName,
      type: 'text',
      error: errorInput.logisticsError
        ? i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.LOGISTICS_NAME_IS_REQUIRED')
        : logisticsName.length > 50 &&
          i18next.t(
            'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.LOGISTICS_NAME_CANNOT_BE_MORE_THAN_50_CHARACTERS'
          ),
      validation: {
        rule: {
          required: {
            isRequired: true
          },
          length: {
            min: 1,
            max: 50,
            errorCode: 'MS105',
            errorMessage: ''
          }
        }
      },
      maxlength: 50
    },
    {
      displayName: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.ENTER_LR_NUMBER'
      ),
      placeHolder: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.WRITE_HERE'
      ),
      required: true,
      value: lrNumber,
      onChange: handleLRChange,
      type: 'text',
      error: errorInput.lrNumberError
        ? i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.LR_NUMBER_IS_REQUIRED')
        : lrNumber.length > 30 &&
          i18next.t(
            'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.LR_NUMBER_CANNOT_BE_MORE_THAN_30_CHARACTERS'
          ),
      validation: {
        rule: {
          required: {
            isRequired: true
          },
          length: {
            min: 1,
            max: 30,
            errorCode: 'MS105',
            errorMessage: ''
          }
        }
      },
      maxlength: 30
    },
    {
      displayName: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.ENTER_TRACKING_URL'
      ),
      placeHolder: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.WRITE_HERE'
      ),
      required: true,
      value: trackingUrl,
      onChange: handleTrackingChange,
      handleBlur: handleBlur,
      type: 'text',
      error: errorInput.trackingUrlError
        ? i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.PLEASE_ENTER_A_VALID_URL')
        : trackingUrl.length > 500 &&
          i18next.t(
            'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.URL_CANNOT_BE_MORE_THAN_500_CHARACTERS'
          ),
      validation: {
        rule: {
          required: {
            isRequired: false
          },
          length: {
            min: 1,
            max: 500,
            errorCode: 'MS105',
            errorMessage: ''
          }
        }
      },
      maxlength: 500
    },
    {
      displayName: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.EXPECTED_DELIVERY_DATE'
      ),
      placeHolder: i18next.t(
        'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.WRITE_HERE'
      ),
      required: true,
      value: deliveryDate,
      min: new Date().toISOString().split('T')[0],
      onChange: handleDeliveryChange,
      type: 'date',
      error:
        errorInput.deliveryDateError &&
        i18next.t(
          'ORDER_MANAGEMENT.ORDER_SUMMARY_POPUPS.ADD_TRANSIT_POPUP.PLEASE_ENTER_DELIVERY_DATE'
        )
    }
  ];

  const submitTransitDetails = (transitData) => {
    handleSubmitTransit(transitData);
  };

  const validateAndSetError = (field, errorField, maxLength = null) => {
    const isValid = field.length && (!maxLength || field.length <= maxLength);
    setErrorInput((prev) => ({
      ...prev,
      [errorField]: !isValid
    }));
    return isValid;
  };

  const handleDataChange = () => {
    let transitData;
    let newDateString = '';
    let isValidTrackingUrl = true;

    if (deliveryDate?.length) {
      const selectedDate = moment(deliveryDate);
      const currentDate = moment();
      if (selectedDate.isSameOrAfter(currentDate, 'day')) {
        setErrorInput((prev) => ({ ...prev, deliveryDateError: false }));
        let parts = deliveryDate.split('-');
        newDateString = parts[0] + '-' + parts[1] + '-' + parts[2];
      } else {
        return setErrorInput((prev) => ({ ...prev, deliveryDateError: true }));
      }
    }

    const isValidLrNumber = validateAndSetError(lrNumber, 'lrNumberError', 30);

    const isValidLogistics = validateAndSetError(
      logisticsName,
      'logisticsError',
      50
    );

    if (trackingUrl.length > 0) {
      //Tracking URL is provided by the user, need to validate.
      //Can proceed further if tracking URL is Valid.
      const urlRegex = new RegExp(regexValues.urlRegex, 'g');
      const isValidUrl = urlRegex.test(trackingUrl);
      if (!isValidUrl || trackingUrl.length > 500) {
        isValidTrackingUrl = false;
      }
    } else {
      //Tracking URL is not provided by the user, no need to validate.
      //Can proceed further without tracking URL.
      isValidTrackingUrl = false;
    }

    if (
      isValidLogistics &&
      isValidLrNumber &&
      errorInput.deliveryDateError === false
    ) {
      transitData = {
        logisticsName,
        lrNumber,
        expectedDeliveryDate: newDateString
      };
      if (trackingUrl.length > 0) {
        if (isValidTrackingUrl) {
          transitData.trackingUrl = trackingUrl;
          submitTransitDetails(transitData);
        }
      } else {
        transitData.trackingUrl = trackingUrl;
        submitTransitDetails(transitData);
      }
    }
  };

  return (
    <div data-testid='add-comment' className='add-comment'>
      <div className='add-comment__container'>
        <Dialog
          header={i18next.t('ORDER_MANAGEMENT.ORDER_STATUS.ADD_TRANSIT_DETAILS')}
          isPrimary={false}
          secondLabel={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT')}
          isTertiary={false}
          isTitle={false}
          isDivider={false}
          onCloseDialog={handleCloseTransit}
          handleSubmitClick={handleDataChange}
          transitError={errorInput}
          className='transit-popup'
        >
          {addTransitData.map((element) => (
            <div className='text-wrapper' key={element.displayName}>
              <TextInput {...element} />
            </div>
          ))}

          <UserManual
            placeHolder={i18next.t(
              'DYNAMIC_FORM.LABEL.DROP_FILES_HERE_TO_IMPORT_THEM'
            )}
            customClass='category__upload'
            handleChange={onFileUploadChange}
            value={currentValue}
            error={fileError}
            handleDeleteFile={handleRemoveFile}
            validation={{
              supportedFileExtensions: ['.jpeg', '.jpg', '.png', '.pdf'],
              maxFiles: 1,
              accept: 'image/png,image/jpg,image/jpeg',
              maxSize: 1048576
            }}
            disabled={currentValue?.length > 0}
          />
        </Dialog>
      </div>
    </div>
  );
};

AddTransit.propTypes = {
  handleCloseTransit: PropTypes.func,
  handleSubmitTransit: PropTypes.func
};

export default AddTransit;
