const initialState = {
  orderHistory: [],
  orderListing: []
};

const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_HISTORY':
      return Object.assign({}, state, { orderHistory: action.payload });
    case 'GET_ORDERS_LISTING':
      return Object.assign({}, state, { orderListing: action.payload });
    default:
      return state;
  }
};

export default OrdersReducer;
