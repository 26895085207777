const initialState = {
  snackBar: false
};
const SnackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_SNACKBAR':
      return Object.assign({}, state, { snackBar: action.data });
    case 'HIDE_SNACKBAR':
      return Object.assign({}, state, { snackBar: action.data });
    default:
      return state;
  }
};

export default SnackbarReducer;
