import React from 'react';
import { Notification } from '../../../msreact-kit/packages/html-components';

import './index.scss';

const PricingNotification = ({ image, children }) => {
  return (
    <div className='pricing'>
      <Notification cssName='pricing__notification'>
        <div className='pricing__notification-wrapper'>
          <div className='pricing__notification-img-wrapper'>
            <img src={image} className='pricing__notification-img' />
          </div>
          <div className='pricing__notification__content'>{children}</div>
        </div>
      </Notification>
    </div>
  );
};

export default PricingNotification;
