import React, { useEffect, useState } from 'react';
import { pricingSlabs } from '../../../../../data/appConstants';
import idConstants from '../../../../../data/idConstants';
import './pricing-slabs.scss';
import {
  TextInput,
  PrimaryButton
} from '../../../../../msreact-kit/packages/html-components';
import Images from '../../../../../assets/img';
import i18next from 'i18next';

function PricingSlabsTable(props) {
  const [rows, setRows] = useState(deserialzeRows(props.slabLimitDetails));
  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    setRows(deserialzeRows(props.slabLimitDetails));
  }, [props.slabLimitDetails]);

  useEffect(() => {
    props.setPricingSlabs(serialzeRows());
    if (
      isPriceError() ||
      rows[rows.length - 1].to === '' ||
      rows[rows.length - 1].toError
    ) {
      props.setTableValidation(false);
      return;
    }

    if (
      rows.length === pricingSlabs.maximumSlabs &&
      rows[rows.length - 1].to !== '' &&
      !rows[rows.length - 1].toError
    ) {
      props.setTableValidation(true);
      return;
    }

    if (
      !isPriceError() &&
      rows[rows.length - 1].to === 'Unlimited' &&
      rows.length !== pricingSlabs.maximumSlabs
    ) {
      props.setTableValidation(true);
      return;
    }

    if (
      !isPriceError() &&
      rows[rows.length - 1].to !== 'Unlimited' &&
      rows.length !== pricingSlabs.maximumSlabs
    ) {
      props.setTableValidation(true);
    }
  }, [rows]);

  useEffect(() => {
    handleRateLimitChange();
  }, [props.rateLimit]);

  useEffect(() => {
    handleQuotachange();
  }, [props.quota]);

  useEffect(() => {
    handleChargableUnitChange();
  }, [props.chargeableUnit]);

  function handleQuotachange() {
    setEdit(false);
    rows.forEach((row) => (row.quota = props.quota));
    setRows([...rows]);
  }

  function handleChargableUnitChange() {
    setEdit(false);
    setRows(deserialzeRows(props.slabLimitDetails));
  }

  function handleRateLimitChange() {
    setEdit(false);
    setRows(deserialzeRows(props.slabLimitDetails));
  }

  function addNewTier() {
    setEdit(true);
    if (props.chargeableUnit === idConstants.pricing.fullSlabType) {
      setRows([
        ...rows,
        {
          from: 0,
          to: '',
          quota: props.quota,
          price: 0,
          unit: props.chargeableUnit,
          checked: false,
          disableUnlimited: false,
          toHelperText: '',
          toError: false,
          priceHelperText: '',
          priceError: false
        }
      ]);
    } else {
      setRows([
        ...rows,
        {
          from: rows[rows.length - 1].to + 1,
          to:
            rows[rows.length - 1].to + 1 === pricingSlabs.maximumToValue
              ? 'Unlimited'
              : '',
          quota: props.quota,
          price: 0,
          unit: props.chargeableUnit,
          checked:
            rows[rows.length - 1].to + 1 === pricingSlabs.maximumToValue
              ? true
              : false,
          disableUnlimited:
            rows[rows.length - 1].to + 1 === pricingSlabs.maximumToValue
              ? true
              : false,
          toHelperText: '',
          toError: false,
          priceHelperText: '',
          priceError: false
        }
      ]);
    }
  }

  function deleteRecentTier() {
    setEdit(true);
    rows.splice(rows.length - 1, 1);
    setRows([...rows]);
  }

  function onSwitchUnlimited(data) {
    setEdit(true);
    rows[data.index].checked = data.checked;
    rows[data.index].to = !data.checked ? '' : 'Unlimited';
    if (data.checked) {
      rows[data.index].toError = false;
      rows[data.index].toHelperText = '';
    }
    setRows([...rows]);
  }

  function upateToValue(data) {
    setEdit(true);
    const expression = /^(?!0\d+)\d{1,9}(\.\d{1,2})?$/;
    if (expression.test(data.value)) {
      rows[data.index].to = parseInt(data.value);
      rows[data.index].disableUnlimited = false;
      if (props.chargeableUnit === idConstants.pricing.fullSlabType) {
        if (rows.length === 1) {
          if (rows[data.index].to <= rows[data.index].from) {
            rows[data.index].toHelperText = i18next.t(
              'DYNAMIC_FORM.PRICING_PLAN.TO_VALUE_SHOULD_BE_GREATER_THAN_FROM'
            );
            rows[data.index].toError = true;
          } else {
            rows[data.index].toHelperText = '';
            rows[data.index].toError = false;
          }
        } else {
          if (rows[data.index].to <= rows[data.index - 1].to) {
            rows[data.index].toHelperText = i18next.t(
              'DYNAMIC_FORM.PRICING_PLAN.THE_MINIMUM_VALUE_SHOULD_BE_GREATER_THAN_MAXIMUM_VALUE_OF_PREVIOU_SLAB'
            );
            rows[data.index].toError = true;
          } else {
            rows[data.index].toHelperText = '';
            rows[data.index].toError = false;
          }
        }
      } else {
        if (rows[data.index].to <= rows[data.index].from) {
          rows[data.index].toHelperText = i18next.t(
            'DYNAMIC_FORM.PRICING_PLAN.TO_VALUE_SHOULD_BE_GREATER_THAN_FROM'
          );
          rows[data.index].toError = true;
        } else {
          rows[data.index].toHelperText = '';
          rows[data.index].toError = false;
        }
      }
      setRows([...rows]);
    } else {
      rows[data.index].to = data.value;
      if (rows[data.index].to !== '' && /[a-zA-Z]/.test(rows[data.index].to)) {
        rows[data.index].toHelperText = i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.ONLY_NUMERICS_ALLOWED'
        );
        rows[data.index].toError = true;
      } else {
        rows[data.index].toHelperText = i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.PRICE_CAN_HAVE_MAXIMUM_NINE_DIGITS_AND_UPTO_TWO_DECIMAL_POINTS'
        );
        rows[data.index].toError = false;
      }
      rows[data.index].disableUnlimited = false;
      setRows([...rows]);
    }
  }

  function updatePriceValue(data) {
    const expression = /^\d{0,9}(\.\d{1,2})?$/;
    setEdit(true);
    if (expression.test(data.value)) {
      rows[data.index].price = data.value;
      rows[data.index].priceHelperText = '';
      rows[data.index].priceError = false;
      setRows([...rows]);
    } else {
      rows[data.index].price = data.value;
      if (rows[data.index].price === '') {
        rows[data.index].priceHelperText = i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.PRICE_IS_REQUIRED'
        );
        rows[data.index].priceError = true;
      } else if (
        rows[data.index].price !== '' &&
        /[a-zA-Z]/.test(rows[data.index].price)
      ) {
        rows[data.index].priceHelperText = i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.ONLY_NUMERICS_ALLOWED'
        );
        rows[data.index].priceError = true;
      } else {
        rows[data.index].priceHelperText = i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.PRICE_CAN_HAVE_MAXIMUM_NINE_DIGITS_AND_UPTO_TWO_DECIMAL_POINTS'
        );
        rows[data.index].priceError = true;
      }
      setRows([...rows]);
    }
  }

  function isPriceError() {
    return rows.find((row) => row.priceError === true);
  }

  function getCurrencySymbol() {
    return (
      props.currencies &&
      props.currencies.find((currency) => currency?.name === props?.currency)?.name
    );
  }

  function getNumberLocaleKey() {
    return 'en-IN';
  }

  function getPriceFieldHelperText(row) {
    switch (props?.rateLimit) {
      case idConstants.pricing.reqCountLimitType:
        return `${
          props?.chargableUnitMasterData?.filter((unit) => unit.name === row.unit)[0]
            ?.displayName
        }`;
      case idConstants.pricing.bandWidthRateLimitType:
        return `per ${
          props?.dataBandwidthChargableUnits?.filter(
            (unit) => unit.name === row.unit
          )[0]?.name
        }`;
    }
  }

  function getToFieldHelperText(row) {
    switch (props?.rateLimit) {
      case idConstants.pricing.reqCountLimitType:
        return `Request(s)`;
      case idConstants.pricing.bandWidthRateLimitType:
        return `Data Bandwidth (${
          props?.dataBandwidthChargableUnits?.filter(
            (unit) => unit.id === row.unit
          )[0]?.name
        })`;
    }
  }

  function getValidationMessage() {
    if (
      isPriceError() ||
      rows[rows.length - 1].to === '' ||
      rows[rows.length - 1].toError
    ) {
      return (
        <div className='alert-banner table-surface__bg-error'>
          <p className='success-message'>
            {i18next.t(
              'DYNAMIC_FORM.PRICING_PLAN.PLEASE_ENTER_VALID_DATA_IN_THE_TABLE'
            )}
          </p>
        </div>
      );
    }

    if (
      rows.length === pricingSlabs.maximumSlabs &&
      rows[rows.length - 1].to !== '' &&
      !rows[rows.length - 1].toError
    ) {
      return (
        <div className='alert-banner table-surface__bg-error'>
          <p className='success-message'>
            <img src={Images.checkMark} className='checkmark-slab-table' />
            {i18next.t(
              'DYNAMIC_FORM.PRICING_PLAN.USAGE_CHARGES_WILL_BE_CALCULATED_BASED_ON_THE_PRICE_DEFINED'
            )}
          </p>
        </div>
      );
    }

    if (!isPriceError() && rows.length !== pricingSlabs.maximumSlabs) {
      return (
        <div className='alert-banner'>
          <p className='success-message'>
            <img src={Images.checkMark} className='checkmark-slab-table' />
            {i18next.t(
              'DYNAMIC_FORM.PRICING_PLAN.USAGE_CHARGES_WILL_BE_CALCULATED_BASED_ON_THE_PRICE_DEFINED'
            )}
          </p>
        </div>
      );
    }
  }

  function serialzeRows() {
    return rows?.map((row, index) => {
      return {
        slabLevel: index,
        minValue: row.from.toString(),
        maxValue: row.to === 'Unlimited' ? '-1' : row.to.toString(),
        cost: Number(row.price).toString()
      };
    });
  }

  function deserialzeRows(slabLimitDetails) {
    return slabLimitDetails.map((row) => {
      return {
        from: Number(row.minValue),
        to: row.maxValue !== '-1' ? Number(row.maxValue) : 'Unlimited',
        quota: props.quota,
        price: Number(row.cost),
        unit: props.chargeableUnit,
        checked: row.maxValue === '-1' ? true : false,
        disableUnlimited: row?.disableUnlimited ? row.disableUnlimited : false,
        toHelperText: '',
        toError: false,
        priceHelperText: '',
        priceError: false
      };
    });
  }

  const getChargeableUnit = () => {
    if (!props.chargableUnitMasterData) return '';
    if (!props.chargeableUnit) return idConstants.pricing.perReqHitSlabType;
    return props.chargableUnitMasterData.find(
      (item) => item.name === props.chargeableUnit
    )?.name;
  };

  return (
    <React.Fragment>
      <div>
        <div className='table-surface'>
          <table className='slab-table'>
            <thead className='table-head'>
              <tr>
                <th className='table-seperator'>
                  {getChargeableUnit() === idConstants.pricing.perReqHitSlabType ||
                  !props.chargeableUnit
                    ? i18next.t('DYNAMIC_FORM.PRICING_PLAN.TIER')
                    : i18next.t('DYNAMIC_FORM.PRICING_PLAN.SLAB')}
                  &nbsp;
                  <span className='bold-heading'>({i18next.t('SHARED.FROM')})</span>
                </th>
                <th className='table-seperator'>
                  {getChargeableUnit() === idConstants.pricing.perReqHitSlabType ||
                  !props.chargeableUnit
                    ? i18next.t('DYNAMIC_FORM.PRICING_PLAN.TIER')
                    : i18next.t('DYNAMIC_FORM.PRICING_PLAN.SLAB')}
                  &nbsp;
                  <span className='bold-heading'>({i18next.t('SHARED.TO')})</span>
                </th>
                <th className='table-seperator'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN_DIALOG.QUOTA')}
                </th>
                <th className='table-seperator'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING')}
                </th>
                <th className='table-seperator'>
                  {i18next.t('DYNAMIC_FORM.PRICING_PLAN.ACTION')}
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={row}>
                  <th align='left' className='table-seperator'>
                    <div title={row.from.toLocaleString(getNumberLocaleKey())}>
                      <span>{row.from.toLocaleString(getNumberLocaleKey())}</span>
                    </div>
                  </th>
                  <th align='left' className='table-seperator'>
                    <div>
                      {!row.checked && (
                        <TextInput
                          displayName={getToFieldHelperText(row)}
                          value={
                            index !== rows.length - 1
                              ? row.to.toLocaleString(getNumberLocaleKey())
                              : row.to
                          }
                          handleChange={(event) =>
                            upateToValue({
                              index: index,
                              value: event.value
                            })
                          }
                          disabled={index !== rows.length - 1}
                          error={row.toHelperText}
                          id={getToFieldHelperText(row)}
                          isEllipsis={true}
                          class='table-seperator-customtooltip'
                        />
                      )}
                      {index === rows.length - 1 && (
                        <label className='unlimited_name'>
                          <input
                            type='checkbox'
                            name={'row-' + index}
                            onChange={(event) =>
                              onSwitchUnlimited({
                                index: index,
                                checked: event.target.checked
                              })
                            }
                            disabled={row.disableUnlimited}
                            checked={row.checked}
                          />
                          {i18next.t('DYNAMIC_FORM.PRICING_PLAN.UNLIMITED')}
                        </label>
                      )}
                    </div>
                  </th>
                  <th align='left' className='table-seperator'>
                    <span className='quota-value'>
                      {
                        props?.quotas?.filter((quota) => quota.name === row.quota)[0]
                          ?.displayName
                      }
                    </span>
                  </th>
                  <th align='left' className='table-seperator currency__th'>
                    <TextInput
                      displayName={getPriceFieldHelperText(row)}
                      value={`${row.price}`}
                      handleChange={(event) =>
                        updatePriceValue({
                          index: index,
                          value: event.value
                        })
                      }
                      disabled={index !== rows.length - 1}
                      error={row.priceHelperText}
                      id={getPriceFieldHelperText(row)}
                      isEllipsis={true}
                      class='table-seperator-customtooltip'
                    />
                    <span className='currency-text'>{getCurrencySymbol()}</span>
                  </th>
                  <th align='center'>
                    {index === rows.length - 1 && rows.length > 1 && (
                      <div
                        onClick={deleteRecentTier}
                        className='button delete-tier-button '
                      >
                        <img
                          src={Images.deleteIcon}
                          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE_ICON')}
                        />
                      </div>
                    )}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <PrimaryButton
            onClick={addNewTier}
            disabled={
              rows.length === pricingSlabs.maximumSlabs ||
              (rows[rows.length - 1].toError &&
                rows[rows.length - 1].to !== 'Unlimited') ||
              rows[rows.length - 1].to === 'Unlimited' ||
              rows[rows.length - 1].to === '' ||
              isPriceError() ||
              rows[rows.length - 1].to === pricingSlabs.maximumToValue
            }
            label={`${i18next.t('DYNAMIC_FORM.PRICING_PLAN.ADD')} ${
              getChargeableUnit() === idConstants.pricing.perReqHitSlabType
                ? i18next.t('DYNAMIC_FORM.PRICING_PLAN.TIER').toLocaleLowerCase()
                : i18next.t('DYNAMIC_FORM.PRICING_PLAN.SLAB').toLocaleLowerCase()
            }`}
          />
          {(rows.length === pricingSlabs.maximumSlabs ||
            rows[rows.length - 1].to === pricingSlabs.maximumToValue) && (
            <div>
              <ul className='validation-list'>
                {rows[rows.length - 1].to === pricingSlabs.maximumToValue && (
                  <li className='maximum-limit-count-text'>
                    <span>
                      {i18next.t('DYNAMIC_FORM.PRICING_PLAN.MAXIMUM_LIMIT_REACHED')}
                    </span>
                  </li>
                )}
                {rows.length === pricingSlabs.maximumSlabs && (
                  <li>
                    <span className='maximum-tiers-count-text'>
                      {i18next.t(
                        'DYNAMIC_FORM.ADD_API.MAXIMUM_LIMIT_OF_10_PRICING_SLABS_REACHED'
                      )}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className='gst-container'>
          <div className='gst-container-icon'>
            <i
              className={`a-icon boschicon-bosch-ic-info-i-frame price-info-icon`}
            ></i>
          </div>
          <div>
            <span className='gst-description'>
              {i18next.t(
                'DYNAMIC_FORM.PRICING_PLAN.PRICES_SHOWN_HERE_ARE_EXCLUDING_TAXES'
              )}
            </span>
          </div>
        </div>
        {typeof props.getBasePriceErrorMsg === 'function' &&
        props?.getBasePriceErrorMsg()
          ? props?.getBasePriceErrorMsg()
          : isEdit && (
              <div className='validation-container'>{getValidationMessage()}</div>
            )}
      </div>
    </React.Fragment>
  );
}
export default PricingSlabsTable;
