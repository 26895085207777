import React, { useState, useEffect } from 'react';
import {
  PrimaryButton,
  DialogBox,
  Link,
  CheckBox
} from '../../../../../msreact-kit/packages/html-components';
import generateRandomId from '../../../../../utils/generate-random-id';
import './index.scss';

function PrivacySettingsModal(props) {
  const [checkboxState, updateCheckboxState] = useState(false);

  useEffect(() => {
    let cookieState = getCookie('cookieConsentByUser') === '1' ? true : false;
    updateCheckboxState(cookieState);
  }, []);

  const getCookie = (cookieName) => {
    const getCookies = decodeURIComponent(document.cookie).split(';');
    for (const [index, value] of getCookies.entries()) {
      if (getCookies[index].split('=')[0].indexOf(cookieName) !== -1) {
        return value.split('=')[1];
      }
    }
  };

  const handleCheckboxChange = (event) => {
    updateCheckboxState(event.value);
  };

  return (
    <DialogBox
      primaryLabel='Save'
      title='You can adjust your privacy settings here'
      handleCancel={() => {
        props.closeCallback();
      }}
      isPrimaryBtn={false}
    >
      <div className='text -size-m consent-content'>
        Disable all cookies If you wish to disable all cookies, please go to your
        browser settings and deactivate the setting of cookies. Please note that this
        can impair the functionality of the website.
      </div>
      <div className='text -size-m consent-content'>
        Managing your settings that govern the use of technically non-essential
        cookies and tracking mechanisms With the exception of the cookie that stores
        your current privacy settings (selection cookie), technically non-essential
        cookies and tracking mechanisms, that enable us to provide you with
        customized offers (marketing cookies and tracking mechanisms), are only used
        if you have given prior consent to such use. Below, you can grant us your
        consent or withdraw the consent that you granted us, effective immediately.
      </div>
      <div className='checkbox-popup'>
        <CheckBox
          handleChange={handleCheckboxChange}
          id={generateRandomId()}
          checked={checkboxState}
          displayName='Marketing'
          name='Marketing'
        />
      </div>
      <p className='text -size-m consent-content'>
        Marketing cookies and tracking mechanisms enable us to provide you with a
        superior user experience as well as customised offers.
        <Link
          className='text -size-m marketing-content'
          to='/data-protection-policy'
          label='Readmore >'
        />
      </p>

      <p className='text -size-m consent-content'>
        Please see our data protection notice (privacy policy) for details in this
        regard
        <Link
          to='/data-protection-policy'
          className='text -size-m marketing-content'
          label='link >'
        />
      </p>
      <div className='cookies-accept-button'>
        <Link
          to='/corporate-information'
          className='text -size-m corporate-content'
          label='Corporate information'
        />
        <PrimaryButton
          onClick={() => {
            checkboxState ? props.okOperation() : props.noCookiesSelection();
          }}
          label='save'
        />
      </div>
    </DialogBox>
  );
}

export default PrivacySettingsModal;
