import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  PrimaryButton,
  IconButton,
  SecondaryButton
} from '../../../msreact-kit/packages/html-components';
import i18next from 'i18next';

const Dialog = ({
  children,
  onCloseDialog,
  header,
  transitError,
  rejectReason,
  handleSubmitClick,
  commentBoxLength,
  className,
  handlePodRequired,
  firstLabel,
  cssName,
  styleName,
  isCancellable,
  rejectFileUpload,
  secondLabel
}) => {
  const [disabledButton, setDisabledButton] = useState(false);

  const dialogBoxData = {
    header: header
  };

  const closeDialogBox = () => {
    onCloseDialog();
  };

  useEffect(() => {
    return setDisabledButton(() => false);
  }, []);

  const handleClick = () => {
    setDisabledButton(() => true);
    handleSubmitClick();
    onCloseDialog();
  };

  const handleRejectPod = () => {
    rejectFileUpload();
    onCloseDialog();
  };

  const handleTransitSubmit = () => {
    handleSubmitClick();
  };

  return (
    <div
      className='m-dialog'
      id='frontend-kit-dialog-default-non-modal-dialog'
      role='dialog'
      aria-labelledby='frontend-kit-dialog-default-non-modal-dialog-title'
    >
      <div className='a-box -floating-shadow-s'>
        <div className='m-dialog__header'>
          <div className='m-dialog__title'>{dialogBoxData.header}</div>
          <IconButton
            label=''
            iconCss='ui-ic-close'
            iconTitle={i18next.t('DYNAMIC_FORM.ICON_TITLE.CLOSE')}
            onClick={closeDialogBox}
            isIcon={true}
          />
        </div>
        <div className='m-dialog__content'>
          <div
            className='m-dialog__headline'
            id='frontend-kit-dialog-default-non-modal-dialog-title'
          >
            {dialogBoxData.title}
          </div>
          <div
            className={`m-dialog__body ${className}`}
            id='frontend-kit-dialog-default-non-modal-dialog-description'
          >
            {children}
          </div>
          <div className={`m-dialog__actions ${styleName}`}>
            {isCancellable === 'true' && (
              <div>
                <SecondaryButton
                  label={firstLabel}
                  className={cssName}
                  disabled={
                    commentBoxLength < 1 ||
                    commentBoxLength > 1000 ||
                    handlePodRequired
                  }
                  onClick={handleRejectPod}
                />
              </div>
            )}
            <PrimaryButton
              onClick={transitError ? handleTransitSubmit : handleClick}
              label={
                isCancellable === 'true'
                  ? secondLabel
                  : i18next.t('DYNAMIC_FORM.LABEL.SUBMIT')
              }
              disabled={
                commentBoxLength < 1 ||
                commentBoxLength > 1000 ||
                disabledButton ||
                rejectReason === 0 ||
                rejectReason > 4000 ||
                handlePodRequired
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  cssName: PropTypes.string,
  className: PropTypes.string
};

Dialog.defaultProps = {
  cssName: '',
  className: ''
};

export default Dialog;
