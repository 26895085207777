import React, { useState, useEffect } from 'react';
import styles from './api-hits-breakdown.module.scss';
import { Table, Dropdown } from '../../../../msreact-kit/packages/html-components';
import i18next from 'i18next';

const APIHitsBreakDown = (props) => {
  const endPointDetails = props?.endPointsData;
  const hitsType = props?.hitsType;
  const [finalArray, setFinalArray] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const tableHeadings = [
    {
      id: '1',
      value: i18next.t('API_DETAIL.ENDPOINTS_TAB.RESPONSE_CODE'),
      key: 'responseCode'
    },
    {
      id: '2',
      value: i18next.t('DYNAMIC_FORM.API_STATISTICS.COUNT'),
      key: 'hitCounts'
    }
  ];
  let dropDownOptions = [
    { label: i18next.t('DYNAMIC_FORM.API_STATISTICS.ALL'), value: 'All' }
  ];
  dropDownOptions = dropDownOptions?.concat(
    endPointDetails?.map((item) => ({
      value: item?.endPointName,
      label: item?.endPointName
    }))
  );

  useEffect(() => {
    handleEndPointChange({
      value: {
        value: 'All',
        label: i18next.t('DYNAMIC_FORM.API_STATISTICS.ALL')
      }
    });
  }, []);

  useEffect(() => {
    setFormattedData(
      finalArray?.map((item) => {
        return {
          responseCode: item?.responseCode,
          hitCounts: item?.hitCounts
        };
      })
    );
  }, [finalArray]);

  const mergeSameResponseCodesAndValues = (array) => {
    const combinedArray = [];
    array.forEach((object) => {
      const existingIndex = combinedArray.findIndex(
        (item) => item.responseCode === object.responseCode
      );
      if (existingIndex === -1) {
        combinedArray.push({
          responseCode: object.responseCode,
          hitCounts: object.hitCounts
        });
      } else {
        combinedArray[existingIndex].hitCounts += object.hitCounts;
      }
    });
    return combinedArray;
  };

  const handleEndPointChange = (event) => {
    const tempArray = [];
    const noDataAvailableText = i18next.t('ENDPOINTS.NO_DATA_AVAILABLE');
    const noDataAvailable = [
      {
        responseCode:
          noDataAvailableText?.charAt(0) +
          noDataAvailableText?.substring(1).toLowerCase() +
          ' !',
        hitCounts: ''
      }
    ];

    if (event?.value?.value === 'All') {
      endPointDetails
        ?.map((item) => item?.methods[hitsType])
        ?.map((innerItem) =>
          innerItem?.map((innerMostItem) => tempArray?.push(innerMostItem))
        );
      setFinalArray(
        tempArray?.length
          ? mergeSameResponseCodesAndValues(tempArray)
          : noDataAvailable
      );
    } else {
      const rowData = endPointDetails.find(
        (item) => item.endPointName === event?.value?.value
      ).methods[hitsType];
      setFinalArray(rowData?.length ? rowData : noDataAvailable);
    }
  };

  return (
    <div className={styles['breakdown-container']}>
      <div
        className={styles['back-button-wrapper']}
        onClick={() => props.setIsResponseCodesTableDisplay(false)}
      >
        <i
          className={`${styles['back-button-svg']} a-icon boschicon-bosch-ic-back-left`}
        ></i>
        <div className={styles['back-button-label']}>
          {i18next.t('DYNAMIC_FORM.LABEL.BACK')}
        </div>
      </div>

      <div className={styles['detailed-time-text']}>
        {`${i18next.t('DYNAMIC_FORM.API_STATISTICS.DETAILED_TIME_TEXT')} '${
          props?.apiName
        }' ${i18next.t('DYNAMIC_FORM.API_STATISTICS.API_USAGE_FOR')} 
        ${
          props?.selectedPeriod === 'Date Range' ? props?.selectedPeriod : ''
        } '${props?.timePeriod}'`}
      </div>

      <hr />
      <div className={styles['text-style']}>
        {hitsType === 'chargeableHits'
          ? i18next.t('DYNAMIC_FORM.API_STATISTICS.CHARGEABLE_REQUESTS')
          : i18next.t('DYNAMIC_FORM.API_STATISTICS.NON_CHARGEABLE_REQUESTS')}
      </div>
      <div className={styles['dropdown-field']}>
        <Dropdown
          options={dropDownOptions}
          displayName={i18next.t('DYNAMIC_FORM.API_STATISTICS.SELECT_ENDPOINT')}
          name={i18next.t('DYNAMIC_FORM.API_STATISTICS.SELECT_ENDPOINT')}
          handleChange={(event) => {
            handleEndPointChange(event);
          }}
        />
      </div>
      <div className={styles['table-wrapper']}>
        <Table rowData={tableHeadings} data={formattedData} hidePagination={true} />
      </div>
    </div>
  );
};
export default APIHitsBreakDown;
