const initialState = {
  featurePlans: [],
  featureSubscriptions: [],
  purchasePlan: {},
  purchaseError: {},
  vinDetails: {},
  vinDetailsError: '',
  vehicleDetails: {}
};

const FeaturePricingPlanReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FEATURE_PLANS_PREVIEW':
      return Object.assign({}, state, { featurePlans: action.payload });
    case 'RESET_FEATURE_PLANS_PREVIEW':
      return Object.assign({}, state, { featurePlans: action.payload });
    case 'GET_SUBSCRIPTION_FOR_FEATURE_PREVIEW':
      return Object.assign({}, state, { featureSubscriptions: action.payload });
    case 'RESET_SUBSCRIPTION_FOR_FEATURE_PREVIEW':
      return Object.assign({}, state, { featureSubscriptions: action.payload });
    case 'FEATURE_PURCHASE_PLAN_PREVIEW':
      return Object.assign({}, state, { purchasePlan: action.payload });
    case 'FEATURE_PURCHASE_PLAN_ERROR_PREVIEW':
      return Object.assign({}, state, { purchaseError: action.error });
    case 'GET_VIN_DETAILS_PREVIEW':
      return Object.assign({}, state, { vinDetails: action.payload });
    case 'RESET_VIN_DETAILS_PREVIEW':
      return Object.assign({}, state, { vinDetails: action.payload });
    case 'VIN_DETAILS_ERROR_PREVIEW':
      return Object.assign({}, state, { vinDetailsError: action.errorMsg });
    case 'RESET_VIN_DETAILS_ERROR_PREVIEW':
      return Object.assign({}, state, { vinDetailsError: action.errorMsg });
    case 'ADD_VEHICLE_DETAILS_PREVIEW':
      return Object.assign({}, state, { vehicleDetails: action.payload });
    case 'RESET_VEHICLE_DETAILS_PREVIEW':
      return Object.assign({}, state, { vehicleDetails: action.payload });
    default:
      return state;
  }
};

export default FeaturePricingPlanReducer_Preview;
