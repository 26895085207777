const initialState = {
  productDetail: {},
  productRating: [],
  postRatingRes: [],
  userRating: [],
  postReviewRes: [],
  postRatingRevRes: [],
  productDocDetail: [],
  productCoverImg: [],
  productThumbnailImg: [],
  productTermsConditionsDoc: [],
  productVideos: [],
  multimediaImages: [],
  productVideosLinks: [],
  updateRevRatRes: [],
  deleteRevRes: [],
  endpointRes: {},
  grantTypeReqAttr: [],
  tokenResponse: {},
  tokenErrorResponse: {},
  applicationOfUsers: [],
  mssApiAccessToken: '',
  baseUrl: '',
  billingCycleMasterData: undefined,
  sendMail: undefined,
  emailStatus: '',
  updateRatingVal: '',
  replyCommentText: {},
  downloadedFile: [],
  productDocList: [],
  totalProductQty: -1,
  selectedProductQty: 1,
  productDisplayNames: [],
  highlightsDocument: [],
  userTermsConsent: '',
  caseStudyDocument: []
};

const ProductDetailReducer_Preview = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_BY_ID_PREVIEW':
      return Object.assign({}, state, {
        productDetail: action.payload,
        endpointRes: ''
      });
    case 'GET_PRODUCT_RATING_PREVIEW':
      return Object.assign({}, state, { userRating: action.payload });
    case 'GET_REVIEW_PREVIEW':
      return Object.assign({}, state, { productRating: action.payload });
    case 'POST_RATING_PREVIEW':
      return Object.assign({}, state, { postRatingRes: action.payload });
    case 'POST_REVIEW_RATING_PREVIEW':
      return Object.assign({}, state, { postRatingRevRes: action.payload });
    case 'POST_REVIEW_PREVIEW':
      return Object.assign({}, state, { postReviewRes: action.payload });
    case 'GET_REVIEW_RATING_PREVIEW':
      return Object.assign({}, state, {
        productRating: action.payload1,
        userRating: action.payload2
      });
    case 'GET_PRODUCT_DOCS_BY_ID_PREVIEW':
      return Object.assign({}, state, { productDocDetail: action.payload });
    case 'GET_PRODUCT_COVER_IMG_PREVIEW':
      return Object.assign({}, state, { productCoverImg: action.payload });
    case 'GET_PRODUCT_THUMBNAIL_IMG_PREVIEW':
      return Object.assign({}, state, { productThumbnailImg: action.payload });
    case 'GET_TERMS_CONDITIONS_DOCUMENT_PREVIEW':
      return Object.assign({}, state, { productTermsConditionsDoc: action.payload });
    case 'GET_PRODUCT_VIDEOS_BY_ID_PREVIEW':
      return Object.assign({}, state, { productVideos: action.payload });
    case 'GET_PRODUCT_MULTIMEDIA_IMG_BY_ID_PREVIEW':
      return Object.assign({}, state, { multimediaImages: action.payload });
    case 'GET_PRODUCT_VIDEOS_LINK_BY_ID_PREVIEW':
      return Object.assign({}, state, { productVideosLinks: action.payload });
    case 'GET_DOWNLOAD_SAMPLE_DATA_PREVIEW':
      return Object.assign({}, state, { downloadedFile: action.payload });
    case 'CLEAR_PRODUCT_DOC_LIST_PREVIEW':
      return Object.assign({}, state, { productDocList: action.payload });
    case 'GET_DOWNLOAD_PRODUCT_DOC_LIST_PREVIEW':
      return {
        ...state,
        productDocList: state.productDocList.concat(action.payload)
      };
    case 'RESET_PRODUCT_DOCS_PREVIEW':
      return Object.assign({}, state, {
        productDocDetail: [],
        productCoverImg: [],
        productThumbnailImg: [],
        productVideos: [],
        multimediaImages: [],
        productVideosLinks: [],
        highlightsDocument: [],
        productTermsConditionsDoc: []
      });
    case 'GET_DOCUMENT_DATA_PREVIEW':
      return Object.assign({}, state, { documentData: action.payload });
    case 'UPDATE_REVIEW_RATING_PREVIEW':
      return Object.assign({}, state, { postRatingRevRes: action.payload });
    case 'DELETE_REVIEW_RATING_PREVIEW':
      return Object.assign({}, state, {
        deleteRevRes: action.payload1,
        productRating: action.payload2,
        userRating: action.payload3
      });
    case 'WSO_RESPONSE_PREVIEW':
      return Object.assign({}, state, { endpointRes: action.payload });
    case 'GET_GRANTTYPE_REQ_ATTR_PREVIEW':
      return Object.assign({}, state, { grantTypeReqAttr: action.payload });
    case 'CLIENT_AUTH_ACCESS_TOKEN_PREVIEW':
      return Object.assign({}, state, {
        tokenResponse: action.payload,
        tokenErrorResponse: {}
      });
    case 'ACCESS_TOKEN_ERROR_PREVIEW':
      return Object.assign({}, state, {
        tokenResponse: {},
        tokenErrorResponse: action.payload
      });
    case 'ERR_FAILED_PREVIEW':
      return Object.assign({}, state, {
        tokenResponse: {},
        tokenErrorResponse: {}
      });
    case 'USER_APPLICATION_PREVIEW':
      return Object.assign({}, state, {
        applicationOfUsers: action.payload,
        mssApiAccessToken: ''
      });
    case 'GET_MSSAPIACCESS_TOKEN_PREVIEW':
      return Object.assign({}, state, { mssApiAccessToken: action.payload });
    case 'BASE_URL_PREVIEW':
      return Object.assign({}, state, { baseUrl: action.payload });
    case 'GET_BILLING_CYCLE_MASTER_DATA_PREVIEW':
      return Object.assign({}, state, {
        billingCycleMasterData: action.payload
      });
    case 'SEND_EMAIL_PREVIEW':
      return Object.assign({}, state, { sendMail: action.payload });
    case 'SEND_EMAIL_WITH_KEY_BODY_PREVIEW':
      return Object.assign({}, state, { emailStatus: action.payload });
    case 'SEND_REPLY_COMMENT_PREVIEW':
      return Object.assign({}, state, { replyCommentText: action.payload });
    case 'UPDATE_RATING_PREVIEW':
      return Object.assign({}, state, { updateRatingVal: action.payload });
    case 'CLEAR_PRODUCT_QTY_PREVIEW':
      return Object.assign({}, state, { totalProductQty: action.payload });
    case 'GET_PRODUCT_QTY_PREVIEW':
      return Object.assign({}, state, {
        totalProductQty: action.payload.quantity
      });
    case 'CLEAR_PRODUCT_DISPLAY_NAMES_PREVIEW':
      return Object.assign({}, state, { productDisplayNames: action.payload });
    case 'GET_PRODUCT_DISPLAY_NAMES_PREVIEW':
      return Object.assign({}, state, {
        productDisplayNames: action.payload
      });
    case 'SET_PRODUCT_QUANTITY_PREVIEW':
      return Object.assign({}, state, { selectedProductQty: action.payload });
    case 'GET_HIGHLIGHTS_DOCUMENT_PREVIEW':
      return Object.assign({}, state, { highlightsDocument: action.payload });
    case 'GET_CONSENT_PREVIEW':
      return Object.assign({}, state, { userTermsConsent: action.payload });
    case 'POST_CONSENT_PREVIEW':
      return Object.assign({}, state, { userTermsConsent: action.payload });
    case 'GET_CASE_STUDY_DOCUMENT':
      return Object.assign({}, state, { caseStudyDocument: action.payload });
    default:
      return state;
  }
};

export default ProductDetailReducer_Preview;
