const initialState = {
  categoryList: [],
  categoryAllList: []
};

const CategoryManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CATEGORY_ALL_LIST':
      return Object.assign({}, state, { categoryAllList: action.payload });
    case 'CATEGORY_LIST':
      return Object.assign({}, state, { categoryList: action.payload });
    default:
      return Object.assign({}, state);
  }
};
export default CategoryManagementReducer;
