const initialState = {
  contractData: []
};

const ConsumptionReducer = (state = initialState, action) => {
  if (action.type === 'GET_CONTRACT_DATA') {
    return Object.assign({}, state, { contractData: action.payload });
  }

  return state;
};

export default ConsumptionReducer;
