import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import CategoryHighlighter from '../../category-highlight';
import capitalizeText from '../../../../utils/capitalize-text';
import IdConstants from '../../../../data/idConstants';
import { convertUTCToLocalDate } from '../../../../utils/date-time-utils';
import './index.scss';

export function getCurrencyFormatWithIsoCode(currencyCode) {
  return {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
}

const ProductDetails = () => {
  const [thumbnailDoc, setThumbnailDoc] = useState(null);
  const [filteredPricingPolicies, setFilteredPricingPolicies] = useState(null);
  const { orderDescription, pricingPolicies, getThumbnailImage } = useSelector(
    (state) => {
      return {
        orderDescription: state.OrderSummaryReducer.orderDescription,
        pricingPolicies: state.OrderSummaryReducer.pricingPolicies,
        getThumbnailImage: state.ProductDetailReducer.productDocDetail
      };
    }
  );

  useEffect(() => {
    if (getThumbnailImage?.length > 0) {
      const thumbnail = getThumbnailImage?.find(
        (document) => document.docTypeId === IdConstants.documentType.thumbnailImg
      );
      setThumbnailDoc(thumbnail);
    }
  }, [getThumbnailImage]);

  useEffect(() => {
    if (pricingPolicies?.length > 0) {
      const pricingPoliciesDetails = pricingPolicies?.find(
        (planDetails) =>
          planDetails.planId === orderDescription?.productOrderDto?.policyId
      );
      setFilteredPricingPolicies(pricingPoliciesDetails);
    }
  }, [pricingPolicies, orderDescription]);

  return (
    <React.Fragment>
      {Object.keys(orderDescription).length > 0 ? (
        <div className='product-details-wrapper'>
          <h5 className='product-details__header'>
            {i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.PRODUCT_DETAILS')}
          </h5>
          <div className='product-details'>
            <div className='product-details__specs'>
              <div className='product-details__specs__image'>
                <img
                  src={thumbnailDoc?.blobFileUrl}
                  width={112}
                  height={112}
                  alt={i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.NOT_AVAILABLE')}
                />
              </div>
              <div className='product-details__specs__details'>
                <div className='product-details-flex-container'>
                  <p className='product-details__specs__details__name highlight -size-l'>
                    {orderDescription?.productDetailsDto?.displayName}
                  </p>
                  <p className='product-details__specs__details__sku -size-s'>
                    {i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.SKU_ID')}:{' '}
                    {filteredPricingPolicies?.skuVariantId}
                  </p>
                </div>
                <div className='product-details__specs__details__category -size-m'>
                  <CategoryHighlighter
                    category={
                      orderDescription?.productDetailsDto?.category?.displayName
                    }
                  />
                </div>
              </div>
            </div>
            <div className='product-details__seller'>
              {orderDescription?.sellerDetails && (
                <p>
                  {i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.SOLD_BY')}:{' '}
                  {capitalizeText(orderDescription.sellerDetails?.companyName)}
                </p>
              )}
            </div>
            {orderDescription?.orderSubscriptionMap?.productCategoryId ===
              IdConstants.hardware.categoryId && (
              <div className='product-details__quantity'>
                <p>
                  {i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.QTY')}:{' '}
                  {orderDescription?.productOrderDto?.cartItemCount}
                </p>
              </div>
            )}
            <div className='product-details__order-date'>
              <p>
                {i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.ORDER_CREATED')}:{' '}
                {convertUTCToLocalDate(
                  orderDescription?.orderSubscriptionMap?.orderDate
                )}
              </p>
            </div>
            {filteredPricingPolicies &&
            filteredPricingPolicies?.currency?.currencyCode &&
            orderDescription?.productOrderDto?.cartItemCount ? (
              <div className='product-details__price -size-l'>
                {parseFloat(
                  filteredPricingPolicies?.planCost *
                    orderDescription?.productOrderDto?.cartItemCount
                ).toLocaleString(
                  `en-${filteredPricingPolicies?.currency?.currencyCode?.slice(0, 2)}`,
                  getCurrencyFormatWithIsoCode(
                    filteredPricingPolicies?.currency?.currencyCode
                  )
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ProductDetails;
