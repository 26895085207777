const initialState = {
  accessKey: '',
  partNumberData: {},
  sellerPartId: ''
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ACCESS_KEY':
      return Object.assign({}, state, { accessKey: action.payload });
    case 'RESET_ACCESS_KEY':
      return Object.assign({}, state, { accessKey: '' });
    case 'UPDATE_PART_NUMBER':
      return Object.assign({}, state, { partNumberData: action.payload });
    case 'PART_NUMBER_DATA':
      return Object.assign({}, state, {
        sellerPartId: action?.payload?.sellerPartId
      });
    case 'RESET_PART_NUMBER_DATA':
      return Object.assign({}, state, { sellerPartId: '' });
    default:
      return state;
  }
};

export default SettingsReducer;
