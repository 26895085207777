import _ from 'lodash';
import { DynamicFieldConstants } from '../constants';
import { getNestedVal, attributeParser } from './clone-parser';

const cloneIgnoreSections = ['media&documents', 'documents'];

const groupParser = (element, additionalInfo, documents) => {
  const groupAttributesWithValue = [];
  const parsedAttributes = element.map((item) => {
    const result = item.xpath
      .split('/')
      .map((element) => element.trim().toString())
      .filter((element) => element !== '');
    const assignField = getNestedVal(additionalInfo, result);
    const sortFields =
      assignField &&
      assignField.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
    const groupElement = element;

    for (let index = 1; index < sortFields?.length; index++) {
      const copyElement = _.cloneDeep(element[0]);
      copyElement.name = copyElement.name + index;
      const fieldData = copyElement.attributes.map((fieldItem) => {
        fieldItem.errror = '';
        fieldItem.value = '';
        return fieldItem;
      });
      copyElement.attributes = fieldData;
      groupElement.push(copyElement);
    }
    const modifiedData =
      groupElement &&
      groupElement.forEach((groupData, index) => {
        const parsedAttributesWithValue = [];
        groupData.attributes.forEach((item) => {
          const attributeValueObj = {
            name: item.name,
            xpath: item.xpath
          };
          if (
            item.attributeType === DynamicFieldConstants.FILE ||
            item.attributeType === DynamicFieldConstants.UPLOAD_IMAGE
          ) {
            const filteredDocuments = documents.filter(
              (docs) => docs.docTypeId === item.attributeId && docs.uid !== null
            );

            if (filteredDocuments && filteredDocuments?.length > 0) {
              if (assignField && assignField[index]) {
                const getDocByUiId = filteredDocuments.filter(
                  (item) =>
                    item.productDocMetaInfoId === assignField[index]['attachmentKey']
                );
                item.value = getDocByUiId;
                attributeValueObj.value = item.value ? item.value : undefined;
              }
            }
            if (assignField && assignField[index]?._id) {
              item._id = assignField[index]?._id;
            }
          } else {
            item.value =
              assignField && assignField[index] ? assignField[index][item.name] : '';
            if (assignField && assignField[index]?._id) {
              item._id = assignField[index]?._id;
            }
            attributeValueObj.value = item.value ? item.value : undefined;
          }
          parsedAttributesWithValue.push(attributeValueObj);
        });
        groupAttributesWithValue.push({
          name: groupData.name,
          xpath: groupData.xpath,
          attributes: [...parsedAttributesWithValue]
        });
      });
    return modifiedData;
  });

  return { parsedAttributes, groupAttributesWithValue };
};

export const cloneFunctionOnlyValue = (
  response,
  additionalInfo,
  documents,
  quantity,
  isClone
) => {
  response = JSON.parse(JSON.stringify(response));
  additionalInfo = JSON.parse(JSON.stringify(additionalInfo));
  documents = JSON.parse(JSON.stringify(documents));
  return {
    status: response.status,
    isQuotation: response.isQuotation,
    steps: response.steps.map((element) => {
      if (isClone && cloneIgnoreSections.includes(element.name)) {
        return element;
      }

      if (element?.attributes?.length > 0) {
        const { parsedAttributesWithValue } = attributeParser(
          element.attributes,
          additionalInfo,
          documents,
          quantity,
          isClone
        );
        element.attributes = parsedAttributesWithValue;
      }
      if (element?.sections?.length > 0) {
        const sectionsArr = [];
        element.sections.forEach((sectionAttributes) => {
          let sectionObj = {
            name: sectionAttributes.name,
            xpath: sectionAttributes.xpath
          };
          if (isClone && cloneIgnoreSections.includes(sectionAttributes.name)) {
            return;
          }

          if (sectionAttributes?.attributes?.length > 0) {
            const { parsedAttributesWithValue } = attributeParser(
              sectionAttributes.attributes,
              additionalInfo,
              documents,
              quantity,
              isClone
            );
            sectionObj.attributes = parsedAttributesWithValue;
          }
          if (sectionAttributes?.groups?.length > 0) {
            const { groupAttributesWithValue } = groupParser(
              sectionAttributes?.groups,
              additionalInfo,
              documents
            );
            sectionObj.groups = groupAttributesWithValue;
          }
          sectionsArr.push(sectionObj);
        });
        element.sections = sectionsArr;
      }
      return {
        name: element.name,
        xpath: element.xpath,
        attributes: element.attributes,
        sections: element.sections,
        groups: element.groups
      };
    })
  };
};
