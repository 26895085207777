import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { BrowserRouter as Router } from 'react-router-dom';
import AppMain from './App';
import store from './store';
import './i18n';

ReactDOM.render(
  <BreadcrumbsProvider>
    <Provider store={store}>
      <Router>
        <AppMain />
      </Router>
    </Provider>
  </BreadcrumbsProvider>,
  document.getElementById('root')
);
