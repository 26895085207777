import React, { Component } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider
} from '@material-ui/core';
import { withStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '../../../Shared/Inputs/Button';
import Images from '../../../../assets/img';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '../../../Shared/Inputs/TextField';
import InfoModal from '../../../Shared/Utils/Modal/InformationModal';
import Alert from '../../../Shared/Utils/ActionAlert';
import Select from '@material-ui/core/Select';
import Tooltip from '../../../Shared/Tooltip';
import errorConstants from '../../../../data/errorConstants';
import ConditionGroup from '../ConditionGroup';
import Snackbar from '../../../Shared/Utils/SnackBar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import isEmpty from '../../../../validator/isEmpty';
import APIThrottlingCard from '../APIThrottlingCard';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import '../api-throttling.scss';
import variables from '../../../../variables.module.scss';

const useStyles = (theme) => ({
  buttonAligning: {
    float: 'right'
  },
  cardHeading: {
    fontSize: '1rem'
  },
  typographyStyle: {
    fontSize: '0.7rem !important',
    color: '#8c8c8c !important',
    textAlign: 'right'
  },
  labelAsterisk: {
    color: 'red'
  },
  inputgroup: {
    marginTop: '20px',
    '& > *': {
      margin: '5px 0px',
      fontSize: '0.8rem',
      fontFamily: variables.mediumBoschFont,
      color: '#333'
    }
  },
  title: {
    fontSize: 14
  }
});

const theme = (theme) =>
  createTheme({
    overrides: {
      MuiSelect: {
        root: {
          background: '#EFEFF0',
          padding: '14px'
        }
      }
    }
  });

class AddThrottlingPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyName: '',
      description: '',
      policyId: this.props.location.state.policyId,
      isValidName: true,
      validNameMsg: '',
      openInfoModal: false,
      modalHeading: '',
      confirmModalText: '',
      producId: '',
      disableAssign: true,
      showDialog: false,
      displaySnackBar: false,
      openConfirmModal: false,
      deleteindex: '',
      currentGroup: {},
      isEdit: false,
      isModifed: false
    };
  }

  componentDidMount() {
    this.props.getApiListForUser();
    if (!this.props.location.state.policyId) {
      this.props.resetThrottleConditionGroups();
      this.props.resetApiListForThrottlingPolicy();
      this.props.resetThrottlePolicyConsumption();
    } else {
      this.props.getApiListForThrottlingPolicy(this.props.location.state.policyId);
      this.props
        .checkForTrottlePolicyConsmuption(this.props.location.state.policyId)
        .then(() => {
          if (this.props.isTrottlePolicyConsumed) {
            this.setState({
              openInfoModal: true,
              confirmModalText:
                'Policy cannot be edited as associated API got active subscriptions.',
              modalHeading: 'Information'
            });
          }
        });
    }

    // below code works when browser front button is clicked after edit of a throttling policy
    if (
      this.props?.throttlePolicyDetails !== undefined &&
      this.props?.location?.state?.policyId ===
        this.props?.throttlePolicyDetails?.throttlePolicyId
    ) {
      let details = this.props.throttlePolicyDetails;
      this.setState({
        policyName: details.throttlePolicyName,
        description: details.description
      });
      if (this.props?.throttlePolicyDetails?.conditionGroups?.length > 0) {
        this.setState({
          disableAssign: false
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEmpty(this.props?.throttlePolicyDetails) &&
      this.props.throttlePolicyDetails !== prevProps.throttlePolicyDetails
    ) {
      let details = this.props.throttlePolicyDetails;
      this.setState({
        policyName: details.throttlePolicyName,
        description: details.description
      });
      if (this.props?.throttlePolicyDetails?.conditionGroups?.length > 0) {
        this.setState({
          disableAssign: false
        });
      }
    }
  }

  onChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value
    });
    if (name !== 'producId') {
      this.setState({
        isModifed: true
      });
    }
    if (name === 'policyName') {
      if (value !== '') {
        const expression = /^[a-zA-Z0-9_-\s]*$/;
        if (expression.test(value)) {
          this.setState({
            isValidName: true,
            validNameMsg: ''
          });
        } else {
          this.setState({
            isValidName: false,
            validNameMsg: 'Invalid Throttling Policy Name.'
          });
        }
      } else {
        this.setState({
          isValidName: false,
          validNameMsg: 'Throttling Policy Name is Mandatory.'
        });
      }
    }
  };
  onBlur = (event) => {
    if (event.target.value !== '') {
      this.props
        .validateThrottlingPolicyName(event.target.value, this.state.policyId)
        .then(() => {
          if (!this.props.isThrottlePolicyUnique) {
            this.setState({
              isValidName: false,
              validNameMsg: 'Throttling Policy Name already exists.'
            });
          } else {
            this.setState({
              isValidName: true,
              validNameMsg: ''
            });
          }
        });
    }
  };
  saveThrottlePolicy = () => {
    if (this.state.policyId === '') {
      let message = 'API Throttling Policy saved successfully.';
      this.props
        .saveThrottlingPolicy(this.state.policyName, this.state.description)
        .then(() => {
          this.showMessage(message);
        });
    } else {
      let message = 'API Throttling Policy updated successfully.';
      this.props
        .updateThrottlingPolicy(
          this.state.policyName,
          this.state.description,
          this.state.policyId
        )
        .then(() => {
          this.showMessage(message);
        });
    }
  };
  showMessage(message) {
    if (this.props?.saveThrottlingPolicyErr === null) {
      this.setState({
        message: message,
        alert: true,
        status: 'success',
        policyId: this.props?.saveThrottlingPolicyRes?.throttlePolicyId,
        isModifed: false
      });
    } else {
      if (
        this.props?.saveThrottlingPolicyErr?.data?.error ===
        errorConstants.ThrottlingPolicyError.duplicatePolicyNameError
      ) {
        this.setState({
          isValidName: false,
          validNameMsg: 'Throttling Policy Name already exists.'
        });
      } else {
        this.setState({
          message: this.props?.saveThrottlingPolicyErr?.data?.message,
          alert: true,
          status: 'error'
        });
      }
    }
  }
  onCloseAlert = () => {
    this.setState({
      alert: false
    });
  };

  openConditionalGroup = () => {
    if (this.state.policyId === '') {
      this.setState({
        openInfoModal: true,
        confirmModalText:
          'Please enter Throttling policy Name first and then add Condition groups',
        modalHeading: 'Information'
      });
    } else if (this.props?.isTrottlePolicyConsumed) {
      this.setState({
        openInfoModal: true,
        confirmModalText:
          'Condition group cannot be added to the policy as associated API got active subscriptions.',
        modalHeading: 'Information'
      });
    } else {
      this.setState({
        showDialog: true,
        isEdit: false
      });
    }
  };
  closeInfoPopup = () => {
    this.setState({ openInfoModal: false });
  };

  handleConditionGroup = (isAdded) => {
    if (isAdded) {
      this.setState({
        displaySnackBar: true,
        snackBarMsg: 'Saved successfully',
        snackBarType: 'success',
        disableAssign: false
      });
    }
    this.setState({
      showDialog: false
    });
  };

  changeState = () => {
    this.setState({
      displaySnackBar: false
    });
  };

  addApiToThrottlingPolicy = () => {
    this.props
      .addApiToThrottlingPolicy(this.state.policyId, this.state.producId)
      .then(() => {
        this.setState({ producId: '' });
        if (this.props?.saveThrottlingPolicyApiErr !== null) {
          if (
            this.props?.saveThrottlingPolicyApiErr?.data?.error ===
            errorConstants.ThrottlingPolicyError.mapToSubscribedApiError
          ) {
            this.setState({
              openInfoModal: true,
              confirmModalText:
                'API has got active subscriptions. Hence throttling policy cannot be assigned.',
              modalHeading: 'Information'
            });
          } else {
            this.setState({
              message: this.props?.saveThrottlingPolicyApiErr?.data?.message,
              alert: true,
              status: 'error'
            });
          }
        }
      });
  };

  handleOpenConfirmModal = () => {
    this.setState({ openConfirmModal: false });
  };

  okBtnAction = (productId) => {
    if (
      this.state.modalHeading === 'Do you really want to delete Condition group?'
    ) {
      this.props
        .deleteThrottleConditionGroup(this.state.policyId, this.state.deleteIndex)
        .then(() => {
          if (isEmpty(this.props.deleteConditionGrpError)) {
            this.setState({
              snackBarType: 'success',
              snackBarMsg: 'Deleted Successfully',
              displaySnackBar: true
            });
          } else {
            this.setState({
              snackBarType: 'error',
              snackBarMsg: this.props?.deleteConditionGrpError?.message,
              displaySnackBar: true
            });
          }
        });
    } else {
      this.props
        .deleteApiFromThrottlingPolicy(this.state.policyId, productId)
        .then(() => {
          this.setState({ producId: '' });
        });
    }
  };

  deleteApiFromThrottlePolicy = (productId) => {
    this.setState({
      deleteIndex: productId,
      openConfirmModal: true,
      confirmModalText:
        'On removing, all the throttling conditions will be removed from this API.',
      modalHeading: 'Do you really want to remove throttling policy?'
    });
  };

  editConditionGroup = (conditionGroup) => {
    this.setState({
      isEdit: true,
      showDialog: true,
      currentGroup: conditionGroup
    });
  };
  deleteConditionGroup = (conditionGroup) => {
    if (this.props.isTrottlePolicyConsumed) {
      this.setState({
        openInfoModal: true,
        confirmModalText:
          'Condition Group cannot be deleted as associated API got active subscriptions.',
        modalHeading: 'Information'
      });
    } else {
      this.setState({
        deleteIndex: conditionGroup.throttleConditionGrpId,
        openConfirmModal: true,
        confirmModalText:
          'On deletion, condition group will be removed permanently.',
        modalHeading: 'Do you really want to delete Condition group?'
      });
    }
  };

  goBackToApiThrottling = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;
    let disableSave = true;
    if (
      this.state.policyName !== '' &&
      this.state.isValidName &&
      this.state.isModifed
    ) {
      disableSave = false;
    }
    return (
      <React.Fragment>
        {this.state.displaySnackBar && (
          <Snackbar
            severity={this.state.snackBarType}
            message={this.state.snackBarMsg}
            changeState={this.changeState}
          />
        )}
        <Grid container sm={12}>
          <Grid item sm={1}>
            <Box className='back-button' m={2}>
              <label
                className='cursor-pointer'
                onClick={() => this.goBackToApiThrottling()}
              >
                {' '}
                <ArrowBackIosSharpIcon className='icon-style' /> Back
              </label>
            </Box>
          </Grid>
          <Grid item sm={11}>
            <Box m={1}>
              <Typography component='h5' variant='h5'>
                <b>API Throttling Policy</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider variant='middle' />
        <Grid container>
          <Grid item sm={11}>
            {this.state.alert && (
              <Box p={2}>
                {' '}
                <Alert
                  message={this.state.message}
                  severity={this.state.status}
                  onCloseAlert={this.onCloseAlert}
                ></Alert>{' '}
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Box px={2}>
              <img
                src={Images.apiThrottling.throttlingSaveBg}
                className='bg-image-style'
                alt='List API'
              />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box px={3}>
              <div className={classes.inputgroup}>
                <InputLabel
                  htmlFor='component-simple'
                  required
                  classes={{ asterisk: classes.labelAsterisk }}
                >
                  Name of the Throttling policy
                </InputLabel>
                <TextField
                  name='policyName'
                  inputProps={{ maxLength: 50 }}
                  value={this.state.policyName}
                  onChange={(event) => this.onChange(event)}
                  onBlur={(event) => this.onBlur(event)}
                  helperText={this.state.validNameMsg}
                  disabled={this.props.isTrottlePolicyConsumed}
                  fullWidth
                />
                <Typography className={classes.typographyStyle}>
                  Max Char - 50
                </Typography>
              </div>
              <div className={classes.inputgroup}>
                <InputLabel htmlFor='component-simple'>Short Description</InputLabel>
                <TextField
                  name='description'
                  inputProps={{ maxLength: 500 }}
                  fullWidth
                  multiline
                  rows='2'
                  variant='filled'
                  value={this.state.description}
                  onChange={(event) => this.onChange(event)}
                />
                <Typography className={classes.typographyStyle}>
                  Max Char - 500
                </Typography>
              </div>
              <Button
                variant='contained'
                label='Save'
                disabled={disableSave}
                onClick={() => this.saveThrottlePolicy()}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item sm={11}>
            <Box m={2}>
              <Typography component='h6' variant='h6'>
                Condition Groups
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={1}>
            <Tooltip title='Add Condition group'>
              <Box className={classes.buttonAligning} m={1}>
                <Button
                  variant='contained'
                  label='+'
                  onClick={() => this.openConditionalGroup()}
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>

        <Box m={2}>
          <Grid container spacing={3}>
            {!isEmpty(this.props?.throttlePolicyDetails) &&
              this.props?.throttlePolicyDetails?.conditionGroups?.map((item) => {
                return (
                  <Grid item sm={4} key={item.conditionGrpName}>
                    <Card className='condition-card-style'>
                      <CardContent>
                        <Grid container>
                          <Grid item sm={3}>
                            <img
                              src={Images.apiThrottling.conditionGroup}
                              className='group-img-style'
                              alt='Condition group'
                            />
                          </Grid>
                          <Grid item sm={9}>
                            <Typography
                              gutterBottom
                              variant='h6'
                              component='h6'
                              className={classes.cardHeading}
                            >
                              <b>{item.conditionGrpName}</b>
                            </Typography>
                            <Box className='card-text'>
                              <Typography
                                variant='body2'
                                color='textSecondary'
                                component='p'
                              >
                                {item.description}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <br />
                      <Box align='center'>
                        <Tooltip title='Edit Condition group'>
                          <EditOutlinedIcon
                            size='small'
                            className='icon-spacing cursor-pointer'
                            onClick={() => this.editConditionGroup(item)}
                          />
                        </Tooltip>
                        <Tooltip title='Delete Condition group'>
                          <DeleteOutlinedIcon
                            size='small'
                            className='cursor-pointer'
                            onClick={() => this.deleteConditionGroup(item)}
                          />
                        </Tooltip>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Grid container>
          <Grid item sm={12}>
            <Box m={2}>
              <Typography component='h6' variant='h6'>
                Assigned API&#39;s
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={3}>
            <ThemeProvider theme={theme}>
              <Box m={2}>
                <Select
                  native
                  value={this.state.producId}
                  fullWidth
                  name='producId'
                  onChange={(event) => this.onChange(event)}
                  autoWidth={true}
                  disabled={this.state.disableAssign}
                >
                  <option value='' disabled>
                    Choose API
                  </option>
                  {this.props.throttlePoliyUserApiList.map((optionsValue) => (
                    <option
                      value={optionsValue.productId}
                      key={optionsValue.productId}
                    >
                      {optionsValue.productName.length > 30
                        ? optionsValue.productName.substring(
                            0,
                            Math.min(optionsValue.productName.length, 30)
                          ) + '...'
                        : optionsValue.productName}
                    </option>
                  ))}
                </Select>
              </Box>
            </ThemeProvider>
          </Grid>
          <Grid item sm={9}>
            <Box m={2}>
              <Button
                variant='contained'
                label='Assign'
                disabled={this.state.disableAssign || this.state.producId === ''}
                onClick={() => this.addApiToThrottlingPolicy()}
              />
            </Box>
          </Grid>
        </Grid>
        <Box m={2}>
          <Grid container spacing={3}>
            {this.props.throttlePoliyIdApiList?.map((card) => (
              <Grid item sm={3} key={card.productId}>
                <APIThrottlingCard
                  data={card}
                  deleteApiFromThrottlePolicy={this.deleteApiFromThrottlePolicy}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        {(() => {
          if (this.state.openInfoModal === true) {
            return (
              <InfoModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                okBtnAction={this.closeInfoPopup}
                onClose={this.closeInfoPopup}
              ></InfoModal>
            );
          }
          if (this.state.showDialog === true) {
            return (
              <ConditionGroup
                modalContent='Condition Group'
                callback={this.handleConditionGroup}
                {...this.props}
                policyId={this.state.policyId}
                isEdit={this.state.isEdit}
                groupDetails={this.state.currentGroup}
                iplist={this.props.iplist}
                addIpToList={this.props.addIpToList}
              ></ConditionGroup>
            );
          }
          if (this.state.openConfirmModal === true) {
            return (
              <ConfirmModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                callback={this.handleOpenConfirmModal}
                okBtnAction={this.okBtnAction}
                index={this.state.deleteIndex}
              ></ConfirmModal>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(AddThrottlingPolicy);
