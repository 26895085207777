import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import loading from '../../actions/LoaderAction';
import UnifiedCategory from './UnifiedCategory';
import { publishProduct } from '../../actions/api/hardwareAction';
import {
  getProductsByStatus,
  getProductsCount,
  resetProducts
} from '../../actions/MyProductsAction';
import { deleteProduct } from '../../actions/api/SolutionAction';
import { getProductQuantity } from '../../actions/api/stockAvailAction';
import { getPartNumber } from '../../actions/api/SettingsAction';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';

const UnifiedCategoryContainer = (props) => {
  const [handleLoader] = useHandleLoader();

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <UnifiedCategory {...props} />;
};

const mapStateToProps = (redux_state) => ({
  allProducts: redux_state.SolutionReducer.allProducts,
  responseHeaders: redux_state.SolutionReducer.responseHeaders,
  loader: redux_state.LoaderReducer,
  allProductsCount: redux_state.SolutionReducer.allProductsCount,
  productsCount: redux_state.SolutionReducer.productsCount,
  categoryList: redux_state.CategoryManagementReducer.categoryList,
  allProductsCallLoaded: redux_state.SolutionReducer.allProductsCallLoaded,
  deleteProdData: redux_state.SolutionReducer.deleteProdData,
  deleteProdError: redux_state.SolutionReducer.deleteProdError,
  deleteProdConsumed: redux_state.SolutionReducer.deleteProdConsumed
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loading,
      getProductsByStatus,
      getProductsCount,
      getProductQuantity,
      publishProduct,
      resetProducts,
      deleteProduct,
      getPartNumber
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnifiedCategoryContainer);
