import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IdConstants from '../../data/idConstants';
import isEmpty from '../../validator/isEmpty';
import AddAPI from '../../components/api/AddAPI';
import getAllMasterData, {
  getPricingPlanMasterData
} from '../../actions/MasterDataAction';
import saveProductInfo, {
  saveBreadCrumbValue,
  publishApi,
  generateSecretKey,
  updateProductDetailedInfo,
  updateProductStatus,
  saveProductDetailedInfo,
  resetProduct,
  reduxStorage,
  updateProductInfo,
  validateProductUniqueness,
  deleteGroup,
  deleteEndpoint,
  deleteSecretParam,
  deleteEndpointParameter,
  getProductDetailedInfo,
  resetProdEndError,
  updateInfo,
  saveSecretHeaderData,
  resetErrorInfo,
  getEditableAttributes,
  getEditableDetails,
  resetEditableDetails,
  validateProductNameEdit,
  resetProdNameError,
  saveAdditionalDetailedInfo,
  saveProductSupportInfo
} from '../../actions/api/SolutionAction';
import {
  createPricingPlan,
  getApiPlans,
  updatePricingPlan,
  resetPlan,
  updatePricingPlanStatus,
  deletePricingPlan,
  getApprovedUsers,
  getOrgGroups,
  auditLogging,
  updatePricingStatus,
  handleShowMessage,
  getBurstControlInfo
} from '../../actions/api/pricing-plan-action';
import {
  getProductDocsListById,
  getDocumentData,
  getProductVisibilityData,
  postProductVisibilityData
} from '../../actions/ProductDetailAction';
import uploadFile, {
  deleteFile,
  resetFiles,
  addVideoLink
} from '../../actions/FileUploadAction';
import { bindActionCreators } from 'redux';
import loading from '../../actions/LoaderAction';
import { retrieveUserCompanyStatus } from '../../actions/CompanyAction';
import { checkIfReseller } from '../../actions/LoginAction';
import progressing from '../../actions/ProgressAction';
import {
  getParamDataTypes,
  getMappedParamUrlOptions,
  saveGroupData,
  saveEndpointData
} from '../../actions/api/EndpointsAction';
import {
  getPolicyIdForProduct,
  addApiToThrottlingPolicy,
  deleteApiFromThrottlingPolicy,
  resetThrottlePolicy,
  swapThrottlingPolicyForAPI
} from '../../actions/api/ApiThrottlingPolicyAction';
import { swaggerFileDownload } from '../../actions/FileDownloadAction';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import {
  DCS_SUBSYSTEM_PRODUCT_ONBOARDING_TOGGLE,
  IS_MKOSINT_TENANT
} from '../../actions/configuration';

const SolutionContainer = (props) => {
  const [handleLoader] = useHandleLoader();
  const [initialCheckResellerCall, setInitialCheckResellerCall] = useState(true);

  const functionCalls = () => {
    let productId = props.location.state.productId;
    let productName = props?.prodBasicInfo?.productName;
    if (productId) {
      props.getProductDetailedInfo(productId, IdConstants.api.categoryId);
      props.getProductDocsListById(productId);
      props.getEditableAttributes(productId);
      props.getPolicyIdForProduct(productId);
      props.getApiPlans(productId, productName, IdConstants.api.categoryId);
      props.validateProductNameEdit(productId);
      props.getProductVisibilityData(productId);
      props.getEditableDetails(productId).then(() => {
        if (props?.editableDetails?.isConsumer) {
          props.updateInfo(props.info, 'isConsumer', true);
        }
      });
    }
  };

  useEffect(() => {
    if (props.location?.state?.productId !== props?.prodBasicInfo?.productId)
      handleLoader();
    if (!!props.location.state) {
      functionCalls();
    }
    // Removed resetting due to commit # code move to stage for api best specific onboarding template and LeanIX integration
    else {
      IS_MKOSINT_TENANT && props.resetProduct();
    }
    IS_MKOSINT_TENANT && props.resetFiles();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const apiCategoryId = IdConstants.api.categoryId;
    props?.getAllMasterData(apiCategoryId);
    props?.getPricingPlanMasterData(apiCategoryId);
    props?.retrieveUserCompanyStatus();
    return () => {
      // Removed resetting due to commit # code move to stage for api best specific onboarding template and LeanIX integration
      if (IS_MKOSINT_TENANT) {
        props.resetProduct();
        props.resetFiles();
        props.resetPlan();
        props.resetThrottlePolicy();
        props.resetErrorInfo();
        props.resetProdNameError();
      }
    };
  }, []);

  useEffect(() => {
    if (
      isEmpty(props?.userCompanyStatus?.companyId) ||
      props?.userCompanyStatus?.companyRegisteredStatus !== 'approved'
    )
      return;

    if (
      DCS_SUBSYSTEM_PRODUCT_ONBOARDING_TOGGLE === 'true' &&
      initialCheckResellerCall &&
      isEmpty(props.location?.state?.productId)
    ) {
      props?.checkIfReseller(props.userCompanyStatus.companyId);
      setInitialCheckResellerCall(false);
    }
  }, [props?.userCompanyStatus]);

  const setBreadCrumbValue = (value) => {
    props.saveBreadCrumbValue(value);
  };

  return <AddAPI {...props} breadCrumbValue={setBreadCrumbValue} />;
};

const mapStateToProps = (redux_state) => {
  const prodBasicInfo = redux_state.SolutionReducer.prodBasicInfo
    ? redux_state.SolutionReducer.prodBasicInfo
    : null;
  const prodDetailedInfo = redux_state.SolutionReducer.prodDetailedInfo
    ? redux_state.SolutionReducer.prodDetailedInfo
    : null;
  const loader = redux_state.LoaderReducer;
  const snackBar = redux_state.SnackbarReducer;
  const progress = redux_state.ProgressReducer;
  const files = redux_state.FileUploadReducer.files;
  const errorInfo = redux_state.SolutionReducer.errorInfo
    ? redux_state.SolutionReducer.errorInfo
    : {};
  const allMasterData = redux_state.MasterDataReducer.allMasterData;
  const pricingPlanMasterData = redux_state.MasterDataReducer.pricingPlanMasterData;
  const productDocDetail = redux_state.ProductDetailReducer.productDocDetail;
  const productThumbnailImg = redux_state.ProductDetailReducer.productThumbnailImg;
  const productCoverImg = redux_state.ProductDetailReducer.productCoverImg;
  const documentData = redux_state.ProductDetailReducer.documentData;
  const authenticationKey = redux_state.SolutionReducer.authenticationKey;
  const validation = redux_state.SolutionReducer.validation;
  const filesUploadErr = redux_state.FileUploadReducer.filesUploadErr;
  const filesUploadErrStatus = redux_state.FileUploadReducer.filesUploadErrStatus;
  const error = redux_state.ErrorReducer.error;
  const apiPlans = redux_state.PricingPlanReducer.apiPlans;
  const validationError = redux_state.PricingPlanReducer.validationError;
  const swaggerProdDetailedInfo =
    redux_state.SolutionReducer.swaggerProdDetailedInfo;
  const prodEndErrorInfo = redux_state.SolutionReducer.prodEndErrorInfo;
  const publishData = redux_state.SolutionReducer.publishData;
  const publishErrorInfo = redux_state.SolutionReducer.publishErrorInfo;
  const errorMsg = redux_state.SolutionReducer.errorMsg;
  const info = redux_state.SolutionReducer.info;
  const baseUrlError = redux_state.SolutionReducer.baseUrlError;
  const prodNameError = redux_state.SolutionReducer.prodNameError;
  const paramDataTypes = redux_state.EndpointsReducer.paramDataTypes;
  const paramUrlOptions = redux_state.EndpointsReducer.paramUrlOptions;
  const editableAttributes = redux_state.SolutionReducer.editableAttributes;
  const editableDetails = redux_state.SolutionReducer.editableDetails;
  const approvedUsers = redux_state.PricingPlanReducer.approvedUsers;
  const orgGrps = redux_state.PricingPlanReducer.orgGrps;
  const throttlingPolicies = redux_state.ApiThrottlingReducer.throttlingPolicies;
  const productThrottlePolicy =
    redux_state.ApiThrottlingReducer.productThrottlePolicy;
  const saveThrottlingPolicyApiRes =
    redux_state.ApiThrottlingReducer.saveThrottlingPolicyApiRes;
  const saveThrottlingPolicyApiErr =
    redux_state.ApiThrottlingReducer.saveThrottlingPolicyApiErr;
  const deleteThrottlingPolicyApiRes =
    redux_state.ApiThrottlingReducer.deleteThrottlingPolicyApiRes;
  const swapThrottlePolicyRes =
    redux_state.ApiThrottlingReducer.swapThrottlePolicyRes;
  const swapThrottlePolicyErr =
    redux_state.ApiThrottlingReducer.swapThrottlePolicyErr;
  const updateApiPlanError = redux_state.PricingPlanReducer.updateApiPlanError;
  const deletePlanError = redux_state.PricingPlanReducer.deletePlanError;
  const productVideosList = redux_state.ProductDetailReducer.productVideos;
  const productVideosLinkList = redux_state.ProductDetailReducer.productVideosLinks;
  const videoLinkFailure = redux_state.FileUploadReducer.videoLinkFailure;
  const videoLinkSuccess = redux_state.FileUploadReducer.videoLinkSuccess;
  const apiPlan = redux_state.PricingPlanReducer.apiPlan;
  const isProdNameEditable = redux_state.SolutionReducer.prodNameEdit;
  const prodName = redux_state.SolutionReducer.prodName;
  const portalView = redux_state.loginReducer.portalView;
  const isVisibleData = redux_state.ProductDetailReducer.isVisibleData;
  const userCompanyStatus = redux_state.CompanyDetailsReducer.userCompanyStatus;
  const productStatusInfo = redux_state.SolutionReducer.productStatusInfo;

  return {
    prodBasicInfo,
    prodDetailedInfo,
    loader,
    progress,
    snackBar,
    files,
    errorInfo,
    allMasterData,
    pricingPlanMasterData,
    productDocDetail,
    productThumbnailImg,
    productCoverImg,
    documentData,
    authenticationKey,
    validation,
    filesUploadErr,
    filesUploadErrStatus,
    apiPlans,
    error,
    validationError,
    swaggerProdDetailedInfo,
    prodEndErrorInfo,
    publishData,
    publishErrorInfo,
    errorMsg,
    info,
    baseUrlError,
    prodNameError,
    paramDataTypes,
    paramUrlOptions,
    editableAttributes,
    editableDetails,
    approvedUsers,
    orgGrps,
    throttlingPolicies,
    productThrottlePolicy,
    saveThrottlingPolicyApiRes,
    saveThrottlingPolicyApiErr,
    deleteThrottlingPolicyApiRes,
    swapThrottlePolicyRes,
    swapThrottlePolicyErr,
    updateApiPlanError,
    deletePlanError,
    productVideosList,
    productVideosLinkList,
    videoLinkFailure,
    videoLinkSuccess,
    apiPlan,
    isProdNameEditable,
    prodName,
    portalView,
    isVisibleData,
    userCompanyStatus,
    productStatusInfo
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveProductInfo,
      saveBreadCrumbValue,
      generateSecretKey,
      updateProductDetailedInfo,
      resetProduct,
      resetFiles,
      updatePricingStatus,
      handleShowMessage,
      saveProductDetailedInfo,
      loading,
      validateProductUniqueness,
      updateProductStatus,
      getAllMasterData,
      getPricingPlanMasterData,
      uploadFile,
      deleteFile,
      progressing,
      reduxStorage,
      updateProductInfo,
      deleteGroup,
      deleteEndpoint,
      deleteSecretParam,
      deleteEndpointParameter,
      getProductDetailedInfo,
      getProductDocsListById,
      getDocumentData,
      createPricingPlan,
      getApiPlans,
      updatePricingPlan,
      resetPlan,
      deletePricingPlan,
      updatePricingPlanStatus,
      resetProdEndError,
      publishApi,
      updateInfo,
      saveSecretHeaderData,
      resetErrorInfo,
      getParamDataTypes,
      getMappedParamUrlOptions,
      saveGroupData,
      saveEndpointData,
      getEditableAttributes,
      getEditableDetails,
      resetEditableDetails,
      getApprovedUsers,
      getOrgGroups,
      getPolicyIdForProduct,
      addApiToThrottlingPolicy,
      deleteApiFromThrottlingPolicy,
      resetThrottlePolicy,
      swapThrottlingPolicyForAPI,
      addVideoLink,
      swaggerFileDownload,
      auditLogging,
      validateProductNameEdit,
      resetProdNameError,
      getProductVisibilityData,
      postProductVisibilityData,
      saveAdditionalDetailedInfo,
      saveProductSupportInfo,
      getBurstControlInfo,
      retrieveUserCompanyStatus,
      checkIfReseller
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SolutionContainer);
