import IdConstants from '../data/idConstants';
import { accessRoles } from '../data/appConstants';

const getUserId = () => {
  const storedItem = localStorage.getItem(
    IdConstants.selectedCompanyObject.selectedCompany
  );
  const parsedItem = storedItem ? JSON.parse(storedItem) : null;
  const selectedCompanyId = parsedItem ? parsedItem.id : null;
  const salesRepRole = localStorage.getItem(IdConstants.userLoginRole.role);
  return `${salesRepRole === accessRoles.sales_rep ? selectedCompanyId : localStorage.getItem(IdConstants.userId)}`;
};

export default getUserId;
