import axios from 'axios';
import * as Constants from './configuration';

export function swaggerFileDownload(urlParams) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          Constants.PRODUCT_DETAIL_ACTION.SWAGGER_FILE_DOWNLOAD +
            urlParams.productId +
            `/swagger/download?categoryId=` +
            urlParams.categoryId +
            `&fileType=` +
            urlParams.fileFormat,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {}
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    });
}
