import React from 'react';
import i18next from 'i18next';
import { CheckBox } from '../../../../msreact-kit/packages/html-components';
import idConstants from '../../../../data/idConstants';
import './index.scss';

const RegionCategory = ({ checkUserAgreement, cardData, checked, setChecked }) => {
  const handleCheckboxChange = (name) => {
    setChecked((prevState) => {
      if (prevState.find((item) => item.tenantId === name)) {
        return prevState.map((item) => {
          if (item.tenantId === name) {
            return {
              ...item,
              checked: !item.checked
            };
          }
          return item;
        });
      } else {
        return [
          ...prevState,
          {
            tenantId: name,
            accepted: false,
            checked: true
          }
        ];
      }
    });
  };

  return (
    <>
      {cardData.map((category, index) => (
        <div key={category?.displayName} className='category__container'>
          <div className='category__container__title'>
            {`${index + 1}. ${category?.displayName}`}
          </div>
          <div className='category__container__check'>
            <CheckBox
              displayName={i18next.t('SHARED.I_HAVE_READ_AND_AGREED')}
              id={category.displayName}
              name={category.tenantId}
              handleChange={(data) => handleCheckboxChange(data.name)}
              checked={
                checked?.find((item) => item.tenantId === category?.tenantId)
                  ?.accepted ||
                checked?.find((item) => item.tenantId === category?.tenantId)
                  ?.checked
              }
              disabled={
                checked?.find((item) => item.tenantId === category?.tenantId)
                  ?.accepted
              }
            />
            <a
              href={
                checkUserAgreement?.find(
                  (item) =>
                    item.identifier === category.tenantId &&
                    item.documentTypeId ===
                      idConstants.documentTypeIds.termsConditions
                )?.termsAndCondUrl
              }
              target='_blank'
              rel='noopener noreferrer'
              className='category__container__check_tnc'
            >
              {i18next.t('SHARED.TERMS_AND_CONDITIONS_CONSENT_HEADING')}
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default RegionCategory;
