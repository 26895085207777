import * as Constants from '../actions/configuration';
import idConstants from '../data/idConstants';
import { trackPageView, initialization } from './analytics';

export const setCookiePolicy = () => {
  const date = new Date();
  const expiryTime = new Date(
    date.getFullYear() + 1,
    date.getMonth(),
    date.getDate()
  ).toUTCString();
  document.cookie =
    'cookieConsentByUser=1;expires=' +
    expiryTime +
    ';path=/;domain=' +
    location.hostname;
  //MS-19526 (Adding Google Analytics)
  initialization();
  trackPageView(window.location.pathname);
};

export const unSetCookiePolicy = () => {
  const date = new Date();
  const expiryTime = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  ).toUTCString();
  document.cookie =
    'cookieConsentByUser=0;expires=' +
    expiryTime +
    ';path=/;domain=' +
    location.hostname;
  window.location.reload();
};

export const sortByDisplayOrder = (list) => {
  return list?.sort(
    (attribute1, attribute2) =>
      parseInt(attribute1.displayOrder) - parseInt(attribute2.displayOrder)
  );
};

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

export const camelToHyphen = (string) =>
  string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const capsWithSpaceToTitleCase = (string) => {
  let convertedString = '';
  string?.split(' ')?.map((word) => {
    if (word !== idConstants?.sidebarConstants?.apiProduct?.title)
      word = word?.toLowerCase();
    convertedString =
      convertedString + ' ' + word[0]?.toUpperCase() + word?.slice(1);
  });
  return convertedString;
};

export const getEnvironment = (env) => {
  switch (env) {
    case Constants.REACT_APP_ENVIRONMENT.DEV:
      return 'development';
    case Constants.REACT_APP_ENVIRONMENT.QA:
      return 'qa';
    case Constants.REACT_APP_ENVIRONMENT.QA_INT:
      return 'qa-internal';
    case Constants.REACT_APP_ENVIRONMENT.STAGE:
      return 'stage';
    case Constants.REACT_APP_ENVIRONMENT.PROD:
      return 'production';
    default:
      return 'development';
  }
};

export const isClientWidthExceeded = (id) => {
  const event = document.getElementById(id);
  return event?.scrollWidth > event?.clientWidth ? true : false;
};

export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const utf8Encode = (input) => {
  return encodeURIComponent(input).replace(/!/g, '%21');
};

export const getPricingPlanStatus = (planStatus, productStatusId) => {
  let status = {};
  switch (planStatus) {
    case idConstants.drafted.description:
      status.type = 'DYNAMIC_FORM.SHARED.DRAFT';
      status.cardType = 'drafted-card';
      break;
    case idConstants.submitted.description:
      status.type = 'DYNAMIC_FORM.TENANT_STATUS.AWAITING_APPROVAL';
      status.cardType = 'submitted-card';
      break;
    case idConstants.publishInProgress.description:
      status.type =
        productStatusId === idConstants.published.statusId
          ? 'DYNAMIC_FORM.SHARED.PUBLISH_IN_PROGRESS'
          : 'DYNAMIC_FORM.SHARED.APPROVED';
      status.cardType =
        productStatusId === idConstants.published.statusId
          ? 'submitted-card'
          : 'published-card';
      break;
    case idConstants.published.description:
      status.type =
        productStatusId === idConstants.published.statusId
          ? 'DYNAMIC_FORM.TENANT_STATUS.PUBLISHED'
          : 'DYNAMIC_FORM.SHARED.APPROVED';
      status.cardType = 'published-card';
      break;
    case idConstants.rejected.description:
      status.type = 'DYNAMIC_FORM.SHARED.REJECTED';
      status.cardType = 'rejected-card';
      break;
    default:
      status.type = planStatus ?? '';
      status.cardType = 'default-card';
  }
  return status;
};
