const initialState = {
  tenantCategoryMapping: [],
  productCategories: []
};

const CategoryMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TENANT_CATEGORY':
      return Object.assign({}, state, {
        tenantCategoryMapping: action.payload
      });
    case 'GET_CATEGORIES':
      return Object.assign({}, state, { productCategories: action.payload });
    default:
      return state;
  }
};

export default CategoryMappingReducer;
