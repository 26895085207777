const validation = {
  userManual: {
    accept: ['.pdf', '.doc', '.docx', '.txt', '.xls', '.xlsx'],
    maxFiles: 1,
    maxSize: 1048576,
    minSize: 0,
    multiple: true,
    disabled: false,
    noDrag: false
  },
  thumbImage: {
    accept: ['.jpeg', '.jpg', '.png', '.bmp', '.svg', '.gif'],
    maxFiles: 1,
    maxSize: 1048576,
    minSize: 0,
    multiple: false,
    disabled: false,
    noDrag: true
  },
  coverImage: {
    accept: ['.jpeg', '.jpg', '.png', '.bmp', '.svg', '.gif'],
    maxFiles: 1,
    maxSize: 5 * 1048576,
    minSize: 0,
    multiple: false,
    disabled: false,
    noDrag: true
  },
  uploadImage: {
    accept: ['.jpeg', '.jpg', '.png', '.bmp', '.svg', '.gif'],
    maxFiles: 10,
    maxSize: 20 * 1048576,
    minSize: 0,
    multiple: true,
    disabled: false,
    noDrag: true
  },
  uploadVideo: {
    accept: ['.mp4', '.mov', '.avi', '.wmv'],
    maxFiles: 10,
    maxSize: 20 * 1048576,
    minSize: 0,
    multiple: true,
    disabled: false,
    noDrag: true
  },
  attachment: {
    accept: ['.jpeg', '.jpg', '.png', '.bmp', '.svg', '.gif'],
    maxFiles: 1,
    maxSize: 1048576,
    minSize: 0,
    multiple: false,
    disabled: false,
    noDrag: true
  }
};

export default validation;
